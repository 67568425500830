"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "task-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "row-key": "id"
    },
    on: {
      "selection-change": _vm.onSelectRow
    }
  }, [_vm.isAdmin && _vm.isEdit ? _c("el-table-column", {
    attrs: {
      label: "",
      type: "selection",
      align: "center"
    }
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      width: "102",
      label: "Nhân viên",
      prop: "staff.name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.staffAvatar ? [_c("img", {
          staticStyle: {
            "object-fit": "cover",
            "border-radius": "50%"
          },
          attrs: {
            src: _vm.$baseUrlMedia + row.staffAvatar,
            width: "40",
            height: "40",
            alt: ""
          }
        }), _c("br")] : _vm._e(), _c("el-button", {
          staticClass: "red-nhanh",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickCustomer(row);
            }
          }
        }, [_vm._v("Nhanh.vn")]), _c("br"), _c("img", {
          staticStyle: {
            "margin-top": "12px",
            cursor: "pointer"
          },
          attrs: {
            width: "80",
            src: row.customerIsAddFriendZalo ? _vm.zaloHorizontalImg : _vm.zaloRed,
            alt: ""
          },
          on: {
            click: function click($event) {
              return _vm.onUpdateAddFriend(row);
            }
          }
        }), row.isHasVAT ? [_c("img", {
          staticStyle: {
            "margin-top": "12px"
          },
          attrs: {
            width: "80",
            src: _vm.vatIcon,
            alt: ""
          }
        })] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng",
      prop: "customer.name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm.checkIsSoonBuy(row) ? _c("div", {
          staticClass: "customer-badge soon-buy"
        }, [_c("img", {
          attrs: {
            src: _vm.comingSoonImg,
            width: "25",
            alt: ""
          }
        }), _c("span", {
          staticClass: "title"
        }, [_vm._v("Sắp mua")])]) : _vm.checkIsLongBuy(row) ? _c("div", {
          staticClass: "customer-badge long-buy"
        }, [_c("img", {
          attrs: {
            src: _vm.longTermImg,
            width: "25",
            alt: ""
          }
        }), _c("span", {
          staticClass: "title"
        }, [_vm._v("Lâu chưa mua")])]) : _vm._e(), _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.onViewCustomer(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.customerName) + " - " + _vm._s(row.customerPhone) + " ")]), _c("br"), _vm.isEdit ? _c("el-button", {
          attrs: {
            type: row.isIgnoreTask ? "warning" : "danger",
            icon: "el-icon-lock",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onBlockCustomer(row);
            }
          }
        }, [_vm._v(_vm._s(row.isIgnoreTask ? "Unblock khách" : "Block khách") + " ")]) : _vm._e(), _c("br"), _c("span", [_vm._v(_vm._s(row.customerAddress))]), _vm.visibleChangeSeller ? [_c("el-select", {
          attrs: {
            value: row.sellerId
          },
          on: {
            change: function change(val) {
              return _vm.onChangeSeller(val, row);
            }
          }
        }, _vm._l(_vm.sellers, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: item.name,
              value: item.id
            }
          });
        }), 1)] : _vm._e(), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tổng tiền: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalPrice)) + "đ")])]), _c("div", {
          class: !row.saleBK ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Băng keo: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.saleBK, 0, "0")) + "đ")])]), _c("div", {
          class: !row.saleMXH ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Xốp hơi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.saleMXH, 0, "0")) + "đ")])]), _c("div", {
          class: !row.saleMPE ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Màng PE: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.saleMPE, 0, "0")) + "đ")])]), _c("div", {
          class: !row.saleTCT ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Thùng carton: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.saleTCT, 0, "0")) + "đ")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú khách hàng",
      prop: "customerNote"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("PromotionList", {
          attrs: {
            titleStyle: "font-size:14px",
            customer: {},
            containerStyle: "padding:8px",
            spanColumn: 24,
            promotions: row.promotions,
            visibleCreate: false
          }
        }), _vm._l(row.customerTags.split(","), function (item, index) {
          return _c("span", {
            key: index
          }, [item ? _c("Tag", {
            attrs: {
              label: _vm.customerTagTrans[item],
              backgroundColor: _vm.customerTagStyles[item].backgroundColor,
              labelColor: _vm.customerTagStyles[item].labelColor
            }
          }) : _vm._e()], 1);
        }), _c("br"), _c("span", [_vm._v(" " + _vm._s(row.customerNote) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hoạt động gần nhất",
      prop: "description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return _vm._l(row.lastLogs, function (item, index) {
          return _c("div", {
            key: index,
            staticStyle: {
              "border-bottom": "#ccc 1px solid"
            }
          }, [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v(" " + _vm._s(_vm.fromNow(item.dateCreated)) + ": ")]), _c("span", [_vm._v(_vm._s(item.content))])])]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thông tin mua hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("div", {
          class: _vm.isOverCycle(row) && "text-danger"
        }, [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Số lần mua: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalOrder)) + " đơn")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Chu kỳ mua: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.buyCycle)) + " ngày")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Lần cuối mua: ")]), _c("span", [_vm._v(_vm._s(_vm.fromNow(row.lastOrderDate)))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Lần cuối chăm sóc: ")]), _c("span", [_vm._v(_vm._s(_vm.fromNow(row.lastTaskDate)))])]), _c("el-button", {
          attrs: {
            loading: row.loadingSyncLastOrder,
            type: "primary",
            icon: "el-icon-refresh",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleSyncLastOrderDate(row);
            }
          }
        }, [_vm._v("Sync lần cuối mua")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Copy nội dung"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("img", {
          staticStyle: {
            cursor: "pointer"
          },
          attrs: {
            width: "80",
            src: _vm.zaloIcon
          },
          on: {
            click: function click($event) {
              return _vm.handleCopyContent(row);
            }
          }
        }), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            loading: row.loadingContent
          },
          on: {
            click: function click($event) {
              return _vm.handleConfirmSentToCustomer(row);
            }
          }
        }, [_vm._v("Đã nhắn cho khách")])];
      }
    }])
  }), _vm.isEdit ? _c("el-table-column", {
    attrs: {
      label: "Hoàn thành",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.onCompleteTask(row);
            }
          }
        }, [_vm._v("Hoàn thành")]), _c("br"), _vm.visibleTaskRemainTime ? _c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v("Thời gian task còn lại: " + _vm._s(_vm.fromNowRaw(row.assignedAt)))]) : _vm._e()];
      }
    }], null, false, 4127773257)
  }) : _vm._e()], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;