"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScheduleProductionStatusTrans = exports.ScheduleProductionStatus = exports.QuoteStatusTrans = exports.QuoteStatusColor = exports.QuoteStatus = exports.ProductQuoteType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _QuoteStatusTrans, _QuoteStatusColor, _ScheduleProductionSt;

var ProductQuoteType;
exports.ProductQuoteType = ProductQuoteType;

(function (ProductQuoteType) {
  ProductQuoteType["Normal"] = "NORMAL";
  ProductQuoteType["Custom"] = "CUSTOM";
})(ProductQuoteType || (exports.ProductQuoteType = ProductQuoteType = {}));

;
var ScheduleProductionStatus;
exports.ScheduleProductionStatus = ScheduleProductionStatus;

(function (ScheduleProductionStatus) {
  ScheduleProductionStatus["New"] = "NEW";
  ScheduleProductionStatus["Designing"] = "DESIGNING";
  ScheduleProductionStatus["Producing"] = "PRODUCING";
  ScheduleProductionStatus["Completed"] = "COMPLETED";
})(ScheduleProductionStatus || (exports.ScheduleProductionStatus = ScheduleProductionStatus = {}));

var QuoteStatus;
exports.QuoteStatus = QuoteStatus;

(function (QuoteStatus) {
  QuoteStatus["New"] = "NEW";
  QuoteStatus["Quoting"] = "QUOTING";
  QuoteStatus["Quoted"] = "QUOTED";
  QuoteStatus["Reviewing"] = "REVIEWING";
  QuoteStatus["RemindLate"] = "REMIND_LATER";
  QuoteStatus["WaitingDeposit"] = "WAITING_DEPOSIT";
  QuoteStatus["Success"] = "SUCCESS";
  QuoteStatus["Fail"] = "FAIL";
})(QuoteStatus || (exports.QuoteStatus = QuoteStatus = {}));

var QuoteStatusTrans = (_QuoteStatusTrans = {}, (0, _defineProperty2.default)(_QuoteStatusTrans, QuoteStatus.New, "Đang tư vấn"), (0, _defineProperty2.default)(_QuoteStatusTrans, QuoteStatus.Quoted, "Đã báo giá"), (0, _defineProperty2.default)(_QuoteStatusTrans, QuoteStatus.Quoting, "Đang gửi mẫu"), (0, _defineProperty2.default)(_QuoteStatusTrans, QuoteStatus.Reviewing, "Cần thương lượng"), (0, _defineProperty2.default)(_QuoteStatusTrans, QuoteStatus.RemindLate, "Nhắc lại sau"), (0, _defineProperty2.default)(_QuoteStatusTrans, QuoteStatus.WaitingDeposit, "Chờ đặt cọc"), (0, _defineProperty2.default)(_QuoteStatusTrans, QuoteStatus.Success, "Thành công"), (0, _defineProperty2.default)(_QuoteStatusTrans, QuoteStatus.Fail, "Thất bại"), _QuoteStatusTrans);
exports.QuoteStatusTrans = QuoteStatusTrans;
var QuoteStatusColor = (_QuoteStatusColor = {}, (0, _defineProperty2.default)(_QuoteStatusColor, QuoteStatus.New, "#7ccb91"), (0, _defineProperty2.default)(_QuoteStatusColor, QuoteStatus.Quoting, "#245748"), (0, _defineProperty2.default)(_QuoteStatusColor, QuoteStatus.Quoted, "#fbd154"), (0, _defineProperty2.default)(_QuoteStatusColor, QuoteStatus.Reviewing, "#ea693e"), (0, _defineProperty2.default)(_QuoteStatusColor, QuoteStatus.WaitingDeposit, "#a13337"), (0, _defineProperty2.default)(_QuoteStatusColor, QuoteStatus.Success, "#67C23A"), (0, _defineProperty2.default)(_QuoteStatusColor, QuoteStatus.Fail, "#F56C6C"), (0, _defineProperty2.default)(_QuoteStatusColor, QuoteStatus.RemindLate, "#fd0f53"), _QuoteStatusColor);
exports.QuoteStatusColor = QuoteStatusColor;
var ScheduleProductionStatusTrans = (_ScheduleProductionSt = {}, (0, _defineProperty2.default)(_ScheduleProductionSt, ScheduleProductionStatus.New, 'Mới'), (0, _defineProperty2.default)(_ScheduleProductionSt, ScheduleProductionStatus.Designing, 'Đang thiết kế'), (0, _defineProperty2.default)(_ScheduleProductionSt, ScheduleProductionStatus.Producing, 'Đang sản xuất'), (0, _defineProperty2.default)(_ScheduleProductionSt, ScheduleProductionStatus.Completed, 'Đã giao hàng'), _ScheduleProductionSt);
exports.ScheduleProductionStatusTrans = ScheduleProductionStatusTrans;