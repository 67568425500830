"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaskTypeTrans = exports.TaskType = exports.TaskStatus = exports.TaskScore = exports.TaskLogType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _TaskTypeTrans;

var TaskStatus;
exports.TaskStatus = TaskStatus;

(function (TaskStatus) {
  TaskStatus["New"] = "NEW";
  TaskStatus["Completed"] = "COMPLETED";
})(TaskStatus || (exports.TaskStatus = TaskStatus = {}));

var TaskScore;
exports.TaskScore = TaskScore;

(function (TaskScore) {
  TaskScore["Bad"] = "BAD";
  TaskScore["Good"] = "GOOD";
})(TaskScore || (exports.TaskScore = TaskScore = {}));

var TaskType;
exports.TaskType = TaskType;

(function (TaskType) {
  TaskType["AddFriendZalo"] = "ADD_FRIEND_ZALO";
  TaskType["Sell"] = "SELL";
  TaskType["Ref"] = "REF";
  TaskType["BuyMore"] = "BUY_MORE";
  TaskType["Profit"] = "PROFIT";
  TaskType["ProfitPercent"] = "PROFIT_PERCENT";
  TaskType["OneOrder"] = "ONE_ORDER";
  TaskType["Common"] = "COMMON";
  TaskType["MultipleProduct"] = "MULTIPLE_PRODUCT";
  TaskType["SingleProduct"] = "SINGLE_PRODUCT";
  TaskType["Sale"] = "SALE"; //doanh số
})(TaskType || (exports.TaskType = TaskType = {}));

var TaskTypeTrans = (_TaskTypeTrans = {}, (0, _defineProperty2.default)(_TaskTypeTrans, TaskType.AddFriendZalo, 'Kết bạn Zalo'), (0, _defineProperty2.default)(_TaskTypeTrans, TaskType.Sell, 'Bán chéo'), (0, _defineProperty2.default)(_TaskTypeTrans, TaskType.Ref, 'Giới thiệu khách'), (0, _defineProperty2.default)(_TaskTypeTrans, TaskType.BuyMore, 'Mua nhiều (Danh mục)'), _TaskTypeTrans);
exports.TaskTypeTrans = TaskTypeTrans;
var TaskLogType;
exports.TaskLogType = TaskLogType;

(function (TaskLogType) {
  TaskLogType[TaskLogType["System"] = 1] = "System";
  TaskLogType[TaskLogType["User"] = 2] = "User";
})(TaskLogType || (exports.TaskLogType = TaskLogType = {}));