"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    staticClass: "pricing-config-table"
  }, [_vm._m(0), _c("hr"), _c("section", _vm._l(_vm.data, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "grid-2",
      staticStyle: {
        "margin-top": "4px"
      }
    }, [_c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(item.totalPrice)) + "đ")]), _c("div", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_c("span", {
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(item.profitPercent) + "%")])])]);
  }), 0)])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "grid-2"
  }, [_c("label", [_vm._v("Giá trị đơn hàng")]), _c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("label", [_vm._v("% lợi nhuận")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;