"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_vm.$devMode ? _c("div", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "default",
      loading: _vm.loadingImport
    },
    on: {
      click: _vm.handleImport
    }
  }, [_vm._v("Import permission")])], 1) : _vm._e(), _c("small", [_vm._v(" Setup which sections are accessible to each user permission level. All logged in staff can access the calendar, and owner accounts have full system access. ")]), _vm._l(_vm.data, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "permission-table"
    }, [_c("div", {
      staticClass: "permission-row header"
    }, [_c("div", {
      staticClass: "permission-cell"
    }, [_vm._v(" " + _vm._s(item.featureName) + " ")]), _vm._l(_vm.merchantRoles, function (item, index) {
      return _c("div", {
        key: index,
        staticClass: "permission-cell"
      }, [_vm._v(" " + _vm._s(item.type) + " ")]);
    })], 2), _vm._l(item.permissions, function (p, i) {
      return _c("div", {
        key: i,
        staticClass: "permission-row"
      }, [_c("div", {
        staticClass: "permission-cell"
      }, [_vm._v(" " + _vm._s(p.name) + " ")]), _vm._l(p.roles, function (pFeature, id) {
        return _c("div", {
          key: id,
          staticClass: "permission-cell",
          class: {
            locked: pFeature.locked
          }
        }, [_c("el-checkbox", {
          attrs: {
            disabled: pFeature.locked
          },
          model: {
            value: pFeature.access,
            callback: function callback($$v) {
              _vm.$set(pFeature, "access", $$v);
            },
            expression: "pFeature.access"
          }
        })], 1);
      })], 2);
    })], 2);
  }), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    staticStyle: {
      "margin-top": "32px"
    },
    attrs: {
      type: "primary",
      size: "default",
      loading: _vm.loadingSave
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("Save changes")])], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;