"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top"
    }
  }, [_c("div", {
    staticClass: "panel-shadow p-mobile-8"
  }, [_c("div", {
    staticStyle: {
      position: "relative",
      "margin-bottom": "12px"
    }
  }, [_c("h3", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0"
    }
  }, [_vm._v("Quy cách thùng")]), _vm._v(" > ")]), _c("el-radio", {
    attrs: {
      label: "box"
    },
    model: {
      value: _vm.mode,
      callback: function callback($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_vm._v("Thùng A1")]), _c("el-radio", {
    attrs: {
      label: "carton"
    },
    model: {
      value: _vm.mode,
      callback: function callback($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_vm._v("Giấy tấm")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_vm.mode == "box" ? [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài (cm)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                  alias: 'decimal',\n                  groupSeparator: '.',\n                  rightAlign: false,\n                  autoGroup: true,\n                  repeat: 20,\n                  autoUnmask: true,\n                  allowMinus: false,\n                }"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.length,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length", $$v);
      },
      expression: "form.length"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Rộng (cm)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                  alias: 'decimal',\n                  groupSeparator: '.',\n                  rightAlign: false,\n                  autoGroup: true,\n                  repeat: 20,\n                  autoUnmask: true,\n                  allowMinus: false,\n                }"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.width,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width", $$v);
      },
      expression: "form.width"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cao (cm)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                  alias: 'decimal',\n                  groupSeparator: '.',\n                  rightAlign: false,\n                  autoGroup: true,\n                  repeat: 20,\n                  autoUnmask: true,\n                  allowMinus: false,\n                }"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1)], 1)] : _vm._e(), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      xs: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng"
    }
  }, _vm._l(_vm.quantityPresets, function (item, index) {
    return _c("el-button", {
      key: index,
      staticClass: "font-bold",
      attrs: {
        round: "",
        type: _vm.form.quantity == item ? "primary" : ""
      },
      on: {
        click: function click($event) {
          return _vm.handleChangeQuantity(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      xs: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lớp",
      prop: "layer"
    }
  }, [_c("el-radio-group", {
    on: {
      change: _vm.handleChangeLayer
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 3
    }
  }, [_vm._v("3 lớp")]), _c("el-radio", {
    attrs: {
      label: 5
    }
  }, [_vm._v("5 lớp")]), _c("el-radio", {
    attrs: {
      label: 7
    }
  }, [_vm._v("7 lớp")])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      xs: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại sóng",
      prop: "cartonWave"
    }
  }, _vm._l(_vm.cartonWavesFilter, function (item, index) {
    return _c("el-radio", {
      key: index,
      attrs: {
        label: item.code
      },
      on: {
        change: _vm.handleChangeWave
      },
      model: {
        value: _vm.form.cartonWave,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "cartonWave", $$v);
        },
        expression: "form.cartonWave"
      }
    }, [_vm._v(_vm._s(item.code))]);
  }), 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      md: 8,
      xs: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm (cm)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }"
    }],
    attrs: {
      disabled: _vm.mode == "box"
    },
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.khoTam,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "khoTam", $$v);
      },
      expression: "form.khoTam"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      md: 8,
      xs: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm (cm)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }"
    }],
    attrs: {
      disabled: _vm.mode == "box"
    },
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.daiTam,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "daiTam", $$v);
      },
      expression: "form.daiTam"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      md: 8,
      xs: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Diện tích (m2)"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: "",
      value: _vm.$formatNumberVN(_vm.acreage, 2)
    }
  })], 1)], 1)], 2)], 1), _c("div", {
    staticClass: "panel-shadow p-mobile-8",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("div", [_c("h3", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v(" Quy đổi ")])]), _c("div", {
    staticClass: "weight-carton"
  }, [_c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Khối lượng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.minWeight, 4)) + "Kg - " + _vm._s(_vm.$formatNumberVN(_vm.maxWeight, 4)) + "Kg")])]), _c("div", [_vm._v(". "), _c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Chính xác:")]), _vm._v(" " + _vm._s(_vm.weightCarton) + "Kg")])]), _c("div", {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      xs: 12,
      offset: 0
    }
  }), _c("el-col", {
    attrs: {
      span: 10,
      xs: 12,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "hidden-xs",
    attrs: {
      for: ""
    }
  }, [_vm._v(" DLg (gam) ")])]), _c("el-col", {
    attrs: {
      span: 8,
      xs: 24,
      offset: 0
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  })])], 1), _vm._l(_vm.waves, function (item, index) {
    return [item.visible ? _c("el-row", {
      key: index,
      staticStyle: {
        "margin-top": "8px"
      },
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 6,
        offset: 0,
        xs: 12
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])]), _c("el-col", {
      attrs: {
        span: 10,
        offset: 0,
        xs: 12
      }
    }, [_c("el-input", {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: {
          alias: "decimal",
          groupSeparator: ".",
          rightAlign: false,
          autoGroup: true,
          repeat: 20,
          autoUnmask: true,
          allowMinus: false
        },
        expression: "{\n                  alias: 'decimal',\n                  groupSeparator: '.',\n                  rightAlign: false,\n                  autoGroup: true,\n                  repeat: 20,\n                  autoUnmask: true,\n                  allowMinus: false,\n                }"
      }],
      on: {
        input: _vm.handleChangeForm
      },
      model: {
        value: item.dl,
        callback: function callback($$v) {
          _vm.$set(item, "dl", $$v);
        },
        expression: "item.dl"
      }
    })], 1), _c("el-col", {
      staticClass: "mt-xs-8",
      attrs: {
        span: 8,
        xs: 24,
        offset: 0
      }
    }, [_c("div", _vm._l(_vm.getPreset(item), function (preset, index) {
      return _c("el-button", {
        key: index,
        attrs: {
          size: "mini",
          round: ""
        },
        on: {
          click: function click($event) {
            return _vm.handleChangeWavePreset(preset, item);
          }
        }
      }, [_vm._v(" " + _vm._s(preset) + " ")]);
    }), 1)])], 1) : _vm._e()];
  })], 2)])]), _c("CartonWeightPresetModal", {
    ref: "CartonWeightPresetModal",
    on: {
      submitOk: _vm.fetchPreset
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;