"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calcCartonUtil = void 0;

require("core-js/modules/es.json.stringify.js");

require("core-js/modules/es.array.find-index.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.to-string.js");

var COST_KEY = "CARTON_COST";
var PROFIT_KEY = 'CARTON_PROFIT_PERCENT';

var setCostToLocalStorage = function setCostToLocalStorage(cost) {
  var local = localStorage.getItem(COST_KEY);

  if (!local) {
    var arr = [cost];
    arr.push(cost);
    localStorage.setItem(COST_KEY, JSON.stringify(arr));
  } else {
    var _arr = JSON.parse(local);

    var findIndex = _arr.findIndex(function (e) {
      return e.id == cost.id;
    });

    if (findIndex > -1) {
      _arr[findIndex] = cost;
    } else {
      _arr.push(cost);
    }

    localStorage.setItem(COST_KEY, JSON.stringify(_arr));
  }
};

var getCostFromLocalStorage = function getCostFromLocalStorage(id) {
  var local = localStorage.getItem(COST_KEY);

  if (!local) {
    return null;
  }

  var arr = JSON.parse(local);
  return arr.find(function (e) {
    return e.id == id;
  });
};

var setProfitPercentToLocalStorage = function setProfitPercentToLocalStorage(_ref) {
  var profitPercent = _ref.profitPercent,
      isAutoProfit = _ref.isAutoProfit;
  localStorage.setItem(PROFIT_KEY, JSON.stringify({
    profitPercent: profitPercent,
    isAutoProfit: isAutoProfit
  }));
};

var getProfitPercentFromLocalStorage = function getProfitPercentFromLocalStorage() {
  var json = localStorage.getItem(PROFIT_KEY);

  if (!json) {
    return null;
  }

  return JSON.parse(json);
};

var calcCartonUtil = {
  setCostToLocalStorage: setCostToLocalStorage,
  getCostFromLocalStorage: getCostFromLocalStorage,
  setProfitPercentToLocalStorage: setProfitPercentToLocalStorage,
  getProfitPercentFromLocalStorage: getProfitPercentFromLocalStorage
};
exports.calcCartonUtil = calcCartonUtil;