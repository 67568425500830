"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      "close-on-click-modal": false,
      "append-to-body": "",
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Yêu cầu quấn PE",
      prop: "peNote"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 2,
      placeholder: "Ví dụ: Quấn kiện 100 cái/ kiện. Quấn PE dày, kiện lẻ quấn riêng"
    },
    model: {
      value: _vm.form.peNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "peNote", $$v);
      },
      expression: "form.peNote"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Thông tin giao"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.form.shipInfoType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "shipInfoType", $$v);
      },
      expression: "form.shipInfoType"
    }
  }, _vm._l(_vm.DeliveryTripShipInfoTypeTrans, function (item, key) {
    return _c("el-radio", {
      key: key,
      attrs: {
        label: key
      }
    }, [_vm._v(_vm._s(_vm.DeliveryTripShipInfoTypeTrans[key]))]);
  }), 1)], 1), _vm.form.shipInfoType == _vm.DeliveryTripShipInfoType.Fill ? [_c("el-form-item", {
    attrs: {
      label: "Thông tin người gửi",
      prop: "senderInfo"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 4,
      placeholder: ""
    },
    model: {
      value: _vm.form.senderInfo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "senderInfo", $$v);
      },
      expression: "form.senderInfo"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Thông tin người nhận",
      prop: "receiverInfo"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 4,
      placeholder: ""
    },
    model: {
      value: _vm.form.receiverInfo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "receiverInfo", $$v);
      },
      expression: "form.receiverInfo"
    }
  })], 1)] : [_c("el-form-item", {
    attrs: {
      label: "Link",
      prop: "shipLink"
    }
  }, [_c("el-input", {
    attrs: {
      rows: 4,
      placeholder: ""
    },
    model: {
      value: _vm.form.shipLink,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "shipLink", $$v);
      },
      expression: "form.shipLink"
    }
  })], 1)], _c("div", {
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.handlePrintShipInfo
    }
  }, [_vm._v(" In nhãn dán kiện hàng ")])], 1)], 2), _c("ShipFormPrint", {
    ref: "ShipFormPrint",
    attrs: {
      deliveryTrip: _vm.form
    }
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submitData
    }
  }, [_vm._v("Đồng ý")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;