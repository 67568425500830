"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

var _vue = _interopRequireDefault(require("vue"));

var _inputmask = _interopRequireDefault(require("inputmask"));

_vue.default.directive('mask', {
  bind: function bind(el, binding) {
    (0, _inputmask.default)(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
  }
}); // v-mask="{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"