"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "employee-item",
    class: {
      drag: _vm.visibleDrag
    },
    on: {
      click: function click($event) {
        return _vm.$emit("click");
      }
    }
  }, [_vm.visibleDrag ? _c("div", {
    staticClass: "drag-icon"
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: "bars"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "avatar-box",
    style: {
      border: "2px solid ".concat(_vm.employee.appointmentColor)
    }
  }, [_vm.employee.avatar ? _c("img", {
    attrs: {
      src: "",
      alt: ""
    }
  }) : _c("div", {
    staticClass: "name-avatar"
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.employee.firstname[0]) + _vm._s(_vm.employee.lastname[0]) + " ")])])]), _c("div", {
    staticClass: "name-box"
  }, [_c("span", {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.employee.firstname) + _vm._s(_vm.employee.lastname))]), _c("span", {
    staticClass: "note"
  }, [_vm._v(" " + _vm._s(_vm.employee.note) + " ")])]), _c("div", {
    staticClass: "mail-box"
  }, [_c("div", [_c("a", {
    staticClass: "mail",
    attrs: {
      href: "mailto:".concat(_vm.employee.email)
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return false.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.employee.email))]), _c("br"), _c("a", {
    staticClass: "phone",
    attrs: {
      href: "tel:".concat(_vm.$formatPhone(_vm.employee.callingCode, _vm.employee.phone))
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return false.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$formatPhone(_vm.employee.callingCode, _vm.employee.phone)))])])]), _c("div", {
    staticClass: "icon"
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: "chevron-right"
    }
  })], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;