"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VatStatusTrans = exports.VatStatus = exports.OrderStatusTrans = exports.OrderStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _OrderStatusTrans, _VatStatusTrans;

var OrderStatus;
exports.OrderStatus = OrderStatus;

(function (OrderStatus) {
  OrderStatus["New"] = "New";
  OrderStatus["Confirming"] = "Confirming";
  OrderStatus["CustomerConfirming"] = "CustomerConfirming";
  OrderStatus["Confirmed"] = "Confirmed";
  OrderStatus["Packing"] = "Packing";
  OrderStatus["Packed"] = "Packed";
  OrderStatus["Shipping"] = "Shipping";
  OrderStatus["Success"] = "Success";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));

var OrderStatusTrans = (_OrderStatusTrans = {}, (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.New, 'Mới'), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Confirming, 'Đang xác nhận'), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.CustomerConfirming, 'Chờ khách xác nhận'), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Confirmed, 'Đã xác nhận'), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Packing, 'Đang đóng gói'), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Packed, 'Đã đóng gói'), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Shipping, 'Đang giao hàng'), (0, _defineProperty2.default)(_OrderStatusTrans, OrderStatus.Success, 'Thành công'), _OrderStatusTrans);
exports.OrderStatusTrans = OrderStatusTrans;
var VatStatus;
exports.VatStatus = VatStatus;

(function (VatStatus) {
  VatStatus["UnDraff"] = "UN_DRAFF";
  VatStatus["Draff"] = "DRAFF";
  VatStatus["Completed"] = "COMPLETED"; //Đã phát hành
})(VatStatus || (exports.VatStatus = VatStatus = {}));

var VatStatusTrans = (_VatStatusTrans = {}, (0, _defineProperty2.default)(_VatStatusTrans, VatStatus.UnDraff, 'Chưa gửi nháp'), (0, _defineProperty2.default)(_VatStatusTrans, VatStatus.Draff, 'Đã tạo nháp'), (0, _defineProperty2.default)(_VatStatusTrans, VatStatus.Completed, 'Đã phát hành'), _VatStatusTrans);
exports.VatStatusTrans = VatStatusTrans;