"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("div", [_c("font-awesome-icon", {
    staticClass: "task-icon text-color--black",
    attrs: {
      icon: "tasks"
    }
  }), _c("label", {
    staticClass: "label text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Việc cần làm")])], 1), _c("div", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [!_vm.visibleAdd ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("Thêm mới")]) : _vm._e(), _vm.visibleAdd ? _c("div", [_c("todo-compose", {
    ref: "TodoCompose",
    on: {
      save: _vm.handleCreate,
      cancel: function cancel($event) {
        _vm.visibleAdd = false;
      }
    }
  })], 1) : _vm._e(), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, _vm._l(_vm.data, function (item) {
    return _c("todo-item", {
      key: item.id,
      attrs: {
        item: item,
        isFocus: _vm.isFocus
      },
      on: {
        update: _vm.handleUpdate,
        delete: _vm.handleDelete,
        "check:done": _vm.handleCheckDone
      }
    });
  }), 1)], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;