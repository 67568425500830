"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "log-item"
  }, [_c("div", {
    staticClass: "avatar-box"
  }, [_c("img", {
    attrs: {
      src: _vm.avatarUrl,
      alt: ""
    }
  })]), _c("div", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.log.staff && _vm.log.staff.name) + " ")]), !_vm.isEditing ? _c("pre", {
    staticClass: "text-color--black content"
  }, [_vm._v(_vm._s(_vm.log.content))]) : _c("CustomerLogCompose", {
    ref: "composer",
    attrs: {
      visibleCancel: true
    },
    on: {
      cancel: function cancel($event) {
        _vm.isEditing = false;
      },
      save: _vm.handleUpdate
    }
  }), _vm.visibleControl && !_vm.isEditing ? [_c("div", {
    staticClass: "control-box"
  }, [_c("span", {
    staticClass: "control-label",
    on: {
      click: _vm.handleEdit
    }
  }, [_vm._v(" Sửa ")]), _c("span", {
    staticClass: "control-label"
  }, [_c("el-popover", {
    attrs: {
      placement: "top",
      title: "",
      width: "",
      trigger: "click",
      content: ""
    },
    on: {
      show: function show($event) {
        _vm.visibleConfirm = true;
      }
    },
    model: {
      value: _vm.visibleConfirm,
      callback: function callback($$v) {
        _vm.visibleConfirm = $$v;
      },
      expression: "visibleConfirm"
    }
  }, [_c("span", {
    staticClass: "control-label",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("Xóa")]), _c("div", {
    staticStyle: {
      width: "200px"
    }
  }, [_c("div", {
    staticClass: "confirm-header text-color--black font-bold"
  }, [_vm._v(" Xác nhận xóa? ")]), _c("div", {
    staticClass: "confirm-content"
  }, [_c("span", [_vm._v("Xoá vĩnh viễn hoạt động này. Sau khi xóa không thể phục hồi")]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "danger",
      size: "small"
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa hoạt động")])], 1)])])], 1)])] : _vm._e(), _c("div", [_c("span", {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm.$formatDateTime(_vm.log.dateCreated)))])])], 2)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;