"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Các lần nhập kho",
      visible: _vm.visible,
      width: "900px",
      "close-on-click-modal": false,
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "ID Phiếu nhập",
      prop: "inventoryDetail.inventoryBill.id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-link", {
          attrs: {
            target: "_blank",
            type: "primary",
            href: "https://nhanh.vn/inventory/bill/detail?storeId=84054&id=".concat(row.inventoryDetail.inventoryBill.id)
          }
        }, [_vm._v(" " + _vm._s(row.inventoryDetail.inventoryBill.id) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL nhập",
      prop: "quantity",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.isVerify ? _c("span", [_vm._v(_vm._s(row.quantity))]) : _c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          staticClass: "text-right",
          model: {
            value: row.quantity,
            callback: function callback($$v) {
              _vm.$set(row, "quantity", $$v);
            },
            expression: "row.quantity"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL trên phiếu",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.quantityOnBill)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sản phẩm",
      prop: "inventoryDetail.product.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Đã xác nhận",
      align: "center",
      width: "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("el-checkbox", {
          attrs: {
            value: row.isVerify
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Xác nhận đã nhập kho",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [!row.isVerify ? _c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleVerify(row);
            }
          }
        }, [_vm._v("Xác nhận đã nhập kho")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Xóa",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v("Xóa")])];
      }
    }])
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;