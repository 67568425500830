"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.string.link.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Tạo liên kết",
      visible: _vm.visible,
      width: "700px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("el-link", {
    attrs: {
      href: _vm.link,
      type: "primary",
      target: "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.link) + " ")])], 1), _c("div", {
    staticClass: "text-center"
  }, [_c("label", {
    staticStyle: {
      "margin-top": "12px",
      display: "block"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Mã QR:")]), _c("br"), _c("canvas", {
    ref: "canvas",
    attrs: {
      id: "link-canvas"
    }
  }), _c("br"), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "default",
      icon: "el-icon-document-copy"
    },
    on: {
      click: _vm.handleCopy
    }
  }, [_vm._v("Sao chép liên kết")])], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;