"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("div", {
    staticClass: "panel-shadow"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hạn báo giá"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "300px"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm._v("ngày")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.expireQuoteConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.expireQuoteConfig, "value", $$v);
      },
      expression: "expireQuoteConfig.value"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Ghi chú"
    }
  }, [_c("small", {
    staticStyle: {
      "margin-bottom": "10px",
      display: "inline-block",
      color: "red"
    }
  }, [_vm._v('Lưu ý: luôn có ký hiệu "{isVat}" để xác định khi in có VAT hay không!')]), _c("tinymce", {
    ref: "editor",
    attrs: {
      visibleUpload: false,
      height: 250
    },
    on: {
      onInit: _vm.handleInit
    },
    model: {
      value: _vm.notePrintConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.notePrintConfig, "value", $$v);
      },
      expression: "notePrintConfig.value"
    }
  })], 1)], 1), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("Lưu")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;