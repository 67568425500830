"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "calendar-component"
  }, [_vm.scrollLeft ? _c("div", {
    staticClass: "btn-control-scroll left",
    on: {
      click: _vm.handlePrevScroll
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: "chevron-left"
    }
  })], 1) : _vm._e(), _vm.scrollLeft < _vm.maxScrollLeft - 20 ? _c("div", {
    staticClass: "btn-control-scroll right",
    on: {
      click: _vm.handleNextScroll
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: "chevron-right"
    }
  })], 1) : _vm._e(), _c("vue-cal", {
    ref: "vueCal",
    staticClass: "work-cal",
    attrs: {
      "selected-date": _vm.dateSelected,
      "time-from": _vm.startHour * 60,
      "time-to": _vm.endHour * 60,
      "time-step": _vm.timeStep,
      "disable-views": ["years", "year", "month", "week"],
      events: _vm.events,
      "split-days": _vm.splitDays,
      "sticky-split-labels": _vm.stickySplitLabels,
      "active-view": "day",
      "min-cell-width": _vm.minCellWidth,
      "min-split-width": _vm.minSplitWidth,
      "editable-events": _vm.isEditableEvent,
      "time-cell-height": _vm.timeCellHeight,
      "cell-contextmenu": "",
      "snap-to-time": _vm.snapTime,
      "twelve-hour": true,
      "on-event-create": _vm.onEventCreate
    },
    on: {
      ready: _vm.scrollToCurrentTime,
      "event-mouse-enter": _vm.hoverEvent,
      "event-resizing": _vm.onEventResizing,
      "event-mouse-leave": _vm.handleEvMouseLeave,
      "event-duration-change": _vm.onEventDurationChange,
      "cell-click": _vm.handleCellClick,
      "event-focus": _vm.focusEvent,
      "event-drag-create": _vm.eventDragCreate,
      "event-change": _vm.eventChange,
      "event-drop": _vm.onDop
    },
    scopedSlots: _vm._u([{
      key: "no-event",
      fn: function fn() {
        return [_c("div")];
      },
      proxy: true
    }, {
      key: "split-label",
      fn: function fn(_ref) {
        var split = _ref.split;
        return [_vm.shouldUseOneDayMode ? [_vm.viewMode == "day" ? [_vm._t("daySplit", null, {
          split: split
        })] : _vm._e(), _vm.viewMode == "three-days" || _vm.viewMode == "week" ? _c("day-header", {
          attrs: {
            split: split
          }
        }) : _vm._e()] : _c("day-header", {
          attrs: {
            split: split
          }
        })];
      }
    }, {
      key: "time-cell",
      fn: function fn(_ref2) {
        var hours = _ref2.hours,
            minutes = _ref2.minutes;
        return [_c("div", {
          class: {
            "vuecal__time-cell-line": true,
            hours: !minutes
          }
        }, [!minutes ? _c("span", {
          staticStyle: {
            "font-size": "15px",
            "font-weight": "bold",
            color: "black"
          }
        }, [_vm._v(_vm._s(_vm.formatHour(hours)))]) : _c("span", {
          staticStyle: {
            "font-size": "11px"
          }
        }, [_vm._v(_vm._s(minutes))])])];
      }
    }, {
      key: "event",
      fn: function fn(_ref3) {
        var event = _ref3.event;
        return [event.class == "empty" ? _c("div", [_c("span", {
          staticClass: "time-empty"
        }, [_vm._v(" " + _vm._s(event.start.formatTime("hh:mm am")) + " ")])]) : event.data ? [_vm._t("event", null, {
          event: event
        })] : _c("div", {
          staticClass: "event-custom"
        }, [_vm._v(" " + _vm._s(event.start.formatTime("hh:mm am")) + " - " + _vm._s(event.end.formatTime("hh:mm am")) + " ")])];
      }
    }], null, true)
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;