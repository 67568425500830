"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_vm.$devMode ? _c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm mới")])], 1)]) : _vm._e(), _c("el-table", {
    attrs: {
      data: _vm.cartonWaves,
      fit: ""
    },
    on: {
      "row-click": _vm.handleUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Loại sóng",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Chiều cao sóng",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.height) + " mm ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hệ số quy đổi",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.ratioChange)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lớp",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.layer) + " lớp ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Phí vận chuyển"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.shipFee)) + "đ ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Phí vận chuyển test"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.shipFee2)) + "đ ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú",
      prop: "note"
    }
  })], 1), _c("carton-wave-modal", {
    ref: "CartonWaveModal",
    on: {
      "submit:ok": _vm.fetchCartonWaves
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;