"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("truck-tour-query", {
    attrs: {
      districts: _vm.districts,
      onQuery: _vm.handleQuery,
      query: _vm.query,
      querySearch: _vm.querySearch,
      sellers: _vm.sellers
    }
  }), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      size: "default",
      disabled: !_vm.selection.length
    },
    on: {
      click: function click($event) {
        return _vm.onAddNew(_vm.selection);
      }
    }
  }, [_vm._v("Thêm vào chuyến mới")]), _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      size: "default",
      disabled: !_vm.selection.length
    },
    on: {
      click: function click($event) {
        return _vm.onAddExist(_vm.selection);
      }
    }
  }, [_vm._v("Thêm vào chuyến đã có")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.orders,
      border: "",
      fit: "",
      "row-key": "id"
    },
    on: {
      "selection-change": _vm.handleSelectRow
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      type: "selection",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "#",
      width: "105"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm.checkIsTruckTour(row) ? _c("img", {
          attrs: {
            src: _vm.truckImg,
            width: "40",
            alt: ""
          }
        }) : _vm._e(), _c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank",
            href: "https://nhanh.vn/order/manage/detail?storeId=84054&id=".concat(row.id)
          }
        }, [_vm._v(" " + _vm._s(row.id) + " ")]), _c("br"), _c("span", [_vm._v(_vm._s(row.createdDateTime))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(row.customer.phone) + " ")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.customer.name))]), _c("br"), _c("span", [_vm._v(_vm._s(row.customer.address))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sản phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return _vm._l(row.productToOrders, function (item, index) {
          return _c("div", {
            key: index,
            staticClass: "product-grid",
            class: {
              "not-last": index != row.productToOrders.length - 1
            }
          }, [_c("label", [_vm._v(" " + _vm._s(item.product.name) + " ")]), _c("span", [item.product.unit ? _c("span", [_vm._v("(" + _vm._s(item.product.unit) + ")")]) : _vm._e()]), _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.price)) + " ")]), _c("span", {
            staticStyle: {
              "text-align": "right"
            }
          }, [_vm._v(" x" + _vm._s(item.quantity) + " ")])]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị",
      width: "90",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalPrice)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Mô tả",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("p", [_vm._v(" " + _vm._s(row.description) + " ")]), row.privateDescription ? _c("p", [_vm._v(" ---- "), _c("br"), _vm._v(" " + _vm._s(row.privateDescription) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nhân viên",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("order-status-label", {
          attrs: {
            statusCode: row.statusCode,
            statusName: row.statusName
          }
        }), _c("br"), _c("span", [_vm._v(_vm._s(row.seller.name))])];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      limit: _vm.limit
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:limit": function updateLimit($event) {
        _vm.limit = $event;
      },
      pagination: _vm.fetchOrders
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;