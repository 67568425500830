"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("p", {
    staticStyle: {
      "font-size": "12px",
      color: "rgba(98,98,98,0.55)"
    }
  }, [_vm._v(" Assign services this staff member can perform. ")]), _c("div", [_c("el-checkbox", {
    attrs: {
      indeterminate: _vm.isIndeterminate
    },
    on: {
      change: _vm.handleCheckAllChange
    },
    model: {
      value: _vm.checkAll,
      callback: function callback($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }, [_vm._v("Select all")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.checkedServices,
      callback: function callback($$v) {
        _vm.checkedServices = $$v;
      },
      expression: "checkedServices"
    }
  }, _vm._l(_vm.services, function (service, index) {
    return _c("el-col", {
      key: index,
      staticClass: "service-item",
      attrs: {
        span: 12,
        offset: 0
      }
    }, [_c("el-checkbox", {
      attrs: {
        label: service.id
      }
    }, [_vm._v(_vm._s(service.name))])], 1);
  }), 1)], 1)], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;