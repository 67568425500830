"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top"
    }
  }, [_c("section", {
    staticClass: "box"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Giấy")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lớp",
      prop: "layer"
    }
  }, [_c("el-select", {
    ref: "layerRef",
    attrs: {
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeLayer
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_c("el-option", {
    attrs: {
      label: "3 lớp",
      value: 3
    }
  }), _c("el-option", {
    attrs: {
      label: "5 lớp",
      value: 5
    }
  }), _c("el-option", {
    attrs: {
      label: "7 lớp",
      value: 7
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại sóng",
      prop: "cartonWave"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      "value-key": "id",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.cartonWave,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonWave", $$v);
      },
      expression: "form.cartonWave"
    }
  }, _vm._l(_vm.cartonWavesFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1)], 1)], 1), _c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Quy cách sản phẩm")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.length,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length", $$v);
      },
      expression: "form.length"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Rộng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.width,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width", $$v);
      },
      expression: "form.width"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cao"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1)], 1)], 1), _c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Cách xếp")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng theo chiều dài"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.amountLength,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amountLength", $$v);
      },
      expression: "form.amountLength"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng theo chiều rộng"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.amountWidth,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amountWidth", $$v);
      },
      expression: "form.amountWidth"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng theo chiều cao"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.amountHeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amountHeight", $$v);
      },
      expression: "form.amountHeight"
    }
  })], 1)], 1)], 1), _c("div", [_c("label", {
    staticStyle: {
      display: "inline-block",
      "margin-right": "4px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.isMainWidth ? "Xếp xuôi theo quy cách sản phẩm" : "Xếp ngược theo quy cách sản phẩm"))]), _c("el-switch", {
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ff4949"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.isMainWidth,
      callback: function callback($$v) {
        _vm.isMainWidth = $$v;
      },
      expression: "isMainWidth"
    }
  })], 1), _c("label", {
    staticClass: "title",
    staticStyle: {
      display: "block",
      "margin-top": "12px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Kết quả")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.result.length,
      callback: function callback($$v) {
        _vm.$set(_vm.result, "length", $$v);
      },
      expression: "result.length"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Rộng"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.result.width,
      callback: function callback($$v) {
        _vm.$set(_vm.result, "width", $$v);
      },
      expression: "result.width"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cao"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.result.height,
      callback: function callback($$v) {
        _vm.$set(_vm.result, "height", $$v);
      },
      expression: "result.height"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;