"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MoldTypeTrans = exports.MoldType = exports.MoldBoxTypeTrans = exports.MoldBoxType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _MoldBoxTypeTrans, _MoldTypeTrans;

var MoldBoxType;
exports.MoldBoxType = MoldBoxType;

(function (MoldBoxType) {
  MoldBoxType["A1"] = "A1";
  MoldBoxType["A1NapChom1"] = "A1C1N";
  MoldBoxType["A1NapChom2"] = "A1C2N";
  MoldBoxType["A11Nap"] = "A11N";
  MoldBoxType["A10Nap"] = "A10N";
  MoldBoxType["NapGai"] = "NAP_GAI";
  MoldBoxType["NapGaiTrong"] = "NGXHT";
  MoldBoxType["AmDuong"] = "AM_DUONG";
  MoldBoxType["QuaiXach"] = "QUAI_XACH";
  MoldBoxType["NapGaiMocDay"] = "NAP_GAI_MOC_DAY";
  MoldBoxType["DungGiay"] = "DUNG_GIAY";
  MoldBoxType["VachNgan"] = "VACH_NGAN";
  MoldBoxType["TamLot"] = "TAM_LOT";
  MoldBoxType["GiayTam"] = "GIAY_TAM";
  MoldBoxType["HopBe"] = "HOP_BE";
  MoldBoxType["BVN_TL"] = "BVN_TL";
  MoldBoxType["NS1"] = "NS1";
})(MoldBoxType || (exports.MoldBoxType = MoldBoxType = {}));

var MoldBoxTypeTrans = (_MoldBoxTypeTrans = {}, (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.A1, 'Thùng A1'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.A1NapChom1, 'Thùng A1 (1 nắp chồm)'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.A1NapChom2, 'Thùng A1 (2 nắp chồm)'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.A11Nap, 'A1 1 nắp'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.A10Nap, 'A1 Không nắp'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.DungGiay, 'Hộp đựng giày'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.NapGai, 'Hộp nắp gài'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.NapGaiTrong, 'Hộp nắp gài xếp hông trong'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.AmDuong, 'Thùng âm dương'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.QuaiXach, 'Quai xách đục lỗ'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.NapGaiMocDay, 'Nắp gài móc đáy'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.VachNgan, 'Vách ngăn'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.TamLot, 'Tấm lót'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.GiayTam, 'Giấy tấm'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.HopBe, 'Hộp bế'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.BVN_TL, 'Bộ vách ngăn/Tấm lót'), (0, _defineProperty2.default)(_MoldBoxTypeTrans, MoldBoxType.NS1, 'NS1'), _MoldBoxTypeTrans);
exports.MoldBoxTypeTrans = MoldBoxTypeTrans;
var MoldType;
exports.MoldType = MoldType;

(function (MoldType) {
  MoldType["Circle"] = "CIRCLE";
  MoldType["Flat"] = "FLAT";
})(MoldType || (exports.MoldType = MoldType = {}));

var MoldTypeTrans = (_MoldTypeTrans = {}, (0, _defineProperty2.default)(_MoldTypeTrans, MoldType.Circle, 'Bế tròn'), (0, _defineProperty2.default)(_MoldTypeTrans, MoldType.Flat, 'Bế phẳng'), _MoldTypeTrans);
exports.MoldTypeTrans = MoldTypeTrans;