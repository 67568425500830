"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Danh sách đơn hàng",
      visible: _vm.visible,
      width: "1200px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("label", {
    staticStyle: {
      "font-size": "22px"
    }
  }, [_vm._v(" Danh sách đơn hàng ")]), _c("br"), _c("span", {
    staticStyle: {
      color: "#555555"
    }
  }, [_vm._v("Mã: " + _vm._s(_vm.misaProduct.code))]), _c("br"), _c("span", {
    staticStyle: {
      color: "#555555"
    }
  }, [_vm._v("Tên: " + _vm._s(_vm.misaProduct.name))])]), _c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Trạng thái")]), _c("div", [_c("el-radio", {
    staticStyle: {
      display: "inline-block"
    },
    attrs: {
      label: null
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.deliveryStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "deliveryStatus", $$v);
      },
      expression: "query.deliveryStatus"
    }
  }, [_vm._v("Tất cả")]), _c("el-radio", {
    staticStyle: {
      display: "inline-block"
    },
    attrs: {
      label: _vm.MisaDeliveryStatus.Pending
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.deliveryStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "deliveryStatus", $$v);
      },
      expression: "query.deliveryStatus"
    }
  }, [_vm._v("Chưa giao")]), _c("el-radio", {
    staticStyle: {
      display: "inline-block"
    },
    attrs: {
      label: _vm.MisaDeliveryStatus.Delivering
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.deliveryStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "deliveryStatus", $$v);
      },
      expression: "query.deliveryStatus"
    }
  }, [_vm._v("Đang giao")])], 1)])]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: true
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isCompleteDelivery,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isCompleteDelivery", $$v);
      },
      expression: "query.isCompleteDelivery"
    }
  }, [_vm._v(" Ẩn còn lại = 0 ")]), _c("el-checkbox", {
    attrs: {
      label: "true"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.is30Day,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "is30Day", $$v);
      },
      expression: "query.is30Day"
    }
  }, [_vm._v("10 ngày gần nhất")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Mã đơn"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [!row.msOrder.syncId ? _c("span", [_vm._v(" " + _vm._s(row.msOrder.code) + " ")]) : _c("el-link", {
          attrs: {
            type: "primary",
            href: "https://actapp.misa.vn/app/popup/SAOrderDetail/".concat(row.msOrder.syncId)
          }
        }, [_vm._v(" " + _vm._s(row.msOrder.code) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày đơn hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDate(row.msOrder.startAt)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tên khách hàng",
      prop: "msOrder.customerName"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("OrderStatusLabel", {
          attrs: {
            statusCode: row.msOrder.deliveryStatus,
            statusName: _vm.MisaDeliveryStatusName[row.msOrder.deliveryStatus]
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tên sản phẩm",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Mã SP",
      prop: "code"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumber(row.quantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đã giao"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumber(row.delivered)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Còn lại"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(row.quantity - row.delivered < 0 ? 0 : _vm.$formatNumber(row.quantity - row.delivered)) + " ")];
      }
    }])
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("span"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;