import { render, staticRenderFns } from "./OrderStatusLabel.vue?vue&type=template&id=15a34e74&scoped=true&"
import script from "./OrderStatusLabel.vue?vue&type=script&lang=ts&"
export * from "./OrderStatusLabel.vue?vue&type=script&lang=ts&"
import style0 from "./OrderStatusLabel.vue?vue&type=style&index=0&id=15a34e74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a34e74",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15a34e74')) {
      api.createRecord('15a34e74', component.options)
    } else {
      api.reload('15a34e74', component.options)
    }
    module.hot.accept("./OrderStatusLabel.vue?vue&type=template&id=15a34e74&scoped=true&", function () {
      api.rerender('15a34e74', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Share/OrderStatusLabel.vue"
export default component.exports