"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật giờ làm việc" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false,
      fullscreen: _vm.isFullScreen
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v("Cập nhật giờ làm việc")]), _vm.productionWork ? _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.productionWork.code) + " ")]), _c("div", [_c("span", [_vm._v(" " + _vm._s(_vm.productionWork.workOrder.name) + " ")])])]) : _vm._e()]), _c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFetch,
      expression: "loadingFetch"
    }],
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số phút",
      prop: "duration"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.duration,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "duration", $$v);
      },
      expression: "form.duration"
    }
  }, _vm._l(_vm.minuteOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("div", [_c("span", [_vm._v("Quy đổi: ")]), _c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.renderTime(_vm.form.duration)))])]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;