"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;

require("core-js/modules/es.object.to-string.js");

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _index = _interopRequireDefault(require("@/layout/index.vue"));

var _Blank = _interopRequireDefault(require("@/layout/Blank.vue"));

_vue.default.use(_vueRouter.default);
/* Layout */


/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/redirect",
  component: _index.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index.vue"));
      });
    }
  }]
}, {
  path: "/public",
  component: _Blank.default,
  hidden: true,
  meta: {
    permission: false
  },
  children: [{
    path: "lich-su-nhap-kho",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/workOrder-inventory/public/WorkOrderInventoryPage.vue"));
      });
    },
    name: "public-lich-su-nhap-kho",
    meta: {
      title: "Lich sử nhập kho",
      permission: false
    },
    hidden: true
  }, {
    path: "work",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-work/index.vue"));
      });
    },
    name: "public-production-work",
    redirect: "/production/work",
    meta: {
      title: "Lệnh làm việc",
      permission: false
    },
    hidden: true
  }, {
    path: "calc-weight",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-weight-carton/index.vue"));
      });
    },
    name: "public-carton-calc-weight",
    meta: {
      title: "Tính K.Lượng",
      permission: false
    },
    hidden: true
  }, {
    path: "carton-price",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/public/carton-pricing/index.vue"));
      });
    },
    name: "public-carton-price",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "calc-carton",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "public-calc-carton",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "calc-carton/v3Test",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "public-calc-carton-v3Test",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "calc-carton/v2Test",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "public-calc-carton-v2test",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "carton-tam-lot",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/TamLotSale.vue"));
      });
    },
    name: "public-tam-lot",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "operate-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/public/operate-order/index.vue"));
      });
    },
    name: "public-operate-order",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "carton/normal",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/v3Sale.vue"));
      });
    },
    name: "public-carton-normal",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "carton/nap-gai",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/NapGaiSale.vue"));
      });
    },
    name: "public-carton-nap-gai",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "carton/am-duong",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/AmDuongSale.vue"));
      });
    },
    name: "public-carton-am-duong",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "carton/quai-xach-duc-lo",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/ThungGaSale.vue"));
      });
    },
    name: "public-carton-quai-xach-duc-lo",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "nap-gai-moc-day",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/ThungMocDaySale.vue"));
      });
    },
    name: "public-carton-nap-gai-moc-day",
    meta: {
      permission: false
    },
    hidden: true
  }]
}, {
  path: "/iframe",
  component: _Blank.default,
  meta: {
    permission: false
  },
  hidden: true,
  children: [{
    path: "quote-carton",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-iframe/index.vue"));
      });
    },
    name: "iframe-quote-carton",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "spec",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-spec-box-iframe/index.vue"));
      });
    },
    name: "iframe-spec",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "size-box",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-size-box-iframe/index.vue"));
      });
    },
    name: "iframe-size-box",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "volume",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-volume-iframe/index.vue"));
      });
    },
    name: "iframe-volume",
    meta: {
      permission: false
    },
    hidden: true
  }, {
    path: "volume-truck",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-volume-truck-iframe/index.vue"));
      });
    },
    name: "iframe-volume-truck",
    meta: {
      permission: false
    },
    hidden: true
  }]
}, {
  path: "/auth",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/auth/index.vue"));
    });
  },
  name: "auth",
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect.vue"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404.vue"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401.vue"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/operate",
  hidden: true
}, {
  path: "/dashboard",
  component: _index.default,
  meta: {
    title: "Dashboard",
    icon: "chart",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index.vue"));
      });
    },
    name: "dashboard-index",
    meta: {
      title: "Admin",
      permission: true
    }
  }, {
    path: "seller",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/report-page.vue"));
      });
    },
    name: "dashboard-seller-index",
    meta: {
      title: "Seller",
      permission: true
    }
  }, {
    path: "top-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/top-sale/index.vue"));
      });
    },
    name: "dashboard-top-sale",
    meta: {
      title: "BXH Sale",
      permission: true
    }
  }, {
    path: "config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/top-sale/configuration.vue"));
      });
    },
    name: "dashboard-top-sale-config",
    meta: {
      title: "Cấu hình BXH",
      permission: true
    }
  }]
}, {
  path: "/production",
  component: _index.default,
  meta: {
    title: "Sản xuất",
    icon: "users",
    permission: true
  },
  children: [{
    path: "production-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/workOrder/index.vue"));
      });
    },
    name: "misa-production-order",
    meta: {
      title: "LSX",
      permission: true
    }
  }, {
    path: "lsx-vb",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/workOrder/index.vue"));
      });
    },
    name: "misa-lsx-vb",
    meta: {
      title: "LSX (VB)",
      permission: true
    }
  }, {
    path: "work",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-work/index.vue"));
      });
    },
    name: "production-work",
    meta: {
      title: "Lệnh làm việc",
      permission: true
    }
  }, {
    path: "scan-inventory",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/scan-inventory/index.vue"));
      });
    },
    name: "production-scan-inventory",
    meta: {
      title: "Nhập kho",
      permission: true
    }
  }, //quyền
  {
    path: "work-edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-work/index.vue"));
      });
    },
    name: "production-work-edit",
    hidden: true,
    meta: {
      title: "Sửa lệnh làm việc",
      permission: true
    }
  }, {
    path: "create-report",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-work/index.vue"));
      });
    },
    name: "production-create-report",
    hidden: true,
    meta: {
      title: "Tạo báo sản lượng",
      permission: true
    }
  }, {
    path: "update-report",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-work/index.vue"));
      });
    },
    name: "production-update-report",
    hidden: true,
    meta: {
      title: "Sửa báo sản lượng",
      permission: true
    }
  }, {
    path: "delete-report",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-work/index.vue"));
      });
    },
    name: "production-delete-report",
    hidden: true,
    meta: {
      title: "Xóa báo sản lượng",
      permission: true
    }
  }, //end - quyền
  {
    path: "delivery-need",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/need-delivery/index.vue"));
      });
    },
    name: "misa-delivery-need",
    meta: {
      title: "Hàng cần lấy",
      permission: true
    }
  }, {
    path: "report",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-report/index.vue"));
      });
    },
    name: "misa-production-report",
    meta: {
      title: "Báo cáo sản lượng",
      permission: true
    }
  }, {
    path: "report-lsx",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report-lsx/index.vue"));
      });
    },
    name: "report-lsx",
    meta: {
      title: "Báo cáo LSX",
      permission: true
    }
  }, {
    path: "setup",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-config/index.vue"));
      });
    },
    name: "production-setup",
    meta: {
      title: "Cấu hình",
      permission: true
    }
  }, {
    path: "c",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-work-hour/index.vue"));
      });
    },
    name: "production-work-hour",
    meta: {
      title: "Giờ làm việc",
      permission: true
    }
  }, {
    path: "log",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-log/index.vue"));
      });
    },
    name: "production-log",
    meta: {
      title: "Log",
      permission: true
    }
  }]
}, {
  path: "/misa",
  component: _index.default,
  meta: {
    title: "MISA ACT",
    icon: "users",
    permission: true
  },
  children: [{
    path: "calendar",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/CalendarPage.vue"));
      });
    },
    name: "misa-calendar",
    meta: {
      title: "Lịch",
      permission: true
    }
  }, {
    path: "calendar-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/CalendarPage.vue"));
      });
    },
    name: "misa-calendar-sale",
    meta: {
      title: "Lịch (sale)",
      permission: true
    }
  }, {
    path: "delivery",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/index.vue"));
      });
    },
    name: "misa-delivery",
    meta: {
      title: "Lệnh giao hàng",
      permission: true
    }
  }, {
    path: "delivery-add",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/index.vue"));
      });
    },
    name: "misa-delivery-add",
    hidden: true,
    meta: {
      title: "Thêm lệnh giao hàng",
      permission: true
    }
  }, {
    path: "delivery-edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/index.vue"));
      });
    },
    name: "misa-delivery-edit",
    hidden: true,
    meta: {
      title: "Sửa lệnh giao hàng",
      permission: true
    }
  }, {
    path: "delivery-delete",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/index.vue"));
      });
    },
    name: "misa-delivery-delete",
    hidden: true,
    meta: {
      title: "Xóa lệnh giao hàng",
      permission: true
    }
  }, {
    path: "delivery-product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/DeliveryGroupProduct.vue"));
      });
    },
    name: "misa-delivery-product",
    meta: {
      title: "SP cần giao",
      permission: true
    }
  }, {
    path: "buy",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-product/BuyToday.vue"));
      });
    },
    name: "misa-buy",
    meta: {
      title: "Hôm nay mua gì",
      permission: true
    }
  }, {
    path: "ms-product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-product/index.vue"));
      });
    },
    name: "misa-ms-product",
    meta: {
      title: "Sản phẩm",
      permission: true
    }
  }, {
    path: "ms-product-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-product/index.vue"));
      });
    },
    name: "misa-ms-product-sale",
    meta: {
      title: "Sản phẩm (Sale)",
      permission: true
    }
  }, {
    path: "ms-product-vb",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-product/MisaProductV2.vue"));
      });
    },
    name: "misa-ms-product-vb",
    meta: {
      title: "Sản phẩm (VB)",
      permission: true
    }
  }, {
    path: "thanh-pham",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-inventory/index.vue"));
      });
    },
    name: "misa-thanh-pham",
    meta: {
      title: "Thành phẩm",
      permission: true
    }
  }, {
    path: "ms-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/msOrder/index.vue"));
      });
    },
    name: "misa-ms-order",
    meta: {
      title: "Đơn hàng",
      permission: true
    }
  }, {
    path: "ms-order-check-price",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/MSOrderCheckPriceBook/index.vue"));
      });
    },
    name: "misa-ms-order-check-price",
    meta: {
      title: "Kiểm giá",
      permission: true
    }
  }, {
    path: "po",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-po/index.vue"));
      });
    },
    name: "misa-po",
    meta: {
      title: "PO",
      permission: true
    }
  }, {
    path: "log",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/craw-misa-log/index.vue"));
      });
    },
    name: "misa-craw-log",
    meta: {
      title: "Log monitor",
      permission: true
    }
  }, {
    path: "configuration",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-config/index.vue"));
      });
    },
    name: "misa-configuration",
    meta: {
      title: "Cấu hình",
      permission: true
    }
  }, {
    path: "sync-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sync-msOrder/index.vue"));
      });
    },
    name: "misa-sync-msOrder",
    meta: {
      title: "Đồng bộ ĐH",
      permission: true
    }
  }, {
    path: "phuc-hao",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-phuchao/index.vue"));
      });
    },
    name: "misa-phuchao",
    meta: {
      title: "Phúc Hảo",
      permission: true
    }
  }]
}, {
  path: "/misa-crm",
  component: _index.default,
  meta: {
    title: "MISA CRM",
    icon: "users",
    permission: true
  },
  children: [{
    path: "customer-rate",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/change-rate-customer-history/index.vue"));
      });
    },
    name: "misa-crm-change-rate-customer-history",
    meta: {
      title: "Duyệt hạng KH",
      permission: true
    }
  }, {
    path: "customer-group",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-customer-group/index.vue"));
      });
    },
    name: "misa-crm-customer-group",
    meta: {
      title: "Nhóm KH",
      permission: true
    }
  }, {
    path: "customer",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-customer/index.vue"));
      });
    },
    name: "misa-crm-customer",
    meta: {
      title: "CSKH",
      permission: true
    }
  }, {
    path: "customer-clone",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-customer/index.vue"));
      });
    },
    name: "misa-crm-customer-clone",
    meta: {
      title: "CSKH (Clone)",
      permission: true
    }
  }, {
    path: "log",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-log/index.vue"));
      });
    },
    name: "misa-crm-log",
    meta: {
      title: "Log",
      permission: true
    }
  }, {
    path: "lead",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-lead/index.vue"));
      });
    },
    name: "misa-crm-lead",
    meta: {
      title: "Chào hàng",
      permission: true
    }
  }, {
    path: "vietbox-lead",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/vietbox-lead/index.vue"));
      });
    },
    name: "misa-crm-vietbox-lead",
    meta: {
      title: "Chào hàng (VB)",
      permission: true
    }
  }, {
    path: "lead-report",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/MisaLeadReport/index.vue"));
      });
    },
    name: "misa-crm-lead-report",
    meta: {
      title: "Thống kê",
      permission: true
    }
  }, {
    path: "lead-log",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-log/LeadLog.vue"));
      });
    },
    name: "misa-crm-lead-log",
    meta: {
      title: "Lead Log",
      permission: true
    }
  }, {
    path: "activity-list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-activity/MisaActivityList.vue"));
      });
    },
    name: "misa-crm-activity-list",
    meta: {
      title: "Hoạt động",
      permission: true
    }
  }, {
    path: "activity",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-activity/index.vue"));
      });
    },
    name: "misa-crm-activity",
    meta: {
      title: "Hoạt động (Lịch)",
      permission: true
    }
  }, {
    path: "opportunity",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-opportunity/index.vue"));
      });
    },
    name: "misa-crm-opportunity",
    meta: {
      title: "Cơ hội",
      permission: true
    }
  }, {
    path: "quote",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-quote/index.vue"));
      });
    },
    name: "misa-crm-quote",
    meta: {
      title: "Báo giá",
      permission: true
    }
  }, {
    path: "activity-type",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-activity-type/index.vue"));
      });
    },
    name: "misa-crm-activity-type",
    meta: {
      title: "Loại nhiệm vụ",
      permission: true
    }
  }, {
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-order/index.vue"));
      });
    },
    name: "misa-crm-order",
    meta: {
      title: "Đơn hàng",
      permission: true
    }
  }, {
    path: "employee",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/misa-employee/index.vue"));
      });
    },
    name: "misa-crm-employee",
    meta: {
      title: "Nhân viên",
      permission: true
    }
  }]
}, {
  path: "/hai-dang",
  component: _index.default,
  meta: {
    title: "Hải Đăng",
    icon: "users",
    permission: true
  },
  alwaysShow: true,
  children: [{
    path: "kho",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/workOrder-inventory/index.vue"));
      });
    },
    name: "haidang-kho",
    meta: {
      title: "Kho",
      permission: true
    }
  }, {
    path: "kho-v3",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/workOrder-inventory/index.vue"));
      });
    },
    name: "haidang-kho-v3",
    meta: {
      title: "Kho v3",
      permission: true
    }
  }, {
    path: "kho-v4",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/workOrder-inventory/v4.vue"));
      });
    },
    name: "haidang-kho-v4",
    meta: {
      title: "Kho v4",
      permission: true
    }
  }, {
    path: "duyet-kho",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/production-report/index.vue"));
      });
    },
    name: "haidang-duyet-kho",
    meta: {
      title: "Duyệt kho",
      permission: true
    }
  }, {
    path: "nv-kho",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/warehoue-staff/index.vue"));
      });
    },
    name: "haidang-nv-kho",
    meta: {
      title: "NV kho",
      permission: true
    }
  }, {
    path: "delivery-today",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/DeliveryGroupTrip.vue"));
      });
    },
    name: "misa-delivery-today",
    meta: {
      title: "Giao hôm nay",
      permission: true
    }
  }, {
    path: "ship",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/DeliveryGroupTrip.vue"));
      });
    },
    name: "misa-delivery-ship",
    meta: {
      title: "Phí vận chuyển",
      permission: true
    }
  }, {
    path: "ship-unblock",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/DeliveryGroupTrip.vue"));
      });
    },
    name: "misa-delivery-ship-unblock",
    hidden: true,
    meta: {
      title: "Mở khóa chuyến",
      permission: true
    }
  }, {
    path: "truck",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/delivery-trip/truck/index.vue"));
      });
    },
    name: "misa-delivery-truck",
    meta: {
      title: "Cấu hình xe",
      permission: true
    }
  }]
}, {
  path: "/crm",
  component: _index.default,
  meta: {
    title: "CRM",
    icon: "users",
    permission: true
  },
  children: [{
    path: "censor-follow",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/task-follow/pending.vue"));
      });
    },
    name: "crm-censor-follow",
    meta: {
      title: "Duyệt follow",
      permission: true
    }
  }, {
    path: "un-follow",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/task-follow/un-follow.vue"));
      });
    },
    name: "crm-censor-un-follow",
    meta: {
      title: "Duyệt un-follow",
      permission: true
    }
  }, {
    path: "customer-care-v4",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/task-v4/index.vue"));
      });
    },
    name: "crm-customer-care",
    meta: {
      title: "Chăm sóc KH v4",
      permission: true
    }
  }, {
    path: "sale-mix",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer-sale-mix/index.vue"));
      });
    },
    name: "crm-customer-sale-mix",
    meta: {
      title: "Bán chéo",
      permission: true
    }
  }, {
    path: "profit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/crm/profit.vue"));
      });
    },
    name: "crm-profit",
    meta: {
      title: "Lợi nhuận",
      permission: true
    }
  }, {
    path: "profit-percent",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/crm/profit-percent.vue"));
      });
    },
    name: "crm-profit-percent",
    meta: {
      title: "Lợi nhuận (%)",
      permission: true
    }
  }, {
    path: "one-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/crm/one-order.vue"));
      });
    },
    name: "crm-one-order",
    meta: {
      title: "Mua 1 lần",
      permission: true
    }
  }, {
    path: "by-product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer-by-product/index.vue"));
      });
    },
    name: "crm-by-product",
    meta: {
      title: "CSKH (Sản phẩm)",
      permission: true
    }
  }, {
    path: "by-one-product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer-by-one-product/index.vue"));
      });
    },
    name: "crm-by-one-product",
    meta: {
      title: "CSKH (1 SP)",
      permission: true
    }
  }, {
    path: "by-amount",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/task-by-amount/index.vue"));
      });
    },
    name: "crm-by-amount",
    meta: {
      title: "CSKH (Doanh số)",
      permission: true
    }
  }, {
    path: "history",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer-log/index.vue"));
      });
    },
    name: "crm-history",
    meta: {
      title: "Lịch sử chăm sóc",
      permission: true
    }
  }, {
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/task-v3/order.vue"));
      });
    },
    name: "crm-order",
    meta: {
      title: "Đơn hàng",
      permission: true
    }
  }]
}, {
  path: "/customer",
  component: _index.default,
  meta: {
    title: "Khách hàng",
    icon: "users",
    permission: true
  },
  alwaysShow: true,
  children: [{
    path: "search",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/search-customer/index.vue"));
      });
    },
    name: "customer-search",
    meta: {
      title: "Tra cứu KH",
      permission: true
    }
  }]
}, {
  path: "/inventory",
  component: _index.default,
  meta: {
    title: "Kho hàng",
    icon: "shopify-brands",
    permission: true
  },
  children: [{
    path: "product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/inventory/index.vue"));
      });
    },
    name: "inventory-product-list",
    meta: {
      title: "Ngưỡng nhập kho",
      permission: true
    }
  }, {
    path: "buy-today",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/inventory/BuyToDay.vue"));
      });
    },
    name: "inventory-product-buy-today",
    meta: {
      title: "Hôm nay mua gì",
      permission: true
    }
  }, {
    path: "import",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/inventory/import/index.vue"));
      });
    },
    name: "inventory-import",
    meta: {
      title: "Nhập kho",
      permission: true
    }
  }, {
    path: "pending",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/inventory/pending-quantity/index.vue"));
      });
    },
    name: "pending-inventory-product-list",
    meta: {
      title: "Chờ nhập kho",
      permission: true
    }
  }, {
    path: "po",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/inventory/po.vue"));
      });
    },
    name: "inventory-po",
    meta: {
      title: "PO",
      permission: true
    }
  }]
}, {
  path: "/product",
  component: _index.default,
  meta: {
    title: "Sản phẩm",
    icon: "shopify-brands",
    permission: true
  },
  children: [{
    path: "promotion",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/promotion/index.vue"));
      });
    },
    name: "product-promotion",
    meta: {
      title: "Khuyến mãi",
      permission: true
    }
  }, {
    path: "list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product-v2/index.vue"));
      });
    },
    name: "product-list",
    meta: {
      title: "Danh sách",
      permission: true
    }
  }, {
    path: "product-group",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product-group/index.vue"));
      });
    },
    name: "product-group",
    meta: {
      title: "Nhóm sản phẩm",
      permission: true
    }
  }, {
    path: "product-group-name",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product-group-name/index.vue"));
      });
    },
    name: "product-group-name",
    meta: {
      title: "Nhóm tên SP",
      permission: true
    }
  }, {
    path: "profit-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profit-product-config/index.vue"));
      });
    },
    name: "product-profit-config",
    meta: {
      title: "Cấu hình lợi nhuận",
      permission: true
    }
  }, // {
  //   path: "pricing",
  //   component: () => import("@/views/product-pricing-config/index.vue"),
  //   name: "product-pricing",
  //   meta: {
  //     title: "Cấu hình giá",
  //     permission: true,
  //   },
  // },
  {
    path: "pricing-group",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product-pricing-config/product-group.vue"));
      });
    },
    name: "product-pricing-group",
    meta: {
      title: "Cấu hình giá",
      permission: true
    }
  }, {
    path: "pricing-group-v2",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product-pricing-config/product-group-v2.vue"));
      });
    },
    name: "product-pricing-group-v2",
    meta: {
      title: "Cấu hình giá v2",
      permission: true
    }
  }, {
    path: "pricing",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sale-product-pricing-table/index.vue"));
      });
    },
    name: "product-pricing-table",
    meta: {
      title: "Bảng giá",
      permission: true
    }
  }]
}, {
  path: "/operate",
  component: _index.default,
  redirect: "/operate/order",
  meta: {
    title: "Vận đơn",
    icon: "truck-moving-solid",
    permission: true
  },
  children: [{
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/operate-order/index.vue"));
      });
    },
    name: "operate-order",
    meta: {
      title: "Đo lường",
      permission: true
    }
  }, {
    path: "category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/category-product/index.vue"));
      });
    },
    name: "operate-category",
    meta: {
      title: "Quy đổi thể tích",
      permission: true
    }
  }, {
    path: "vehicle",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/vehicle/index.vue"));
      });
    },
    name: "operate-vehicle",
    meta: {
      title: "Q.lý phương tiện",
      permission: true
    }
  }, {
    path: "truck-calendar",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/truck-calendar/index.vue"));
      });
    },
    name: "operate-truck",
    meta: {
      title: "Lịch xe tải",
      permission: true
    }
  }]
}, {
  path: "/truck-tour",
  component: _index.default,
  redirect: "/truck-tour/index",
  hidden: true,
  meta: {
    title: "Điều xe tải",
    icon: "truck-moving-solid",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/truck-tour/index.vue"));
      });
    },
    name: "truck-tour",
    meta: {
      title: "Điều xe tải",
      permission: true
    }
  }]
}, {
  path: "/calendar",
  component: _index.default,
  redirect: "/calendar/index",
  meta: {
    title: "Lịch công việc",
    icon: "calendar-alt",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/work-calendar/index.vue"));
      });
    },
    name: "calendar-index",
    meta: {
      title: "Lịch công việc",
      permission: true
    }
  }]
}, {
  path: "/zalo-utility",
  component: _index.default,
  redirect: "/zalo-utility/index",
  // alwaysShow: true,
  hidden: true,
  meta: {
    title: "Tiện ích Zalo",
    icon: "calendar-alt",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/zalo-utility/index.vue"));
      });
    },
    name: "zalo-utility-index",
    meta: {
      title: "Cài đặt",
      permission: true
    }
  }]
}, {
  path: "/craw",
  component: _index.default,
  redirect: "/craw",
  meta: {
    title: "Craw",
    icon: "screwdriver-wrench-solid",
    permission: true
  },
  children: [{
    path: "trangvang",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/craw/trangvang/index.vue"));
      });
    },
    name: "craw-trangvang",
    meta: {
      title: "Trang vàng",
      permission: true
    }
  }]
}, {
  path: "/calc-carton",
  component: _index.default,
  redirect: "/calc-carton/index",
  meta: {
    title: "Tính giá carton",
    icon: "box-open-solid",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "calc-carton-index",
    meta: {
      title: "Tính giá",
      permission: true
    }
  }, {
    path: "hai-dang",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "calc-carton-hai-dang",
    meta: {
      title: "Tính giá (HĐ)",
      permission: true
    }
  }, {
    path: "khach-hang",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "calc-carton-khach-hang",
    meta: {
      title: "Tính giá (KH)",
      permission: true
    }
  }, {
    path: "vietbox",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "calc-carton-vietbox",
    meta: {
      title: "Tính giá (VB)",
      permission: true
    }
  }, {
    path: "test-v2",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "calc-carton-index-test-v2",
    meta: {
      title: "Tính giá (Test v2)",
      permission: true
    }
  }, {
    path: "test-v3",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "calc-carton-index-test-v3",
    meta: {
      title: "Tính giá (Test v3)",
      permission: true
    }
  }, {
    path: "machine",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/carton-machine/index.vue"));
      });
    },
    name: "carton-machine",
    meta: {
      title: "Cấu hình máy",
      permission: true
    }
  }, {
    path: "pricing-group",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/carton-pricing-group/index.vue"));
      });
    },
    name: "calc-carton-carton-pricing-group",
    meta: {
      title: "Nhóm khách hàng",
      permission: true
    }
  }, {
    path: "sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-carton/index.vue"));
      });
    },
    name: "calc-carton-sale",
    meta: {
      title: "Tính giá (Sale)",
      permission: true
    }
  }, {
    path: "config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/carton-cost/index.vue"));
      });
    },
    name: "calc-carton-config",
    meta: {
      title: "Cấu hình thùng",
      permission: true
    }
  }, {
    path: "config-profit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/carton-profit-config/index.vue"));
      });
    },
    name: "calc-carton-config-profit",
    meta: {
      title: "Cấu hình LN",
      permission: true
    }
  }]
}, {
  path: "/carton",
  component: _index.default,
  redirect: "/operate/order",
  meta: {
    title: "Thùng Carton",
    icon: "box-open-solid",
    permission: true
  },
  children: [{
    path: "quote",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote/index.vue"));
      });
    },
    name: "carton-quote",
    meta: {
      title: "Báo giá",
      permission: true
    }
  }, {
    path: "quote-v3",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote/v3.vue"));
      });
    },
    name: "carton-quote-v3",
    meta: {
      title: "Báo giá V3",
      permission: true
    }
  }, {
    path: "eta",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/index.vue"));
      });
    },
    name: "carton-quote-v2",
    meta: {
      title: "Tính giá",
      permission: true
    }
  }, {
    path: "eta-v2",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/v2.vue"));
      });
    },
    name: "carton-quote-v2-v2",
    meta: {
      title: "Tính giá V2",
      permission: true
    }
  }, {
    path: "eta-v3-testing",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/ThungATesting.vue"));
      });
    },
    name: "carton-quote-thunga-testing",
    meta: {
      title: "V3 Testing",
      permission: true
    }
  }, {
    path: "eta-v3",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/v3.vue"));
      });
    },
    name: "carton-quote-v2-v3",
    meta: {
      title: "Tính giá V3",
      permission: true
    }
  }, {
    path: "eta-v3-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/v3Sale.vue"));
      });
    },
    name: "carton-quote-v2-v3-sale",
    meta: {
      title: "Tính giá (Sale)",
      permission: true
    }
  }, {
    path: "eta-nap",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/NapGai.vue"));
      });
    },
    name: "carton-quote-v2-eta-nap",
    meta: {
      title: "Hộp nắp gài",
      permission: true
    }
  }, {
    path: "eta-nap-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/NapGaiSale.vue"));
      });
    },
    name: "carton-quote-v2-eta-nap-sale",
    meta: {
      title: "Hộp nắp gài(Sale)",
      permission: true
    }
  }, {
    path: "calc-thung-chua",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/ThungChua.vue"));
      });
    },
    name: "carton-quote-calc-thung-chua",
    meta: {
      title: "Thùng chứa",
      permission: true
    }
  }, {
    path: "calc-thung-chua-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/ThungChuaSale.vue"));
      });
    },
    name: "carton-quote-calc-thung-chua-sale",
    meta: {
      title: "Thùng chứa(Sale)",
      permission: true
    }
  }, {
    path: "calc-phu-bi",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/PhuBiLotLong.vue"));
      });
    },
    name: "carton-quote-calc-phu-phi",
    meta: {
      title: "Phủ bì & lọt lòng",
      permission: true
    }
  }, {
    path: "quy-doi-song",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quy-doi-song/QuyDoiSong.vue"));
      });
    },
    name: "quy-doi-song",
    meta: {
      title: "Quy đổi sóng",
      permission: true
    }
  }, {
    path: "calc-vach-ngan",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/VachNgan.vue"));
      });
    },
    name: "carton-quote-calc-vach-ngan",
    meta: {
      title: "Vách ngăn",
      permission: true
    }
  }, {
    path: "calc-tam-lot",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/TamLot.vue"));
      });
    },
    name: "carton-quote-calc-tam-lot",
    meta: {
      title: "Tấm lót",
      permission: true
    }
  }, {
    path: "calc-tam-lot-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/TamLotSale.vue"));
      });
    },
    name: "carton-quote-calc-tam-lot-sale",
    meta: {
      title: "Tấm lót(Sale)",
      permission: true
    }
  }, {
    path: "eta-solar",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/AmDuong.vue"));
      });
    },
    name: "carton-quote-v2-eta-solar",
    meta: {
      title: "Âm dương",
      permission: true
    }
  }, {
    path: "eta-solar-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/AmDuongSale.vue"));
      });
    },
    name: "carton-quote-v2-eta-solar-sale",
    meta: {
      title: "Âm dương (Sale)",
      permission: true
    }
  }, {
    path: "thung-ga",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/ThungGa.vue"));
      });
    },
    name: "carton-quote-v2-thung-ga",
    meta: {
      title: "Quai xách đục lỗ",
      permission: true
    }
  }, {
    path: "thung-ga-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/ThungGaSale.vue"));
      });
    },
    name: "carton-quote-v2-thung-ga-sale",
    meta: {
      title: "Quai xách đục lỗ (Sale)",
      permission: true
    }
  }, {
    path: "nap-gai-moc-day",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/ThungMocDay.vue"));
      });
    },
    name: "carton-quote-nap-gai-moc-day",
    meta: {
      title: "Nắp gài móc đáy",
      permission: true
    }
  }, {
    path: "nap-gai-moc-day-sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/ThungMocDaySale.vue"));
      });
    },
    name: "carton-quote-nap-gai-moc-day-sale",
    meta: {
      title: "Nắp gài móc đáy (Sale)",
      permission: true
    }
  }, {
    path: "eta-config-v3",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/configV3.vue"));
      });
    },
    name: "carton-eta-config-v3",
    meta: {
      title: "Cấu hình V3",
      permission: true
    }
  }, {
    path: "machine-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/machine-config/index.vue"));
      });
    },
    name: "carton-machine-config",
    meta: {
      title: "Cấu hình máy",
      permission: true
    }
  }, {
    path: "eta-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-carton-v2/config.vue"));
      });
    },
    name: "carton-eta-config-v2",
    meta: {
      title: "Cấu hình t.giá",
      permission: true
    }
  }, {
    path: "schedule",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/schedule-production/index.vue"));
      });
    },
    name: "carton-schedule",
    meta: {
      title: "Lịch sản xuất",
      permission: true
    }
  }, {
    path: "carton-pricing-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/carton-price/index.vue"));
      });
    },
    name: "carton-pricing-config",
    meta: {
      title: "Cấu hình giá",
      permission: true
    }
  }, {
    path: "customer-carton-pricing-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/carton-price/customer.vue"));
      });
    },
    name: "customer-carton-pricing-config",
    meta: {
      title: "Giá theo khách",
      permission: true
    }
  }, {
    path: "calc-weight",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/calc-weight-carton/index.vue"));
      });
    },
    name: "carton-calc-weight",
    meta: {
      title: "Tính K.Lượng",
      permission: true
    }
  }, // {
  //   path: "quote-history",
  //   component: () => import("@/views/quote-history/index.vue"),
  //   name: "carton-quote-history",
  //   meta: {
  //     title: "Danh sách báo giá",
  //     permission: true,
  //   },
  // },
  {
    path: "profit-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profit-config/index.vue"));
      });
    },
    name: "carton-profit-config",
    meta: {
      title: "Cấu hình lợi nhuận",
      permission: true
    }
  }, {
    path: "mold",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mold/index.vue"));
      });
    },
    name: "carton-mold",
    meta: {
      title: "Khuôn bế",
      permission: true
    }
  }, {
    path: "mold-update",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mold/index.vue"));
      });
    },
    name: "carton-mold-edit",
    hidden: true,
    meta: {
      title: "Chỉnh sửa khuôn bế",
      permission: true
    }
  }, {
    path: "type",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/carton-type/index.vue"));
      });
    },
    name: "carton-type",
    meta: {
      title: "Loại thùng",
      permission: true
    }
  }, {
    path: "wave",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/carton-wave/index.vue"));
      });
    },
    name: "carton-wave",
    meta: {
      title: "Loại sóng",
      permission: true
    }
  }, {
    path: "struct",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/carton-struct/index.vue"));
      });
    },
    name: "carton-struct",
    meta: {
      title: "Cấu trúc giấy",
      permission: true
    }
  }, {
    path: "provider-price",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/provider-carton-price/index.vue"));
      });
    },
    name: "carton-provider-price",
    meta: {
      title: "Giá gốc giấy tấm",
      permission: true
    }
  }, {
    path: "provider-product-price",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/provider-product-price/index.vue"));
      });
    },
    name: "carton-provider-product-price",
    meta: {
      title: "Giá gốc thùng carton",
      permission: true
    }
  }]
}, {
  path: "/vat",
  component: _index.default,
  redirect: "/vat/index",
  meta: {
    title: "Hóa đơn",
    icon: "file-invoice-dollar",
    permission: true
  },
  children: [{
    path: "v2",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/export-vat-v2/index.vue"));
      });
    },
    name: "export-vat-v2",
    meta: {
      title: "Xuất VAT v2",
      permission: true
    }
  }, {
    path: "search",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/search-vat/index.vue"));
      });
    },
    name: "search-vat-v2",
    meta: {
      title: "Tra cứu VAT",
      permission: true
    }
  }, {
    path: "sub",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sub-export-vat/index.vue"));
      });
    },
    name: "sub-vat",
    meta: {
      title: "Xuất hộ VAT",
      permission: true
    }
  }, {
    path: "quote",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/quote-product/index.vue"));
      });
    },
    name: "quote-product-index",
    meta: {
      title: "Báo giá sản phẩm",
      permission: true
    }
  }, {
    path: "other-quote",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/other-quote-product/index.vue"));
      });
    },
    name: "other-quote-product-index",
    meta: {
      title: "Báo giá SP (Khác)",
      permission: true
    }
  }, {
    path: "receipt",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/receipt/index.vue"));
      });
    },
    name: "receipt",
    meta: {
      title: "Hóa đơn bán lẻ",
      permission: true
    }
  }, {
    path: "other-receipt",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/other-receipt/index.vue"));
      });
    },
    name: "other-receipt",
    meta: {
      title: "HĐ bán lẻ (Khác)",
      permission: true
    }
  }, {
    path: "product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product/index.vue"));
      });
    },
    name: "product-index",
    meta: {
      title: "Sản phẩm",
      permission: true
    }
  }]
}, // {
//   path: "/setting",
//   component: Layout,
//   redirect: "/setting/index",
//   meta: {
//     title: "Cấu hình chung",
//     icon: "cog",
//     permission: false,
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/config-staff/index.vue"),
//       name: "setting-index",
//       meta: {
//         title: "Cấu hình chung",
//         permission: false,
//       },
//     },
//   ],
// },
{
  path: "/system",
  component: _index.default,
  redirect: "/sync/sync",
  meta: {
    title: "Cài đặt hệ thống",
    icon: "sync",
    permission: true
  },
  children: [{
    path: "sync",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sync-nhanh-data/index.vue"));
      });
    },
    name: "sync-index",
    meta: {
      title: "Đồng bộ",
      permission: true
    }
  }, {
    path: "import-price",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product-import-price/index.vue"));
      });
    },
    name: "inventory-import-price",
    meta: {
      title: "Cấu hình giá nhập kho",
      permission: true
    }
  }]
}, {
  path: "/woo",
  component: _index.default,
  redirect: "/woo/sync",
  meta: {
    title: "Woocommerce",
    icon: "sync",
    permission: true
  },
  children: [{
    path: "sync-price",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/woo-sync-price/index.vue"));
      });
    },
    name: "woo-sync-price",
    meta: {
      title: "Đồng bộ giá SP",
      permission: true
    }
  }, {
    path: "sync",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/woo-sync/index.vue"));
      });
    },
    name: "woo-sync",
    meta: {
      title: "Đồng bộ mã SP",
      permission: true
    }
  }]
}, {
  path: "/staff",
  component: _index.default,
  redirect: "/staff/employees",
  meta: {
    title: "Nhân viên",
    icon: "briefcase",
    permission: true
  },
  children: [{
    path: "employees",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/staff/index.vue"));
      });
    },
    name: "staff-employees",
    meta: {
      title: "Nhân viên",
      permission: true
    }
  }]
}, {
  path: "/profile",
  component: _index.default,
  redirect: "/profile/index",
  meta: {
    title: "Profile",
    icon: "qq",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index.vue"));
      });
    },
    name: "Profile",
    meta: {
      title: "profile",
      permission: false
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
exports.constantRoutes = constantRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0,
        x: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
}

var _default = router;
exports.default = _default;