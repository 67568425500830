"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "quote-print"
  }, [_c("div", [_vm.quote ? _c("div", {
    attrs: {
      id: "print"
    }
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "logo"
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: _vm.logo,
      alt: ""
    }
  })]), _vm._m(0)]), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày báo giá")]), _c("span", [_vm._v(_vm._s(_vm.currentDay))])]), _c("div", {
    staticClass: "info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số báo giá")]), _c("span", [_vm._v(_vm._s(_vm.numberQuote) + "-" + _vm._s(_vm.quote.no))])]), _c("div", {
    staticClass: "info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Hiệu lực đến")]), _c("span", [_vm._v(_vm._s(_vm.expireDay))])])])]), _c("div", {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Điện thoại: ")]), _c("span", [_vm._v(_vm._s(_vm.staff.phone))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Email: ")]), _c("span", [_vm._v(_vm._s(_vm.staff.email))])]), _vm._m(1), _c("h3", {
    staticClass: "text-center"
  }, [_vm._v("TÀI LIỆU BÁO GIÁ")]), _c("div", [_c("div", {
    staticClass: "customer-info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tên khách hàng: ")]), _c("span", [_vm._v(_vm._s(_vm.quote.customer.name))])]), _c("div", {
    staticClass: "customer-info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Công ty: ")]), _c("span", [_vm._v(_vm._s(_vm.quote.customer.company))])]), _c("div", {
    staticClass: "customer-info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Địa chỉ: ")]), _c("span", [_vm._v(_vm._s(_vm.quote.customer.address))])]), _c("div", {
    staticClass: "customer-info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Điện thoại: ")]), _c("span", [_vm._v(_vm._s(_vm.quote.customer.phone))])])]), _c("table", {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_vm._m(2), _c("tbody", [_vm._l(_vm.products, function (item, index) {
    return _c("tr", {
      key: index
    }, [_c("td", {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c("td", [_vm._v(_vm._s(item.name))]), _c("td", {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.note))]), _c("td", {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.quantity, 0, ".")) + " ")]), _c("td", {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.price, 0, ".")) + "đ ")]), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.totalPrice, 0, ".")) + "đ ")])]);
  }), _c("tr", {
    staticClass: "footer"
  }, [_c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("Cộng tiền hàng")]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.totalProductPrice, 0, ".")) + "đ ")])]), _vm.isVat ? [_c("tr", {
    staticClass: "footer"
  }, [_c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v(" Thuế giá trị gia tăng(8%) ")]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.totalProductPrice * 0.08, 0, ".")) + "đ ")])]), _c("tr", {
    staticClass: "footer"
  }, [_vm._m(3), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.totalProductPrice * 1.08, 0, ".")) + "đ ")])])] : [_c("tr", {
    staticClass: "footer"
  }, [_vm._m(4), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.totalProductPrice, 0, ".")) + "đ ")])])]], 2)]), _c("div", {
    staticClass: "note",
    staticStyle: {
      "font-weight": "bold",
      "margin-top": "32px",
      "text-decoration": "underline"
    }
  }, [_vm._v(" Ghi chú: ")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.note)
    }
  }), _c("div", {
    staticClass: "sign",
    staticStyle: {
      display: "flex",
      "justify-content": "space-around",
      "margin-top": "32px"
    }
  }, [_c("div", {
    staticClass: "left text-center"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người biên soạn")]), _c("br"), _c("span", [_vm._v("(Ký và ghi rõ họ tên)")]), _c("div", {
    staticStyle: {
      height: "150px"
    }
  }), _c("span", [_vm._v("Chức vụ: Nhân viên Kinh doanh")]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.staff.name))])]), _vm._m(5)])]) : _vm._e()])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "company text-center"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("CÔNG TY TNHH SẢN XUẤT VÀ THƯƠNG MẠI VIETBOX")]), _c("br"), _c("i", [_vm._v("27 Nguyễn Văn Vịnh, P.Hiệp Tân, Q.Tân Phú, TP.HCM")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Website: ")]), _c("span", [_vm._v("https://vietbox.vn/")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("thead", [_c("tr", [_c("th", [_vm._v("STT")]), _c("th", {
    staticClass: "text-left",
    staticStyle: {
      width: "250px"
    }
  }, [_vm._v("Sản phẩm")]), _c("th", {
    staticStyle: {
      width: "200px"
    }
  }, [_vm._v("Ghi chú")]), _c("th", [_vm._v("SL")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("Đơn giá")]), _c("th", {
    staticClass: "text-right",
    staticStyle: {
      width: "100px"
    }
  }, [_vm._v("Thành tiền")])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền thanh toán")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền thanh toán")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "right text-center"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người đại diện")]), _c("br"), _c("span", [_vm._v("(Ký và ghi rõ họ tên)")]), _c("div", {
    staticStyle: {
      height: "150px"
    }
  }), _c("span", [_vm._v("Chức vụ: Giám đốc")]), _c("br"), _c("span", [_vm._v("Nguyễn Quốc Thái")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;