"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày")]), _c("el-date-picker", {
    attrs: {
      format: "dd/MM/yyyy",
      type: "daterange"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhân viên")]), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "staffId", $$v);
      },
      expression: "query.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm kiếm")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Nhân viên",
      prop: "",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("img", {
          staticStyle: {
            "object-fit": "cover",
            "border-radius": "50%"
          },
          attrs: {
            src: _vm.$baseUrlMedia + row.staff.avatar,
            width: "40",
            height: "40",
            alt: ""
          }
        }), _c("br")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewCustomer(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.customer.name) + " - " + _vm._s(row.customer.phone) + " ")]), _c("br"), _c("span", [_vm._v(_vm._s(row.customer.address))]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tổng tiền: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.customer.totalPrice)) + "đ")])]), _c("div", {
          class: !row.customer.saleBK ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Băng keo: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.customer.saleBK, 0, "0")) + "đ")])]), _c("div", {
          class: !row.customer.saleMXH ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Xốp hơi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.customer.saleMXH, 0, "0")) + "đ")])]), _c("div", {
          class: !row.customer.saleMPE ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Màng PE: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.customer.saleMPE, 0, "0")) + "đ")])]), _c("div", {
          class: !row.customer.saleTCT ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Thùng carton: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.customer.saleTCT, 0, "0")) + "đ")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nội dung"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.content) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày chăm sóc"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " ")];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("CustomerModal", {
    ref: "CustomerModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;