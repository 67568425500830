"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductionLogTypeTrans = exports.ProductionLogType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _ProductionLogTypeTra;

var ProductionLogType;
exports.ProductionLogType = ProductionLogType;

(function (ProductionLogType) {
  ProductionLogType["Start"] = "START";
  ProductionLogType["End"] = "END";
  ProductionLogType["Report"] = "REPORT";
  ProductionLogType["ProductionWorkUpdateStatus"] = "PRODUCTION_WORK_UPDATE_STATUS";
  ProductionLogType["ProductionWorkUpdateDuration"] = "PRODUCTION_WORK_UPDATE_DURATION";
  ProductionLogType["UpdateTag"] = "UPDATE_TAG";
  ProductionLogType["UpdateWorkOrder"] = "UPDATE_WORK_ORDER";
  ProductionLogType["CreateProcess"] = "CREATE_PROCESS";
  ProductionLogType["UpdateProcess"] = "UPDATE_PROCESS";
  ProductionLogType["UpdateNoteWorkOrder"] = "UPDATE_NOTE_WORK_ORDER";
  ProductionLogType["UpdateNoteProductionWork"] = "UPDATE_NOTE_PRODUCTION_WORK";
})(ProductionLogType || (exports.ProductionLogType = ProductionLogType = {}));

var ProductionLogTypeTrans = (_ProductionLogTypeTra = {}, (0, _defineProperty2.default)(_ProductionLogTypeTra, ProductionLogType.ProductionWorkUpdateStatus, 'Cập nhật trạng thái'), (0, _defineProperty2.default)(_ProductionLogTypeTra, ProductionLogType.ProductionWorkUpdateDuration, 'Cập nhật thời gian làm việc'), (0, _defineProperty2.default)(_ProductionLogTypeTra, ProductionLogType.UpdateTag, 'Cập nhật thẻ'), (0, _defineProperty2.default)(_ProductionLogTypeTra, ProductionLogType.UpdateWorkOrder, 'Cập nhật LSX'), (0, _defineProperty2.default)(_ProductionLogTypeTra, ProductionLogType.CreateProcess, 'Lên kế hoạch'), (0, _defineProperty2.default)(_ProductionLogTypeTra, ProductionLogType.UpdateProcess, 'Đổi kế hoạch'), (0, _defineProperty2.default)(_ProductionLogTypeTra, ProductionLogType.UpdateNoteWorkOrder, 'Cập nhật ghi chú (LSX)'), (0, _defineProperty2.default)(_ProductionLogTypeTra, ProductionLogType.UpdateNoteProductionWork, 'Cập nhật ghi chú (lệnh làm việc)'), _ProductionLogTypeTra);
exports.ProductionLogTypeTrans = ProductionLogTypeTrans;