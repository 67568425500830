"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-upload", {
    staticClass: "upload-file",
    attrs: {
      action: _vm.$baseUrl + _vm.uploadUrl,
      headers: {
        token: _vm.token,
        version: _vm.version
      },
      "on-change": _vm.handleChange,
      "file-list": _vm.fileList,
      "on-success": _vm.handleSuccess,
      "before-upload": _vm.beforeUpload,
      drag: "",
      "on-remove": _vm.handleRemove
    }
  }, [_vm.isProgressing ? _c("el-progress", {
    attrs: {
      type: "circle",
      percentage: _vm.percentUpload
    }
  }) : [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v(" Kéo thả hoặc "), _c("em", [_vm._v("click vào để upload")])])], _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v(_vm._s(_vm.description))])], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;