"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("div", {
    staticClass: "category-chart"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      xs: 24,
      offset: 0
    }
  }, [_c("pie-chart", {
    ref: "amountChart",
    attrs: {
      legend: _vm.legend,
      formatter: _vm.formatter,
      series: _vm.seriesAmount,
      width: 500
    }
  }), _c("div", {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Doanh thu theo danh mục")])])], 1), _c("el-col", {
    attrs: {
      span: 12,
      xs: 24,
      offset: 0
    }
  }, [_c("pie-chart", {
    ref: "profitChat",
    attrs: {
      width: 500,
      formatter: _vm.formatter,
      legend: _vm.legend,
      series: _vm.seriesProfit
    }
  }), _c("div", {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lợi nhuận theo danh mục")])])], 1)], 1), _c("el-table", {
    staticStyle: {
      "margin-top": "32px"
    },
    attrs: {
      data: _vm.data,
      fit: "",
      "default-sort": {
        prop: "amount",
        order: "descending"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Danh mục",
      prop: "cateName",
      sortable: ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Doanh thu",
      sortable: "",
      prop: "amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amount)) + " đ ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Lợi nhuận",
      prop: "profit",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.profit)) + " đ ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      sortable: "",
      label: "% Lợi nhuận",
      align: "center",
      prop: "percent"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.percent) + "% ")];
      }
    }])
  })], 1)], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;