"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.deliveryTrips,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tên lệnh",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(row.name))]), row.misaTags.length ? [_c("div", _vm._l(row.misaTags, function (item, index) {
          return _c("MisaTagComp", {
            key: index,
            attrs: {
              misaTag: item
            }
          });
        }), 1)] : _vm._e(), _c("div", [row.isPE ? _c("el-tag", {
          attrs: {
            size: "normal",
            effect: "dark"
          }
        }, [_vm._v("Có quấn PE")]) : _vm._e()], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chuyến"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.parent ? _c("span", [_vm._v(" " + _vm._s(row.parent.name) + " " + _vm._s(row.parent.privateName ? "- ".concat(row.parent.privateName) : "") + " "), _c("br"), _vm._v(" " + _vm._s(_vm.$formatDate(row.parent.startAt)) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú",
      prop: "note"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số m3",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.totalCubicMeter.toFixed(1)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày giờ bắt đầu"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.startAt)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày giờ kết thúc"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.deliveryAt)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("OrderStatusLabel", {
          attrs: {
            statusCode: row.status,
            statusName: _vm.DeliveryTripStatusName[row.status]
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thời gian tạo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("div", [row.createdStaff ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tạo bởi: ")]), _c("span", [_vm._v(_vm._s(row.createdStaff.name))])]) : _vm._e(), _c("div", [_c("span", [_vm._v("Lúc: " + _vm._s(_vm.$formatDateTime(row.dateCreated)))])])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("div", [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" Cập nhật ")])], 1), _c("div", {
          staticStyle: {
            "margin-top": "4px"
          }
        }, [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onViewChange(row);
            }
          }
        }, [_vm._v(" LS thay đổi ")])], 1)];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;