"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "code"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Từ ngày ")]), _c("el-date-picker", {
    attrs: {
      format: "dd/MM/yyyy"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.fromAtDate,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "fromAtDate", $$v);
      },
      expression: "query.fromAtDate"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Trạng thái")]), _c("div", _vm._l(_vm.summaryStatus, function (item, index) {
    return _c("el-radio", {
      key: index,
      staticStyle: {
        display: "inline-block"
      },
      attrs: {
        label: item.status
      },
      on: {
        change: _vm.fetchData
      },
      model: {
        value: _vm.query.deliveryStatus,
        callback: function callback($$v) {
          _vm.$set(_vm.query, "deliveryStatus", $$v);
        },
        expression: "query.deliveryStatus"
      }
    }, [_vm._v(_vm._s(item.label) + " (" + _vm._s(item.total) + ")")]);
  }), 1)])]), _c("div", [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-refresh",
      loading: _vm.loadingSync
    },
    on: {
      click: _vm.handleSyncOrder
    }
  }, [_vm._v("Đồng bộ đơn hàng")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "default-sort": {
        prop: "startAt",
        order: "descending"
      }
    },
    on: {
      "sort-change": _vm.handleChangeSort
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Mã đơn",
      prop: "code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [!row.syncId ? _c("span", [_vm._v(" " + _vm._s(row.syncId) + " ")]) : _vm._e(), _c("el-link", {
          attrs: {
            type: "primary",
            href: "https://actapp.misa.vn/app/popup/SAOrderDetail/".concat(row.syncId)
          }
        }, [_vm._v(" " + _vm._s(row.code) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("OrderStatusLabel", {
          attrs: {
            statusCode: row.deliveryStatus,
            statusName: _vm.MisaDeliveryStatusName[row.deliveryStatus]
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mã: ")]), _c("span", [_vm._v(_vm._s(row.customerCode))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tên KH: ")]), _c("span", [_vm._v(_vm._s(row.customerName))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sản phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return _vm._l(row.msOrderDetails, function (item, index) {
          return _c("div", {
            key: index
          }, [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v(_vm._s(item.name))]), _c("br"), _c("span", [_vm._v("x " + _vm._s(item.quantity))]), index != row.msOrderDetails.length - 1 ? _c("div", {
            staticStyle: {
              height: "1px",
              width: "100%",
              background: "#eaeaea"
            }
          }) : _vm._e()]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày đơn hàng",
      prop: "startAt",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.startAt ? _vm.$formatDate(row.startAt) : "--") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày giao",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(row.endAt ? _vm.$formatDate(row.endAt) : "--") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Last Update"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.dateUpdated)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")]), _c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v("Xóa")])];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("MSOrderModal", {
    ref: "MSOrderModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;