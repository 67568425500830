"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [!_vm.error ? _c("WorkOrderInventoryForm", {
    attrs: {
      workOrderId: _vm.workOrderId,
      isHiddenName: true
    }
  }) : _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;