"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerProfileApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var _axios = _interopRequireDefault(require("axios"));

var customerProfileApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/customerProfile",
      params: params
    });
  },
  detail: function detail(id) {
    return (0, _request.default)({
      url: "/customerProfile/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/customerProfile",
      method: 'post',
      data: data
    });
  },
  createBatch: function createBatch(data, token) {
    return _axios.default.post('/customerProfile', data, {
      baseURL: process.env.VUE_APP_BASE_API,
      headers: {
        token: token
      }
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/customerProfile/".concat(id, "/update"),
      method: 'post',
      data: data
    });
  },
  block: function block(id) {
    return (0, _request.default)({
      url: "/customerProfile/".concat(id, "/block"),
      method: 'post'
    });
  },
  unblock: function unblock(id) {
    return (0, _request.default)({
      url: "/customerProfile/".concat(id, "/unblock"),
      method: 'post'
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/customerProfile/".concat(id, "/delete"),
      method: 'post'
    });
  },
  addNoShow: function addNoShow(id) {
    return (0, _request.default)({
      url: "/customerProfile/".concat(id, "/noShow/add"),
      method: 'post'
    });
  },
  removeNoShow: function removeNoShow(id) {
    return (0, _request.default)({
      url: "/customerProfile/".concat(id, "/noShow/remove"),
      method: 'post'
    });
  }
};
exports.customerProfileApi = customerProfileApi;