"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "",
      visible: _vm.visible,
      width: "1200px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Xem lệnh làm việc")]), _c("br"), _vm.productionMachine ? _c("span", [_vm._v(_vm._s(_vm.productionMachine.name))]) : _vm._e()]), _c("div", [_c("ProductionWorkTable", {
    attrs: {
      productionWorks: _vm.productionWorks,
      loading: _vm.loading,
      visibleAction: false
    }
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("span"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;