"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", {
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "In",
      name: "print"
    }
  }, [_c("quote-product-print", {
    attrs: {
      note: _vm.note,
      selectedQuotingOrder: _vm.selectedQuotingOrder,
      numExpireDay: _vm.numExpireDay,
      onUpdateNote: _vm.handleUpdateNote
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Cấu hình in",
      name: "config"
    }
  }, [_c("quote-product-config-print", {
    on: {
      "change-config": _vm.handleChangeConfig
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Lịch sử báo giá",
      name: "history"
    }
  }, [_c("QuotingOrderHistory", {
    attrs: {
      isFocused: _vm.tabName == "history"
    },
    on: {
      "choose-order": _vm.setSelectedQuotingOrder
    }
  })], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;