"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productionReportApi = void 0;

var _request = require("@/utils/request");

var productionReportApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/productionReport',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/productionReport',
      data: data,
      method: 'post'
    });
  },
  updateBeType: function updateBeType(data) {
    return (0, _request.request)({
      url: '/v1/admin/productionReport/updateBeType',
      data: data,
      method: 'patch'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/productionReport/".concat(id),
      data: data,
      method: 'patch'
    });
  },
  updateBlock: function updateBlock(id, isBlock) {
    return (0, _request.request)({
      url: "/v1/admin/productionReport/".concat(id, "/block"),
      data: {
        isBlock: isBlock
      },
      method: 'patch'
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionReport/".concat(id),
      method: 'delete'
    });
  }
};
exports.productionReportApi = productionReportApi;