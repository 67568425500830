"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Cấu hình tính giá V3")]), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Lợi nhuận(%) ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.profitConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.profitConfig, "value", $$v);
      },
      expression: "profitConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Đơn giá keo ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.priceKeoConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.priceKeoConfig, "value", $$v);
      },
      expression: "priceKeoConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Đơn giá dây ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.priceDayConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.priceDayConfig, "value", $$v);
      },
      expression: "priceDayConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Đơn giá ghim ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.priceGhimConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.priceGhimConfig, "value", $$v);
      },
      expression: "priceGhimConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Đơn giá mực in ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.priceInConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.priceInConfig, "value", $$v);
      },
      expression: "priceInConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Gia công/m2 ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.priceGiaCongConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.priceGiaCongConfig, "value", $$v);
      },
      expression: "priceGiaCongConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Vận chuyển/m2 ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.priceTransferConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.priceTransferConfig, "value", $$v);
      },
      expression: "priceTransferConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Phí vận chuyển tối thiểu ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.minShipFeeConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.minShipFeeConfig, "value", $$v);
      },
      expression: "minShipFeeConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Phí dán / thùng ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.pastePriceConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.pastePriceConfig, "value", $$v);
      },
      expression: "pastePriceConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Phí bế / tờ ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.bePriceConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.bePriceConfig, "value", $$v);
      },
      expression: "bePriceConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Phần trăm phụ phí bế / chi phí tổng cộng (%) ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px",
      "margin-left": "4px"
    },
    model: {
      value: _vm.cartonPhuPhiBePercentConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.cartonPhuPhiBePercentConfig, "value", $$v);
      },
      expression: "cartonPhuPhiBePercentConfig.value"
    }
  })], 1)]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loading
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("Lưu")])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;