"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "quote-item"
  }, [_c("div", {
    staticClass: "quote-box"
  }, [_c("div", {
    ref: "imgBox",
    staticClass: "img-box"
  }, [_c("el-image", {
    staticStyle: {
      "max-height": "100%"
    },
    attrs: {
      fit: "cover",
      src: _vm.$baseUrlMedia + _vm.quote.image
    }
  }, [_c("div", {
    staticClass: "image-slot",
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_c("i", {
    staticClass: "el-icon-picture-outline"
  })])])], 1), _c("div", {
    staticClass: "center"
  }, [_c("h3", {
    staticClass: "customer-name",
    on: {
      click: function click($event) {
        return _vm.$emit("click", _vm.quote);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.quote.customer.name) + " " + _vm._s(_vm.quote.customer.phone ? "- ".concat(_vm.quote.customer.phone) : "") + " ")]), _c("label", {
    staticStyle: {
      color: "#606266"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.CustomerQuoteTypeTrans[_vm.quote.customer.type]))]), _vm.quote.customer.implementationDate ? _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày triển khai: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatDate(_vm.quote.customer.implementationDate)))])]) : _vm._e(), _c("p", {
    staticClass: "customer-address"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Địa chỉ: ")]), _vm._v(_vm._s(_vm.quote.customer.address) + " ")]), _c("p", {
    staticClass: "customer-note"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Hoạt động cuối: ")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.lastActivity))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Bước tiếp: ")]), !_vm.todos.length ? _c("span", {
    staticStyle: {
      background: "#f02849",
      color: "white",
      "border-radius": "4px",
      "font-size": "14px",
      padding: "2px 4px"
    }
  }, [_vm._v("Chưa có")]) : _c("div", {
    staticClass: "todo-list"
  }, _vm._l(_vm.todos, function (item, index) {
    return _c("badge-todo-item", {
      key: index,
      attrs: {
        todo: item
      }
    });
  }), 1)]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày tạo deal: ")]), _c("span", [_vm._v(_vm._s(_vm.time))])])]), _c("div", {
    staticClass: "right"
  }, [_c("div", [_vm.quote.status == _vm.QuoteStatus.Success ? _c("el-button", {
    staticStyle: {
      "margin-bottom": "8px",
      "margin-right": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onClickCreateSchedule
    }
  }, [_vm._v("Lịch sản xuất")]) : _vm._e(), !_vm.quote.isDeleted ? _c("el-button", {
    staticStyle: {
      "margin-bottom": "8px",
      "margin-right": "8px"
    },
    attrs: {
      loading: _vm.quote.loadingDelete,
      type: "",
      size: "mini",
      icon: "el-icon-delete"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("delete", _vm.quote);
      }
    }
  }, [_vm._v("Xóa")]) : _vm._e(), _c("el-dropdown", {
    attrs: {
      trigger: "hover",
      size: "default",
      type: ""
    }
  }, [_c("el-button", {
    attrs: {
      type: "",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._v("In")]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$emit("print-vat", _vm.quote);
      }
    }
  }, [_vm._v(" In có VAT (Gửi khách hàng) ")])]), _c("el-dropdown-item", [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$emit("print", _vm.quote);
      }
    }
  }, [_vm._v(" In không VAT (Gửi khách hàng) ")])]), _c("el-dropdown-item", [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$emit("print-ncc", _vm.quote);
      }
    }
  }, [_vm._v(" In đặt hàng (Gửi NCC) ")])])], 1)], 1)], 1), _c("div", {
    staticClass: "status text-center"
  }, [_c("label", {
    style: {
      color: _vm.QuoteStatusColor[_vm.quote.status]
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.QuoteStatusTrans[_vm.quote.status]))]), _c("br"), _c("span", {
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v(_vm._s(_vm.quote.staff.name))])]), _c("div", {
    staticClass: "price"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.quote.totalPrice)) + "đ")])])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;