"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var orderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/order",
      params: params
    });
  },
  findAllDetails: function findAllDetails(params) {
    return (0, _request.default)({
      url: "/v1/admin/order/details",
      params: params
    });
  },
  findVat: function findVat(params) {
    return (0, _request.default)({
      url: "/v1/admin/order/vat",
      params: params
    });
  },
  findVatV2: function findVatV2(params) {
    return (0, _request.default)({
      url: "/v1/admin/order/vat/v2",
      params: params
    });
  },
  confirmExportVat: function confirmExportVat(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/confirm-export-vat"),
      data: data,
      method: 'post'
    });
  },
  sync: function sync(data) {
    return (0, _request.default)({
      url: "/v1/admin/order/craw",
      method: 'post',
      data: data
    });
  },
  syncOne: function syncOne(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/order/craw/".concat(id),
      method: 'post',
      data: data
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/order",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/delete"),
      method: "post"
    });
  },
  delivering: function delivering(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/delivering"),
      method: "post"
    });
  },
  complete: function complete(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/complete"),
      method: "post"
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/cancel"),
      method: "post"
    });
  },
  exportVat: function exportVat(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/export-vat"),
      method: "post"
    });
  }
};
exports.orderApi = orderApi;