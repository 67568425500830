"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerTags = exports.customerTagTrans = exports.customerTagStyles = exports.CustomerTag = exports.CustomerLogType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _customerTagStyles, _customerTagTrans;

var CustomerTag;
exports.CustomerTag = CustomerTag;

(function (CustomerTag) {
  CustomerTag["ToExpensive"] = "TO_EXPENSIVE";
  CustomerTag["LongDebt"] = "LONG_DEBT";
})(CustomerTag || (exports.CustomerTag = CustomerTag = {}));

var customerTags = [{
  value: CustomerTag.ToExpensive,
  label: "Chê giá cao"
}, {
  value: CustomerTag.LongDebt,
  label: "Nợ dai"
}];
exports.customerTags = customerTags;
var customerTagStyles = (_customerTagStyles = {}, (0, _defineProperty2.default)(_customerTagStyles, CustomerTag.ToExpensive, {
  backgroundColor: '#ff4949',
  labelColor: '#fff'
}), (0, _defineProperty2.default)(_customerTagStyles, CustomerTag.LongDebt, {
  backgroundColor: '#ffbd35',
  labelColor: '#fff'
}), _customerTagStyles);
exports.customerTagStyles = customerTagStyles;
var customerTagTrans = (_customerTagTrans = {}, (0, _defineProperty2.default)(_customerTagTrans, CustomerTag.ToExpensive, 'Chê giá cao'), (0, _defineProperty2.default)(_customerTagTrans, CustomerTag.LongDebt, 'Nợ dai'), _customerTagTrans);
exports.customerTagTrans = customerTagTrans;
var CustomerLogType;
exports.CustomerLogType = CustomerLogType;

(function (CustomerLogType) {
  CustomerLogType["System"] = "SYSTEM";
  CustomerLogType["User"] = "USER";
})(CustomerLogType || (exports.CustomerLogType = CustomerLogType = {}));