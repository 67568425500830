"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MisaQuoteStageTrans = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _MisaQuoteStageTrans;

var MisaQuoteStageTrans = (_MisaQuoteStageTrans = {}, (0, _defineProperty2.default)(_MisaQuoteStageTrans, 1, 'Bản thảo'), (0, _defineProperty2.default)(_MisaQuoteStageTrans, 4, 'Chờ xác nhận'), (0, _defineProperty2.default)(_MisaQuoteStageTrans, 6, 'Đồng ý'), (0, _defineProperty2.default)(_MisaQuoteStageTrans, 7, 'Từ chối'), _MisaQuoteStageTrans);
exports.MisaQuoteStageTrans = MisaQuoteStageTrans;