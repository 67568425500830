"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container carton-struct-page"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phiên bản giá")]), _c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Chưa chọn phiên bản",
      clearable: ""
    },
    on: {
      change: _vm.fetchCartonStructs
    },
    model: {
      value: _vm.query.version,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "version", $$v);
      },
      expression: "query.version"
    }
  }, _vm._l(_vm.versions, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: "Phi\xEAn b\u1EA3n ".concat(item),
        value: item
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm mới")])], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreateVersion
    }
  }, [_vm._v("Thêm mới phiên bản giá")])], 1)]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      disabled: !_vm.query.version
    },
    on: {
      click: _vm.handleUpdateVersion
    }
  }, [_vm._v("Chỉnh sửa phiên bản giá")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.cartonStructs,
      fit: "",
      "row-class-name": _vm.tableRowClassName
    },
    on: {
      "row-click": _vm.handleUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Kết cấu giấy (Khách hàng)",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Kết cấu giấy (Kỹ thuật)",
      prop: "subName"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Kết cấu giấy (Dễ hiểu)",
      prop: "description"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Phiên bản"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.version) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá tiền"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.price)) + " đ/m"), _c("sup", [_vm._v("2")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Phiên bản (Gốc)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.mainVersion ? _c("span", [_vm._v(" " + _vm._s(row.mainVersion) + " ")]) : _c("span", [_vm._v(" -- ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá tiền (Gốc)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.mainPrice ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.mainPrice)) + " đ/m"), _c("sup", [_vm._v("2")])]) : _c("span", [_vm._v(" -- ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lớp",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.layer) + " lớp ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Định lượng",
      prop: "weight"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.weight)) + " gram ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số KG tối đa",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.maxWeight) + " Kg ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số m2",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(" " + _vm._s(row.minM2) + "~" + _vm._s(row.maxM2) + " m2")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú",
      prop: "note"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Hiển thị",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("div", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return false.apply(null, arguments);
            }
          }
        }, [_c("el-checkbox", {
          on: {
            change: function change($event) {
              return _vm.changeIsShow(row);
            }
          },
          model: {
            value: row.isShow,
            callback: function callback($$v) {
              _vm.$set(row, "isShow", $$v);
            },
            expression: "row.isShow"
          }
        })], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hiển thị (Báo giá)",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("div", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return false.apply(null, arguments);
            }
          }
        }, [_c("el-checkbox", {
          on: {
            change: function change($event) {
              return _vm.changeVisibleInQuote(row);
            }
          },
          model: {
            value: row.isVisibleInQuote,
            callback: function callback($$v) {
              _vm.$set(row, "isVisibleInQuote", $$v);
            },
            expression: "row.isVisibleInQuote"
          }
        })], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return _vm.handleSetMain(row);
            }
          }
        }, [_vm._v(" Chọn làm bản gốc ")])];
      }
    }])
  })], 1), _c("carton-struct-modal", {
    ref: "CartonStructModal",
    on: {
      "submit:ok": _vm.fetchCartonStructs
    }
  }), _c("CartonStructPriceModal", {
    ref: "CartonStructPriceModal",
    on: {
      submitOk: function submitOk() {
        _vm.fetchCartonStructs();

        _vm.fetchLastVersion();
      }
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;