"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "truck-tour-list"
  }, [_c("h3", [_vm._v("Danh sách chuyến")]), _c("div", {
    staticStyle: {
      display: "flex",
      "overflow-x": "scroll"
    }
  }, _vm._l(_vm.truckTours, function (item) {
    return _c("truck-tour-item", {
      key: item.id,
      attrs: {
        truckTour: item,
        onClick: _vm.onClick
      }
    });
  }), 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;