"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateCartonName = void 0;

require("core-js/modules/es.array.concat.js");

var _Product = require("@/types/Product");

var generateCartonName = function generateCartonName(_ref) {
  var width = _ref.width,
      height = _ref.height,
      length = _ref.length,
      waterProofFaceType = _ref.waterProofFaceType,
      printCost = _ref.printCost;

  if (length && width && height) {
    return "Th\xF9ng carton ".concat(printCost ? "(In)" : "").concat(length, "x").concat(width, "x").concat(height, " ").concat(waterProofFaceType ? "(Ch\u1ED1ng th\u1EA5m: ".concat(_Product.WaterProofFaceTypeTrans[waterProofFaceType], ")") : '');
  }

  return '';
};

exports.generateCartonName = generateCartonName;