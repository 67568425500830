"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merchantApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var _index = require("./index");

var baseURL = _index.baseUrlCustomer;
var headers = {
  token: '',
  from: 'customer'
};
var merchantApi = {
  findOne: function findOne(id) {
    return (0, _request.default)({
      headers: headers,
      url: "/v1/customer/merchant/".concat(id),
      baseURL: baseURL
    });
  }
};
exports.merchantApi = merchantApi;