"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merchantApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var merchantApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/merchant",
      params: params
    });
  },
  findOne: function findOne() {
    return (0, _request.default)({
      url: "/merchant"
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/merchant/",
      data: data,
      method: "post"
    });
  },
  update: function update(data) {
    return (0, _request.default)({
      url: "/merchant/update",
      data: data,
      method: "post"
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/merchant/".concat(id),
      method: 'post'
    });
  }
};
exports.merchantApi = merchantApi;