"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("div", {
    staticClass: "todo-item"
  }, [_c("el-checkbox", {
    attrs: {
      disabled: _vm.item.isDone,
      value: _vm.item.isDone
    },
    on: {
      change: _vm.handleCheck
    }
  }), _c("div", {
    staticClass: "right",
    on: {
      click: _vm.handleEdit
    }
  }, [!_vm.visibleEdit ? [_c("span", {
    staticClass: "text-color--black",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.item.content))]), _vm.item.dueDate ? _c("div", {
    staticClass: "due-date",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_c("i", [_c("i", {
    staticClass: "el-icon-timer"
  }), _c("span", {}, [_vm._v(_vm._s(_vm.$formatDateTime(_vm.item.dueDate)) + " ")])])]) : _vm._e()] : _c("div", [_c("todo-compose", {
    ref: "TodoCompose",
    attrs: {
      visibleDelete: true
    },
    on: {
      save: _vm.handleSave,
      cancel: function cancel($event) {
        _vm.visibleEdit = false;
      },
      delete: _vm.handleDelete
    }
  })], 1)], 2)], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;