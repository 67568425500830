"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "panel-shadow",
    staticStyle: {
      width: "550px",
      margin: "0 auto"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm đơn")]), _c("el-input", {
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchOrder.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "small",
      loading: _vm.loading
    },
    on: {
      click: _vm.fetchOrder
    }
  }, [_vm._v("Tìm")]), !_vm.isPreviewing ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-view",
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.isPreviewing = true;
      }
    }
  }, [_vm._v("Xem trước")]) : _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-close",
      size: "small",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.isPreviewing = false;
      }
    }
  }, [_vm._v("Hủy xem trước")])], 1)], 1), _c("el-switch", {
    staticStyle: {
      display: "block"
    },
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ff4949",
      "active-text": "Form khác",
      "inactive-text": "Form công ty"
    },
    model: {
      value: _vm.otherReceipt,
      callback: function callback($$v) {
        _vm.otherReceipt = $$v;
      },
      expression: "otherReceipt"
    }
  }), _c("div", {
    staticStyle: {
      background: "#fff",
      "margin-top": "32px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [!_vm.otherReceipt ? [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_vm.isPreviewing ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.print1
    }
  }, [_vm._v("In")]) : _vm._e(), _vm.visibleR1 ? _c("receipt-print", {
    ref: "r1",
    attrs: {
      order: _vm.order,
      preview: _vm.isPreviewing,
      products: _vm.products,
      form: _vm.form,
      type: 1
    }
  }) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_vm.isPreviewing ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.print2
    }
  }, [_vm._v("In")]) : _vm._e(), _c("receipt-print", {
    ref: "r2",
    attrs: {
      type: 2,
      form: _vm.form,
      order: _vm.order,
      preview: _vm.isPreviewing,
      products: _vm.products
    }
  })], 1)] : [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_vm.isPreviewing ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.print1
    }
  }, [_vm._v("In")]) : _vm._e(), _c("receipt-print", {
    ref: "r1",
    attrs: {
      visibleLogo: false,
      type: 2,
      form: _vm.form,
      order: _vm.order,
      preview: _vm.isPreviewing,
      products: _vm.products
    }
  })], 1)]], 2)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;