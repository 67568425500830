"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ecountApi = void 0;

var _request = require("@/utils/request");

var ecountApi = {
  createProduct: function createProduct(data) {
    return (0, _request.request)({
      url: '/v1/admin/ecount/product',
      data: data,
      method: 'post'
    });
  }
};
exports.ecountApi = ecountApi;