"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deliveryTripApi = void 0;

var _request = require("@/utils/request");

var deliveryTripApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/deliveryTrip',
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/v1/admin/deliveryTrip/".concat(id)
    });
  },
  findGroupByProduct: function findGroupByProduct(params) {
    return (0, _request.request)({
      url: '/v1/admin/deliveryTrip/group/product',
      params: params
    });
  },
  findNeed: function findNeed(params) {
    return (0, _request.request)({
      url: '/v1/admin/deliveryTrip/need',
      params: params
    });
  },
  findGroupByTrip: function findGroupByTrip(params) {
    return (0, _request.request)({
      url: '/v1/admin/deliveryTrip/group/trip',
      params: params
    });
  },
  createOrUpdateTrip: function createOrUpdateTrip(data) {
    return (0, _request.request)({
      url: '/v1/admin/deliveryTrip/trip',
      data: data,
      method: 'post'
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/deliveryTrip',
      data: data,
      method: 'post'
    });
  },
  updateDetails: function updateDetails(data) {
    return (0, _request.request)({
      url: "/v1/admin/deliveryTrip/details",
      method: 'patch',
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/deliveryTrip/".concat(id),
      method: 'patch',
      data: data
    });
  },
  updateStatus: function updateStatus(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/deliveryTrip/".concat(id, "/status"),
      method: 'patch',
      data: data
    });
  },
  updateExport: function updateExport(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/deliveryTrip/".concat(id, "/export"),
      method: 'patch',
      data: data
    });
  },
  mergeDate: function mergeDate(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/deliveryTrip/".concat(id, "/mergeDate"),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/deliveryTrip/".concat(id),
      method: 'delete'
    });
  }
};
exports.deliveryTripApi = deliveryTripApi;