"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _echarts = _interopRequireDefault(require("echarts"));

var _resize = _interopRequireDefault(require("./mixins/resize"));

require("echarts/theme/macarons"); // echarts theme


var _default = {
  mixins: [_resize.default],
  props: {
    legend: Array,
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "350px"
    },
    series: Array,
    formatter: {
      type: String | Function,
      default: "{a} <br/>{b} : {c} ({d}%)"
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    resize: function resize() {
      this.chart.resize();
    },
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "item",
          formatter: this.formatter
        },
        legend: {
          left: "center",
          bottom: "0",
          data: this.legend // string[]

        },
        series: this.series
      });
    }
  }
};
exports.default = _default;