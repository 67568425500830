"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", {
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, _vm._l(_vm.tabs, function (item) {
    return _c("el-tab-pane", {
      key: item.categoryCode,
      attrs: {
        lazy: "",
        label: item.name,
        name: item.categoryCode
      }
    }, [_c("ProductList", {
      attrs: {
        productCategoryCode: item.categoryCode
      },
      on: {
        updateProviderNote: _vm.handleUpdateProviderNote
      }
    })], 1);
  }), 1), _c("UpdateProviderNoteModal", {
    ref: "UpdateProviderNoteModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;