"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staffConfigApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var staffConfigApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/config",
      params: params
    });
  },
  findAllGeneral: function findAllGeneral(params) {
    return (0, _request.default)({
      url: "/v1/admin/config/general",
      params: params
    });
  },
  findGeneralByParam: function findGeneralByParam(params) {
    return (0, _request.default)({
      url: "/v1/admin/config/general/param",
      params: params
    });
  },
  findByParam: function findByParam(params) {
    return (0, _request.default)({
      url: "/v1/admin/config/param",
      params: params
    });
  },
  update: function update(staffConfigId, data) {
    return (0, _request.default)({
      url: "/v1/admin/config/".concat(staffConfigId),
      data: data,
      method: 'PUT'
    });
  },
  updateGeneral: function updateGeneral(configId, data) {
    return (0, _request.default)({
      url: "/v1/admin/config/general/".concat(configId),
      data: data,
      method: 'PUT'
    });
  }
};
exports.staffConfigApi = staffConfigApi;