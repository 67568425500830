"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Hoàn thành task",
      visible: _vm.visible,
      width: "1000px",
      top: "30px",
      "close-on-click-modal": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm.task.customer ? _c("span", {
          staticClass: "el-dialog__title"
        }, [_vm._v(" " + _vm._s(_vm.task.customer.name) + " - " + _vm._s(_vm.task.customer.phone) + " - " + _vm._s(_vm.TaskTypeTrans[_vm.task.type]) + " ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFetch,
      expression: "loadingFetch"
    }],
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 10,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ghi chú khách hàng",
      prop: "note"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  }), _c("div", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.task.note))])])], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Điểm hoàn thành"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.TaskScore.Bad
    },
    model: {
      value: _vm.form.score,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "score", $$v);
      },
      expression: "form.score"
    }
  }, [_vm._v(" Chưa tốt ")]), _c("el-radio", {
    attrs: {
      label: _vm.TaskScore.Good
    },
    model: {
      value: _vm.form.score,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "score", $$v);
      },
      expression: "form.score"
    }
  }, [_vm._v(" Hoàn thành tốt ")])], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khác"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.form.isSell,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isSell", $$v);
      },
      expression: "form.isSell"
    }
  }, [_vm._v("Đã bán chéo")]), _c("el-checkbox", {
    model: {
      value: _vm.form.isRef,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isRef", $$v);
      },
      expression: "form.isRef"
    }
  }, [_vm._v("Đã giới thiệu")]), _c("el-checkbox", {
    model: {
      value: _vm.form.isAddFriendZalo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isAddFriendZalo", $$v);
      },
      expression: "form.isAddFriendZalo"
    }
  }, [_vm._v("Đã kết bạn")])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Block khách hàng"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.form.isIgnoreTask,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isIgnoreTask", $$v);
      },
      expression: "form.isIgnoreTask"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày mua hàng tiếp theo"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      format: "dd/MM/yyyy"
    },
    model: {
      value: _vm.form.nextBuyAt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nextBuyAt", $$v);
      },
      expression: "form.nextBuyAt"
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 14,
      offset: 0
    }
  }, [_c("CustomerLog", {
    ref: "CustomerLog",
    attrs: {
      customerId: _vm.form.customerId
    }
  })], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("div", [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateAndComplete
    }
  }, [_vm._v("Lưu và hoàn thành")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Lưu")])], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;