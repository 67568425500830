"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cartonStructPriceApi = void 0;

var _request = require("@/utils/request");

var cartonStructPriceApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/cartonStructPrice',
      params: params
    });
  },
  lastVersion: function lastVersion() {
    return (0, _request.request)({
      url: '/v1/admin/cartonStructPrice/lastVersion'
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/cartonStructPrice',
      data: data,
      method: 'post'
    });
  },
  updateMain: function updateMain(id) {
    return (0, _request.request)({
      url: "/v1/admin/cartonStructPrice/".concat(id, "/main"),
      method: 'patch'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/cartonStructPrice/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/cartonStructPrice/".concat(id),
      method: 'delete'
    });
  }
};
exports.cartonStructPriceApi = cartonStructPriceApi;