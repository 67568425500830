"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "misa-activity-calendar-item"
  }, [_c("el-tag", [_vm._v(" " + _vm._s(_vm.layoutName) + " ")]), _c("div", {
    staticClass: "activity-name"
  }, [_c("span", {
    class: {
      "line-through": _vm.misaActivity.statusId == 4
    }
  }, [_vm._v(" " + _vm._s(_vm.misaActivity.name) + " ")])])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;