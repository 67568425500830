"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.taskApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var taskApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/admin/task',
      params: params
    });
  },
  findAllV2: function findAllV2(params) {
    return (0, _request.default)({
      url: '/v1/admin/task/v2',
      params: params
    });
  },
  findAllV4: function findAllV4(params) {
    return (0, _request.default)({
      url: '/v1/admin/task/v4',
      params: params
    });
  },
  findOne: function findOne(taskId) {
    return (0, _request.default)({
      url: "/v1/admin/task/".concat(taskId)
    });
  },
  delete: function _delete(data) {
    return (0, _request.default)({
      url: '/v1/admin/task',
      data: data,
      method: 'delete'
    });
  },
  generateV2: function generateV2() {
    return (0, _request.default)({
      url: '/v1/admin/task/generate/v2',
      method: 'post'
    });
  },
  report: function report(params) {
    return (0, _request.default)({
      url: '/v1/admin/task/report',
      params: params
    });
  },
  reportV2: function reportV2(params) {
    return (0, _request.default)({
      url: '/v1/admin/task/report/v2',
      params: params
    });
  },
  findOrders: function findOrders(params) {
    return (0, _request.default)({
      url: '/v1/admin/task/order',
      params: params
    });
  },
  //CREATE
  createTask: function createTask(data) {
    return (0, _request.default)({
      url: '/v1/admin/task',
      data: data,
      method: 'post'
    });
  },
  createSellTask: function createSellTask(data) {
    return (0, _request.default)({
      url: '/v1/admin/task/sell',
      data: data,
      method: 'post'
    });
  },
  createBuyMoreTask: function createBuyMoreTask(data) {
    return (0, _request.default)({
      url: '/v1/admin/task/buy-more',
      data: data,
      method: 'post'
    });
  },
  update: function update(taskId, data) {
    return (0, _request.default)({
      url: "/v1/admin/task/".concat(taskId),
      data: data,
      method: 'put'
    });
  },
  //COMPLETE
  complete: function complete(taskId) {
    return (0, _request.default)({
      url: "/v1/admin/task/".concat(taskId, "/complete"),
      method: 'put'
    });
  }
};
exports.taskApi = taskApi;