"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật quy trình" : "Thêm mới quy trình",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên quy trình",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nhân viên",
      prop: "staffIds"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.form.staffIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffIds", $$v);
      },
      expression: "form.staffIds"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-table-draggable", {
    attrs: {
      animate: 300
    },
    on: {
      drop: _vm.onSort
    }
  }, [_c("el-table", {
    attrs: {
      "row-key": "id",
      data: _vm.steps,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var $index = _ref.$index;
        return [_vm._v(" " + _vm._s($index + 1) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Bước"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-select", {
          attrs: {
            filterable: ""
          },
          model: {
            value: row.stepId,
            callback: function callback($$v) {
              _vm.$set(row, "stepId", $$v);
            },
            expression: "row.stepId"
          }
        }, _vm._l(_vm.productionSteps, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: item.name,
              value: item.id
            }
          });
        }), 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "100",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var $index = _ref3.$index;
        return [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete($index);
            }
          }
        })];
      }
    }])
  })], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAddStep
    }
  }, [_vm._v("Thêm dòng")])], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;