"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      size: "medium",
      placeholder: "Tìm nội dung, sđt khách"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhân viên")]), _c("el-select", {
    attrs: {
      size: "medium",
      clearable: "",
      filterable: ""
    },
    on: {
      change: function change($event) {
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "staffId", $$v);
      },
      expression: "query.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Thời gian ")]), _c("el-date-picker", {
    attrs: {
      type: "daterange",
      clearable: false,
      format: "dd/MM/yyyy"
    },
    on: {
      change: function change($event) {
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)]), _c("MisaCustomerSummaryLog", {
    attrs: {
      summaryStaff: _vm.summaryStaff
    }
  }), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Nội dung"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.content) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.misaCustomer ? [_vm.buyTypeContent(row.misaCustomer) == "SOON" ? _c("div", {
          staticClass: "customer-badge soon-buy"
        }, [_c("img", {
          attrs: {
            src: _vm.comingSoonImg,
            width: "25",
            alt: ""
          }
        }), _c("span", {
          staticClass: "title"
        }, [_vm._v("Sắp mua")])]) : _vm.buyTypeContent(row.misaCustomer) == "LONG" ? _c("div", {
          staticClass: "customer-badge long-buy"
        }, [_c("img", {
          attrs: {
            src: _vm.longTermImg,
            width: "25",
            alt: ""
          }
        }), _c("span", {
          staticClass: "title"
        }, [_vm._v("Lâu chưa mua")])]) : _vm._e(), _c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(row.misaCustomer.name) + " - " + _vm._s(row.misaCustomer.tel) + " ")]), _c("br"), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tổng tiền đơn hàng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.misaCustomer.orderSales || 0)) + "đ")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Số đơn hàng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.misaCustomer.numOfOrders || 0)))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tổng tiền báo giá: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.misaCustomer.totalSaleQuote || 0)) + "đ")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Số báo giá: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.misaCustomer.totalQuote || 0)))])])] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thông tin mua hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.misaCustomer ? [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Chu kỳ mua: ")]), row.misaCustomer.purchaseDay || row.misaCustomer.avgDayBetweenPurchase ? _c("span", [_vm._v(" " + _vm._s(row.misaCustomer.purchaseDay || row.misaCustomer.avgDayBetweenPurchase) + " ngày ")]) : _vm._e()]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Số ngày chưa mua: ")]), row.misaCustomer.dayWithoutPurchase ? _c("span", [_vm._v(" " + _vm._s(row.misaCustomer.dayWithoutPurchase) + " ngày ")]) : _vm._e()]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mua lần cuối: ")]), row.misaCustomer.purchaseRecentDate ? _c("span", [_vm._v(" " + _vm._s(_vm.$stringToDate(row.misaCustomer.purchaseRecentDate)) + " (" + _vm._s(_vm.fromAgo(row.misaCustomer.purchaseRecentDate)) + ") ")]) : _vm._e()])] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nhân viên"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.staff ? _c("div", [_c("img", {
          staticStyle: {
            "object-fit": "cover",
            "border-radius": "50%"
          },
          attrs: {
            src: row.staff.avatar ? _vm.$baseUrlMedia + row.staff.avatar : _vm.defaultAvatar,
            width: "40",
            height: "40",
            alt: ""
          }
        }), _c("br"), _c("span", [_vm._v(" " + _vm._s(row.staff.name) + " ")])]) : row.misaEmployee ? _c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(" " + _vm._s(row.misaEmployee.name) + " (" + _vm._s(row.misaEmployee.code) + ") ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày tạo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.fromAgo(row.createdDate)) + " ")];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;