"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaInventoryApi = void 0;

var _request = require("@/utils/request");

var misaInventoryApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaInventory',
      params: params
    });
  },
  findAvailable: function findAvailable(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaInventory/available',
      params: params
    });
  },
  findBanIn: function findBanIn(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaInventory/banIn',
      params: params
    });
  },
  findKhuonBe: function findKhuonBe(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaInventory/khuonBe',
      params: params
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/misaInventory/".concat(id),
      method: 'patch',
      data: data
    });
  }
};
exports.misaInventoryApi = misaInventoryApi;