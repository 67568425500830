"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DictionaryType = void 0;
var DictionaryType;
exports.DictionaryType = DictionaryType;

(function (DictionaryType) {
  DictionaryType["WarehouseStaff"] = "WAREHOUSE_STAFF";
})(DictionaryType || (exports.DictionaryType = DictionaryType = {}));