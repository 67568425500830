"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [!_vm.isNotFoundProduct ? _c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingProduct,
      expression: "loadingProduct"
    }],
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Thành phẩm",
      prop: "misaInventoryProductId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      disabled: _vm.status == "update",
      "remote-method": _vm.fetchProducts
    },
    model: {
      value: _vm.form.misaInventoryProductId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaInventoryProductId", $$v);
      },
      expression: "form.misaInventoryProductId"
    }
  }, _vm._l(_vm.products, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.code,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "NVL",
      prop: "misaInventoryMaterialId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.fetchMaterials
    },
    model: {
      value: _vm.form.misaInventoryMaterialId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaInventoryMaterialId", $$v);
      },
      expression: "form.misaInventoryMaterialId"
    }
  }, _vm._l(_vm.materials, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.code,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Định lượng NVL"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.rate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rate", $$v);
      },
      expression: "form.rate"
    }
  })], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tồn tối thiểu (Mua hàng)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.minQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "minQuantity", $$v);
      },
      expression: "form.minQuantity"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tồn tối thiểu (Sản xuất)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.minQuantityProduce,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "minQuantityProduce", $$v);
      },
      expression: "form.minQuantityProduce"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Sản phẩm nhanh.vn"
    }
  }, [_c("el-select", {
    attrs: {
      remote: "",
      "remote-method": _vm.fetchNhanhProducts,
      filterable: "",
      clearable: ""
    },
    model: {
      value: _vm.form.productId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "productId", $$v);
      },
      expression: "form.productId"
    }
  }, _vm._l(_vm.nhanhProducts, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.id, " - ").concat(item.name),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("label", {
    staticStyle: {
      color: "#000",
      "font-size": "18px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Mua hàng")])]), _c("el-form-item", {
    attrs: {
      label: "Số lượng khi mua hàng",
      prop: "buyQuantity"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.buyQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "buyQuantity", $$v);
      },
      expression: "form.buyQuantity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Ghi chú khi mua hàng",
      prop: "buyQuantity"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.buyNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "buyNote", $$v);
      },
      expression: "form.buyNote"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Loại hàng"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.MisaProductType.Sale
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Buôn")]), _c("el-radio", {
    attrs: {
      label: _vm.MisaProductType.Produce
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Sản xuất")])], 1)], 1) : _c("div", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("Không tìm thấy sản phẩm trong kho")]), _c("br"), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.handleOpenLink
    }
  }, [_vm._v("Đồng bộ ngay")])], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), !_vm.isNotFoundProduct ? _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")]) : _vm._e()], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;