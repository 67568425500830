"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Danh sách câu",
      visible: _vm.visible,
      fullscreen: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-button", {
    staticClass: "filter-item font-bold font-size--medium",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" Thêm câu ")])], 1), _c("div", {
    staticClass: "list"
  }, [_c("el-table", {
    attrs: {
      data: _vm.answers,
      fit: ""
    },
    on: {
      "row-click": _vm.handleUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tiêu đề"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", {
          staticStyle: {
            display: "inline-block",
            padding: "4px",
            "border-radius": "4px"
          },
          style: {
            color: row.textColor ? row.textColor : "#000",
            background: row.bgColor
          }
        }, [_vm._v(_vm._s(row.title))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Danh mục",
      prop: "category.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tin nhắn",
      prop: "content"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", [_c("pre", [_vm._v(_vm._s(row.content))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-tooltip", {
          attrs: {
            content: "Xóa",
            placement: "top",
            effect: "dark"
          }
        }, [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleDelete(row);
            }
          }
        })], 1)];
      }
    }])
  })], 1)], 1), _c("add-answer-modal", {
    ref: "AddAnswerModal",
    on: {
      "submit:ok": _vm.fetchAnswers
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;