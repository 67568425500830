"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Buôn",
      name: "product"
    }
  }, [_c("BuyTodayTab", {
    attrs: {
      type: _vm.MisaProductType.Sale
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Sản xuất",
      name: "nvl"
    }
  }, [_c("BuyTodayTab", {
    attrs: {
      type: _vm.MisaProductType.Produce
    }
  })], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;