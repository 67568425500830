"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.truckTourApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var truckTourApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/truckTour",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/truckTour",
      data: data,
      method: 'post'
    });
  },
  update: function update(truckTourId, data) {
    return (0, _request.default)({
      url: "/v1/admin/truckTour/".concat(truckTourId, "/update"),
      method: 'put',
      data: data
    });
  },
  delete: function _delete(truckTourId) {
    return (0, _request.default)({
      url: "/v1/admin/truckTour/".concat(truckTourId, "/delete"),
      method: 'delete'
    });
  },
  complete: function complete(truckTourId) {
    return (0, _request.default)({
      url: "/v1/admin/truckTour/".concat(truckTourId, "/complete"),
      method: 'put'
    });
  }
};
exports.truckTourApi = truckTourApi;