"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới" : "Cập nhật",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại sóng",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: _vm.status == "update" && !_vm.$devMode
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lớp"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 3
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_vm._v("3 lớp")]), _c("el-radio", {
    attrs: {
      label: 5
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_vm._v("5 lớp")]), _c("el-radio", {
    attrs: {
      label: 7
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_vm._v("7 lớp")])], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Phí vận chuyển"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    model: {
      value: _vm.form.shipFee,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "shipFee", $$v);
      },
      expression: "form.shipFee"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Phí vận chuyển test"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    model: {
      value: _vm.form.shipFee2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "shipFee2", $$v);
      },
      expression: "form.shipFee2"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Chiều cao sóng (mm)",
      prop: "height"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hệ số quy đổi",
      prop: "ratioChange"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    model: {
      value: _vm.form.ratioChange,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ratioChange", $$v);
      },
      expression: "form.ratioChange"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Ghi chú",
      prop: "note"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 4,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-size--medium font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loadingSubmit
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Lưu")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;