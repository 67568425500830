"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      prop: "no",
      width: "60"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Mã",
      prop: "code"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Loại",
      prop: "type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.WorkOrderInventoryTypeTrans[row.type]) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng cân nặng trên pallet (Kg)",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalPalletWeight)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Cân nặng của pallet (Kg)",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.palletWeight)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Cân nặng giấy carton",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalPalletWeight - row.palletWeight)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Cân nặng 1 sp (Kg)",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.weight)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng/pallet",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "NV kho",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.staffName) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "NV tạo",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(" " + _vm._s(row.staff.name) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thời gian",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " ")];
      }
    }])
  }), _vm.visibleAction ? _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      align: "center",
      width: "230"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-printer"
          },
          on: {
            click: function click($event) {
              return _vm.handlePrint(row);
            }
          }
        }), _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        })];
      }
    }], null, false, 1955579272)
  }) : _vm._e()], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;