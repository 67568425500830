"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      width: "1200px",
      "close-on-click-modal": false,
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("div", [_c("label", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.status == "update" ? "Cập nhật lệnh giao" : "Thêm mới lệnh giao") + " ")]), _vm.status == "update" ? _c("div", [_vm.form.createdStaff ? _c("span", [_vm._v("Bởi: " + _vm._s(_vm.form.createdStaff.name) + " - ")]) : _vm._e(), _c("span", [_vm._v(_vm._s(_vm.$formatDateTime(_vm.form.dateCreated)))])]) : _vm._e()])]), _c("DeliveryTripForm", {
    ref: "form",
    attrs: {
      form: _vm.form,
      rules: _vm.rules,
      isDisableForm: _vm.isDisableForm,
      misaTags: _vm.misaTags
    },
    on: {
      selectVietBox: _vm.handleSelectVietBox,
      changeIsPE: _vm.handleChangeIsPE,
      updatePE: _vm.handleUpdatePE,
      changeStartHour: _vm.handleChangeStartHour,
      createMisaTag: _vm.handleCreateMisaTag,
      viewMisaTag: _vm.handleViewMisaTag
    }
  }), _c("div", {
    staticStyle: {
      width: "100%",
      border: "1px solid #ccc",
      "margin-top": "12px"
    }
  }), _c("section", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [!_vm.isDisableForm ? _c("section", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleDropDownClick("order");
      }
    }
  }, [_vm._v(" Chọn từ đơn ")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleDropDownClick("lsx");
      }
    }
  }, [_vm._v(" Chọn từ LSX ")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleDropDownClick("inventory");
      }
    }
  }, [_vm._v(" Chọn từ tồn kho ")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleDropDownClick("nhanhOrder");
      }
    }
  }, [_vm._v(" Chọn từ Nhanh.vn ")])], 1) : _vm._e(), _c("DeliveryTripDetailTable", {
    attrs: {
      data: _vm.deliveryTripDetails,
      isDisableForm: _vm.isDisableForm,
      visibleLinkProduct: _vm.status == "update"
    },
    on: {
      remove: _vm.handleRemove,
      changeQuantity: _vm.handleChangeQuantity,
      "link-product": _vm.handleLinkProduct
    }
  })], 1), _c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("label", {
    staticStyle: {
      color: "#000",
      "margin-top": "12px",
      display: "inline-block",
      "font-size": "16px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tổng m3: " + _vm._s(_vm.totalCubicMeter))])]), _c("SelectMsOrderDetailModal", {
    ref: "SelectMsOrderDetailModal",
    on: {
      submitOk: _vm.handleAddOrder
    }
  }), _c("SelectWorkOrderModal", {
    ref: "SelectWorkOrderModal",
    on: {
      submitOk: _vm.handleAddWorkOrder
    }
  }), _c("MisaTagModal", {
    ref: "MisaTagModal",
    on: {
      submitOk: _vm.fetchMisaTags
    }
  }), _c("SelectMisaProductModal", {
    ref: "SelectMisaProductModal",
    on: {
      submitOk: _vm.handleAddInventory
    }
  }), _c("MisaTagManageModal", {
    ref: "MisaTagManageModal",
    on: {
      submitOk: _vm.fetchMisaTags
    }
  }), _c("SelectNhanhOrderDetailModal", {
    ref: "SelectNhanhOrderDetailModal",
    on: {
      submitOk: _vm.handleAddNhanhOrder
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _vm.status == "update" && _vm.isDelete ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "danger",
      icon: "el-icon-delete",
      loading: _vm.loadingDelete
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa")]) : _vm._e(), _vm.status == "update" ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-receiving"
    },
    on: {
      click: _vm.viewLog
    }
  }, [_vm._v("LS thay đổi")]) : _vm._e()], 1), _vm.status == "create" ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.createData();
      }
    }
  }, [_vm._v("Tạo")]) : _vm.isEdit ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.updateData();
      }
    }
  }, [_vm._v("Cập nhật")]) : _vm._e()], 1), _c("LinkMisaInventoryModal", {
    ref: "LinkMisaInventoryModal",
    on: {
      submitOk: _vm.handleLinkProductOk
    }
  }), _c("UpdatePEModal", {
    ref: "UpdatePEModal",
    on: {
      submitOk: _vm.handleSubmitPE
    }
  }), _c("DeliveryViewLogModal", {
    ref: "DeliveryViewLogModal"
  })], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;