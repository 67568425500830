"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.number.to-fixed.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _objectSpread2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "delivery-trip-event",
    class: (0, _objectSpread2.default)({}, _vm.renderClass(_vm.deliveryTrip)),
    on: {
      click: function click($event) {
        $event.preventDefault();
        if ($event.target !== $event.currentTarget) return null;
        return _vm.onClickItem(_vm.deliveryTrip);
      }
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    },
    on: {
      click: function click($event) {
        return _vm.onClickItem(_vm.deliveryTrip);
      }
    }
  }, [_c("div", [_c("div", {
    staticClass: "line-clamp-1",
    staticStyle: {
      height: "22px",
      overflow: "hidden",
      width: "calc(100% - 62px)"
    }
  }, [_c("label", {
    staticClass: "deliver-trip--name",
    class: !_vm.isChild ? "parent" : "",
    staticStyle: {
      "font-size": "12px",
      padding: "2px 4px",
      "border-radius": "4px",
      cursor: "pointer",
      "margin-left": "-5px"
    }
  }, [_vm._v(" (" + _vm._s(_vm.deliveryTrip.totalCubicMeter.toFixed(1)) + " m"), _c("sup", [_vm._v("3")]), _vm._v(") " + _vm._s(_vm.deliveryTrip.name) + _vm._s(_vm.deliveryTrip.privateName ? "- ".concat(_vm.deliveryTrip.privateName) : "") + " ")])])]), _vm.deliveryTrip.note ? _c("div", {
    staticStyle: {
      color: "#fff",
      "font-size": "12px"
    }
  }, [_vm._v(" Note: " + _vm._s(_vm.deliveryTrip.note) + " ")]) : _vm._e(), _vm.isChild ? [_c("el-tag", {
    staticStyle: {
      "font-size": "10px",
      padding: "0 4px"
    },
    attrs: {
      effect: "plain",
      type: _vm.deliveryTrip.isEnough ? "success" : "danger"
    }
  }, [_vm._v(_vm._s(_vm.deliveryTrip.isEnough ? "Đã đủ hàng" : "Chưa đủ hàng"))]), _vm.deliveryTrip.note && _vm.deliveryTrip.note.toLowerCase().includes("tại kho") ? _c("el-tag", {
    staticStyle: {
      "font-size": "10px",
      padding: "0 4px"
    },
    attrs: {
      effect: "plain",
      type: "warning"
    }
  }, [_vm._v("BỐC TẠI KHO")]) : _vm._e(), _vm.deliveryTrip.isPE ? _c("el-tag", {
    staticStyle: {
      "font-size": "10px",
      padding: "0 4px"
    },
    attrs: {
      effect: "plain"
    }
  }, [_vm._v("CÓ QUẤN PE")]) : _vm._e()] : _vm._e()], 2), _vm.deliveryTrip.misaTags.length ? _c("div", _vm._l(_vm.deliveryTrip.misaTags, function (item, index) {
    return _c("MisaTagBackgroundComp", {
      key: index,
      attrs: {
        misaTag: item,
        isThroughText: _vm.deliveryTrip.status == _vm.DeliveryTripStatus.Complete
      }
    });
  }), 1) : _vm._e(), _c("div", {
    staticClass: "absolute-container"
  }, [_vm.isAdd && _vm.visibleAdd ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Thêm lệnh giao",
      placement: "top"
    }
  }, [_c("IconButton", {
    staticStyle: {
      "margin-right": "4px"
    },
    attrs: {
      icon: "el-icon-plus",
      color: "#047aff"
    },
    on: {
      click: function click() {
        return _vm.onAdd(_vm.deliveryTrip);
      }
    }
  })], 1) : _vm._e(), _vm.isEdit && _vm.visibleEdit ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Cập nhật tên chuyến, ghi chú",
      placement: "top"
    }
  }, [_c("IconButton", {
    staticStyle: {
      "margin-right": "4px"
    },
    attrs: {
      icon: "el-icon-edit",
      color: "#047aff"
    },
    on: {
      click: function click() {
        return _vm.onUpdate(_vm.deliveryTrip);
      }
    }
  })], 1) : _vm._e(), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Copy",
      placement: "top"
    }
  }, [_vm.visibleCopy ? _c("IconButton", {
    staticStyle: {
      "margin-right": "4px"
    },
    attrs: {
      icon: "el-icon-copy-document",
      color: "#047aff"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return function () {
          return _vm.onCopy(_vm.deliveryTrip);
        }.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _vm.visibleCopyProduct ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Copy hàng hóa",
      placement: "top"
    }
  }, [_c("IconButton", {
    staticStyle: {
      "margin-right": "4px"
    },
    attrs: {
      icon: "el-icon-receiving",
      color: "#047aff"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return function () {
          return _vm.onCopyProduct(_vm.deliveryTrip);
        }.apply(null, arguments);
      }
    }
  })], 1) : _vm._e(), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Sắp xếp lệnh",
      placement: "top"
    }
  }, [_vm.visibleSort ? _c("IconButton", {
    staticStyle: {
      "margin-right": "4px"
    },
    attrs: {
      icon: "el-icon-refresh",
      color: "#047aff"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return function () {
          return _vm.onSort(_vm.deliveryTrip);
        }.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Clone lệnh",
      placement: "top"
    }
  }, [_vm.isChild ? _c("IconButton", {
    staticStyle: {
      "margin-right": "4px"
    },
    attrs: {
      icon: "el-icon-files",
      color: "#047aff"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return function () {
          return _vm.onClone(_vm.deliveryTrip);
        }.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _vm.visibleCheck ? _c("div", {
    staticClass: "delivery-trip-event--mark-complete"
  }, [_c("div", {
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return false.apply(null, arguments);
      }
    }
  }, [_c("el-checkbox", {
    attrs: {
      value: _vm.deliveryTrip.status == _vm.DeliveryTripStatus.Complete,
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.onCheck(_vm.deliveryTrip);
      }
    }
  })], 1)]) : _vm._e()], 1), _vm._t("default")], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;