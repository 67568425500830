"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "log-list"
  }, [_c("div", {
    staticClass: "composer"
  }, [_c("div", {
    staticClass: "avatar-box"
  }, [_c("img", {
    attrs: {
      src: _vm.avatarUrl,
      alt: ""
    }
  })]), _c("CustomerLogCompose", {
    on: {
      save: _vm.handleAddLog
    }
  })], 1), _vm._l(_vm.logs, function (item, index) {
    return _c("CustomerLogItem", {
      key: index,
      attrs: {
        log: item,
        visibleControl: item.visibleControl
      },
      on: {
        update: _vm.handleUpdate,
        delete: _vm.handleDelete
      }
    });
  })], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;