"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dashboardApi = void 0;

var _request = require("@/utils/request");

var dashboardApi = {
  topSale: function topSale(params) {
    return (0, _request.request)({
      url: '/v1/admin/dashboard/topSale',
      params: params
    });
  }
};
exports.dashboardApi = dashboardApi;