"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    },
    on: {
      "selection-change": _vm.onSelectionChange
    }
  }, [_vm.visibleAction ? _c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55",
      align: "center"
    }
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "STT",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày lập lệnh",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.startAt ? _vm.$formatDate(row.startAt) : "--") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "LSX",
      prop: "code",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.syncId ? _c("el-link", {
          staticStyle: {
            color: "#1989fa"
          },
          attrs: {
            href: "https://actapp.misa.vn/app/popup/INProductionOrderDetail/".concat(row.syncId),
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.code) + " ")]) : _c("span", [_vm._v(" " + _vm._s(row.code) + " ")]), row.misaTags.length ? [_c("div", _vm._l(row.misaTags, function (item, index) {
          return _c("MisaTagComp", {
            key: index,
            attrs: {
              misaTag: item
            }
          });
        }), 1)] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "PO",
      width: "105"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.poCode ? _c("div", [_c("el-link", {
          attrs: {
            target: "_blank",
            type: "primary",
            href: "https://actapp.misa.vn/app/popup/PUOrderDetail/".concat(row.poId)
          }
        }, [_vm._v(_vm._s(row.poCode))])], 1) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Mã KH"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.msOrder ? _c("div", [_c("span", {
          staticStyle: {
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(row.msOrder.customerCode) + " ")]), _c("br"), _c("span", [_vm._v(_vm._s(row.msOrder.customerName))])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái SX",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var _vm$WorkOrderProducti, _vm$WorkOrderProducti2;

        var row = _ref5.row;
        return [_c("el-tag", {
          staticStyle: {
            border: "unset"
          },
          attrs: {
            color: (_vm$WorkOrderProducti = _vm.WorkOrderProductionStatusTrans[row.productionStatus]) === null || _vm$WorkOrderProducti === void 0 ? void 0 : _vm$WorkOrderProducti.color,
            effect: "dark"
          }
        }, [_vm._v(" " + _vm._s((_vm$WorkOrderProducti2 = _vm.WorkOrderProductionStatusTrans[row.productionStatus]) === null || _vm$WorkOrderProducti2 === void 0 ? void 0 : _vm$WorkOrderProducti2.name) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "File đính kèm",
      width: "220"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("div", [row.msOrder && row.msOrder.misaAttaches ? _c("div", _vm._l(row.msOrder.misaAttaches.filter(function (e) {
          return e.extension == ".pdf" && e.isVisible;
        }), function (misaAttach) {
          return _c("div", {
            key: misaAttach.id,
            staticStyle: {
              background: "#f0f2f4",
              width: "200px",
              padding: "0 8px",
              "border-radius": "4px",
              cursor: "pointer",
              "margin-top": "4px"
            },
            on: {
              click: function click($event) {
                return _vm.handleClickAttach(misaAttach);
              }
            }
          }, [_c("img", {
            staticStyle: {
              "vertical-align": "middle"
            },
            attrs: {
              src: _vm.pdfImage,
              width: "16",
              alt: ""
            }
          }), _c("span", {
            staticStyle: {
              color: "#1890ff",
              display: "inline-block",
              "vertical-align": "middle",
              "margin-left": "4px"
            }
          }, [_vm._v(_vm._s(misaAttach.name))]), _c("el-button", {
            staticClass: "font-bold",
            attrs: {
              type: "primary",
              size: "mini"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.onHiddenMisaAttach(misaAttach);
              }
            }
          }, [_vm._v("Ẩn")])], 1);
        }), 0) : _vm._e(), row.misaAttaches ? _vm._l(row.misaAttaches.filter(function (e) {
          return e.extension == ".pdf" && e.isVisible;
        }), function (misaAttach) {
          return _c("div", {
            key: misaAttach.id,
            staticStyle: {
              background: "#f0f2f4",
              width: "200px",
              padding: "0 8px",
              "border-radius": "4px",
              cursor: "pointer",
              "margin-top": "4px"
            },
            on: {
              click: function click($event) {
                return _vm.handleClickAttach(misaAttach);
              }
            }
          }, [_c("img", {
            staticStyle: {
              "vertical-align": "middle"
            },
            attrs: {
              src: _vm.pdfImage,
              width: "16",
              alt: ""
            }
          }), _c("span", {
            staticStyle: {
              color: "#1890ff",
              display: "inline-block",
              "vertical-align": "middle",
              "margin-left": "4px"
            }
          }, [_vm._v(_vm._s(misaAttach.name))]), _c("el-button", {
            staticClass: "font-bold",
            attrs: {
              type: "primary",
              size: "mini"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.onHiddenMisaAttach(misaAttach);
              }
            }
          }, [_vm._v("Ẩn")])], 1);
        }) : _vm._e()], 2)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tiến độ",
      width: "75"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.completedProcess) + " / " + _vm._s(row.totalProcess) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Diễn giải",
      width: "250",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("div", [_vm.checkIsGap(row.name.toLowerCase()) ? _c("img", {
          attrs: {
            width: "50",
            src: _vm.warningImage
          }
        }) : _vm._e(), _c("span", [_vm._v(_vm._s(row.name))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "70",
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(_vm._s(_vm.$formatNumberVN(row.quantity)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      label: "SL NVL",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Yêu cầu: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.materialQuantity)))])]), row.material ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Có sẵn: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.material.quantity)))])]) : _vm._e(), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.viewInventory(row);
            }
          }
        }, [_vm._v("Xem kho")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số màu in",
      align: "right",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_vm._v(" " + _vm._s(row.printColors > 0 ? row.printColors : "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chống thấm",
      align: "right",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_vm._v(" " + _vm._s(row.waterProof) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Định hình",
      align: "right",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_vm._v(" " + _vm._s(row.dinhHinh) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú misa"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var _row$productNote;

        var row = _ref14.row;
        return [row.productNote != "1" ? _c("span", [_vm.checkIsGap((_row$productNote = row.productNote) === null || _row$productNote === void 0 ? void 0 : _row$productNote.toLowerCase()) ? _c("img", {
          attrs: {
            width: "50",
            src: _vm.warningImage
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(row.productNote) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var row = _ref15.row;
        return [_c("el-tooltip", {
          attrs: {
            content: row.note,
            placement: "top",
            effect: "dark"
          }
        }, [_c("span", [_vm._v(_vm._s(row.note))])]), _vm.visibleAction ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.onNote(row);
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _vm.visibleAction ? _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var row = _ref16.row;
        return [_c("div", [_c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            width: "130px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.onUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")])], 1), _c("div", {
          staticStyle: {
            "margin-top": "8px"
          }
        }, [_c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            width: "130px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: row.productionStatus == "NEW" ? "el-icon-plus" : "el-icon-view"
          },
          on: {
            click: function click($event) {
              return _vm.onStartProduction(row);
            }
          }
        }, [_vm._v(_vm._s(row.productionStatus == "NEW" ? "Lên kế hoạch" : "Xem lệnh làm việc") + " ")])], 1), _vm.visibleDelete ? _c("div", {
          staticStyle: {
            "margin-top": "8px"
          }
        }, [_c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            width: "130px"
          },
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.onDelete(row);
            }
          }
        }, [_vm._v("Xóa")])], 1) : _vm._e()];
      }
    }], null, false, 141193984)
  }) : _vm._e()], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;