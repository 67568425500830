"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Xem chi tiết vốn",
      visible: _vm.visible,
      width: "1200px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "top"
    },
    on: {
      "tab-click": _vm.handleTabClick
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Danh sách"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-table", {
    staticClass: "carton-cost-table",
    attrs: {
      data: _vm.costs,
      fit: "",
      "show-summary": "",
      "summary-method": _vm.getSummaries
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Thành phần",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(row.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Loại",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.CartonCostTypeTrans[row.type]) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng chi phí",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amount)) + " đ ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Phần trăm",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.percent) + "% ")];
      }
    }])
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("pie-chart", {
    ref: "listChart",
    attrs: {
      formatter: _vm.formatter,
      legend: _vm.listLegend,
      series: _vm.listSeries
    }
  })], 1)], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "Theo loại"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.groupByTypes,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Nhóm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.CartonCostTypeTrans[row.type]) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chi phí"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amount)) + " đ ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Phần trăm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.percent) + "% ")];
      }
    }])
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("pie-chart", {
    ref: "groupChart",
    attrs: {
      formatter: _vm.formatter,
      legend: _vm.groupLegend,
      series: _vm.groupSeries
    }
  })], 1)], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;