"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addressApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var addressApi = {
  findCity: function findCity(params) {
    return (0, _request.default)({
      url: "/v1/admin/address/city",
      params: params
    });
  },
  findDistrict: function findDistrict(params) {
    return (0, _request.default)({
      url: "/v1/admin/address/district",
      params: params
    });
  },
  findWard: function findWard(params) {
    return (0, _request.default)({
      url: "/ward",
      params: params
    });
  }
};
exports.addressApi = addressApi;