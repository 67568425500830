"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "panel-item misa-customer-kpi"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tiến độ CSKH ")]), _c("section", _vm._l(_vm.data, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "detail",
      staticStyle: {
        "margin-top": "4px"
      }
    }, [_c("div", [_c("img", {
      staticStyle: {
        "object-fit": "cover",
        "vertical-align": "middle",
        "margin-right": "4px",
        "border-radius": "50%"
      },
      attrs: {
        width: "40",
        height: "40",
        src: item.staffAvatar ? _vm.$baseUrlMedia + item.staffAvatar : _vm.defaultAvatar,
        alt: ""
      }
    })]), _c("div", {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        flex: "1"
      }
    }, [_c("span", [_vm._v(_vm._s(item.staffName) + " (" + _vm._s(item.current) + "/" + _vm._s(item.target) + ")")]), _c("el-progress", {
      staticStyle: {
        "margin-top": "4px"
      },
      attrs: {
        "text-color": "#000",
        color: _vm.customColors,
        "stroke-width": 18,
        percentage: item.percent
      }
    })], 1)]);
  }), 0), _c("el-link", {
    staticClass: "setting-button",
    staticStyle: {
      "margin-top": "4px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleUpdate
    }
  }, [_c("i", {
    staticClass: "el-icon-setting"
  }), _vm._v(" Cập nhật KPI")]), _c("KpiConfigModal", {
    ref: "KpiConfigModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;