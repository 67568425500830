"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vueAutonumeric = _interopRequireDefault(require("vue-autonumeric"));

var _default = _vue.default.extend({
  props: ["value", "options"],
  components: {
    VueAutoNumeric: _vueAutonumeric.default
  },
  methods: {
    handleInput: function handleInput(value) {
      this.$emit("input", value);
    },
    handleBlur: function handleBlur() {
      this.$emit("blur");
    }
  }
});

exports.default = _default;