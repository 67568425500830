"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "promotion-item",
    style: "backgroundColor: ".concat(_vm.backgroundColor, "; ").concat(_vm.containerStyle),
    on: {
      click: function click($event) {
        return _vm.onClick(_vm.promotion);
      }
    }
  }, [_c("label", {
    staticClass: "promotion-title",
    style: _vm.titleStyle,
    attrs: {
      for: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.promotion.title) + " ")]), _c("div", {
    staticClass: "discount-value"
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.promotion.discountValue) + "% ")])]), _c("div", {
    staticClass: "product-group-name"
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.promotion.productGroup.name) + " ")])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;