"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      size: "medium"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhà cung cấp")]), _c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.supplierId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "supplierId", $$v);
      },
      expression: "query.supplierId"
    }
  }, _vm._l(_vm.suppliers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Tạo PO")])], 1)]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: _vm.hiddenComplete
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.hiddenComplete,
      callback: function callback($$v) {
        _vm.hiddenComplete = $$v;
      },
      expression: "hiddenComplete"
    }
  }, [_vm._v(" Ẩn PO hoàn thành ")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "summary-method": _vm.getSummaries,
      "show-summary": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Mã PO",
      prop: "code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.code) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nhà cung cấp",
      prop: "supplier.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng SL đặt",
      prop: "",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalQuantity)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng sản phẩm",
      prop: "",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("span", [_vm._v(_vm._s(row.poDetails.length))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tiến độ",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.poDetails.filter(function (e) {
          return e.isComplete;
        }).length) + "/" + _vm._s(row.poDetails.length) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nhân viên tạo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("span", [_vm._v(_vm._s(row.createdStaff.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày tạo PO"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("span", [_vm._v(_vm._s(row.poDate ? _vm.$formatDate(row.poDate) + " (".concat(_vm.getFromNow(row.poDate), ")") : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng tiền",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalPrice)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("el-tag", {
          attrs: {
            type: row.status == "NEW" ? "primary" : "success",
            size: "small",
            effect: "dark"
          }
        }, [_vm._v(" " + _vm._s(row.status == "NEW" ? "Mới" : "Hoàn thành") + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("div", {
          staticStyle: {
            width: "100px",
            "text-align": "center",
            display: "inline-block"
          }
        }, [_c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            width: "100%"
          },
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")])], 1), _c("div", {
          staticStyle: {
            width: "100px",
            "text-align": "center",
            "margin-top": "8px",
            display: "inline-block"
          }
        }, [_c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            width: "100%"
          },
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v("Xóa")])], 1)];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("POModal", {
    ref: "POModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;