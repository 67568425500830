"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top",
      disabled: _vm.isDisableForm
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên lệnh giao",
      prop: "name"
    }
  }, [_c("el-input", {
    class: _vm.isCheckDiff && _vm.checkDiff("name"),
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Chọn chuyến",
      prop: "deliveryTripName"
    }
  }, [_c("el-select", {
    class: _vm.isCheckDiff && _vm.checkDiff("deliveryTripName"),
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.deliveryTripName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "deliveryTripName", $$v);
      },
      expression: "form.deliveryTripName"
    }
  }, _vm._l(_vm.deliveryTripNames, function (item) {
    return _c("el-option", {
      key: item.name,
      attrs: {
        label: item.name,
        value: item.name
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Trang thái"
    }
  }, [_c("el-select", {
    class: _vm.isCheckDiff && _vm.checkDiff("status"),
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, _vm._l(_vm.DeliveryTripStatusName, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày",
      prop: "date"
    }
  }, [_c("el-date-picker", {
    class: _vm.isCheckDiff && _vm.checkDiff("date"),
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      format: "dd/MM/yyyy",
      placeholder: ""
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giờ bắt đầu",
      prop: "startHour"
    }
  }, [_c("el-time-select", {
    class: _vm.isCheckDiff && _vm.checkDiff("startHour"),
    staticStyle: {
      width: "100%"
    },
    attrs: {
      format: "HH:mm",
      "picker-options": {
        start: "06:00",
        step: "00:30",
        end: "22:00"
      }
    },
    on: {
      change: _vm.handleChangeStartHour
    },
    model: {
      value: _vm.form.startHour,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "startHour", $$v);
      },
      expression: "form.startHour"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giờ kết thúc",
      prop: "endHour"
    }
  }, [_c("el-time-select", {
    class: _vm.isCheckDiff && _vm.checkDiff("endHour"),
    staticStyle: {
      width: "100%"
    },
    attrs: {
      format: "HH:mm",
      "picker-options": {
        start: "06:00",
        step: "00:30",
        end: "22:00"
      }
    },
    model: {
      value: _vm.form.endHour,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "endHour", $$v);
      },
      expression: "form.endHour"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Thẻ"
    }
  }, [!_vm.isDisableForm ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreateMisaTag
    }
  }, [_vm._v("Thẻ")]) : _vm._e(), !_vm.isDisableForm ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.handleViewMisaTag
    }
  }, [_vm._v("Quản lý thẻ")]) : _vm._e(), _c("el-select", {
    attrs: {
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.form.misaTagIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaTagIds", $$v);
      },
      expression: "form.misaTagIds"
    }
  }, _vm._l(_vm.misaTags, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("MisaTagComp", {
      attrs: {
        misaTag: item
      }
    })], 1);
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Ghi chú"
    }
  }, [_c("el-input", {
    class: _vm.isCheckDiff && _vm.checkDiff("note"),
    attrs: {
      placeholder: "",
      type: "textarea"
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1), _c("section", [_c("div", [_c("label", {
    staticStyle: {
      color: "#000",
      "font-size": "18px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Thông tin giao hàng ")]), !_vm.isDisableForm ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleSelectVietBox
    }
  }, [_vm._v("Giao cho VietBox")]) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên khách hàng"
    }
  }, [_c("el-input", {
    class: _vm.isCheckDiff && _vm.checkDiff("customerName"),
    model: {
      value: _vm.form.customerName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customerName", $$v);
      },
      expression: "form.customerName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SĐT"
    }
  }, [_c("el-input", {
    class: _vm.isCheckDiff && _vm.checkDiff("customerPhone"),
    model: {
      value: _vm.form.customerPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customerPhone", $$v);
      },
      expression: "form.customerPhone"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Địa chỉ"
    }
  }, [_c("el-input", {
    class: _vm.isCheckDiff && _vm.checkDiff("customerAddress"),
    model: {
      value: _vm.form.customerAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customerAddress", $$v);
      },
      expression: "form.customerAddress"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giao hộ"
    }
  }, [_c("el-input", {
    class: _vm.isCheckDiff && _vm.checkDiff("privateNote"),
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.privateNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "privateNote", $$v);
      },
      expression: "form.privateNote"
    }
  })], 1)], 1)], 1), _c("div", [_c("div", [_c("el-switch", {
    class: _vm.isCheckDiff && _vm.checkDiff("isPE"),
    attrs: {
      value: _vm.form.isPE,
      "active-color": "#13ce66",
      "active-text": "Quấn PE",
      "inactive-color": "#ff4949"
    },
    on: {
      change: _vm.handleChangeIsPE
    }
  }), _vm.form.isPE ? [_c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleUpdatePE
    }
  }, [_c("i", {
    staticClass: "el-icon-edit"
  })])] : _vm._e(), _c("div", [_vm.shouldUsePE && !_vm.form.isPE ? _c("label", {
    staticStyle: {
      color: "red",
      display: "inline-block",
      "margin-left": "12px",
      "font-size": "18px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Nên dùng quấn PE")]) : _vm._e()])], 2)])], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;