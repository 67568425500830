"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.concat.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      "close-on-click-modal": false,
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiêu đề",
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Giá trị khuyến mãi (%)",
      prop: "discountValue"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.discountValue,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "discountValue", $$v);
      },
      expression: "form.discountValue"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nhóm sản phẩm",
      prop: "productGroupId"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.productGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "productGroupId", $$v);
      },
      expression: "form.productGroupId"
    }
  }, _vm._l(_vm.productGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Khách hàng",
      prop: "customerIds"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      multiple: "",
      filterable: "",
      "reserve-keyword": "",
      remote: "",
      "remote-method": _vm.fetchCustomers,
      size: "medium"
    },
    model: {
      value: _vm.form.customerIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customerIds", $$v);
      },
      expression: "form.customerIds"
    }
  }, _vm._l(_vm.customers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " - ").concat(item.phone),
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;