"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Nhân viên",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.staff ? _c("div", [_c("img", {
          staticStyle: {
            "object-fit": "cover",
            "border-radius": "50%"
          },
          attrs: {
            src: row.staff.avatar ? _vm.$baseUrlMedia + row.staff.avatar : _vm.defaultAvatar,
            width: "40",
            height: "40",
            alt: ""
          }
        }), _c("br"), _c("span", [_vm._v(" " + _vm._s(row.staff.name) + " ")])]) : _c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(" Chưa có ai chăm sóc ")]), _c("div", [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-refresh"
          },
          on: {
            click: function click($event) {
              return _vm.onChangeOwner(row);
            }
          }
        }, [_vm._v("Gán NV")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Lead"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.onClickDetail(row);
            }
          }
        }, [_c("span", [_vm._v(" " + _vm._s(row.leadName) + " ")]), _c("br"), _c("span", [_vm._v(" " + _vm._s(row.code) + " ")])]), _c("br")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SĐT"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Di động: ")]), _c("span", [_vm._v(_vm._s(row.mobile))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Cơ quan: ")]), _c("span", [_vm._v(_vm._s(row.officeTel))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Địa chỉ"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", [_vm._v(" " + _vm._s(row.address) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngành hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("span", [_vm._v(" " + _vm._s(row.category) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nguồn"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("span", [_vm._v(" " + _vm._s(row.source) + " ")]), _c("div", [row.facebook ? _c("el-link", {
          attrs: {
            type: "primary",
            href: row.facebook,
            target: "_blank"
          }
        }, [_vm._v("Mở facebook")]) : _vm._e(), row.shopee ? _c("el-link", {
          attrs: {
            type: "primary",
            href: row.shopee,
            target: "_blank"
          }
        }, [_vm._v("Mở Shopee")]) : _vm._e()], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hoạt động gần nhất"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("div", _vm._l(row.nearActivities, function (item, index) {
          return row.nearActivities ? _c("div", {
            key: index,
            staticStyle: {
              "border-bottom": "#ccc 1px solid"
            }
          }, [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v(" " + _vm._s(_vm.fromAgo(item.date)) + ": ")]), _c("span", [_vm._v(_vm._s(item.name))])])]) : _vm._e();
        }), 0)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("div", [_c("div", [_c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            width: "126px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.onCreateNote(row);
            }
          }
        }, [_vm._v("Tạo ghi chú")])], 1), _c("div", {
          staticStyle: {
            "margin-top": "12px"
          }
        }, [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            icon: "el-icon-tickets",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onCreateActivity(row);
            }
          }
        }, [_vm._v("Tạo nhiệm vụ")])], 1)])];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;