"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "tag",
    style: {
      backgroundColor: _vm.backgroundColor
    }
  }, [_c("span", {
    style: {
      color: _vm.labelColor
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;