"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reportApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var reportApi = {
  profit: function profit(params) {
    return (0, _request.default)({
      url: "/v1/admin/report/profit",
      params: params
    });
  },
  profitChart: function profitChart(params) {
    return (0, _request.default)({
      url: "/v1/admin/report/profit/chart",
      params: params
    });
  },
  profitByOrderType: function profitByOrderType(params) {
    return (0, _request.default)({
      url: "/v1/admin/report/profit/chart/order-type",
      params: params
    });
  },
  category: function category(params) {
    return (0, _request.default)({
      url: "/v1/admin/report/product-category",
      params: params
    });
  }
};
exports.reportApi = reportApi;