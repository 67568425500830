"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.crawLinkLogApi = void 0;

var _request = require("@/utils/request");

var crawLinkLogApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/crawLinkLog',
      params: params
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/crawLinkLog/".concat(id),
      method: 'delete'
    });
  }
};
exports.crawLinkLogApi = crawLinkLogApi;