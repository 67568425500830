"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "change-order-staff-modal",
    attrs: {
      title: "",
      visible: _vm.visible,
      fullscreen: "",
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("div", {
          staticClass: "close-box",
          on: {
            click: function click($event) {
              _vm.visible = false;
            }
          }
        }, [_c("font-awesome-icon", {
          attrs: {
            icon: "times"
          }
        })], 1), _c("div", [_c("span", {
          staticClass: "font-size--large font-bold",
          staticStyle: {
            "line-height": "31px"
          }
        }, [_vm._v("Change the staff order")]), _c("br"), _c("span", [_vm._v("Drag and drop staff members to change the order. This order will reflect calendar visibility and the online booking flow.")])]), _c("el-button", {
          staticClass: "font-size--medium font-bold",
          attrs: {
            type: "primary",
            loading: _vm.loadingSubmit
          },
          on: {
            click: _vm.handleSave
          }
        }, [_vm._v("SAVE")])];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticStyle: {
      "max-width": "900px",
      margin: "0 auto"
    }
  }, [_c("container", {
    on: {
      drop: _vm.onDrop
    }
  }, _vm._l(_vm.employees, function (item) {
    return _c("draggable", {
      key: item.id,
      staticClass: "drag-employee-item"
    }, [_c("employee-item", {
      attrs: {
        visibleDrag: true,
        employee: item
      }
    })], 1);
  }), 1)], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;