"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertChatContent = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.string.replace-all.js");

require("core-js/modules/es.array.slice.js");

var convertChatContent = function convertChatContent(content, customerName, productCateName) {
  var firstName = customerName.toLowerCase().split(" ")[0];
  var me = "";

  if (firstName == "anh" || firstName == "chị") {
    me = "em";
  } else {
    me = "con";
  }

  var firstNameCap = capitalizeFirstLetter(firstName);
  var customerNameToken = '${customerName}',
      meToken = '${me}',
      productCategoryNameToken = '${productCategoryName}',
      firstNameCapToken = '${firstNameCap}',
      firstNameToken = '${firstName}';
  return content.replaceAll(customerNameToken, customerName).replaceAll(meToken, me).replaceAll(productCategoryNameToken, productCateName.toLowerCase()).replaceAll(firstNameCapToken, firstNameCap).replaceAll(firstNameToken, firstName);
};

exports.convertChatContent = convertChatContent;

var capitalizeFirstLetter = function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};