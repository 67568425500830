"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật máy" : "Thêm mới máy",
      visible: _vm.visible,
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên máy",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Bế",
      name: "1"
    }
  }, [_c("CartonMachineForm", {
    attrs: {
      form: {
        minLength: _vm.form.minLengthBe,
        minWidth: _vm.form.minWidthBe,
        minHeight: _vm.form.minHeightBe,
        minKhoTam: _vm.form.minKhoTamBe,
        minDaiTam: _vm.form.minDaiTamBe,
        maxKhoTam: _vm.form.maxKhoTamBe,
        maxDaiTam: _vm.form.maxDaiTamBe,
        isEnable: _vm.form.isEnableBe,
        isSwap: _vm.form.isSwap
      },
      visibleSwap: true
    },
    on: {
      change: _vm.handleChangeBe
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Chạp",
      name: "2"
    }
  }, [_c("CartonMachineForm", {
    attrs: {
      form: {
        minLength: _vm.form.minLengthChap,
        minWidth: _vm.form.minWidthChap,
        minHeight: _vm.form.minHeightChap,
        minKhoTam: _vm.form.minKhoTamChap,
        minDaiTam: _vm.form.minDaiTamChap,
        maxKhoTam: _vm.form.maxKhoTamChap,
        maxDaiTam: _vm.form.maxDaiTamChap,
        isEnable: _vm.form.isEnableChap
      },
      visibleSwap: false
    },
    on: {
      change: _vm.handleChangeChap
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "In",
      name: "3"
    }
  }, [_c("CartonMachineForm", {
    attrs: {
      form: {
        minLength: _vm.form.minLengthIn,
        minWidth: _vm.form.minWidthIn,
        minHeight: _vm.form.minHeightIn,
        minKhoTam: _vm.form.minKhoTamIn,
        minDaiTam: _vm.form.minDaiTamIn,
        maxKhoTam: _vm.form.maxKhoTamIn,
        maxDaiTam: _vm.form.maxDaiTamIn,
        isEnable: _vm.form.isEnableIn
      },
      visibleSwap: false
    },
    on: {
      change: _vm.handleChangeIn
    }
  })], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;