"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = handleClipboard;

var _vue = _interopRequireDefault(require("vue"));

function clipboardSuccess() {
  _vue.default.prototype.$message({
    message: 'Copy successfully',
    type: 'success',
    duration: 1500
  });
}

function clipboardError() {
  _vue.default.prototype.$message({
    message: 'Copy failed',
    type: 'error'
  });
}

function handleClipboard(text, event) {
  navigator.clipboard.writeText(text).then(function () {
    /* clipboard successfully set */
    clipboardSuccess();
  }, function () {
    clipboardError();
  });
}