"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("label", {
    staticStyle: {
      display: "inline-block",
      "margin-bottom": "24px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Log crawl data")]), _vm._l(_vm.data, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "misa-crawl-log-item"
    }, [_c("div", [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Loại: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.MisaCrawlLogTypeTrans[item.type]) + " ")])]), _c("div", [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Nguồn: ")]), _c("span", [_vm._v(" " + _vm._s(item.isAuto ? "Tự động" : item.source) + " ")])]), item.staff ? _c("div", [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Nhân viên: ")]), _c("span", [_vm._v(" " + _vm._s(item.staff.name) + " ")])]) : _vm._e(), _c("div", [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Lúc: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.$formatDateTime(item.dateCreated)) + " ")])])]);
  })], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;