"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [!_vm.product.isEdit ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.product.ratioChange)) + " ")]) : _c("el-input", {
    ref: "input",
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onUpdate(_vm.product);
      }
    },
    model: {
      value: _vm.product.tmpRatioChange,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "tmpRatioChange", $$v);
      },
      expression: "product.tmpRatioChange"
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;