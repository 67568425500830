"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 2,
      offset: 0
    }
  }, [_c("el-button", {
    attrs: {
      type: "danger",
      icon: "el-icon-close",
      size: "mini"
    },
    on: {
      click: _vm.onDelete
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 11,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Danh mục"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.category.categoryId,
      callback: function callback($$v) {
        _vm.$set(_vm.category, "categoryId", $$v);
      },
      expression: "category.categoryId"
    }
  }, _vm._l(_vm.productCategories, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 11,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Doanh s\u1ED1 ".concat(_vm.categoryName)
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.category.totalPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.category, "totalPrice", $$v);
      },
      expression: "category.totalPrice"
    }
  })], 1)], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;