"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.quoteLogApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var quoteLogApi = {
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/quoteLog",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/quoteLog/".concat(id, "/update"),
      data: data,
      method: "put"
    });
  },
  logPrint: function logPrint(quoteId) {
    return (0, _request.default)({
      url: "/v1/admin/quoteLog/print/".concat(quoteId),
      method: "post"
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/v1/admin/quoteLog/".concat(id, "/delete"),
      method: "delete"
    });
  }
};
exports.quoteLogApi = quoteLogApi;