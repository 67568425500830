"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container task-follow-pending"
  }, [_c("el-tabs", {
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, _vm._l(_vm.tabs, function (item) {
    return _c("el-tab-pane", {
      key: item.class,
      attrs: {
        label: item.label,
        name: item.class
      }
    }, [_c("span", {
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_vm._v(" " + _vm._s(item.label) + " "), _c("el-badge", {
      class: item.class,
      attrs: {
        value: item.total
      }
    })], 1), _c("CustomerList", {
      attrs: {
        className: item.class,
        taskFollowStatus: item.taskFollowStatus
      },
      on: {
        "fetch:ok": _vm.handleFetchOk
      }
    })], 1);
  }), 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;