"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "\u0110\u01A1n h\xE0ng #".concat(_vm.form.code),
      visible: _vm.visible,
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày bắt đầu"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      format: "dd/MM/yyyy"
    },
    model: {
      value: _vm.form.startAtDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "startAtDate", $$v);
      },
      expression: "form.startAtDate"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày kết thúc"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      format: "dd/MM/yyyy"
    },
    model: {
      value: _vm.form.endAtDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "endAtDate", $$v);
      },
      expression: "form.endAtDate"
    }
  })], 1)], 1)], 1)], 1), _c("el-table", {
    attrs: {
      data: _vm.orderDetails,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Mã hàng",
      prop: "code"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tên hàng",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đã giao"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.delivered)) + " ")];
      }
    }])
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;