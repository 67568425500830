"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-form", {
    ref: "form",
    staticStyle: {
      "max-width": "500px"
    },
    attrs: {
      model: _vm.form,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SERVICE COMMISSION"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.commissionService,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commissionService", $$v);
      },
      expression: "form.commissionService"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_vm._v("%")])], 2)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "PRODUCT COMMISSION"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.commissionProduct,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commissionProduct", $$v);
      },
      expression: "form.commissionProduct"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_vm._v("%")])], 2)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "VOUCHER COMMISSION"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.commissionVoucher,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commissionVoucher", $$v);
      },
      expression: "form.commissionVoucher"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_vm._v("%")])], 2)], 1)], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;