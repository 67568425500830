"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartonType = void 0;
var CartonType;
exports.CartonType = CartonType;

(function (CartonType) {
  CartonType["Normal"] = "NORMAL";
  CartonType["NapGai"] = "NAP_GAI";
  CartonType["Solar"] = "SOLAR";
})(CartonType || (exports.CartonType = CartonType = {}));