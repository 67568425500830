"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productionWorkMachineApi = void 0;

var _request = require("@/utils/request");

var productionWorkMachineApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/productionWorkMachine',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/productionWorkMachine',
      data: data,
      method: 'post'
    });
  },
  pause: function pause(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWorkMachine/".concat(id, "/pause"),
      method: 'patch'
    });
  },
  resume: function resume(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWorkMachine/".concat(id, "/resume"),
      method: 'patch'
    });
  },
  complete: function complete(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWorkMachine/".concat(id, "/complete"),
      method: 'patch'
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWorkMachine/".concat(id),
      method: 'delete'
    });
  }
};
exports.productionWorkMachineApi = productionWorkMachineApi;