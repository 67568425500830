"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KpiConfigType = void 0;
var KpiConfigType;
exports.KpiConfigType = KpiConfigType;

(function (KpiConfigType) {
  KpiConfigType["MisaCustomer"] = "MISA_CUSTOMER";
  KpiConfigType["MisaLead"] = "MISA_LEAD";
})(KpiConfigType || (exports.KpiConfigType = KpiConfigType = {}));