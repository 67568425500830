"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingDetail,
      expression: "loadingDetail"
    }]
  }, [_c("div", [_c("div", {
    staticClass: "customer-info"
  }, [_c("div", {
    staticClass: "avatar-box"
  }, [_vm.todo.staff ? [!_vm.todo.staff.avatar ? _c("span", [_vm._v(" " + _vm._s(_vm.todo.staff.name[0]) + " ")]) : _c("img", {
    attrs: {
      src: _vm.$baseUrlMedia + _vm.todo.staff.avatar,
      alt: ""
    }
  })] : _vm._e()], 2), _c("div", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_c("span", {
    staticClass: "customer-name"
  }, [_vm._v(_vm._s(_vm.todo.staff.name))]), _c("br"), _vm.todo.staff ? _c("span", {
    staticClass: "customer-phone"
  }, [_vm._v(_vm._s(_vm.todo.staff.phone))]) : _vm._e()])]), _c("div", {
    staticClass: "text-color--black"
  }, [_c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Công việc:")]), _c("span", [_vm._v(" " + _vm._s(_vm.todo.content) + " ")])]), !_vm.todo.isDone ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      loading: _vm.loadingDone,
      type: "primary",
      size: "mini",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleDone
    }
  }, [_vm._v("Hoàn thành")]) : _vm._e(), _vm.visibleDelete ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      loading: _vm.loadingDelete,
      type: "danger",
      size: "mini",
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa")]) : _vm._e()], 1), _c("hr"), _c("div", [_c("div", {
    staticClass: "time-box"
  }, [_c("div", [_vm._v(" " + _vm._s(_vm.todo.quote.customer.name) + " - " + _vm._s(_vm.todo.quote.customer.phone) + " ")]), _c("label", {
    staticClass: "time",
    style: {
      color: _vm.QuoteStatusColor[_vm.todo.quote.status]
    }
  }, [_vm._v(" " + _vm._s(_vm.QuoteStatusTrans[_vm.todo.quote.status]) + " ")]), _c("br"), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleClickDetailQuote
    }
  }, [_vm._v(" Xem chi tiết ")])], 1)])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;