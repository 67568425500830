"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.iterator.js");

var _vue = _interopRequireDefault(require("vue"));

var _index = _interopRequireDefault(require("@/components/SvgIcon/index.vue"));

// svg component
// register globally
_vue.default.component('svg-icon', _index.default);

var req = require.context('./svg', false, /\.svg$/);

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};

requireAll(req);