"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "top"
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.MoldBoxTypeTrans, function (item, key) {
    return _c("el-tab-pane", {
      key: key,
      attrs: {
        label: item,
        name: key
      }
    }, [_c("CartonCostTab", {
      attrs: {
        boxType: key
      }
    })], 1);
  }), 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;