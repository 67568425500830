"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Quét QR LSX",
      visible: _vm.visible,
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.handleClose
    }
  }, [_vm.error ? _c("p", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e(), _vm.status == "fetching" ? _c("div", {
    staticStyle: {
      color: "red",
      "font-weight": "bold"
    }
  }, [_vm._v(" Đang tải dữ liệu ")]) : _vm._e(), _vm.workOrder ? _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "24px",
      color: "#000",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.workOrder.code))]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.name))]), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-receiving",
      size: "mini"
    },
    on: {
      click: _vm.handleImport
    }
  }, [_vm._v("Nhập kho")]), _vm.status == "ok" ? [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-magic-stick",
      size: "mini"
    },
    on: {
      click: _vm.scanOther
    }
  }, [_vm._v("Quét mã khác")]), _vm.productionReport ? [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: function click($event) {
        return _vm.handlePrint("tp");
      }
    }
  }, [_vm._v("In phiếu TP")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: function click($event) {
        return _vm.handlePrint("btp");
      }
    }
  }, [_vm._v("In phiếu BTP, NVL")])] : _vm._e()] : _vm._e()], 2)]) : _vm._e(), _vm.$devMode ? _c("p", [_vm._v(" Last result: "), _c("b", [_vm._v(_vm._s(_vm.result))])]) : _vm._e(), _c("div", {
    staticStyle: {
      cursor: "pointer",
      width: "40px",
      height: "40px",
      position: "absolute",
      "z-index": "1"
    },
    on: {
      click: _vm.switchCamera
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: _vm.switchCameraImg,
      alt: ""
    }
  })]), _vm.visibleScan ? _c("QrcodeStream", {
    attrs: {
      paused: _vm.paused,
      constraints: {
        facingMode: _vm.facingMode
      },
      formats: ["qr_code"]
    },
    on: {
      decode: _vm.onDetect,
      error: _vm.onError
    }
  }) : _vm._e(), _c("CreateReportQuantityModal", {
    ref: "CreateReportQuantityModal",
    on: {
      submitOk: _vm.handleReportOk
    }
  }), _vm.productionReport ? _c("ReportTPPrint", {
    ref: "ReportTPPrint",
    attrs: {
      productionReport: _vm.productionReport,
      workOrder: _vm.workOrder
    }
  }) : _vm._e(), _vm.productionReport ? _c("ReportBTPPrint", {
    ref: "ReportBTPPrint",
    attrs: {
      productionReport: _vm.productionReport,
      workOrder: _vm.workOrder
    }
  }) : _vm._e(), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;