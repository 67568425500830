"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Sắp xếp",
      visible: _vm.visible,
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("draggable", _vm._b({
    on: {
      start: function start($event) {
        _vm.isDragging = true;
      },
      end: function end($event) {
        _vm.isDragging = false;
      }
    },
    model: {
      value: _vm.data,
      callback: function callback($$v) {
        _vm.data = $$v;
      },
      expression: "data"
    }
  }, "draggable", _vm.dragOptions, false), [_c("transition-group", _vm._l(_vm.data, function (element, index) {
    return _c("div", {
      key: element.id,
      staticClass: "sort-item"
    }, [_c("i", {
      staticClass: "el-icon-rank"
    }), _c("span", [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(element.name) + " ")])]);
  }), 0)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;