"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merchantPermissionApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var merchantPermissionApi = {
  findAll: function findAll() {
    return (0, _request.default)({
      url: "/merchantPermission"
    });
  },
  import: function _import(data) {
    return (0, _request.default)({
      url: "/merchantPermission/import",
      data: data,
      method: "post"
    });
  }
};
exports.merchantPermissionApi = merchantPermissionApi;