"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaCrmApi = void 0;

var _request = require("@/utils/request");

var misaCrmApi = {
  createActivityForLead: function createActivityForLead(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/activity/lead',
      data: data,
      method: 'post'
    });
  },
  createActivityForCustomer: function createActivityForCustomer(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/activity/customer',
      data: data,
      method: 'post'
    });
  },
  createActivityForOpportunity: function createActivityForOpportunity(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/activity/opportunity',
      data: data,
      method: 'post'
    });
  },
  createNoteForCustomer: function createNoteForCustomer(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/note/customer',
      data: data,
      method: 'post'
    });
  },
  createNoteForOpportunity: function createNoteForOpportunity(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/note/opportunity',
      data: data,
      method: 'post'
    });
  },
  createNoteForLead: function createNoteForLead(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/note/lead',
      data: data,
      method: 'post'
    });
  },
  syncCustomer: function syncCustomer() {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/sync/customer',
      method: 'post'
    });
  },
  syncActivity: function syncActivity() {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/sync/activity',
      method: 'post'
    });
  },
  syncLead: function syncLead() {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/sync/lead',
      method: 'post'
    });
  },
  syncOpportunity: function syncOpportunity() {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/sync/opportunity',
      method: 'post'
    });
  },
  syncQuote: function syncQuote() {
    return (0, _request.request)({
      url: '/v1/admin/misaCrm/sync/quote',
      method: 'post'
    });
  }
};
exports.misaCrmApi = misaCrmApi;