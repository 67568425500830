"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaEmployeeApi = void 0;

var _request = require("@/utils/request");

var misaEmployeeApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaEmployee',
      params: params
    });
  },
  sync: function sync() {
    return (0, _request.request)({
      url: '/v1/admin/misaEmployee/sync',
      method: 'post'
    });
  }
};
exports.misaEmployeeApi = misaEmployeeApi;