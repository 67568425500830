"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var productApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/product",
      params: params
    });
  },
  findAllV2: function findAllV2(params) {
    return (0, _request.default)({
      url: "/v1/admin/product/v2",
      params: params
    });
  },
  findPendingQuantity: function findPendingQuantity(params) {
    return (0, _request.default)({
      url: "/v1/admin/product/pending-quantity",
      params: params
    });
  },
  sort: function sort(data) {
    return (0, _request.default)({
      url: "/v1/admin/product/sort",
      data: data,
      method: 'put'
    });
  },
  pricingDetail: function pricingDetail(params) {
    return (0, _request.default)({
      url: "/v1/admin/product/pricing-detail",
      params: params
    });
  },
  pricingDetailByCategory: function pricingDetailByCategory(params) {
    return (0, _request.default)({
      url: "/v1/admin/product/pricing-detail/category",
      params: params
    });
  },
  pricingDetailByGroup: function pricingDetailByGroup(productGroupId, params) {
    return (0, _request.default)({
      url: "/v1/admin/product/pricing-detail/group/".concat(productGroupId),
      params: params
    });
  },
  pricingDetailByGroupNoSave: function pricingDetailByGroupNoSave(productGroupId, params) {
    return (0, _request.default)({
      url: "/v1/admin/product/pricing-detail/group/".concat(productGroupId, "/no-save"),
      params: params
    });
  },
  sync: function sync() {
    return (0, _request.default)({
      url: "/v1/admin/product/craw",
      method: 'post'
    });
  },
  pricingByCustomer: function pricingByCustomer(params) {
    return (0, _request.default)({
      url: "/v1/admin/product/pricing-by-customer",
      params: params
    });
  },
  generateToken: function generateToken() {
    return (0, _request.default)({
      url: "/v1/admin/product/generate-token",
      method: 'post'
    });
  },
  updateCartonProperty: function updateCartonProperty() {
    return (0, _request.default)({
      url: "/v1/admin/product/update-carton-property",
      method: 'put'
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/product",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/update"),
      data: data,
      method: "put"
    });
  },
  linkMisaProduct: function linkMisaProduct(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/linkMisaProduct"),
      data: data,
      method: "patch"
    });
  },
  linkMisaInventory: function linkMisaInventory(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/linkMisaInventory"),
      data: data,
      method: "patch"
    });
  },
  updateV2: function updateV2(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/update-v2"),
      data: data,
      method: "put"
    });
  },
  updateProduct: function updateProduct(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id),
      data: data,
      method: "put"
    });
  },
  updateNameVat: function updateNameVat(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/update/nameVat"),
      data: data,
      method: "put"
    });
  },
  updateExportVat: function updateExportVat(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/update/is-export-vat"),
      data: data,
      method: "put"
    });
  },
  updateExchangeVat: function updateExchangeVat(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/update/exchange-vat"),
      data: data,
      method: "put"
    });
  },
  orderHistory: function orderHistory(id, params) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/history"),
      params: params
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/delete"),
      method: "post"
    });
  },
  findRates: function findRates(id, params) {
    return (0, _request.default)({
      url: "/v1/admin/product/".concat(id, "/rates"),
      params: params
    });
  }
};
exports.productApi = productApi;