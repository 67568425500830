"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật chuyến" : "Thêm mới chuyến",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    },
    nativeOn: {
      submit: function submit($event) {
        $event.preventDefault();
        return false.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "quick-name"
  }, _vm._l(_vm.drivers, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "driver-item",
      staticStyle: {
        display: "inline-block",
        position: "relative"
      }
    }, [_c("el-button", {
      attrs: {
        type: "default",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.handleClickQuickName(item.name);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name))]), _c("span", {
      staticClass: "driver-item--edit",
      staticStyle: {
        position: "absolute",
        right: "0",
        top: "0"
      },
      attrs: {
        type: "primary",
        size: "mini"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.handleUpdateDriver(item);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-edit"
    })])], 1);
  }), 0), _c("el-button", {
    staticStyle: {
      "margin-top": "4px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreateDriver
    }
  }), _c("el-form-item", {
    attrs: {
      label: "Tên chuyến xe",
      prop: "privateName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Xe anh Duy, xe anh tuấn"
    },
    model: {
      value: _vm.form.privateName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "privateName", $$v);
      },
      expression: "form.privateName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Ghi chú",
      prop: "note"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1), _c("DriverModal", {
    ref: "DriverModal",
    on: {
      submitOk: _vm.fetchDrivers
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;