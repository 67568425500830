"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_vm._m(0), _c("el-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "Phân cách bởi dấu ','"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSync.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-left": "4px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-refresh",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSync
    }
  }, [_vm._v("Đồng bộ")]), _vm.errorMessage ? _c("div", {
    staticStyle: {
      color: "red",
      "margin-top": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e(), _vm.successMessage ? _c("div", {
    staticStyle: {
      color: "rgb(62, 167, 13)",
      "margin-top": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.successMessage) + " ")]) : _vm._e(), _vm._m(1)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      "margin-bottom": "4px"
    }
  }, [_c("label", {
    staticStyle: {
      color: "red"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Cách 1: Gõ ID đơn hàng vào đây và đồng bộ")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("div", [_c("label", {
    staticStyle: {
      color: "red"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Cách 2:")])]), _c("div", [_vm._v(" Bước 1: Vào link này: "), _c("a", {
    staticStyle: {
      color: "#428bca"
    },
    attrs: {
      href: "https://actapp.misa.vn/app/SA/SAOrder",
      target: "_blank"
    }
  }, [_vm._v("https://actapp.misa.vn/app/SA/SAOrder")])]), _c("div", [_vm._v("Bước 2: Tìm đơn cần đồng bộ, nhấn vào đơn hàng để xem chi tiết")]), _c("div", [_vm._v("Bước 3: Nhấn nút Craw đơn hàng")]), _c("div", [_vm._v(" Bước 4: Nếu thấy Craw ok là thành công, nếu thất bại thì nhắn lên nhóm Feedback phần mềm HĐ ")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;