"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cartonApi = void 0;

var _request = require("@/utils/request");

var cartonApi = {
  log: function log(data) {
    return (0, _request.request)({
      url: '/v1/public/carton/log',
      data: data,
      method: 'post'
    });
  },
  logToFile: function logToFile(data) {
    return (0, _request.request)({
      url: '/v1/public/carton/log/file',
      data: data,
      method: 'post'
    });
  }
};
exports.cartonApi = cartonApi;