"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.productionReports,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Sản lượng",
      align: "right",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_vm._v(" " + _vm._s(_vm.$formatNumber(row.quantity)) + " ")]), row.productionWork.productionStep.isStrict ? [_c("span", [!row.isBlock ? _c("el-tooltip", {
          attrs: {
            content: "Cần kho duyệt",
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "el-icon-warning",
          staticStyle: {
            color: "red"
          }
        })]) : _c("el-tooltip", {
          attrs: {
            content: "Kho đã duyệt",
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "el-icon-check",
          staticStyle: {
            color: "#67c23a"
          }
        })])], 1)] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hao hụt",
      align: "right",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumber(row.loss)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Canh máy",
      align: "right",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumber(row.canhMay)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hình ảnh",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("el-link", {
          attrs: {
            type: "primary",
            href: _vm.$baseUrl + row.image,
            target: "_blank"
          }
        }, [_c("i", {
          staticClass: "el-icon-info"
        }), _vm._v(" Hình ảnh ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.note) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "LSX"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(row.productionWork.workOrder.code) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Bước"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.productionWork.productionStep.name) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Người báo cáo",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(" " + _vm._s(row.staff ? row.staff.name : "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày báo cáo",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("el-dropdown", {
          attrs: {
            trigger: "click"
          },
          on: {
            command: function command(value) {
              return _vm.onCommand(value, row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-more"
          }
        }), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_vm.visibleUpdate ? _c("el-dropdown-item", {
          attrs: {
            command: "update"
          }
        }, [_vm._v("Cập nhật")]) : _vm._e(), _vm.visibleInspec ? _c("el-dropdown-item", {
          attrs: {
            command: "inspec"
          }
        }, [_vm._v(_vm._s(row.isBlock ? "Bỏ duyệt" : "Duyệt"))]) : _vm._e(), _c("el-dropdown-item", {
          attrs: {
            command: "print-tp"
          }
        }, [_vm._v("In phiếu TP")]), _c("el-dropdown-item", {
          attrs: {
            command: "print-btp"
          }
        }, [_vm._v("In phiếu BTP, NVL")])], 1)], 1), _vm.visibleDelete ? _c("el-button", {
          staticStyle: {
            "margin-left": "4px"
          },
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.onDelete(row);
            }
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;