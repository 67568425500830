"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "misa-customer-tab"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "scroll-height",
    attrs: {
      sm: 18,
      xl: 18,
      offset: 0
    }
  }, [_vm.isSyncQuote == false ? _c("el-alert", {
    attrs: {
      title: "Báo giá chưa được đồng bộ",
      type: "warning",
      closable: false
    }
  }) : _vm._e(), _c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tên, sdt",
      size: "medium"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhiệm vụ")]), _c("el-select", {
    attrs: {
      size: "medium",
      value: _vm.query.isHasActivity,
      clearable: ""
    },
    on: {
      change: function change(value) {
        return _vm.handleChangeQuery("isHasActivity", value);
      }
    }
  }, [_c("el-option", {
    attrs: {
      label: "Có",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "Không",
      value: false
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhân viên misa")]), _c("el-select", {
    attrs: {
      size: "medium",
      value: _vm.query.misaEmployeeId,
      clearable: "",
      filterable: "",
      disabled: _vm.isFilterMisaEmployeeDisable
    },
    on: {
      change: function change(value) {
        return _vm.handleChangeQuery("misaEmployeeId", value);
      }
    }
  }, [_c("el-option", {
    attrs: {
      label: "Chưa có nhân viên",
      value: -1
    }
  }), _vm._l(_vm.misaEmployees, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " (").concat(item.code, ")"),
        value: item.id
      }
    });
  })], 2)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tag")]), _c("el-select", {
    attrs: {
      size: "medium",
      value: _vm.query.misaCustomerTagIds,
      clearable: "",
      filterable: ""
    },
    on: {
      change: function change(value) {
        return _vm.handleChangeQuery("misaCustomerTagIds", value);
      }
    }
  }, _vm._l(_vm.misaCustomerTags, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("span", {
      staticStyle: {
        display: "inline-block",
        width: "12px",
        height: "12px",
        "border-radius": "50%",
        "vertical-align": "middle"
      },
      style: {
        background: item.color
      }
    }), _c("span", [_vm._v(" " + _vm._s(item.name) + " ")])]);
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Khách chặn")]), _c("el-select", {
    attrs: {
      size: "medium",
      value: _vm.query.isBlocked,
      clearable: ""
    },
    on: {
      change: function change(value) {
        return _vm.handleChangeQuery("isBlocked", value);
      }
    }
  }, [_c("el-option", {
    attrs: {
      label: "Có",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "Không",
      value: false
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhóm khách hàng")]), _c("el-select", {
    attrs: {
      size: "medium",
      value: _vm.query.misaCustomerGroupId,
      clearable: ""
    },
    on: {
      change: function change(value) {
        return _vm.handleChangeQuery("misaCustomerGroupId", value);
      }
    }
  }, _vm._l(_vm.misaCustomerGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Sắp xếp")]), _c("el-select", {
    attrs: {
      size: "medium",
      value: _vm.query.sortBy,
      clearable: ""
    },
    on: {
      change: function change(value) {
        return _vm.handleChangeQuery("sortBy", value);
      }
    }
  }, _vm._l(_vm.SortBy, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.value
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đơn hàng")]), _c("el-select", {
    attrs: {
      size: "medium",
      clearable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.isNoOrderQuery,
      callback: function callback($$v) {
        _vm.isNoOrderQuery = $$v;
      },
      expression: "isNoOrderQuery"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Có đơn hàng",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "Không có đơn hàng",
      value: false
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v(" Tìm kiếm ")])], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loadingSync,
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.handleSync
    }
  }, [_vm._v(" Đồng bộ ")])], 1)]), _vm.visibleNoOrderFilter ? _c("div", [_c("el-checkbox", {
    attrs: {
      label: true,
      indeterminate: false
    },
    on: {
      change: _vm.handleChangeNoOrder
    },
    model: {
      value: _vm.isFilterNoOrder,
      callback: function callback($$v) {
        _vm.isFilterNoOrder = $$v;
      },
      expression: "isFilterNoOrder"
    }
  }, [_c("span", {
    staticClass: "font-bold",
    staticStyle: {
      "font-size": "18px",
      color: "red"
    }
  }, [_vm._v("Chưa có đơn nào")])]), _c("el-checkbox", {
    attrs: {
      label: true,
      indeterminate: false
    },
    on: {
      change: _vm.handleChangeNoOrderFromLastQuote
    },
    model: {
      value: _vm.isNoOrderFromLastQuote,
      callback: function callback($$v) {
        _vm.isNoOrderFromLastQuote = $$v;
      },
      expression: "isNoOrderFromLastQuote"
    }
  }, [_c("span", {
    staticClass: "font-bold",
    staticStyle: {
      "font-size": "18px",
      color: "red"
    }
  }, [_vm._v("Chưa có đơn kể từ báo giá cuối")])])], 1) : _vm._e(), _c("section", {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c("div", {
    staticClass: "summary-item green"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.summary.orderSales)) + "đ")])]), _c("div", {
    staticClass: "summary-item blue"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng đơn: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.summary.numOfOrders)))])]), _c("div", {
    staticClass: "summary-item green"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền báo giá: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.summaryQuote.totalMoney)) + "đ")])]), _c("div", {
    staticClass: "summary-item blue"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng báo giá: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.summaryQuote.totalQuote)))])])]), _c("div", {
    staticClass: "table-container"
  }, [_c("MisaCustomerTable", {
    attrs: {
      data: _vm.data,
      loading: _vm.loading,
      isVisibleFollow: _vm.isFollow && _vm.user.role.id != 1
    },
    on: {
      clickCustomer: _vm.handleClickCustomer,
      follow: _vm.handleFollow,
      unFollow: _vm.handleUnFollow,
      createNote: _vm.handleCreateNote,
      createActivity: _vm.handleCreateActivity,
      link: _vm.handleLink,
      clickTag: _vm.handleClickTag
    }
  })], 1)], 1), _c("el-col", {
    staticClass: "scroll-height",
    attrs: {
      sm: 6,
      xl: 6,
      offset: 0
    }
  }, [_c("section", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "flex-end",
      position: "sticky",
      top: "0"
    }
  }, [_c("el-select", {
    on: {
      change: _vm.handleChangeDateFilter
    },
    model: {
      value: _vm.dateFilter,
      callback: function callback($$v) {
        _vm.dateFilter = $$v;
      },
      expression: "dateFilter"
    }
  }, _vm._l(_vm.dateOptions, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.label,
        value: item.label
      }
    });
  }), 1), _c("MisaCustomerKpi", {
    attrs: {
      fromAt: _vm.dateValues[0],
      toAt: _vm.dateValues[1]
    }
  }), _c("MisaLeadKpi", {
    attrs: {
      fromAt: _vm.dateValues[0],
      toAt: _vm.dateValues[1]
    }
  }), _c("MisaCustomerSummaryLog", {
    attrs: {
      fromAt: _vm.dateValues[0],
      toAt: _vm.dateValues[1]
    },
    on: {
      clickStaff: _vm.handleClickStaff
    }
  }), _c("MisaLeadSummaryLog", {
    attrs: {
      fromAt: _vm.dateValues[0],
      toAt: _vm.dateValues[1]
    },
    on: {
      clickStaff: _vm.handleClickStaff
    }
  }), _c("TopMisaQuote")], 1)])], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("MisaCustomerModal", {
    ref: "MisaCustomerModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("CreateMisaNote", {
    ref: "CreateMisaNote",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("CreateMisaActivityCustomer", {
    ref: "CreateMisaActivityCustomer",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("CustomerKpiConfigModal", {
    ref: "CustomerKpiConfigModal",
    on: {
      submitOk: _vm.handleChangeKpiConfig
    }
  }), _c("ChangeOwnerMisaCustomerModal", {
    ref: "ChangeOwnerMisaCustomerModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("UpdateTagCustomerModal", {
    ref: "UpdateTagCustomerModal",
    on: {
      submitOk: _vm.updateTagOk
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;