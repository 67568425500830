"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [!_vm.product.isEdit ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.product.transportImportPrice)) + " ")]) : _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    ref: "input",
    on: {
      blur: _vm.handleBlur
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.$emit("update");
      }
    },
    model: {
      value: _vm.product.tmpTransportImportPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "tmpTransportImportPrice", $$v);
      },
      expression: "product.tmpTransportImportPrice"
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;