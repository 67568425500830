"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.findOne = findOne;
exports.getInfo = getInfo;
exports.getList = getList;
exports.login = login;
exports.remove = remove;
exports.reset = reset;
exports.resetPassword = resetPassword;
exports.staffApi = void 0;
exports.update = update;
exports.verifyOtp = verifyOtp;

var _request = _interopRequireDefault(require("@/utils/request"));

var staffApi = {
  login: login,
  getInfo: getInfo,
  reset: reset,
  findOne: findOne,
  resetPassword: resetPassword,
  getList: getList,
  add: add,
  update: update,
  remove: remove,
  verifyOtp: verifyOtp,
  updatePassword: function updatePassword(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/update/password',
      method: 'post',
      data: data
    });
  },
  block: function block(staffId) {
    return (0, _request.default)({
      url: "/v1/admin/staff/".concat(staffId, "/block"),
      method: 'post'
    });
  },
  unblock: function unblock(staffId) {
    return (0, _request.default)({
      url: "/v1/admin/staff/".concat(staffId, "/unblock"),
      method: 'post'
    });
  }
};
exports.staffApi = staffApi;

function login(data) {
  return (0, _request.default)({
    url: "/v1/admin/auth/login",
    method: "post",
    data: data
  });
}

function verifyOtp(data) {
  return (0, _request.default)({
    url: "/v1/admin/auth/otp",
    method: "post",
    data: data
  });
}

function getInfo() {
  return (0, _request.default)({
    url: "/v1/admin/auth/profile",
    method: "get"
  });
}

function reset(id, data) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(id, "/password/reset"),
    method: "post",
    data: data
  });
}

function findOne(userId) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId),
    method: "get"
  });
}

function resetPassword(userId, data) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId, "/update/password"),
    method: "put",
    data: data
  });
}

function getList(listQuery) {
  return (0, _request.default)({
    url: "/v1/admin/staff",
    method: "get",
    params: listQuery
  });
}

function add(data) {
  return (0, _request.default)({
    url: "/v1/admin/staff",
    method: "post",
    data: data
  });
}

function update(userId, data) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId, "/update"),
    method: "put",
    data: data
  });
}

function remove(userId) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId, "/delete"),
    method: "delete"
  });
}