"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.taskConfigApi = void 0;

var _request = require("@/utils/request");

var taskConfigApi = {
  findByType: function findByType(_ref) {
    var type = _ref.type;
    return (0, _request.request)({
      url: '/v1/admin/taskConfig/type',
      params: {
        type: type
      }
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/taskConfig/".concat(id),
      method: 'patch',
      data: data
    });
  }
};
exports.taskConfigApi = taskConfigApi;