"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inventoryApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var inventoryApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/admin/inventoryBill',
      params: params
    });
  },
  findPoDetailInventory: function findPoDetailInventory(params) {
    return (0, _request.default)({
      url: '/v1/admin/inventoryBill/po-detail-inventory',
      params: params
    });
  },
  verifyPoDetailInventory: function verifyPoDetailInventory(poDetailInventoryId, quantity) {
    return (0, _request.default)({
      url: "/v1/admin/inventoryBill/".concat(poDetailInventoryId, "/verify"),
      data: {
        quantity: quantity
      },
      method: 'put'
    });
  },
  deletePoDetailInventory: function deletePoDetailInventory(poDetailInventoryId) {
    return (0, _request.default)({
      url: "/v1/admin/inventoryBill/".concat(poDetailInventoryId),
      method: 'delete'
    });
  }
};
exports.inventoryApi = inventoryApi;