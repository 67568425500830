"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "1200px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên khách hàng"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.customerName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customerName", $$v);
      },
      expression: "form.customerName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên công ty",
      prop: "companyName"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mã số thuế",
      prop: "companyTaxCode"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.companyTaxCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyTaxCode", $$v);
      },
      expression: "form.companyTaxCode"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Địa chỉ",
      prop: "companyAddress"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.companyAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyAddress", $$v);
      },
      expression: "form.companyAddress"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    staticClass: "is-required",
    attrs: {
      label: "Email",
      prop: ""
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      placeholder: "Nhập email",
      "allow-create": "",
      filterable: ""
    },
    model: {
      value: _vm.form.emails,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "emails", $$v);
      },
      expression: "form.emails"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Phương thức thanh toán",
      prop: "paymentMethod"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.paymentMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paymentMethod", $$v);
      },
      expression: "form.paymentMethod"
    }
  }, _vm._l(_vm.PaymentMethodTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "warning",
      size: "medium"
    },
    on: {
      click: _vm.pasteCompanyInfo
    }
  }, [_vm._v(" Dán thông tin công ty ")]), _c("br"), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addDetail
    }
  }, [_vm._v("Thêm sản phẩm")]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("table", {
    staticClass: "table"
  }, [_c("thead", [_c("tr", [_c("th", {
    staticStyle: {
      width: "60px"
    }
  }, [_vm._v("STT")]), _c("th", {
    staticClass: "text-left",
    staticStyle: {
      width: "250px"
    }
  }, [_vm._v("Sản phẩm")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("Đơn vị")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("SL")]), _c("th", {
    staticStyle: {
      width: "120px"
    }
  }, [_vm._v("Đơn giá")]), _c("th", {
    staticClass: "text-right",
    staticStyle: {
      width: "100px"
    }
  }, [_vm._v("Thành tiền")])])]), _c("tbody", [_vm._l(_vm.details, function (item, index) {
    return _c("tr", {
      key: index
    }, [_c("td", {
      staticClass: "text-center"
    }, [_c("span", [_vm._v(_vm._s(index + 1))]), _c("i", {
      staticClass: "el-icon-close delete-btn",
      on: {
        click: function click($event) {
          return _vm.deleteDetail(item);
        }
      }
    })]), _c("td", [_c("el-select", {
      attrs: {
        "remote-method": _vm.fetchProducts,
        filterable: "",
        remote: "",
        "allow-create": "",
        "default-first-option": ""
      },
      on: {
        change: function change(val) {
          return _vm.changeProductDetail(val, item);
        }
      },
      model: {
        value: item.productId,
        callback: function callback($$v) {
          _vm.$set(item, "productId", $$v);
        },
        expression: "item.productId"
      }
    }, _vm._l(_vm.products, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item.name,
          value: item.id
        }
      });
    }), 1)], 1), _c("td", {
      staticClass: "text-center"
    }, [_c("el-input", {
      staticClass: "text-center",
      model: {
        value: item.unit,
        callback: function callback($$v) {
          _vm.$set(item, "unit", $$v);
        },
        expression: "item.unit"
      }
    })], 1), _c("td", {
      staticClass: "text-center"
    }, [_c("el-input", {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: {
          alias: "decimal",
          groupSeparator: ".",
          rightAlign: false,
          autoGroup: true,
          repeat: 20,
          autoUnmask: true,
          allowMinus: false
        },
        expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
      }],
      staticClass: "text-center",
      model: {
        value: item.quantity,
        callback: function callback($$v) {
          _vm.$set(item, "quantity", $$v);
        },
        expression: "item.quantity"
      }
    })], 1), _c("td", {
      staticClass: "text-center"
    }, [_c("el-input", {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: {
          alias: "decimal",
          groupSeparator: ".",
          rightAlign: false,
          autoGroup: true,
          repeat: 20,
          autoUnmask: true,
          allowMinus: false
        },
        expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
      }],
      staticClass: "text-center",
      model: {
        value: item.price,
        callback: function callback($$v) {
          _vm.$set(item, "price", $$v);
        },
        expression: "item.price"
      }
    })], 1), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(Math.round(item.quantity * item.price))) + "đ")])]);
  }), _c("tr", {
    staticClass: "footer"
  }, [_c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("Cộng tiền hàng")]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalProductPrice, 0, ".")) + "đ")])]), _c("tr", {
    staticClass: "footer"
  }, [_c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("Thuế giá trị gia tăng(10%)")]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.vat10, 0, ".")) + "đ")])]), _c("tr", {
    staticClass: "footer"
  }, [_c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("Thuế giá trị gia tăng(8%)")]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.vat8, 0, ".")) + "đ")])]), _c("tr", {
    staticClass: "footer"
  }, [_c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền thanh toán")])]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalPrice, 0, ".")) + "đ")])])], 2)])]), _c("div", {
    staticStyle: {
      color: "#000",
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Bằng chữ: ")]), _c("span", [_vm._v(_vm._s(_vm.amountWord))])]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", [_vm.status == "create" ? _c("el-button", {
    staticClass: "font-size--medium font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]) : _vm._e()], 1), _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v(_vm._s(_vm.status == "create" ? "Tạo đơn hàng" : "Cập nhật"))])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;