"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "employee-page"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      size: "medium",
      placeholder: "Tìm theo tên,sdt, username"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" Thêm mới ")])], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-refresh",
      loading: _vm.loadingSyncSeller
    },
    on: {
      click: _vm.handleSyncSeller
    }
  }, [_vm._v("Đồng bộ seller")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.data,
      fit: ""
    },
    on: {
      "row-click": _vm.handleUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Mã NV",
      prop: "code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(row.code))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Username",
      prop: "username"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-image", {
          staticStyle: {
            "border-radius": "50%",
            width: "50px",
            height: "50px"
          },
          attrs: {
            src: _vm.$baseUrlMedia + row.avatar,
            fit: "cover",
            lazy: true
          },
          scopedSlots: _vm._u([{
            key: "error",
            fn: function fn() {
              return [_c("img", {
                staticStyle: {
                  "border-radius": "50%",
                  width: "50px",
                  height: "50px"
                },
                attrs: {
                  src: _vm.defaultAvatar,
                  alt: ""
                }
              })];
            },
            proxy: true
          }], null, true)
        }), _c("div", [_vm._v(" " + _vm._s(row.username) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tên",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Nhóm user",
      prop: "staffGroup.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Công ty",
      prop: "company.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "SDT",
      prop: "phone"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Email",
      prop: "email"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Trạng thái"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.isBlock ? _c("el-tag", {
          attrs: {
            type: "danger",
            effect: "dark"
          }
        }, [_vm._v(" Bị khoá ")]) : _c("el-tag", {
          attrs: {
            type: "success",
            effect: "dark"
          }
        }, [_vm._v(" Còn hoạt động ")]), _c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            "margin-top": "12px"
          },
          attrs: {
            type: row.isBlock ? "warning" : "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleBlock(row);
            }
          }
        }, [_vm._v(_vm._s(row.isBlock ? "Mở khoá" : "Khóa"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hạn chế đăng nhập",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.startHour && row.endHour ? _c("div", [_c("span", [_vm._v(_vm._s(row.startHour))]), _c("br"), _c("span", [_vm._v("-")]), _c("br"), _c("span", [_vm._v(_vm._s(row.endHour))])]) : _vm._e(), _c("div", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return false.apply(null, arguments);
            }
          }
        }, [_c("el-switch", {
          staticStyle: {
            display: "block"
          },
          attrs: {
            value: row.isRestrict,
            "active-color": "#13ce66"
          },
          on: {
            change: function change($event) {
              return _vm.handleChangeRestrict(row);
            }
          }
        }), _c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            "margin-top": "8px"
          },
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleLogout(row);
            }
          }
        }, [_vm._v("Đăng xuất")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Bắt buộc GPS",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("div", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return false.apply(null, arguments);
            }
          }
        }, [_c("el-switch", {
          staticStyle: {
            display: "block"
          },
          attrs: {
            value: row.isRequiredGPS,
            "active-color": "#13ce66"
          },
          on: {
            change: function change($event) {
              return _vm.handleChangeRequiredGPS(row);
            }
          }
        })], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Quyền",
      prop: "role.name"
    }
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("employee-modal", {
    ref: "employeeModal",
    on: {
      "submit:success": _vm.fetchData
    }
  }), _c("change-order-staff-modal", {
    ref: "ChangeOrderStaffModal",
    on: {
      "submit:ok": _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;