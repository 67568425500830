"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trangvangDataApi = void 0;

var _request = require("@/utils/request");

var trangvangDataApi = {
  craw: function craw(crawLinkId) {
    return (0, _request.request)({
      url: "/v1/admin/trangvangData/".concat(crawLinkId, "/craw"),
      method: 'POST',
      timeout: 30 * 60 * 1e3
    });
  },
  reCraw: function reCraw(crawLinkLogId) {
    return (0, _request.request)({
      url: "/v1/admin/trangvangData/".concat(crawLinkLogId, "/re-craw"),
      method: 'POST',
      timeout: 30 * 60 * 1e3
    });
  }
};
exports.trangvangDataApi = trangvangDataApi;