"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "badge-todo-item",
    class: {
      expired: _vm.isExpired
    }
  }, [_c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.todo.content))]), _c("div", {
    staticClass: "time"
  }, [_c("i", {
    staticClass: "el-icon-timer"
  }), _c("span", [_vm._v(_vm._s(_vm.time))])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;