"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productionWorkApi = void 0;

var _request = require("@/utils/request");

var productionWorkApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/productionWork',
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id)
    });
  },
  checkReport: function checkReport(id, params) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/checkReport"),
      params: params
    });
  },
  summaryByStatus: function summaryByStatus(params) {
    return (0, _request.request)({
      url: '/v1/admin/productionWork/summary/status',
      params: params
    });
  },
  summaryByStep: function summaryByStep(params) {
    return (0, _request.request)({
      url: '/v1/admin/productionWork/summary/step',
      params: params
    });
  },
  updateStep: function updateStep(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/step"),
      method: 'patch',
      data: data
    });
  },
  updateNote: function updateNote(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/note"),
      method: 'patch',
      data: data
    });
  },
  updatePublic: function updatePublic(id, isPublic) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/public"),
      method: 'patch',
      data: {
        isPublic: isPublic
      }
    });
  },
  updateBatchPublic: function updateBatchPublic(ids, isPublic) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/batch/public",
      method: 'patch',
      data: {
        isPublic: isPublic,
        ids: ids
      }
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/productionWork',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id),
      data: data,
      method: 'patch'
    });
  },
  updateBatchStatus: function updateBatchStatus(productionWorkIds, status) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/batch/status",
      method: 'patch',
      data: {
        productionWorkIds: productionWorkIds,
        status: status
      }
    });
  },
  sort: function sort(data) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/sort",
      method: 'post',
      data: data
    });
  },
  start: function start(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/start"),
      method: 'patch',
      data: data
    });
  },
  pause: function pause(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/pause"),
      method: 'patch'
    });
  },
  resume: function resume(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/resume"),
      method: 'patch'
    });
  },
  undo: function undo(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/undo"),
      method: 'patch'
    });
  },
  complete: function complete(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/complete"),
      method: 'patch'
    });
  },
  end: function end(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id, "/end"),
      method: 'patch'
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/productionWork/".concat(id),
      method: 'delete'
    });
  }
};
exports.productionWorkApi = productionWorkApi;