"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleChangeTab
    },
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Tất cả",
      name: "1"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Tất cả "), _c("el-badge", {
    attrs: {
      value: _vm.total.all
    }
  })], 1), _c("MisaCustomerTab", {
    attrs: {
      query: _vm.query
    },
    on: {
      changeData: _vm.handleChangeData,
      changQuery: _vm.handleChangeQuery
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Lâu chưa mua",
      lazy: "",
      name: "2"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Lâu chưa mua "), _c("el-badge", {
    attrs: {
      value: _vm.total.late
    }
  })], 1), _c("MisaCustomerTab", {
    attrs: {
      query: _vm.query,
      buyType: "LATE"
    },
    on: {
      changeData: _vm.handleChangeData,
      changQuery: _vm.handleChangeQuery
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Sắp mua",
      name: "3",
      lazy: ""
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Sắp mua "), _c("el-badge", {
    attrs: {
      value: _vm.total.soon
    }
  })], 1), _c("MisaCustomerTab", {
    attrs: {
      query: _vm.query,
      buyType: "SOON"
    },
    on: {
      changeData: _vm.handleChangeData,
      changQuery: _vm.handleChangeQuery
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Báo giá trong 30 ngày",
      name: "4",
      lazy: ""
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Báo giá trong 30 ngày "), _c("el-badge", {
    attrs: {
      value: _vm.total.last30DaysQuote
    }
  })], 1), _c("MisaCustomerTab", {
    attrs: {
      query: _vm.query,
      isExist30DaysQuote: true
    },
    on: {
      changeData: _vm.handleChangeData,
      changQuery: _vm.handleChangeQuery
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Mua gần nhất (14 ngày)",
      name: "5",
      lazy: ""
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Mua gần nhất (14 ngày) "), _c("el-badge", {
    attrs: {
      value: _vm.total.exist14DayOrder
    }
  })], 1), _c("MisaCustomerTab", {
    attrs: {
      query: _vm.query,
      isExist14Order: true
    },
    on: {
      changeData: _vm.handleChangeData,
      changQuery: _vm.handleChangeQuery
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      lazy: "",
      name: "6"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Chưa có đơn "), _c("el-badge", {
    attrs: {
      value: _vm.total.noOrder
    }
  })], 1), _c("MisaCustomerTab", {
    attrs: {
      query: _vm.query,
      isNoOrder: true
    },
    on: {
      changeData: _vm.handleChangeData,
      changQuery: _vm.handleChangeQuery
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Đang follow",
      name: "7",
      lazy: ""
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Đang follow "), _c("el-badge", {
    attrs: {
      value: _vm.total.following
    }
  })], 1), _c("MisaCustomerTab", {
    attrs: {
      query: _vm.query,
      isFollow: true
    },
    on: {
      changeData: _vm.handleChangeData,
      changQuery: _vm.handleChangeQuery
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Sắp chăm sóc",
      name: "8",
      lazy: ""
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Sắp chăm sóc "), _c("el-badge", {
    attrs: {
      value: _vm.total.nextActivity
    }
  })], 1), _c("MisaCustomerTab", {
    attrs: {
      query: _vm.query,
      isGetNextActivity: true
    },
    on: {
      changeData: _vm.handleChangeData,
      changQuery: _vm.handleChangeQuery
    }
  })], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;