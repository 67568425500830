"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("div", {
    staticClass: "panel-shadow container",
    class: {
      invalid: _vm.isInvalid && _vm.vehicle.type == _vm.VehicleType.Other,
      valid: _vm.vehicle.type == _vm.VehicleType.Bike || !_vm.isInvalid
    }
  }, [_c("h3", {
    staticStyle: {
      margin: "0"
    }
  }, [_vm._v(_vm._s(_vm.vehicle.name) + " (" + _vm._s(_vm.$formatNumberVN(_vm.vehicle.weight)) + "Kg)")]), _vm.vehicle.type == _vm.VehicleType.Bike ? _c("div", {
    staticClass: "info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Shipper: ")]), _c("span", [_vm._v(_vm._s(_vm.totalShipper))])]) : _c("div", [_c("div", {
    staticClass: "info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tải trọng: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(Math.round(this.totalWeightReal))) + "Kg/" + _vm._s(_vm.$formatNumberVN(Math.round(_vm.vehicle.weight))) + "Kg ")])]), _c("div", {
    staticClass: "info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thể tích: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(this.totalVolumeReal / 1000, 1)) + "m"), _c("sup", [_vm._v("3")]), _vm._v("/" + _vm._s(_vm.$formatNumberVN(_vm.volume, 1)) + "m"), _c("sup", [_vm._v("3")])])])])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;