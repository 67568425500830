"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật máy" : "Thêm mới máy",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên máy",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Hoạt động với các bước",
      prop: "productionStepIds"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.form.productionStepIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "productionStepIds", $$v);
      },
      expression: "form.productionStepIds"
    }
  }, _vm._l(_vm.productionSteps, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Nhân viên",
      prop: "staffIds"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.form.staffIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffIds", $$v);
      },
      expression: "form.staffIds"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tính theo loại",
      prop: "calcBy"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.calcBy,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "calcBy", $$v);
      },
      expression: "form.calcBy"
    }
  }, _vm._l(_vm.MachineCalcByTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Áp dụng cho",
      prop: "calcType"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.calcType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "calcType", $$v);
      },
      expression: "form.calcType"
    }
  }, _vm._l(_vm.MachineCalcTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Sản lượng/phút",
      prop: "quantityOnMinute"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
    }],
    model: {
      value: _vm.form.quantityOnMinute,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantityOnMinute", $$v);
      },
      expression: "form.quantityOnMinute"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;