import { render, staticRenderFns } from "./PricingTable.vue?vue&type=template&id=839c2a62&scoped=true&"
import script from "./PricingTable.vue?vue&type=script&lang=ts&"
export * from "./PricingTable.vue?vue&type=script&lang=ts&"
import style0 from "./PricingTable.vue?vue&type=style&index=0&id=839c2a62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "839c2a62",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('839c2a62')) {
      api.createRecord('839c2a62', component.options)
    } else {
      api.reload('839c2a62', component.options)
    }
    module.hot.accept("./PricingTable.vue?vue&type=template&id=839c2a62&scoped=true&", function () {
      api.rerender('839c2a62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sale-product-pricing-table/components/PricingTable.vue"
export default component.exports