"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Danh sách PO chưa mua bên NCC",
      visible: _vm.visible,
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, _vm._l(_vm.missPO, function (item) {
    return _c("div", {
      staticStyle: {
        color: "#000",
        "margin-top": "8px"
      }
    }, [_c("a", {
      staticStyle: {
        color: "#1989fa"
      },
      attrs: {
        href: "https://actapp.misa.vn/app/popup/PUOrderDetail/".concat(item.syncId),
        target: "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.code) + ": ")]), _c("span", [_vm._v("Không tìm thấy đơn mua hàng này bên NCC. (Ngày PO:" + _vm._s(_vm.$formatDate(item.orderedAt)) + ")")]), _c("el-button", {
      attrs: {
        type: "primary",
        size: "mini",
        icon: "el-icon-check"
      },
      on: {
        click: function click($event) {
          return _vm.handleCheck(item);
        }
      }
    }, [_vm._v("Đã check")])], 1);
  }), 0), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;