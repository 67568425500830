"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "panel-shadow",
    staticStyle: {
      "box-shadow": "0 2px 12px 0 #0000001a"
    }
  }, [_c("h3", {
    staticClass: "text-color--black"
  }, [_vm._v("Thông tin công ty")]), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên công ty",
      prop: "companyName"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Địa chỉ công ty",
      prop: "companyAddress"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.companyAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyAddress", $$v);
      },
      expression: "form.companyAddress"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "MST",
      prop: "companyTaxCode"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.companyTaxCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyTaxCode", $$v);
      },
      expression: "form.companyTaxCode"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    staticClass: "is-required",
    attrs: {
      label: "Địa chỉ email"
    }
  }, [_c("el-select", {
    attrs: {
      "allow-create": "",
      multiple: "",
      filterable: ""
    },
    model: {
      value: _vm.emails,
      callback: function callback($$v) {
        _vm.emails = $$v;
      },
      expression: "emails"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ghi chú"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 5
      }
    },
    model: {
      value: _vm.form.privateNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "privateNote", $$v);
      },
      expression: "form.privateNote"
    }
  })], 1)], 1)], 1)], 1), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Mô tả của đơn hàng:")]), _c("p", [_vm._v(" " + _vm._s(_vm.order.description) + " ")]), _vm.order.privateDescription ? _c("p", [_vm._v(" ---- "), _c("br"), _vm._v(" " + _vm._s(_vm.order.privateDescription) + " ")]) : _vm._e()]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loadingUpdateInfoVat
    },
    on: {
      click: _vm.updateExportVatInfo
    }
  }, [_vm._v(" Cập nhật thông tin công ty ")]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "warning",
      size: "medium"
    },
    on: {
      click: _vm.pasteCompanyInfo
    }
  }, [_vm._v(" Dán thông tin công ty ")])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;