import { render, staticRenderFns } from "./TrangVangLogModal.vue?vue&type=template&id=0b88929f&"
import script from "./TrangVangLogModal.vue?vue&type=script&lang=ts&"
export * from "./TrangVangLogModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0b88929f')) {
      api.createRecord('0b88929f', component.options)
    } else {
      api.reload('0b88929f', component.options)
    }
    module.hot.accept("./TrangVangLogModal.vue?vue&type=template&id=0b88929f&", function () {
      api.rerender('0b88929f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/craw/trangvang/components/TrangVangLogModal.vue"
export default component.exports