"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    staticStyle: {
      "margin-bottom": "12px",
      "text-align": "center"
    }
  }, [_c("span", [_vm._v("Lịch sử nhập kho")]), _vm.workOrder ? [_c("div", [_c("span", {
    staticStyle: {
      "font-size": "30px"
    }
  }, [_vm._v(" " + _vm._s(_vm.workOrder.code) + " ")])]), _c("div", [_c("span", [_vm._v(" " + _vm._s(_vm.name) + " ")])])] : _vm._e()], 2), _vm.isLoaded ? _c("div", [!_vm.error ? _c("div", [_vm.workOrder ? [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("NVL: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.workOrder.materialName) + " ")])]), _c("div", {
    staticClass: "raw-table"
  }, [_c("table", [_c("tr", [_vm._m(0), _c("td", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.workOrder.materialQuantity)) + " ")])]), _c("tr", [_vm._m(1), _c("td", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.summaryQuantity)) + " ")])]), _c("tr", [_vm._m(2), _c("td", [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, _vm._l(_vm.workOrder.misaTags, function (item) {
    return _c("MisaTagComp", {
      key: item.id,
      attrs: {
        misaTag: item
      }
    });
  }), 1)])]), _c("tr", [_vm._m(3), _c("td", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.workOrder.material ? _vm.workOrder.material.quantity : 0)) + " ")])])])])] : _vm._e(), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, _vm._l(_vm.data, function (item) {
    return _c("WorkOrderInventoryItem", {
      key: item.id,
      attrs: {
        workOrderInventory: item,
        visibleUpdate: _vm.visibleUpdateInventory
      },
      on: {
        update: _vm.onUpdateInventory,
        print: _vm.handlePrint
      }
    });
  }), 1)], 2) : _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")])]) : _c("div", [_vm._v("Đang tải dữ liệu...")]), _vm.selectedWorkOrderInventory ? _c("WorkOrderInventoryPrint", {
    ref: "WorkOrderInventoryPrint",
    attrs: {
      workOrderInventory: _vm.selectedWorkOrderInventory
    }
  }) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("SL cần để SX")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("NV đã nhập (Hậu + Thiện)")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Kế hoạch ghi chú (A.Thái)")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tồn trên Misa (Huyền)")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;