"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    attrs: {
      data: _vm.data,
      border: "",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      prop: "no",
      align: "center",
      width: "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var $index = _ref.$index;
        return [_vm._v(" " + _vm._s($index + 1) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thời gian"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "NV kho"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.staffName) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Biến số xe"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.workOrderInventory.licensePlate) + " ")];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;