"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartonMachineType = void 0;
var CartonMachineType;
exports.CartonMachineType = CartonMachineType;

(function (CartonMachineType) {
  CartonMachineType["Be"] = "BE";
  CartonMachineType["Chap"] = "CHAP";
  CartonMachineType["In"] = "IN";
})(CartonMachineType || (exports.CartonMachineType = CartonMachineType = {}));