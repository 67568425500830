"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.todoApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var todoApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/todo",
      params: params,
      method: 'get'
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/todo",
      data: data,
      method: 'post'
    });
  },
  update: function update(todoId, data) {
    return (0, _request.default)({
      url: "/v1/admin/todo/".concat(todoId, "/update"),
      data: data,
      method: 'put'
    });
  },
  done: function done(todoId) {
    return (0, _request.default)({
      url: "/v1/admin/todo/".concat(todoId, "/done"),
      method: 'put'
    });
  },
  delete: function _delete(todoId) {
    return (0, _request.default)({
      url: "/v1/admin/todo/".concat(todoId, "/delete"),
      method: 'delete'
    });
  }
};
exports.todoApi = todoApi;