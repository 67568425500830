"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChatContentType = void 0;
var ChatContentType;
exports.ChatContentType = ChatContentType;

(function (ChatContentType) {
  ChatContentType["SoonBuy"] = "SOON_BUY";
  ChatContentType["LongBuy"] = "LONG_BUY";
})(ChatContentType || (exports.ChatContentType = ChatContentType = {}));