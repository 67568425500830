"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.configs,
      border: "",
      fit: ""
    },
    on: {
      "row-click": _vm.handleUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Cấu hình"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.ConfigParamsTrans[row.param]) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú",
      prop: "config.note"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", {
          domProps: {
            innerHTML: _vm._s(row.value)
          }
        })];
      }
    }])
  })], 1), _c("staff-config-modal", {
    ref: "StaffConfigModal",
    on: {
      "submit:ok": _vm.fetchConfigs
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;