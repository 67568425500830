"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "footer-fixed",
    attrs: {
      title: "Chọn từ tồn kho",
      visible: _vm.visible,
      width: "1200px",
      top: "30px",
      fullscreen: "",
      "close-on-click-modal": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "content-container"
  }, [_c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm mã, tên"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item",
    staticStyle: {
      "margin-left": "4px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Loại")]), _c("el-radio", {
    staticStyle: {
      display: "inline-block"
    },
    attrs: {
      label: ""
    },
    on: {
      change: function change($event) {
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "type", $$v);
      },
      expression: "query.type"
    }
  }, [_vm._v("Tất cả")]), _c("el-radio", {
    staticStyle: {
      display: "inline-block"
    },
    attrs: {
      label: "TP"
    },
    on: {
      change: function change($event) {
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "type", $$v);
      },
      expression: "query.type"
    }
  }, [_vm._v("Thành phẩm")]), _c("el-radio", {
    staticStyle: {
      display: "inline-block"
    },
    attrs: {
      label: "BTP"
    },
    on: {
      change: function change($event) {
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "type", $$v);
      },
      expression: "query.type"
    }
  }, [_vm._v("Bán thành phẩm")])], 1)]), _vm.selectedProducts.length ? _c("div", {
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đã chọn (" + _vm._s(_vm.selectedProducts.length) + ")")]), _c("el-table", {
    attrs: {
      data: _vm.selectedProducts,
      border: "",
      fit: ""
    },
    on: {
      select: _vm.handleSelectOne
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Mã thành phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("DeleteButton", {
          on: {
            click: function click($event) {
              return _vm.handleSelectOne([], row);
            }
          }
        }), _c("span", [_vm._v(" " + _vm._s(row.code) + " ")])];
      }
    }], null, false, 884106068)
  }), _c("el-table-column", {
    attrs: {
      label: "Tên thành phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.name) + " ")];
      }
    }], null, false, 4085208056)
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " ")];
      }
    }], null, false, 1265906521)
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng giao",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.needed)))])];
      }
    }], null, false, 545240978)
  }), _c("el-table-column", {
    attrs: {
      label: "Số m3"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("span", [_vm._v(_vm._s((row.needed * row.cubicMeter).toFixed(1)))]), _vm._v(" m3 ")];
      }
    }], null, false, 3512005276)
  })], 1), _c("label", {
    staticStyle: {
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng m3: ")]), _c("span", [_vm._v(_vm._s(_vm.totalCubicMeter))])], 1) : _vm._e(), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "table",
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    },
    on: {
      "select-all": _vm.handleSelectAll,
      select: _vm.handleSelectOne
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Mã thành phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(row.code) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tên thành phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.name) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Loại"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(_vm._s(row.type))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn kho thực tế",
      align: "right",
      sortable: "",
      prop: "remain"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.misaProduct.remain, 0))) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn kho thực tế "), _c("el-tooltip", {
    attrs: {
      content: "Bao gồm TP, BTP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _c("el-table-column", {
    attrs: {
      label: "Đơn hàng tạm giữ",
      align: "right",
      sortable: "",
      prop: "pendingExport"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.misaProduct.pendingExport, 0))) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn có thể bán",
      align: "right",
      sortable: "",
      prop: "available"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.misaProduct.available, 0))) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn có thể bán "), _c("el-tooltip", {
    attrs: {
      content: "Bao gồm cả BTP, TP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _c("el-table-column", {
    attrs: {
      label: "Tồn có thể bán (TP)",
      align: "right",
      sortable: "",
      prop: "availableTP"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.misaProduct.availableTP, 0))) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn có thể bán (TP) "), _c("el-tooltip", {
    attrs: {
      content: "Gồm TP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _c("el-table-column", {
    attrs: {
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng giao",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var row = _ref14.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          on: {
            input: function input($event) {
              return _vm.handleChangeQuantity(row);
            }
          },
          model: {
            value: row.needed,
            callback: function callback($$v) {
              _vm.$set(row, "needed", $$v);
            },
            expression: "row.needed"
          }
        })];
      }
    }])
  })], 1)], 1), _c("div", {
    staticClass: "pagination-container"
  }, [_c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("Đồng ý")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;