"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top"
    }
  }, [_c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhập")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Chọn máy"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium"
    },
    on: {
      change: _vm.handleChangeMachineConfig
    },
    model: {
      value: _vm.form.machineConfigId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "machineConfigId", $$v);
      },
      expression: "form.machineConfigId"
    }
  }, _vm._l(_vm.machineConfigs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lớp",
      prop: "layer"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    on: {
      "": function _($event) {},
      change: _vm.handleChangeLayer
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_c("el-option", {
    attrs: {
      label: "3 lớp",
      value: 3
    }
  }), _c("el-option", {
    attrs: {
      label: "5 lớp",
      value: 5
    }
  }), _c("el-option", {
    attrs: {
      label: "7 lớp",
      value: 7
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại sóng",
      prop: "cartonWave"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      "value-key": "id",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.cartonWave,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonWave", $$v);
      },
      expression: "form.cartonWave"
    }
  }, _vm._l(_vm.cartonWavesFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Kết cấu giấy",
      prop: "cartonStruct"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium",
      filterable: "",
      "value-key": "id"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.cartonStruct,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonStruct", $$v);
      },
      expression: "form.cartonStruct"
    }
  }, _vm._l(_vm.cartonStructsFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      input: function input($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.length,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length", $$v);
      },
      expression: "form.length"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Rộng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      input: function input($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.width,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width", $$v);
      },
      expression: "form.width"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cao"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      input: function input($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1)], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Tạo SP ECount")])], 1), _c("section", {
    staticClass: "box"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Ước tính")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length2", $$v);
      },
      expression: "form.length2"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width2", $$v);
      },
      expression: "form.width2"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhà cung cấp")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length3,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length3", $$v);
      },
      expression: "form.length3"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width3,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width3", $$v);
      },
      expression: "form.width3"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Ước tính 1 thùng")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length4,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length4", $$v);
      },
      expression: "form.length4"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm",
      size: "normal"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width4,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width4", $$v);
      },
      expression: "form.width4"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Thực tế (" + _vm._s(_vm.form.quantity) + " thùng)")]), _c("div", {}, [_c("span", [_vm._v("Dán tự động ")]), _c("el-switch", {
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ff4949"
    },
    on: {
      change: _vm.calcNet
    },
    model: {
      value: _vm.isAutoPaste,
      callback: function callback($$v) {
        _vm.isAutoPaste = $$v;
      },
      expression: "isAutoPaste"
    }
  })], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length5,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length5", $$v);
      },
      expression: "form.length5"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width5,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width5", $$v);
      },
      expression: "form.width5"
    }
  })], 1)], 1)], 1), _vm.form.length5 > _vm.maxLengthCut ? _c("small", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" Chiều dài tối đa là 1480mm ")]) : _vm._e(), _vm.form.width5 > _vm.maxWidthCut ? _c("div", [_c("small", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" Khổ tấm tối đa là 1080mm ")])]) : _vm._e()], 1)], 1)], 1), _c("section", {
    staticClass: "box"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Cấu thành giá")]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số m2: ")]), _c("span", [_vm._v(_vm._s(_vm.form.acr) + " m2")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lượng giấy hao hụt: ")]), _c("span", [_vm._v(_vm._s(_vm.loss) + " m2 (" + _vm._s(_vm.$formatNumber(+(_vm.loss * _vm.cartonPrice).toFixed(1))) + " đ)")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá giấy nguyên liệu: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.price, ",")) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí gia công: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.giaCong)) + " đ (Dán: " + _vm._s(_vm.$formatNumber(_vm.pasteFee)) + "đ, Cột: " + _vm._s(_vm.$formatNumber(_vm.bindFee)) + "đ)")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phụ gia: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.phuGia)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng cộng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.moneyFinal)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá tại kho: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.sellPrice)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí vận chuyển: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.shipFee)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá giao tận nơi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.deliveryPrice)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lợi nhuận: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.sellPrice - _vm.form.moneyFinal)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("% lợi nhuận: ")]), _c("el-select", {
    staticStyle: {
      width: "100px"
    },
    attrs: {
      filterable: ""
    },
    on: {
      change: function change($event) {
        _vm.calcPrice();

        _vm.changeProfitConfig();
      }
    },
    model: {
      value: _vm.profitPercent,
      callback: function callback($$v) {
        _vm.profitPercent = $$v;
      },
      expression: "profitPercent"
    }
  }, _vm._l(_vm.profitPercentValues, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: "".concat(item, "%"),
        value: item
      }
    });
  }), 1)], 1)]), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Công suất ngày")]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số m2: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.summary.arc)) + " m2")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lượng giấy hao hụt: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.summary.loss)) + " m2 (" + _vm._s(_vm.$formatNumber(Math.round(_vm.summary.loss * _vm.cartonPrice))) + " đ)")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số thùng / ngày: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.amountPerDay)) + " thùng")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí gia công: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.summary.giaCong)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phụ gia: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.summary.phuGia)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng cộng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.summary.moneyFinal)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá tại kho: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.summary.sellPrice)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí vận chuyển: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.summary.shipFee)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá giao tận nơi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.summary.deliveryPrice)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lợi nhuận: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.summary.sellPrice - _vm.summary.moneyFinal)) + " đ")])])]), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Chi tiết phí gia công")]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Công dán: ")]), _c("span", [_vm._v(_vm._s(_vm.isAutoPaste ? _vm.net.autoPastePerson : _vm.net.pastePerson) + " công")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Công cột: ")]), _c("span", [_vm._v(_vm._s(_vm.net.bindPerson) + " công")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Chi phí dán: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.isAutoPaste ? _vm.net.autoPastePrice : _vm.net.pastePrice)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Chi phí công cột: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.net.bindPrice)) + " đ")])]), _vm.isAutoPaste ? [_c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số thùng/phút: ")]), _c("span", [_vm._v(_vm._s(_vm.form.autoPasteQuantityPerMin) + " ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số thùng/ngày: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.autoPasteQuantity)) + " ")])])] : _vm._e()], 2)], 1)], 1)]), _c("EcountProductModal", {
    ref: "EcountProductModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;