"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật chuyến" : "Thêm mới chuyến",
      visible: _vm.visible,
      width: _vm.status == "create" ? "700px" : "1200px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên chuyến",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày giao",
      prop: "date"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      format: "dd/MM/yyyy",
      size: "normal"
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Biển số",
      prop: "licensePlate"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.licensePlate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "licensePlate", $$v);
      },
      expression: "form.licensePlate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nhà cung cấp",
      prop: "providerName"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.form.providerName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "providerName", $$v);
      },
      expression: "form.providerName"
    }
  }, _vm._l(_vm.providerNames, function (name) {
    return _c("el-radio", {
      key: name,
      attrs: {
        label: name
      }
    }, [_vm._v(_vm._s(name))]);
  }), 1)], 1), _vm.status == "update" ? _c("div", {
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Lưu thông tin chuyến")]), _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa chuyến")])], 1) : _vm._e(), _vm.status == "update" ? _c("div", [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addDetail
    }
  }, [_vm._v("Tạo thẻ kho")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingTable,
      expression: "loadingTable"
    }],
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      data: _vm.workOrderInventories,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Lần nhập",
      prop: "code",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(row.code))]), _c("br"), _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDeleteInventory(row);
            }
          }
        })];
      }
    }], null, false, 1311679074)
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng (NCC)",
      prop: "materialCode"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.getTamXa(row))) + " ")];
      }
    }], null, false, 2705463482)
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng (Thực tế)",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalRealQuantity)) + " ")];
      }
    }], null, false, 644391329)
  }), _c("el-table-column", {
    attrs: {
      label: "Số pallet",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("el-link", {
          staticStyle: {
            display: "block"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickPallet(row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalPallet)) + " ")]), _c("el-link", {
          staticStyle: {
            display: "block"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleAddPallet(row);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-plus"
        }), _vm._v(" Thêm pallet ")]), _c("el-link", {
          staticStyle: {
            display: "block"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleAddPalletCotBo(row);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-plus"
        }), _vm._v(" Thêm pallet (Cột bó) ")])];
      }
    }], null, false, 687229691)
  }), _c("el-table-column", {
    attrs: {
      label: "Số LSX",
      prop: "workOrder.code",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Diễn giải",
      "min-width": "250",
      prop: "workOrder.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "ĐMH",
      prop: "workOrder.poCode",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tên NVL",
      "min-width": "250",
      prop: "workOrder.materialName"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng NVL",
      prop: "workOrder.materialQuantity",
      align: "right",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Sóng",
      prop: "workOrder.materialCartonWave",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Chống thấm",
      prop: "workOrder.waterProof",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Kết cấu",
      prop: "workOrder.materialCartonStruct",
      "min-width": "150"
    }
  })], 1)], 1) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.status == "update" ? _c("div") : _vm._e(), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _vm.status == "create" ? _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")]) : _vm._e()], 1), _c("SelectWorkOrderModal", {
    ref: "SelectWorkOrderModal",
    on: {
      submitOk: _vm.handleSelectWorkOrder
    }
  }), _c("CreateWorkOrderInventoryModal", {
    ref: "CreateWorkOrderInventoryModal",
    on: {
      submitOk: _vm.handleCreateInventoryOk
    }
  }), _c("InventoryDetailModal", {
    ref: "InventoryDetailModal",
    attrs: {
      version: 3
    },
    on: {
      submitOk: _vm.fetchWorkOrderInventory
    }
  }), _c("InventoryDetailListModal", {
    ref: "InventoryDetailListModal",
    attrs: {
      version: 3
    },
    on: {
      submitOk: _vm.fetchWorkOrderInventory
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;