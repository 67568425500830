"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Chọn máy để sản xuất",
      visible: _vm.visible,
      width: "500px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Chọn máy",
      prop: "productionMachineId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    on: {
      change: _vm.handleChangeMachine
    },
    model: {
      value: _vm.form.productionMachineId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "productionMachineId", $$v);
      },
      expression: "form.productionMachineId"
    }
  }, _vm._l(_vm.machines, function (item) {
    var _vm$ProductionMachine, _vm$ProductionMachine2;

    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("span", [_vm._v(" " + _vm._s(item.name) + " ")]), _c("el-tag", {
      staticClass: "font-bold",
      staticStyle: {
        "border-color": "unset"
      },
      attrs: {
        color: (_vm$ProductionMachine = _vm.ProductionMachineStatusTrans[item.status]) === null || _vm$ProductionMachine === void 0 ? void 0 : _vm$ProductionMachine.tagColor,
        size: "mini",
        effect: "dark"
      }
    }, [_vm._v(" " + _vm._s((_vm$ProductionMachine2 = _vm.ProductionMachineStatusTrans[item.status]) === null || _vm$ProductionMachine2 === void 0 ? void 0 : _vm$ProductionMachine2.name) + " ")])], 1);
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Nhân viên đảm nhiệm",
      prop: "staffId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffId", $$v);
      },
      expression: "form.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;