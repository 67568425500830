"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "order-type-chart"
  }, [_c("bar-chart", {
    ref: "chart",
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      legend: _vm.legend,
      xAxisValue: _vm.xAxisValue,
      series: _vm.series
    }
  }), _vm._m(0)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Biểu đồ đơn mới, đơn cũ")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;