"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm kiếm tên"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.handleUpdateBatch
    }
  }, [_vm._v("Cập nhật hàng loạt")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      "row-key": "id",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Sản phẩm",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn kho",
      align: "right",
      prop: "available"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Đã lên lịch",
      align: "right",
      prop: "quantityPo"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn + Đã lên lịch",
      align: "right",
      prop: "quantityPo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.available + row.quantityPo) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL chờ nhập kho",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.pendingQuantity)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú NCC"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.note ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" Ghi chú: ")]), _c("span", {
          on: {
            click: function click($event) {
              return _vm.handleUpdateProviderNote(row);
            }
          }
        }, [_vm._v(_vm._s(row.note))])]) : _vm._e(), row.providerNote ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" Ghi chú NCC: ")]), _c("span", {
          on: {
            click: function click($event) {
              return _vm.handleUpdateProviderNote(row);
            }
          }
        }, [_vm._v(_vm._s(row.providerNote))])]) : _vm._e(), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdateProviderNote(row);
            }
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngưỡng hạn cần nhập kho",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }"
          }],
          staticClass: "text-right",
          model: {
            value: row.quantityThreshold,
            callback: function callback($$v) {
              _vm.$set(row, "quantityThreshold", $$v);
            },
            expression: "row.quantityThreshold"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn tối thiểu để bán",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }"
          }],
          staticClass: "text-right",
          model: {
            value: row.minQuantityToSell,
            callback: function callback($$v) {
              _vm.$set(row, "minQuantityToSell", $$v);
            },
            expression: "row.minQuantityToSell"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            loading: row.loading
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" Cập nhật ")])];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("HandlePendingQuantityModal", {
    ref: "HandlePendingQuantityModal"
  }), _c("UpdateProviderNoteModal", {
    ref: "UpdateProviderNoteModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;