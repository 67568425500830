"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MisaLeadStatusTrans = exports.MisaLeadStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _MisaLeadStatusTrans;

var MisaLeadStatus;
exports.MisaLeadStatus = MisaLeadStatus;

(function (MisaLeadStatus) {
  MisaLeadStatus["New"] = "NEW";
  MisaLeadStatus["HasPhone"] = "HAS_PHONE";
  MisaLeadStatus["Contact"] = "CONTACT";
  MisaLeadStatus["Info"] = "INFO";
  MisaLeadStatus["Quote"] = "QUOTE";
  MisaLeadStatus["Free"] = "FREE";
  MisaLeadStatus["Fail"] = "FAIL";
})(MisaLeadStatus || (exports.MisaLeadStatus = MisaLeadStatus = {}));

var MisaLeadStatusTrans = (_MisaLeadStatusTrans = {}, (0, _defineProperty2.default)(_MisaLeadStatusTrans, MisaLeadStatus.New, "Chưa phân loại"), (0, _defineProperty2.default)(_MisaLeadStatusTrans, MisaLeadStatus.HasPhone, "Đã có SĐT"), (0, _defineProperty2.default)(_MisaLeadStatusTrans, MisaLeadStatus.Contact, "Đã liên hệ"), (0, _defineProperty2.default)(_MisaLeadStatusTrans, MisaLeadStatus.Info, "Đang lấy yêu cầu"), (0, _defineProperty2.default)(_MisaLeadStatusTrans, MisaLeadStatus.Quote, "Đã báo giá"), (0, _defineProperty2.default)(_MisaLeadStatusTrans, MisaLeadStatus.Free, "Tự do"), (0, _defineProperty2.default)(_MisaLeadStatusTrans, MisaLeadStatus.Fail, "Thất bại"), _MisaLeadStatusTrans);
exports.MisaLeadStatusTrans = MisaLeadStatusTrans;