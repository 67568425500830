"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "dashboard-editor-container"
  }, [_c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-bottom": "40px"
    }
  }, [_c("div", {
    staticClass: "filter-box"
  }, [_c("div", {
    staticStyle: {
      "margin-right": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Loại đơn")]), _c("br"), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.OrderType.New
    },
    model: {
      value: _vm.orderType,
      callback: function callback($$v) {
        _vm.orderType = $$v;
      },
      expression: "orderType"
    }
  }, [_vm._v("Đơn mới")]), _c("el-radio", {
    attrs: {
      label: _vm.OrderType.Old
    },
    model: {
      value: _vm.orderType,
      callback: function callback($$v) {
        _vm.orderType = $$v;
      },
      expression: "orderType"
    }
  }, [_vm._v("Đơn cũ")]), _c("el-radio", {
    attrs: {
      label: _vm.OrderType.All
    },
    model: {
      value: _vm.orderType,
      callback: function callback($$v) {
        _vm.orderType = $$v;
      },
      expression: "orderType"
    }
  }, [_vm._v("Tất cả")])], 1)]), _c("div", {
    staticStyle: {
      "margin-right": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Trạng thái đơn")]), _c("br"), _c("div", [_c("el-select", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.orderStatus,
      callback: function callback($$v) {
        _vm.orderStatus = $$v;
      },
      expression: "orderStatus"
    }
  }, _vm._l(_vm.OrderStatusTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Chọn ngày")]), _c("br"), _c("el-date-picker", {
    attrs: {
      type: "daterange",
      "range-separator": "to",
      format: "dd/MM/yyyy",
      "value-format": "yyyy-MM-dd",
      "start-placeholder": "Ngày bắt đầu",
      "end-placeholder": "Ngày kết thúc",
      "picker-options": _vm.pickerOptions
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)])]), _c("div", {
    staticStyle: {
      "margin-bottom": "32px"
    }
  }, [_c("panel-group", {
    attrs: {
      orderType: _vm.orderType,
      date: _vm.convertedDate,
      orderStatus: _vm.orderStatus,
      isAdmin: _vm.isAdmin
    }
  })], 1), _c("div", {
    staticStyle: {
      "box-shadow": "#32325d40 -1px -4px 60px -20px, #0000004d -1px 1px 40px -30px"
    }
  }, [_c("category-chart", {
    attrs: {
      isAdmin: _vm.isAdmin,
      orderStatus: _vm.orderStatus,
      orderType: _vm.orderType,
      date: _vm.convertedDate
    }
  }), _c("profit-chart", {
    attrs: {
      isAdmin: _vm.isAdmin,
      orderStatus: _vm.orderStatus,
      orderType: _vm.orderType,
      date: _vm.convertedDate
    }
  }), _c("order-type-chart", {
    attrs: {
      isAdmin: _vm.isAdmin,
      orderStatus: _vm.orderStatus,
      date: _vm.convertedDate
    }
  })], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;