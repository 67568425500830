"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      width: "1200px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Lịch sử nhập: " + _vm._s(_vm.workOrder ? _vm.workOrder.code : "") + " ")]), _c("br"), _vm.workOrder ? _c("span", [_vm._v(" " + _vm._s(_vm.workOrder.materialName) + " ")]) : _vm._e()]), _c("WorkOrderInventoryTable", {
    attrs: {
      visibleAction: _vm.isAdmin,
      data: _vm.data,
      loading: _vm.loading,
      version: _vm.version
    },
    on: {
      print: _vm.handlePrint,
      addPallet: _vm.handleAddPallet,
      addPalletCotBo: _vm.handleAddPalletCotBo,
      update: _vm.handleUpdateInventory,
      delete: _vm.handleDeleteInventory,
      clickPallet: _vm.handleClickPallet
    }
  }), _vm.version == 1 ? _c("WorkOrderInventoryPrint", {
    ref: "WorkOrderInventoryPrint",
    attrs: {
      workOrderInventory: _vm.selected
    }
  }) : _vm._e(), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1), _c("CreateWorkOrderInventoryModal", {
    ref: "CreateWorkOrderInventoryModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _vm.version != 1 ? [_c("InventoryDetailModal", {
    ref: "InventoryDetailModal",
    attrs: {
      version: _vm.version
    },
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("InventoryDetailListModal", {
    ref: "InventoryDetailListModal",
    attrs: {
      version: _vm.version
    },
    on: {
      submitOk: _vm.fetchData
    }
  })] : _vm._e()], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;