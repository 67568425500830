"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "misa-activity-overlay"
  }, [_c("el-tooltip", {
    attrs: {
      content: _vm.misaActivity.name
    }
  }, [_c("div", {
    staticClass: "activity-name"
  }, [_c("el-tag", [_vm._v(" " + _vm._s(_vm.layoutName) + " ")]), _c("span", [_vm._v(" " + _vm._s(_vm.misaActivity.name) + " " + _vm._s(_vm.misaActivity.id) + " ")])], 1)]), _c("div", {
    staticClass: "due-date"
  }, [_c("i", {
    staticClass: "el-icon-timer"
  }), _c("span", [_vm._v("Hạn hoàn thành: " + _vm._s(_vm.$stringToDateTime(_vm.misaActivity.dueDate)))])]), _c("div", {
    staticClass: "due-date",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c("span", [_vm._v("Trạng thái: " + _vm._s(_vm.misaActivity.statusIdText))])]), _vm.layoutName == _vm.EMisaActivityType.Account ? _c("div", {}, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Khách hàng: ")]), _c("span", [_vm._v(_vm._s(_vm.misaActivity.misaCustomer ? _vm.misaActivity.misaCustomer.name : "N/A"))])]) : _vm._e(), _vm.layoutName == _vm.EMisaActivityType.Opportunity ? _c("div", {}, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Cơ hội: ")]), _c("span", [_vm._v(_vm._s(_vm.misaActivity.misaOpportunity ? _vm.misaActivity.misaOpportunity.name : "N/A"))])]) : _vm._e(), _vm.layoutName == _vm.EMisaActivityType.Lead ? _c("div", {}, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tiềm năng: ")]), _c("span", [_vm._v(_vm._s(_vm.misaActivity.misaLead ? _vm.misaActivity.misaLead.leadName : "N/A"))])]) : _vm._e(), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      type: "success",
      size: "mini",
      disabled: _vm.misaActivity.statusId == 4
    },
    on: {
      click: _vm.onComplete
    }
  }, [_vm._v(_vm._s(_vm.misaActivity.statusId == 4 ? "Đã hoàn thành" : "Hoàn thành") + " ")])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;