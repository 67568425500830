"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MisaActivityTypeTrans = exports.MisaActivityStatuses = exports.MisaActivityStatusColors = exports.EMisaActivityType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _MisaActivityTypeTran, _MisaActivityStatuses, _MisaActivityStatusCo;

var EMisaActivityType;
exports.EMisaActivityType = EMisaActivityType;

(function (EMisaActivityType) {
  EMisaActivityType["Account"] = "Account";
  EMisaActivityType["Contact"] = "Contact";
  EMisaActivityType["Lead"] = "Lead";
  EMisaActivityType["Opportunity"] = "Opportunity";
})(EMisaActivityType || (exports.EMisaActivityType = EMisaActivityType = {}));

var MisaActivityTypeTrans = (_MisaActivityTypeTran = {}, (0, _defineProperty2.default)(_MisaActivityTypeTran, EMisaActivityType.Account, 'Khách hàng'), (0, _defineProperty2.default)(_MisaActivityTypeTran, EMisaActivityType.Contact, ' Liên hệ'), (0, _defineProperty2.default)(_MisaActivityTypeTran, EMisaActivityType.Lead, ' Tiềm năng'), (0, _defineProperty2.default)(_MisaActivityTypeTran, EMisaActivityType.Opportunity, ' Cơ hội'), _MisaActivityTypeTran);
exports.MisaActivityTypeTrans = MisaActivityTypeTrans;
var MisaActivityStatuses = (_MisaActivityStatuses = {}, (0, _defineProperty2.default)(_MisaActivityStatuses, '1', 'Chưa bắt đầu'), (0, _defineProperty2.default)(_MisaActivityStatuses, '4', 'Đã hoàn thành'), _MisaActivityStatuses);
exports.MisaActivityStatuses = MisaActivityStatuses;
var MisaActivityStatusColors = (_MisaActivityStatusCo = {}, (0, _defineProperty2.default)(_MisaActivityStatusCo, '1', '#e2e4e9'), (0, _defineProperty2.default)(_MisaActivityStatusCo, '4', '#67c23a'), _MisaActivityStatusCo);
exports.MisaActivityStatusColors = MisaActivityStatusColors;