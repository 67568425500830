import { render, staticRenderFns } from "./ViewCartonInfoModal.vue?vue&type=template&id=c22506d8&scoped=true&"
import script from "./ViewCartonInfoModal.vue?vue&type=script&lang=ts&"
export * from "./ViewCartonInfoModal.vue?vue&type=script&lang=ts&"
import style0 from "./ViewCartonInfoModal.vue?vue&type=style&index=0&id=c22506d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c22506d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c22506d8')) {
      api.createRecord('c22506d8', component.options)
    } else {
      api.reload('c22506d8', component.options)
    }
    module.hot.accept("./ViewCartonInfoModal.vue?vue&type=template&id=c22506d8&scoped=true&", function () {
      api.rerender('c22506d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/calc-carton/components/ViewCartonInfoModal.vue"
export default component.exports