"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "work-order-inventory-item"
  }, [_c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_c("img", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: _vm.timeImage,
      alt: "",
      width: "20"
    }
  }), _vm._v(" Tạo : ")]), _c("span", [_vm._v(" " + _vm._s(_vm.$formatDateTime(_vm.workOrderInventory.dateCreated)) + " ")])]), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_c("img", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: _vm.packageImage,
      alt: "",
      width: "20"
    }
  }), _vm._v(" Số lượng: ")]), _c("span", {
    staticStyle: {
      color: "#209020",
      "font-weight": "500",
      "font-size": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.workOrderInventory.quantity)) + " ")]), _vm.visibleUpdate ? _c("el-link", {
    staticStyle: {
      transform: "translateY(-3px)"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onUpdate
    }
  }, [_vm._v("Sửa nhập kho")]) : _vm._e()], 1), _c("div", {
    staticStyle: {
      "margin-top": "2px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_c("img", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: _vm.packageImage,
      alt: "",
      width: "20"
    }
  }), _vm._v(" Số lượng (Tấm nhỏ): ")]), _c("span", {
    staticStyle: {
      color: "#209020",
      "font-weight": "500",
      "font-size": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.getTamXa(_vm.workOrderInventory))) + " ")])]), _c("div", {
    staticStyle: {
      "margin-top": "2px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_c("img", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: _vm.deliveryImage,
      alt: "",
      width: "20"
    }
  }), _vm._v(" NCC: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.workOrderInventory.providerName) + " ")])]), _c("div", {
    staticStyle: {
      "margin-top": "2px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_c("img", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: _vm.licensePlateImage,
      alt: "",
      width: "20"
    }
  }), _vm._v(" Biển số: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.workOrderInventory.licensePlate) + " ")])]), _c("div", {
    staticStyle: {
      "margin-top": "2px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_c("img", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: _vm.staffImage,
      alt: "",
      width: "20"
    }
  }), _vm._v(" NV kho: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.workOrderInventory.staffName) + " ")])]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.onPrint
    }
  }, [_vm._v("In")])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;