"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "misa-tag",
    class: {
      clickable: _vm.clickable
    },
    on: {
      click: _vm.onClick
    }
  }, [_c("span", {
    style: "display: inline-block;\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background: ".concat(_vm.misaTag.color, ";\n  margin-right: 4px;")
  }), _c("span", [_vm._v(_vm._s(_vm.misaTag.name))])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;