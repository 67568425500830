"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

var _vuex = _interopRequireDefault(require("vuex"));

var _vue = _interopRequireDefault(require("vue"));

var _getters = _interopRequireDefault(require("./getters"));

var _vuexPersistedstate = _interopRequireDefault(require("vuex-persistedstate"));

var dataState = (0, _vuexPersistedstate.default)({
  paths: ['company', 'task', 'chatContent']
});

_vue.default.use(_vuex.default); // https://webpack.js.org/guides/dependency-management/#requirecontext


var modulesFiles = require.context("./modules", true, /\.js|\.ts$/); // you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file


var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new _vuex.default.Store({
  modules: modules,
  getters: _getters.default,
  plugins: [dataState]
});
var _default = store;
exports.default = _default;