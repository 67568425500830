"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("section", [_c("h3", [_vm._v("Tổng quan theo nhóm ngành")]), _c("el-table", {
    attrs: {
      data: _vm.summaryCategory,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Nhóm ngành"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.category || "Chưa có ngành") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL báo giá"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.totalByStatus) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng lead"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.total) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tỉ lệ"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.total > 0 ? (row.totalByStatus / row.total * 100).toFixed(0) : "0") + "% ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lần tương tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.totalInteract) + " ")];
      }
    }])
  })], 1)], 1), _c("section", [_c("h3", [_vm._v("Tổng quan theo nhân viên")]), _c("el-table", {
    attrs: {
      data: _vm.summaryStaff,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Nhân viên"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(row.employeeName) + " (" + _vm._s(row.employeeCode) + ") ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL báo giá"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.totalQuote) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng lead"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(" " + _vm._s(row.total) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tỉ lệ"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(" " + _vm._s(row.total > 0 ? (row.totalQuote / row.total * 100).toFixed(0) : "0") + "% ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lần tương tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_vm._v(" " + _vm._s(row.totalInteract) + " ")];
      }
    }])
  })], 1)], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;