"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "panel-item green"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" BXH chào hàng (" + _vm._s(_vm.total) + ")")]), _c("section", _vm._l(_vm.summaryStaff, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "detail",
      staticStyle: {
        "margin-top": "4px"
      },
      on: {
        click: function click($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_c("div", {
      staticStyle: {
        width: "40px",
        height: "40px",
        position: "relative",
        display: "inline-block"
      }
    }, [_c("img", {
      staticStyle: {
        "object-fit": "cover",
        "vertical-align": "middle",
        "margin-right": "4px",
        "border-radius": "50%",
        width: "100%",
        height: "100%"
      },
      attrs: {
        src: item.avatar ? _vm.$baseUrlMedia + item.avatar : _vm.defaultAvatar,
        alt: ""
      }
    }), index == 0 ? _c("img", {
      staticStyle: {
        position: "absolute",
        top: "-5px",
        left: "-15px"
      },
      attrs: {
        src: _vm.top1Image,
        width: "30px",
        alt: ""
      }
    }) : _vm._e(), index == 1 ? _c("img", {
      staticStyle: {
        position: "absolute",
        top: "-5px",
        left: "-15px"
      },
      attrs: {
        src: _vm.top2Image,
        width: "30px",
        alt: ""
      }
    }) : _vm._e(), index == 2 ? _c("img", {
      staticStyle: {
        position: "absolute",
        top: "-5px",
        left: "-15px"
      },
      attrs: {
        src: _vm.top3Image,
        width: "30px",
        alt: ""
      }
    }) : _vm._e()]), _c("span", [_vm._v(_vm._s(item.name))]), _vm._v(": "), _c("label", [_vm._v(_vm._s(item.totalActivities))])]);
  }), 0)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;