"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appointmentApi = exports.AppointmentType = exports.AppointmentStatusTrans = exports.AppointmentStatus = exports.AppointmentHistoryType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _request = _interopRequireDefault(require("@/utils/request"));

var appointmentApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/appointment",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/appointment/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/appointment",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/appointment/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updateParent: function updateParent(id, data) {
    return (0, _request.default)({
      url: "/appointment/".concat(id, "/update/parent"),
      data: data,
      method: "post"
    });
  },
  addTip: function addTip(id, data) {
    return (0, _request.default)({
      url: "/appointment/".concat(id, "/add/tip"),
      data: data,
      method: "post"
    });
  },
  noShow: function noShow(id, data) {
    return (0, _request.default)({
      url: "/appointment/".concat(id, "/noShow"),
      data: data,
      method: "post"
    });
  },
  undoNoShow: function undoNoShow(id) {
    return (0, _request.default)({
      url: "/appointment/".concat(id, "/noShow/undo"),
      method: "post"
    });
  },
  cancel: function cancel(id, data) {
    return (0, _request.default)({
      url: "/appointment/".concat(id, "/cancel"),
      method: "post",
      data: data
    });
  },
  cancelParent: function cancelParent(id, data) {
    return (0, _request.default)({
      url: "/appointment/".concat(id, "/cancel/parent"),
      method: "post",
      data: data
    });
  },
  check: function check(params) {
    return (0, _request.default)({
      url: "/appointment/check",
      params: params
    });
  }
};
exports.appointmentApi = appointmentApi;
var AppointmentType;
exports.AppointmentType = AppointmentType;

(function (AppointmentType) {
  AppointmentType["Appointment"] = "APPOINTMENT";
  AppointmentType["Block"] = "BLOCK";
})(AppointmentType || (exports.AppointmentType = AppointmentType = {}));

var AppointmentHistoryType;
exports.AppointmentHistoryType = AppointmentHistoryType;

(function (AppointmentHistoryType) {
  AppointmentHistoryType["Booked"] = "BOOKED";
  AppointmentHistoryType["Cancel"] = "CANCEL";
  AppointmentHistoryType["Checkout"] = "CHECKOUT";
  AppointmentHistoryType["Reschedule"] = "RESCHEDULE";
  AppointmentHistoryType["ReminderSMS"] = "REMINDER_MAIL";
  AppointmentHistoryType["ConfirmedSMS"] = "CONFIRMED_SMS";
  AppointmentHistoryType["ReminderMail"] = "REMINDER_MAIL";
  AppointmentHistoryType["ConfirmedMail"] = "CONFIRMED_MAIL";
  AppointmentHistoryType["NoShow"] = "NO_SHOW";
  AppointmentHistoryType["UndoNoShow"] = "UNDO_NO_SHOW";
})(AppointmentHistoryType || (exports.AppointmentHistoryType = AppointmentHistoryType = {}));

var AppointmentStatus;
exports.AppointmentStatus = AppointmentStatus;

(function (AppointmentStatus) {
  AppointmentStatus["New"] = "NEW";
  AppointmentStatus["Confirmed"] = "CONFIRM";
  AppointmentStatus["Arrived"] = "ARRIVED";
  AppointmentStatus["Started"] = "STARTED";
  AppointmentStatus["Completed"] = "COMPLETED";
  AppointmentStatus["CheckIn"] = "CHECK_IN";
  AppointmentStatus["Late5M"] = "LATE_5M";
  AppointmentStatus["Late10M"] = "LATE_10M";
  AppointmentStatus["NoShow"] = "NO_SHOW";
})(AppointmentStatus || (exports.AppointmentStatus = AppointmentStatus = {}));

var AppointmentStatusTrans = (0, _defineProperty2.default)({
  'NEW': 'New',
  'CONFIRM': 'Confirm',
  'ARRIVED': 'Arrived',
  'STARTED': 'Started',
  'COMPLETED': 'Completed',
  'CHECK_IN': 'Check-in',
  'LATE_5M': 'Late 5 mins',
  'LATE_10M': 'Late 10 mins'
}, AppointmentStatus.NoShow, 'No Show');
exports.AppointmentStatusTrans = AppointmentStatusTrans;