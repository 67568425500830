"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.string.trim.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.concat.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-form", {
    ref: "form",
    attrs: {
      id: "employee-form",
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Username",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.username"
    }
  })], 1)], 1), _vm.visiblePassword ? _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mật khẩu",
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1)], 1) : _vm._e(), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SDT",
      prop: "phone"
    }
  }, [_c("vue-phone-number-input", {
    attrs: {
      "default-country-code": "VN"
    },
    on: {
      update: _vm.handleUpdatePhone
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Email",
      prop: "email"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Quyền"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.roleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "roleId", $$v);
      },
      expression: "form.roleId"
    }
  }, _vm._l(_vm.roles, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nhóm user",
      prop: "staffGroupId"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.staffGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffGroupId", $$v);
      },
      expression: "form.staffGroupId"
    }
  }, _vm._l(_vm.staffGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Telegram username"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.telegramUsername,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "telegramUsername", $$v);
      },
      expression: "form.telegramUsername"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Telegram ID"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.form.telegramId,
      size: "medium",
      disabled: ""
    }
  }), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      position: "absolute",
      right: "0",
      top: "-32px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      loading: _vm.loadingGetId
    },
    on: {
      click: _vm.handleGetTelegramId
    }
  }, [_vm._v("Lấy ID")])], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "NVBH bên nhanh"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeSeller
    },
    model: {
      value: _vm.form.sellerId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sellerId", $$v);
      },
      expression: "form.sellerId"
    }
  }, _vm._l(_vm.sellers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nhân viên misa"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeMisaEmployee
    },
    model: {
      value: _vm.form.misaEmployeeId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaEmployeeId", $$v);
      },
      expression: "form.misaEmployeeId"
    }
  }, _vm._l(_vm.misaEmployees, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " - ").concat(item.code),
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nhóm KH (Carton)"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      size: "medium"
    },
    model: {
      value: _vm.form.cartonPricingGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonPricingGroupId", $$v);
      },
      expression: "form.cartonPricingGroupId"
    }
  }, _vm._l(_vm.cartonPricingGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Công ty"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      size: "medium"
    },
    model: {
      value: _vm.form.companyId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyId", $$v);
      },
      expression: "form.companyId"
    }
  }, _vm._l(_vm.companies, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name),
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c("section", [_c("label", {
    staticStyle: {
      "font-size": "18px",
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Khung giờ đăng nhập")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giờ bắt đầu"
    }
  }, [_c("el-time-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "",
      size: "medium",
      clearable: "",
      "picker-options": {
        start: "07:00",
        step: "00:15",
        end: "22:00"
      }
    },
    model: {
      value: _vm.form.startHour,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "startHour", $$v);
      },
      expression: "form.startHour"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giờ kết thúc"
    }
  }, [_c("el-time-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      placeholder: "",
      clearable: "",
      "picker-options": {
        start: "07:00",
        step: "00:15",
        end: "22:00",
        minTime: _vm.form.startHour
      }
    },
    model: {
      value: _vm.form.endHour,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "endHour", $$v);
      },
      expression: "form.endHour"
    }
  })], 1)], 1)], 1)], 1), _c("section", [_c("div", [_c("label", {
    staticStyle: {
      "font-size": "18px",
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Vị trí đăng nhập")])]), _c("el-form-item", {
    attrs: {
      label: "Bắt buộc"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.form.isRequiredGPS,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isRequiredGPS", $$v);
      },
      expression: "form.isRequiredGPS"
    }
  })], 1)], 1), _c("section", [_c("label", {
    staticStyle: {
      "font-size": "18px",
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("KPI misa")]), _c("el-form-item", {
    attrs: {
      label: "Bắt buộc KPI"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.form.isRequiredKpi,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isRequiredKpi", $$v);
      },
      expression: "form.isRequiredKpi"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Avatar",
      prop: "avatar"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 100,
      imagePath: _vm.form.avatar,
      uploadUrl: "/v1/admin/staff/upload"
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.$set(_vm.form, "avatar", path);
      }
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;