"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "Tìm kiếm LSX, mã SP"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Ngày ")]), _c("el-date-picker", {
    attrs: {
      clearable: false,
      type: "daterange",
      format: "dd/MM/yyyy",
      "range-separator": "-",
      "start-placeholder": "",
      "end-placeholder": ""
    },
    on: {
      change: _vm.handleChangeDate
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm kiếm")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Code",
      prop: "code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.syncId ? _c("el-link", {
          staticStyle: {
            color: "#1989fa"
          },
          attrs: {
            href: "https://actapp.misa.vn/app/popup/INProductionOrderDetail/".concat(row.syncId),
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.code) + " ")]) : _c("span", [_vm._v(" " + _vm._s(row.code) + " ")]), row.misaTags && row.misaTags.length ? [_c("div", _vm._l(row.misaTags, function (item, index) {
          return _c("MisaTagComp", {
            key: index,
            attrs: {
              misaTag: item
            }
          });
        }), 1)] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Diễn giải",
      width: "250",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", [_vm.checkIsGap(row.name.toLowerCase()) ? _c("img", {
          attrs: {
            width: "50",
            src: _vm.warningImage
          }
        }) : _vm._e(), _c("span", [_vm._v(_vm._s(row.name))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "70",
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(_vm._s(_vm.$formatNumberVN(row.quantity)))];
      }
    }])
  }), _vm._l(5, function (item) {
    return _c("el-table-column", {
      key: item,
      attrs: {
        label: "Quy tr\xECnh ".concat(item)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref4) {
          var row = _ref4.row;
          return [row["productionWork".concat(item)] ? _c("div", [_c("label", {
            staticStyle: {
              "font-size": "18px"
            },
            attrs: {
              for: ""
            }
          }, [_vm._v(_vm._s(row["productionWork".concat(item)].productionStep.name))]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Số lượng: ")]), _c("el-link", {
            attrs: {
              type: "primary"
            },
            on: {
              click: function click($event) {
                return _vm.viewDetail(row["productionWork".concat(item)], row);
              }
            }
          }, [_vm._v(" " + _vm._s(row["productionWork".concat(item)].totalQuantityProduce) + " ")])], 1), _c("hr"), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Hao hụt: ")]), _c("span", [_vm._v(" " + _vm._s(row["productionWork".concat(item)].totalLoss) + " ")])]), _c("hr"), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Canh máy: ")]), _c("span", [_vm._v(" " + _vm._s(row["productionWork".concat(item)].totalCanhMay))])])]) : _vm._e()];
        }
      }], null, true)
    });
  })], 2), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("ReportQuantityProductionModal", {
    ref: "ReportQuantityProductionModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;