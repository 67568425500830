"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "quote-modal footer-fixed",
    attrs: {
      title: _vm.status == "create" ? "Thêm mới" : "Cập nhật",
      visible: _vm.visible,
      fullscreen: "",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.handleClose
    }
  }, [_c("div", [_vm.status == "update" ? _c("quote-status-panel", {
    staticStyle: {
      "margin-bottom": "32px"
    },
    attrs: {
      form: _vm.form
    },
    on: {
      "change-status": _vm.handleChangeStatus
    }
  }) : _vm._e(), _c("customer-panel", {
    ref: "customerRef",
    attrs: {
      customer: _vm.form.customer,
      form: _vm.form,
      onChangeImages: _vm.handleChangeImages
    },
    on: {
      changeArea: _vm.handleChangeArea
    }
  }), _c("quote-process-tab", {
    ref: "QuoteProcess",
    attrs: {
      form: _vm.form,
      onAddProd: _vm.handleClickAddProduct,
      onDeleteProd: _vm.handleDeleteProd,
      onUpdateProd: _vm.handleUpdateProd,
      visibleLog: _vm.status == "update",
      quoteId: _vm.form.id,
      expiredQuoteConfig: _vm.expiredQuoteConfig,
      isFocus: _vm.visible,
      onAddOther: _vm.handleAddOther,
      visibleForm: _vm.visible
    }
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", [_vm.status == "update" ? [!_vm.form.isDeleted ? _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "danger",
      loading: _vm.loadingDelete
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa")]) : _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      loading: _vm.loadingRestore
    },
    on: {
      click: _vm.handleRestore
    }
  }, [_vm._v("Khôi phục")])] : _vm._e()], 2), _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Lưu")])], 1), _c("quote-product-modal", {
    ref: "QuoteProductModal",
    attrs: {
      area: _vm.form.customer.area
    },
    on: {
      add: _vm.handleAddProduct,
      update: _vm.updateProd
    }
  }), _c("QuoteProductModalV3", {
    ref: "QuoteProductModalV3",
    attrs: {
      area: _vm.form.customer.area
    },
    on: {
      add: _vm.handleAddProduct,
      update: _vm.updateProd
    }
  }), _c("OtherProductQuoteModal", {
    ref: "OtherProductQuoteModal",
    on: {
      "create:ok": _vm.handleCreateOtherProductOk,
      "update:ok": _vm.updateProd
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;