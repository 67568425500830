"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.treatmentApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var treatmentApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/treatment",
      params: params
    });
  }
};
exports.treatmentApi = treatmentApi;