"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arrayUniqueByKey = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.map.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/es.array.map.js");

var _toConsumableArray2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/toConsumableArray.js"));

var arrayUniqueByKey = function arrayUniqueByKey(array, key) {
  return (0, _toConsumableArray2.default)(new Map(array.map(function (item) {
    return [item[key], item];
  })).values());
};

exports.arrayUniqueByKey = arrayUniqueByKey;