"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "top-sale-table"
  }, _vm._l(_vm.data, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("div", {
      staticClass: "avatar"
    }, [_c("img", {
      attrs: {
        src: _vm.$baseUrl + item.staffAvatar,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "info"
    }, [_c("span", {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.staffName) + " ")]), _c("br"), _c("CountTo", {
      staticClass: "value",
      attrs: {
        startVal: 0,
        endVal: item.amount
      }
    })], 1)]);
  }), 0);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;