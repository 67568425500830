"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.productionLogs,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Nhân viên",
      prop: "staff.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Loại"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(_vm.ProductionLogTypeTrans[row.type]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Lệnh làm việc",
      prop: "productionWork.code"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "LSX",
      prop: "workOrder.code"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Mô tả",
      prop: "note"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", [_vm._v(_vm._s(_vm.$formatDateTime(row.dateCreated)))])];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;