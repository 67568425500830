"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "misa-opportunity-item"
  }, [_c("div", {
    staticClass: "opportunity--name",
    on: {
      click: _vm.handleClick
    }
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.misaOpportunity.name) + " ")])]), _vm.misaOpportunity.misaCustomer ? _c("div", {
    staticClass: "opportunity--customer"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Khách hàng: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.misaOpportunity.misaCustomer.name) + " - " + _vm._s(_vm.misaOpportunity.misaCustomer.code) + " ")])]) : _vm._e(), _c("div", {
    staticClass: "opportunity--stage"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giai đoạn: ")]), _c("el-tag", {
    staticStyle: {
      border: "none"
    },
    attrs: {
      color: _vm.MisaOpportunityStatusColors[_vm.misaOpportunity.stageId]
    }
  }, [_c("span", {
    staticStyle: {
      color: "#fff"
    }
  }, [_vm._v(" " + _vm._s(_vm.misaOpportunity.stageText))])])], 1), _c("div", {
    staticClass: "opportunity--last-activity"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhiệm vụ cuối: ")]), _vm.misaOpportunity.lastActivity ? _c("span", [_vm._v(_vm._s(_vm.misaOpportunity.lastActivity.name))]) : _c("el-tag", {
    attrs: {
      type: "danger",
      size: "mini",
      effect: "dark"
    }
  }, [_vm._v("Chưa có")])], 1), _c("p", {
    staticClass: "opportunity--create-by"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người thực hiện: ")]), _vm._v(_vm._s(_vm.misaOpportunity.ownerMisaEmployee.name))]), _c("div", {
    staticClass: "customer--last-activity"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày tạo: ")]), _c("span", [_vm._v(_vm._s(_vm.$stringToDateTime(_vm.misaOpportunity.createdDate)))])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;