"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "footer-fixed",
    attrs: {
      title: "Xem chi tiết chuyến",
      visible: _vm.visible,
      fullscreen: "",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.handleClose
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("div", [_c("label", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Xem chi tiết chuyến")]), _vm.deliveryTrip && _vm.type == "NORMAL" ? _c("div", [_c("span", {
    staticStyle: {
      color: "#f54a45",
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatDate(_vm.deliveryTrip.startAt, "HH:mm")))]), _vm._v(" - "), _c("span", [_vm._v(_vm._s(_vm.deliveryTrip.name))]), _vm._v(" - "), _c("span", [_vm._v(_vm._s(_vm.deliveryTrip.privateName))])]) : _vm.type == "PE" ? _c("div", [_c("span", {
    staticStyle: {
      color: "#f54a45",
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.date) + " - ")]), _c("span", {
    staticStyle: {
      color: "#f54a45"
    }
  }, [_vm._v("Có yêu cầu quấn PE")])]) : _vm._e()])]), _vm.deliveryTrips.length ? _c("div", {
    attrs: {
      id: "view-delivery-group-modal"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "20px",
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng thể tích: ")]), _c("span", [_vm._v(_vm._s(_vm.totalCubicMeter) + " m3")])]), _vm.visibleDelivered ? [_c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng thể tích (Đã giao): ")]), _c("span", [_vm._v(_vm._s(_vm.totalCubicMeterDelivered.toFixed(1)) + " m3")])]), _c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng mét vuông: ")]), _c("span", [_vm._v(_vm._s(_vm.totalMeterDelivered) + " m2")])]), _vm.deliveryTrips.length > 1 ? _c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ghép: ")]), _c("span", {
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.deliveryTrips.length - 1) + " điểm (" + _vm._s(_vm.$formatNumberVN(_vm.phiGhepDiem)) + " đ) ")])]) : _vm._e(), _c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền theo thể tích: ")]), _c("span", {
    staticStyle: {
      color: "red",
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByCubicMeter)) + "đ")]), _vm.phiGhepDiem ? _c("span", [_vm._v(" + " + _vm._s(_vm.$formatNumberVN(_vm.phiGhepDiem)) + "đ = "), _c("span", {
    staticStyle: {
      color: "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.totalAmountByCubicMeter + _vm.phiGhepDiem)) + " ")])]) : _vm._e()]), _c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền theo mét vuông: ")]), _c("span", {
    staticStyle: {
      color: "red",
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByMeter)) + "đ")]), _vm.phiGhepDiem ? _c("span", [_vm._v(" + " + _vm._s(_vm.$formatNumberVN(_vm.phiGhepDiem)) + "đ = "), _c("span", {
    staticStyle: {
      color: "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByMeter + _vm.phiGhepDiem)))])]) : _vm._e(), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền theo mét vuông (Mới): ")]), _c("span", {
    staticStyle: {
      color: "red",
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByMeter2)) + "đ")]), _vm.phiGhepDiem ? _c("span", [_vm._v(" + " + _vm._s(_vm.$formatNumberVN(_vm.phiGhepDiem)) + "đ = "), _c("span", {
    staticStyle: {
      color: "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByMeter2 + _vm.phiGhepDiem)))])]) : _vm._e()]), _vm.visibleDelivered ? _c("div", _vm._l(_vm.summaryAllWave, function (wave) {
    return _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" " + _vm._s(wave.cubicMeter) + " m3 sóng " + _vm._s(wave.wave) + ": ")]), _c("span", [_vm._v(" " + _vm._s(wave.meter) + " m2 ")])]);
  }), 0) : _vm._e()])] : _vm._e(), _vm.visibleDelivered ? [_c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    staticStyle: {
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" % Giá mét vuông ")]), _c("el-select", {
    attrs: {
      disabled: _vm.isDisabled,
      filterable: ""
    },
    on: {
      change: _vm.handleChangePrice
    },
    model: {
      value: _vm.meterPercent,
      callback: function callback($$v) {
        _vm.meterPercent = $$v;
      },
      expression: "meterPercent"
    }
  }, _vm._l(_vm.meterPercents, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: "".concat(item, "%"),
        value: item
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    staticStyle: {
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Giá 1 mét khối ")]), _c("el-select", {
    attrs: {
      disabled: _vm.isDisabled,
      filterable: ""
    },
    on: {
      change: _vm.handleChangePrice
    },
    model: {
      value: _vm.cubicMeterPrice,
      callback: function callback($$v) {
        _vm.cubicMeterPrice = $$v;
      },
      expression: "cubicMeterPrice"
    }
  }, _vm._l(_vm.cubicMeterPrices, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: "".concat(_vm.$formatNumberVN(item), "\u0111"),
        value: item
      }
    });
  }), 1)], 1)])] : _vm._e()], 2), _c("el-collapse", {
    attrs: {
      accordion: ""
    },
    model: {
      value: _vm.actives,
      callback: function callback($$v) {
        _vm.actives = $$v;
      },
      expression: "actives"
    }
  }, _vm._l(_vm.deliveryTrips, function (child, i) {
    return _c("el-collapse-item", {
      key: child.id,
      attrs: {
        name: child.id,
        disabled: ""
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("label", {
      staticStyle: {
        "font-size": "20px"
      }
    }, [_vm._v("Điểm " + _vm._s(i + 1) + ": " + _vm._s(child.customerName) + " ")]), _c("el-tag", {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        type: "",
        size: "normal",
        effect: "dark"
      }
    }, [_c("label", {
      staticStyle: {
        "font-size": "18px"
      },
      attrs: {
        for: ""
      }
    }, [_vm._v(" (" + _vm._s(child.totalCubicMeter.toFixed(1)) + " m3)")])])], 1), child.createdStaff ? _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Người tạo: ")]), _c("span", [_vm._v(" " + _vm._s(child.createdStaff.name) + " ")])]) : _vm._e(), _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("ĐC: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.getAddress(child)) + " ")])]), child.isPE ? _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      staticStyle: {
        color: "red"
      },
      attrs: {
        for: ""
      }
    }, [_vm._v("Yeu cầu quấn PE: ")]), _c("span", [_vm._v(" " + _vm._s(child.peNote) + " ")]), _c("div", {
      staticStyle: {
        "margin-top": "4px"
      }
    }, [_c("el-button", {
      staticClass: "font-bold",
      attrs: {
        type: "primary",
        size: "mini",
        icon: "el-icon-printer"
      },
      on: {
        click: function click($event) {
          return _vm.handlePrintShipInfo(child);
        }
      }
    }, [_vm._v(" In nhãn dán kiện hàng ")])], 1)]) : _vm._e(), child.note ? _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Ghi chú: ")]), _c("span", [_vm._v(" " + _vm._s(child.note) + " ")])]) : _vm._e(), child.privateNote ? _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Giao hộ: ")]), _c("span", [_vm._v(" " + _vm._s(child.privateNote) + " ")])]) : _vm._e(), _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" Tiền theo m2: ")]), _c("span", {
      staticStyle: {
        color: "red",
        "font-size": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(child.amountByMeter)) + "đ (" + _vm._s(child.totalMeterDelivered) + "m2) ")])]), _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" Tiền theo m2 (mới): ")]), _c("span", {
      staticStyle: {
        color: "red",
        "font-size": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(child.amountByMeter2)) + "đ (" + _vm._s(child.totalMeterDelivered) + "m2) ")])]), _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" Tiền theo m3: ")]), _c("span", {
      staticStyle: {
        color: "red",
        "font-size": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(child.amountByCubicMeter)) + "đ (" + _vm._s(child.totalCubicMeterDelivered) + "m3) ")])]), _vm.visibleDelivered ? _c("div", _vm._l(_vm.getSummaryWave(child), function (wave) {
      return _c("div", {
        staticStyle: {
          "font-size": "18px"
        }
      }, [_c("label", {
        attrs: {
          for: ""
        }
      }, [_vm._v(" " + _vm._s(child.totalCubicMeterDelivered) + " m3 sóng " + _vm._s(wave.wave) + ": ")]), _c("span", [_vm._v(" " + _vm._s(wave.meter) + " m2 ")]), _c("div", [_c("label", {
        attrs: {
          for: ""
        }
      }, [_vm._v("Phí vc 1 m2 sóng " + _vm._s(wave.wave) + ": ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(wave.costPerMeter)) + " đ")])])]);
    }), 0) : _vm._e(), _c("DeliveryTripDetailTable", {
      attrs: {
        data: child.deliveryTripDetails,
        isDisableForm: true,
        visibleNote: true,
        visibleProductCode: false,
        visibleCubicMeter: false,
        visibleTotalNVL: false,
        visibleDelivered: _vm.visibleDelivered,
        isDisabled: _vm.isDisabled
      },
      on: {
        "change-delivered": function changeDelivered(detail) {
          return _vm.handleChangeDelivered(detail, child);
        },
        fill: function fill($event) {
          return _vm.handleFill(child);
        }
      }
    })], 2);
  }), 1)], 1) : _vm._e(), _c("DeliveryPrint", {
    ref: "DeliveryPrint",
    attrs: {
      deliveryTrip: _vm.deliveryTrip,
      deliveryTrips: _vm.deliveryTrips,
      totalCubicMeter: _vm.totalCubicMeter,
      date: _vm.date,
      type: _vm.type,
      visibleDelivered: _vm.visibleDelivered
    }
  }), _c("ShipFormPrint", {
    ref: "ShipFormPrint",
    attrs: {
      deliveryTrip: _vm.selectedChild
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("Đóng")]), !_vm.visibleDelivered ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.print
    }
  }, [_vm._v("In phiếu YC giao hàng")]) : [_c("div", [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      loading: _vm.loading,
      type: "primary",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.print
    }
  }, [_vm._v("In")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      disabled: _vm.isDisabled,
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("Lưu")])], 1)]], 2)], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;