"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaskFollowType = exports.TaskFollowStatus = void 0;
var TaskFollowType;
exports.TaskFollowType = TaskFollowType;

(function (TaskFollowType) {
  TaskFollowType["MultipleProduct"] = "MULTIPLE_PRODUCT";
  TaskFollowType["SingleProduct"] = "SINGLE_PRODUCT";
  TaskFollowType["Sale"] = "SALE";
  TaskFollowType["Profit"] = "PROFIT";
  TaskFollowType["ProfitPercent"] = "PROFIT_PERCENT";
  TaskFollowType["OneOrder"] = "ONE_ORDER";
})(TaskFollowType || (exports.TaskFollowType = TaskFollowType = {}));

var TaskFollowStatus;
exports.TaskFollowStatus = TaskFollowStatus;

(function (TaskFollowStatus) {
  TaskFollowStatus["Pending"] = "PENDING";
  TaskFollowStatus["Approve"] = "APPROVE";
  TaskFollowStatus["PendingDelete"] = "PENDING_DELETE"; //chờ duyệt unfolow
})(TaskFollowStatus || (exports.TaskFollowStatus = TaskFollowStatus = {}));