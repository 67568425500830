"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "panel-shadow"
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "8px"
    }
  }, [_vm._v(_vm._s(_vm.form.status != _vm.QuoteStatus.Success ? "Trạng thái đơn" : "Trạng thái sản xuất"))]), _vm.form.status != _vm.QuoteStatus.Success ? _c("el-select", {
    attrs: {
      filterable: ""
    },
    on: {
      change: _vm.handleChangeStatus
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, _vm._l(_vm.QuoteStatusTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1) : _c("el-select", {
    attrs: {
      filterable: ""
    },
    on: {
      change: _vm.handleChangeScheduleProductionStatus
    },
    model: {
      value: _vm.form.scheduleProductionStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "scheduleProductionStatus", $$v);
      },
      expression: "form.scheduleProductionStatus"
    }
  }, _vm._l(_vm.ScheduleProductionStatusTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1), _c("section", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "8px"
    }
  }, [_vm._v("Người báo giá")]), _c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffId", $$v);
      },
      expression: "form.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;