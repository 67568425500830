"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.easyInvoiceApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var easyInvoiceApi = {
  createDraffInvoice: function createDraffInvoice(data) {
    return (0, _request.default)({
      url: '/v1/admin/easyInvoice/create-draff-invoice',
      method: 'post',
      data: data
    });
  },
  createDraffOtherInvoice: function createDraffOtherInvoice(data) {
    return (0, _request.default)({
      url: '/v1/admin/easyInvoice/create-draff-other-invoice',
      method: 'post',
      data: data
    });
  },
  sendEmailDraff: function sendEmailDraff(orderId, data) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/".concat(orderId, "/send-email"),
      method: 'post',
      data: data
    });
  },
  sendEmailDraffOther: function sendEmailDraffOther(orderId) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/".concat(orderId, "/send-email/other"),
      method: 'post'
    });
  },
  remind: function remind(orderId, data) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/".concat(orderId, "/remind"),
      method: 'post',
      data: data
    });
  },
  remindOther: function remindOther(orderId, data) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/".concat(orderId, "/remind/other"),
      method: 'post',
      data: data
    });
  },
  downloadInvoice: function downloadInvoice(orderId, params) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/".concat(orderId, "/download-invoice"),
      params: params
    });
  },
  downloadOtherInvoice: function downloadOtherInvoice(orderId, params) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/".concat(orderId, "/download-other-invoice"),
      params: params
    });
  },
  updateInvoice: function updateInvoice(data) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/update-draff-invoice",
      method: 'put',
      data: data
    });
  },
  updateOtherInvoice: function updateOtherInvoice(data) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/update-draff-invoice/other",
      method: 'put',
      data: data
    });
  },
  cancelInvoice: function cancelInvoice(orderId) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/".concat(orderId, "/cancel"),
      method: 'post'
    });
  },
  cancelOtherInvoice: function cancelOtherInvoice(orderId) {
    return (0, _request.default)({
      url: "/v1/admin/easyInvoice/".concat(orderId, "/cancel/other"),
      method: 'post'
    });
  }
};
exports.easyInvoiceApi = easyInvoiceApi;