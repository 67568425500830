"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      title: "Cấu hình KPI CSKH",
      visible: _vm.visible,
      width: "500px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lần tương tác",
      prop: "target"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{ alias: 'decimal', groupSeparator: '.', rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false }"
    }],
    model: {
      value: _vm.form.target,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "target", $$v);
      },
      expression: "form.target"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submitData
    }
  }, [_vm._v("Lưu")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;