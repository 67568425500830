"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var BuyType;

(function (BuyType) {
  BuyType["SoonBuy"] = "SOON_BUY";
  BuyType["LongBuy"] = "LONG_BUY";
})(BuyType || (BuyType = {}));

function getDefaultState() {
  return {
    query: {
      buyType: null,
      staffId: null,
      isHasVat: null
    },
    tab: ''
  };
}

var state = getDefaultState;
var mutations = {
  CHANGE_QUERY: function CHANGE_QUERY(state, newState) {
    state.query = newState;
  },
  CHANGE_TAB: function CHANGE_TAB(state, tab) {
    state.tab = tab;
  }
};
var getters = {};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations
};
exports.default = _default;