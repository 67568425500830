"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "employee-header"
  }, [_c("div", {
    staticClass: "avatar-box",
    style: {
      "background-color": !_vm.avatar && "rgb(255, 191, 105)"
    }
  }, [_c("el-image", {
    attrs: {
      src: _vm.$baseUrlMedia + _vm.avatar,
      fit: "cover",
      lazy: true
    }
  }, [_c("span", {
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_vm._v(_vm._s(_vm.nameShort))])])], 1), _c("span", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.name))])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;