"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container schedule-production"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabs, function (item) {
    return _c("el-tab-pane", {
      key: item.status,
      attrs: {
        label: _vm.ScheduleProductionStatusTrans[item.status],
        name: item.status
      }
    }, [_c("span", {
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_vm._v(" " + _vm._s(_vm.ScheduleProductionStatusTrans[item.status]) + " "), _c("el-badge", {
      staticClass: "schedule-production-status",
      class: item.status,
      attrs: {
        value: item.total
      }
    })], 1)]);
  }), 1), _c("schedule-chart", {
    attrs: {
      data: _vm.dataQuote
    },
    on: {
      "submit:ok": _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;