"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serviceApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var serviceApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/service",
      params: params
    });
  },
  createSingle: function createSingle(data) {
    return (0, _request.default)({
      url: "/service/single",
      data: data,
      method: "post"
    });
  },
  createPackage: function createPackage(data) {
    return (0, _request.default)({
      url: "/service/package",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/service/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updateSingle: function updateSingle(id, data) {
    return (0, _request.default)({
      url: "/service/single/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updatePackage: function updatePackage(id, data) {
    return (0, _request.default)({
      url: "/service/package/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/service/".concat(id, "/delete"),
      method: "post"
    });
  }
};
exports.serviceApi = serviceApi;