"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Xu\u1EA5t VAT #".concat(_vm.orderId),
      visible: _vm.visible,
      width: "1100px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("h3", {
          staticStyle: {
            margin: "0",
            "font-size": "22px"
          }
        }, [_c("span", {
          staticStyle: {
            "vertical-align": "middle"
          }
        }, [_vm._v("Xuất VAT ")]), _c("el-link", {
          staticStyle: {
            "font-size": "20px",
            "vertical-align": "middle"
          },
          attrs: {
            type: "primary",
            target: "_blank",
            href: "https://nhanh.vn/order/manage/detail?storeId=84054&id=".concat(_vm.orderId)
          }
        }, [_vm._v(" #" + _vm._s(_vm.orderId) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_c("el-tabs", {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      name: "vat",
      label: "VAT",
      disabled: !!_vm.notVatProducts.length
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "preview"
  }, [!_vm.isValidVat ? _c("el-alert", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      title: "Giá trị VAT không khớp",
      type: "error",
      effect: "dark",
      closable: false
    }
  }) : _vm._e(), !_vm.isValidAmount ? _c("el-alert", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      title: "Giá trị đơn hàng không khớp",
      type: "error",
      effect: "dark",
      closable: false
    }
  }) : _vm._e(), _vm.isNotUnit ? _c("el-alert", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      title: "".concat(_vm.productNotUnit.name, " kh\xF4ng c\xF3 \u0111\u01A1n v\u1ECB t\xEDnh"),
      type: "error",
      effect: "dark",
      closable: false
    }
  }) : _vm._e(), !_vm.order.isVatExported && _vm.editable ? [!_vm.orderMers.length ? _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleOpenMerOrder
    }
  }, [_vm._v("Gộp đơn")]) : _vm.orderMers.length && _vm.order.vatStatus == _vm.VatStatus.UnDraff ? _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "warning"
    },
    on: {
      click: _vm.handleEditMerOrder
    }
  }, [_vm._v("Xóa gộp đơn")]) : _vm._e()] : _vm._e(), _vm.orderMers.length ? _c("p", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đơn đang gộp: ")]), _vm._v(" " + _vm._s(_vm.orderMers.map(function (e) {
    return e.id;
  }).join(", ")) + " ")]) : _vm._e(), _c("section", {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c("h3", {
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_vm._v("Thông tin công ty:")]), _vm.order.customer.privateNote ? _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_c("span", {
    staticStyle: {
      "text-transform": "uppercase",
      background: "red",
      color: "#fff",
      "font-weight": "bold",
      "border-radius": "4px",
      padding: "0 4px",
      "font-size": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.order.customer.privateNote) + " ")])]) : _vm._e(), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tên công ty: ")]), _c("span", [_vm._v(_vm._s(_vm.order.customer.companyName))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("MST: ")]), _c("span", [_vm._v(_vm._s(_vm.order.customer.companyTaxCode))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Địa chỉ: ")]), _c("span", [_vm._v(_vm._s(_vm.order.customer.companyAddress))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Email nhận hóa đơn: ")]), _c("span", [_vm._v(_vm._s(_vm.order.customer.companyEmail))])])]), _c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" THÔNG TIN SẢN PHẨM: ")]), _vm._l(_vm.productsTransform, function (item, index) {
    return _c("div", {
      key: index
    }, [_vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(item.productName))]), _c("br"), _vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Đơn giá:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(item.price, 0, ".")) + " "), _c("br"), _vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Số lượng:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(item.quantity, 0, ".")) + " " + _vm._s(item.product.unit ? "(".concat(item.product.unit, ")") : "") + " "), _c("br"), _vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Thành tiền:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(Math.ceil(item.price * item.quantity), 0, ".")) + " "), _c("br"), _c("br")]);
  })], 2), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thành tiền: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalPrice)))])]), _c("section", [_c("div", [_c("label", {
    staticStyle: {
      "line-height": "28px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Phương thức thanh toán")]), _c("br"), _c("el-select", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      disabled: _vm.order.vatStatus != _vm.VatStatus.UnDraff
    },
    model: {
      value: _vm.order.paymentMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.order, "paymentMethod", $$v);
      },
      expression: "order.paymentMethod"
    }
  }, _vm._l(_vm.PaymentMethodTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("label", {
    staticStyle: {
      "line-height": "28px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Tiền bằng chữ")]), _c("el-input", {
    attrs: {
      disabled: !_vm.editable
    },
    model: {
      value: _vm.amountWord,
      callback: function callback($$v) {
        _vm.amountWord = $$v;
      },
      expression: "amountWord"
    }
  })], 1), _c("section", _vm._l(_vm.order.subOrders, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "4px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" Hóa đơn (" + _vm._s(item.vatFactor * 100) + "%): ")]), _c("span", [_vm._v(_vm._s(_vm.generateVatStatus(item.vatStatus)))]), _c("el-button", {
      staticStyle: {
        "margin-left": "8px"
      },
      attrs: {
        type: "primary",
        size: "mini",
        loading: item.loadingViewInvoice
      },
      on: {
        click: function click($event) {
          return _vm.viewSubInvoice(item);
        }
      }
    }, [_vm._v("Xem hóa đơn")])], 1);
  }), 0), !_vm.checkIsChangeCompanyInfo() ? _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("Thông tin công ty đã thay đổi")])]) : _vm._e()])]), _vm.editable ? _c("el-tab-pane", {
    attrs: {
      name: "company-info",
      label: "Thông tin công ty"
    }
  }, [_c("company-info-form", {
    attrs: {
      order: _vm.order,
      customer: _vm.customer
    },
    on: {
      "submit:ok": function submitOk($event) {
        _vm.onSubmitOk();

        _vm.fetchOrder();
      }
    },
    model: {
      value: _vm.exportVatInfo,
      callback: function callback($$v) {
        _vm.exportVatInfo = $$v;
      },
      expression: "exportVatInfo"
    }
  })], 1) : _vm._e(), _vm.editable ? _c("el-tab-pane", {
    attrs: {
      disabled: !_vm.notVatProducts.length,
      name: "issue",
      label: "Sản phẩm không xuất VAT"
    }
  }, [_c("product-issue-tab", {
    attrs: {
      noVatProducts: _vm.notVatProducts
    },
    on: {
      confirm: _vm.handleConfirmNoVat
    }
  })], 1) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.tab == "vat" ? _c("div", [_vm.order.vatStatus == _vm.VatStatus.Completed ? [_c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loadingRemind
    },
    on: {
      click: _vm.remindInvoice
    }
  }, [_vm._v(" Gửi email hóa đơn phát hành (" + _vm._s(_vm.order.emailPublishTime) + ") ")])] : [_vm.editable ? _c("el-button", {
    staticClass: "font-size--medium font-bold zalo-btn",
    attrs: {
      size: "small",
      disabled: !_vm.order.customer.companyName || _vm.order.vatStatus == _vm.VatStatus.Draff || _vm.isNotUnit,
      loading: _vm.loadingDraff
    },
    on: {
      click: _vm.draffInvoice
    }
  }, [_vm._v(" " + _vm._s(_vm.order.vatStatus == _vm.VatStatus.UnDraff ? "Tạo hóa đơn nháp" : "Đã gửi nháp") + " ")]) : _vm._e(), _vm.order.vatStatus == _vm.VatStatus.Draff && _vm.editable ? _c("el-button", {
    staticClass: "font-size--medium font-bold zalo-btn",
    attrs: {
      size: "small",
      loading: _vm.loadingUpdate
    },
    on: {
      click: _vm.updateDraffInvoice
    }
  }, [_vm._v(" Cập nhật hóa đơn nháp ")]) : _vm._e(), _vm.order.vatStatus == _vm.VatStatus.Draff ? _c("el-button", {
    staticClass: "font-size--medium font-bold zalo-btn",
    attrs: {
      size: "small",
      disabled: _vm.order.vatStatus != _vm.VatStatus.Draff,
      loading: _vm.loadingSendMail
    },
    on: {
      click: _vm.sendEmailDraff
    }
  }, [_vm._v(" Gửi email nháp (" + _vm._s(_vm.order.emailDraffTime) + ") ")]) : _vm._e()], (_vm.order.vatStatus == _vm.VatStatus.Draff || _vm.order.vatStatus == _vm.VatStatus.Completed) && _vm.order.iKey ? _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loadingViewInvoice
    },
    on: {
      click: _vm.viewInvoice
    }
  }, [_vm._v(" Xem hóa đơn ")]) : _vm._e(), _vm.order.vatStatus == _vm.VatStatus.Draff && _vm.editable ? _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "danger",
      size: "small",
      loading: _vm.loadingCancel
    },
    on: {
      click: _vm.cancelInvoice
    }
  }, [_vm._v(" Hủy hóa đơn nháp ")]) : _vm._e()], 2) : _vm._e(), _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1), _c("mer-order-modal", {
    ref: "MerOrderModal",
    on: {
      submit: _vm.handleMerOrder
    }
  }), _c("view-invoice-modal", {
    ref: "ViewInvoiceModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;