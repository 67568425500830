"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "delivery-trip-page"
  }, [_vm.loading ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mask-loading"
  }) : _c("div", [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingCalendar,
      expression: "loadingCalendar"
    }],
    staticStyle: {
      background: "white"
    }
  }, [_c("calendar-component", {
    ref: "CalendarComponent",
    attrs: {
      isEditableEvent: _vm.isAdmin,
      dateSelected: _vm.query.date,
      events: _vm.events,
      viewMode: _vm.query.viewMode,
      snapTime: 30,
      startHour: 6,
      shouldUseOneDayMode: false,
      timeStep: 30,
      endHour: 23,
      timeCellHeight: 40,
      splitDays: _vm.splitDays
    },
    on: {
      onDrop: _vm.handleDropEvent,
      eventDurationChange: _vm.handleResize,
      create: _vm.handleCreateEvent
    },
    scopedSlots: _vm._u([{
      key: "event",
      fn: function fn(_ref) {
        var event = _ref.event;
        return [_c("EventTripItem", {
          attrs: {
            onCheck: _vm.handleCheck,
            onClickItem: _vm.handleAddTrip,
            checkText: "Hoàn thành chuyến",
            visibleAdd: _vm.isAdmin,
            onAdd: _vm.handleAddTrip,
            onUpdate: _vm.handleUpdateTrip,
            visibleEdit: _vm.isAdmin,
            onCopy: _vm.handleCopy,
            visibleCopy: true,
            deliveryTrip: event.data,
            visibleSort: _vm.isAdmin,
            visibleCheck: _vm.isAdmin,
            onSort: _vm.handleSort,
            visibleCopyProduct: true
          },
          on: {
            copyProduct: _vm.handleCopyProduct
          }
        }, [_vm._l(event.data.children, function (item) {
          return _c("EventTripItem", {
            key: item.id,
            attrs: {
              isChild: true,
              visibleCopy: true,
              visibleCheck: _vm.isAdmin,
              checkText: "Hoàn thành lệnh",
              onCheck: _vm.handleCheck,
              onClickItem: _vm.handleViewDetailTrip,
              deliveryTrip: item,
              onCopy: _vm.handleCopySingle
            },
            on: {
              clone: _vm.handleClone
            }
          });
        })], 2)];
      }
    }])
  })], 1)]), _c("DeliveryTripModal", {
    ref: "DeliveryTripModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("DeliveryTripNameModal", {
    ref: "DeliveryTripNameModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("SortTripModal", {
    ref: "SortTripModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;