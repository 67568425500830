"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c("div", {
    staticClass: "misa-customer-tag",
    style: {
      background: _vm.tag.color
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.tag.name))])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;