"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [!_vm.product.isEdit ? _c("span", [_vm._v(" " + _vm._s(_vm.ProductCategoryTypeTrans[_vm.product.type]) + " ")]) : _c("el-select", {
    model: {
      value: _vm.product.tmpType,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "tmpType", $$v);
      },
      expression: "product.tmpType"
    }
  }, _vm._l(_vm.ProductCategoryTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;