"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaApi = void 0;

var _request = require("@/utils/request");

var misaApi = {
  findProducts: function findProducts(params) {
    return (0, _request.request)({
      url: '/v1/admin/misa/product',
      params: params
    });
  },
  checkPO: function checkPO(params) {
    return (0, _request.request)({
      url: '/v1/admin/misa/check/po',
      params: params
    });
  },
  crawOrder: function crawOrder(params) {
    return (0, _request.request)({
      url: '/v1/admin/misa/craw/order',
      method: 'post',
      params: params
    });
  },
  crawLSX: function crawLSX() {
    return (0, _request.request)({
      url: '/v1/admin/misa/craw/lsx',
      method: 'post'
    });
  },
  crawPO: function crawPO() {
    return (0, _request.request)({
      url: '/v1/admin/misa/craw/po',
      method: 'post'
    });
  },
  crawInventory: function crawInventory() {
    return (0, _request.request)({
      url: '/v1/admin/misa/craw/inventory',
      method: 'post'
    });
  }
};
exports.misaApi = misaApi;