"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fancyTime = void 0;

require("core-js/modules/es.number.to-fixed.js");

var _moment = _interopRequireDefault(require("moment"));

var fancyTime = function fancyTime(seconds) {
  var duration = _moment.default.duration(seconds, 'seconds');

  var h = duration.hours(); // 1

  var m = duration.minutes(); // 20

  var s = duration.seconds(); // 25

  if (h) {
    var newM = +(m / 60).toFixed(1);
    h += newM;
    return "".concat(h, " gi\u1EDD");
  } else if (m) {
    var newS = +(s / 60).toFixed(1);
    m += newS;
    return "".concat(m, " ph\xFAt");
  } else {
    return "".concat(s, " gi\xE2y");
  }
};

exports.fancyTime = fancyTime;