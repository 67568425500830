"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "misa-lead-item"
  }, [_c("div", {
    staticClass: "lead--name",
    on: {
      click: _vm.handleClick
    }
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.misaLead.leadName) + " - " + _vm._s(_vm.misaLead.code) + " ")])]), _c("div", {
    staticClass: "lead--phone"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("SĐT: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.misaLead.mobile) + " ")])]), _c("p", {
    staticClass: "lead--address"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Địa chỉ: ")]), _vm._v(_vm._s(_vm.misaLead.address))]), _c("div", {
    staticClass: "lead--last-activity"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhiệm vụ cuối: ")]), _vm.misaLead.lastActivity ? _c("span", [_vm._v(_vm._s(_vm.misaLead.lastActivity.name))]) : _c("el-tag", {
    attrs: {
      type: "danger",
      size: "mini",
      effect: "dark"
    }
  }, [_vm._v("Chưa có")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;