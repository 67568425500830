"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.poApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var poApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/admin/po',
      params: params
    });
  },
  findOne: function findOne(poId) {
    return (0, _request.default)({
      url: "/v1/admin/po/".concat(poId)
    });
  },
  findPendingQuantity: function findPendingQuantity(params) {
    return (0, _request.default)({
      url: "/v1/admin/po/pending-quantity",
      params: params
    });
  },
  findPoDetail: function findPoDetail(params) {
    return (0, _request.default)({
      url: '/v1/admin/po/po-detail/product',
      params: params
    });
  },
  completePoDetail: function completePoDetail(poDetailId) {
    return (0, _request.default)({
      url: "/v1/admin/po/poDetail/".concat(poDetailId, "/complete"),
      method: 'put'
    });
  },
  backcCompletePoDetail: function backcCompletePoDetail(poDetailId) {
    return (0, _request.default)({
      url: "/v1/admin/po/poDetail/".concat(poDetailId, "/back-complete"),
      method: 'put'
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/v1/admin/po',
      data: data,
      method: 'post'
    });
  },
  update: function update(poId, data) {
    return (0, _request.default)({
      url: "/v1/admin/po/".concat(poId),
      data: data,
      method: 'put'
    });
  },
  delete: function _delete(poId) {
    return (0, _request.default)({
      url: "/v1/admin/po/".concat(poId),
      method: 'delete'
    });
  }
};
exports.poApi = poApi;