"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "scroll-height",
    attrs: {
      sm: 18,
      xl: 18,
      offset: 0
    }
  }, [_c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _vm.isAdmin ? _c("div", {
    staticClass: "filter-item",
    staticStyle: {
      "margin-bottom": "2px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: function click() {
        return _vm.handleCreate();
      }
    }
  }, [_vm._v("Thêm mới")])], 1) : _vm._e(), _vm.isAdmin ? _c("div", {
    staticClass: "filter-item",
    staticStyle: {
      "margin-bottom": "2px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loadingSync,
      icon: "el-icon-refresh"
    },
    on: {
      click: function click() {
        return _vm.handleSync();
      }
    }
  }, [_vm._v("Đồng bộ")])], 1) : _vm._e()]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: true
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isNeedImport,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isNeedImport", $$v);
      },
      expression: "query.isNeedImport"
    }
  }, [_vm._v("Cần nhập kho")]), _c("el-checkbox", {
    attrs: {
      label: true
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isAlmostOver,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isAlmostOver", $$v);
      },
      expression: "query.isAlmostOver"
    }
  }, [_vm._v("Sắp hết hàng")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thành phẩm",
      width: "300",
      prop: "code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mã: ")]), _c("span", [_vm._v(_vm._s(row.code))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tên: ")]), _c("span", [_vm._v(_vm._s(row.name))])]), row.product ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("SP Nhanh: ")]), _c("span", [_vm._v(_vm._s(row.product.name))])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn tối thiểu (Mua hàng)",
      align: "right",
      sortable: "",
      prop: "minQuantity"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.id ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(row.minQuantity)) + " ")]) : _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click() {
              return _vm.handleCreate(row.code);
            }
          }
        }, [_vm._v(" Chưa setup ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn tối thiểu (Sản xuất)",
      align: "right",
      sortable: "",
      prop: "minQuantityProduce"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.id ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(row.minQuantityProduce)) + " ")]) : _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click() {
              return _vm.handleCreate(row.code);
            }
          }
        }, [_vm._v(" Chưa setup ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn kho thực tế",
      align: "right",
      sortable: "",
      prop: "remain"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.id ? _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewInventoryDetail(row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.remain, 0))) + " ")]) : _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click() {
              return _vm.handleCreate(row.code);
            }
          }
        }, [_vm._v(" Chưa setup ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn kho thực tế "), _c("el-tooltip", {
    attrs: {
      content: "Bao gồm TP, BTP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _c("el-table-column", {
    attrs: {
      label: "Đơn hàng tạm giữ",
      align: "right",
      sortable: "",
      prop: "pendingExport"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.id ? _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewExport(row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.pendingExport, 0))) + " ")]) : _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click() {
              return _vm.handleCreate(row.code);
            }
          }
        }, [_vm._v(" Chưa setup ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn có thể bán",
      align: "right",
      sortable: "",
      prop: "available"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [row.id ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.available, 0))) + " ")]) : _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click() {
              return _vm.handleCreate(row.code);
            }
          }
        }, [_vm._v(" Chưa setup ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn có thể bán "), _c("el-tooltip", {
    attrs: {
      content: "Bao gồm cả BTP, TP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _c("el-table-column", {
    attrs: {
      label: "Tồn có thể bán (TP)",
      align: "right",
      sortable: "",
      prop: "availableTP"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [row.id ? _c("span", [row.availableTP >= row.minQuantityProduce ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.availableTP, 0))) + " ")]) : _c("label", {
          staticStyle: {
            color: "red",
            "font-weight": "bold"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.availableTP, 0))) + " ")])]) : _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click() {
              return _vm.handleCreate(row.code);
            }
          }
        }, [_vm._v(" Chưa setup ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn có thể bán (TP) "), _c("el-tooltip", {
    attrs: {
      content: "Gồm TP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _vm.isCheck ? _c("el-table-column", {
    attrs: {
      label: "Số lượng cần"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [row.id ? [row.needQuantity <= row.availableTP ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(row.needQuantity)) + " ")]) : _c("span", {
          staticStyle: {
            color: "red",
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(row.needQuantity)) + " ")])] : _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click() {
              return _vm.handleCreate(row.code);
            }
          }
        }, [_vm._v(" Chưa setup ")])];
      }
    }], null, false, 3107305283)
  }) : _vm._e(), _vm.isAdmin ? _c("el-table-column", {
    attrs: {
      label: "NVL => TP",
      align: "right",
      sortable: "",
      prop: "materialQuantity"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [row.id ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.materialQuantity, 0))) + " ")]) : _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click() {
              return _vm.handleCreate(row.code);
            }
          }
        }, [_vm._v(" Chưa setup ")])];
      }
    }], null, false, 1901184982)
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" NVL => TP "), _c("el-tooltip", {
    attrs: {
      content: "Nguyên vật liệu khả dụng để sản xuất ra thành phẩm",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "Đang mua hàng",
      align: "right",
      sortable: "",
      prop: "pending"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewPending(row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.pending, 0))) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Đang mua hàng "), _c("el-tooltip", {
    attrs: {
      content: "Đã lên đơn đặt hàng và chờ giao",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _vm.isAdmin ? _c("el-table-column", {
    attrs: {
      label: "Tồn có thể bán + Đang mua hàng",
      align: "right",
      sortable: "",
      prop: "realRemain"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c("span", {
          style: (0, _defineProperty2.default)({
            color: row.realRemain < row.minQuantity ? "red" : "#000"
          }, "font-weight", row.realRemain < row.minQuantity ? "bold" : 400)
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.realRemain, 0))) + " ")])];
      }
    }], null, false, 140231334)
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn có thể bán + Đang mua hàng "), _c("el-tooltip", {
    attrs: {
      content: "Lượng hàng ở kho và hàng hóa đang chờ nhập về",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2) : _vm._e(), _vm.isAdmin ? _c("el-table-column", {
    attrs: {
      label: "Số lượng mua hàng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.buyQuantity)) + " ")];
      }
    }], null, false, 1086948055)
  }) : _vm._e(), _vm.isAdmin ? _c("el-table-column", {
    attrs: {
      label: "Ghi chú mua hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_vm._v(" " + _vm._s(row.buyNote) + " ")];
      }
    }], null, false, 2643287585)
  }) : _vm._e(), _vm.isAdmin ? _c("el-table-column", {
    attrs: {
      label: "Ghi chú mua hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var row = _ref14.row;
        return [_vm._v(" " + _vm._s(row.buyNote) + " ")];
      }
    }], null, false, 2643287585)
  }) : _vm._e(), _vm.isAdmin ? _c("el-table-column", {
    attrs: {
      label: "Loại hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var row = _ref15.row;
        return [_vm._v(" " + _vm._s(_vm.MisaProductTypeTrans[row.type]) + " ")];
      }
    }], null, false, 3651008894)
  }) : _vm._e(), _vm.isAdmin ? _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      align: "center",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var row = _ref16.row;
        return [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }), _c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        })];
      }
    }], null, false, 303067240)
  }) : _vm._e()], 1)], 1), _c("el-col", {
    staticClass: "scroll-height",
    attrs: {
      sm: 6,
      xl: 6,
      offset: 0
    }
  }, [_c("MisaCrawlLogWidget")], 1)], 1), _c("MisaProductModal", {
    ref: "MisaProductModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("MisaProductExport", {
    ref: "MisaProductExport"
  }), _c("MisaProductPendingModal", {
    ref: "MisaProductPendingModal"
  }), _c("MisaProductInventoryDetailModal", {
    ref: "MisaProductInventoryDetailModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;