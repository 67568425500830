"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaAttachApi = void 0;

var _request = require("@/utils/request");

var misaAttachApi = {
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/misaAttach/".concat(id),
      method: 'patch',
      data: data
    });
  }
};
exports.misaAttachApi = misaAttachApi;