"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("span", {
    ref: "tag",
    staticStyle: {
      color: "white",
      "border-radius": "4px",
      margin: "2px 4px",
      "font-size": "12px"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.misaTag.name))])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;