"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("el-table", {
    attrs: {
      data: _vm.products,
      border: "",
      stripe: ""
    },
    on: {
      "row-click": _vm.handleUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Sản phẩm",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tỷ số quy đổi thể tích",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("edit-ratio-change-input", {
          attrs: {
            onCancel: _vm.handleCancelEdit,
            product: row,
            onUpdate: _vm.updateProduct
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Loại",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("edit-product-type-select", {
          attrs: {
            product: row
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.isEdit ? [_c("el-tooltip", {
          attrs: {
            content: "Lưu",
            placement: "top",
            effect: "dark"
          }
        }, [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-check"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.updateProduct(row);
            }
          }
        })], 1), _c("el-tooltip", {
          attrs: {
            content: "Hủy",
            placement: "top",
            effect: "dark"
          }
        }, [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-close"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleCancelEdit(row);
            }
          }
        })], 1)] : _vm._e()];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchProducts
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;