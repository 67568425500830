import { render, staticRenderFns } from "./ProviderCartonPriceModal.vue?vue&type=template&id=0f7f5e6b&"
import script from "./ProviderCartonPriceModal.vue?vue&type=script&lang=ts&"
export * from "./ProviderCartonPriceModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f7f5e6b')) {
      api.createRecord('0f7f5e6b', component.options)
    } else {
      api.reload('0f7f5e6b', component.options)
    }
    module.hot.accept("./ProviderCartonPriceModal.vue?vue&type=template&id=0f7f5e6b&", function () {
      api.rerender('0f7f5e6b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/provider-carton-price/components/ProviderCartonPriceModal.vue"
export default component.exports