"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.distances = void 0;
var distances = [{
  label: '30km',
  value: 30
}, {
  label: '40km',
  value: 40
}, {
  label: '50km',
  value: 50
}, {
  label: '60km',
  value: 60
}];
exports.distances = distances;