"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Chi tiết thay đổi",
      visible: _vm.visible,
      fullscreen: "",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.deliveryTripLog ? [_c("div", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("Người thực hiện: ")]), _c("label", {
    staticStyle: {
      "font-weight": "bold",
      color: "#000"
    }
  }, [_vm._v(_vm._s(_vm.deliveryTripLog.staff.name))])]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("h2", [_vm._v("Cũ")]), _vm.oldForm ? _c("DeliveryTripForm", {
    attrs: {
      form: _vm.oldForm,
      isDisableForm: true,
      misaTags: _vm.misaTags
    }
  }) : _vm._e(), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("DeliveryTripDetailTable", {
    attrs: {
      data: _vm.oldDeliveryTripDetails,
      isDisableForm: true,
      visibleLinkProduct: false
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("h2", [_vm._v("Đã thay đổi")]), _vm.curForm ? _c("DeliveryTripForm", {
    attrs: {
      isCheckDiff: true,
      otherForm: _vm.oldForm,
      form: _vm.curForm,
      isDisableForm: true,
      misaTags: _vm.misaTags
    }
  }) : _vm._e(), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("DeliveryTripDetailTable", {
    attrs: {
      data: _vm.curDeliveryTripDetails,
      isDisableForm: true,
      visibleLinkProduct: false
    }
  })], 1)], 1)], 1)] : _vm._e(), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;