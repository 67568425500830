"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Thời gian",
      width: "130",
      prop: "session"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", {
          staticStyle: {
            "font-size": "32px",
            "text-transform": "capitalize",
            "font-weight": "bold",
            color: "red"
          }
        }, [_vm._v(" " + _vm._s(row.session) + " ")]), _c("div", [_vm._v(" " + _vm._s(_vm.renderTime(row.session)) + " ")]), row.note ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Ghi chú: ")]), _c("span", [_vm._v(" " + _vm._s(row.note))])]) : _vm._e(), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onUpdateNote(row.session);
            }
          }
        }, [_vm._v("Ghi chú")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hàng tồn",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(" Hàng tồn ")]), _c("el-button", {
          attrs: {
            icon: "el-icon-document-copy",
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onCopy("tonKho");
            }
          }
        }, [_vm._v("Copy")])];
      }
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return _vm._l(_vm.getProducts("tonKho", row), function (item) {
          return _c("div", {
            staticStyle: {
              display: "flex"
            },
            style: {
              color: item.isEnough ? "#0b830b" : "red"
            }
          }, [_c("div", {
            staticStyle: {
              flex: "1",
              "border-bottom": "1px dotted #000"
            }
          }, [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v(_vm._s(item.name) + " (Tồn: " + _vm._s(_vm.$formatNumberVN(item.available)) + ")")]), item.note ? _c("span", {
            staticStyle: {
              color: "#000"
            }
          }, [_vm._v(" (" + _vm._s(item.note) + ") ")]) : _vm._e()]), _vm.visibleSelectSession ? _c("div", [_c("el-checkbox", {
            attrs: {
              value: row.session == "sáng",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "sáng", "tonKho");
              }
            }
          }, [_vm._v("Sáng")]), _c("el-checkbox", {
            attrs: {
              value: row.session == "trưa",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "trưa", "tonKho");
              }
            }
          }, [_vm._v("Trưa")]), _c("el-checkbox", {
            attrs: {
              value: row.session == "chiều",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "chiều", "tonKho");
              }
            }
          }, [_vm._v("Chiều")]), _c("el-checkbox", {
            attrs: {
              value: row.session == "tối",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "tối", "tonKho");
              }
            }
          }, [_vm._v("Tối")])], 1) : _vm._e()]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(item.quantity)) + " thùng")])]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hải Đăng tự sản xuất",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(" Hải Đăng tự sản xuất ")]), _c("el-button", {
          attrs: {
            icon: "el-icon-document-copy",
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onCopy("sanXuat");
            }
          }
        }, [_vm._v("Copy")])];
      }
    }, {
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return _vm._l(_vm.getProducts("sanXuat", row), function (item) {
          return _c("div", {
            staticStyle: {
              display: "flex"
            },
            style: {
              color: item.isEnough ? "#0b830b" : "red"
            }
          }, [_c("div", {
            staticStyle: {
              flex: "1",
              "border-bottom": "1px dotted #000"
            }
          }, [_c("el-tooltip", {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: "T\u1ED3n kho: ".concat(item.available),
              placement: "top-start"
            }
          }, [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v(_vm._s(item.name))]), item.note ? _c("span", {
            staticStyle: {
              color: "#000"
            }
          }, [_vm._v(" (" + _vm._s(item.note) + ") ")]) : _vm._e()])]), _vm.visibleSelectSession ? _c("div", [_c("el-checkbox", {
            attrs: {
              value: row.session == "sáng",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "sáng", "sanXuat");
              }
            }
          }, [_vm._v("Sáng")]), _c("el-checkbox", {
            attrs: {
              value: row.session == "trưa",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "trưa", "sanXuat");
              }
            }
          }, [_vm._v("Trưa")]), _c("el-checkbox", {
            attrs: {
              value: row.session == "chiều",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "chiều", "sanXuat");
              }
            }
          }, [_vm._v("Chiều")]), _c("el-checkbox", {
            attrs: {
              value: row.session == "tối",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "tối", "sanXuat");
              }
            }
          }, [_vm._v("Tối")])], 1) : _vm._e()], 1), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(item.quantity)) + " thùng")])]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đặt bên ngoài gia công",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(" Đặt bên ngoài gia công ")]), _c("el-button", {
          attrs: {
            icon: "el-icon-document-copy",
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onCopy("muaNgoai");
            }
          }
        }, [_vm._v("Copy")])];
      }
    }, {
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return _vm._l(_vm.getProducts("muaNgoai", row), function (item) {
          return _c("div", {
            staticStyle: {
              display: "flex"
            },
            style: {
              color: item.isEnough ? "#0b830b" : "red"
            }
          }, [_c("div", {
            staticStyle: {
              flex: "1",
              "border-bottom": "1px dotted #000"
            }
          }, [_c("div", [_c("label", {
            attrs: {
              stfor: ""
            }
          }, [_vm._v(_vm._s(item.name) + ": ")]), item.note ? _c("span", {
            staticStyle: {
              color: "#000"
            }
          }, [_vm._v(" (" + _vm._s(item.note) + ") ")]) : _vm._e()]), _vm.visibleSelectSession ? _c("div", [_c("el-checkbox", {
            attrs: {
              value: row.session == "sáng",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "sáng", "muaNgoai");
              }
            }
          }, [_vm._v("Sáng")]), _c("el-checkbox", {
            attrs: {
              value: row.session == "trưa",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "trưa", "muaNgoai");
              }
            }
          }, [_vm._v("Trưa")]), _c("el-checkbox", {
            attrs: {
              value: row.session == "chiều",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "chiều", "muaNgoai");
              }
            }
          }, [_vm._v("Chiều")]), _c("el-checkbox", {
            attrs: {
              value: row.session == "tối",
              label: "",
              indeterminate: false
            },
            on: {
              change: function change($event) {
                return _vm.onClickSession(item, "tối", "muaNgoai");
              }
            }
          }, [_vm._v("Tối")])], 1) : _vm._e()]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(item.quantity)) + " thùng")])]);
        });
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;