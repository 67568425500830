"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "footer-fixed",
    attrs: {
      title: "Chọn từ LSX",
      visible: _vm.visible,
      width: "1200px",
      fullscreen: "",
      top: "30px",
      "close-on-click-modal": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "content-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm mã hàng, số lệnh"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "table",
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "max-height": "500"
    },
    on: {
      select: _vm.handleSelectOne,
      "select-all": _vm.handleSelectAll
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDate(row.startAt)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.syncId ? _c("el-link", {
          staticStyle: {
            color: "#1989fa"
          },
          attrs: {
            href: "https://actapp.misa.vn/app/popup/INProductionOrderDetail/".concat(row.syncId),
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.code) + " ")]) : _c("span", [_vm._v(" " + _vm._s(row.code) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Mã hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.productCode) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tên hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.productName) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Diễn giải"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.name) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn kho thực tế",
      align: "right",
      sortable: "",
      prop: "remain"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.misaProduct.remain, 0))) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn kho thực tế "), _c("el-tooltip", {
    attrs: {
      content: "Bao gồm TP, BTP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _c("el-table-column", {
    attrs: {
      label: "Đơn hàng tạm giữ",
      align: "right",
      sortable: "",
      prop: "pendingExport"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.misaProduct.pendingExport, 0))) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn có thể bán",
      align: "right",
      sortable: "",
      prop: "available"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.misaProduct.available, 0))) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn có thể bán "), _c("el-tooltip", {
    attrs: {
      content: "Bao gồm cả BTP, TP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _c("el-table-column", {
    attrs: {
      label: "Tồn có thể bán (TP)",
      align: "right",
      sortable: "",
      prop: "availableTP"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.misaProduct.availableTP, 0))) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn có thể bán (TP) "), _c("el-tooltip", {
    attrs: {
      content: "Gồm TP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2), _c("el-table-column", {
    attrs: {
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng giao",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          on: {
            input: function input($event) {
              return _vm.handleChangeQuantity(row);
            }
          },
          model: {
            value: row.needed,
            callback: function callback($$v) {
              _vm.$set(row, "needed", $$v);
            },
            expression: "row.needed"
          }
        })];
      }
    }])
  })], 1)], 1), _c("div", {
    staticClass: "pagination-container"
  }, [_c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("Đồng ý")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;