"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaActivityApi = void 0;

var _request = require("@/utils/request");

var misaActivityApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaActivity',
      params: params
    });
  },
  getSummaryByStaff: function getSummaryByStaff(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaActivity/summary/byStaff',
      params: params
    });
  },
  getKPI: function getKPI(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaActivity/kpi',
      params: params
    });
  },
  getSummaryStatus: function getSummaryStatus(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaActivity/summary/status',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaActivity',
      data: data,
      method: 'post'
    });
  },
  complete: function complete(id) {
    return (0, _request.request)({
      url: "/v1/admin/misaActivity/".concat(id, "/complete"),
      method: 'patch'
    });
  }
};
exports.misaActivityApi = misaActivityApi;