"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Cập nhật",
      visible: _vm.visible,
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "code"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Khuôn bế"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "",
      clearable: "",
      remote: "",
      filterable: "",
      "remote-method": _vm.fetchKhuonBe
    },
    model: {
      value: _vm.form.khuonBeId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "khuonBeId", $$v);
      },
      expression: "form.khuonBeId"
    }
  }, _vm._l(_vm.khuonBes, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Bản in"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "",
      clearable: "",
      remote: "",
      filterable: "",
      "remote-method": _vm.fetchBanIn
    },
    model: {
      value: _vm.form.banInId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "banInId", $$v);
      },
      expression: "form.banInId"
    }
  }, _vm._l(_vm.banIns, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Lưu")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;