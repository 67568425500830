"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      display: "none"
    }
  }, [_c("div", {
    attrs: {
      id: "workOrder-inventory-print"
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      "font-size": "70px",
      "font-weight": "bold"
    }
  }, [_vm._v(" " + _vm._s(_vm.sizeString) + " ")]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_vm.workOrderInventory ? _c("span", [_vm._v(" " + _vm._s(_vm.hiddenName(_vm.workOrderInventory.workOrder)) + " ")]) : _vm._e()])])]), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center",
      "padding-left": "10px"
    }
  }, [_vm.workOrderInventory ? _c("qrcode-vue", {
    attrs: {
      value: _vm.workOrderInventory.workOrder.code,
      size: _vm.qrSize,
      level: "H",
      "render-as": "svg"
    }
  }) : _vm._e(), _vm._m(0)], 1), _c("div", {
    staticStyle: {
      "text-align": "center",
      flex: "1"
    }
  }, [_vm.workOrderInventory && _vm.workOrderInventory.workOrder ? _c("span", {
    staticStyle: {
      "font-size": "100px",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.lsxCode))]) : _vm._e(), _vm._m(1)])]), _vm.workOrderInventory ? _c("table", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_vm._m(2), _c("tr", [_c("td", {
    staticStyle: {
      width: "60%"
    }
  }, [_vm._v(_vm._s(_vm.workOrderInventory.materialName))]), _c("td", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.workOrderInventory.quantity)) + " ")]), _c("td", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.getTamXa(_vm.workOrderInventory))) + " ")])])]) : _vm._e(), _vm._m(3), _vm.workOrderInventory && _vm.workOrderInventory.workOrder ? _c("table", [_vm._m(4), _c("tr", [_c("td", [_vm._v(" " + _vm._s(_vm.workOrderInventory.workOrder.materialCartonWave) + " - " + _vm._s(_vm.workOrderInventory.providerName) + " ")]), _c("td", [_vm._v(" " + _vm._s(_vm.workOrderInventory.workOrder.materialCartonStruct) + " ")])]), _c("tr", [_c("td", {
    attrs: {
      colspan: "2"
    }
  }, [_c("div", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "20px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ghi chú: ")]), _vm.workOrderInventory.workOrder.printColors > 0 ? _c("span", [_c("span", [_vm._v(" In " + _vm._s(_vm.workOrderInventory.workOrder.printColors) + " màu ")])]) : _vm._e(), _vm.workOrderInventory.workOrder.waterProof != "Không" ? _c("span", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Chống thấm: ")]), _c("span", {
    staticStyle: {
      color: "red",
      "font-size": "20px",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.workOrderInventory.workOrder.waterProof))])]) : _vm._e(), _vm.workOrderInventory.workOrder.materialName.toLowerCase().includes("giấy trắng") || _vm.workOrderInventory.workOrder.materialName.toLowerCase().includes("giấy vàng") ? _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giấy: ")]), _c("span", {
    staticStyle: {
      color: "red",
      "font-size": "20px",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.workOrderInventory.workOrder.materialName.toLowerCase().includes("giấy trắng") ? "Giấy trắng" : "Giấy vàng"))])]) : _vm._e()])])])]) : _vm._e(), _vm.workOrderInventory ? _c("table", [_vm._m(5), _c("tr", [_c("td", [_vm._v(" " + _vm._s(_vm.$formatDateTime(_vm.workOrderInventory.dateCreated)) + " ")]), _c("td", [_vm._v(" " + _vm._s(_vm.workOrderInventory.licensePlate) + " ")])])]) : _vm._e(), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "margin-top": "24px"
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.workOrderInventory ? _c("qrcode-vue", {
    attrs: {
      value: _vm.detailLink,
      size: _vm.qrSize,
      level: "H",
      "render-as": "svg"
    }
  }) : _vm._e(), _vm._m(6)], 1), _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.dateString))]), _c("div", {
    staticStyle: {
      "min-height": "100px"
    }
  }), _vm.workOrderInventory ? _c("label", [_vm._v(_vm._s(_vm.workOrderInventory.staffName))]) : _vm._e()])])])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("span", [_vm._v("Xem lệnh sản xuất")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("span", [_vm._v("Mã lệnh sản xuất")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("tr", [_c("td", {
    staticStyle: {
      width: "60%"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tên NVL ")])]), _c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" SL tấm lớn ")])]), _c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" SL tấm xả ")])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      display: "block",
      "margin-top": "12px",
      "margin-bottom": "4px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thông tin giấy NVL")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("tr", [_c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Sóng/NCC")])]), _c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Kết cấu giấy")])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("tr", [_c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Thời gian nhập kho ")])]), _c("td", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Biển số xe")])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("span", [_vm._v("Xem chi tiết nhập kho")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;