"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "C\u01A1 h\u1ED9i #".concat(_vm.misaOpportunity ? _vm.misaOpportunity.name : "") : "Thêm mới",
      visible: _vm.visible,
      fullscreen: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 14,
      offset: 0
    }
  }, [_c("section", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingStatus,
      expression: "loadingStatus"
    }],
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticClass: "panel-shadow"
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v("Thông tin chung")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khách hàng"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.form.misaCustomer ? _vm.form.misaCustomer.name : "",
      readonly: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên cơ hội"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giai đoạn"
    }
  }, [_c("el-select", {
    attrs: {
      value: _vm.form.stageId,
      filterable: ""
    },
    on: {
      change: _vm.handleChangeStatus
    }
  }, _vm._l(_vm.MisaOpportunityStatusValue, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.text,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Người thực hiện"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.form.ownerMisaEmployee ? _vm.form.ownerMisaEmployee.name : "",
      readonly: ""
    }
  })], 1)], 1)], 1)], 1)]), _c("section", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticClass: "panel-shadow"
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v("Ghi chú")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "mini"
    },
    on: {
      click: _vm.handleCreateNote
    }
  }, [_vm._v("Tạo ghi chú")]), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, _vm._l(_vm.misaNotes, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "12px"
      }
    }, [_c("MisaNoteItem", {
      attrs: {
        misaNote: item
      }
    })], 1);
  }), 0)], 1)])]), _c("el-col", {
    attrs: {
      span: 10,
      offset: 0
    }
  }, [_c("section", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticClass: "panel-shadow"
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v("Nhiệm vụ")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "mini"
    },
    on: {
      click: _vm.handleCreateActivity
    }
  }, [_vm._v("Tạo nhiệm vụ")]), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, _vm._l(_vm.misaActivities, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "12px"
      }
    }, [_c("MisaActivityItem", {
      attrs: {
        misaActivity: item
      },
      on: {
        complete: _vm.handleCompleteActivity
      }
    })], 1);
  }), 0)], 1)])])], 1)], 1), _c("CreateMisaActivityOpportunity", {
    ref: "CreateMisaActivityOpportunity",
    on: {
      submitOk: _vm.fetchMisaActivities
    }
  }), _c("CreateMisaNote", {
    ref: "CreateMisaNote",
    on: {
      submitOk: _vm.fetchMisaNotes
    }
  }), _c("CompleteMisaActivityModal", {
    ref: "CompleteMisaActivityModal",
    on: {
      submitOk: _vm.handleCompleteOk
    }
  }), _c("SelectReasonModal", {
    ref: "SelectReasonModal",
    on: {
      submitOk: _vm.handleChangeStatusOk
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;