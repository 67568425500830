"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCartonType = exports.getBestFactorKhoTamV3 = exports.getBestFactorKhoTamV2 = exports.getBestFactorKhoTam = exports.checkOverSizeV2 = exports.checkOverSize = exports.calcCubicMeter = exports.calc = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.error.cause.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.number.to-fixed.js");

var _mold = require("@/types/mold");

var _utils = require("@/utils");

var _elementUi = require("element-ui");

var _cartonMachine = require("@/types/cartonMachine");

/**
 * - lấy loại thùng bế hay chạp
 * - Nếu dài >=10, Rộng + Cao >=25, Rộng>=6, Cao >=8 => Kiểu làm là chạp
 * - Còn lại là bế
 */
var getCartonType = function getCartonType(_ref) {
  var length = _ref.length,
      width = _ref.width,
      height = _ref.height,
      khoTam1Thung = _ref.khoTam1Thung,
      daiTam1Thung = _ref.daiTam1Thung,
      printColor = _ref.printColor,
      boxType = _ref.boxType;
  var version = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'v1';
  var cartonMachines = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  if (['v1', 'v3'].includes(version)) {
    if ([_mold.MoldBoxType.A1NapChom1, _mold.MoldBoxType.A1NapChom2].includes(boxType)) {
      return {
        type: 'CHAP',
        canPrint: '',
        machine: null
      };
    } //chạp mini
    // Dài >=9 & Rộng >= 9 & Cao >= 3 & Khổ tấm >= 25 & khổ tấm <= 60 & dài tấm >= 50 & dài tấm <= 160


    var condition1 = length >= 9 && width >= 9 && height >= 3 && khoTam1Thung >= 25 && khoTam1Thung <= 60 && daiTam1Thung >= 50 && daiTam1Thung <= 160; // Máy in
    // Dài >=24.5 & Rộng >= 6 & Cao >= 10 & Khổ tấm >= 30 & khổ tấm <= 120 & dài tấm >= 50 & dài tấm <= 52 

    var condition2 = length >= 24.5 && width >= 6 && height >= 10 && khoTam1Thung >= 30 && khoTam1Thung <= 120 && daiTam1Thung >= 50 && daiTam1Thung <= 520;

    if (condition1 || condition2) {
      return {
        type: 'CHAP',
        canPrint: '',
        machine: null
      };
    }

    return {
      type: 'BE',
      canPrint: '',
      machine: null
    };
  } else if (version == 'v2') {
    var checkIsValid = function checkIsValid(_ref2) {
      var minLength = _ref2.minLength,
          minWidth = _ref2.minWidth,
          minHeight = _ref2.minHeight,
          minKhoTam = _ref2.minKhoTam,
          minDaiTam = _ref2.minDaiTam,
          maxKhoTam = _ref2.maxKhoTam,
          maxDaiTam = _ref2.maxDaiTam,
          isSwap = _ref2.isSwap;
      var sizeThung = length >= minLength && width >= minWidth && height >= minHeight;
      var tam = khoTam1Thung >= minKhoTam && daiTam1Thung >= minDaiTam && khoTam1Thung <= maxKhoTam && daiTam1Thung <= maxDaiTam;
      var swapTam = khoTam1Thung >= minDaiTam && daiTam1Thung >= minKhoTam && khoTam1Thung <= maxDaiTam && daiTam1Thung <= maxKhoTam;

      if (isSwap) {
        return {
          isValid: sizeThung && (tam || swapTam),
          sizeThung: sizeThung,
          tam: tam,
          swapTam: swapTam
        };
      }

      return {
        sizeThung: sizeThung,
        tam: tam,
        isValid: sizeThung && tam
      };
    };

    var type = null;
    var selectedMachine = null;

    if (printColor && !cartonMachines.some(function (e) {
      return e.isEnableIn;
    })) {
      throw new Error("Không thể chạy thùng này: Không có máy để in");
    }

    var canPrint = ''; //khi chú có thể in

    var _iterator = (0, _createForOfIteratorHelper2.default)(cartonMachines),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var machine = _step.value;
        var isSwap = machine.isSwap,
            isEnableBe = machine.isEnableBe,
            isEnableChap = machine.isEnableChap,
            isEnableIn = machine.isEnableIn; //check thùng có in

        if (printColor) {
          if (isEnableIn) {
            var _checkIsValid = checkIsValid({
              minLength: machine.minLengthIn,
              minWidth: machine.minWidthIn,
              minHeight: machine.minHeightIn,
              minKhoTam: machine.minKhoTamIn,
              minDaiTam: machine.minDaiTamIn,
              maxKhoTam: machine.maxKhoTamIn,
              maxDaiTam: machine.maxDaiTamIn,
              isSwap: false
            }),
                isValid = _checkIsValid.isValid,
                sizeThung = _checkIsValid.sizeThung;

            if (isValid) {
              type = 'CHAP';
              selectedMachine = (0, _objectSpread2.default)({}, machine);
              selectedMachine.type = _cartonMachine.CartonMachineType.In;
            } else {
              if (sizeThung && khoTam1Thung < machine.minKhoTamIn && daiTam1Thung < machine.minDaiTamIn) {
                canPrint = 'Có thể in, nhưng bị thiếu khổ, dài tấm nhỏ';
                type = 'CHAP';
                selectedMachine = (0, _objectSpread2.default)({}, machine);
                selectedMachine.type = _cartonMachine.CartonMachineType.In;
              }
            }
          } else {
            continue;
          }
        }

        if (isEnableChap) {
          var _isValid = checkIsValid({
            minLength: machine.minLengthChap,
            minWidth: machine.minWidthChap,
            minHeight: machine.minHeightChap,
            minKhoTam: machine.minKhoTamChap,
            minDaiTam: machine.minDaiTamChap,
            maxKhoTam: machine.maxKhoTamChap,
            maxDaiTam: machine.maxDaiTamChap,
            isSwap: false
          });

          if (_isValid) {
            type = 'CHAP';
            selectedMachine = (0, _objectSpread2.default)({}, machine);
            selectedMachine.type = _cartonMachine.CartonMachineType.Chap;
          }
        }

        if (isEnableBe) {
          var _isValid2 = checkIsValid({
            minLength: machine.minLengthBe,
            minWidth: machine.minWidthBe,
            minHeight: machine.minHeightBe,
            minKhoTam: machine.minKhoTamBe,
            minDaiTam: machine.minDaiTamBe,
            maxKhoTam: machine.maxKhoTamBe,
            maxDaiTam: machine.maxDaiTamBe,
            isSwap: isSwap
          });

          if (_isValid2) {
            type = 'BE';
            selectedMachine = (0, _objectSpread2.default)({}, machine);
            selectedMachine.type = _cartonMachine.CartonMachineType.Be;
          }
        }

        if (type) {
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    if (!type && canPrint) {
      type = 'CHAP';
    }

    return {
      type: type,
      canPrint: canPrint,
      machine: selectedMachine
    };
  }
};

exports.getCartonType = getCartonType;

var checkOverSizeV2 = function checkOverSizeV2(_ref3) {
  var _ref3$type = _ref3.type,
      type = _ref3$type === void 0 ? 'CHAP' : _ref3$type,
      daiTamNho = _ref3.daiTamNho,
      khoTamNho = _ref3.khoTamNho,
      isPrint = _ref3.isPrint;
  var isOver = false,
      message = '';

  if (type == 'CHAP') {
    var arr = [{
      minKho: 32,
      maxKho: 120,
      maxDaiTam: 520
    }, {
      minKho: 25,
      maxKho: 60,
      maxDaiTam: 120
    }];
    var paths = [true, true];

    for (var i = 0; i < arr.length; i++) {
      var item = arr[i];
      var minKho = item.minKho,
          maxDaiTam = item.maxDaiTam,
          maxKho = item.maxKho;

      if (khoTamNho >= minKho && khoTamNho <= maxKho) {
        if (daiTamNho > maxDaiTam) {
          paths[i] = false;
        }
      }
    }

    if (paths.every(function (e) {
      return e == false;
    })) {
      isOver = true;
      message = "Đã quá khổ, không thể chạy";
    }
  } else {
    if (khoTamNho >= 100 && daiTamNho >= 100 || khoTamNho >= 140 || daiTamNho >= 140) {
      isOver = true;
      message = 'Quá khổ (Bế)';
    }
  }

  return {
    isOver: isOver,
    message: message
  };
};
/**
 * - Dài tấm <= 230
 * - Khổ tấm <= 80
 * - Dài tấm > 115 thì giá +5%
 * @returns true: +%5
 */


exports.checkOverSizeV2 = checkOverSizeV2;

var checkOverSize = function checkOverSize(_ref4) {
  var length = _ref4.length,
      width = _ref4.width,
      height = _ref4.height,
      _ref4$type = _ref4.type,
      type = _ref4$type === void 0 ? 'CHAP' : _ref4$type,
      daiTamNho = _ref4.daiTamNho,
      khoTamNho = _ref4.khoTamNho;
  var maxLength = 520;
  return khoTamNho >= 120 || daiTamNho > maxLength; // if (type == 'CHAP') {
  //     const maxLength = 515;
  //     const khoTam = width + height;
  //     const daiTam = (length + width) * 2;
  //     if (khoTam >= 140) {
  //         isOver = true;
  //     }
  //     if (daiTam >= maxLength) {
  //         throw new Error("Thùng này quá chiều dài tấm ngay cả khi ghép giấy (Tối đa nhỏ hơn 320cm)");
  //     }
  //     if (khoTam >= 100 && khoTam < 140) {
  //         isOver = true
  //     }
  //     if (daiTam >= 260 && daiTam < maxLength) {
  //         isOver = true
  //     }
  // } else {
  //     if ((khoTamNho >= 100 && daiTamNho >= 100) || (khoTamNho >= 140 || daiTamNho >= 140)) {
  //         isOver = true;
  //     }
  // }
  // return isOver;
};

exports.checkOverSize = checkOverSize;

var getBestFactorKhoTamV2 = function getBestFactorKhoTamV2(_ref5) {
  var khoTam = _ref5.khoTam,
      khoTamCongThemNCC = _ref5.khoTamCongThemNCC;
  return getBestFactorKhoTamV3({
    khoTam: khoTam,
    khoTamCongThemNCC: khoTamCongThemNCC
  });
  var minKhotam = (0, _utils.ceiling)(khoTam + khoTamCongThemNCC, 5);
  if (minKhotam < 115) minKhotam = 115;
  var minPheLieu = minKhotam - khoTam;
  var factor = 1;

  for (var i = 2; i <= 5; i++) {
    var khotamTemp = (0, _utils.ceiling)(khoTam * i + khoTamCongThemNCC, 5);

    if (khotamTemp == 195 || khotamTemp == 205 || khotamTemp == 215) {
      khotamTemp += 5;
    }

    if (khotamTemp > 220) {
      break;
    }

    if (khotamTemp < 115) khotamTemp = 115;
    var pheLieu = khotamTemp - khoTam * i - khoTamCongThemNCC;

    if (pheLieu < minPheLieu) {
      minPheLieu = pheLieu;
      minKhotam = khotamTemp;
      factor = i;
    }
  }

  return {
    minKhotam: minKhotam,
    factor: factor
  };
};

exports.getBestFactorKhoTamV2 = getBestFactorKhoTamV2;

var getBestFactorKhoTamV3 = function getBestFactorKhoTamV3(_ref6) {
  var khoTam = _ref6.khoTam,
      khoTamCongThemNCC = _ref6.khoTamCongThemNCC;
  debugger;
  var minKhotam = (0, _utils.ceiling)(khoTam + khoTamCongThemNCC, 5);
  if (minKhotam < 130) minKhotam = 130;
  var minPheLieu = minKhotam - khoTam;
  var factor = 1;

  for (var i = 2; i <= 5; i++) {
    var khotamTemp = khoTam * i + khoTamCongThemNCC;
    khotamTemp = (0, _utils.ceiling)(khotamTemp, khotamTemp > 190 ? 10 : 5);

    if (khotamTemp > 250) {
      break;
    }

    if (khotamTemp < minKhotam) {
      khotamTemp = minKhotam;
    }

    var pheLieu = khotamTemp - khoTam * i - khoTamCongThemNCC;

    if (pheLieu < minPheLieu) {
      minPheLieu = pheLieu;
      minKhotam = khotamTemp;
      factor = i;
    }
  }

  return {
    minKhotam: minKhotam,
    factor: factor
  };
};

exports.getBestFactorKhoTamV3 = getBestFactorKhoTamV3;

var getBestFactorKhoTam = function getBestFactorKhoTam(_ref7) {
  var currentFactor = _ref7.currentFactor,
      khoTam = _ref7.khoTam,
      khoTamCongThemNCC = _ref7.khoTamCongThemNCC,
      originKhoTamNcc = _ref7.originKhoTamNcc;
  var availableValues = [];
  var minCut = originKhoTamNcc - (khoTam * currentFactor + khoTamCongThemNCC);
  var newFactor = currentFactor,
      newKhoTamNcc = originKhoTamNcc;

  for (var i = currentFactor; i <= 5; i++) {
    var beforeKhoTamNCC = khoTam * i + khoTamCongThemNCC;
    var khoTamNCC = (0, _utils.ceiling)(beforeKhoTamNCC, 5);
    var newMinCut = khoTamNCC - beforeKhoTamNCC;

    if (minCut > newMinCut && khoTamNCC <= 220) {
      minCut = newMinCut;
      newFactor = i;
      newKhoTamNcc = khoTamNCC;
      availableValues.push({
        minCut: minCut,
        khoTamNCC: khoTamNCC,
        factor: i
      });
    }
  }

  console.log('best available', availableValues);
  return {
    newFactor: newFactor,
    newKhoTamNcc: newKhoTamNcc,
    availableValues: availableValues
  };
};
/**
 * Xác định khổ giấy, và tính tiền
 */


exports.getBestFactorKhoTam = getBestFactorKhoTam;

var calc = function calc(_ref8) {
  var length = _ref8.length,
      width = _ref8.width,
      height = _ref8.height,
      cartonWave = _ref8.cartonWave,
      cartonStruct = _ref8.cartonStruct,
      unitGiaCong = _ref8.unitGiaCong,
      unitPriceKeo = _ref8.unitPriceKeo,
      unitPriceDay = _ref8.unitPriceDay,
      unitPriceGhim = _ref8.unitPriceGhim,
      unitPriceIn = _ref8.unitPriceIn,
      type = _ref8.type,
      beMatrix = _ref8.beMatrix;
  var taiThung = 0; //tai thùng

  var daiTam = 0,
      khoTam = 0; //dài tấm, khổ tấm

  var khoTamNCC = 0,
      daiTamNCC = 0,
      minKhoTamNcc = 115,
      minDaiTamNCC = 80;
  var realKhoTam = 0,
      realDaiTam = 0; //khổ tấm, dài tấm thực tế

  var factorKhoTam = 1,
      factorDaiTam = 1; //tỉ trọng khổ, dài

  var realQuantity = 0; //sl thùng thực tế khi cắt từ giấy ncc

  var unitPriceGiay = cartonStruct.price;
  var totalPriceGiay = 0;
  var totalGiaCong = 0;
  var totalPriceKeo = 0,
      totalPriceDay = 0,
      totalPriceGhim = 0,
      totalPriceIn = 0;
  var uocTinhKhoTam = 0,
      uocTinhDaiTam = 0;
  var daiKhuonBe = 1,
      rongKhuonBe = 1;

  if (cartonWave.code == 'B' && cartonWave.layer == 3) {
    taiThung = 2;
  } else if (cartonWave.code == 'C' && cartonWave.layer == 3) {
    taiThung = 2.5;
  } else if (cartonWave.layer == 5) {
    taiThung = 3;
  } else if (cartonWave.layer == 7) {
    taiThung = 3.5;
  }

  console.log('tai thung', taiThung);
  daiTam = (length + width) * 2 + taiThung + 1;
  console.log('daiTam before be:', daiTam);
  khoTam = width + height;

  if (cartonWave.layer == 3) {
    khoTam += .2;
  } else if (cartonWave.layer == 5) {
    khoTam += .4;
  } else if (cartonWave.layer == 7) {
    khoTam += .6;
  }

  if (type == 'BE' && beMatrix) {
    rongKhuonBe = +beMatrix.split('x')[0];
    daiKhuonBe = +beMatrix.split('x')[1];
    daiTam = +(daiTam * daiKhuonBe + 2).toFixed(1);
    khoTam = +(khoTam * rongKhuonBe + 2).toFixed(1);
  }

  khoTam = +khoTam.toFixed(1);
  console.log('khoTam 1', khoTam);
  console.log('daiTam after be:', daiTam);
  var khoTamCongThemNCC = 2; //Nếu khổ tấm < 115, thì ghép giấy để đủ khổ >= 115

  var isGetBestFactor = false;

  if (khoTam < minKhoTamNcc) {
    factorKhoTam = Math.ceil(minKhoTamNcc / khoTam);

    if (khoTam * factorKhoTam + khoTamCongThemNCC <= 220) {
      khoTamNCC = khoTam * factorKhoTam + khoTamCongThemNCC;
      isGetBestFactor = true;
    } else {
      factorKhoTam = 1;
      khoTamNCC = minKhoTamNcc;
    }
  } else {
    khoTamNCC = khoTam + khoTamCongThemNCC;
  }

  khoTamNCC = (0, _utils.ceiling)(khoTamNCC, 5);
  console.log('isGetBestFactor:', isGetBestFactor);

  if (khoTam < minKhoTamNcc && isGetBestFactor) {
    var _getBestFactorKhoTam = getBestFactorKhoTam({
      currentFactor: factorKhoTam,
      khoTam: khoTam,
      khoTamCongThemNCC: khoTamCongThemNCC,
      originKhoTamNcc: khoTamNCC
    }),
        newFactor = _getBestFactorKhoTam.newFactor,
        newKhoTamNcc = _getBestFactorKhoTam.newKhoTamNcc;

    if (newFactor != factorKhoTam) {
      factorKhoTam = newFactor;
      khoTamNCC = newKhoTamNcc;
    }
  }

  if (khoTamNCC > 220) {
    _elementUi.Message.error("Khổ tấm NCC đã vượt quá 220cm");
  } //check ngoại lệ


  if (khoTamNCC == 195) {
    khoTamNCC = 200;
  } else if (khoTamNCC == 205) {
    khoTamNCC = 210;
  } else if (khoTamNCC == 215) {
    khoTamNCC = 220;
  } // Nếu dài tấm < 80, thì ghép giấy để đủ dài >= 80


  if (daiTam < minDaiTamNCC) {
    factorDaiTam = Math.ceil(minDaiTamNCC / daiTam);
    daiTamNCC = +(daiTam * factorDaiTam).toFixed(1);
  } else {
    daiTamNCC = daiTam;
  } //tính khổ, dài thực tế
  // realKhoTam = +(khoTamNCC / factorKhoTam)
  // realDaiTam = +(daiTamNCC / factorDaiTam)


  console.log('factorKhoTam:', factorKhoTam);
  realKhoTam = +(khoTamNCC / factorKhoTam).toFixed(1);
  realDaiTam = +(daiTamNCC / factorDaiTam).toFixed(1); //sl thùng thực tế

  realQuantity = Math.floor(khoTamNCC * daiTamNCC / (realKhoTam * realDaiTam)); //tính tổng phí gia công

  totalGiaCong = Math.ceil(realKhoTam * realDaiTam / 10000 * unitGiaCong);
  uocTinhKhoTam = +(khoTamNCC / (khoTamNCC / khoTam)).toFixed(1);
  uocTinhDaiTam = +(daiTamNCC / (daiTamNCC / daiTam)).toFixed(1); //

  totalPriceKeo = height * unitPriceKeo;
  totalPriceDay = width * unitPriceDay;
  totalPriceGhim = height * unitPriceGhim;
  totalPriceIn = realDaiTam * realKhoTam / 10000 * unitPriceIn;
  totalPriceGiay = Math.ceil(realDaiTam * realKhoTam / 10000 * unitPriceGiay);
  var minQuantity = Math.ceil(300 * 100 * realQuantity / daiTamNCC);
  var cubicMeter = calcCubicMeter({
    daiTam: realDaiTam,
    khoTam: realKhoTam,
    cartonWaveCode: cartonWave.code
  });
  return {
    totalGiaCong: totalGiaCong,
    taiThung: taiThung,
    daiTam: daiTam,
    khoTam: khoTam,
    realKhoTam: realKhoTam,
    realDaiTam: realDaiTam,
    realQuantity: realQuantity,
    totalPriceKeo: totalPriceKeo,
    totalPriceDay: totalPriceDay,
    totalPriceGhim: totalPriceGhim,
    totalPriceIn: totalPriceIn,
    totalPriceGiay: totalPriceGiay,
    daiTamNCC: daiTamNCC,
    khoTamNCC: khoTamNCC,
    uocTinhDaiTam: uocTinhDaiTam,
    uocTinhKhoTam: uocTinhKhoTam,
    daiKhuonBe: daiKhuonBe,
    rongKhuonBe: rongKhuonBe,
    minQuantity: minQuantity,
    cubicMeter: cubicMeter
  };
};
/**
 * - Tính thể tích: Sóng B 0.3, C 0.4,  E 0.2, BC 0.65, BCE 0.8
 */


exports.calc = calc;

var calcCubicMeter = function calcCubicMeter(_ref9) {
  var daiTam = _ref9.daiTam,
      khoTam = _ref9.khoTam,
      cartonWaveCode = _ref9.cartonWaveCode;
  var faction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  var cubicMeter = 0;

  if (cartonWaveCode == 'B') {
    cubicMeter = daiTam * khoTam * 0.3;
  } else if (cartonWaveCode == 'C') {
    cubicMeter = daiTam * khoTam * 0.4;
  } else if (cartonWaveCode == 'E') {
    cubicMeter = daiTam * khoTam * 0.2;
  } else if (cartonWaveCode == 'A') {
    cubicMeter = daiTam * khoTam * 0.5;
  } else if (cartonWaveCode == 'BC') {
    cubicMeter = daiTam * khoTam * 0.65;
  } else if (cartonWaveCode == 'BCE') {
    cubicMeter = daiTam * khoTam * 0.8;
  } else if (cartonWaveCode == 'BA') {
    cubicMeter = daiTam * khoTam * 0.8;
  }

  cubicMeter = +(cubicMeter / 1000000).toFixed(faction);
  return cubicMeter;
};

exports.calcCubicMeter = calcCubicMeter;