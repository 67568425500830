"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("ThungGaForm", {
    attrs: {
      form: _vm.form,
      onChangeForm: _vm.handleChangeForm,
      errorMessage: _vm.errorMessage,
      cartonStructs: _vm.cartonStructs,
      cartonWaves: _vm.cartonWaves,
      lastCartonVersion: _vm.lastCartonVersion
    }
  }), _c("el-form", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      model: _vm.form
    }
  }, [_c("section", {
    staticClass: "box"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Ước tính")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length2", $$v);
      },
      expression: "form.length2"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width2", $$v);
      },
      expression: "form.width2"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhà cung cấp")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length3,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length3", $$v);
      },
      expression: "form.length3"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width3,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width3", $$v);
      },
      expression: "form.width3"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Ước tính 1 thùng")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length4,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length4", $$v);
      },
      expression: "form.length4"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm",
      size: "normal"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width4,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width4", $$v);
      },
      expression: "form.width4"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Thực tế (" + _vm._s(_vm.form.quantityEstimate) + " thùng)")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length5,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length5", $$v);
      },
      expression: "form.length5"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width5,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width5", $$v);
      },
      expression: "form.width5"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c("section", {
    staticClass: "box"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Cấu thành giá")]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số m2: ")]), _c("span", [_vm._v(_vm._s(_vm.form.acr) + " m2")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số m3: ")]), _c("span", [_vm._v(_vm._s(_vm.form.cubicMeter) + " m3")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số m3 theo số lượng: ")]), _c("span", [_vm._v(_vm._s(Math.ceil(_vm.form.cubicMeter * _vm.form.quantity)) + " m3")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lượng giấy hao hụt: ")]), _c("span", [_vm._v(_vm._s(_vm.form.loss) + " m2 (" + _vm._s(_vm.$formatNumber(+(_vm.form.loss * _vm.cartonPrice).toFixed(1))) + " đ)")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá giấy nguyên liệu: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.price, ",")) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí gia công: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.giaCong)) + " đ ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phụ gia: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.phuGia)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí in: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.moneyPrint)) + " đ (" + _vm._s(_vm.form.numColor) + " màu) " + _vm._s(_vm.form.numColor ? "".concat(_vm.form.numOfPrint, " m\u1EB7t") : ""))])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phụ phí bế: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.moneyPhuPhiBe)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng cộng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.moneyFinal)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá tại kho: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.sellPrice)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí vận chuyển: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.shipFee)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá giao tận nơi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.deliveryPrice)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lợi nhuận: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.sellPrice - _vm.form.moneyFinal)) + " đ")])])])], 1), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, _vm._l(_vm.distances, function (item, index) {
    return _c("el-button", {
      key: index,
      staticClass: "font-bold",
      attrs: {
        type: "primary",
        size: "mini",
        icon: _vm.form.distance == item.value ? "el-icon-check" : ""
      },
      on: {
        click: function click($event) {
          return _vm.handleChangeDistance(item.value);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1)])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;