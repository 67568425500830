"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "quote-history"
  }, [_c("div", {
    staticStyle: {
      height: "0",
      overflow: "hidden"
    }
  }, [_c("quote-print", {
    ref: "QuotePrint"
  })], 1), _c("div", {
    staticClass: "head"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm tên, sđt"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchQuotes.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhân viên")]), _c("el-select", {
    attrs: {
      placeholder: "Chọn",
      clearable: "",
      filterable: ""
    },
    on: {
      change: _vm.handleQuery
    },
    model: {
      value: _vm.query.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "staffId", $$v);
      },
      expression: "query.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-button", {
    staticClass: "font-bold filter-item",
    staticStyle: {
      margin: "0"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" Thêm mới ")])], 1), _c("div", [_c("h3", {
    staticStyle: {
      "font-size": "25px",
      color: "#146b15"
    }
  }, [_vm._v(" Tổng tiền: " + _vm._s(_vm.$formatNumberVN(this.summary.totalPrice)) + "đ ")])])]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }]
  }, _vm._l(_vm.quotes, function (item) {
    return _c("quote-item", {
      key: item.id,
      attrs: {
        quote: item
      },
      on: {
        print: function print(quote) {
          return _vm.handlePrint(quote, false);
        },
        "print-vat": function printVat(quote) {
          return _vm.handlePrint(quote, true);
        },
        "print-ncc": function printNcc(quote) {
          return _vm.handlePrint(quote, false, true);
        },
        click: _vm.handleUpdate,
        "click-create-schedule": _vm.openCreateSchedule,
        delete: _vm.handleDelete
      }
    });
  }), 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchQuotes
    }
  }), _c("quote-modal", {
    ref: "QuoteModal",
    attrs: {
      version: _vm.version
    },
    on: {
      "submit:ok": _vm.handleSubmitOk,
      close: _vm.handleCloseQuoteModal
    }
  }), _c("schedule-production-modal", {
    ref: "ScheduleProductionModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;