"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Sắp xếp lệnh làm việc",
      visible: _vm.visible,
      width: "1000px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", [_c("ElTableDraggable", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFetch,
      expression: "loadingFetch"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "40",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var $index = _ref.$index;
        return [_c("i", {
          staticClass: "el-icon-rank"
        }), _c("span", [_vm._v(" " + _vm._s($index + 1) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày tạo",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.workOrder ? _c("label", {
          class: [_vm.dateClass(row.workOrder.dateCreated)],
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(_vm.$formatDate(row.workOrder.dateCreated)))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Diễn giải",
      width: "400",
      prop: "workOrder.name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.workOrder ? _c("div", [row.workOrder.dinhHinh != "Không" && row.workOrder.dinhHinh != "Dán" ? _c("div", [_c("span", {
          staticStyle: {
            color: "red",
            "font-size": "20px",
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(row.workOrder.dinhHinh) + " ")])]) : _vm._e(), _c("span", [_vm._v(_vm._s(row.workOrder.name))]), row.workOrder.waterProof != "Không" ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Chống thấm: ")]), _c("span", {
          staticStyle: {
            color: "red",
            "font-size": "20px",
            "font-weight": "bold"
          }
        }, [_vm._v(_vm._s(row.workOrder.waterProof))])]) : _vm._e()]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tình trạng NVL",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.workOrder && row.workOrder.misaTags.length ? [_c("div", _vm._l(row.workOrder.misaTags, function (item, index) {
          return _c("MisaTagComp", {
            key: index,
            attrs: {
              misaTag: item,
              clickable: _vm.visibleAction
            },
            on: {
              click: function click($event) {
                _vm.visibleAction && _vm.onUpdateTag(row);
              }
            }
          });
        }), 1)] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL NVL",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.workOrder ? [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Yêu cầu: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.workOrder.materialQuantity)))])]), row.material ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Có sẵn: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.material.quantity)))])]) : _vm._e()] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn BTP",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [row.btp ? [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.btp.quantity)) + " ")] : [_vm._v("--")]];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "70",
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(_vm._s(_vm.$formatNumberVN(row.workOrder.quantity)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú misa"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [row.workOrder ? _c("div", [row.workOrder.productNote != "1" && row.workOrder.productNote != "0" ? _c("div", {
          staticStyle: {
            background: "yellow",
            color: "red",
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(row.workOrder.productNote) + " ")]) : _vm._e(), row.workOrder.productNote != "1" && row.workOrder.productNote != "0" ? _c("span", {
          staticStyle: {
            background: "yellow",
            color: "red",
            "font-weight": "bold"
          },
          style: {
            borderTop: row.workOrder.productNote ? "1px dashed #000" : ""
          }
        }, [_vm._v(_vm._s(row.workOrder.note))]) : _vm._e()]) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.saveData
    }
  }, [_vm._v("Cập nhật")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;