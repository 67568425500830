"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductionMachineStatusTrans = exports.ProductionMachineStatus = exports.MachineCalcTypeTrans = exports.MachineCalcType = exports.MachineCalcByTrans = exports.MachineCalcBy = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _ProductionMachineSta, _MachineCalcByTrans, _MachineCalcTypeTrans;

var ProductionMachineStatus;
exports.ProductionMachineStatus = ProductionMachineStatus;

(function (ProductionMachineStatus) {
  ProductionMachineStatus["Free"] = "FREE";
  ProductionMachineStatus["Busy"] = "BUSY";
  ProductionMachineStatus["Block"] = "BLOCK";
})(ProductionMachineStatus || (exports.ProductionMachineStatus = ProductionMachineStatus = {}));

var ProductionMachineStatusTrans = (_ProductionMachineSta = {}, (0, _defineProperty2.default)(_ProductionMachineSta, ProductionMachineStatus.Busy, {
  name: 'Đang làm việc',
  tagColor: '#f56c6c'
}), (0, _defineProperty2.default)(_ProductionMachineSta, ProductionMachineStatus.Free, {
  name: 'Đang rảnh',
  tagColor: '#67c23a'
}), (0, _defineProperty2.default)(_ProductionMachineSta, ProductionMachineStatus.Block, {
  name: 'Bị khóa',
  tagColor: '#909399'
}), _ProductionMachineSta);
exports.ProductionMachineStatusTrans = ProductionMachineStatusTrans;
var MachineCalcBy;
exports.MachineCalcBy = MachineCalcBy;

(function (MachineCalcBy) {
  MachineCalcBy["Quantity"] = "QUANTITY";
  MachineCalcBy["Width"] = "WIDTH";
  MachineCalcBy["Length"] = "LENGTH";
  MachineCalcBy["Meter"] = "METER"; //số mét tới
})(MachineCalcBy || (exports.MachineCalcBy = MachineCalcBy = {}));

var MachineCalcByTrans = (_MachineCalcByTrans = {}, (0, _defineProperty2.default)(_MachineCalcByTrans, MachineCalcBy.Meter, 'Số mét tới'), (0, _defineProperty2.default)(_MachineCalcByTrans, MachineCalcBy.Length, 'Chiều dài'), (0, _defineProperty2.default)(_MachineCalcByTrans, MachineCalcBy.Width, 'Chiều khổ'), (0, _defineProperty2.default)(_MachineCalcByTrans, MachineCalcBy.Quantity, 'Số lượng'), _MachineCalcByTrans);
exports.MachineCalcByTrans = MachineCalcByTrans;
var MachineCalcType;
exports.MachineCalcType = MachineCalcType;

(function (MachineCalcType) {
  MachineCalcType["Product"] = "PRODUCT";
  MachineCalcType["SmallPlate"] = "SMALL_PLATE";
})(MachineCalcType || (exports.MachineCalcType = MachineCalcType = {}));

var MachineCalcTypeTrans = (_MachineCalcTypeTrans = {}, (0, _defineProperty2.default)(_MachineCalcTypeTrans, MachineCalcType.Product, 'Sản phẩm'), (0, _defineProperty2.default)(_MachineCalcTypeTrans, MachineCalcType.SmallPlate, 'Tấm nhỏ'), _MachineCalcTypeTrans);
exports.MachineCalcTypeTrans = MachineCalcTypeTrans;