"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "truck-tour-item",
    class: {
      success: _vm.shipFee > 1200000,
      fail: _vm.shipFee < 1200000
    },
    on: {
      click: function click($event) {
        return _vm.onClick(_vm.truckTour);
      }
    }
  }, [_c("label", {
    staticClass: "lh-20",
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.truckTour.name))]), _c("div", {
    staticClass: "lh-20"
  }, [_c("span", [_vm._v("Tổng " + _vm._s(_vm.truckTour.orders.length) + " đơn")])]), _vm._l(_vm.products, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "lh-20"
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(item.name) + ": ")]), _c("span", [_vm._v(_vm._s(item.quantity) + " " + _vm._s(item.unit))])]);
  }), _c("div", {
    staticClass: "lh-20"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tiền ship: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.shipFee)) + " đ")])]), _c("div", {
    staticClass: "lh-20"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tiền chiết khấu: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.chieuKhau)) + " đ")])])], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;