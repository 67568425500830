"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("h2", [_vm._v("Mục tiêu chung tháng " + _vm._s(_vm.currentMonth))]), _c("h4", [_vm._v("Đang xem theo "), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.mode == "month" ? "tháng" : "ngày"))])])]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18,
      offset: 0
    }
  }, [_c("section", {
    staticClass: "section-total"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("Panel", {
    attrs: {
      title: "Mục tiêu doanh số tổng",
      amount: _vm.totalSummary.goal,
      color: "green"
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("Panel", {
    attrs: {
      title: "Đã đạt được",
      amount: _vm.totalSummary.reached,
      color: "yellow"
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("Panel", {
    attrs: {
      title: "Còn lại",
      amount: _vm.totalSummary.remain,
      color: "blue"
    }
  })], 1)], 1)], 1), _c("section", {
    staticClass: "section-carton",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("Panel", {
    attrs: {
      title: "Mục tiêu doanh số carton",
      amount: _vm.cartonSummary.goal,
      color: "green"
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("Panel", {
    attrs: {
      title: "Đã đạt được",
      amount: _vm.cartonSummary.reached,
      color: "yellow"
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("Panel", {
    attrs: {
      title: "Còn lại",
      amount: _vm.cartonSummary.remain,
      color: "blue"
    }
  })], 1)], 1)], 1), _vm.contentDefines.length ? _c("marquee-text", {
    staticClass: "marquee",
    attrs: {
      duration: 10,
      repeat: 2
    }
  }, _vm._l(_vm.contentDefines, function (item, index) {
    return _c("span", {
      key: index
    }, [_vm._v(" " + _vm._s(item.content) + " ")]);
  }), 0) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingTab,
      expression: "loadingTab"
    }],
    staticClass: "top-sale-panel"
  }, [_c("el-tabs", {
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Tổng",
      name: "total"
    }
  }, [_c("TopSaleTable", {
    attrs: {
      data: _vm.totalSale
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Carton",
      name: "carton"
    }
  }, [_c("TopSaleTable", {
    attrs: {
      data: _vm.cartonSale
    }
  })], 1)], 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "12px",
      "text-align": "center"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Còn lại " + _vm._s(_vm.remainDay) + " ngày")])])])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;