"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _echarts = _interopRequireDefault(require("echarts"));

var _resize = _interopRequireDefault(require("./mixins/resize"));

var _format = require("@/utils/format");

var _variables = _interopRequireDefault(require("@/styles/variables.scss"));

require("echarts/theme/macarons"); // echarts theme


var animationDuration = 6000;
var _default = {
  mixins: [_resize.default],
  props: {
    series: Array,
    legend: Array,
    format: {
      type: String,
      default: ""
    },
    xAxisValue: Array,
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var self = this;
      this.chart = _echarts.default.init(this.$el);
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: this.legend // string[]

        },
        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: this.xAxisValue,
          // string[]
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: "value"
        }],
        series: this.series //  [
        //   {
        //     name: this.pointerName,
        //     type: "bar",
        //     stack: "vistors",
        //     barWidth: "60%",
        //     itemStyle: {
        //       color: variable.primaryColor,
        //     },
        //     data: this.dataChart.map((item) => {
        //       return item[this.attrGet];
        //     }),
        //     animationDuration,
        //   },
        // ],

      });
    }
  }
};
exports.default = _default;