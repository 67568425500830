"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var _objectSpread2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "work-order-event",
    class: (0, _objectSpread2.default)({}, _vm.renderClass(_vm.workOrder)),
    on: {
      click: function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.onClickItem(_vm.workOrder);
      }
    }
  }, [_c("div", {
    on: {
      click: function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.onClickItem(_vm.workOrder);
      }
    }
  }, [_c("label", {
    staticClass: "work-order--name",
    staticStyle: {
      color: "white",
      "font-size": "12px",
      padding: "2px 4px",
      "border-radius": "4px"
    },
    on: {
      click: function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.onClickItem(_vm.workOrder);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _vm.visibleName ? _c("div", {
    staticStyle: {
      color: "#fff"
    },
    on: {
      click: function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.onClickItem(_vm.workOrder);
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "12px"
    },
    on: {
      click: function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.onClickItem(_vm.workOrder);
      }
    }
  }, [_vm._v(_vm._s(_vm.workOrder.name))])]) : _vm._e()]), _vm.workOrder.misaTags.length ? _c("div", _vm._l(_vm.workOrder.misaTags, function (item, index) {
    return _c("MisaTagBackgroundComp", {
      key: index,
      attrs: {
        misaTag: item,
        isThroughText: _vm.workOrder.status == _vm.WorkOrderStatus.Complete
      }
    });
  }), 1) : _vm._e(), _c("div", {
    staticClass: "absolute-container"
  }, [_vm.visibleCheckInventory ? _c("el-button", {
    staticStyle: {
      padding: "4px 8px"
    },
    attrs: {
      type: _vm.workOrder.isEnoughMaterial ? "success" : "primary",
      size: "mini",
      loading: _vm.workOrder.loadingCheckInventory
    },
    on: {
      click: function click($event) {
        _vm.workOrder.isEnoughMaterial ? false : _vm.onCheckInventory(_vm.workOrder);
      }
    }
  }, [_vm._v(_vm._s(_vm.workOrder.isEnoughMaterial ? "Đã đủ kho" : "Kiểm kho"))]) : _vm._e(), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Sắp xếp lệnh",
      placement: "top"
    }
  }, [_vm.visibleSort ? _c("IconButton", {
    staticStyle: {
      "margin-right": "4px"
    },
    attrs: {
      icon: "el-icon-refresh",
      color: "#047aff"
    },
    on: {
      click: function click() {
        return _vm.onSort(_vm.workOrder);
      }
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "work-order-event--mark-complete"
  }, [_c("el-checkbox", {
    attrs: {
      value: _vm.workOrder.status == _vm.WorkOrderStatus.Complete
    },
    on: {
      change: function change($event) {
        return _vm.onCheck(_vm.workOrder);
      }
    }
  })], 1)], 1), _vm._t("default")], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;