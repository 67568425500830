"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: "",
      logo: require("@/assets/images/".concat(process.env.VUE_APP_LOGO_APP))
    };
  }
};
exports.default = _default;