"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Danh s\xE1ch pallet #".concat(_vm.workOrderInventory ? _vm.workOrderInventory.code : ""),
      visible: _vm.visible,
      width: "1200px",
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("WorkOrderInventoryDetailTable", {
    attrs: {
      data: _vm.data,
      loading: _vm.loading,
      visibleAction: true
    },
    on: {
      delete: _vm.handleDelete,
      update: _vm.handleUpdate,
      print: _vm.handlePrint
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1), _c("InventoryDetailModal", {
    ref: "InventoryDetailModal",
    on: {
      submitOk: _vm.handleUpdateOk
    }
  }), _c("div", {
    staticClass: "pdf-wrapper"
  }, [_vm.selectedWorkOrderInventoryDetail && _vm.version == 2 ? _c("WorkOrderInventoryPrint2", {
    ref: "WorkOrderInventoryPrint2",
    attrs: {
      visible: _vm.visiblePrint,
      quantity: _vm.selectedWorkOrderInventoryDetail.quantity,
      no: _vm.selectedWorkOrderInventoryDetail.no,
      workOrderInventory: _vm.workOrderInventory
    }
  }) : _vm._e(), _vm.selectedWorkOrderInventoryDetail && _vm.version == 3 ? _c("WorkOrderInventoryPrint3", {
    ref: "WorkOrderInventoryPrint3",
    attrs: {
      visible: _vm.visiblePrint,
      quantity: _vm.selectedWorkOrderInventoryDetail.quantity,
      staffName: _vm.selectedWorkOrderInventoryDetail.staffName,
      productWeight: _vm.selectedWorkOrderInventoryDetail.totalPalletWeight - _vm.selectedWorkOrderInventoryDetail.palletWeight,
      unitWeight: _vm.selectedWorkOrderInventoryDetail.weight,
      no: _vm.selectedWorkOrderInventoryDetail.no,
      workOrderInventory: _vm.workOrderInventory
    }
  }) : _vm._e()], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;