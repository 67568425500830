"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.phucHaoApi = void 0;

var _request = require("@/utils/request");

var phucHaoApi = {
  validate: function validate(params) {
    return (0, _request.request)({
      url: '/v1/admin/phucHao/validate',
      params: params
    });
  }
};
exports.phucHaoApi = phucHaoApi;