"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      float: "right",
      "margin-bottom": "12px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.onAdd
    }
  }, [_vm._v("Thêm sản phẩm")]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      float: "right",
      "margin-bottom": "12px",
      "margin-right": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.onAddOther
    }
  }, [_vm._v("Thêm sản phẩm (Khác)")]), _c("el-table", {
    attrs: {
      data: _vm.products,
      border: "",
      fit: ""
    },
    on: {
      "row-click": _vm.onUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Sản phẩm",
      prop: "carton.name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [!row.name ? _c("span", [_vm._v(" " + _vm._s(_vm.generateName(row)) + " ")]) : _c("span", [_vm._v(_vm._s(row.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lớp",
      prop: "cartonWave.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Kết cấu",
      prop: "cartonStruct.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá bán (sale)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.price)) + "đ ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá gợi ý"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.priceSuggest)) + " "), row.priceSuggest ? _c("span", [_vm._v(" đ ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thành tiền (sale)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalPrice)) + "đ ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú",
      prop: "note"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Cho phép in",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("div", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return false.apply(null, arguments);
            }
          }
        }, [_c("el-checkbox", {
          model: {
            value: row.isAllowPrint,
            callback: function callback($$v) {
              _vm.$set(row, "isAllowPrint", $$v);
            },
            expression: "row.isAllowPrint"
          }
        })], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var $index = _ref7.$index,
            row = _ref7.row;
        return [_c("el-tooltip", {
          attrs: {
            content: "Copy",
            placement: "top",
            effect: "dark"
          }
        }, [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-document-copy"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleCopy($index, row);
            }
          }
        })], 1), _c("el-tooltip", {
          attrs: {
            content: "Xóa",
            placement: "top",
            effect: "dark"
          }
        }, [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.onDelete($index);
            }
          }
        })], 1)];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;