"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

require("core-js/modules/es.array.concat.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Tạo nhiệm vụ",
      visible: _vm.visible,
      "close-on-click-modal": false,
      width: "700px",
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiêu đề",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mô tả",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm.visibleOpportunity ? _c("el-form-item", {
    attrs: {
      label: "Cơ hội",
      prop: "misaOpportunityId"
    }
  }, [_c("el-select", {
    attrs: {
      remote: "",
      filterable: "",
      "remote-method": _vm.fetchMisaOpportunities
    },
    model: {
      value: _vm.form.misaOpportunityId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaOpportunityId", $$v);
      },
      expression: "form.misaOpportunityId"
    }
  }, _vm._l(_vm.misaOpportunities, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " - ").concat(item.stageText),
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Hạn hoàn thành",
      prop: "dueDate"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "datetime",
      format: "HH:mm dd/MM/yyyy",
      placeholder: "",
      clearable: false
    },
    model: {
      value: _vm.form.dueDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dueDate", $$v);
      },
      expression: "form.dueDate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Người thực hiện",
      prop: "misaEmployeeId"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: _vm.user.role.id != 1,
      filterable: "",
      remote: "",
      "remote-method": _vm.fetchMisaEmployees
    },
    model: {
      value: _vm.form.misaEmployeeId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaEmployeeId", $$v);
      },
      expression: "form.misaEmployeeId"
    }
  }, _vm._l(_vm.employees, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name),
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;