"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Loại")]), _c("el-select", {
    attrs: {
      clearable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "type", $$v);
      },
      expression: "query.type"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Sắp mua",
      value: _vm.ChatContentType.SoonBuy
    }
  }), _c("el-option", {
    attrs: {
      label: "Lâu chưa mua",
      value: _vm.ChatContentType.LongBuy
    }
  })], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm mới")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tiêu đề",
      prop: "title"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tiêu đề",
      prop: "content"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Loại"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.type == _vm.ChatContentType.LongBuy ? _c("span", [_vm._v("Lâu chưa mua")]) : _c("span", [_vm._v("Sắp mua")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")])];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("ChatContentModal", {
    ref: "ChatContentModal",
    on: {
      submitOk: _vm.handleSubmitOk
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;