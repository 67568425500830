"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ganttScheduleTimelineCalendar = _interopRequireDefault(require("gantt-schedule-timeline-calendar"));

require("gantt-schedule-timeline-calendar/dist/style.css");

var state, gstc;
var _default = {
  name: "GSTC",
  props: ["config"],
  mounted: function mounted() {
    state = _ganttScheduleTimelineCalendar.default.api.stateFromConfig(this.config);
    this.$emit("state", state);
    gstc = (0, _ganttScheduleTimelineCalendar.default)({
      element: this.$refs.gstc,
      state: state
    });
    this.$watch("config", function (config) {
      state.update("config", function (current) {
        if (current !== config) {
          return _ganttScheduleTimelineCalendar.default.api.mergeDeep({}, current, config);
        } else {
          return current;
        }
      });
    }, {
      deep: true
    });
  },
  methods: {
    updateConfig: function updateConfig(cb) {
      state.update("config.chart.items", this.config.chart.items);
      state.update("config.list.rows", this.config.list.rows);
      state.update("config.actions['chart-timeline-items-row-item']", [cb]);
    }
  },
  destroyed: function destroyed() {
    gstc.app.destroy();
  }
};
exports.default = _default;