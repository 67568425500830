"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    attrs: {
      data: _vm.data,
      fit: ""
    },
    on: {
      "row-click": _vm.handleUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Danh mục",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Loại"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.ProductCategoryTypeTrans[row.type]) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tỷ số quy đổi thể tích",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.ratioChange)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Độ khó vận chuyển",
      prop: "difficultLevel",
      align: "center"
    }
  }), false ? _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-tooltip", {
          attrs: {
            content: "Cập nhật",
            placement: "top",
            effect: "dark"
          }
        }, [_c("el-button", {
          attrs: {
            icon: "el-icon-edit",
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        })], 1)];
      }
    }], null, false, 1611084670)
  }) : _vm._e()], 1), _c("CategoryProductModal", {
    ref: "categoryProductModal",
    on: {
      "submit:success": _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;