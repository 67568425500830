"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.quoteApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var quoteApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/quote",
      params: params
    });
  },
  findLogs: function findLogs(quoteId) {
    return (0, _request.default)({
      url: "/v1/admin/quote/".concat(quoteId, "/logs")
    });
  },
  findTodo: function findTodo(quoteId) {
    return (0, _request.default)({
      url: "/v1/admin/quote/".concat(quoteId, "/todo")
    });
  },
  findTotalToday: function findTotalToday() {
    return (0, _request.default)({
      url: "/v1/admin/quote/today"
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/v1/admin/quote/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/quote",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/quote/".concat(id, "/update"),
      data: data,
      method: "put"
    });
  },
  updateStatus: function updateStatus(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/quote/".concat(id, "/update/status"),
      data: data,
      method: "put"
    });
  },
  updateScheduleProductionStatus: function updateScheduleProductionStatus(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/quote/".concat(id, "/update/status/schedule-production"),
      data: data,
      method: "put"
    });
  },
  restore: function restore(id) {
    return (0, _request.default)({
      url: "/v1/admin/quote/".concat(id, "/restore"),
      method: "put"
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/v1/admin/quote/".concat(id, "/delete"),
      method: "delete"
    });
  }
};
exports.quoteApi = quoteApi;