"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configApi = void 0;

var _request = require("@/utils/request");

var configApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/config',
      params: params
    });
  },
  findAllGeneral: function findAllGeneral(params) {
    return (0, _request.request)({
      url: '/v1/admin/config/general',
      params: params
    });
  },
  findOne: function findOne(param) {
    return (0, _request.request)({
      url: '/v1/admin/config/general/param',
      params: {
        param: param
      }
    });
  },
  updateByParam: function updateByParam(param, value) {
    return (0, _request.request)({
      url: '/v1/admin/config/param',
      method: 'put',
      data: {
        value: value,
        param: param
      }
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/config/general/".concat(id),
      data: data,
      method: 'put'
    });
  }
};
exports.configApi = configApi;