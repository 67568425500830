"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.workOrderApi = void 0;

var _request = require("@/utils/request");

/**
 * lsx: production order
 */
var workOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/workOrder',
      params: params
    });
  },
  findOneByCode: function findOneByCode(code) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/byCode",
      params: {
        code: code
      }
    });
  },
  findSummaryReportQuantity: function findSummaryReportQuantity(params) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/summary/reportQuantity",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/".concat(id)
    });
  },
  updateBatchProductionStatus: function updateBatchProductionStatus(workOrderIds, productionStatus) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/batch/productionStatus",
      method: 'PATCH',
      data: {
        workOrderIds: workOrderIds,
        productionStatus: productionStatus
      }
    });
  },
  assignProductionProcess: function assignProductionProcess(workOrderId, productionProcessId) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/".concat(workOrderId, "/assign/productionProcess"),
      method: 'PATCH',
      data: {
        productionProcessId: productionProcessId
      }
    });
  },
  startProduction: function startProduction(workOrderId, data) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/".concat(workOrderId, "/start/production"),
      method: 'PATCH',
      data: data
    });
  },
  findSummaryStatus: function findSummaryStatus(params) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/summary/status",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/workOrder',
      data: data,
      method: 'post'
    });
  },
  checkInventory: function checkInventory(params) {
    return (0, _request.request)({
      url: '/v1/admin/workOrder/check/inventory',
      params: params
    });
  },
  mergeDate: function mergeDate(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/".concat(id, "/mergeDate"),
      data: data,
      method: 'patch'
    });
  },
  createParent: function createParent(data) {
    return (0, _request.request)({
      url: '/v1/admin/workOrder/parent',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/".concat(id),
      method: 'patch',
      data: data
    });
  },
  updateNote: function updateNote(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/".concat(id, "/note"),
      method: 'patch',
      data: data
    });
  },
  updateFollowStep: function updateFollowStep(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/".concat(id, "/isFlowStep"),
      method: 'patch',
      data: data
    });
  },
  updateTags: function updateTags(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/".concat(id, "/tags"),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/workOrder/".concat(id),
      method: 'delete'
    });
  }
};
exports.workOrderApi = workOrderApi;