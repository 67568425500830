"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "quote-log-compose"
  }, [_c("div", {
    staticClass: "comment-box"
  }, [_c("el-input", {
    ref: "input",
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 2,
        maxRows: 4
      },
      placeholder: "Ghi hoạt động"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c("div", {
    staticClass: "comment-control",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("Lưu")]), _vm.visibleCancel ? _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("cancel");
      }
    }
  }, [_vm._v("Hủy")]) : _vm._e()], 1)], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;