"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Nhân viên"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.misaEmployee && row.misaEmployee.staff ? _c("div", [_c("img", {
          staticStyle: {
            "object-fit": "cover",
            "border-radius": "50%"
          },
          attrs: {
            src: row.misaEmployee.staff.avatar ? _vm.$baseUrlMedia + row.misaEmployee.staff.avatar : _vm.defaultAvatar,
            width: "40",
            height: "40",
            alt: ""
          }
        }), _c("br"), _c("span", [_vm._v(" " + _vm._s(row.misaEmployee.staff.name) + " ")])]) : _c("div", [_c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(" Chưa liên kết nhân viên ")])]), _c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            icon: "el-icon-refresh",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onLinkCustomer(row);
            }
          }
        }, [_vm._v("Gán NV")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm.buyTypeContent(row) == "SOON" ? _c("div", {
          staticClass: "customer-badge soon-buy"
        }, [_c("img", {
          attrs: {
            src: _vm.comingSoonImg,
            width: "25",
            alt: ""
          }
        }), _c("span", {
          staticClass: "title"
        }, [_vm._v("Sắp mua")])]) : _vm.buyTypeContent(row) == "LONG" ? _c("div", {
          staticClass: "customer-badge long-buy"
        }, [_c("img", {
          attrs: {
            src: _vm.longTermImg,
            width: "25",
            alt: ""
          }
        }), _c("span", {
          staticClass: "title"
        }, [_vm._v("Lâu chưa mua")])]) : _vm._e(), _c("el-link", {
          style: {
            color: row.isBlocked ? "red" : "#1890ff",
            "text-decoration": row.isBlocked ? "line-through" : "unset"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.onViewCustomer(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.name) + " - " + _vm._s(row.tel) + " ")]), row.misaCustomerTags && row.misaCustomerTags.length ? [_c("div", _vm._l(row.misaCustomerTags, function (item, index) {
          return _c("MisaCustomerTagComp", {
            key: index,
            attrs: {
              tag: item
            }
          });
        }), 1)] : _vm._e(), _c("el-button", {
          staticStyle: {
            "margin-top": "4px"
          },
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onClickTag(row);
            }
          }
        }, [_vm._v("Gắn tag")]), _c("br"), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Nhóm: ")]), _c("span", [_vm._v(_vm._s(row.misaCustomerGroup ? row.misaCustomerGroup.name : "--"))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tổng tiền đơn hàng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.orderSales || 0)) + "đ")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Số đơn hàng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.numOfOrders || 0)))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tổng tiền báo giá: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalSaleQuote || 0)) + "đ")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Số báo giá: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalQuote || 0)))])]), row.misaCustomerFollows && _vm.isVisibleFollow ? [_c("div", [!row.misaCustomerFollows.length ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onFollow(row);
            }
          }
        }, [_vm._v(" Follow khách ")]) : _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onUnFollow(row);
            }
          }
        }, [_vm._v(" Bỏ follow ")])], 1), _vm._l(row.nextActivities, function (item, index) {
          return row.nextActivities ? _c("div", {
            key: index,
            staticStyle: {
              "border-bottom": "#ccc 1px solid"
            }
          }, [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v(" " + _vm._s(_vm.fromAgo(item.createdAt)) + ": ")]), _c("span", [_vm._v(_vm._s(item.name))])])]) : _vm._e();
        })] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thông tin mua hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Chu kỳ mua: ")]), row.purchaseDay || row.avgDayBetweenPurchase ? _c("span", [_vm._v(" " + _vm._s(row.purchaseDay || row.avgDayBetweenPurchase) + " ngày ")]) : _vm._e()]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Số ngày chưa mua: ")]), row.dayWithoutPurchase ? _c("span", [_vm._v(" " + _vm._s(row.dayWithoutPurchase) + " ngày ")]) : _vm._e()]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mua lần cuối: ")]), row.purchaseRecentDate ? _c("span", [_vm._v(" " + _vm._s(_vm.$stringToDate(row.purchaseRecentDate)) + " (" + _vm._s(_vm.fromAgo(row.purchaseRecentDate)) + ") ")]) : _vm._e()])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hoạt động gần nhất"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("div", [row.lastInteractAt ? _c("div", [_c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(_vm._s(_vm.fromAgo(row.lastInteractAt)))])]) : _vm._e(), _vm._l(row.nearActivities, function (item, index) {
          return row.nearActivities ? _c("div", {
            key: index,
            staticStyle: {
              "border-bottom": "#ccc 1px solid"
            }
          }, [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v(" " + _vm._s(_vm.fromAgo(item.createdAt)) + ": ")]), _c("span", [_vm._v(_vm._s(item.name))])])]) : _vm._e();
        })], 2)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("div", [_c("div", [_c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            width: "126px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.onCreateNote(row);
            }
          }
        }, [_vm._v("Tạo ghi chú")])], 1), _c("div", {
          staticStyle: {
            "margin-top": "12px"
          }
        }, [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            icon: "el-icon-tickets",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onCreateActivity(row);
            }
          }
        }, [_vm._v("Tạo nhiệm vụ")])], 1)])];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;