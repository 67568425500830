"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("span", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("i", _vm._g({
    staticClass: "icon-button",
    class: _vm.icon,
    style: {
      color: _vm.color,
      width: _vm.width,
      height: _vm.height
    }
  }, _vm.$listeners))]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;