"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container truck-tour"
  }, [_c("truck-tour-list", {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      onClick: _vm.handleUpdateTruckTour,
      truckTours: _vm.truckTours
    }
  }), _c("el-tabs", {
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, _vm._l(_vm.tabs, function (item, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        label: item.label,
        name: item.name
      }
    }, [_c("span", {
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_vm._v(" " + _vm._s(item.label) + " "), _c("el-badge", {
      class: item.name,
      attrs: {
        value: item.total
      }
    })], 1), _c("order-table", {
      attrs: {
        query: _vm.query,
        isFocused: _vm.tabName == item.name,
        status: item.name,
        districts: _vm.districts,
        sellers: _vm.sellers,
        truckTours: _vm.truckTours,
        onAddExist: _vm.handleAddExist,
        onAddNew: _vm.handleAddNew
      },
      on: {
        fetched: _vm.handleFetched
      }
    })], 1);
  }), 1), _c("truck-tour-modal", {
    ref: "TruckTourModal",
    on: {
      "submit:ok": _vm.fetchTruckTours
    }
  }), _c("choose-truck-tour-modal", {
    ref: "ChooseTruckTourModal",
    attrs: {
      truckTours: _vm.truckTours
    },
    on: {
      "submit:ok": _vm.fetchTruckTours
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;