"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    attrs: {
      id: "gallery-product"
    }
  }, [_c("el-upload", {
    attrs: {
      action: _vm.$baseUrl + "/v1/admin/quote/upload",
      "on-remove": _vm.handleRemove,
      multiple: "",
      "before-upload": _vm.beforeUpload,
      "file-list": _vm.fileList,
      "on-success": _vm.handleSuccess,
      drag: "",
      "on-preview": _vm.handlePreview,
      "on-error": _vm.onError,
      headers: {
        token: _vm.token,
        version: _vm.version
      },
      "list-type": "picture-card"
    }
  }, [_vm.isProgressing ? _c("el-progress", {
    attrs: {
      type: "circle",
      percentage: _vm.percentUpload
    }
  }) : _vm._e(), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    }
  }, [_vm._v(" Click to upload ")]), _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  })], 1), _vm.imageSelected ? _c("el-image", {
    ref: "imageView",
    staticStyle: {
      height: "0"
    },
    attrs: {
      src: _vm.imageSelected,
      "preview-src-list": [_vm.imageSelected]
    }
  }) : _vm._e(), _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "append-to-body": "",
      title: "Xem ảnh",
      visible: _vm.previewModal,
      width: "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.previewModal = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("img", {
    staticStyle: {
      width: "350px",
      height: "300px",
      "object-fit": "contain"
    },
    attrs: {
      src: _vm.imageSelected,
      alt: ""
    }
  })]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.previewModal = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;