"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.filter.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "production-work-table"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "table",
    attrs: {
      data: _vm.productionWorks,
      border: "",
      fit: "",
      "row-key": "id",
      "row-class-name": _vm.renderClassName
    },
    on: {
      select: _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "55",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-checkbox", {
          attrs: {
            checked: _vm.selected.some(function (e) {
              return e.id == row.id;
            })
          },
          on: {
            change: function change(val) {
              return _vm.handleSelect(row, val);
            }
          }
        })];
      }
    }])
  }), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "Mã lệnh",
      prop: "code",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_vm._v(" " + _vm._s(row.code) + " ")])];
      }
    }], null, false, 1297179377)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "Tổng giờ",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm.getPrevStep(row) ? _c("div", {
          staticStyle: {
            "font-size": "16px",
            color: "#000"
          }
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Đang chờ: ")]), _c("label", [_vm._v(_vm._s(_vm.getPrevStep(row).productionStep.name))])]) : _vm._e(), _c("div", {
          staticClass: "cell-should-fade"
        }, [row.workOrder && row.workOrder.misaTags.length ? [_c("div", _vm._l(row.workOrder.misaTags, function (item, index) {
          return _c("MisaTagComp", {
            key: index,
            attrs: {
              misaTag: item,
              clickable: _vm.isAdmin
            },
            on: {
              click: function click($event) {
                _vm.isAdmin && _vm.onUpdateTag(row);
              }
            }
          });
        }), 1)] : _vm._e(), _c("div", [row.workOrder ? _c("label", {
          class: [_vm.dateClass(row.workOrder.startAt)],
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(_vm.$formatDate(row.workOrder.startAt)))]) : _vm._e()]), _c("span", {
          staticClass: "hour-hover",
          staticStyle: {
            color: "red",
            "font-weight": "bold"
          },
          on: {
            click: function click($event) {
              return _vm.onClickDuration(row);
            }
          }
        }, [_vm._v(_vm._s(_vm.renderTime(row)) + " ")]), row.plannedEndAt ? _c("div", [_c("span", [_vm._v("Dự kiến kết thúc: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatDate(row.plannedEndAt, "HH:mm")))])]) : _vm._e(), _c("div", [_c("span", [_vm._v("Dự kiến hoàn thành: ")]), _c("span", [_vm._v("--")])]), _c("div", [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onLog(row);
            }
          }
        }, [_vm._v("Xem lịch sử")]), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onPrintBTP(row);
            }
          }
        }, [_vm._v("In BTP, NVL")])], 1)], 2)];
      }
    }])
  }), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "LSX",
      prop: "workOrder.code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_vm.isAdmin ? _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickWorkOrder(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.workOrder.code) + " ")]) : _c("span", [_vm._v(" " + _vm._s(row.workOrder.code) + " ")])], 1)];
      }
    }], null, false, 2806737091)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "Diễn giải",
      width: "250",
      prop: "workOrder.name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.workOrder ? _c("div", {
          staticClass: "cell-should-fade"
        }, [row.workOrder.dinhHinh != "Không" && row.workOrder.dinhHinh != "Dán" ? _c("div", [_c("span", {
          staticStyle: {
            color: "red",
            "font-size": "20px",
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(row.workOrder.dinhHinh) + " ")])]) : _vm._e(), _c("span", [_vm._v(_vm._s(_vm.hiddenName(row.workOrder)))]), row.workOrder.cartonWaveCode ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Sóng: ")]), _c("span", {
          staticStyle: {
            color: "red",
            "font-size": "20px",
            "font-weight": "bold"
          }
        }, [_vm._v(_vm._s(row.workOrder.cartonWaveCode))])]) : _vm._e(), row.workOrder.waterProof != "Không" ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Chống thấm: ")]), _c("span", {
          staticStyle: {
            color: "red",
            "font-size": "20px",
            "font-weight": "bold"
          }
        }, [_vm._v(_vm._s(row.workOrder.waterProof))])]) : _vm._e()]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL NVL",
      align: "right",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [row.workOrder ? [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Yêu cầu: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.workOrder.materialQuantity)))])]), _vm.visibleBTP ? [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Thành phẩm: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.tpQuantity)))])]), row.btp ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Bán TP: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.btp.quantity)))])]) : _vm._e()] : _vm._e(), row.material ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("NVL (Misa): ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.material.quantity)))])]) : _vm._e(), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("SL min: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.minMaterialQuantity)))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("SL max: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.maxMaterialQuantity)))])]), _vm.checkStatusNVL(row) != "ok" ? _c("div", {
          staticStyle: {
            color: "red",
            "font-size": "20px"
          }
        }, [_vm._v(" " + _vm._s(_vm.checkStatusNVL(row) == "over" ? "Đã vượt quá 5%" : "Bị hụt 5%") + " ")]) : _vm._e(), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewQr(row);
            }
          }
        }, [_vm._v("Xem kho")])] : _vm._e()], 2)];
      }
    }])
  }), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "Ngày lập lệnh"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_vm._v(" " + _vm._s(_vm.$formatDate(row.workOrder.startAt)) + " ")])];
      }
    }], null, false, 27115962)
  }) : _vm._e(), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "Ngày bắt đầu"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_vm._v(" " + _vm._s(row.startAt ? _vm.$formatDate(row.startAt) : "") + " ")])];
      }
    }], null, false, 833265455)
  }) : _vm._e(), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "Ngày kết thúc"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_vm._v(" " + _vm._s(row.endAt ? _vm.$formatDate(row.endAt) : "") + " ")])];
      }
    }], null, false, 3006325999)
  }) : _vm._e(), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "Bước",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_c("span", [_vm._v(" " + _vm._s(row.productionStep.name) + " ")]), _vm.isAuth && _vm.isAdmin ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-refresh"
          },
          on: {
            click: function click($event) {
              return _vm.onChangeStep(row);
            }
          }
        }, [_vm._v("Đổi công đoạn")]) : _vm._e()], 1)];
      }
    }], null, false, 129575566)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      width: "70",
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.workOrder.quantity)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_c("el-tooltip", {
          attrs: {
            content: row.note,
            placement: "top",
            effect: "dark"
          }
        }, [_c("span", {
          staticStyle: {
            background: "yellow",
            color: "red",
            "font-weight": "bold"
          }
        }, [_vm._v(_vm._s(row.note))])]), _vm.visibleAction && _vm.isAdmin ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.onNote(row);
            }
          }
        }) : _vm._e(), row.workOrder ? _c("div", [row.workOrder.productNote != "1" ? _c("div", {
          staticStyle: {
            background: "yellow",
            color: "red",
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(row.workOrder.productNote) + " ")]) : _vm._e(), row.workOrder.productNote != "1" ? _c("span", {
          staticStyle: {
            background: "yellow",
            color: "red",
            "font-weight": "bold"
          },
          style: {
            borderTop: row.workOrder.productNote ? "1px dashed #000" : ""
          }
        }, [_vm._v(_vm._s(row.workOrder.note))]) : _vm._e()]) : _vm._e()], 1)];
      }
    }])
  }), _vm.visibleMarket ? _c("el-table-column", {
    attrs: {
      label: "Market",
      align: "right",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_vm._v(" " + _vm._s(row.workOrder.printColors > 0 ? "In ".concat(row.workOrder.printColors, " m\xE0u") : "Không in") + " "), row.workOrder && row.workOrder.msOrder && row.workOrder.msOrder.misaAttaches ? _c("div", _vm._l(row.workOrder.msOrder.misaAttaches.filter(function (e) {
          return e.extension == ".pdf";
        }), function (misaAttach) {
          return _c("div", {
            key: misaAttach.id,
            staticStyle: {
              background: "#f0f2f4",
              padding: "0 8px",
              "border-radius": "4px",
              cursor: "pointer",
              "margin-top": "4px"
            },
            on: {
              click: function click($event) {
                return _vm.handleClickAttach(misaAttach);
              }
            }
          }, [_c("img", {
            staticStyle: {
              "vertical-align": "middle"
            },
            attrs: {
              src: _vm.pdfImage,
              width: "16",
              alt: ""
            }
          }), _c("span", {
            staticStyle: {
              color: "#1890ff",
              display: "inline-block",
              "vertical-align": "middle",
              "margin-left": "4px"
            }
          }, [_vm._v(_vm._s(misaAttach.name))]), _c("el-button", {
            staticClass: "font-bold",
            attrs: {
              type: "primary",
              size: "mini"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.onHiddenMisaAttach(misaAttach);
              }
            }
          }, [_vm._v("Ẩn")])], 1);
        }), 0) : _vm._e(), row.workOrder ? _vm._l(row.workOrder.misaAttaches.filter(function (e) {
          return e.extension == ".pdf";
        }), function (misaAttach) {
          return _c("div", {
            key: misaAttach.id,
            staticStyle: {
              background: "#f0f2f4",
              padding: "0 8px",
              "border-radius": "4px",
              cursor: "pointer",
              "margin-top": "4px"
            },
            on: {
              click: function click($event) {
                return _vm.handleClickAttach(misaAttach);
              }
            }
          }, [_c("img", {
            staticStyle: {
              "vertical-align": "middle"
            },
            attrs: {
              src: _vm.pdfImage,
              width: "16",
              alt: ""
            }
          }), _c("span", {
            staticStyle: {
              color: "#1890ff",
              display: "inline-block",
              "vertical-align": "middle",
              "margin-left": "4px"
            }
          }, [_vm._v(_vm._s(misaAttach.name))]), _c("el-button", {
            staticClass: "font-bold",
            attrs: {
              type: "primary",
              size: "mini"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.onHiddenMisaAttach(misaAttach);
              }
            }
          }, [_vm._v("Ẩn")])], 1);
        }) : _vm._e()], 2)];
      }
    }], null, false, 4210285156)
  }) : _vm._e(), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "Trạng thái",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var row = _ref14.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_c("el-tag", {
          staticClass: "font-bold",
          staticStyle: {
            border: "unset !important"
          },
          attrs: {
            effect: "dark",
            color: _vm.ProductionWorkStatusTrans[row.status].color
          }
        }, [_vm._v(" " + _vm._s(_vm.ProductionWorkStatusTrans[row.status].name) + " ")])], 1)];
      }
    }], null, false, 3297900766)
  }) : _vm._e(), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "Máy",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var row = _ref15.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [row.productionMachine ? [_vm._v(" " + _vm._s(row.productionMachine.name) + " ")] : row.productionWorkMachines.length ? [_c("div", {
          staticStyle: {
            "white-space": "nowrap"
          }
        }, _vm._l(row.productionWorkMachines, function (item, index) {
          return _c("div", {
            key: index,
            staticStyle: {
              padding: "4px 0"
            }
          }, [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v(_vm._s(item.productionMachine.name))]), _c("el-tag", {
            staticStyle: {
              color: "#fff",
              border: "none"
            },
            attrs: {
              color: _vm.ProductionWorkMachineStatusColors[item.status]
            }
          }, [_vm._v(" " + _vm._s(_vm.ProductionWorkMachineStatusTrans[item.status]) + " ")]), _c("br"), item.responsibilityStaff ? _c("span", [_vm._v(_vm._s(item.staff.name))]) : _vm._e(), item.status != _vm.ProductionWorkMachineStatus.Complete && _vm.visibleAction ? [false ? _c("div", [item.status == _vm.ProductionWorkMachineStatus.Processing ? _c("el-tooltip", {
            attrs: {
              content: "Tạm dừng máy",
              placement: "top",
              effect: "dark"
            }
          }, [_c("el-button", {
            attrs: {
              type: "primary",
              size: "small",
              icon: "el-icon-video-pause"
            },
            on: {
              click: function click($event) {
                return _vm.pauseProduce(item);
              }
            }
          })], 1) : _c("el-tooltip", {
            attrs: {
              content: "Tiếp tục việc",
              placement: "top",
              effect: "dark"
            }
          }, [_c("el-button", {
            attrs: {
              type: "primary",
              size: "small",
              icon: "el-icon-video-play"
            },
            on: {
              click: function click($event) {
                return _vm.handleResume(item);
              }
            }
          }, [_vm._v("Tiếp tục")])], 1)], 1) : _vm._e()] : _vm._e()], 2);
        }), 0)] : _c("div", [_vm._v("Chưa cài đặt")]), row.estimatedTime ? _c("div", [_c("span", [_vm._v("Ước tính: ")]), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.estimatedTime) + " phút")])]) : _vm._e()], 2)];
      }
    }], null, false, 1432780346)
  }) : _vm._e(), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "Quy trình"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var row = _ref16.row;
        return [_vm._v(" " + _vm._s(row.productionProcess ? row.productionProcess.name : "") + " ")];
      }
    }], null, false, 4276085719)
  }) : _vm._e(), !_vm.isMobile ? _c("el-table-column", {
    attrs: {
      label: "Nhân viên đảm nhiệm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var row = _ref17.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_vm._v(" " + _vm._s(row.responsibilityStaff ? row.responsibilityStaff.name : "") + " ")])];
      }
    }], null, false, 2439645556)
  }) : _vm._e(), !_vm.isMobile && _vm.visibleAction ? _c("el-table-column", {
    attrs: {
      align: "center",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c("el-checkbox", {
          attrs: {
            value: _vm.isCheckAll,
            disabled: !_vm.isAdmin
          },
          on: {
            change: _vm.onPublicAll
          }
        }, [_vm._v("Public")])];
      }
    }, {
      key: "default",
      fn: function fn(_ref18) {
        var row = _ref18.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [_c("el-checkbox", {
          attrs: {
            value: row.isPublic,
            label: "",
            indeterminate: false
          },
          on: {
            change: function change($event) {
              return _vm.onPublic(row);
            }
          }
        })], 1)];
      }
    }], null, false, 2726496063)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "200",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var row = _ref19.row;
        return [_c("div", {
          staticClass: "cell-should-fade"
        }, [[_c("el-dropdown", {
          attrs: {
            trigger: "click"
          },
          on: {
            command: function command(value) {
              return _vm.handleCommand(value, row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-more"
          }
        }), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          attrs: {
            command: "reportQuantity"
          }
        }, [_vm._v("Nhập kho")]), _vm.visibleAction && _vm.isAdmin ? _c("el-dropdown-item", {
          attrs: {
            command: "endProduce"
          }
        }, [_vm._v("Kết thúc")]) : _vm._e()], 1)], 1)], _vm.visibleAction && _vm.isAdmin ? [row.status == _vm.ProductionWorkStatus.Pending ? [_c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            "margin-top": "4px"
          },
          attrs: {
            icon: "el-icon-play",
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleResume(row);
            }
          }
        }, [_vm._v("Tiếp tục sản xuất")])] : _vm._e()] : _vm._e()], 2)];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;