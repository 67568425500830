"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkOverSize = exports.calc = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.number.to-fixed.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.function.name.js");

var _Product = require("@/types/Product");

var _handler = require("./handler");

var _cartonCost = require("@/types/cartonCost");

var _mold = require("@/types/mold");

var printPriceFactors = [1, 1.2, 1.4, 1.6, 8];
/**
 *  dài >= 50 && <115
 *  khổ >=50 && <75
 * @returns {width:number, length: number}: hợp lệ, err: quá lớn
 */

var checkOverSize = function checkOverSize(_ref) {
  var length = _ref.length,
      width = _ref.width;
  var minLength = 50,
      maxLength = 115;
  var minWidth = 50,
      maxWidth = 75;
  var rateWidth = 0,
      rateLength = 0; // if (length > maxLength) {
  //     throw new Error("Đã quá chiều dài: 115cm");
  // }
  // if (width > maxWidth) {
  //     throw new Error("Đã quá chiều khổ: 75cm");
  // }

  for (var i = 1; i <= 10; i++) {
    if (length * i > minLength) {
      rateLength = i;
      break;
    }
  }

  for (var _i = 1; _i <= 10; _i++) {
    if (width * _i > minWidth) {
      rateWidth = _i;
      break;
    }
  }

  if (rateLength == 0) {
    rateLength = 1;
    length = minLength;
  } else {
    length = rateLength * length;
  }

  if (rateWidth == 0) {
    rateWidth = 1;
    width = minWidth;
  } else {
    width = rateWidth * width;
  }

  return {
    length: length,
    width: width,
    rateLength: rateLength,
    rateWidth: rateWidth
  };
};

exports.checkOverSize = checkOverSize;

var calc = function calc(_ref2) {
  var length = _ref2.length,
      width = _ref2.width,
      cartonWave = _ref2.cartonWave,
      cartonStruct = _ref2.cartonStruct,
      unitPriceDay = _ref2.unitPriceDay,
      unitPriceIn = _ref2.unitPriceIn,
      beMatrix = _ref2.beMatrix,
      unitGiaCong = _ref2.unitGiaCong,
      numColor = _ref2.numColor,
      numOfPrint = _ref2.numOfPrint,
      bangInPrice = _ref2.bangInPrice,
      quantity = _ref2.quantity,
      waterProofFaceType = _ref2.waterProofFaceType,
      khuonBePrice = _ref2.khuonBePrice,
      tamLotPhuPhiBeConfig = _ref2.tamLotPhuPhiBeConfig,
      quantityProfitConfig = _ref2.quantityProfitConfig,
      distance = _ref2.distance,
      _ref2$taiThung = _ref2.taiThung,
      taiThung = _ref2$taiThung === void 0 ? 2 : _ref2$taiThung,
      cartonCosts = _ref2.cartonCosts,
      mold = _ref2.mold,
      isAutoProfit = _ref2.isAutoProfit,
      profitPercent = _ref2.profitPercent,
      isEnablePEPrice = _ref2.isEnablePEPrice,
      pePrice = _ref2.pePrice,
      lastChangeInput = _ref2.lastChangeInput,
      daiTamNho = _ref2.daiTamNho,
      khoTamNho = _ref2.khoTamNho,
      slThungTrenTamNho = _ref2.slThungTrenTamNho,
      daiTam1Thung = _ref2.daiTam1Thung,
      khoTam1Thung = _ref2.khoTam1Thung,
      isAutoCalc = _ref2.isAutoCalc,
      _ref2$teBien = _ref2.teBien,
      teBien = _ref2$teBien === void 0 ? 2 : _ref2$teBien,
      isCot = _ref2.isCot,
      boxType = _ref2.boxType;
  var costs = [];
  var daiTam = 0,
      khoTam = 0; //dài tấm, khổ tấm

  var khoTamNCC = 0,
      daiTamNCC = 0,
      minDaiTamNCC = 80;
  var realKhoTam = 0,
      realDaiTam = 0; //khổ tấm, dài tấm thực tế

  var factorKhoTam = 1,
      factorDaiTam = 1; //tỉ trọng khổ, dài

  var realQuantity = 0; //sl thùng thực tế khi cắt từ giấy ncc

  daiTam1Thung = lastChangeInput == 'thung' || !isAutoCalc ? daiTam1Thung : length;
  khoTam1Thung = lastChangeInput == 'thung' || !isAutoCalc ? khoTam1Thung : width;
  var unitPriceGiay = cartonStruct.price;
  var totalPriceGiay = 0;
  var totalGiaCong = 0;
  var totalPriceKeo = 0,
      totalPriceDay = 0,
      totalPriceGhim = 0,
      totalPriceIn = 0;
  var uocTinhKhoTam = 0,
      uocTinhDaiTam = 0;
  var daiKhuonBe = 1,
      rongKhuonBe = 1;
  var rateLength = 0,
      rateWidth = 0;

  if (boxType != _mold.MoldBoxType.GiayTam) {
    if (lastChangeInput != 'numOfBe') {
      if (mold) {
        daiTam = mold.cartonLength;
        khoTam = mold.cartonWidth;
        beMatrix = "".concat(mold.beWidth, "x").concat(mold.beLength);
        rateLength = mold.beLength;
        rateWidth = mold.beWidth;
      } else {
        var _checkOverSize = checkOverSize({
          length: length,
          width: width
        }),
            calcRateLength = _checkOverSize.rateLength,
            calcRateWidth = _checkOverSize.rateWidth,
            calcDaiTam = _checkOverSize.length,
            calcKhoTam = _checkOverSize.width;

        rateLength = calcRateLength;
        rateWidth = calcRateWidth;
        console.log('checkOverSize calcDaiTam:', calcDaiTam, 'calcKhoTam:', calcKhoTam); //bế cộng cả 2 chiều khổ, dài

        daiTam = calcDaiTam + teBien;
        khoTam = calcKhoTam + teBien;
        beMatrix = "".concat(rateWidth, "x").concat(rateLength);
      }
    } else {
      rateWidth = +beMatrix.split('x')[0];
      rateLength = +beMatrix.split('x')[1];
      daiTam = +(length * rateLength + 2).toFixed(1);
      khoTam = +(width * rateWidth + 2).toFixed(1);
    }

    daiTamNho = lastChangeInput == 'tamNho' || !isAutoCalc ? daiTamNho : daiTam;
    khoTamNho = lastChangeInput == 'tamNho' || !isAutoCalc ? khoTamNho : khoTam;
  } else {
    khoTamNho = +width;
    daiTamNho = +length;
    daiTam = daiTamNho;
    khoTam = khoTamNho;
    rateWidth = 1;
    rateLength = 1;
  }

  slThungTrenTamNho = lastChangeInput == 'slThung' || !isAutoCalc ? slThungTrenTamNho : mold ? mold.numOfProducts : Math.floor(daiTamNho * khoTamNho / (daiTam1Thung * khoTam1Thung));
  debugger;
  var khoTamCongThemNCC = teBien; //Nếu khổ tấm < 115, thì ghép giấy để đủ khổ >= 115

  var _getBestFactorKhoTamV = (0, _handler.getBestFactorKhoTamV2)({
    khoTam: khoTam,
    khoTamCongThemNCC: khoTamCongThemNCC
  }),
      factor = _getBestFactorKhoTamV.factor,
      minKhotam = _getBestFactorKhoTamV.minKhotam;

  factorKhoTam = factor;
  khoTamNCC = minKhotam; //dài tấm ncc = dài tấm

  daiTamNCC = daiTam; //tính khổ, dài thực tế

  realKhoTam = +(khoTamNCC / factorKhoTam).toFixed(1);
  realDaiTam = +(daiTamNCC / factorDaiTam).toFixed(1); //sl thùng thực tế

  realQuantity = Math.floor(khoTamNCC / khoTamNho);
  var calcRealDaiTam = realDaiTam / rateLength,
      calcRealKhoTam = realKhoTam / rateWidth;
  console.log('calcRealKhoTam:', calcRealKhoTam);
  console.log('calcRealDaiTam:', calcRealDaiTam); //tính tổng phí gia công

  uocTinhKhoTam = +(khoTamNCC / (khoTamNCC / khoTam)).toFixed(1);
  uocTinhDaiTam = +(daiTamNCC / (daiTamNCC / daiTam)).toFixed(1); //

  console.log('unitGiaCong:', unitGiaCong);
  totalGiaCong = Math.ceil(unitGiaCong / (rateWidth * rateLength));
  totalPriceKeo = 0;
  totalPriceDay = width * unitPriceDay;
  totalPriceGhim = 0;
  totalPriceIn = calcRealDaiTam * calcRealKhoTam / 10000 * unitPriceIn;
  var realAcr = +(realDaiTam * realKhoTam / 10000).toFixed(4);
  totalPriceGiay = Math.ceil(realAcr * unitPriceGiay / slThungTrenTamNho);
  var minQuantity = Math.ceil(300 * 100 * realQuantity / daiTamNCC);
  var cubicMeter = +(0, _handler.calcCubicMeter)({
    daiTam: daiTam1Thung,
    khoTam: khoTam1Thung,
    cartonWaveCode: cartonWave.code
  });
  var acr = +(realDaiTam * realKhoTam / 10000 / (daiKhuonBe * rongKhuonBe) / (rateWidth * rateLength)).toFixed(4);
  console.log('acr:', acr);
  var moneyPrint = 0;

  if (numColor) {
    var printCost = cartonCosts.find(function (e) {
      return e.name.toLowerCase() == 'phí in';
    });
    console.log('printCost:', {
      price: printCost.price,
      numColor: numColor,
      numOfPrint: numOfPrint
    });
    moneyPrint = Math.round(acr * printCost.price * printPriceFactors[numColor - 1]);

    if (numOfPrint == 2) {
      moneyPrint *= 3;
    } else {
      moneyPrint *= numOfPrint;
    }

    console.log('moneyPrint:', moneyPrint);
  }

  var totalMoneyBangIn = !quantity ? 0 : Math.ceil(+bangInPrice / quantity);
  console.log('totalMoneyBangIn:', totalMoneyBangIn);
  var moneyFinal = Math.ceil(totalPriceGiay + totalMoneyBangIn + moneyPrint);
  console.log('moneyFinal 1:', moneyFinal); //tiền mặt chống thấm

  var moneyWaterProofFace = 0;

  if (waterProofFaceType) {
    var faceType = _Product.waterProofFaces.find(function (e) {
      return e.value == waterProofFaceType;
    });

    console.log("num face:", faceType.face);
    moneyWaterProofFace = faceType.face * acr * 300;
    moneyFinal += moneyWaterProofFace;
  }

  console.log('moneyFinal 2:', moneyFinal); //nếu k đủ 300m ncc thì tăng giá

  if (daiTamNCC < 300) {
    moneyFinal *= 1.05;
  }

  console.log("moneyFinal after daiTamNCC:", moneyFinal);
  var moneyCartonE = 0;

  if (cartonWave.code == "E") {
    moneyCartonE = moneyFinal * 0.02;
    moneyFinal += moneyCartonE;
  }

  console.log("moneyFinal after carton code:", moneyFinal); //tiền phụ phí bế

  var moneyPhuPhiBe = +tamLotPhuPhiBeConfig.value / (rateLength * rateWidth); //tiền khuôn bế

  moneyFinal += +khuonBePrice / quantity; //! tính chi phí

  console.log('cartonCosts:', cartonCosts);
  var acr1Thung = +(daiTam1Thung * khoTam1Thung / 10000).toFixed(4);
  console.log('acr1Thung:', acr1Thung);
  var bigPlateToProductQuantity = realQuantity * slThungTrenTamNho; //quy đổi 1 thùng lớn ra ? thùng nhỏ

  var acrNCC = +(daiTamNCC * khoTamNCC / 10000).toFixed(4);
  console.log('acrNCC:', {
    acrNCC: acrNCC,
    daiTamNCC: daiTamNCC,
    khoTamNCC: khoTamNCC
  });
  var acrTamNho = +(daiTamNho * khoTamNho / 10000).toFixed(4);
  console.log('acrTamNho:', acrTamNho);
  var moneyKhuonBe = 0;

  if (quantity) {
    moneyKhuonBe = Math.ceil(+khuonBePrice / quantity);
  }

  moneyFinal += moneyKhuonBe;
  console.log('moneyFinal 3:', moneyFinal);

  var _iterator = (0, _createForOfIteratorHelper2.default)(cartonCosts),
      _step;

  try {
    var _loop = function _loop() {
      var cost = _step.value;
      //* check chi phí bế
      var beTuDong = 'Phí bế tự động';
      var beTay = 'Phí bế tay';
      var isPhiBe = (cost.name == beTuDong || cost.name == beTay) && !!mold;

      if (isPhiBe) {
        if (mold.type == _mold.MoldType.Circle && cost.name == beTay || mold.type == _mold.MoldType.Flat && cost.name == beTuDong) {
          return "continue";
        }
      } //* end - check chi phí bế


      if (cost.isUseParent) {
        return "continue";
      }

      if (cost.name.toLowerCase() == 'phí in') {
        return "continue";
      } //sử dụng liên kết


      if (cost.name == beTay || cost.name == beTuDong) {
        if (mold || cost.isPriority) {
          if (cost.linked) {
            var findLinked = cartonCosts.find(function (e) {
              return e.id == cost.linked.id;
            });

            if (findLinked) {
              findLinked.isUse = false;
            }
          } //nothing

        } else {
          return "continue";
        }
      } else {
        if (!cost.isPriority && cost.linked) {
          return "continue";
        }
      }

      if (cost.isNoBe) {
        return "continue";
      }

      if (cost.isPrint && (!numColor || !numOfPrint)) {
        return "continue";
      }

      if (cost.isEnableAcr) {
        var string = "".concat(acr1Thung, " ").concat(cost.acrOperator, " ").concat(cost.acrValue);

        if (cost.acrOperator2) {
          string += " && ".concat(acr1Thung, " ").concat(cost.acrOperator2, " ").concat(cost.acrValue2);
        }

        if (!eval(string)) {
          return "continue";
        }
      }

      if (cost.isEnableQuantity) {
        var _string = "".concat(quantity, " ").concat(cost.quantityOperator, " ").concat(cost.quantityValue);

        if (cost.quantityOperator2) {
          _string += " && ".concat(quantity, " ").concat(cost.quantityOperator2, " ").concat(cost.quantityValue2);
        }

        if (!eval(_string)) {
          return "continue";
        }
      }

      if (cost.isEnableKho1Thung) {
        var _string2 = "".concat(khoTam1Thung, " ").concat(cost.kho1ThungOperator, " ").concat(cost.kho1ThungValue);

        if (cost.kho1ThungOperator2) {
          _string2 += " && ".concat(khoTam1Thung, " ").concat(cost.kho1ThungOperator2, " ").concat(cost.kho1ThungValue2);
        }

        if (!eval(_string2)) {
          return "continue";
        }
      }

      if (cost.linked && !isPhiBe) {
        var _findLinked = cartonCosts.find(function (e) {
          return e.id == cost.linked.id;
        });

        if (_findLinked.isUse) {
          return "continue";
        }
      } //tính giấy tấm có tùy chọn cột


      if (boxType == _mold.MoldBoxType.GiayTam) {
        if (cost.name == 'Phí cột + đếm tay') {
          cost.isEnable = isCot;
        }
      }

      var price = cost.price,
          minPrice = cost.minPrice;

      if (cost.isPrint) {
        // price *= numColor
        minPrice *= numColor;
      }

      var calcValue = cost.calcValue,
          calcType = cost.calcType;
      var totalPrice = 0,
          unitQuantity = 0;

      switch (calcValue) {
        case _cartonCost.CartonCostCalcValueType.Quantity:
          if (calcType == _cartonCost.CartonCostCalcType.Product) {
            unitQuantity = 1;
            totalPrice = price;
          } else if (calcType == _cartonCost.CartonCostCalcType.BigPlate) {
            unitQuantity = bigPlateToProductQuantity;
            totalPrice = price / unitQuantity;
          } else if (calcType == _cartonCost.CartonCostCalcType.SmallPlate) {
            unitQuantity = slThungTrenTamNho;
            totalPrice = price / slThungTrenTamNho;
          }

          break;

        case _cartonCost.CartonCostCalcValueType.Width:
          if (calcType == _cartonCost.CartonCostCalcType.Product) {
            unitQuantity = khoTam1Thung / 100;
            totalPrice = price * unitQuantity;
          } else if (calcType == _cartonCost.CartonCostCalcType.BigPlate) {
            unitQuantity = +(khoTamNCC / khoTamNho / khoTam1Thung).toFixed(4);
            totalPrice = price / unitQuantity;
          } else if (calcType == _cartonCost.CartonCostCalcType.SmallPlate) {
            unitQuantity = +(khoTamNho / khoTam1Thung).toFixed(4);
            totalPrice = price / unitQuantity;
          }

          break;

        case _cartonCost.CartonCostCalcValueType.Length:
          if (calcType == _cartonCost.CartonCostCalcType.Product) {
            unitQuantity = daiTam1Thung / 100;
            totalPrice = price * unitQuantity;
          } else if (calcType == _cartonCost.CartonCostCalcType.BigPlate) {
            unitQuantity = +(daiTamNCC / daiTamNho / daiTam1Thung).toFixed(4);
            totalPrice = price / unitQuantity;
          } else if (calcType == _cartonCost.CartonCostCalcType.SmallPlate) {
            totalPrice = price / (daiTamNho / daiTam1Thung);
            unitQuantity = daiTam1Thung;
          }

          break;

        case _cartonCost.CartonCostCalcValueType.Meter:
          if (calcType == _cartonCost.CartonCostCalcType.Product) {
            unitQuantity = acr1Thung;
            totalPrice = price * acr1Thung;
          } else if (calcType == _cartonCost.CartonCostCalcType.BigPlate) {
            totalPrice = price * acrNCC;
            unitQuantity = acrNCC;
          } else if (calcType == _cartonCost.CartonCostCalcType.SmallPlate) {
            totalPrice = price * acrTamNho;
            unitQuantity = acrTamNho;
          }

          break;

        default:
          break;
      } // console.log('calc cost', cost.name, 'unitQuantity', unitQuantity, 'totalPrice', totalPrice);


      var unitMinPrice = 0;

      if (unitQuantity) {
        unitMinPrice = minPrice / quantity;
      }

      totalPrice += +unitMinPrice.toFixed(1);
      console.log("cost name".concat(cost.name, ", cost fee ").concat(totalPrice, ", after calc:"), moneyFinal);

      if (cost.isEnable) {
        moneyFinal += totalPrice;
      }

      console.log("cost name ".concat(cost.name, ", cost fee ").concat(totalPrice, ", after calc:"), moneyFinal);
      cost.isUse = true;
      costs.push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, cost), {}, {
        totalPrice: totalPrice,
        unitMinPrice: unitMinPrice,
        price: price,
        minPrice: minPrice,
        quantity: unitQuantity
      }));
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _ret = _loop();

      if (_ret === "continue") continue;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  if (numColor && numOfPrint) {
    costs.unshift({
      // name: `Chi phí in ${numColor} màu`,
      id: 0,
      name: "Ph\xED in",
      minPrice: 0,
      isEnable: true,
      price: moneyPrint,
      unitMinPrice: 0,
      totalPrice: moneyPrint,
      type: _cartonCost.CartonCostType.Material
    });
  }

  if (totalMoneyBangIn) {
    costs.unshift({
      id: 0,
      name: "Chi ph\xED b\u1EA3n in",
      minPrice: 0,
      price: totalMoneyBangIn,
      unitMinPrice: 0,
      totalPrice: totalMoneyBangIn,
      isEnable: true,
      type: _cartonCost.CartonCostType.Material
    });
  }

  if (moneyWaterProofFace) {
    costs.unshift({
      id: 0,
      name: "Ti\u1EC1n ch\u1ED1ng th\u1EA5m",
      minPrice: 0,
      price: moneyWaterProofFace,
      unitMinPrice: 0,
      totalPrice: moneyWaterProofFace,
      isEnable: true,
      type: _cartonCost.CartonCostType.Material
    });
  }

  if (moneyCartonE) {
    costs.unshift({
      id: 0,
      name: "Ti\u1EC1n s\xF3ng E",
      minPrice: 0,
      price: moneyCartonE,
      unitMinPrice: 0,
      totalPrice: moneyCartonE,
      isEnable: true,
      type: _cartonCost.CartonCostType.Material
    });
  }

  if (moneyKhuonBe) {
    costs.unshift({
      id: 0,
      name: "Ti\u1EC1n khu\xF4n b\u1EBF",
      minPrice: 0,
      price: moneyKhuonBe,
      unitMinPrice: 0,
      totalPrice: moneyKhuonBe,
      isEnable: true,
      type: _cartonCost.CartonCostType.Material
    });
  } //tính phí hao hụt
  // Nếu sl thùng <1000, là 20
  // <3000, 30
  // <5000, 50
  // <10.000, 100


  var haoHutRate = 10;
  var haoHutPhatSinh = 0;

  if (quantity > 1000) {
    haoHutRate = (quantity - 1000) / 1000 * 10;
  }

  haoHutRate += haoHutPhatSinh;
  var phiHaoHut1Tam = Math.ceil(acrTamNho * unitPriceGiay);
  console.log('phiHaoHut1Tam:', phiHaoHut1Tam);
  var phiHaoHut = Math.ceil(phiHaoHut1Tam * haoHutRate / quantity);
  console.log('phiHaoHut:', phiHaoHut);
  moneyFinal += phiHaoHut;
  console.log('moneyFinal 6:', moneyFinal);
  costs.unshift({
    id: 0,
    name: 'Hao hụt',
    minPrice: 0,
    price: phiHaoHut,
    isEnable: true,
    unitMinPrice: 0,
    totalPrice: phiHaoHut,
    type: _cartonCost.CartonCostType.Other
  }); //end - tính phí hao hụt
  //tính phí hao hụt (khi in)

  if (numColor && numOfPrint) {
    var phiHaoHutKhiIn = Math.ceil(phiHaoHut1Tam * ((numColor - 1) * 5 + 10) / quantity);
    moneyFinal += phiHaoHutKhiIn;
    console.log('moneyFinal 7:', moneyFinal);
    costs.unshift({
      id: 0,
      name: 'Hao hụt khi in',
      minPrice: 0,
      price: phiHaoHutKhiIn,
      isEnable: true,
      unitMinPrice: 0,
      totalPrice: phiHaoHutKhiIn,
      type: _cartonCost.CartonCostType.Other
    });
  } //
  //màng quấn PE


  if (isEnablePEPrice && pePrice) {
    var totalPEPice = pePrice * acr1Thung;
    costs.unshift({
      id: 0,
      quantity: acr1Thung,
      name: 'Quấn PE',
      minPrice: 0,
      price: pePrice,
      isEnable: true,
      unitMinPrice: 0,
      totalPrice: totalPEPice,
      type: _cartonCost.CartonCostType.Other
    });
    moneyFinal += totalPEPice;
  }

  costs.unshift({
    id: 0,
    name: 'Giấy nguyên liệu',
    quantity: +(totalPriceGiay / unitPriceGiay).toFixed(4),
    minPrice: 0,
    price: unitPriceGiay,
    isEnable: true,
    unitMinPrice: 0,
    totalPrice: totalPriceGiay,
    type: _cartonCost.CartonCostType.Material
  }); //! end - tính chi phí

  var profitRate = 0;

  if (isAutoProfit) {
    profitRate = getProfitPercent({
      quantity: quantity,
      moneyFinal: moneyFinal,
      numColor: numColor,
      quantityProfitConfig: quantityProfitConfig
    });
  } else {
    profitRate = profitPercent / 100;
  }

  console.log("profitPercent:", profitRate);
  var sellPrice = Math.ceil((moneyFinal + moneyPhuPhiBe) / (1 - profitRate));
  console.log('sellPrice:', {
    sellPrice: sellPrice,
    moneyPhuPhiBe: moneyPhuPhiBe
  });

  if (quantity < 1000) {
    sellPrice *= 1.03;
  }

  sellPrice = Math.ceil(sellPrice); //phí ship

  var shipFee = acr * cartonWave.shipFee;

  switch (distance) {
    case 40:
      shipFee *= 1.3;
      break;

    case 50:
      shipFee *= 1.6;
      break;

    case 60:
      shipFee *= 2;
      break;
  }

  var deliveryPrice = Math.ceil(sellPrice + shipFee);
  var delivery40Price = Math.ceil(sellPrice + shipFee * 1.3);
  var delivery50Price = Math.ceil(sellPrice + shipFee * 1.6);
  var delivery60Price = Math.ceil(sellPrice + shipFee * 2);
  shipFee = Math.ceil(shipFee);
  moneyFinal = Math.ceil(moneyFinal);
  var weight = +(cartonStruct.weight / 1000 * acr).toFixed(3); //trọng lượng 1 thùng

  var totalAcr = +(acr * quantity).toFixed(4);
  var totalWeight = +(weight * quantity).toFixed(3);
  var shipFeeCarton = shipFee;

  switch (distance) {
    case 40:
      shipFeeCarton *= 1.3;
      break;

    case 50:
      shipFeeCarton *= 1.6;
      break;

    case 60:
      shipFeeCarton *= 2;
      break;
  }

  shipFeeCarton = Math.ceil(shipFeeCarton);
  var totalShipFeeCarton = Math.ceil(shipFeeCarton * totalAcr); //thông tin đơn hàng

  var totalMoneySell = quantity * sellPrice;
  var totalMoneyDelivery = quantity * deliveryPrice;
  var totalCost = Math.round(moneyFinal * quantity);
  var totalProfit = Math.round(totalMoneySell - totalCost);
  var totalShipFee = shipFee * quantity;
  return {
    totalMoneySell: totalMoneySell,
    totalMoneyDelivery: totalMoneyDelivery,
    totalProfit: totalProfit,
    totalCost: totalCost,
    slThungTrenTamNho: slThungTrenTamNho,
    daiTamNho: daiTamNho,
    khoTamNho: khoTamNho,
    totalShipFeeCarton: totalShipFeeCarton,
    shipFeeCarton: shipFeeCarton,
    totalWeight: totalWeight,
    totalAcr: totalAcr,
    weight: weight,
    totalShipFee: totalShipFee,
    totalGiaCong: totalGiaCong,
    taiThung: taiThung,
    daiTam: daiTam,
    khoTam: khoTam,
    realKhoTam: realKhoTam,
    realDaiTam: realDaiTam,
    realQuantity: realQuantity,
    totalPriceKeo: totalPriceKeo,
    totalPriceDay: totalPriceDay,
    totalPriceGhim: totalPriceGhim,
    totalPriceIn: totalPriceIn,
    totalPriceGiay: totalPriceGiay,
    daiTamNCC: daiTamNCC,
    khoTamNCC: khoTamNCC,
    uocTinhDaiTam: uocTinhDaiTam,
    uocTinhKhoTam: uocTinhKhoTam,
    daiKhuonBe: daiKhuonBe,
    rongKhuonBe: rongKhuonBe,
    minQuantity: minQuantity,
    cubicMeter: cubicMeter,
    rateWidth: rateWidth,
    rateLength: rateLength,
    deliveryPrice: deliveryPrice,
    delivery40Price: delivery40Price,
    delivery50Price: delivery50Price,
    delivery60Price: delivery60Price,
    moneyFinal: moneyFinal,
    sellPrice: sellPrice,
    acr: acr,
    shipFee: shipFee,
    moneyPhuPhiBe: moneyPhuPhiBe,
    moneyPrint: moneyPrint,
    profitPercent: profitRate * 100,
    costs: costs,
    khoTam1Thung: khoTam1Thung,
    daiTam1Thung: daiTam1Thung,
    beMatrix: beMatrix,
    acr1Thung: acr1Thung,
    realAcr: realAcr
  };
};

exports.calc = calc;

var getProfitPercent = function getProfitPercent(_ref3) {
  var quantity = _ref3.quantity,
      numColor = _ref3.numColor,
      moneyFinal = _ref3.moneyFinal,
      quantityProfitConfig = _ref3.quantityProfitConfig;
  var percent = 0;

  var _iterator2 = (0, _createForOfIteratorHelper2.default)(quantityProfitConfig),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _config = _step2.value;

      if (moneyFinal * quantity >= _config.totalPrice) {
        if (numColor) {
          percent = _config.profitPrintPercent;
          break;
        } else {
          percent = _config.profitNoPrintPercent;
          break;
        }
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  if (percent == 0) {
    var config = quantityProfitConfig[quantityProfitConfig.length - 1];
    percent = numColor ? config.profitPrintPercent : config.profitNoPrintPercent;
  }

  return percent / 100;
};