"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới" : "Cập nhật",
      visible: _vm.visible,
      width: "600px",
      "append-to-body": "",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("span", {
    staticStyle: {
      display: "inline-block",
      padding: "4px",
      "border-radius": "4px"
    },
    style: {
      color: _vm.form.textColor ? _vm.form.textColor : "#000",
      background: _vm.form.bgColor
    }
  }, [_vm._v(_vm._s(_vm.form.title))])]), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiêu đề",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Màu chữ"
    }
  }, [_c("el-color-picker", {
    model: {
      value: _vm.form.textColor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "textColor", $$v);
      },
      expression: "form.textColor"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Màu nền"
    }
  }, [_c("el-color-picker", {
    model: {
      value: _vm.form.bgColor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bgColor", $$v);
      },
      expression: "form.bgColor"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Tin nhắn"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3
      }
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", [_vm.status == "create" ? _c("el-button", {
    staticClass: "font-bold font-size--medium",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]) : _vm._e()], 1), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Lưu")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;