"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scheduleProductionApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var scheduleProductionApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/admin/scheduleProduction',
      params: params
    });
  },
  findByQuoteId: function findByQuoteId(quoteId) {
    return (0, _request.default)({
      url: "/v1/admin/scheduleProduction/".concat(quoteId)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/v1/admin/scheduleProduction',
      data: data,
      method: 'post'
    });
  },
  delete: function _delete(quoteId) {
    return (0, _request.default)({
      url: "/v1/admin/scheduleProduction/".concat(quoteId),
      method: 'delete'
    });
  }
};
exports.scheduleProductionApi = scheduleProductionApi;