"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      "min-height": "100%",
      background: "white"
    }
  }, [true ? [false ? _c("div", [_c("el-radio", {
    attrs: {
      label: _vm.CustomerArea.Internal
    },
    model: {
      value: _vm.area,
      callback: function callback($$v) {
        _vm.area = $$v;
      },
      expression: "area"
    }
  }, [_vm._v("Nội thành HCM")]), _c("el-radio", {
    attrs: {
      label: _vm.CustomerArea.External
    },
    model: {
      value: _vm.area,
      callback: function callback($$v) {
        _vm.area = $$v;
      },
      expression: "area"
    }
  }, [_vm._v("Ngoại thành HCM")])], 1) : _vm._e(), _c("div", {
    staticClass: "form"
  }, [_vm.title ? _c("h2", {
    staticStyle: {
      "margin-top": "0",
      color: "#163237",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.description ? _c("span", [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _c("el-form", {
    ref: "form",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lớp",
      prop: "layer"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    on: {
      change: _vm.handleChangeLayer
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_c("el-option", {
    attrs: {
      label: "3 lớp",
      value: 3
    }
  }), _c("el-option", {
    attrs: {
      label: "5 lớp",
      value: 5
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Kết cấu giấy",
      prop: "cartonStruct"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      "value-key": "id"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.cartonStruct,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonStruct", $$v);
      },
      expression: "form.cartonStruct"
    }
  }, _vm._l(_vm.cartonStructsFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.description,
        value: item
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng",
      prop: "quantity"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số màu in (Nếu có)"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      disabled: _vm.isNotEnoughConditionPrint
    },
    on: {
      change: _vm.handleChangeNumColor
    },
    model: {
      value: _vm.form.numColor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "numColor", $$v);
      },
      expression: "form.numColor"
    }
  }, _vm._l(5, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: "".concat(item, " m\xE0u"),
        value: item
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài (cm)",
      prop: "length"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.length,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length", $$v);
      },
      expression: "form.length"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Rộng (cm)",
      prop: "width"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.width,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width", $$v);
      },
      expression: "form.width"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cao (cm)",
      prop: "height"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1)], 1), _vm.isSmallSize && _vm.form.quantity < 1000 ? _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("span", {
    staticStyle: {
      color: "#ff1515"
    }
  }, [_vm._v("Số lượt ít nhất là 1.000 thùng nếu là thùng trơn. Và 3.000 thùng nếu là thùng in")])]) : !_vm.isSmallSize && _vm.form.quantity < 500 ? _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("span", {
    staticStyle: {
      color: "#ff1515"
    }
  }, [_vm._v("Số lượng ít nhất là 500 thùng nếu là thùng trơn và 1.000 thùng nếu là thùng in")])]) : _vm._e()], 1), _c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá bán: "), _c("span", {
    staticStyle: {
      "font-size": "22px",
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.form.price && !_vm.isNotEnoughConditionSell ? _vm.$formatNumberVN(_vm.form.price) : "0") + "đ")])])], 1)], 1)] : _vm._e()], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;