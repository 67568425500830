"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;
var TokenKey = 'token';

function getToken() {
  return localStorage.getItem(TokenKey);
}

function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

function removeToken() {
  return localStorage.removeItem(TokenKey);
}