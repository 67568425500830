"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-switch", {
    staticStyle: {
      display: "block"
    },
    attrs: {
      value: _vm.form.isEnable,
      "active-color": "#13ce66",
      "inactive-color": "#ff4949",
      "active-text": "Kích hoạt"
    },
    on: {
      change: function change(val) {
        return _vm.onChangeForm("isEnable", val);
      }
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SP dài tối thiểu"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: !_vm.form.isEnable,
      value: _vm.form.minLength
    },
    on: {
      input: function input(val) {
        return _vm.onChangeForm("minLength", val);
      }
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SP rộng tối thiểu"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: !_vm.form.isEnable,
      value: _vm.form.minWidth
    },
    on: {
      input: function input(val) {
        return _vm.onChangeForm("minWidth", val);
      }
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SP cao tối thiểu"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: !_vm.form.isEnable,
      value: _vm.form.minHeight
    },
    on: {
      input: function input(val) {
        return _vm.onChangeForm("minHeight", val);
      }
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm tối thiểu"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: !_vm.form.isEnable,
      value: _vm.form.minKhoTam
    },
    on: {
      input: function input(val) {
        return _vm.onChangeForm("minKhoTam", val);
      }
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm tối thiểu"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.form.minDaiTam
    },
    on: {
      input: function input(val) {
        return _vm.onChangeForm("minDaiTam", val);
      }
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm tối đa"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: !_vm.form.isEnable,
      value: _vm.form.maxKhoTam
    },
    on: {
      input: function input(val) {
        return _vm.onChangeForm("maxKhoTam", val);
      }
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm tối đa"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: !_vm.form.isEnable,
      value: _vm.form.maxDaiTam
    },
    on: {
      input: function input(val) {
        return _vm.onChangeForm("maxDaiTam", val);
      }
    }
  })], 1)], 1), _vm.visibleSwap ? _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Đảo chiều khổ"
    }
  }, [_c("el-checkbox", {
    attrs: {
      disabled: !_vm.form.isEnable,
      value: _vm.form.isSwap
    },
    on: {
      change: function change(val) {
        return _vm.onChangeForm("visibleSwap", val);
      }
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;