"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      format: "dd/MM/yyyy",
      clearable: "",
      placeholder: "Chọn ngày"
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }), _c("br"), _c("el-input", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "Nhập mã đơn"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSyncOneOrder.apply(null, arguments);
      }
    },
    model: {
      value: _vm.orderId,
      callback: function callback($$v) {
        _vm.orderId = $$v;
      },
      expression: "orderId"
    }
  }), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary",
      loading: _vm.loadingSyncOneOrder
    },
    on: {
      click: _vm.handleSyncOneOrder
    }
  }, [_vm._v("Đồng bộ 1 đơn")])], 1), _c("el-button", {
    staticClass: "font-weight--medium font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-tickets",
      loading: _vm.loadingSyncOrder
    },
    on: {
      click: _vm.syncOrder
    }
  }, [_vm._v("Đồng độ nhiều đơn hàng")]), _c("el-button", {
    staticClass: "font-weight--medium font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-present",
      loading: _vm.loadingSyncProduct
    },
    on: {
      click: _vm.syncProduct
    }
  }, [_vm._v("Đồng độ sản phẩm")]), _c("el-button", {
    staticClass: "font-weight--medium font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-s-shop",
      loading: _vm.loadingSyncSupplier
    },
    on: {
      click: _vm.handleSyncSupplier
    }
  }, [_vm._v("Đồng độ NCC")]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("el-button", {
    staticClass: "font-weight--medium font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-unlock",
      loading: _vm.loadingAuth
    },
    on: {
      click: _vm.fetchLinkAuth
    }
  }, [_vm._v("Lấy link Auth Nhanh.vn")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;