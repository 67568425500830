"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm mã, lsx"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Bước")]), _c("el-select", {
    staticStyle: {
      width: "160px"
    },
    attrs: {
      filterable: "",
      clearable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.productionStepId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "productionStepId", $$v);
      },
      expression: "query.productionStepId"
    }
  }, _vm._l(_vm.productionSteps, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày")]), _c("el-date-picker", {
    attrs: {
      type: "date",
      clearable: false,
      "range-separator": "-",
      format: "dd/MM/yyyy",
      "start-placeholder": "",
      "end-placeholder": ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleReportDay
    }
  }, [_vm._v("Báo cáo ngày")])], 1), _vm.visibleInspec ? _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleScanQR
    }
  }, [_vm._v("Quét QR")])], 1) : _vm._e()]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.handlePrintAll
    }
  }, [_vm._v("In tất cả")]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      id: "production-report-print"
    }
  }, _vm._l(_vm.productionSteps, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        background: "#fff",
        padding: "12px",
        "border-radius": "5px",
        "margin-top": "24px"
      }
    }, [_c("ReportDayPrint", {
      ref: "report-day-print-".concat(item.id),
      refInFor: true,
      attrs: {
        productionStep: item,
        productionReports: _vm.getData(item),
        date: _vm.currentDateString,
        shouldPrint: _vm.checkShouldPrint(item.id),
        visiblePrint: true,
        visibleBe: item.name.toLowerCase().includes("in") || item.name.toLowerCase().includes("bế")
      },
      on: {
        print: _vm.handlePrintOne,
        viewDetail: _vm.handleViewDetail
      }
    })], 1);
  }), 0), _c("ReportDayModal", {
    ref: "ReportDayModal",
    attrs: {
      productionSteps: _vm.productionSteps
    }
  }), _c("ReportQuantityProductionModal", {
    ref: "ReportQuantityProductionModal",
    attrs: {
      visibleInspec: _vm.visibleInspec
    }
  }), _c("ReportQuantityScanModal", {
    ref: "ReportQuantityScanModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;