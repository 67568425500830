"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("el-checkbox", {
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isAvailable,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isAvailable", $$v);
      },
      expression: "query.isAvailable"
    }
  }, [_c("span", {
    staticStyle: {
      color: "red",
      "font-weight": "bold"
    }
  }, [_vm._v("Chưa có hàng")])])], 1)]), _c("el-collapse", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      accordion: ""
    },
    model: {
      value: _vm.actives,
      callback: function callback($$v) {
        _vm.actives = $$v;
      },
      expression: "actives"
    }
  }, _vm._l(_vm.sections, function (section) {
    return _c("el-collapse-item", {
      key: section.key,
      attrs: {
        title: section.label,
        name: section.key,
        disabled: ""
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("label", {
      staticStyle: {
        "font-size": "20px"
      },
      style: {
        color: section.color
      },
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(section.label) + " (" + _vm._s(section.data.length) + ") ")]), _c("span", {
      staticStyle: {
        color: "red",
        "margin-left": "12px"
      }
    }, [_vm._v("Màu đỏ: Chưa có hàng")]), _c("span", {
      staticStyle: {
        color: "#0b830b",
        "margin-left": "4px"
      }
    }, [_vm._v("Màu xanh: Đã đủ hàng")]), _c("el-button", {
      staticClass: "font-bold",
      staticStyle: {
        "margin-left": "12px"
      },
      attrs: {
        type: "primary",
        size: "mini",
        icon: "el-icon-refresh"
      },
      on: {
        click: function click($event) {
          return _vm.handleSort(section.data);
        }
      }
    }, [_vm._v("Sắp xếp")]), _c("el-button", {
      staticClass: "font-bold",
      staticStyle: {
        "margin-left": "12px"
      },
      attrs: {
        type: "primary",
        size: "mini",
        icon: "el-icon-document-copy"
      },
      on: {
        click: function click($event) {
          return _vm.handleCopySectionDate(section.data);
        }
      }
    }, [_vm._v("Copy")])], 1), _c("DeliveryGroupDateTable", {
      attrs: {
        data: section.data
      },
      on: {
        copy: function copy(_ref) {
          var type = _ref.type;
          return _vm.handleCopySingle({
            type: type,
            date: section.date
          });
        },
        updateNote: function updateNote(session) {
          return _vm.handleUpdateNote({
            session: session,
            date: section.date
          });
        }
      }
    })], 2);
  }), 1), _c("SortNeedDeliveryModal", {
    ref: "SortNeedDeliveryModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;