"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "pricing-table"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      size: "medium",
      placeholder: "Tìm tên sp"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Khách hàng")]), _c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.fetchCustomers,
      size: "medium"
    },
    on: {
      change: function change($event) {
        return _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.customerId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "customerId", $$v);
      },
      expression: "query.customerId"
    }
  }, _vm._l(_vm.customers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " - ").concat(item.phone),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm")])], 1)]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_vm.query.customerId ? _c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Lần cập nhật cuối: " + _vm._s(_vm.$formatDateTime(_vm.lastConfig.dateUpdated)) + " ")]) : _vm._e()]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "table",
    staticClass: "table-1",
    attrs: {
      data: _vm.data,
      height: _vm.heightTable ? _vm.heightTable : undefined,
      border: "",
      fit: "",
      "span-method": _vm.objectSpanMethod,
      "row-key": "id",
      "row-class-name": _vm.tableRowClassName
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Sản phẩm",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(" " + _vm._s(row.id < 0 ? row.productGroupName : row.name) + " ")])];
      }
    }])
  }), _vm._l(this.quantities, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        align: "center",
        width: "120",
        label: "".concat(item, " ").concat(_vm.firstProduct.unit)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var row = _ref2.row;
          return [_c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row["price".concat(item)])) + " đ")])];
        }
      }], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Giá bán lẻ",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.price)) + " đ ")];
      }
    }])
  })], 2)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;