"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "LSX, mã hàng hóa, mã khách hàng"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thẻ")]), _c("el-select", {
    attrs: {
      filterable: "",
      multiple: "",
      clearable: ""
    },
    on: {
      change: function change($event) {
        _vm.setQueryLocal();

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.misaTagIds,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "misaTagIds", $$v);
      },
      expression: "query.misaTagIds"
    }
  }, _vm._l(_vm.misaTags, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Ngày ")]), _c("el-date-picker", {
    attrs: {
      clearable: false,
      type: "daterange",
      format: "dd/MM/yyyy",
      "range-separator": "-",
      "start-placeholder": "",
      "end-placeholder": ""
    },
    on: {
      change: _vm.handleChangeDate
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm kiếm")])], 1), _vm.isAuth && _vm.mode == "full" ? _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-refresh",
      loading: _vm.loadingSync
    },
    on: {
      click: _vm.handleSync
    }
  }, [_vm._v("Đồng bộ misa")])], 1) : _vm._e()]), _c("div", [_c("el-checkbox", {
    staticStyle: {
      color: "red",
      "font-weight": "bold"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.onlyVietBox,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "onlyVietBox", $$v);
      },
      expression: "query.onlyVietBox"
    }
  }, [_vm._v("Chỉ hiển thị của VietBox")])], 1), _c("el-tabs", {
    attrs: {
      "tab-position": "top"
    },
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.summaryStatus, function (item) {
    return _c("el-tab-pane", {
      key: item.key,
      attrs: {
        label: item.label,
        name: item.key
      }
    }, [_c("span", {
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_vm._v(" " + _vm._s(item.label) + " "), _c("el-badge", {
      staticClass: "work-order-badge",
      class: item.status,
      attrs: {
        value: item.total
      }
    })], 1), _vm.isAuth && _vm.mode == "full" ? _c("el-dropdown", {
      attrs: {
        trigger: "click",
        type: "primary"
      },
      on: {
        command: _vm.handleCommand
      }
    }, [_c("el-button", {
      staticClass: "font-bold",
      staticStyle: {
        "margin-bottom": "12px"
      },
      attrs: {
        type: "primary",
        size: "small",
        disabled: _vm.selected.length == 0
      }
    }, [_vm._v("Thao tác hàng loạt "), _c("i", {
      staticClass: "el-icon-arrow-down el-icon--right"
    })]), _c("el-dropdown-menu", {
      attrs: {
        slot: "dropdown"
      },
      slot: "dropdown"
    }, _vm._l(_vm.WorkOrderProductionStatusTrans, function (item, index) {
      return _c("el-dropdown-item", {
        key: index,
        attrs: {
          command: "status:".concat(index)
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")]);
    }), 1)], 1) : _vm._e(), _vm.mode == "full" ? _c("WorkOrderTable", {
      attrs: {
        data: _vm.data,
        loading: _vm.loading,
        visibleAction: _vm.isAuth,
        visibleDelete: _vm.isAdmin
      },
      on: {
        update: _vm.handleUpdate,
        selectionChange: _vm.handleSelectionChange,
        note: _vm.handleNote,
        startProduction: _vm.handleStartProduction,
        delete: _vm.handleDelete,
        viewInventory: _vm.handleViewInventory,
        hiddenAttach: _vm.hiddenAttach
      }
    }) : _c("WorkOrderTableViewOnly", {
      attrs: {
        data: _vm.data,
        loading: _vm.loading
      }
    }), _c("pagination", {
      attrs: {
        total: _vm.total,
        page: _vm.query.page,
        limit: _vm.query.limit
      },
      on: {
        "update:page": function updatePage($event) {
          return _vm.$set(_vm.query, "page", $event);
        },
        "update:limit": function updateLimit($event) {
          return _vm.$set(_vm.query, "limit", $event);
        },
        pagination: _vm.fetchData
      }
    })], 1);
  }), 1), _c("WorkOrderModal", {
    ref: "WorkOrderModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("StartProductionModal", {
    ref: "StartProductionModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("WorkOrderNoteModal", {
    ref: "WorkOrderNoteModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ViewQRModal", {
    ref: "ViewQRModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;