"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "event-custom",
    class: {
      new: _vm.event.data.status == _vm.AppointmentStatus.New,
      confirmed: _vm.event.data.status == _vm.AppointmentStatus.Confirmed,
      arrived: _vm.event.data.status == _vm.AppointmentStatus.Arrived,
      started: _vm.event.data.status == _vm.AppointmentStatus.Started
    },
    on: {
      click: function click($event) {
        return _vm.$emit("event-click", _vm.event);
      }
    }
  }, [_c("div", {
    staticClass: "head-event"
  }, [_c("span", {
    staticClass: "time"
  }, [_vm._v(" " + _vm._s(_vm.event.start.formatTime("hh:mm")) + " - " + _vm._s(_vm.event.end.formatTime("hh:mm")) + " ")]), _vm.event.data.appointment.checkout ? _c("el-tooltip", {
    attrs: {
      content: "Invoice",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-tickets"
  })]) : _vm._e(), _c("el-tooltip", {
    attrs: {
      content: _vm.event.data.appointment.status,
      placement: "top",
      effect: "dark"
    }
  })], 1), _c("span", {
    staticClass: "customer-name"
  }, [_vm._v(" " + _vm._s(_vm.event.data.customer.firstname) + " " + _vm._s(_vm.event.data.customer.lastname) + " ")]), _c("p", {
    staticClass: "service-name"
  }, [_vm._v(_vm._s(_vm.event.data.serviceOption.service && _vm.event.data.serviceOption.service.name) + " " + _vm._s(_vm.event.data.serviceOption.priceName))]), _c("font-awesome-icon", {
    staticClass: "icon-resize",
    attrs: {
      icon: "arrows-alt-v"
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;