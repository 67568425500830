"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.concat.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_vm.isEdit ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-view"
    },
    on: {
      click: function click($event) {
        _vm.isEdit = false;
      }
    }
  }, [_vm._v(" Xem trước")]) : [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-view"
    },
    on: {
      click: function click($event) {
        _vm.isEdit = true;
      }
    }
  }, [_vm._v(" Hủy xem trước")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-printer"
    },
    on: {
      click: function click($event) {
        return _vm.handlePrint(true);
      }
    }
  }, [_vm._v(" In có VAT")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-printer"
    },
    on: {
      click: function click($event) {
        return _vm.handlePrint(false);
      }
    }
  }, [_vm._v(" In không VAT")])]], 2), _c("div", {
    attrs: {
      id: _vm.tabName
    }
  }, [_c("div", {
    attrs: {
      id: "quote-product-print"
    }
  }, [_c("div", {
    staticClass: "quote-product-print"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm.company.logo ? _c("div", {
    staticClass: "logo"
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: _vm.company.logo,
      alt: ""
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "company text-center"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.company.name))]), _c("br"), _c("i", [_vm._v(_vm._s(_vm.company.address))])])]), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày báo giá")]), !_vm.isEdit ? _c("span", [_vm._v(_vm._s(_vm.dateTrans))]) : _c("el-date-picker", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      type: "date",
      format: "dd/MM/yyyy"
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("div", {
    staticClass: "info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số báo giá")]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.numberQuote))]), _vm._v("- "), !_vm.isEdit ? _c("span", [_vm._v(_vm._s(_vm.no))]) : _c("el-input", {
    staticStyle: {
      width: "50px"
    },
    model: {
      value: _vm.no,
      callback: function callback($$v) {
        _vm.no = $$v;
      },
      expression: "no"
    }
  })], 1)]), _c("div", {
    staticClass: "info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Hiệu lực đến")]), _c("span", [_vm._v(_vm._s(_vm.expireDay))])])])]), _c("div", {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Điện thoại: ")]), _c("span", [_vm._v(_vm._s(_vm.company.phone))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Email: ")]), _c("span", [_vm._v(_vm._s(_vm.company.email))])]), _c("h3", {
    staticClass: "text-center header-document"
  }, [_vm._v(" TÀI LIỆU BÁO GIÁ ")]), _c("div", [_c("div", {
    staticClass: "customer-info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tên khách hàng: ")]), !_vm.isEdit ? _c("span", {
    staticClass: "name",
    staticStyle: {
      "line-height": "20px"
    }
  }, [_vm._v(_vm._s(_vm.customer.name))]) : _c("el-input", {
    staticClass: "input-inline",
    model: {
      value: _vm.customer.name,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "name", $$v);
      },
      expression: "customer.name"
    }
  })], 1), _c("div", {
    staticClass: "customer-info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Công ty: ")]), !_vm.isEdit ? _c("span", {
    staticClass: "name",
    staticStyle: {
      "line-height": "20px"
    }
  }, [_vm._v(_vm._s(_vm.customer.company))]) : _c("el-input", {
    staticClass: "input-inline",
    model: {
      value: _vm.customer.company,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "company", $$v);
      },
      expression: "customer.company"
    }
  })], 1), _c("div", {
    staticClass: "customer-info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Địa chỉ: ")]), !_vm.isEdit ? _c("span", {
    staticStyle: {
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.customer.address))]) : _c("el-input", {
    staticClass: "input-inline",
    model: {
      value: _vm.customer.address,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "address", $$v);
      },
      expression: "customer.address"
    }
  })], 1), _c("div", {
    staticClass: "customer-info"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Điện thoại: ")]), !_vm.isEdit ? _c("span", [_vm._v(_vm._s(_vm.customer.phone))]) : _c("el-select", {
    staticStyle: {
      width: "200px",
      display: "inline-block"
    },
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      "allow-create": "",
      "default-first-option": "",
      "remote-method": _vm.fetchCustomers
    },
    on: {
      change: _vm.handleChangePhone
    },
    model: {
      value: _vm.customerId,
      callback: function callback($$v) {
        _vm.customerId = $$v;
      },
      expression: "customerId"
    }
  }, _vm._l(_vm.customers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " - ").concat(item.phone),
        value: item.id
      }
    });
  }), 1)], 1)]), _c("div", {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_vm.isEdit ? _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAddProd
    }
  }, [_vm._v("Thêm sản phẩm")]) : _vm._e(), _c("table", [_vm._m(0), _c("tbody", [_vm._l(_vm.products, function (item, index) {
    return _c("tr", {
      key: index
    }, [_c("td", {
      staticClass: "text-center"
    }, [_c("span", [_vm._v(_vm._s(index + 1))]), _vm.isEdit ? _c("i", {
      staticClass: "el-icon-close delete-btn",
      on: {
        click: function click($event) {
          return _vm.handleDeleteProd(index);
        }
      }
    }) : _vm._e()]), _c("td", [!_vm.isEdit ? _c("span", [_vm._v(_vm._s(item.name))]) : _c("el-select", {
      attrs: {
        "remote-method": _vm.fetchProducts,
        filterable: "",
        remote: "",
        "allow-create": "",
        "default-first-option": ""
      },
      on: {
        change: function change(val) {
          return _vm.handleChangeProd(val, item);
        }
      },
      model: {
        value: item.productItem,
        callback: function callback($$v) {
          _vm.$set(item, "productItem", $$v);
        },
        expression: "item.productItem"
      }
    }, _vm._l(_vm.dataProducts, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item.name,
          value: item.id
        }
      });
    }), 1)], 1), _c("td", {
      staticClass: "text-center"
    }, [!_vm.isEdit ? _c("span", [_vm._v(_vm._s(item.unit))]) : _c("el-input", {
      model: {
        value: item.unit,
        callback: function callback($$v) {
          _vm.$set(item, "unit", $$v);
        },
        expression: "item.unit"
      }
    })], 1), _c("td", {
      staticClass: "text-center"
    }, [!_vm.isEdit ? _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(item.quantity, 0, ".")))]) : _c("el-input", {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: {
          alias: "decimal",
          groupSeparator: ".",
          rightAlign: false,
          autoGroup: true,
          repeat: 20,
          autoUnmask: true,
          allowMinus: false
        },
        expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
      }],
      model: {
        value: item.quantity,
        callback: function callback($$v) {
          _vm.$set(item, "quantity", $$v);
        },
        expression: "item.quantity"
      }
    })], 1), _c("td", {
      staticClass: "text-center"
    }, [!_vm.isEdit ? _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(item.price, 0, ".")) + "đ")]) : _c("el-input", {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: {
          alias: "decimal",
          groupSeparator: ".",
          rightAlign: false,
          autoGroup: true,
          repeat: 20,
          autoUnmask: true,
          allowMinus: false
        },
        expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
      }],
      model: {
        value: item.price,
        callback: function callback($$v) {
          _vm.$set(item, "price", $$v);
        },
        expression: "item.price"
      }
    })], 1), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(Math.round(item.quantity * item.price))) + "đ")])]);
  }), _c("tr", {
    staticClass: "footer"
  }, [_c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("Cộng tiền hàng")]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalProductPrice, 0, ".")) + "đ")])]), _vm.isVat ? [_c("tr", {
    staticClass: "footer"
  }, [_c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("Thuế giá trị gia tăng(8%)")]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalProductPrice * 0.08, 0, ".")) + "đ")])]), _c("tr", {
    staticClass: "footer"
  }, [_vm._m(1), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalProductPrice * 1.08, 0, ".")) + "đ")])])] : [_c("tr", {
    staticClass: "footer"
  }, [_vm._m(2), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalProductPrice, 0, ".")) + "đ")])])]], 2)])], 1), _c("div", {
    staticClass: "note",
    staticStyle: {
      "font-weight": "bold",
      "margin-top": "32px",
      "text-decoration": "underline"
    }
  }, [_vm._v("Ghi chú:")]), !_vm.isEdit ? _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.noteComputed)
    }
  }) : [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "8px",
      "margin-bottom": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      loading: _vm.loadingSaveConfig
    },
    on: {
      click: _vm.handleSaveConfig
    }
  }, [_vm._v("Lưu ghi chú")]), _c("tinymce", {
    ref: "editor",
    attrs: {
      visibleUpload: false,
      height: 150
    },
    on: {
      onInit: _vm.handleInit
    },
    model: {
      value: _vm.notePrintConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.notePrintConfig, "value", $$v);
      },
      expression: "notePrintConfig.value"
    }
  })], _c("div", {
    staticClass: "sign",
    staticStyle: {
      display: "flex",
      "justify-content": "space-around",
      "margin-top": "32px"
    }
  }, [_vm._m(3), _c("div", {
    staticClass: "right text-center"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người biên soạn")]), _c("br"), _c("span", [_vm._v("(Ký và ghi rõ họ tên)")]), _c("div", {
    staticStyle: {
      height: "150px"
    }
  }), _vm.isEdit ? _c("el-input", {
    attrs: {
      value: _vm.company.composer
    },
    on: {
      input: _vm.handleChangeComposer
    }
  }) : _c("span", [_vm._v(_vm._s(_vm.company.composer))])], 1)])], 2)])])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("thead", [_c("tr", [_c("th", {
    staticStyle: {
      width: "60px"
    }
  }, [_vm._v("STT")]), _c("th", {
    staticClass: "text-left",
    staticStyle: {
      width: "250px"
    }
  }, [_vm._v("Sản phẩm")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("Đơn vị")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("SL")]), _c("th", {
    staticStyle: {
      width: "120px"
    }
  }, [_vm._v("Đơn giá")]), _c("th", {
    staticClass: "text-right",
    staticStyle: {
      width: "100px"
    }
  }, [_vm._v("Thành tiền")])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền thanh toán")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("td", {
    staticClass: "text-right",
    attrs: {
      colspan: "5"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền thanh toán")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "left text-center"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }), _c("br"), _c("span"), _c("div", {
    staticStyle: {
      height: "150px"
    }
  }), _c("span"), _c("br"), _c("span")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;