"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 14,
      offset: 0
    }
  }, [_c("section", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticClass: "panel-shadow"
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v(" Thông tin khách hàng ")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên khách hàng"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: "",
      disabled: ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SĐT"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: "",
      disabled: ""
    },
    model: {
      value: _vm.form.tel,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tel", $$v);
      },
      expression: "form.tel"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Địa chỉ"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: "",
      disabled: ""
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số ngày chưa mua hàng"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: "",
      disabled: ""
    },
    model: {
      value: _vm.form.dayWithoutPurchase,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dayWithoutPurchase", $$v);
      },
      expression: "form.dayWithoutPurchase"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Lần cuối mua"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.$stringToDate(_vm.form.purchaseRecentDate),
      readonly: "",
      disabled: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Chu kỳ mua (Đồng bộ)"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.form.avgDayBetweenPurchase,
      readonly: "",
      disabled: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Chu kỳ mua (tự set)"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.purchaseDay,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "purchaseDay", $$v);
      },
      expression: "form.purchaseDay"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nhóm khách hàng"
    }
  }, [_c("el-select", {
    attrs: {
      "value-key": "",
      placeholder: "",
      clearable: "",
      filterable: ""
    },
    on: {
      change: function change($event) {}
    },
    model: {
      value: _vm.form.misaCustomerGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaCustomerGroupId", $$v);
      },
      expression: "form.misaCustomerGroupId"
    }
  }, _vm._l(_vm.misaCustomerGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Chặn khách"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ff4949"
    },
    on: {
      change: _vm.handleSave
    },
    model: {
      value: _vm.form.isBlocked,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isBlocked", $$v);
      },
      expression: "form.isBlocked"
    }
  })], 1)], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-s-promotion",
      loading: _vm.loadingSave
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("Lưu")])], 1)]), _c("section", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticClass: "panel-shadow"
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v(" Ghi chú ")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "mini"
    },
    on: {
      click: _vm.handleCreateNote
    }
  }, [_vm._v("Tạo ghi chú")]), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, _vm._l(_vm.misaNotes, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "12px"
      }
    }, [_c("MisaNoteItem", {
      attrs: {
        misaNote: item
      }
    })], 1);
  }), 0)], 1)])]), _c("el-col", {
    attrs: {
      span: 10,
      offset: 0
    }
  }, [_c("section", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticClass: "panel-shadow"
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v(" Nhiệm vụ ")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "mini"
    },
    on: {
      click: _vm.handleCreateActivity
    }
  }, [_vm._v("Tạo nhiệm vụ")]), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, _vm._l(_vm.misaActivities, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "12px"
      }
    }, [_c("MisaActivityItem", {
      attrs: {
        misaActivity: item
      },
      on: {
        complete: _vm.handleCompleteActivity
      }
    })], 1);
  }), 0)], 1)])])], 1)], 1), _c("CreateMisaActivityCustomer", {
    ref: "CreateMisaActivityCustomer",
    on: {
      submitOk: _vm.fetchMisaActivities
    }
  }), _c("CreateMisaNote", {
    ref: "CreateMisaNote",
    on: {
      submitOk: _vm.fetchMisaNotes
    }
  }), _c("CompleteMisaActivityModal", {
    ref: "CompleteMisaActivityModal",
    on: {
      submitOk: _vm.handleCompleteOk
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;