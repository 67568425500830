"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    style: {
      display: _vm.isVisible ? "block" : "none"
    }
  }, [_vm.deliveryTrip ? _c("div", {
    ref: "container",
    attrs: {
      id: "ship-form-print"
    }
  }, [_c("div", {
    ref: "content",
    staticClass: "content"
  }, [_c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người gửi: ")]), _c("div", [_vm._v(_vm._s(_vm.deliveryTrip.senderInfo))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người nhận: ")]), _c("div", [_vm._v(_vm._s(_vm.deliveryTrip.receiverInfo))])]), _c("div", {
    staticStyle: {
      width: "100%",
      height: "1px",
      background: "#000",
      "margin-top": "16px"
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người gửi: ")]), _c("div", [_vm._v(_vm._s(_vm.deliveryTrip.senderInfo))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người nhận: ")]), _c("div", [_vm._v(_vm._s(_vm.deliveryTrip.receiverInfo))])]), _c("div", {
    staticStyle: {
      width: "100%",
      height: "1px",
      background: "#000",
      "margin-top": "16px"
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người gửi: ")]), _c("div", [_vm._v(_vm._s(_vm.deliveryTrip.senderInfo))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người nhận: ")]), _c("div", [_vm._v(_vm._s(_vm.deliveryTrip.receiverInfo))])]), _c("div", {
    staticStyle: {
      width: "100%",
      height: "1px",
      background: "#000",
      "margin-top": "16px"
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người gửi: ")]), _c("div", [_vm._v(_vm._s(_vm.deliveryTrip.senderInfo))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người nhận: ")]), _c("div", [_vm._v(_vm._s(_vm.deliveryTrip.receiverInfo))])])])]) : _vm._e()]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;