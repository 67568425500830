"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MisaCrawlLogTypeTrans = exports.MisaCrawlLogType = exports.MisaCrawLogSource = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _MisaCrawlLogTypeTran;

var MisaCrawlLogType;
exports.MisaCrawlLogType = MisaCrawlLogType;

(function (MisaCrawlLogType) {
  MisaCrawlLogType["Inventory"] = "INVENTORY";
  MisaCrawlLogType["PO"] = "PO";
  MisaCrawlLogType["SO"] = "SO";
})(MisaCrawlLogType || (exports.MisaCrawlLogType = MisaCrawlLogType = {}));

var MisaCrawLogSource;
exports.MisaCrawLogSource = MisaCrawLogSource;

(function (MisaCrawLogSource) {
  MisaCrawLogSource["Admin"] = "ADMIN";
  MisaCrawLogSource["Extension"] = "EXTENSION";
})(MisaCrawLogSource || (exports.MisaCrawLogSource = MisaCrawLogSource = {}));

var MisaCrawlLogTypeTrans = (_MisaCrawlLogTypeTran = {}, (0, _defineProperty2.default)(_MisaCrawlLogTypeTran, MisaCrawlLogType.Inventory, 'Kho hàng'), (0, _defineProperty2.default)(_MisaCrawlLogTypeTran, MisaCrawlLogType.PO, 'PO'), (0, _defineProperty2.default)(_MisaCrawlLogTypeTran, MisaCrawlLogType.SO, 'Đơn hàng'), _MisaCrawlLogTypeTran);
exports.MisaCrawlLogTypeTrans = MisaCrawlLogTypeTrans;