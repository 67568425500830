"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Trạng thái ")]), _c("el-select", {
    attrs: {
      clearable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.vatStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "vatStatus", $$v);
      },
      expression: "query.vatStatus"
    }
  }, _vm._l(_vm.VatStatusTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      position: "relative",
      top: "4px"
    },
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Tạo đơn hàng")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "#",
      width: "105"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("label", [_vm._v(_vm._s(row.id))]), _c("br"), _c("span", [_vm._v(_vm._s(row.createdDateTime))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(row.customerPhone) + " ")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.customerName))]), _c("hr"), _c("section", [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tên công ty: ")]), _c("span", [_vm._v(_vm._s(row.companyName))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Địa chỉ: ")]), _c("span", [_vm._v(_vm._s(row.companyAddress))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("MST: ")]), _c("span", [_vm._v(_vm._s(row.companyTaxCode))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Email: ")]), _c("span", [_vm._v(_vm._s(row.companyEmail))])])]), _c("el-button", {
          attrs: {
            type: "warning",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sản phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return _vm._l(row.details, function (item, index) {
          return _c("div", {
            key: index,
            staticClass: "product-grid",
            class: {
              "not-last": index != row.details.length - 1
            }
          }, [_c("label", [_vm._v(" " + _vm._s(item.product.name) + " ")]), _c("span", [item.product.unit ? _c("span", [_vm._v("(" + _vm._s(item.product.unit) + ")")]) : _vm._e()]), _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.price)) + " ")]), _c("span", {
            staticStyle: {
              "text-align": "right"
            }
          }, [_vm._v(" x" + _vm._s(item.quantity) + " ")])]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị",
      width: "90",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amount)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("el-tag", {
          attrs: {
            effect: "dark",
            type: _vm.generateVatStatusClass(row)
          }
        }, [_vm._v(" " + _vm._s(_vm.VatStatusTrans[row.vatStatus]) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày tạo",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("span", [_vm._v(_vm._s(_vm.$formatDateTime(row.dateCreated)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "align-center": "center",
            "flex-direction": "column"
          }
        }, [_c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            width: "90px"
          },
          attrs: {
            type: "primary",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleExport(row);
            }
          }
        }, [_vm._v("Xuất VAT")]), _c("el-button", {
          staticClass: "font-bold",
          staticStyle: {
            width: "90px",
            "margin-top": "4px",
            "margin-left": "0"
          },
          attrs: {
            type: "danger",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v("Xoá")])], 1)];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("other-order-modal", {
    ref: "OtherOrderModal",
    on: {
      "submit:ok": _vm.fetchData
    }
  }), _c("export-vat-modal", {
    ref: "ExportVatModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;