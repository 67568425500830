"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "work-order-page"
  }, [_vm.loading ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mask-loading"
  }) : _c("div", [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingCalendar,
      expression: "loadingCalendar"
    }],
    staticStyle: {
      background: "white"
    }
  }, [_c("calendar-component", {
    ref: "CalendarComponent",
    attrs: {
      startHour: 6,
      endHour: 22,
      dateSelected: _vm.date,
      events: _vm.events,
      viewMode: _vm.viewMode,
      timeCellHeight: 40,
      timeStep: 30,
      snapTime: 10,
      shouldUseOneDayMode: false,
      splitDays: _vm.splitDays
    },
    on: {
      onDrop: _vm.handleDropEvent,
      create: _vm.handleCreateEvent,
      eventDurationChange: _vm.handleResize
    },
    scopedSlots: _vm._u([{
      key: "event",
      fn: function fn(_ref) {
        var event = _ref.event;
        return [_c("WorkOrderEventItem", {
          attrs: {
            visibleSort: true,
            onSort: _vm.handleSort,
            onCheck: _vm.handleCheck,
            workOrder: event.data
          }
        }, _vm._l(event.data.children, function (item) {
          return _c("WorkOrderEventItem", {
            key: item.id,
            attrs: {
              onClickItem: _vm.handleViewDetail,
              onCheck: _vm.handleCheck,
              workOrder: item,
              visibleCheckInventory: true,
              onCheckInventory: _vm.handleCheckInventory
            }
          });
        }), 1)];
      }
    }])
  })], 1)]), _c("WorkOrderModal", {
    ref: "WorkOrderModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("SortWorkOrderModal", {
    ref: "SortWorkOrderModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;