"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "day-header",
    class: {
      active: _vm.isActive
    }
  }, [_c("span", {
    staticClass: "day"
  }, [_vm._v(_vm._s(_vm.split.day))]), _c("span", {}, [_vm._v(_vm._s(_vm.split.label))])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;