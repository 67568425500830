"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container task"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_vm._l(_vm.tabs, function (item) {
    return _c("el-tab-pane", {
      key: item.class,
      attrs: {
        label: item.label,
        name: item.class
      }
    }, [_c("span", {
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_vm._v(" " + _vm._s(item.label) + " "), _c("el-badge", {
      staticClass: "task-badge-status",
      class: item.class,
      attrs: {
        value: item.total
      }
    })], 1), _vm.isInit ? _c("TaskList", {
      attrs: {
        isGroup: item.isGroup,
        className: item.class,
        productCategoryCode: item.productCategoryCode,
        staffs: _vm.staffs,
        taskFollowStatus: item.taskFollowStatus,
        isSelected: item.class == _vm.tabName,
        sortType: "amount",
        visibleProfit: false,
        loadingLimitTask: _vm.loadingLimitTask,
        limitTask: _vm.limitTask
      },
      on: {
        "fetch:ok": _vm.handleFetchData,
        saveLimitTask: _vm.saveLimitTask,
        changeLimitTask: _vm.handleLimitTask,
        changeBuyType: _vm.handleChangeBuyType,
        "change-staff": _vm.handleChangeStaff,
        "change-has-vat": _vm.handleChangeVat
      }
    }) : _vm._e()], 1);
  }), _c("el-tab-pane", {
    attrs: {
      label: "Chăm sóc hôm nay",
      name: "need-care"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Chăm sóc hôm nay "), _c("el-badge", {
    staticClass: "task-badge-status",
    class: "need-care",
    attrs: {
      value: _vm.totalNeedCare
    }
  })], 1), _vm.isInit ? _c("NeedCareTaskList", {
    attrs: {
      className: "need-care",
      staffs: _vm.staffs,
      isSelected: "need-care" == _vm.tabName,
      isNeedCare: true
    },
    on: {
      "fetch:ok": _vm.handleFetchedNeedCare,
      changeBuyType: _vm.handleChangeBuyType,
      "change-staff": _vm.handleChangeStaff
    }
  }) : _vm._e()], 1), _c("el-tab-pane", {
    attrs: {
      label: "Khách tự do",
      name: "no-seller"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Khách tự do "), _c("el-badge", {
    staticClass: "task-badge-status",
    class: "no-seller",
    attrs: {
      value: _vm.totalNoSeller
    }
  })], 1), _vm.isInit ? _c("NeedCareTaskList", {
    attrs: {
      className: "no-seller",
      staffs: _vm.staffs,
      isSelected: "no-seller" == _vm.tabName,
      isNoSeller: true,
      visibleChangeSeller: true
    },
    on: {
      "fetch:ok": _vm.handleFetchedNoSeller,
      changeBuyType: _vm.handleChangeBuyType,
      "change-staff": _vm.handleChangeStaff
    }
  }) : _vm._e()], 1), _c("el-tab-pane", {
    attrs: {
      label: "Cấu hình nội dung",
      name: "config"
    }
  }, [_c("ChatContentList")], 1)], 2)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;