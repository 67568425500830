"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userStore = exports.treatmentStore = exports.serviceCategoryStore = exports.merchantStore = exports.merchantLocationStore = exports.employeeStore = exports.clientStore = exports.bookingStore = void 0;

var _vuexModuleDecorators = require("vuex-module-decorators");

var _employee = _interopRequireDefault(require("./modules/employee"));

var _index = _interopRequireDefault(require("./index"));

var _serviceCategory = _interopRequireDefault(require("./modules/serviceCategory"));

var _treatment = _interopRequireDefault(require("./modules/treatment"));

var _client = _interopRequireDefault(require("./modules/client"));

var _merchantLocation = _interopRequireDefault(require("./modules/merchantLocation"));

var _user = _interopRequireDefault(require("./modules/user"));

var _booking = _interopRequireDefault(require("./modules/booking"));

var _merchant = _interopRequireDefault(require("./modules/merchant"));

// import PermissionStore from "./modules/permission";
var employeeStore = (0, _vuexModuleDecorators.getModule)(_employee.default, _index.default);
exports.employeeStore = employeeStore;
var serviceCategoryStore = (0, _vuexModuleDecorators.getModule)(_serviceCategory.default, _index.default);
exports.serviceCategoryStore = serviceCategoryStore;
var treatmentStore = (0, _vuexModuleDecorators.getModule)(_treatment.default, _index.default);
exports.treatmentStore = treatmentStore;
var clientStore = (0, _vuexModuleDecorators.getModule)(_client.default, _index.default);
exports.clientStore = clientStore;
var merchantLocationStore = (0, _vuexModuleDecorators.getModule)(_merchantLocation.default, _index.default);
exports.merchantLocationStore = merchantLocationStore;
var userStore = (0, _vuexModuleDecorators.getModule)(_user.default, _index.default); // export const permissionStore = getModule(PermissionStore, store);

exports.userStore = userStore;
var bookingStore = (0, _vuexModuleDecorators.getModule)(_booking.default, _index.default);
exports.bookingStore = bookingStore;
var merchantStore = (0, _vuexModuleDecorators.getModule)(_merchant.default, _index.default);
exports.merchantStore = merchantStore;