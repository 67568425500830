"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportExcel = void 0;

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));

var _Export2Excel = require("@/vendor/Export2Excel");

var exportExcel = function exportExcel(_ref) {
  var rows = _ref.rows,
      filename = _ref.filename;
  var header = ["Xưng hô", "Họ và đệm", "Tên (*)", "Phòng ban", "Chức danh", "ĐT di động", "ĐT cơ quan", "ĐT khác", "Loại tiềm năng", "Nguồn gốc", "Không gọi điện", "Không gửi Email", "Email cá nhân", "Zalo", "Tổ chức", "Email cơ quan", "Mã số thuế", "Giới tính", "Ngày sinh", "Facebook", "Tài khoản ngân hàng", "Mở tại ngân hàng", "Ngày thành lập", "Loại hình", "Lĩnh vực", "Ngành nghề", "Doanh thu", "Quy mô nhân sự", "Website", "Quốc gia", "Tỉnh/Thành phố", "Quận/Huyện", "Phường/Xã", "Số nhà, Đường phố", "Mã vùng", "Địa chỉ", "Mô tả", "Dùng chung", "Ngừng theo dõi", "Mã tiềm năng (*)"];
  var data = [];

  var _iterator = (0, _createForOfIteratorHelper2.default)(rows),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      data.push([item.xungHo, item.firstName, item.lastName, "", "", item.phoneDiDong, item.phoneTel, "", "", "", "", "", item.privateEmail, "", item.companyName, item.publicEmail, item.taxCode, item.xungHo == "Anh" ? "Nam" : "Nữ", "", "", "", "", item.foundedYear, item.companyType, "", item.category, "", item.employees, item.website, item.country, item.city, item.district, item.ward, item.street, "", item.address, item.tel, "", "", ""]);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  (0, _Export2Excel.export_json_to_excel)({
    header: header,
    filename: filename,
    data: data
  });
};

exports.exportExcel = exportExcel;