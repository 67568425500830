"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", [_c("label", {
    staticStyle: {
      "font-size": "18px",
      color: "red"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Lọc mua hàng")]), _c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đã mua danh mục")]), _c("el-select", {
    staticStyle: {
      width: "350px"
    },
    attrs: {
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.query.fromCateCodes,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "fromCateCodes", $$v);
      },
      expression: "query.fromCateCodes"
    }
  }, _vm._l(_vm.filterFromCategories, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item",
    staticStyle: {
      "margin-left": "24px",
      "margin-right": "24px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tổng tiền danh mục đã mua >= ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.query.totalSale,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "totalSale", $$v);
      },
      expression: "query.totalSale"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Không chưa danh mục")]), _c("el-select", {
    staticStyle: {
      width: "350px"
    },
    attrs: {
      multiple: "",
      clearable: "",
      disabled: _vm.query.fromCateCodes.length == 0
    },
    model: {
      value: _vm.query.toCateCodes,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "toCateCodes", $$v);
      },
      expression: "query.toCateCodes"
    }
  }, _vm._l(_vm.filterCategories, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1)]), _c("div", {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleSaveFilter
    }
  }, [_vm._v("Thêm bộ lọc")]), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click() {
        return _vm.visibleFilter = true;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-setting"
  }), _vm._v(" Chọn bộ lọc")])], 1)]), _c("label", {
    staticStyle: {
      "font-size": "18px",
      color: "red"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm tên, sđt"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhân viên")]), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: ""
    },
    model: {
      value: _vm.query.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "staffId", $$v);
      },
      expression: "query.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày mua hàng")]), _c("el-select", {
    attrs: {
      clearable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.buyType,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "buyType", $$v);
      },
      expression: "query.buyType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Sắp mua",
      value: "SOON_BUY"
    }
  }), _c("el-option", {
    attrs: {
      label: "Lâu chưa mua",
      value: "LONG_BUY"
    }
  })], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loading,
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v(" Tìm kiếm")])], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  })], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("CustomerSaleMixTable", {
    attrs: {
      customers: _vm.data,
      onViewCustomer: _vm.handleViewCustomer,
      visibleProfit: false
    }
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("CustomerModal", {
    ref: "CustomerModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("SavedFilterDrawer", {
    attrs: {
      visible: _vm.visibleFilter,
      selectedId: _vm.selectedFilterId,
      filters: _vm.localFilters
    },
    on: {
      close: _vm.handleCloseFilter,
      "update:visible": function updateVisible($event) {
        _vm.visibleFilter = $event;
      },
      select: _vm.handleSelectFilter,
      remove: _vm.handleRemoveFilter,
      delete: _vm.handleDeleteFilter
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;