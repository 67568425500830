"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaQuoteApi = void 0;

var _request = require("@/utils/request");

var misaQuoteApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaQuote',
      params: params
    });
  },
  findTop: function findTop(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaQuote/top',
      params: params
    });
  },
  syncStatus: function syncStatus() {
    return (0, _request.request)({
      url: '/v1/admin/misaQuote/sync/status'
    });
  },
  getTotal: function getTotal(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaQuote/total',
      params: params
    });
  },
  sync: function sync() {
    return (0, _request.request)({
      url: '/v1/admin/misaQuote/sync',
      method: 'post'
    });
  }
};
exports.misaQuoteApi = misaQuoteApi;