"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WorkOrderInventoryTypeTrans = exports.WorkOrderInventoryType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _WorkOrderInventoryTy;

var WorkOrderInventoryType;
exports.WorkOrderInventoryType = WorkOrderInventoryType;

(function (WorkOrderInventoryType) {
  WorkOrderInventoryType["Pallet"] = "PALLET";
  WorkOrderInventoryType["CotBo"] = "COT_BO";
})(WorkOrderInventoryType || (exports.WorkOrderInventoryType = WorkOrderInventoryType = {}));

var WorkOrderInventoryTypeTrans = (_WorkOrderInventoryTy = {}, (0, _defineProperty2.default)(_WorkOrderInventoryTy, WorkOrderInventoryType.Pallet, 'Pallet'), (0, _defineProperty2.default)(_WorkOrderInventoryTy, WorkOrderInventoryType.CotBo, 'Cột bó'), _WorkOrderInventoryTy);
exports.WorkOrderInventoryTypeTrans = WorkOrderInventoryTypeTrans;