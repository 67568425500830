"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    class: "container ".concat(_vm.containerClass)
  }, _vm._l(_vm.vehicles, function (item, index) {
    return _c("vehicle-item", {
      key: index,
      attrs: {
        vehicle: item,
        productTable: _vm.productTable,
        totalVolumeReal: _vm.totalVolumeReal,
        totalWeight: _vm.totalWeight,
        totalWeightReal: _vm.totalWeightReal
      }
    });
  }), 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;