"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.waterProofFaces = exports.productGroupQuantities = exports.WaterProofFaceTypeTrans = exports.WaterProofFaceType = exports.ProductCategoryType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _WaterProofFaceTypeTr;

var productGroupQuantities = {
  BK: [10, 20, 60, 100],
  TCTL: [10, 50, 100, 300, 500, 1000],
  TCTN: [50, 100, 300, 500, 1000],
  MPE: [1, 4, 6, 24, 48, 96, 192, 384],
  MXH: [1, 5, 10, 20, 27, 44]
};
exports.productGroupQuantities = productGroupQuantities;
var WaterProofFaceType;
exports.WaterProofFaceType = WaterProofFaceType;

(function (WaterProofFaceType) {
  WaterProofFaceType["Inner"] = "INNER";
  WaterProofFaceType["Outer"] = "OUTER";
  WaterProofFaceType["Both"] = "BOTH"; //2 mặt
})(WaterProofFaceType || (exports.WaterProofFaceType = WaterProofFaceType = {}));

var WaterProofFaceTypeTrans = (_WaterProofFaceTypeTr = {}, (0, _defineProperty2.default)(_WaterProofFaceTypeTr, WaterProofFaceType.Inner, 'Mặt trong'), (0, _defineProperty2.default)(_WaterProofFaceTypeTr, WaterProofFaceType.Outer, 'Mặt ngoài'), (0, _defineProperty2.default)(_WaterProofFaceTypeTr, WaterProofFaceType.Both, 'Hai mặt'), _WaterProofFaceTypeTr);
exports.WaterProofFaceTypeTrans = WaterProofFaceTypeTrans;
var ProductCategoryType;
exports.ProductCategoryType = ProductCategoryType;

(function (ProductCategoryType) {
  ProductCategoryType["Size"] = "SIZE";
  ProductCategoryType["Weight"] = "WEIGHT";
})(ProductCategoryType || (exports.ProductCategoryType = ProductCategoryType = {}));

var waterProofFaces = [{
  label: "Không",
  face: 0,
  value: "NULL"
}, {
  label: "Mặt trong",
  face: 1,
  value: WaterProofFaceType.Inner
}, {
  label: "Mặt ngoài",
  face: 1,
  value: WaterProofFaceType.Outer
}, {
  label: "Hai mặt",
  face: 2,
  value: WaterProofFaceType.Both
}];
exports.waterProofFaces = waterProofFaces;