"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaCustomerApi = void 0;

var _request = require("@/utils/request");

var misaCustomerApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaCustomer',
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/v1/admin/misaCustomer/".concat(id)
    });
  },
  getTotal: function getTotal(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaCustomer/total',
      params: params
    });
  },
  getSummary: function getSummary(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaCustomer/summary',
      params: params
    });
  },
  getSummaryQuote: function getSummaryQuote(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaCustomer/summary/quote',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaCustomer',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/misaCustomer/".concat(id),
      method: 'patch',
      data: data
    });
  },
  changeOwner: function changeOwner(id, misaEmployeeId) {
    return (0, _request.request)({
      url: "/v1/admin/misaCustomer/".concat(id, "/owner"),
      method: 'patch',
      data: {
        misaEmployeeId: misaEmployeeId
      }
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/misaCustomer/".concat(id),
      method: 'delete'
    });
  }
};
exports.misaCustomerApi = misaCustomerApi;