"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-collapse", {
    attrs: {
      accordion: ""
    },
    model: {
      value: _vm.actives,
      callback: function callback($$v) {
        _vm.actives = $$v;
      },
      expression: "actives"
    }
  }, _vm._l(_vm.sections, function (section) {
    return _c("el-collapse-item", {
      key: section.key,
      attrs: {
        title: section.label,
        name: section.key,
        disabled: ""
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("label", {
      staticStyle: {
        "font-size": "20px"
      },
      style: {
        color: section.color
      },
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(section.label) + " (" + _vm._s(section.data.length) + ") ")]), section.totalPE ? _c("el-link", {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        type: "primary"
      },
      on: {
        click: function click($event) {
          return _vm.handleViewPE(section.date);
        }
      }
    }, [_vm._v("Có " + _vm._s(section.totalPE) + " yêu cầu quấn PE")]) : _vm._e()], 1), _c("DeliveryTripGroupTable", {
      attrs: {
        data: section.data,
        loading: _vm.loading,
        visibleAmount: _vm.visibleDelivered,
        visibleExcel: _vm.visibleDelivered
      },
      on: {
        detail: _vm.handleViewDetail,
        "export-excel": _vm.exportExcel,
        "un-export": _vm.handleUnExport
      }
    })], 2);
  }), 1), _c("ViewDeliveryGroupModal", {
    ref: "ViewDeliveryGroupModal",
    attrs: {
      visibleDelivered: _vm.visibleDelivered
    },
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;