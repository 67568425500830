"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top"
    }
  }, [_c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhập")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lớp",
      prop: "layer"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    on: {
      "": function _($event) {},
      change: _vm.handleChangeLayer
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_c("el-option", {
    attrs: {
      label: "3 lớp",
      value: 3
    }
  }), _c("el-option", {
    attrs: {
      label: "5 lớp",
      value: 5
    }
  }), _c("el-option", {
    attrs: {
      label: "7 lớp",
      value: 7
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại sóng",
      prop: "cartonWave"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      "value-key": "id",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.cartonWave,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonWave", $$v);
      },
      expression: "form.cartonWave"
    }
  }, _vm._l(_vm.cartonWavesFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Kết cấu giấy",
      prop: "cartonStruct"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium",
      filterable: "",
      "value-key": "id"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.cartonStruct,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonStruct", $$v);
      },
      expression: "form.cartonStruct"
    }
  }, _vm._l(_vm.cartonStructsFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1), _vm.form.cartonStruct && _vm.form.cartonStruct.version != _vm.lastCartonVersion ? _c("div", [_c("el-alert", {
    attrs: {
      closable: false,
      title: "Giá giấy chưa được cập nhật mới",
      type: "warning",
      effect: "dark"
    }
  })], 1) : _vm._e()], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.length,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length", $$v);
      },
      expression: "form.length"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Rộng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.width,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width", $$v);
      },
      expression: "form.width"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cao"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1)], 1), _vm.form.type == "BE" ? _c("el-col", {
    attrs: {
      span: 4,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "numOfBe",
      label: "Số lượng bế"
    }
  }, [_c("el-select", {
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.beMatrix,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "beMatrix", $$v);
      },
      expression: "form.beMatrix"
    }
  }, _vm._l(_vm.beMatrix, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.label
      }
    });
  }), 1)], 1)], 1) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng thùng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  }), _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tối thiểu: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.form.minQuantity) + " thùng ")])])], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mặt chống thấm"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.waterProofFaceType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "waterProofFaceType", $$v);
      },
      expression: "form.waterProofFaceType"
    }
  }, _vm._l(_vm.waterProofFaces, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số màu"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.numColor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "numColor", $$v);
      },
      expression: "form.numColor"
    }
  }, _vm._l(5, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: "".concat(item, " m\xE0u"),
        value: item
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mặt in"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: !_vm.form.numColor,
      size: "medium"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.numOfPrint,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "numOfPrint", $$v);
      },
      expression: "form.numOfPrint"
    }
  }, [_c("el-option", {
    attrs: {
      label: "1 mặt",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "2 mặt",
      value: 2
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiền khuôn bế"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.khuonBePrice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "khuonBePrice", $$v);
      },
      expression: "form.khuonBePrice"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiền bảng in"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n              }"
    }],
    attrs: {
      placeholder: "",
      size: "normal"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm();
      }
    },
    model: {
      value: _vm.form.bangInPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bangInPrice", $$v);
      },
      expression: "form.bangInPrice"
    }
  })], 1)], 1)], 1), _vm.errorMessage ? _c("el-alert", {
    attrs: {
      title: _vm.errorMessage,
      type: "error",
      effect: "dark",
      "show-icon": ""
    }
  }) : _vm._e()], 1), _c("section", {
    staticClass: "box"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Ước tính")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length2", $$v);
      },
      expression: "form.length2"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width2", $$v);
      },
      expression: "form.width2"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhà cung cấp")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length3,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length3", $$v);
      },
      expression: "form.length3"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width3,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width3", $$v);
      },
      expression: "form.width3"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Ước tính 1 thùng")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length4,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length4", $$v);
      },
      expression: "form.length4"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm",
      size: "normal"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width4,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width4", $$v);
      },
      expression: "form.width4"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Thực tế (" + _vm._s(_vm.form.quantityEstimate) + " thùng)")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.length5,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length5", $$v);
      },
      expression: "form.length5"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      disabled: "",
      placeholder: "",
      size: "normal"
    },
    model: {
      value: _vm.form.width5,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width5", $$v);
      },
      expression: "form.width5"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c("section", {
    staticClass: "box"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Cấu thành giá")]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số m2: ")]), _c("span", [_vm._v(_vm._s(_vm.form.acr) + " m2")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số m3: ")]), _c("span", [_vm._v(_vm._s(_vm.form.cubicMeter) + " m3")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số m3 theo số lượng: ")]), _c("span", [_vm._v(_vm._s(Math.ceil(_vm.form.cubicMeter * _vm.form.quantity)) + " m3")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lượng giấy hao hụt: ")]), _c("span", [_vm._v(_vm._s(_vm.form.loss) + " m2 (" + _vm._s(_vm.$formatNumber(+(_vm.form.loss * _vm.cartonPrice).toFixed(1))) + " đ)")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá giấy nguyên liệu: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.price, ",")) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí gia công: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.giaCong)) + " đ ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phụ gia: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumber(_vm.form.phuGia)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí in: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.moneyPrint)) + " đ (" + _vm._s(_vm.form.numColor) + " màu) " + _vm._s(_vm.form.numColor ? "".concat(_vm.form.numOfPrint, " m\u1EB7t") : ""))])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phụ phí bế: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.moneyPhuPhiBe)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng cộng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.moneyFinal)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá tại kho: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.sellPrice)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí vận chuyển: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.shipFee)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá giao tận nơi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.deliveryPrice)) + " đ")])]), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lợi nhuận: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.sellPrice - _vm.form.moneyFinal)) + " đ")])])])], 1), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, _vm._l(_vm.distances, function (item, index) {
    return _c("el-button", {
      key: index,
      staticClass: "font-bold",
      attrs: {
        type: "primary",
        size: "mini",
        icon: _vm.form.distance == item.value ? "el-icon-check" : ""
      },
      on: {
        click: function click($event) {
          return _vm.handleChangeDistance(item.value);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1)]), _c("EcountProductModal", {
    ref: "EcountProductModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;