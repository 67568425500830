"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.telegramApi = void 0;

var _request = require("@/utils/request");

var telegramApi = {
  logCongViec: function logCongViec(message) {
    return (0, _request.request)({
      url: '/v1/admin/telegram/logCongViec',
      method: 'post',
      data: {
        message: message
      }
    });
  }
};
exports.telegramApi = telegramApi;