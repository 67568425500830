"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.string.split.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm tên, sđt",
      size: "medium"
    },
    nativeOn: {
      keypress: function keypress($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhân viên")]), _c("el-select", {
    attrs: {
      clearable: "",
      size: "medium",
      filterable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "staffId", $$v);
      },
      expression: "query.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("VAT")]), _c("el-select", {
    attrs: {
      clearable: "",
      size: "medium",
      filterable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isHasVat,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isHasVat", $$v);
      },
      expression: "query.isHasVat"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Có",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "Không",
      value: false
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày mua hàng")]), _c("el-select", {
    attrs: {
      clearable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.buyType,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "buyType", $$v);
      },
      expression: "query.buyType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Sắp mua",
      value: "SOON_BUY"
    }
  }), _c("el-option", {
    attrs: {
      label: "Lâu chưa mua",
      value: "LONG_BUY"
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lọc tag")]), _c("el-select", {
    staticStyle: {
      width: "230px"
    },
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.customerTag,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "customerTag", $$v);
      },
      expression: "query.customerTag"
    }
  }, _vm._l(_vm.customerTags, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("BLock / Unblock")]), _c("el-select", {
    attrs: {
      clearable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isCustomerBlock,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isCustomerBlock", $$v);
      },
      expression: "query.isCustomerBlock"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Bị khóa",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "Không khóa",
      value: false
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Sản phẩm")]), _c("el-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      filterable: "",
      multiple: "",
      remote: "",
      "remote-method": _vm.fetchProducts
    },
    model: {
      value: _vm.query.productIds,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "productIds", $$v);
      },
      expression: "query.productIds"
    }
  }, _vm._l(_vm.products, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Toán tử")]), _c("el-select", {
    staticStyle: {
      width: "100px"
    },
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.query.operator,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "operator", $$v);
      },
      expression: "query.operator"
    }
  }, _vm._l(_vm.operators, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số lượng")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "quantity", $$v);
      },
      expression: "query.quantity"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm kiếm")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "120",
      label: "Nhân viên",
      align: "center",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("img", {
          staticStyle: {
            "object-fit": "cover",
            "border-radius": "50%"
          },
          attrs: {
            src: _vm.$baseUrlMedia + row.staffAvatar,
            width: "40",
            height: "40",
            alt: ""
          }
        }), _c("br"), _c("el-button", {
          staticClass: "red-nhanh",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickCustomer(row);
            }
          }
        }, [_vm._v("Nhanh.vn")]), row.isHasVAT ? [_c("img", {
          staticStyle: {
            "margin-top": "12px"
          },
          attrs: {
            width: "80",
            src: _vm.vatIcon,
            alt: ""
          }
        })] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm.checkIsSoonBuy(row) ? _c("div", {
          staticClass: "customer-badge soon-buy"
        }, [_c("img", {
          attrs: {
            src: _vm.comingSoonImg,
            width: "25",
            alt: ""
          }
        }), _c("span", {
          staticClass: "title"
        }, [_vm._v("Sắp mua")])]) : _vm.checkIsLongBuy(row) ? _c("div", {
          staticClass: "customer-badge long-buy"
        }, [_c("img", {
          attrs: {
            src: _vm.longTermImg,
            width: "25",
            alt: ""
          }
        }), _c("span", {
          staticClass: "title"
        }, [_vm._v("Lâu chưa mua")])]) : _vm._e(), _c("br"), _c("el-button", {
          attrs: {
            type: row.isIgnoreTask ? "warning" : "danger",
            icon: "el-icon-lock",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleBlock(row);
            }
          }
        }, [_vm._v(_vm._s(row.isIgnoreTask ? "Unblock khách" : "Block khách") + " ")]), _c("br"), _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewCustomer(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.name) + " - " + _vm._s(row.phone) + " ")]), _c("br"), _c("span", [_vm._v(_vm._s(row.address))]), _c("FollowButton", {
          attrs: {
            customerId: row.id,
            onFollowOk: _vm.fetchData,
            taskFollowId: row.taskFollowId,
            taskFollowStatus: row.taskFollowStatus,
            visibleCensorFollow: _vm.visibleCensorFollow,
            type: _vm.TaskFollowType.SingleProduct
          }
        }), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tổng tiền: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalPrice)) + "đ")])]), _c("div", {
          class: !row.totalBK ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Băng keo: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalBK, 0, "0")) + "đ")])]), _c("div", {
          class: !row.totalMXH ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Xốp hơi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalMXH, 0, "0")) + "đ")])]), _c("div", {
          class: !row.totalMPE ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Màng PE: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalMPE, 0, "0")) + "đ")])]), _c("div", {
          class: !row.totalTCT ? "background-red" : "background-green"
        }, [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Thùng carton: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalTCT, 0, "0")) + "đ")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú khách hàng",
      prop: "customerNote"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._l(row.tags.split(","), function (item, index) {
          return _c("span", {
            key: index
          }, [item ? _c("Tag", {
            attrs: {
              label: _vm.customerTagTrans[item],
              backgroundColor: _vm.customerTagStyles[item].backgroundColor,
              labelColor: _vm.customerTagStyles[item].labelColor
            }
          }) : _vm._e()], 1);
        }), _c("br"), _c("span", [_vm._v(" " + _vm._s(row.customerNote) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hoạt động gần nhất",
      prop: "description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return _vm._l(row.lastLogs, function (item, index) {
          return _c("div", {
            key: index,
            staticStyle: {
              "border-bottom": "#ccc 1px solid"
            }
          }, [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v(" " + _vm._s(_vm.fromNow(item.dateCreated)) + ": ")]), _c("span", [_vm._v(_vm._s(item.content))])])]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thông tin mua hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("div", {
          class: _vm.isOverCycle(row) && "text-danger"
        }, [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Số lần mua: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalOrder)) + " đơn")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Chu kỳ mua: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.buyCycle)) + " ngày")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Lần cuối mua: ")]), _c("span", [_vm._v(_vm._s(_vm.fromNow(row.lastOrderDate)))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Lần cuối chăm sóc: ")]), _c("span", [_vm._v(_vm._s(_vm.fromNow(row.lastTaskDate)))])]), _c("el-button", {
          attrs: {
            loading: row.loadingSyncLastOrder,
            type: "primary",
            icon: "el-icon-refresh",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleSyncLastOrderDate(row);
            }
          }
        }, [_vm._v("Sync lần cuối mua")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Copy nội dung"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("img", {
          staticStyle: {
            cursor: "pointer"
          },
          attrs: {
            width: "80",
            src: _vm.zaloIcon
          },
          on: {
            click: function click($event) {
              return _vm.handleCopyContent(row);
            }
          }
        }), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            loading: row.loadingContent
          },
          on: {
            click: function click($event) {
              return _vm.handleConfirmSentToCustomer(row);
            }
          }
        }, [_vm._v("Đã nhắn cho khách")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sản phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return _vm._l(row.products, function (item, index) {
          return _c("div", {
            key: index
          }, [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("-" + _vm._s(item.name) + "(SL: " + _vm._s(_vm.$formatNumberVN(item.quantity)) + "):")]), _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.amount)) + "đ ")])]);
        });
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("CustomerModal", {
    ref: "CustomerModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;