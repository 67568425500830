"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeliveryTripType = exports.DeliveryTripStatusName = exports.DeliveryTripStatus = exports.DeliveryTripShipInfoTypeTrans = exports.DeliveryTripShipInfoType = exports.DeliveryTripDetailType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _DeliveryTripStatusNa, _DeliveryTripShipInfo;

var DeliveryTripDetailType;
exports.DeliveryTripDetailType = DeliveryTripDetailType;

(function (DeliveryTripDetailType) {
  DeliveryTripDetailType["Full"] = "FULL";
  DeliveryTripDetailType["PO"] = "PO";
  DeliveryTripDetailType["Part"] = "PART";
})(DeliveryTripDetailType || (exports.DeliveryTripDetailType = DeliveryTripDetailType = {}));

var DeliveryTripStatus;
exports.DeliveryTripStatus = DeliveryTripStatus;

(function (DeliveryTripStatus) {
  DeliveryTripStatus["New"] = "NEW";
  DeliveryTripStatus["Complete"] = "COMPLETE";
})(DeliveryTripStatus || (exports.DeliveryTripStatus = DeliveryTripStatus = {}));

var DeliveryTripStatusName = (_DeliveryTripStatusNa = {}, (0, _defineProperty2.default)(_DeliveryTripStatusNa, DeliveryTripStatus.New, 'Mới'), (0, _defineProperty2.default)(_DeliveryTripStatusNa, DeliveryTripStatus.Complete, 'Hoàn tất'), _DeliveryTripStatusNa);
exports.DeliveryTripStatusName = DeliveryTripStatusName;
var DeliveryTripType;
exports.DeliveryTripType = DeliveryTripType;

(function (DeliveryTripType) {
  DeliveryTripType["Command"] = "COMMAND";
  DeliveryTripType["Trip"] = "TRIP";
})(DeliveryTripType || (exports.DeliveryTripType = DeliveryTripType = {}));
/**
 * hình thức giao: file: tự nhập thông tin, link: liên kết ngoài
 */


var DeliveryTripShipInfoType;
exports.DeliveryTripShipInfoType = DeliveryTripShipInfoType;

(function (DeliveryTripShipInfoType) {
  DeliveryTripShipInfoType["Fill"] = "FILL";
  DeliveryTripShipInfoType["Link"] = "LINK";
})(DeliveryTripShipInfoType || (exports.DeliveryTripShipInfoType = DeliveryTripShipInfoType = {}));

var DeliveryTripShipInfoTypeTrans = (_DeliveryTripShipInfo = {}, (0, _defineProperty2.default)(_DeliveryTripShipInfo, DeliveryTripShipInfoType.Fill, 'Tự nhập thông tin'), (0, _defineProperty2.default)(_DeliveryTripShipInfo, DeliveryTripShipInfoType.Link, 'Liên kết ngoài'), _DeliveryTripShipInfo);
exports.DeliveryTripShipInfoTypeTrans = DeliveryTripShipInfoTypeTrans;