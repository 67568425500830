"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProviderCartonPriceType = void 0;
var ProviderCartonPriceType;
exports.ProviderCartonPriceType = ProviderCartonPriceType;

(function (ProviderCartonPriceType) {
  ProviderCartonPriceType["Carton"] = "CARTON";
  ProviderCartonPriceType["Product"] = "PRODUCT";
})(ProviderCartonPriceType || (exports.ProviderCartonPriceType = ProviderCartonPriceType = {}));