"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Xem thông tin sản xuất",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.product,
      "label-position": "top"
    }
  }, [_c("section", {
    staticClass: "carton-info"
  }, [_c("span", {
    staticStyle: {
      color: "black",
      "font-size": "18px",
      "font-weight": "bold",
      "margin-bottom": "8px",
      display: "inline-block"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Sản phẩm ")]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tên thành phẩm: ")]), _c("span", [_vm._v(_vm._s(_vm.product.productName))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Mã thành phẩm: ")]), _c("span", [_vm._v(_vm._s(_vm.product.productCode))])])]), _vm.data.type == "BE" ? _c("section", {
    staticClass: "carton-info",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      color: "black",
      "font-size": "18px",
      "font-weight": "bold",
      "margin-bottom": "8px",
      display: "inline-block"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Khuôn bế ")])]), !_vm.data.mold ? _c("span", {
    staticStyle: {
      color: "red",
      display: "block"
    }
  }, [_vm._v("Không có khuôn bế")]) : _c("div", [_c("el-input", {
    attrs: {
      disabled: "",
      placeholder: ""
    },
    model: {
      value: _vm.data.mold.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data.mold, "name", $$v);
      },
      expression: "data.mold.name"
    }
  })], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.product.moldDaiTam,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "moldDaiTam", $$v);
      },
      expression: "product.moldDaiTam"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.product.moldKhoTam,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "moldKhoTam", $$v);
      },
      expression: "product.moldKhoTam"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SL thùng/tấm bế"
    }
  }, [_c("el-input", {
    on: {
      change: _vm.handleChangeSlThung
    },
    model: {
      value: _vm.product.moldQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "moldQuantity", $$v);
      },
      expression: "product.moldQuantity"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c("section", {
    staticClass: "carton-info",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "black",
      "font-size": "18px",
      "font-weight": "bold",
      "margin-bottom": "8px",
      display: "inline-block"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" NVL ")]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tên NVL: ")]), _c("span", [_vm._v(_vm._s(_vm.product.materialName))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Mã NVL: ")]), _c("span", [_vm._v(_vm._s(_vm.product.materialCode))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Định mức: ")]), _c("span", [_vm._v(_vm._s(_vm.product.rate))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Ghi chú: ")]), _c("span", [_vm._v(_vm._s(_vm.product.note))])])])]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;