"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "po-modal",
    attrs: {
      title: _vm.status == "update" ? "C\u1EADp nh\u1EADt PO (#".concat(_vm.selectedPo.code, ")") : "Thêm mới PO",
      visible: _vm.visible,
      width: "1200px",
      top: "30px",
      "close-on-click-modal": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nhà cung cấp",
      size: "",
      prop: "supplierId"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: ""
    },
    model: {
      value: _vm.form.supplierId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "supplierId", $$v);
      },
      expression: "form.supplierId"
    }
  }, _vm._l(_vm.suppliers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày tạo PO",
      prop: "poDate"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      format: "dd/MM/yyyy"
    },
    model: {
      value: _vm.form.poDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "poDate", $$v);
      },
      expression: "form.poDate"
    }
  })], 1)], 1)], 1), _vm.status == "update" ? _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v("In phiếu")]), _c("div", {
    staticStyle: {
      height: "0",
      overflow: "hidden"
    }
  }, [_c("PoPrint", {
    ref: "PoPrint",
    attrs: {
      po: _vm.selectedPo,
      productsPo: _vm.productsPo
    }
  })], 1)], 1) : _vm._e(), _c("el-table", {
    staticClass: "po-detail-table",
    attrs: {
      data: _vm.productsPo,
      border: "",
      fit: "",
      "row-class-name": _vm.handleClassName,
      "row-key": "id",
      "summary-method": _vm.getSummaries,
      "show-summary": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "No.",
      width: "80",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var $index = _ref.$index,
            row = _ref.row;
        return [_c("span", {
          class: row.isComplete ? "text-through" : ""
        }, [_vm._v(_vm._s($index + 1))]), _vm.productsPo.length > 1 && !row.isComplete ? _c("i", {
          staticClass: "el-icon-close delete-btn",
          on: {
            click: function click($event) {
              return _vm.deleteProduct($index);
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sản phẩm",
      prop: "",
      width: "320"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-select", {
          attrs: {
            value: row.productId,
            filterable: "",
            disabled: row.isComplete
          },
          on: {
            change: function change($event) {
              return _vm.handleChangeProduct($event, row);
            }
          }
        }, _vm._l(_vm.products, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: "".concat(item.name),
              value: item.id
            }
          }, [_c("span", [_vm._v(_vm._s(item.name) + " (Tồn: " + _vm._s(item.available) + ")")])]);
        }), 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL đã đặt",
      align: "right",
      prop: "quantityPo",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("span", {
          class: row.isComplete ? "text-through" : ""
        }, [_vm._v(_vm._s(_vm.$formatNumberVN(row.quantityPo)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn kho",
      prop: "available",
      width: "80",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", {
          class: row.isComplete ? "text-through" : ""
        }, [_vm._v(_vm._s(_vm.$formatNumberVN(row.available)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đã giao",
      align: "right",
      prop: "actualQuantity"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.actualQuantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Còn lại",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantityPo - row.actualQuantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn + Chưa nhập",
      align: "right",
      prop: "quantityPo",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("span", {
          class: row.isComplete ? "text-through" : ""
        }, [_vm._v(_vm._s(!row.id ? _vm.$formatNumberVN(row.quantityYet + +row.quantity) : _vm.$formatNumberVN(row.quantityYet)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng đặt hàng",
      align: "right",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          staticClass: "text-right",
          attrs: {
            disabled: row.isComplete
          },
          model: {
            value: row.quantity,
            callback: function callback($$v) {
              _vm.$set(row, "quantity", $$v);
            },
            expression: "row.quantity"
          }
        })];
      }
    }])
  }), _vm.status == "update" ? _c("el-table-column", {
    attrs: {
      label: "Hoàn thành"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [!row.isComplete ? _c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleComplete(row);
            }
          }
        }, [_vm._v(" Hoàn thành ")]) : _c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "warning",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleBackComplete(row);
            }
          }
        }, [_vm._v(" Chưa Hoàn thành ")])];
      }
    }], null, false, 693923643)
  }) : _vm._e()], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addProduct
    }
  }, [_vm._v("Thêm sản phẩm")])], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("div", [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1), _c("InsertProductModal", {
    ref: "InsertProductModal",
    on: {
      submitOk: _vm.addProduct
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;