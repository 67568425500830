import { render, staticRenderFns } from "./index.vue?vue&type=template&id=31355dee&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31355dee')) {
      api.createRecord('31355dee', component.options)
    } else {
      api.reload('31355dee', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=31355dee&", function () {
      api.rerender('31355dee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/woo-sync/index.vue"
export default component.exports