"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merchantRoleApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var merchantRoleApi = {
  findAll: function findAll() {
    return (0, _request.default)({
      url: "/merchantRole"
    });
  },
  createPermission: function createPermission(data) {
    return (0, _request.default)({
      url: "/merchantRole/permission",
      data: data,
      method: "post"
    });
  },
  findPermission: function findPermission() {
    return (0, _request.default)({
      url: "/merchantRole/permission"
    });
  }
};
exports.merchantRoleApi = merchantRoleApi;