"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới" : "Cập nhật",
      visible: _vm.visible,
      width: "600px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: "",
      value: _vm.form.name
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tên khi xuất VAT"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameVat,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameVat", $$v);
      },
      expression: "form.nameVat"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "% VAT"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.vat,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vat", $$v);
      },
      expression: "form.vat"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      loading: _vm.loadingSubmit
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Lưu")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;