"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTokenHistoryApi = exports.GetTokenHistoryType = void 0;

var _request = require("@/utils/request");

var GetTokenHistoryType;
exports.GetTokenHistoryType = GetTokenHistoryType;

(function (GetTokenHistoryType) {
  GetTokenHistoryType["GetToken"] = "GET_TOKEN";
  GetTokenHistoryType["SyncInventory"] = "SYNC_INVENTORY";
})(GetTokenHistoryType || (exports.GetTokenHistoryType = GetTokenHistoryType = {}));

var getTokenHistoryApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/getTokenHistory',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/getTokenHistory',
      data: data,
      method: 'post'
    });
  }
};
exports.getTokenHistoryApi = getTokenHistoryApi;