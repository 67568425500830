"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "panel-search panel-shadow"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("h3", {
    staticStyle: {
      margin: "0",
      "margin-bottom": "26px"
    }
  }, [_vm._v("Tìm sản phẩm")]), _c("el-select", {
    ref: "selectRef",
    attrs: {
      filterable: "",
      clearable: "",
      "reserve-keyword": ""
    },
    on: {
      change: _vm.handleSelect
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }, _vm._l(_vm.products, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name),
        value: item.id
      }
    });
  }), 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("h3", {
    staticStyle: {
      margin: "0"
    }
  }, [_vm._v("Tìm đơn")]), _c("div", {
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "single"
    },
    model: {
      value: _vm.findType,
      callback: function callback($$v) {
        _vm.findType = $$v;
      },
      expression: "findType"
    }
  }, [_vm._v("Một đơn")]), _c("el-radio", {
    attrs: {
      label: "multiple"
    },
    model: {
      value: _vm.findType,
      callback: function callback($$v) {
        _vm.findType = $$v;
      },
      expression: "findType"
    }
  }, [_vm._v("Nhiều đơn")])], 1), _c("el-input", {
    attrs: {
      placeholder: "Tìm mã đơn"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchOrder.apply(null, arguments);
      }
    },
    model: {
      value: _vm.orderId,
      callback: function callback($$v) {
        _vm.orderId = $$v;
      },
      expression: "orderId"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "panel-shadow",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c("h3", {
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v("Tổng kết")]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng khối lượng quy đổi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(Math.round(_vm.totalWeight))) + " Kg")])]), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng khối lượng thực tế: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(Math.round(_vm.totalWeightReal))) + " Kg")])]), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng thể tích thực tế: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalVolumeReal / 1000, 1)) + " m"), _c("sup", [_vm._v("3")])])]), false ? _vm._l(_vm.shippers, function (shipper, index) {
    return _c("div", {
      key: index
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("----Chi tiết----")]), _c("div", [_c("ul", [_c("li", [_vm._v(" Ship " + _vm._s(index + 1) + " ")]), _vm._l(shipper.products, function (product, index) {
      return _c("li", {
        key: index
      }, [_c("label", {
        attrs: {
          for: ""
        }
      }, [_vm._v(" " + _vm._s(product.name) + " ")]), _vm._v("| " + _vm._s(product.quantity) + " x " + _vm._s(product.weight) + "Kg = " + _vm._s(product.quantity * product.weight) + "Kg ")]);
    })], 2)])]);
  }) : _vm._e()], 2), _c("el-tabs", [_c("el-tab-pane", {
    attrs: {
      label: "Phương tiện"
    }
  }, [_c("vehicle-tab", {
    attrs: {
      productTable: _vm.productTable,
      totalVolumeReal: _vm.totalVolumeReal,
      totalWeight: _vm.totalWeight,
      totalWeightReal: _vm.totalWeightReal,
      vehicles: _vm.vehicles
    }
  })], 1), false ? _c("el-tab-pane", {
    attrs: {
      label: "Chi tiết"
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "panel-shadow",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c("h3", {
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v("Danh sách sản phẩm")]), _c("product-table", {
    attrs: {
      productXopHoi: _vm.xopHoi,
      products: _vm.productTable,
      onDelete: _vm.handleDelete
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;