"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartonCostTypeTrans = exports.CartonCostType = exports.CartonCostCalcValueTypeTrans = exports.CartonCostCalcValueType = exports.CartonCostCalcTypeTrans = exports.CartonCostCalcType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _CartonCostTypeTrans, _CartonCostCalcValueT, _CartonCostCalcTypeTr;

var CartonCostType;
exports.CartonCostType = CartonCostType;

(function (CartonCostType) {
  CartonCostType["Material"] = "MATERIAL";
  CartonCostType["Processing"] = "PROCESSING";
  CartonCostType["Other"] = "OTHER";
})(CartonCostType || (exports.CartonCostType = CartonCostType = {}));

var CartonCostTypeTrans = (_CartonCostTypeTrans = {}, (0, _defineProperty2.default)(_CartonCostTypeTrans, CartonCostType.Material, 'Nguyên vật liệu'), (0, _defineProperty2.default)(_CartonCostTypeTrans, CartonCostType.Processing, 'Gia công'), (0, _defineProperty2.default)(_CartonCostTypeTrans, CartonCostType.Other, 'Khác'), _CartonCostTypeTrans);
exports.CartonCostTypeTrans = CartonCostTypeTrans;
var CartonCostCalcValueType;
exports.CartonCostCalcValueType = CartonCostCalcValueType;

(function (CartonCostCalcValueType) {
  CartonCostCalcValueType["Quantity"] = "QUANTITY";
  CartonCostCalcValueType["Width"] = "WIDTH";
  CartonCostCalcValueType["Length"] = "LENGTH";
  CartonCostCalcValueType["Meter"] = "METER";
})(CartonCostCalcValueType || (exports.CartonCostCalcValueType = CartonCostCalcValueType = {}));

var CartonCostCalcValueTypeTrans = (_CartonCostCalcValueT = {}, (0, _defineProperty2.default)(_CartonCostCalcValueT, CartonCostCalcValueType.Quantity, 'Số lượng'), (0, _defineProperty2.default)(_CartonCostCalcValueT, CartonCostCalcValueType.Width, 'Chiều khổ'), (0, _defineProperty2.default)(_CartonCostCalcValueT, CartonCostCalcValueType.Length, 'Chiều dài'), (0, _defineProperty2.default)(_CartonCostCalcValueT, CartonCostCalcValueType.Meter, 'Số m2'), _CartonCostCalcValueT);
exports.CartonCostCalcValueTypeTrans = CartonCostCalcValueTypeTrans;
var CartonCostCalcType;
exports.CartonCostCalcType = CartonCostCalcType;

(function (CartonCostCalcType) {
  CartonCostCalcType["Product"] = "PRODUCT";
  CartonCostCalcType["SmallPlate"] = "SMALL_PLATE";
  CartonCostCalcType["BigPlate"] = "BIG_PLATE";
})(CartonCostCalcType || (exports.CartonCostCalcType = CartonCostCalcType = {}));

var CartonCostCalcTypeTrans = (_CartonCostCalcTypeTr = {}, (0, _defineProperty2.default)(_CartonCostCalcTypeTr, CartonCostCalcType.Product, 'Sản phẩm'), (0, _defineProperty2.default)(_CartonCostCalcTypeTr, CartonCostCalcType.SmallPlate, 'Tấm nhỏ'), (0, _defineProperty2.default)(_CartonCostCalcTypeTr, CartonCostCalcType.BigPlate, 'Tấm lớn'), _CartonCostCalcTypeTr);
exports.CartonCostCalcTypeTrans = CartonCostCalcTypeTrans;