"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MisaProductTypeTrans = exports.MisaProductType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _MisaProductTypeTrans;

var MisaProductType;
exports.MisaProductType = MisaProductType;

(function (MisaProductType) {
  MisaProductType["Sale"] = "SALE";
  MisaProductType["Produce"] = "PRODUCE";
})(MisaProductType || (exports.MisaProductType = MisaProductType = {}));

var MisaProductTypeTrans = (_MisaProductTypeTrans = {}, (0, _defineProperty2.default)(_MisaProductTypeTrans, MisaProductType.Produce, 'Sản xuất'), (0, _defineProperty2.default)(_MisaProductTypeTrans, MisaProductType.Sale, 'Buôn'), _MisaProductTypeTrans);
exports.MisaProductTypeTrans = MisaProductTypeTrans;