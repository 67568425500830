"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeRateCustomerHistoryApi = void 0;

var _request = require("@/utils/request");

var changeRateCustomerHistoryApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/changeRateCustomerHistory',
      params: params
    });
  },
  approve: function approve(id) {
    return (0, _request.request)({
      url: "/v1/admin/changeRateCustomerHistory/".concat(id, "/approve"),
      method: 'patch'
    });
  },
  reject: function reject(id) {
    return (0, _request.request)({
      url: "/v1/admin/changeRateCustomerHistory/".concat(id, "/reject"),
      method: 'delete'
    });
  }
};
exports.changeRateCustomerHistoryApi = changeRateCustomerHistoryApi;