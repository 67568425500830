"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.query.page = 1;

        _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", [_c("el-radio", {
    attrs: {
      label: false
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isFollowProduct,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isFollowProduct", $$v);
      },
      expression: "query.isFollowProduct"
    }
  }, [_vm._v("Tất cả")]), _c("el-radio", {
    attrs: {
      label: true
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isFollowProduct,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isFollowProduct", $$v);
      },
      expression: "query.isFollowProduct"
    }
  }, [_vm._v("Chỉ SP follow")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    },
    on: {
      "sort-change": _vm.handleChangeSort
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thành phẩm",
      width: "300",
      prop: "code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mã: ")]), _c("span", [_vm._v(_vm._s(row.code))])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tên: ")]), _c("span", [_vm._v(_vm._s(row.name))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn có thể bán (TP)",
      align: "right",
      prop: "availableTP"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.availableTP >= row.minQuantityProduce ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.availableTP, 0))) + " ")]) : _c("label", {
          staticStyle: {
            color: "red",
            "font-weight": "bold"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(+_vm.$roundUp(row.availableTP, 0))) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v(" Tồn có thể bán (TP) "), _c("el-tooltip", {
    attrs: {
      content: "Gồm TP, HH",
      placement: "top",
      effect: "dark"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1)])], 2)], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;