"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Hiệu lực báo giá")]), _c("div", {
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "",
      size: "normal"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm._v("ngày")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.expiredQuoteConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.expiredQuoteConfig, "value", $$v);
      },
      expression: "expiredQuoteConfig.value"
    }
  })], 1)]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Ghi chú")]), _c("br"), _c("small", {
    staticStyle: {
      "margin-bottom": "10px",
      display: "inline-block",
      color: "red"
    }
  }, [_vm._v('Lưu ý: luôn có ký hiệu "{isVat}" để xác định khi in có VAT hay không!')]), _vm.visibleForm ? _c("tinymce", {
    ref: "editor",
    attrs: {
      visibleUpload: false,
      height: 250
    },
    on: {
      onInit: _vm.handleInit
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  }) : _vm._e()], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;