"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_vm.title ? _c("h2", {
    staticStyle: {
      "margin-top": "0",
      color: "#163237",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.description ? _c("span", [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _c("div", {
    staticClass: "form",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm.status == "inner" ? [_c("label", {
    staticStyle: {
      "font-size": "18px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Kích thước lọt lòng")]), _c("size-box-form", {
    attrs: {
      onChange: _vm.handleChangeForm,
      waves: _vm.cartonWaves,
      form: _vm.form
    }
  })] : [_c("label", {
    staticStyle: {
      "font-size": "18px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Kích thước phủ bì")]), _c("size-box-form", {
    attrs: {
      onChange: _vm.handleChangeForm,
      waves: _vm.cartonWaves,
      form: _vm.form
    }
  })]], 2), _c("div", {
    staticClass: "exchange-btn",
    on: {
      click: _vm.handleExchange
    }
  }, [_c("font-awesome-icon", {
    staticStyle: {
      transform: "rotate(90deg)"
    },
    attrs: {
      icon: "exchange-alt"
    }
  }), _c("span", [_vm._v("Chuyển đổi")])], 1), _c("div", {
    staticClass: "form"
  }, [_vm.status == "inner" ? [_c("label", {
    staticStyle: {
      "font-size": "18px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Kích thước phủ bì")]), _c("size-box-form", {
    attrs: {
      visibleWave: false,
      isDisabled: true,
      waves: _vm.cartonWaves,
      form: _vm.formExchange
    }
  })] : [_c("label", {
    staticStyle: {
      "font-size": "18px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Kích thước lọt lòng")]), _c("size-box-form", {
    attrs: {
      visibleWave: false,
      isDisabled: true,
      waves: _vm.cartonWaves,
      form: _vm.formExchange
    }
  })]], 2)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;