"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "L\u1ECBch s\u1EED c\xE2n m\u1EABu: ".concat(_vm.workOrder ? _vm.workOrder.productCode : ""),
      visible: _vm.visible,
      width: "800px",
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tổng khối lượng mẫu (Kg)",
      prop: "palletWeight"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng tấm mẫu đi cân (cái)",
      prop: "quantity"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Cân nặng trên 1 sp (Kg)",
      prop: "unitWeight"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "230"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onSelect(row);
            }
          }
        }, [_vm._v("Chọn")]), _c("el-button", {
          attrs: {
            type: "primary",
            icon: "el-icon-edit",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }), _c("el-button", {
          attrs: {
            type: "danger",
            icon: "el-icon-delete",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        })];
      }
    }])
  })], 1), _vm.data.length ? [_c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("span", [_c("label", {
    staticStyle: {
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Trung bình cân nặng 1 SP: ")]), _c("span", [_vm._v(_vm._s(_vm.summary.avgWeight))])]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-left": "4px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleClickAvg
    }
  }, [_vm._v("Chọn giá trị trung bình")])], 1)] : _vm._e(), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1), _c("UpdatePalletWeighHistoryModal", {
    ref: "UpdatePalletWeighHistoryModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;