"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", [_vm.$checkAction("staff-members") ? _c("el-tab-pane", {
    attrs: {
      label: "Danh sách nhân viên"
    }
  }, [_c("employee-page")], 1) : _vm._e(), _vm.$checkAction("staff-members") ? _c("el-tab-pane", {
    attrs: {
      lazy: "",
      label: "Lịch sử đăng nhập"
    }
  }, [_c("DeviceSessionTab")], 1) : _vm._e(), _vm.$checkAction("staff-members") ? _c("el-tab-pane", {
    attrs: {
      lazy: "",
      label: "Nhóm user"
    }
  }, [_c("StaffGroupPage")], 1) : _vm._e(), _vm.$checkAction("permission-levels") ? _c("el-tab-pane", {
    attrs: {
      lazy: "",
      label: "Quyền"
    }
  }, [_c("role")], 1) : _vm._e()], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;