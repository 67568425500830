"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deliveryNoteApi = void 0;

var _request = require("@/utils/request");

var deliveryNoteApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/deliveryNote',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/deliveryNote/createOrUpdate',
      data: data,
      method: 'post'
    });
  }
};
exports.deliveryNoteApi = deliveryNoteApi;