"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới" : "Cập nhật",
      visible: _vm.visible,
      width: _vm.modalWidth,
      top: "30px",
      "close-on-press-escape": false,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      name: "info",
      label: "Thông tin"
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: "Danh mục"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: "",
      value: _vm.form.name
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Loại"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.ProductCategoryTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Tỷ số quy đổi thể tích"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.ratioChange,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ratioChange", $$v);
      },
      expression: "form.ratioChange"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Độ khó vận chuyển"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.difficultLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "difficultLevel", $$v);
      },
      expression: "form.difficultLevel"
    }
  })], 1)], 1)], 1), _c("el-tab-pane", {
    attrs: {
      name: "product",
      label: "Sản phẩm"
    }
  }, [_c("product-tab", {
    attrs: {
      productCategoryId: _vm.form.id
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-size--medium font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _vm.tabName == "info" ? _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loadingSubmit
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Lưu")]) : _vm._e()], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;