"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaCustomerFollowApi = void 0;

var _request = require("@/utils/request");

var misaCustomerFollowApi = {
  follow: function follow(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaCustomerFollow/follow',
      data: data,
      method: 'post'
    });
  },
  unFollow: function unFollow(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaCustomerFollow/follow',
      data: data,
      method: 'delete'
    });
  }
};
exports.misaCustomerFollowApi = misaCustomerFollowApi;