"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "quote-process-tab"
  }, [_c("el-tabs", [_vm.visibleLog ? _c("el-tab-pane", {
    attrs: {
      label: "Log làm việc"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("quote-log", {
    ref: "log",
    attrs: {
      quoteId: _vm.quoteId
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("todo-list", {
    attrs: {
      quoteId: _vm.quoteId,
      isFocus: _vm.isFocus
    },
    on: {
      submit: _vm.handleTodoSubmit
    }
  })], 1)], 1)], 1) : _vm._e(), _c("el-tab-pane", {
    attrs: {
      label: "Báo giá"
    }
  }, [_vm.totalQuantity >= 10000 ? _c("el-tag", {
    staticStyle: {
      "font-size": "25px",
      "line-height": "48px",
      height: "48px"
    },
    attrs: {
      type: "warning",
      effect: "dark"
    }
  }, [_vm._v(" Duyệt đơn hỏi rõ khách số lượng này lấy trong bao lâu ")]) : _vm._e(), _c("quote-product-panel", {
    staticStyle: {
      "margin-top": "32px"
    },
    attrs: {
      onAdd: _vm.onAddProd,
      products: _vm.form.products,
      onDelete: _vm.onDeleteProd,
      onAddOther: _vm.onAddOther,
      onUpdate: _vm.onUpdateProd
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Cấu hình in"
    }
  }, [_c("quote-note-panel", {
    ref: "note",
    attrs: {
      form: _vm.form,
      expiredQuoteConfig: _vm.expiredQuoteConfig,
      visibleForm: _vm.visibleForm
    }
  })], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;