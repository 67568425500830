"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới nhân viên" : "Cập nhật nhân viên",
      visible: _vm.visible,
      width: "600px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("employee-form", {
    ref: "employeeForm",
    attrs: {
      rules: _vm.rules,
      form: _vm.form,
      roles: _vm.roles,
      visiblePassword: _vm.status == "create",
      visibleStaffPermission: _vm.status != "create",
      handleUpdatePhone: _vm.handleUpdatePhone,
      staffGroups: _vm.staffGroups
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", [_vm.status != "create" ? _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "danger",
      size: "default"
    },
    on: {
      click: _vm.deleteData
    }
  }, [_vm._v("Xóa")]) : _vm._e(), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "medium",
      type: "warning",
      loading: _vm.loadingReset
    },
    on: {
      click: _vm.handleResetPwd
    }
  }, [_vm._v("Reset mật khẩu")])], 1), _c("div", {}, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "medium"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loadingSubmit
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Lưu")])], 1)]), _c("OTPModal", {
    ref: "OTPModal",
    on: {
      submitOk: _vm.handleOTPOk
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;