"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MisaDeliveryStatusName = exports.MisaDeliveryStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _MisaDeliveryStatusNa;

var MisaDeliveryStatus;
exports.MisaDeliveryStatus = MisaDeliveryStatus;

(function (MisaDeliveryStatus) {
  MisaDeliveryStatus["Pending"] = "PENDING";
  MisaDeliveryStatus["Delivering"] = "DELIVERING";
  MisaDeliveryStatus["Complete"] = "COMPLETE";
})(MisaDeliveryStatus || (exports.MisaDeliveryStatus = MisaDeliveryStatus = {}));

var MisaDeliveryStatusName = (_MisaDeliveryStatusNa = {}, (0, _defineProperty2.default)(_MisaDeliveryStatusNa, MisaDeliveryStatus.Pending, 'Chưa giao'), (0, _defineProperty2.default)(_MisaDeliveryStatusNa, MisaDeliveryStatus.Delivering, 'Đang giao'), (0, _defineProperty2.default)(_MisaDeliveryStatusNa, MisaDeliveryStatus.Complete, 'Đã giao đủ'), _MisaDeliveryStatusNa);
exports.MisaDeliveryStatusName = MisaDeliveryStatusName;