"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("h3", [_vm._v("Phát hiện " + _vm._s(_vm.noVatProducts.length) + " sản phẩm không thể xuất VAT")]), _vm._l(_vm.noVatProducts, function (item, index) {
    return _c("div", {
      key: index
    }, [_vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(item.product.name))]), _c("br"), _vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Đơn giá:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(item.price, 0, ".")) + " "), _c("br"), _vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Số lượng:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(item.quantity, 0, ".")) + " " + _vm._s(item.product.unit ? "(".concat(item.product.unit, ")") : "") + " "), _c("br"), _vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Thành tiền:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(item.price * item.quantity, 0, ".")) + " "), _c("br"), _c("br")]);
  }), _c("el-table", {
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      width: "350"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-plus"
          },
          on: {
            click: _vm.handleAddProd
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row,
            $index = _ref.$index;
        return [$index == 0 ? _c("span", [_vm._v(" Tổng tiền hoá đơn cần quy đổi ")]) : _c("span", [_c("el-select", {
          attrs: {
            remote: "",
            "remote-method": _vm.fetchProducts,
            filterable: "",
            clearable: "",
            "value-key": "id"
          },
          on: {
            change: function change($event) {
              return _vm.handleChangeProd($event, $index);
            }
          },
          model: {
            value: row.product,
            callback: function callback($$v) {
              _vm.$set(row, "product", $$v);
            },
            expression: "row.product"
          }
        }, _vm._l(_vm.products, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: item.name,
              value: item
            }
          });
        }), 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đơn giá"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [[_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.price, 3)) + " ")]];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thành tiền"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row,
            $index = _ref4.$index;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          attrs: {
            disabled: $index == 0 || $index == _vm.data.length - 1
          },
          on: {
            input: _vm.handleChangeAmount
          },
          model: {
            value: row.amount,
            callback: function callback($$v) {
              _vm.$set(row, "amount", $$v);
            },
            expression: "row.amount"
          }
        })];
      }
    }])
  })], 1), !_vm.visibleConfirm ? _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.handleBalance
    }
  }, [_vm._v("Cân bằng phiếu")]) : _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleConfirm
    }
  }, [_vm._v("Xác nhận đã cân bằng")])], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;