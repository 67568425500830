"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductionWorkMachineStatusTrans = exports.ProductionWorkMachineStatusColors = exports.ProductionWorkMachineStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _ProductionWorkMachin, _ProductionWorkMachin2;

var ProductionWorkMachineStatus;
exports.ProductionWorkMachineStatus = ProductionWorkMachineStatus;

(function (ProductionWorkMachineStatus) {
  ProductionWorkMachineStatus["Pending"] = "PENDING";
  ProductionWorkMachineStatus["Processing"] = "PROCESSING";
  ProductionWorkMachineStatus["Pause"] = "PASSE";
  ProductionWorkMachineStatus["Complete"] = "COMPLETE";
})(ProductionWorkMachineStatus || (exports.ProductionWorkMachineStatus = ProductionWorkMachineStatus = {}));

var ProductionWorkMachineStatusColors = (_ProductionWorkMachin = {}, (0, _defineProperty2.default)(_ProductionWorkMachin, ProductionWorkMachineStatus.Pending, '#e6a23c'), (0, _defineProperty2.default)(_ProductionWorkMachin, ProductionWorkMachineStatus.Processing, '#2499f6'), (0, _defineProperty2.default)(_ProductionWorkMachin, ProductionWorkMachineStatus.Pause, '#rgb'), (0, _defineProperty2.default)(_ProductionWorkMachin, ProductionWorkMachineStatus.Complete, '#13ce66'), _ProductionWorkMachin);
exports.ProductionWorkMachineStatusColors = ProductionWorkMachineStatusColors;
var ProductionWorkMachineStatusTrans = (_ProductionWorkMachin2 = {}, (0, _defineProperty2.default)(_ProductionWorkMachin2, ProductionWorkMachineStatus.Pending, 'Chờ sản xuất'), (0, _defineProperty2.default)(_ProductionWorkMachin2, ProductionWorkMachineStatus.Processing, 'Đang thực hiện'), (0, _defineProperty2.default)(_ProductionWorkMachin2, ProductionWorkMachineStatus.Pause, 'Tạm dừng'), (0, _defineProperty2.default)(_ProductionWorkMachin2, ProductionWorkMachineStatus.Complete, 'Hoàn thành'), _ProductionWorkMachin2);
exports.ProductionWorkMachineStatusTrans = ProductionWorkMachineStatusTrans;