"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      width: "148mm"
    },
    style: _vm.$devMode || _vm.visible ? "display: block" : "display: none"
  }, [_c("div", {
    attrs: {
      id: "report-btp-print"
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      "font-size": "70px",
      "font-weight": "bold"
    }
  }, [_vm._v(" " + _vm._s(_vm.sizeString) + " ")]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.hiddenName(_vm.workOrder)) + " ")])])])]), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center",
      "padding-left": "10px"
    }
  }, [_c("qrcode-vue", {
    attrs: {
      value: _vm.workOrder.code,
      size: _vm.qrSize,
      level: "H",
      "render-as": "svg"
    }
  }), _vm._m(0)], 1), _c("div", {
    staticStyle: {
      "text-align": "center",
      flex: "1"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "100px",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.lsxCode))]), _vm._m(1)])]), _vm.workOrder ? [_c("div", [_vm.note ? _c("div", {
    staticStyle: {
      color: "red",
      "font-weight": "bold",
      "margin-top": "12px",
      "font-size": "20px"
    }
  }, [_c("label", {
    staticStyle: {
      "text-decoration": "underline"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Ghi chú LSX: ")]), _c("span", [_vm._v(_vm._s(_vm.note))])]) : _vm._e()]), _c("div", {
    staticClass: "raw-table",
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("table", [_vm._m(2), _vm._l(_vm.workOrder.productionWorks, function (item, index) {
    return _c("tr", {
      key: item.id
    }, [_c("td", [_vm._v(" " + _vm._s(item.productionStep.name) + " "), _vm.productionReport && item.productionStep.id == _vm.productionReport.productionWork.productionStep.id ? [_vm._v(" (Hiện tại)")] : _vm._e()], 2), _c("td", [_vm._v(_vm._s(item.note))])]);
  })], 2)])] : _vm._e()], 2)]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("span", [_vm._v("Xem lệnh sản xuất")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("span", [_vm._v("Mã lệnh sản xuất")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("tr", [_c("td", {
    staticStyle: {
      width: "150px"
    }
  }, [_c("label", {
    staticStyle: {
      "font-weight": "bold"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Quy trình")])]), _c("td", [_c("label", {
    staticStyle: {
      "font-weight": "bold"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Ghi chú")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;