"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("./admin/index.vue"));

//not admin dashboard
var _default = {
  name: "Dashboard",
  components: {
    adminDashboard: _index.default
  },
  data: function data() {
    return {
      currentRole: "adminDashboard"
    };
  }
};
exports.default = _default;