"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_vm.visiblePrint ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.onPrint
    }
  }, [_vm._v("In")]) : _vm._e(), _c("div", {
    attrs: {
      id: _vm.shouldPrint ? "report-day-print" : ""
    }
  }, [_vm._m(0), _c("div", {
    staticClass: "text-center"
  }, [_vm.productionStep ? _c("span", [_vm._v(" MẪU NÀY DÀNH CHO " + _vm._s(_vm.productionStep.name.toUpperCase()) + " ")]) : _vm._e()]), _c("div", {
    staticClass: "text-center"
  }, [_vm.date ? _c("span", [_vm._v("Ngày " + _vm._s(_vm.date))]) : _vm._e()]), _c("div", {
    staticClass: "raw-table table-wrapper",
    staticStyle: {
      "margin-top": "20px",
      "overflow-x": "auto"
    }
  }, [_c("table", {
    staticStyle: {
      "overflow-x": "auto"
    }
  }, [_c("tr", [_c("td", {
    staticClass: "font-bold text-center",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("TT")]), _c("td", {
    staticClass: "font-bold",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Tên thùng")]), _c("td", {
    staticClass: "font-bold text-center",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Số lượng ")]), _c("td", {
    staticClass: "font-bold text-center",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Canh máy ")]), _vm.visibleBe ? _c("td", {
    staticClass: "font-bold text-center",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Số con bế ")]) : _vm._e(), _vm.visibleBe ? _c("td", {
    staticClass: "font-bold",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Loại ")]) : _vm._e(), _c("td", {
    staticClass: "font-bold text-center",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Tổng cộng ")]), _c("td", {
    staticClass: "font-bold text-center",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Hao hụt ")]), _c("td", {
    staticClass: "font-bold text-center",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Tồn NVL ")])]), _vm._l(_vm.convertedData, function (item, index) {
    return _c("tr", {
      key: index
    }, [_c("td", {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c("td", [_c("span", [_vm._v(" " + _vm._s(item.name) + " ")]), item.notes && item.notes.length ? _c("div", _vm._l(item.notes, function (note, index) {
      return _c("div", [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(note) + " ")]);
    }), 0) : _vm._e()]), _c("td", {
      staticClass: "text-center",
      staticStyle: {
        "text-decoration": "underline",
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.onViewDetail(item);
        }
      }
    }, [_c("span", {
      style: {
        color: item.isWarning ? "red" : "#000"
      }
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.quantity)) + " ")])]), _c("td", {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.totalCanhMay)) + " ")]), _vm.visibleBe ? _c("td", {
      staticClass: "text-center"
    }, [_c("span", [_vm._v(" " + _vm._s(item.numOfBe) + " ")])]) : _vm._e(), _vm.visibleBe ? _c("td", [_vm._v(" " + _vm._s(item.beType == "BE" ? "Bế" : "Chạp") + " ")]) : _vm._e(), _c("td", {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.totalQuantity)) + " ")]), _c("td", {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(item.totalLost)))]), _c("td", {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(item.isOutStock ? "Hết NVL" : "Còn NVL") + " ")])]);
  }), _c("tr", [_c("td", {
    staticStyle: {
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("Tổng cộng")]), _vm._l(_vm.maxCol, function (index) {
    return _c("td", {
      key: index,
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.getSumQuantity(index))) + " ")]);
  }), _c("td", {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.getTotalCanhMay())) + " ")]), _vm.visibleBe ? _c("td", {
    staticClass: "text-center"
  }) : _vm._e(), _vm.visibleBe ? _c("td", {
    staticClass: "text-center"
  }) : _vm._e(), _c("td", {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.getTotalQuantity())) + " ")]), _c("td", {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.getTotalLoss())))]), _c("td", {
    staticClass: "text-center"
  })], 2)], 2)])])], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "text-center"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" BÁO CÁO THÀNH PHẨM HẢI ĐĂNG ")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;