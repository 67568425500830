"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VietboxLeadStatusTrans = exports.VietboxLeadStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _VietboxLeadStatusTra;

var VietboxLeadStatus;
exports.VietboxLeadStatus = VietboxLeadStatus;

(function (VietboxLeadStatus) {
  VietboxLeadStatus["New"] = "NEW";
  VietboxLeadStatus["HasPhone"] = "HAS_PHONE";
  VietboxLeadStatus["Contact"] = "CONTACT";
  VietboxLeadStatus["Info"] = "INFO";
  VietboxLeadStatus["Quote"] = "QUOTE";
  VietboxLeadStatus["Free"] = "FREE";
  VietboxLeadStatus["Fail"] = "FAIL";
})(VietboxLeadStatus || (exports.VietboxLeadStatus = VietboxLeadStatus = {}));

var VietboxLeadStatusTrans = (_VietboxLeadStatusTra = {}, (0, _defineProperty2.default)(_VietboxLeadStatusTra, VietboxLeadStatus.New, "Chưa phân loại"), (0, _defineProperty2.default)(_VietboxLeadStatusTra, VietboxLeadStatus.HasPhone, "Đã có SĐT"), (0, _defineProperty2.default)(_VietboxLeadStatusTra, VietboxLeadStatus.Contact, "Đã liên hệ"), (0, _defineProperty2.default)(_VietboxLeadStatusTra, VietboxLeadStatus.Info, "Đang lấy yêu cầu"), (0, _defineProperty2.default)(_VietboxLeadStatusTra, VietboxLeadStatus.Quote, "Đã báo giá"), (0, _defineProperty2.default)(_VietboxLeadStatusTra, VietboxLeadStatus.Free, "Tự do"), (0, _defineProperty2.default)(_VietboxLeadStatusTra, VietboxLeadStatus.Fail, "Thất bại"), _VietboxLeadStatusTra);
exports.VietboxLeadStatusTrans = VietboxLeadStatusTrans;