"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Chỉnh sửa lịch sản xuất",
      visible: _vm.visible,
      width: "1200px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "panel-shadow",
    staticStyle: {
      width: "400px",
      "margin-bottom": "32px"
    }
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v("Trạng thái sản xuất")]), _c("el-select", {
    attrs: {
      filterable: ""
    },
    on: {
      change: _vm.handleChangeScheduleProductionStatus
    },
    model: {
      value: _vm.quote.scheduleProductionStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.quote, "scheduleProductionStatus", $$v);
      },
      expression: "quote.scheduleProductionStatus"
    }
  }, _vm._l(_vm.ScheduleProductionStatusTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      type: "danger",
      size: "small",
      loading: _vm.loadingDelete
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa lịch sản xuất")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.form,
      border: "",
      fit: "",
      "row-key": "id"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Sản phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" Thùng carton " + _vm._s(row.productQuote.length) + "x" + _vm._s(row.productQuote.width) + "x" + _vm._s(row.productQuote.height) + " (" + _vm._s(row.productQuote.printCost ? "In ".concat(row.productQuote.numColor, " m\xE0u, ") : "") + " " + _vm._s(row.productQuote.layer) + " lớp, " + _vm._s(row.productQuote.cartonStruct.name) + ", " + _vm._s(row.productQuote.cartonWave.name) + ") ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Màu cho lịch"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-color-picker", {
          model: {
            value: row.color,
            callback: function callback($$v) {
              _vm.$set(row, "color", $$v);
            },
            expression: "row.color"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày bắt đầu",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-date-picker", {
          attrs: {
            format: "dd/MM/yyyy",
            "value-format": "yyyy-MM-dd"
          },
          model: {
            value: row.startAt,
            callback: function callback($$v) {
              _vm.$set(row, "startAt", $$v);
            },
            expression: "row.startAt"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày kết thúc",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("el-date-picker", {
          attrs: {
            "value-format": "yyyy-MM-dd",
            format: "dd/MM/yyyy"
          },
          on: {
            change: function change() {
              return _vm.changeEnd(row);
            }
          },
          model: {
            value: row.endAt,
            callback: function callback($$v) {
              _vm.$set(row, "endAt", $$v);
            },
            expression: "row.endAt"
          }
        })];
      }
    }])
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loadingView
    },
    on: {
      click: _vm.viewDetail
    }
  }, [_vm._v("Chi tiết báo giá")])], 1), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loadingSubmit
    },
    on: {
      click: _vm.createData
    }
  }, [_vm._v("Đồng ý")])], 1), _c("quote-modal", {
    ref: "QuoteModal",
    on: {
      "submit:ok": _vm.onSubmitOk
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;