"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Tạo báo cáo sản lượng" : "Cập nhật báo cáo sản lượng",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Diễn giải",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _vm.visibleSelectProductionWork ? _c("el-form-item", {
    attrs: {
      label: "Lệnh làm việc",
      prop: "productionWorkId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    on: {
      change: _vm.handleChangeProductionWork
    },
    model: {
      value: _vm.form.productionWorkId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "productionWorkId", $$v);
      },
      expression: "form.productionWorkId"
    }
  }, _vm._l(_vm.productionWorks, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.code, " - ").concat(item.productionStep.name),
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng",
      prop: "quantity"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hao hụt",
      prop: "loss"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n              clearIncomplete: true,\n            }"
    }],
    model: {
      value: _vm.form.loss,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "loss", $$v);
      },
      expression: "form.loss"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Canh máy",
      prop: "canhMay"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n              clearIncomplete: true,\n            }"
    }],
    model: {
      value: _vm.form.canhMay,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "canhMay", $$v);
      },
      expression: "form.canhMay"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "isOutStock"
    }
  }, [_c("el-checkbox", {
    staticStyle: {
      transform: "translateY(32px)"
    },
    model: {
      value: _vm.form.isOutStock,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isOutStock", $$v);
      },
      expression: "form.isOutStock"
    }
  }, [_vm._v("Hết NVL")])], 1)], 1), _vm.productionWork && _vm.productionWork.productionStep && (_vm.productionWork.productionStep.name.toLowerCase().includes("in") || _vm.productionWork.productionStep.name.toLowerCase().includes("bế")) ? [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại",
      prop: "beType"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.beType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "beType", $$v);
      },
      expression: "form.beType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Chạp",
      value: "CHAP"
    }
  }), _c("el-option", {
    attrs: {
      label: "Bế",
      value: "BE"
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_vm.form.beType == "BE" ? _c("el-form-item", {
    attrs: {
      label: "Số lượng con bế",
      prop: "numBe"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.numOfBe,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "numOfBe", $$v);
      },
      expression: "form.numOfBe"
    }
  }, _vm._l(_vm.numOfBes, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: "".concat(item, " con"),
        value: item
      }
    });
  }), 1)], 1) : _vm._e()], 1)] : _vm._e()], 2), _c("el-form-item", {
    attrs: {
      label: "Ghi chú",
      prop: "note"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Hình ảnh",
      prop: "image"
    }
  }, [_c("SingleImage", {
    attrs: {
      imagePath: _vm.form.image,
      visibleDelete: true,
      uploadUrl: "/v1/admin/productionReport/upload"
    },
    on: {
      "upload:success": _vm.handleUploadOk,
      delete: _vm.handleDeleteImage
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;