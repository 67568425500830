"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Mã",
      prop: "code",
      width: "110"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng (NCC)",
      prop: "materialCode"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.getTamXa(row))) + " ")];
      }
    }])
  }), _vm.version != 1 ? [_c("el-table-column", {
    attrs: {
      label: "Số lượng (Thực tế)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalRealQuantity)) + " ")];
      }
    }], null, false, 644391329)
  }), _c("el-table-column", {
    attrs: {
      label: "Số pallet"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-link", {
          staticStyle: {
            display: "block"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.onClickTotalPallet(row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalPallet)) + " ")]), _c("el-link", {
          staticStyle: {
            display: "block"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.onAddPallet(row);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-plus"
        }), _vm._v(" Thêm pallet ")]), _vm.version == 3 ? _c("el-link", {
          staticStyle: {
            display: "block"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.onAddPalletCotBo(row);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-plus"
        }), _vm._v(" Thêm pallet (Cột bó) ")]) : _vm._e()];
      }
    }], null, false, 2269786916)
  })] : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "NCC",
      prop: "providerName"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Biển số",
      prop: "licensePlate"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "NV kho",
      prop: "staffName"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày nhập"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " ")];
      }
    }])
  }), _vm.visiblePrint ? _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm.version == 1 ? _c("el-button", {
          attrs: {
            type: "primary",
            icon: "el-icon-printer"
          },
          on: {
            click: function click($event) {
              return _vm.onPrint(row);
            }
          }
        }, [_vm._v("In")]) : _vm._e(), _vm.visibleAction ? [_c("el-button", {
          attrs: {
            type: "primary",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.onUpdate(row);
            }
          }
        }, [_vm._v("Sửa")]), _c("el-button", {
          attrs: {
            type: "danger",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.onDelete(row);
            }
          }
        }, [_vm._v("Xóa")])] : _vm._e()];
      }
    }], null, false, 1330077502)
  }) : _vm._e()], 2)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;