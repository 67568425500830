"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productPricingApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var productPricingApi = {
  update: function update(data) {
    return (0, _request.default)({
      url: "/v1/admin/productPricing",
      method: 'put',
      data: data
    });
  },
  updatePricingByCustomer: function updatePricingByCustomer(data) {
    return (0, _request.default)({
      url: "/v1/admin/productPricing/pricing-by-customer",
      method: 'put',
      data: data
    });
  }
};
exports.productPricingApi = productPricingApi;