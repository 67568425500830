"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Tạo ghi chú",
      visible: _vm.visible,
      "close-on-click-modal": false,
      width: "500px",
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nội dung ghi chú",
      prop: "content"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1), _c("el-form-item", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingMisaEmployee,
      expression: "loadingMisaEmployee"
    }],
    attrs: {
      label: "Người thực hiện",
      prop: "misaEmployeeId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      disabled: _vm.user.role.id != 1,
      remote: "",
      "remote-method": _vm.fetchMisaEmployees
    },
    model: {
      value: _vm.form.misaEmployeeId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaEmployeeId", $$v);
      },
      expression: "form.misaEmployeeId"
    }
  }, _vm._l(_vm.misaEmployees, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Lưu")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;