"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Chọn nhiệm vụ cho",
      visible: _vm.visible,
      width: "700px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleChoose("customer");
      }
    }
  }, [_vm._v("Khách hàng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleChoose("opportunity");
      }
    }
  }, [_vm._v("Cơ hội")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleChoose("lead");
      }
    }
  }, [_vm._v("Tiềm năng")])], 1), _c("CreateMisaActivityCustomer", {
    ref: "CreateMisaActivityCustomer",
    on: {
      submitOk: _vm.onSubmitOk
    }
  }), _c("CreateMisaActivityOpportunity", {
    ref: "CreateMisaActivityOpportunity",
    on: {
      submitOk: _vm.onSubmitOk
    }
  }), _c("CreateMisaActivityLead", {
    ref: "CreateMisaActivityLead",
    on: {
      submitOk: _vm.onSubmitOk
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;