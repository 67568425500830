"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "panel-shadow"
  }, [_c("el-switch", {
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ff4949",
      "active-text": "Chỉ tính thùng carton"
    },
    on: {
      change: _vm.handleSwitchOnlyCarton
    },
    model: {
      value: _vm.isOnlyCarton,
      callback: function callback($$v) {
        _vm.isOnlyCarton = $$v;
      },
      expression: "isOnlyCarton"
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng khối lượng quy đổi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(Math.round(_vm.totalWeight))) + " Kg "), _vm.cartonWeight ? _c("span", [_vm._v("(" + _vm._s((_vm.cartonWeight / 80).toFixed(1)) + " lần ship)")]) : _vm._e()])])]), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng khối lượng thực tế: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(Math.round(_vm.totalWeightReal))) + " Kg")])])]), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng thể tích thực tế: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalVolumeReal / 1000, 1)) + " m"), _c("sup", [_vm._v("3")])])])])], 1)], 1), _c("vehicle-tab", {
    attrs: {
      productTable: _vm.productTable,
      totalVolumeReal: _vm.totalVolumeReal,
      totalWeight: _vm.totalWeight,
      totalWeightReal: _vm.totalWeightReal,
      vehicles: _vm.vehicles,
      containerClass: "grid-4"
    }
  }), _c("div", {
    staticClass: "panel-shadow",
    staticStyle: {
      "margin-top": "32px",
      display: "none"
    }
  }, [_c("h3", {
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v("Danh sách sản phẩm")]), _c("product-table", {
    attrs: {
      productXopHoi: _vm.xopHoi,
      products: _vm.productTable,
      onDelete: _vm.handleDelete
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;