"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Nhập excel tiềm năng",
      visible: _vm.visible,
      width: "700px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("UploadExcel", {
    attrs: {
      onSuccess: _vm.handleUpload
    }
  }), _c("el-link", {
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      type: "primary",
      href: "/nhap-chao-hang-vietbox.xlsx"
    }
  }, [_c("div", {
    staticClass: "el-icon-download"
  }), _vm._v(" Tải file mẫu ")]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading,
      disabled: _vm.data.length == 0
    },
    on: {
      click: _vm.createData
    }
  }, [_vm._v("Nhập excel")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;