"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wooApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var wooApi = {
  findProduct: function findProduct(data) {
    return (0, _request.default)({
      url: "/v1/admin/woo/products",
      data: data,
      method: 'post'
    });
  },
  updateProduct: function updateProduct(data) {
    return (0, _request.default)({
      url: "/v1/admin/woo/products",
      data: data,
      method: 'put'
    });
  },
  syncProductId: function syncProductId(data) {
    return (0, _request.default)({
      url: "/v1/admin/woo/products/sync-id",
      data: data,
      method: 'put'
    });
  },
  findCategories: function findCategories(data) {
    return (0, _request.default)({
      url: "/v1/admin/woo/products/categories",
      data: data,
      method: 'post'
    });
  }
};
exports.wooApi = wooApi;