"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }), _c("section", [_c("h2", [_vm._v("Cấu hình doanh số")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Cấu hình",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.ConfigParamsTrans[row.param]) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị (VND)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.value)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" Cập nhật ")])];
      }
    }])
  })], 1)], 1), _c("section", [_c("h2", [_vm._v("Cấu hình câu")]), _c("div", {
    staticClass: "filter-container"
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreateContent
    }
  }, [_vm._v("Thêm mới")])], 1), _c("el-table", {
    attrs: {
      data: _vm.contentDefines,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tiêu đề",
      prop: "title"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Nội dung",
      prop: "content"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdateContent(row);
            }
          }
        }, [_vm._v("Cập nhật")])];
      }
    }])
  })], 1)], 1), _c("ConfigurationModal", {
    ref: "ConfigurationModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ContentDefineModal", {
    ref: "ContentDefineModal",
    on: {
      submitOk: _vm.fetchContentDefines
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;