"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.misaOrders,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Mã đơn",
      prop: "code"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng",
      prop: "customerName"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày đơn hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDate(row.orderedAt)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái",
      prop: "status"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng tiền"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amount)) + "đ ")];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;