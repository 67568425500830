"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_vm.status == "checking" ? _c("el-alert", {
    attrs: {
      title: "Đang kiểm dữ liệu",
      type: "warning"
    }
  }) : _vm.status == "ok" ? _c("el-result", {
    attrs: {
      icon: "success",
      title: "Đã kiểm thành công",
      subTitle: "Không có lỗi nào"
    }
  }) : _c("el-result", {
    attrs: {
      icon: "error",
      title: "Đã kiểm không khớp giá",
      subTitle: "Xem chi tiết bên dưới"
    }
  }), _vm.data.length ? _c("div", [_c("ul", _vm._l(_vm.data, function (item, index) {
    return _c("li", {
      key: index
    }, [_c("span", {
      style: {
        color: item.isError ? "red" : "#000"
      }
    }, [_vm._v(" " + _vm._s(item.message) + " ")])]);
  }), 0)]) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;