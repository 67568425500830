"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.otpApi = void 0;

var _request = require("@/utils/request");

var otpApi = {
  get: function get(params) {
    return (0, _request.request)({
      url: '/v1/admin/otp',
      params: params
    });
  },
  verify: function verify(code) {
    return (0, _request.request)({
      url: '/v1/admin/otp/verify',
      data: {
        code: code
      },
      method: 'post'
    });
  }
};
exports.otpApi = otpApi;