"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "work-calendar-page",
    style: {
      "padding-top": _vm.isSelectTime ? "18px" : "0"
    }
  }, [_vm.loading ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mask-loading"
  }) : _c("div", [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhân viên")]), _c("el-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      filterable: "",
      clearable: "",
      size: "medium"
    },
    on: {
      change: function change($event) {
        _vm.handleChangeEmployee(_vm.query.staffId);

        _vm.watchQuery();
      }
    },
    model: {
      value: _vm.query.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "staffId", $$v);
      },
      expression: "query.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày")]), _c("div", {
    staticClass: "date-picker"
  }, [_c("el-date-picker", {
    ref: "datePicker",
    attrs: {
      size: "medium",
      format: "yyyy-MM-dd",
      clearable: false,
      type: _vm.viewMode == "day" || _vm.viewMode == "three-days" ? "date" : "week"
    },
    on: {
      change: function change($event) {
        _vm.fetchTodos();

        _vm.watchQuery();
      }
    },
    model: {
      value: _vm.query.date,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "date", $$v);
      },
      expression: "query.date"
    }
  }), _c("div", {
    staticClass: "mask-date-picker",
    on: {
      click: function click($event) {
        return _vm.$refs.datePicker.focus();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate) + " ")])], 1)]), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Chế độ xem")]), _c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      size: "medium"
    },
    on: {
      change: function change($event) {
        _vm.handleChangeViewMode(_vm.viewMode);

        _vm.watchQuery();
      }
    },
    model: {
      value: _vm.viewMode,
      callback: function callback($$v) {
        _vm.viewMode = $$v;
      },
      expression: "viewMode"
    }
  }, _vm._l(_vm.viewModes, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-button", {
    staticClass: "font-bold font-size--medium filter-item",
    staticStyle: {
      transform: "translateY(11px)"
    },
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.handleClickToDay
    }
  }, [_vm._v("Hôm nay")]), _c("el-button", {
    staticClass: "font-bold font-size--medium filter-item",
    staticStyle: {
      transform: "translateY(11px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: function click($event) {
        return _vm.handleNextPrevDate("prev");
      }
    }
  }), _c("el-button", {
    staticClass: "font-bold font-size--medium filter-item",
    staticStyle: {
      transform: "translateY(11px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-arrow-right"
    },
    on: {
      click: function click($event) {
        return _vm.handleNextPrevDate("next");
      }
    }
  })], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingCal,
      expression: "loadingCal"
    }],
    staticStyle: {
      background: "white"
    }
  }, [_c("calendar-component", {
    ref: "CalendarComponent",
    attrs: {
      dateSelected: _vm.query.date,
      events: _vm.events,
      viewMode: _vm.viewMode,
      splitDays: _vm.splitDays
    },
    on: {
      "change-event": _vm.handleChangeEvent,
      create: _vm.handleCreateEvent
    },
    scopedSlots: _vm._u([{
      key: "daySplit",
      fn: function fn(_ref) {
        var split = _ref.split;
        return [_vm.viewMode == "day" ? _c("employee-header", {
          attrs: {
            name: split.label,
            nameShort: split.nameShort,
            avatar: split.avatar
          }
        }) : _vm._e()];
      }
    }, {
      key: "event",
      fn: function fn(_ref2) {
        var event = _ref2.event;
        return [_c("el-popover", {
          attrs: {
            width: "400",
            placement: "top-start",
            trigger: "click"
          }
        }, [[_c("event-popover", {
          attrs: {
            todo: event.data.todo,
            onClickQuoteDetail: _vm.handleClickQuoteDetail
          },
          on: {
            "delete-todo": _vm.fetchTodos
          }
        })], _c("div", {
          staticClass: "event-custom",
          class: {
            done: event.data.todo.isDone,
            expired: !event.data.todo.isDone && event.data.todo.dueDate < _vm.currentTime
          },
          attrs: {
            slot: "reference"
          },
          on: {
            click: function click($event) {
              return _vm.$emit("event-click", event);
            }
          },
          slot: "reference"
        }, [_c("el-image", {
          staticStyle: {
            width: "40px",
            height: "40px",
            position: "absolute",
            right: "8px",
            "border-radius": "50%",
            overflow: "hidden"
          },
          attrs: {
            fit: "cover",
            src: _vm.$baseUrlMedia + event.data.todo.staff.avatar
          }
        }, [_c("div", {
          staticClass: "image-slot",
          attrs: {
            slot: "error"
          },
          slot: "error"
        }, [_c("i", {
          staticClass: "el-icon-picture-outline"
        })])]), [_c("div", {
          staticStyle: {
            display: "flex",
            height: "100%"
          }
        }, [_c("el-image", {
          staticStyle: {
            width: "60px",
            height: "60px",
            "align-self": "center"
          },
          attrs: {
            fit: "cover",
            src: _vm.$baseUrlMedia + event.data.todo.quote.image
          }
        }, [_c("div", {
          staticClass: "image-slot",
          attrs: {
            slot: "error"
          },
          slot: "error"
        }, [_c("i", {
          staticClass: "el-icon-picture-outline"
        })])]), _c("div", {
          staticStyle: {
            "margin-left": "4px",
            "padding-right": "48px"
          }
        }, [_c("span", {
          staticClass: "customer-name"
        }, [_vm._v(" " + _vm._s(event.data.todo.quote.customer.name) + " - " + _vm._s(event.data.todo.quote.customer.phone) + " ")]), _c("p", {
          staticClass: "description",
          staticStyle: {
            "margin-top": "0",
            "font-size": "13px"
          },
          style: {
            "text-decoration": event.data.todo.isDone ? "line-through" : "unset"
          }
        }, [_vm._v(_vm._s(event.data.todo.content))])])], 1)], _c("font-awesome-icon", {
          staticClass: "icon-resize",
          attrs: {
            icon: "arrows-alt-v"
          }
        })], 2)], 2)];
      }
    }])
  })], 1)]), _c("create-todo-modal", {
    ref: "CreateTodoModal",
    on: {
      "submit:ok": _vm.fetchTodos
    }
  }), _c("quote-modal", {
    ref: "QuoteModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;