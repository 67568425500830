"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-popover", {
    attrs: {
      placement: "top-end",
      width: "400",
      trigger: "click"
    }
  }, [_c("div", [_c("el-tabs", {
    attrs: {
      "tab-position": "top"
    },
    on: {
      "tab-click": _vm.handleChangeTab
    },
    model: {
      value: _vm.selectedTab,
      callback: function callback($$v) {
        _vm.selectedTab = $$v;
      },
      expression: "selectedTab"
    }
  }, _vm._l(_vm.tabs, function (item, index) {
    return _c("el-tab-pane", {
      key: item.name,
      attrs: {
        label: item.label,
        name: item.name
      }
    }, [_vm.data.length ? _vm._l(_vm.data, function (notification) {
      return _c("NotificationItem", {
        key: notification.id,
        attrs: {
          notification: notification
        }
      });
    }) : _c("div", {
      staticStyle: {
        "text-align": "center"
      }
    }, [_c("span", [_vm._v("Chưa có thông báo nào")])])], 2);
  }), 1)], 1), _c("div", {
    staticStyle: {
      width: "40px",
      height: "40px",
      padding: "16px",
      background: "#000",
      "border-radius": "50%",
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("img", {
    staticStyle: {
      width: "20px",
      height: "20px"
    },
    attrs: {
      src: _vm.notificationImage,
      alt: ""
    }
  })])])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;