"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.includes.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container production-work"
  }, [_c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-bottom": "4px"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    style: {
      width: _vm.isMobile ? "120px" : "unset"
    },
    attrs: {
      placeholder: "Tìm mã làm việc, lsx"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleChangeQuery.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thẻ")]), _c("el-select", {
    staticClass: "select-mobile",
    attrs: {
      filterable: "",
      clearable: "",
      multiple: ""
    },
    on: {
      change: _vm.handleChangeTag
    },
    model: {
      value: _vm.query.misaTagIds,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "misaTagIds", $$v);
      },
      expression: "query.misaTagIds"
    }
  }, _vm._l(_vm.misaTags, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("MisaTagComp", {
      attrs: {
        misaTag: item
      }
    })], 1);
  }), 1)], 1), !_vm.isMobile ? _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Ngày ")]), _c("el-date-picker", {
    attrs: {
      clearable: false,
      type: "daterange",
      format: "dd/MM/yyyy",
      "range-separator": "-",
      "start-placeholder": "",
      "end-placeholder": ""
    },
    on: {
      change: _vm.handleChangeDate
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "filter-item"
  }, [_c("span", {
    staticStyle: {
      transform: "translateY(15px)",
      "margin-left": "12px",
      display: "inline-block"
    }
  }, [_c("el-checkbox", {
    staticStyle: {
      "font-weight": "bold"
    },
    on: {
      change: _vm.handleChangeQuery
    },
    model: {
      value: _vm.query.hiddenPending,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "hiddenPending", $$v);
      },
      expression: "query.hiddenPending"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "16px",
      color: "red",
      "font-weight": "bold"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Ẩn lệnh đợi")])])], 1)]), _c("div", {
    staticClass: "filter-item"
  }, [_c("span", {
    staticStyle: {
      transform: "translateY(15px)",
      "margin-left": "12px",
      display: "inline-block"
    }
  }, [_c("el-checkbox", {
    staticStyle: {
      "font-weight": "bold"
    },
    on: {
      change: _vm.handleChangeQuery
    },
    model: {
      value: _vm.query.isAlmostDone,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isAlmostDone", $$v);
      },
      expression: "query.isAlmostDone"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "16px",
      color: "red",
      "font-weight": "bold"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Gần hoàn thành (>=95%)")])])], 1)])]), _c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm kiếm")])], 1), _c("el-dropdown", {
    attrs: {
      trigger: "click",
      type: "primary"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_vm.isAuth ? _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(5px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      disabled: _vm.selected.length == 0
    }
  }, [_vm._v("Thao tác "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]) : _vm._e(), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.status, function (item, key) {
    return _c("el-dropdown-item", {
      key: key,
      attrs: {
        command: "status:".concat(key)
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _vm.isMobile ? _c("el-dropdown", {
    staticStyle: {
      "margin-left": "4px"
    },
    attrs: {
      trigger: "click",
      type: "primary"
    },
    on: {
      command: _vm.handleCommandOther
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(5px)"
    },
    attrs: {
      type: "primary",
      size: "small"
    }
  }, [_vm._v("Khác "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.isAdmin ? _c("el-dropdown-item", {
    attrs: {
      command: "sort"
    }
  }, [_vm._v(" Sắp xếp ")]) : _vm._e(), _c("el-dropdown-item", {
    attrs: {
      command: "need"
    }
  }, [_vm._v(" Hàng cần lấy ")])], 1)], 1) : [_vm.isAdmin ? _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-sort",
      size: "small"
    },
    on: {
      click: _vm.handleSort
    }
  }, [_vm._v("Sắp xếp")]) : _vm._e(), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-s-promotion",
      size: "small"
    },
    on: {
      click: _vm.openHangCanLay
    }
  }, [_vm._v("Hàng cần lấy")])]], 2), _c("div", {
    staticClass: "collapse-container"
  }, [_c("el-collapse", {
    attrs: {
      accordion: ""
    },
    on: {
      change: _vm.handleChangeCollapse
    },
    model: {
      value: _vm.activeNames,
      callback: function callback($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, _vm._l(_vm.sections.filter(function (e) {
    return e.total && (e.key == _vm.ProductionWorkStatus.Complete && _vm.isAdmin || e.key != _vm.ProductionWorkStatus.Complete);
  }), function (section) {
    return _c("el-collapse-item", {
      key: section.key,
      attrs: {
        title: section.name,
        name: section.key
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("label", {
      staticStyle: {
        "font-size": "20px"
      },
      style: {
        color: section.color
      },
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(section.name) + " (" + _vm._s(section.total) + ") " + _vm._s(section.key == _vm.ProductionWorkStatus.New && _vm.diffDay ? "(".concat(_vm.diffDay, " ng\xE0y n\u1EEFa)") : ""))])]), _c("div", [_c("el-tabs", {
      attrs: {
        "tab-position": "top"
      },
      on: {
        "tab-click": _vm.handleTabClick
      },
      model: {
        value: _vm.tabName,
        callback: function callback($$v) {
          _vm.tabName = $$v;
        },
        expression: "tabName"
      }
    }, _vm._l(_vm.tabs.filter(function (e) {
      return e.count;
    }), function (item) {
      return _c("el-tab-pane", {
        key: item.key,
        attrs: {
          lazy: "",
          label: item.label,
          name: item.key
        }
      }, [_c("span", {
        attrs: {
          slot: "label"
        },
        slot: "label"
      }, [_vm._v(" " + _vm._s(item.label) + " "), _c("el-badge", {
        attrs: {
          value: item.count
        }
      })], 1), section.key != _vm.ProductionWorkStatus.Complete ? _vm._l(_vm.dateTab, function (date) {
        return _c("div", {
          key: date.key
        }, [_c("div", [_c("label", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(date.label) + " (" + _vm._s(_vm.dayNames[date.dayInWeek]) + ") "), _c("el-badge", {
          attrs: {
            value: date.total
          }
        })], 1)]), _c("ProductionWorkTable", {
          attrs: {
            productionWorks: _vm.getDataByDate(date.date),
            loading: _vm.loading,
            visibleAction: _vm.isAuth,
            date: date.date,
            selected: _vm.selected,
            visibleMarket: item.label == "In, Bế, Chạp (Máy in)" || item.label == "Bế tay" || item.label == "Máy in",
            visibleBTP: ["5", "6", "7"].includes(item.key),
            isAdmin: _vm.isAdmin,
            isHiddenName: !_vm.isAuth
          },
          on: {
            startProduce: _vm.handleStartProduce,
            reportQuantity: _vm.handleReportQuantity,
            endProduce: _vm.handleEndProduce,
            pauseProduce: _vm.handlePauseProduce,
            undo: _vm.handleUndo,
            clickWorkOrder: _vm.handleClickWorkOrder,
            selectionChange: _vm.handleChangeSelection,
            select: _vm.handleSelectRow,
            resumeWork: _vm.handleResumeProduce,
            changeStep: _vm.handleChangeStep,
            public: _vm.handlePublic,
            updateTag: _vm.handleUpdateTag,
            publicAll: _vm.handlePublicAll,
            note: _vm.handleUpdateNote,
            duration: _vm.handleUpdateDuration,
            viewQr: _vm.viewQr,
            log: _vm.viewLog,
            hiddenAttach: _vm.hiddenAttach,
            printBTP: _vm.handlePrintBTP
          }
        })], 1);
      }) : [_c("ProductionWorkTable", {
        attrs: {
          productionWorks: _vm.data,
          loading: _vm.loading,
          visibleAction: _vm.isAuth,
          selected: _vm.selected,
          visibleMarket: item.label == "In, Bế, Chạp (Máy in)" || item.label == "Bế tay" || item.label == "Máy in",
          visibleBTP: ["5", "6", "7"].includes(item.key),
          isAdmin: _vm.isAdmin,
          isHiddenName: !_vm.isAuth
        },
        on: {
          viewQr: _vm.viewQr,
          select: _vm.handleSelectRow,
          startProduce: _vm.handleStartProduce,
          reportQuantity: _vm.handleReportQuantity,
          endProduce: _vm.handleEndProduce,
          pauseProduce: _vm.handlePauseProduce,
          undo: _vm.handleUndo,
          clickWorkOrder: _vm.handleClickWorkOrder,
          selectionChange: _vm.handleChangeSelection,
          resumeWork: _vm.handleResumeProduce,
          changeStep: _vm.handleChangeStep,
          public: _vm.handlePublic,
          updateTag: _vm.handleUpdateTag,
          publicAll: _vm.handlePublicAll,
          note: _vm.handleUpdateNote,
          duration: _vm.handleUpdateDuration,
          log: _vm.viewLog,
          hiddenAttach: _vm.hiddenAttach,
          printBTP: _vm.handlePrintBTP
        }
      })]], 2);
    }), 1)], 1)], 2);
  }), 1)], 1), _c("StartProduceModal", {
    ref: "StartProduceModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ReportQuantityProductionModal", {
    ref: "ReportQuantityProductionModal"
  }), _c("WorkOrderModal", {
    ref: "WorkOrderModal"
  }), _c("SortProductionWorkModal", {
    ref: "SortProductionWorkModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ChangeStepModal", {
    ref: "ChangeStepModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("UpdateMisaTagModal", {
    ref: "UpdateMisaTagModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ProductionWorkNoteModal", {
    ref: "ProductionWorkNoteModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("UpdateWorkHourModal", {
    ref: "UpdateWorkHourModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ViewQRModal", {
    ref: "ViewQRModal"
  }), _c("ViewProductionLogModal", {
    ref: "ViewProductionLogModal"
  }), _c("WorkScanModal", {
    ref: "WorkScanModal"
  }), _c("ReportBTPPrint", {
    ref: "ReportBTPPrint",
    attrs: {
      productionWork: _vm.selectedProductionWork,
      workOrder: _vm.selectedWorkOrder
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;