"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Chung")]), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Lợi nhuận(%) ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.profitConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.profitConfig, "value", $$v);
      },
      expression: "profitConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Số thùng làm được mỗi giây ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.cartonPerSecondConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.cartonPerSecondConfig, "value", $$v);
      },
      expression: "cartonPerSecondConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Giá nguyên liệu ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.cartonPriceConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.cartonPriceConfig, "value", $$v);
      },
      expression: "cartonPriceConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Số giờ làm việc trong 1 ngày ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.cartonWorkingHourConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.cartonWorkingHourConfig, "value", $$v);
      },
      expression: "cartonWorkingHourConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Chi phí cột ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.cartonBindFeeConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.cartonBindFeeConfig, "value", $$v);
      },
      expression: "cartonBindFeeConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Chi phí dán ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.cartonPasteFeeConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.cartonPasteFeeConfig, "value", $$v);
      },
      expression: "cartonPasteFeeConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Chi phí công ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.netPriceConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.netPriceConfig, "value", $$v);
      },
      expression: "netPriceConfig.value"
    }
  })], 1), _c("div", {
    staticClass: "field",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Chi phí công dự kiến ")]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "150px"
    },
    model: {
      value: _vm.netPriceEtaConfig.value,
      callback: function callback($$v) {
        _vm.$set(_vm.netPriceEtaConfig, "value", $$v);
      },
      expression: "netPriceEtaConfig.value"
    }
  })], 1)]), _c("section", {
    staticClass: "box",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Máy dán (Auto)")]), _vm._l(_vm.configs, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "field"
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" " + _vm._s(_vm.ConfigParamsTrans[item.param]) + " ")]), _c("el-input", {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: {
          alias: "decimal",
          groupSeparator: ".",
          rightAlign: false,
          autoGroup: true,
          repeat: 20,
          autoUnmask: true,
          allowMinus: false
        },
        expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
      }],
      staticStyle: {
        width: "150px"
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1);
  })], 2), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loading
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("Lưu")])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;