"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var productApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/public/product',
      params: params
    });
  },
  findAllV2: function findAllV2(params) {
    return (0, _request.default)({
      url: '/v1/public/product/v2',
      params: params
    });
  },
  findOne: function findOne(productId) {
    return (0, _request.default)({
      url: "/v1/public/product/".concat(productId)
    });
  },
  validate: function validate(token) {
    return (0, _request.default)({
      url: '/v1/public/product/validate',
      method: 'post',
      data: {
        token: token
      }
    });
  }
};
exports.productApi = productApi;