"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productImportPriceApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var productImportPriceApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/productImportPrice",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/v1/admin/productImportPrice/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/productImportPrice",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/productImportPrice/".concat(id, "/update"),
      data: data,
      method: "put"
    });
  }
};
exports.productImportPriceApi = productImportPriceApi;