"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Cảnh báo",
      visible: _vm.visible,
      width: "600px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", [_c("div", [_c("img", {
    attrs: {
      src: _vm.warningImg,
      width: "60"
    }
  })]), _c("p", {
    staticStyle: {
      color: "#000",
      "font-size": "16px"
    }
  }, [_vm._v(" Dữ liệu công ty là tài sản "), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("cực kì quan trọng")]), _vm._v(". Nếu bạn cố tình chia sẻ ra ngoài với bất kì lí do nào, gây ảnh hưởng tới công ty thì bạn phải "), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("chịu hoàn toàn trách nhiệm trước pháp luật")]), _vm._v(". Khi sử dụng hệ thống này, chúng tôi cần xin quyền "), _c("label", {
    staticStyle: {
      color: "#409eff"
    }
  }, [_vm._v("định vị")]), _vm._v(" để đảm bảo rằng bạn được phép sử dụng trong phạm vi cho phép của công ty. ")])]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.handleAgree
    }
  }, [_vm._v("Đồng ý")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;