"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Xu\u1EA5t VAT #".concat(_vm.orderId),
      visible: _vm.visible,
      width: "1100px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "preview"
  }, [_c("section", {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c("h3", {
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_vm._v("Thông tin công ty:")]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Khách hàng: ")]), _c("span", [_vm._v(_vm._s(_vm.order.customerName))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("SĐT: ")]), _c("span", [_vm._v(_vm._s(_vm.order.customerPhone))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tên công ty: ")]), _c("span", [_vm._v(_vm._s(_vm.order.companyName))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("MST: ")]), _c("span", [_vm._v(_vm._s(_vm.order.companyTaxCode))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Địa chỉ: ")]), _c("span", [_vm._v(_vm._s(_vm.order.companyAddress))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Email nhận hóa đơn: ")]), _c("span", [_vm._v(_vm._s(_vm.order.companyEmail))])])]), _c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" THÔNG TIN SẢN PHẨM: ")]), _vm._l(_vm.productsTransform, function (item, index) {
    return _c("div", {
      key: index
    }, [_vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(item.productName))]), _c("br"), _vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Đơn giá:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(item.price, 0, ".")) + " "), _c("br"), _vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Số lượng:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(item.quantity, 0, ".")) + " " + _vm._s(item.product.unit ? "(".concat(item.product.unit, ")") : "") + " "), _c("br"), _vm._v(" - "), _c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Thành tiền:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(item.price * item.quantity, 0, ".")) + " "), _c("br"), _c("br")]);
  })], 2), _c("section", [_c("div", {}, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thuế VAT(10%): ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.order.vat10)))])]), _c("div", {}, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thuế VAT(8%): ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.order.vat8)))])]), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thành tiền: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.moneyProduct * 0.08 + _vm.moneyProduct)))])]), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("label", {
    staticStyle: {
      "line-height": "28px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Phương thức thanh toán")]), _c("br"), _c("el-select", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.order.paymentMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.order, "paymentMethod", $$v);
      },
      expression: "order.paymentMethod"
    }
  }, _vm._l(_vm.PaymentMethodTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("section", _vm._l(_vm.order.subOtherOrders, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "4px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" Hóa đơn (" + _vm._s(item.vatFactor * 100) + "%): ")]), _c("span", [_vm._v(_vm._s(_vm.VatStatusTrans[item.vatStatus]))]), _c("el-button", {
      staticStyle: {
        "margin-left": "8px"
      },
      attrs: {
        type: "primary",
        size: "mini",
        loading: item.loadingViewInvoice
      },
      on: {
        click: function click($event) {
          return _vm.viewSubInvoice(item);
        }
      }
    }, [_vm._v("Xem hóa đơn")])], 1);
  }), 0), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("label", {
    staticStyle: {
      "line-height": "28px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Tiền bằng chữ")]), _c("el-input", {
    model: {
      value: _vm.amountWord,
      callback: function callback($$v) {
        _vm.amountWord = $$v;
      },
      expression: "amountWord"
    }
  })], 1)]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", [_vm.order.vatStatus == _vm.VatStatus.Completed && _vm.order.iKey ? [_c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loadingRemind
    },
    on: {
      click: _vm.remindInvoice
    }
  }, [_vm._v(" Gửi email hóa đơn phát hành (" + _vm._s(_vm.order.emailPublishTime) + ") ")])] : [_c("el-button", {
    staticClass: "font-size--medium font-bold zalo-btn",
    attrs: {
      size: "small",
      disabled: !_vm.order.companyName || _vm.order.vatStatus == _vm.VatStatus.Draff,
      loading: _vm.loadingDraff
    },
    on: {
      click: _vm.draffInvoice
    }
  }, [_vm._v(" " + _vm._s(_vm.order.vatStatus == _vm.VatStatus.UnDraff ? "Tạo hóa đơn nháp" : "Đã gửi nháp") + " ")]), _vm.order.vatStatus == _vm.VatStatus.Draff ? _c("el-button", {
    staticClass: "font-size--medium font-bold zalo-btn",
    attrs: {
      size: "small",
      loading: _vm.loadingUpdate
    },
    on: {
      click: _vm.updateDraffInvoice
    }
  }, [_vm._v(" Cập nhật hóa đơn nháp ")]) : _vm._e(), _vm.order.vatStatus == _vm.VatStatus.Draff ? _c("el-button", {
    staticClass: "font-size--medium font-bold zalo-btn",
    attrs: {
      size: "small",
      disabled: _vm.order.vatStatus != _vm.VatStatus.Draff,
      loading: _vm.loadingSendMail
    },
    on: {
      click: _vm.sendEmailDraff
    }
  }, [_vm._v(" Gửi email nháp (" + _vm._s(_vm.order.emailDraffTime) + ") ")]) : _vm._e()], (_vm.order.vatStatus == _vm.VatStatus.Draff || _vm.order.vatStatus == _vm.VatStatus.Completed) && _vm.order.iKey ? _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loadingViewInvoice
    },
    on: {
      click: _vm.viewInvoice
    }
  }, [_vm._v(" Xem hóa đơn ")]) : _vm._e(), _vm.order.vatStatus == _vm.VatStatus.Draff ? _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "danger",
      size: "small",
      loading: _vm.loadingCancel
    },
    on: {
      click: _vm.cancelInvoice
    }
  }, [_vm._v(" Hủy hóa đơn nháp ")]) : _vm._e()], 2), _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;