"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calc = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.number.to-fixed.js");

var _handler = require("./handler");

/**
 * Xác định khổ giấy, và tính tiền
 */
var calc = function calc(_ref) {
  var length = _ref.length,
      width = _ref.width,
      height = _ref.height,
      cartonWave = _ref.cartonWave,
      cartonStruct = _ref.cartonStruct,
      unitGiaCong = _ref.unitGiaCong,
      unitPriceKeo = _ref.unitPriceKeo,
      unitPriceDay = _ref.unitPriceDay,
      unitPriceGhim = _ref.unitPriceGhim,
      unitPriceIn = _ref.unitPriceIn,
      beMatrix = _ref.beMatrix;
  var daiTam = 0,
      khoTam = 0; //dài tấm, khổ tấm

  var khoTamNCC = 0,
      daiTamNCC = 0,
      minKhoTamNcc = 115,
      minDaiTamNCC = 80;
  var realKhoTam = 0,
      realDaiTam = 0; //khổ tấm, dài tấm thực tế

  var factorKhoTam = 1,
      factorDaiTam = 1; //tỉ trọng khổ, dài

  var realQuantity = 0; //sl thùng thực tế khi cắt từ giấy ncc

  var unitPriceGiay = cartonStruct.price;
  var totalPriceGiay = 0;
  var totalGiaCong = 0;
  var totalPriceKeo = 0,
      totalPriceDay = 0,
      totalPriceGhim = 0,
      totalPriceIn = 0;
  var uocTinhKhoTam = 0,
      uocTinhDaiTam = 0;
  var daiKhuonBe = 1,
      rongKhuonBe = 1;
  daiTam = (length + width) * 2 + 2.5;
  khoTam = height + 1.5 * width + 0.2;

  if (beMatrix) {
    rongKhuonBe = +beMatrix.split('x')[0];
    daiKhuonBe = +beMatrix.split('x')[1];
    daiTam = +(daiTam * daiKhuonBe + 2).toFixed(1);
    khoTam = +(khoTam * rongKhuonBe + 2).toFixed(1);
  }

  var khoTamCongThemNCC = 2; //Nếu khổ tấm < 115, thì ghép giấy để đủ khổ >= 115

  var _getBestFactorKhoTamV = (0, _handler.getBestFactorKhoTamV2)({
    khoTam: khoTam,
    khoTamCongThemNCC: khoTamCongThemNCC
  }),
      factor = _getBestFactorKhoTamV.factor,
      minKhotam = _getBestFactorKhoTamV.minKhotam;

  factorKhoTam = factor;
  khoTamNCC = minKhotam; // Nếu dài tấm < 80, thì ghép giấy để đủ dài >= 80

  if (daiTam < minDaiTamNCC) {
    factorDaiTam = Math.ceil(minDaiTamNCC / daiTam);
    daiTamNCC = +(daiTam * factorDaiTam).toFixed(1);
  } else {
    daiTamNCC = daiTam;
  } //tính khổ, dài thực tế
  // realKhoTam = +(khoTamNCC / factorKhoTam)
  // realDaiTam = +(daiTamNCC / factorDaiTam)


  realKhoTam = +(khoTamNCC / factorKhoTam).toFixed(1);
  realDaiTam = +(daiTamNCC / factorDaiTam).toFixed(1); //sl thùng thực tế

  realQuantity = Math.floor(khoTamNCC * daiTamNCC / (realKhoTam * realDaiTam)); //tính tổng phí gia công

  totalGiaCong = Math.ceil(realKhoTam * realDaiTam / 10000 * unitGiaCong);
  uocTinhKhoTam = +(khoTamNCC / (khoTamNCC / khoTam)).toFixed(1);
  uocTinhDaiTam = +(daiTamNCC / (daiTamNCC / daiTam)).toFixed(1); //

  totalPriceKeo = height * unitPriceKeo;
  totalPriceDay = width * unitPriceDay;
  totalPriceGhim = height * unitPriceGhim;
  totalPriceIn = realDaiTam * realKhoTam / 10000 * unitPriceIn;
  totalPriceGiay = Math.ceil(realDaiTam * realKhoTam / 10000 * unitPriceGiay);
  var minQuantity = Math.ceil(300 * 100 * realQuantity / daiTamNCC); //- Tính thể tích: Sóng B 0.3, C 0.4,  E 0.2, BC 0.65, BCE 0.8

  var cubicMeter = (0, _handler.calcCubicMeter)({
    daiTam: realDaiTam,
    khoTam: realKhoTam,
    cartonWaveCode: cartonWave.code
  });
  return {
    cubicMeter: cubicMeter,
    totalGiaCong: totalGiaCong,
    daiTam: daiTam,
    khoTam: khoTam,
    realKhoTam: realKhoTam,
    realDaiTam: realDaiTam,
    realQuantity: realQuantity,
    totalPriceKeo: totalPriceKeo,
    totalPriceDay: totalPriceDay,
    totalPriceGhim: totalPriceGhim,
    totalPriceIn: totalPriceIn,
    totalPriceGiay: totalPriceGiay,
    daiTamNCC: daiTamNCC,
    khoTamNCC: khoTamNCC,
    uocTinhDaiTam: uocTinhDaiTam,
    uocTinhKhoTam: uocTinhKhoTam,
    daiKhuonBe: daiKhuonBe,
    rongKhuonBe: rongKhuonBe,
    minQuantity: minQuantity,
    taiThung: 0
  };
};

exports.calc = calc;