"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Chọn khuyến mãi",
      visible: _vm.visible,
      "append-to-body": "",
      width: "900px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "table",
    attrs: {
      data: _vm.promotions,
      border: "",
      fit: ""
    },
    on: {
      "selection-change": _vm.handleSelectChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      label: ""
    }
  }), _c("el-table-column", {
    attrs: {
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tiêu đề"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(_vm._s(row.title))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giảm giá"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(_vm._s(row.discountValue) + "%")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nhóm sản phẩm",
      prop: "productGroup.name"
    }
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.selectedPromotions.length,
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;