"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      width: "500px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("label", {
    staticStyle: {
      "font-size": "22px"
    }
  }, [_vm._v(" Chi tiết kho ")]), _c("br"), _c("span", {
    staticStyle: {
      color: "#555555"
    }
  }, [_vm._v("Mã: " + _vm._s(_vm.misaProduct.code))]), _c("br"), _c("span", {
    staticStyle: {
      color: "#555555"
    }
  }, [_vm._v("Tên: " + _vm._s(_vm.misaProduct.name))])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Loại",
      prop: "type"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumber(row.quantity)) + " ")];
      }
    }])
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("span"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;