"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Giờ",
      width: "80px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", {
          staticStyle: {
            "font-weight": "bold",
            "font-size": "18px",
            color: "#f54a45"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatDate(row.startAt, "HH:mm")) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tên chuyến",
      width: "100px",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", [_vm._v(" " + _vm._s(row.name) + " ")]), row.isExport ? _c("div", {
          staticStyle: {
            "font-weight": "bold",
            color: "red",
            "font-size": "16px",
            "text-decoration": "underline"
          },
          on: {
            click: function click($event) {
              return _vm.handleUnExport(row);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-lock"
        }), _c("span", [_vm._v(" Đã xuất excel ")])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tài xế",
      prop: "privateName",
      width: "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("div", [_c("div", [_vm._v(" " + _vm._s(row.privateName ? row.privateName : "Chưa sắp tài") + " ")]), row.note ? _c("div", [_vm._v(" " + _vm._s(row.note) + " ")]) : _vm._e()])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thể tích",
      align: "right",
      width: "80px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.totalCubicMeter.toFixed(1)) + " m3 ")];
      }
    }])
  }), _vm.visibleAmount ? [_c("el-table-column", {
    attrs: {
      label: "Tiền theo m2",
      align: "right",
      width: "80px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amountByMeter)) + " đ ")];
      }
    }], null, false, 2049896143)
  }), _c("el-table-column", {
    attrs: {
      label: "Tiền theo m2 (mới)",
      align: "right",
      width: "80px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amountByMeter2)) + " đ ")];
      }
    }], null, false, 456153181)
  }), _c("el-table-column", {
    attrs: {
      label: "Tiền theo m3",
      align: "right",
      width: "80px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amountByCubicMeter)) + " đ ")];
      }
    }], null, false, 1324574097)
  })] : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "Điểm 1"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [row.children[0] ? _c("div", [_c("span", [_vm._v(_vm._s(row.children[0].customerName))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("ĐC: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.getAddress(row.children[0])) + " ")]), _c("div", [row.children[0].isPE ? _c("el-tag", {
          attrs: {
            size: "normal",
            effect: "dark"
          }
        }, [_vm._v("Có quấn PE")]) : _vm._e()], 1)]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Điểm 2"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [row.children[1] ? _c("div", [_c("span", [_vm._v(_vm._s(row.children[1].customerName))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("ĐC: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.getAddress(row.children[1])) + " ")]), _c("div", [row.children[1].isPE ? _c("el-tag", {
          attrs: {
            size: "normal",
            effect: "dark"
          }
        }, [_vm._v("Có quấn PE")]) : _vm._e()], 1)]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Điểm 3"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [row.children[2] ? _c("div", [_c("span", [_vm._v(_vm._s(row.children[2].customerName))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("ĐC: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.getAddress(row.children[2])) + " ")]), _c("div", [row.children[2].isPE ? _c("el-tag", {
          attrs: {
            size: "normal",
            effect: "dark"
          }
        }, [_vm._v("Có quấn PE")]) : _vm._e()], 1)]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chi tiết",
      align: "center",
      width: "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-view"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewDetail(row);
            }
          }
        }, [_vm._v("Xem")]), _vm.visibleExcel ? _c("el-button", {
          staticStyle: {
            "margin-top": "4px"
          },
          attrs: {
            icon: "el-icon-paperclip",
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.onExport(row);
            }
          }
        }, [_vm._v("Xuất exccel")]) : _vm._e()];
      }
    }])
  })], 2)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;