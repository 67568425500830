"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaProductApi = void 0;

var _request = require("@/utils/request");

var misaProductApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaProduct',
      params: params
    });
  },
  findAllV2: function findAllV2(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaProduct/v2',
      params: params
    });
  },
  findInventory: function findInventory(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaProduct/inventory/type',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaProduct',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/misaProduct/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/misaProduct/".concat(id),
      method: 'delete'
    });
  }
};
exports.misaProductApi = misaProductApi;