"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staffApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var staffApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/admin/staff',
      params: params
    });
  },
  findAllWithSeller: function findAllWithSeller(params) {
    return (0, _request.default)({
      url: '/v1/admin/staff/with-seller',
      params: params
    });
  }
};
exports.staffApi = staffApi;