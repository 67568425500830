"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "pending-quantity-modal",
    attrs: {
      title: "Danh sách PO",
      visible: _vm.visible,
      width: "1200px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.onSubmitOk
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Sản phẩm: ")]), _c("span", [_vm._v(_vm._s(_vm.product.name))])]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: true
    },
    model: {
      value: _vm.hiddenComplete,
      callback: function callback($$v) {
        _vm.hiddenComplete = $$v;
      },
      expression: "hiddenComplete"
    }
  }, [_vm._v(" Ẩn hoàn thành ")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "pending-quantity-table",
    attrs: {
      data: _vm.computeData,
      border: "",
      fit: "",
      "row-class-name": _vm.handleClassName
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "PO",
      prop: "po.code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-link", {
          class: row.isComplete && "text-through",
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickPo(row.po);
            }
          }
        }, [_vm._v(" " + _vm._s(row.po.code) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "NCC",
      prop: "po.supplier.name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", {
          class: row.isComplete && "text-through"
        }, [_vm._v(_vm._s(row.po.supplier.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày đặt hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("span", {
          class: row.isComplete && "text-through"
        }, [_vm._v(" " + _vm._s(_vm.$formatDate(row.po.poDate)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL đặt hàng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", {
          class: row.isComplete && "text-through"
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " " + _vm._s(row.isComplete ? "(S\u1ED1 l\u01B0\u1EE3ng g\u1ED1c: ".concat(row.quantityStock, ")") : "") + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đã giao",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("el-link", {
          class: row.isComplete && "text-through",
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickPoDetailInventory(row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.actualQuantity)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Còn lại",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("span", {
          class: row.isComplete && "text-through"
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity - row.actualQuantity)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL nhập kho",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("span", {
          class: row.isComplete && "text-through"
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.pendingQuantity)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "ID Phiếu nhập"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return _vm._l(row.poDetailInventories, function (item, index) {
          return _c("span", {
            key: index,
            class: row.isComplete && "text-through"
          }, [item.inventoryDetail.inventoryBill ? _c("el-link", {
            attrs: {
              target: "_blank",
              type: "primary",
              href: "https://nhanh.vn/inventory/bill/detail?storeId=84054&id=".concat(item.inventoryDetail.inventoryBill.id)
            }
          }, [_vm._v(" " + _vm._s(item.inventoryDetail.inventoryBill.id) + " ")]) : _vm._e(), index != row.poDetailInventories.length - 1 ? _c("span", [_vm._v(", ")]) : _vm._e()], 1);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lần nhập về",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("el-link", {
          class: row.isComplete && "text-through",
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickPoDetailInventory(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.poDetailInventories.length) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hoàn thành",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("el-checkbox", {
          attrs: {
            value: row.isComplete
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [!row.isComplete ? _c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleComplete(row);
            }
          }
        }, [_vm._v(" Hoàn thành ")]) : _c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "warning",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleBackComplete(row);
            }
          }
        }, [_vm._v(" Chưa hoàn thành ")])];
      }
    }])
  })], 1), _c("Pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchPoDetails
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("div")], 1), _c("InventoryBillModal", {
    ref: "InventoryBillModal",
    on: {
      submitOk: _vm.handleSubmitBillOk
    }
  }), _c("POModal", {
    ref: "POModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;