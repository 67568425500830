"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.number.to-fixed.js");

require("core-js/modules/es.array.concat.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "C\u1EADp nh\u1EADt pallet #".concat(_vm.form.code) : "Th\xEAm m\u1EDBi pallet #".concat(_vm.workOrderInventory ? _vm.workOrderInventory.code : ""),
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "append-to-body": "",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_vm.type == _vm.WorkOrderInventoryType.Pallet ? [_c("div", [_c("label", {
    staticStyle: {
      "font-size": "20px",
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Cân mẫu")]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.viewHistory
    }
  }, [_vm._v("Lịch sử")])], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng khối lượng mẫu (Kg)",
      prop: "samplePalletWeight"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n                clearIncomplete: true,\n              }"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.samplePalletWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "samplePalletWeight", $$v);
      },
      expression: "form.samplePalletWeight"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng tấm mẫu đi cân (cái)",
      prop: "quantityOnPallet"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false,\n                clearIncomplete: true,\n              }"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.quantityOnPallet,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantityOnPallet", $$v);
      },
      expression: "form.quantityOnPallet"
    }
  })], 1)], 1)], 1), _vm.version == 3 ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      loading: _vm.loadingSaveSample
    },
    on: {
      click: _vm.handleSaveSample
    }
  }, [_vm._v("Lưu cân mẫu")]) : _vm._e(), _c("hr"), _c("label", {
    staticStyle: {
      "font-size": "20px",
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Thực nhập")]), _c("el-form-item", {
    attrs: {
      label: "Tổng cân nặng trên pallet (Kg - Đã bao gồm khối lượng pallet, SP)",
      prop: "totalPalletWeight"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n            clearIncomplete: true,\n          }"
    }],
    on: {
      input: function input($event) {
        return _vm.handleChangeForm("totalPalletWeight");
      }
    },
    model: {
      value: _vm.form.totalPalletWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "totalPalletWeight", $$v);
      },
      expression: "form.totalPalletWeight"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Cân nặng của pallet (Kg - chỉ tính khối lượng pallet)",
      prop: "palletWeight"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n            clearIncomplete: true,\n          }"
    }],
    on: {
      input: function input($event) {
        return _vm.handleChangeForm("palletWeight");
      }
    },
    model: {
      value: _vm.form.palletWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "palletWeight", $$v);
      },
      expression: "form.palletWeight"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Cân nặng giấy carton (Không bao gồm pallet)",
      prop: "palletWeight"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n            clearIncomplete: true,\n          }"
    }],
    attrs: {
      disabled: "",
      value: +(_vm.form.totalPalletWeight - _vm.form.palletWeight).toFixed(6)
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "C\xE2n n\u1EB7ng tr\xEAn 1 sp (Kg)",
      prop: "weight"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n            clearIncomplete: true,\n          }"
    }],
    on: {
      input: function input($event) {
        return _vm.handleChangeForm("weight");
      }
    },
    model: {
      value: _vm.form.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "weight", $$v);
      },
      expression: "form.weight"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "SL th\u1EF1c t\u1EBF ".concat(_vm.form.quantity ? "(".concat(_vm.$formatNumberVN(+(_vm.form.totalPalletWeight - _vm.form.palletWeight).toFixed(6)), "/").concat(_vm.form.weight, ")") : ""),
      prop: "quantity"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n            clearIncomplete: true,\n          }"
    }],
    staticStyle: {
      "text-align": "left !important"
    },
    attrs: {
      disabled: "",
      value: _vm.form.quantity
    }
  })], 1)] : [_c("el-form-item", {
    attrs: {
      label: "SL th\u1EF1c t\u1EBF tr\xEAn pallet",
      prop: "quantity"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }"
    }],
    staticStyle: {
      "text-align": "left !important"
    },
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  })], 1)], _c("el-form-item", {
    attrs: {
      label: "Nhân viên kho",
      prop: "staffName"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.form.staffName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffName", $$v);
      },
      expression: "form.staffName"
    }
  }, _vm._l(_vm.staffNames, function (name) {
    return _c("el-radio", {
      key: name,
      attrs: {
        label: name
      }
    }, [_vm._v(_vm._s(name))]);
  }), 1)], 1)], 2), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1), _c("HistorySampleModal", {
    ref: "HistorySampleModal",
    on: {
      select: _vm.onSelect
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;