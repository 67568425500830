"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticStyle: {
      background: "white"
    }
  }, [_c("calendar-component", {
    ref: "CalendarComponent",
    attrs: {
      dateSelected: _vm.date,
      events: _vm.events,
      viewMode: _vm.viewMode,
      snapTime: 30,
      startHour: 6,
      shouldUseOneDayMode: false,
      timeStep: 60,
      endHour: 23,
      timeCellHeight: 70,
      splitDays: _vm.splitDays
    },
    on: {
      create: _vm.handleCreateEvent
    },
    scopedSlots: _vm._u([{
      key: "daySplit",
      fn: function fn(_ref) {
        var split = _ref.split;
        return [_vm.viewMode == "day" ? _c("employee-header", {
          attrs: {
            name: split.label,
            nameShort: split.nameShort,
            avatar: split.avatar
          }
        }) : _vm._e()];
      }
    }, {
      key: "event",
      fn: function fn(_ref2) {
        var event = _ref2.event;
        return [_c("el-popover", {
          attrs: {
            placement: "top-start",
            trigger: "click"
          }
        }, [_c("MisaActivityOverlay", {
          attrs: {
            misaActivity: event.data
          },
          on: {
            complete: _vm.handleComplete
          }
        }), _c("template", {
          slot: "reference"
        }, [_c("MisaActivityCalendarItem", {
          attrs: {
            misaActivity: event.data
          }
        })], 1)], 2)];
      }
    }])
  })], 1), _c("CompleteMisaActivityModal", {
    ref: "CompleteMisaActivityModal",
    on: {
      submitOk: _vm.eventSubmitOk
    }
  }), _c("SelectActivityModal", {
    ref: "SelectActivityModal",
    on: {
      submitOk: _vm.eventSubmitOk
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;