"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá nhập xốp hơi: ")]), _c("br"), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      size: "small",
      placeholder: "Nhập giá xốp hơi"
    },
    model: {
      value: _vm.xopHoiImportPrice,
      callback: function callback($$v) {
        _vm.xopHoiImportPrice = $$v;
      },
      expression: "xopHoiImportPrice"
    }
  }), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-left": "4px"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.updateImportPrice
    }
  }, [_vm._v("Cập nhật giá")])], 1), _c("el-table", {
    attrs: {
      data: _vm.suppliers,
      fit: ""
    },
    on: {
      "row-click": _vm.handleClickRow
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Nhà cung cấp",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "SDT",
      prop: "mobile"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Địa chỉ",
      prop: "address"
    }
  })], 1), _c("product-import-price-modal", {
    ref: "ProductImportPriceModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;