"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [!_vm.taskFollowStatus ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-chat-line-round",
      loading: _vm.loadingFollow
    },
    on: {
      click: _vm.handleFollow
    }
  }, [_vm._v("Follow")]) : _vm.taskFollowStatus == "PENDING" ? [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-chat-line-round"
    }
  }, [_vm._v("Đợi duyệt follow")]), _c("div", {
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_vm.visibleCensorFollow ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "success",
      size: "mini",
      loading: _vm.loadingApproveFollow,
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleApproveFollow
    }
  }, [_vm._v("Duyệt")]) : _vm._e(), _vm.visibleCensorFollow ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "danger",
      size: "mini",
      loading: _vm.loadingDeleteFollow,
      icon: "el-icon-close"
    },
    on: {
      click: _vm.handleDeleteFollow
    }
  }, [_vm._v("Xóa")]) : _vm._e()], 1)] : _vm.taskFollowStatus == _vm.TaskFollowStatus.PendingDelete ? [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-chat-line-round"
    }
  }, [_vm._v("Đợi un-follow")]), _c("div", {
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_vm.visibleCensorFollow ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "success",
      size: "mini",
      loading: _vm.loadingDeleteFollow,
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleDeleteFollow
    }
  }, [_vm._v("Duyệt")]) : _vm._e(), _vm.visibleCensorFollow ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "danger",
      size: "mini",
      loading: _vm.loadingApproveFollow,
      icon: "el-icon-close"
    },
    on: {
      click: _vm.handleReject
    }
  }, [_vm._v("Từ chối")]) : _vm._e()], 1)] : _vm.taskFollowStatus == "APPROVE" ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-chat-line-round"
    },
    on: {
      click: _vm.handleUnFollow
    }
  }, [_vm._v("Un-follow")]) : _vm._e()], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;