"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container calc-carton-page"
  }, [_vm.isVisibleCheckPrice ? _c("el-alert", {
    staticClass: "price-tag",
    attrs: {
      center: true,
      title: _vm.isCorrectPrice ? "\u0110\xE3 \u0111\xFAng gi\xE1: ".concat(_vm.priceFromMisa) : "Sai gi\xE1: ".concat(_vm.priceFromMisa),
      type: _vm.isCorrectPrice ? "success" : "error",
      "show-icon": "",
      closable: false
    }
  }) : _vm._e(), _c("el-form", {
    attrs: {
      model: _vm.form,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Thông tin thùng carton")]), _vm.mode == "admin" ? [_vm.visibleNormal ? _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleViewCartonInfo
    }
  }, [_vm._v(" Thông tin sản xuất ")]) : _vm._e(), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleViewNamed
    }
  }, [_vm._v(" Quy cách đặt tên ")])] : _vm._e(), _vm.form.boxType == _vm.MoldBoxType.TamLot || _vm.form.boxType == _vm.MoldBoxType.VachNgan ? _c("div", {
    staticStyle: {
      color: "red",
      "font-weight": "bold"
    }
  }, [_vm._v(" Tấm lót: tính giá này áp dụng cho tấm lót, vách ngăn, khuôn bế mặc định là 1.200.000đ/bộ ")]) : _vm._e(), _vm.form.boxType == _vm.MoldBoxType.NS1 ? _c("div", {
    staticStyle: {
      color: "red",
      "font-weight": "bold"
    }
  }, [_vm._v(" Thùng nước suối: Thùng loại này bế, luôn có phí khuôn bế là 1.400.000đ ")]) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại thùng"
    }
  }, [_c("el-select", {
    attrs: {
      size: "mini"
    },
    on: {
      change: _vm.handleChangeBoxType
    },
    model: {
      value: _vm.form.boxType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "boxType", $$v);
      },
      expression: "form.boxType"
    }
  }, _vm._l(_vm.MoldBoxTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _vm.form.length && _vm.form.width && _vm.form.length >= 5 * _vm.form.width && _vm.form.width <= 20 && _vm.form.boxType != _vm.MoldBoxType.A1NapChom1 && _vm.form.boxType != _vm.MoldBoxType.A1NapChom2 ? _c("el-alert", {
    attrs: {
      closable: false,
      title: "Nên tư vấn nắp chồm",
      type: "warning",
      effect: "dark"
    }
  }) : _vm._e()], 1), _vm.form.boxType != _vm.MoldBoxType.HopBe && _vm.form.boxType != _vm.MoldBoxType.BVN_TL ? [_c("el-col", {
    attrs: {
      span: _vm.visibleWithBox ? 6 : 8,
      offset: 0
    }
  }, [_c("el-form-item", [_c("template", {
    slot: "label"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Dài (cm) ")]), _c("span", {
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(_vm._s(_vm.form.boxType == _vm.MoldBoxType.TamLot || _vm.form.boxType == _vm.MoldBoxType.VachNgan ? "(Chiều dài tấm)" : ""))])]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.length,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length", $$v);
      },
      expression: "form.length"
    }
  })], 2)], 1), _c("el-col", {
    attrs: {
      span: _vm.visibleWithBox ? 6 : 8,
      offset: 0
    }
  }, [_c("el-form-item", [_c("template", {
    slot: "label"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Rộng" + _vm._s(_vm.form.boxType == _vm.MoldBoxType.GiayTam ? " - Chiều khổ" : "") + " (cm) ")]), _c("span", {
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(_vm._s(_vm.form.boxType == _vm.MoldBoxType.TamLot || _vm.form.boxType == _vm.MoldBoxType.VachNgan ? "(Chiều khổ tấm, chiều song song với sóng giấy)" : ""))])]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.width,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width", $$v);
      },
      expression: "form.width"
    }
  })], 2)], 1), _vm.visibleWithBox ? _c("el-col", {
    attrs: {
      span: _vm.visibleWithBox ? 6 : 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cao (cm)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1)], 1) : _vm._e()] : [_c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm nhỏ"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("tamNho");
      }
    },
    model: {
      value: _vm.form.khoTamNho,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "khoTamNho", $$v);
      },
      expression: "form.khoTamNho"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm nhỏ"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("tamNho");
      }
    },
    model: {
      value: _vm.form.daiTamNho,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "daiTamNho", $$v);
      },
      expression: "form.daiTamNho"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SL theo chiều khổ"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.slTheoChieuKho,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "slTheoChieuKho", $$v);
      },
      expression: "form.slTheoChieuKho"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SL theo chiều dài"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.slTheoChieuDai,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "slTheoChieuDai", $$v);
      },
      expression: "form.slTheoChieuDai"
    }
  })], 1)], 1)], _vm.form.boxType == _vm.MoldBoxType.GiayTam && _vm.isMinConditionGiayTam ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "12px",
      color: "red"
    }
  }, [_vm._v(" Không đạt yêu cầu tối thiểu (Dài >= 50, Rộng >= 25). Yêu cầu chuyển sang tính giá tấm lót ")])]) : _vm._e()], 2), _vm.form.khoTamNho < 32 && _vm.form.numColor ? _c("div", [_c("el-alert", {
    attrs: {
      title: "Khổ in tối thiểu 32",
      type: "error",
      effect: "dark",
      "show-icon": ""
    }
  })], 1) : _vm._e(), +_vm.form.length < +_vm.form.width && _vm.form.boxType != _vm.MoldBoxType.GiayTam && _vm.form.boxType != _vm.MoldBoxType.TamLot && _vm.form.boxType != _vm.MoldBoxType.VachNgan ? _c("div", [_c("el-alert", {
    attrs: {
      title: "Chiều dài phải lớn hơn hoặc bằng chiều rộng",
      type: [_vm.MoldBoxType.NapGai, _vm.MoldBoxType.NapGaiTrong].includes(_vm.form.boxType) ? "warning " : "error",
      effect: "dark",
      "show-icon": ""
    }
  })], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lớp",
      prop: "layer"
    }
  }, [_c("el-select", {
    attrs: {
      size: "mini",
      filterable: ""
    },
    on: {
      change: _vm.handleChangeLayer
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_c("el-option", {
    attrs: {
      label: "3 lớp",
      value: 3
    }
  }), _c("el-option", {
    attrs: {
      label: "5 lớp",
      value: 5
    }
  }), _c("el-option", {
    attrs: {
      label: "7 lớp",
      value: 7
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại sóng",
      prop: "cartonWave"
    }
  }, [_c("el-select", {
    attrs: {
      size: "mini",
      filterable: "",
      "value-key": "id"
    },
    on: {
      change: _vm.handleChangeCartonWave
    },
    model: {
      value: _vm.form.cartonWave,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonWave", $$v);
      },
      expression: "form.cartonWave"
    }
  }, _vm._l(_vm.cartonWavesFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1)], 1), _vm.visibleNormal ? _c("el-col", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Kết cấu giấy",
      prop: "cartonStruct"
    }
  }, [_c("el-select", {
    attrs: {
      size: "mini",
      filterable: "",
      "value-key": "id"
    },
    on: {
      change: _vm.handleChangeCartonStruct
    },
    model: {
      value: _vm.form.cartonStruct,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonStruct", $$v);
      },
      expression: "form.cartonStruct"
    }
  }, _vm._l(_vm.cartonStructsFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1), _vm.isAdmin ? _c("el-form-item", {
    attrs: {
      label: "Version giá",
      prop: "cartonStructPriceId"
    }
  }, [_c("el-select", {
    attrs: {
      size: "mini",
      filterable: ""
    },
    on: {
      change: _vm.handleChangeCartonStructPrice
    },
    model: {
      value: _vm.form.cartonStructPriceId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonStructPriceId", $$v);
      },
      expression: "form.cartonStructPriceId"
    }
  }, _vm._l(_vm.cartonStructPrices, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: "v".concat(item.version, ": ").concat(_vm.$formatNumberVN(item.price), "\u0111"),
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm.isAdmin && _vm.form.cartonStruct && _vm.form.cartonStruct.mainVersion ? [_c("el-radio", {
    attrs: {
      label: "current"
    },
    on: {
      change: _vm.handleChangeCartonPriceMode
    },
    model: {
      value: _vm.form.cartonPriceMode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonPriceMode", $$v);
      },
      expression: "form.cartonPriceMode"
    }
  }, [_vm._v("Giá mới")]), _c("el-radio", {
    attrs: {
      label: "main"
    },
    on: {
      change: _vm.handleChangeCartonPriceMode
    },
    model: {
      value: _vm.form.cartonPriceMode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonPriceMode", $$v);
      },
      expression: "form.cartonPriceMode"
    }
  }, [_vm._v("Giá gốc")])] : _vm._e(), _vm.$devMode && _vm.form.cartonStruct ? _c("div", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phiên bản: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.form.cartonStruct.version) + " ")])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Last verion: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.lastCartonVersion) + " ")])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đơn giá: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.form.cartonStruct.price) + " ")])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phiên bản (Gốc): ")]), _c("span", [_vm._v(" " + _vm._s(_vm.form.cartonStruct.mainVersion) + " ")])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đơn giá (Gốc): ")]), _c("span", [_vm._v(" " + _vm._s(_vm.form.cartonStruct.mainPrice) + " ")])])]) : _vm._e(), _vm.form.cartonStruct && _vm.form.cartonStruct.version != _vm.lastCartonVersion ? _c("div", [_c("el-alert", {
    attrs: {
      closable: false,
      type: "warning",
      effect: "dark"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("span", [_vm._v("Giá giấy chưa được cập nhật mới")]), _vm.isAdmin ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: _vm.handleUpdateCartonPriceVersion
          }
        }, [_vm._v("Cập nhật")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 2963362736)
  })], 1) : _vm._e()], 2) : _vm._e()], 1), _vm.visibleNormal ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mặt chống thấm"
    }
  }, [_c("el-select", {
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.waterProofFaceType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "waterProofFaceType", $$v);
      },
      expression: "form.waterProofFaceType"
    }
  }, _vm._l(_vm.waterProofFaces, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số màu"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      disabled: _vm.form.boxType == _vm.MoldBoxType.TamLot,
      size: "mini"
    },
    on: {
      change: _vm.handleChangePrintColor
    },
    model: {
      value: _vm.form.printColorId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "printColorId", $$v);
      },
      expression: "form.printColorId"
    }
  }, _vm._l(_vm.printColors, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.visibleNormal ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", [_c("template", {
    slot: "label"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Số lượng thùng ")]), _c("span", {
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" (Tối thiểu " + _vm._s(_vm.$formatNumberVN(_vm.form.minQuantity)) + " thùng) ")])]), _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      placeholder: "",
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  }), _c("span", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Mét tới: ")]), _c("span", [_vm._v(_vm._s(_vm.soMetToi))])])], 2), _vm.form.minQuantity > _vm.form.quantity ? _c("el-alert", {
    attrs: {
      title: "Chưa đủ số lượng tối thiểu",
      type: "warning",
      effect: "dark",
      "show-icon": ""
    }
  }) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiền bản"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      placeholder: "",
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.bangInPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bangInPrice", $$v);
      },
      expression: "form.bangInPrice"
    }
  })], 1), _vm.form.numColor && !_vm.form.bangInPrice ? _c("el-alert", {
    attrs: {
      title: "Chưa có tiền bản in",
      type: "warning",
      effect: "dark",
      "show-icon": ""
    }
  }) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_vm.form.boxType != _vm.MoldBoxType.GiayTam ? _c("el-form-item", {
    attrs: {
      label: "Tiền khuôn bế"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      placeholder: "",
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.khuonBePrice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "khuonBePrice", $$v);
      },
      expression: "form.khuonBePrice"
    }
  })], 1) : _vm._e(), (![_vm.MoldBoxType.A1, _vm.MoldBoxType.A1NapChom1, _vm.MoldBoxType.A1NapChom2, _vm.MoldBoxType.A10Nap, _vm.MoldBoxType.A11Nap].includes(_vm.form.boxType) || _vm.form.khoTamNho < 32 && _vm.form.numColor || _vm.form.type == "BE") && !_vm.form.khuonBePrice && _vm.form.boxType != _vm.MoldBoxType.GiayTam ? _c("el-alert", {
    attrs: {
      title: "Chưa có tiền khuôn bế",
      type: "warning",
      effect: "dark",
      "show-icon": ""
    }
  }) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_vm.visibleNormal ? _c("el-form-item", {
    attrs: {
      label: "Màng quấn PE"
    }
  }, [_c("el-checkbox", {
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("cost");
      }
    },
    model: {
      value: _vm.form.isEnablePEPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isEnablePEPrice", $$v);
      },
      expression: "form.isEnablePEPrice"
    }
  }, [_vm._v(" Có quấn PE ")])], 1) : _vm._e(), _vm.form.boxType == _vm.MoldBoxType.GiayTam ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tề biên"
    }
  }, [_c("el-checkbox", {
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.isTeBien,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isTeBien", $$v);
      },
      expression: "form.isTeBien"
    }
  }, [_vm._v(" Có tề biên ")])], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cột"
    }
  }, [_c("el-checkbox", {
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.isCot,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isCot", $$v);
      },
      expression: "form.isCot"
    }
  }, [_vm._v(" Có cột ")])], 1)], 1)], 1) : _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Yêu cầu cột bó lót giấy"
    }
  }, [_c("el-checkbox", {
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.isCotBoLotGiay,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isCotBoLotGiay", $$v);
      },
      expression: "form.isCotBoLotGiay"
    }
  }, [_vm._v(" Yêu cầu cột bó lót giấy ")])], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_vm.mode == "admin" ? [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleCopyLink
    }
  }, [_vm._v("Copy link")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleOpenOtherVersion
    }
  }, [_vm._v(_vm._s(_vm.calcVersion == "v1" ? "Mở v3" : "Mở v1"))]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleCopyText
    }
  }, [_vm._v("Copy text")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleCopyMessage
    }
  }, [_vm._v("Copy tin nhắn")])] : _vm._e()], 2)], 1)], 1), _vm.visibleWithBox ? _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_vm.form.boxType != _vm.MoldBoxType.HopBe && _vm.form.boxType != _vm.MoldBoxType.BVN_TL ? _c("el-form-item", {
    attrs: {
      label: "Chạp / Bế"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: _vm.isAutoCalc,
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Chạp",
      value: "CHAP"
    }
  }), _c("el-option", {
    attrs: {
      label: "Bế",
      value: "BE"
    }
  })], 1), _vm.mode == "admin" ? _c("div", [_c("el-checkbox", {
    attrs: {
      label: ""
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.isAutoCalc,
      callback: function callback($$v) {
        _vm.isAutoCalc = $$v;
      },
      expression: "isAutoCalc"
    }
  }, [_vm._v("Hệ thống tính")])], 1) : _vm._e()], 1) : _vm._e(), _vm.currentMachine ? _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Máy đang chạy: ")]), _c("span", [_vm._v(_vm._s(_vm.currentMachine.name))])]) : _vm._e()], 1) : _vm._e(), _vm.form.type == "BE" && !_vm.form.mold && _vm.form.boxType != _vm.MoldBoxType.HopBe && _vm.form.boxType != _vm.MoldBoxType.BVN_TL && _vm.form.boxType != _vm.MoldBoxType.GiayTam ? _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "numOfBe",
      label: "Số lượng bế"
    }
  }, [_c("el-select", {
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("numOfBe");
      }
    },
    model: {
      value: _vm.form.beMatrix,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "beMatrix", $$v);
      },
      expression: "form.beMatrix"
    }
  }, _vm._l(_vm.beMatrix, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.label
      }
    });
  }), 1), _vm.visibleAddMold && _vm.mode == "admin" ? [_c("el-alert", {
    attrs: {
      title: "Chưa có cấu hình khuôn bế",
      type: "warning",
      effect: "dark",
      "show-icon": ""
    }
  }), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAddMold
    }
  }, [_vm._v("Thêm khuôn bế")])] : _vm._e()], 2)], 1) : _vm.form.type == "BE" && _vm.form.mold ? _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại bế"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: "",
      size: "mini"
    },
    model: {
      value: _vm.moldTypeTrans[_vm.form.mold.type],
      callback: function callback($$v) {
        _vm.$set(_vm.moldTypeTrans, _vm.form.mold.type, $$v);
      },
      expression: "moldTypeTrans[form.mold.type]"
    }
  })], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.errorMessage ? _c("el-alert", {
    attrs: {
      title: _vm.errorMessage,
      type: "error",
      effect: "dark",
      "show-icon": ""
    }
  }) : _vm._e(), _vm.form.mold && (!_vm.form.mold.beWidth || !_vm.form.mold.beLength) ? _c("div", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.handleUpdateMold(_vm.form.mold);
      }
    }
  }, [_vm._v("Cấu hình khuôn bế")])], 1) : _vm._e(), _vm.diffResultMessage && _vm.mode == "admin" ? _c("el-alert", {
    attrs: {
      title: _vm.diffResultMessage,
      type: "error",
      effect: "dark",
      "show-icon": ""
    }
  }) : _vm._e(), _vm.warningMessage ? _c("el-alert", {
    attrs: {
      title: _vm.warningMessage,
      type: "warning",
      effect: "dark",
      "show-icon": ""
    }
  }) : _vm._e()], 2), _vm.mode == "admin" ? [_vm.visibleWithBox ? _c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title"
  }, [_vm._v(" Thông tin biên, nắp, tai thùng ")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nắp thùng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      size: "mini",
      disabled: !_vm.isAutoCalcSize
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.napThung,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "napThung", $$v);
      },
      expression: "form.napThung"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tai thùng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      size: "mini",
      disabled: !_vm.isAutoCalcSize
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.taiThung,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "taiThung", $$v);
      },
      expression: "form.taiThung"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cắt đuôi"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      disabled: !_vm.isAutoCalcSize,
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.catDuoi,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "catDuoi", $$v);
      },
      expression: "form.catDuoi"
    }
  })], 1)], 1), _vm.visibleWithBox ? _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tề biên"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      disabled: !_vm.isAutoCalcSize,
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.teBien,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "teBien", $$v);
      },
      expression: "form.teBien"
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.visibleNormal ? _c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Thông tin khổ giấy ")]), _c("div", [_c("el-checkbox", {
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("isAutoCalcSize");
      }
    },
    model: {
      value: _vm.isAutoCalcSize,
      callback: function callback($$v) {
        _vm.isAutoCalcSize = $$v;
      },
      expression: "isAutoCalcSize"
    }
  }, [_vm._v(" Tự động tính ")])], 1), _vm.form.boxType != _vm.MoldBoxType.HopBe && _vm.form.boxType != _vm.MoldBoxType.BVN_TL ? [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ (1 thùng)"
    }
  }, [_vm.form.mold && _vm.form.khoTam1Thung == 0 ? _c("el-input", {
    attrs: {
      size: "mini",
      value: "~",
      disabled: true
    }
  }) : _c("el-input", {
    attrs: {
      size: "mini",
      disabled: _vm.isAutoCalcSize
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("thung");
      }
    },
    model: {
      value: _vm.form.khoTam1Thung,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "khoTam1Thung", $$v);
      },
      expression: "form.khoTam1Thung"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài (1 thùng)"
    }
  }, [_vm.form.mold && _vm.form.daiTam1Thung == 0 ? _c("el-input", {
    attrs: {
      size: "mini",
      value: "~",
      disabled: true
    }
  }) : _c("el-input", {
    attrs: {
      size: "mini",
      disabled: _vm.isAutoCalcSize
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("thung");
      }
    },
    model: {
      value: _vm.form.daiTam1Thung,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "daiTam1Thung", $$v);
      },
      expression: "form.daiTam1Thung"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Diện tích 1 thùng"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.form.acr1Thung,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "acr1Thung", $$v);
      },
      expression: "form.acr1Thung"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm nhỏ"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: _vm.isAutoCalcSize
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("tamNho");
      }
    },
    model: {
      value: _vm.form.khoTamNho,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "khoTamNho", $$v);
      },
      expression: "form.khoTamNho"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm nhỏ"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.isAutoCalcSize
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("tamNho");
      }
    },
    model: {
      value: _vm.form.daiTamNho,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "daiTamNho", $$v);
      },
      expression: "form.daiTamNho"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SL/tấm"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: _vm.isAutoCalcSize
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("slThung");
      }
    },
    model: {
      value: _vm.form.slThungTrenTamNho,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "slThungTrenTamNho", $$v);
      },
      expression: "form.slThungTrenTamNho"
    }
  })], 1)], 1)], 1)] : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm NCC"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.form.khoTamNcc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "khoTamNcc", $$v);
      },
      expression: "form.khoTamNcc"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm NCC"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.form.daiTamNcc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "daiTamNcc", $$v);
      },
      expression: "form.daiTamNcc"
    }
  })], 1), _vm.form.daiTamNcc < 50 ? _c("el-alert", {
    attrs: {
      title: "Dài tấm NCC tối thiểu là 50cm",
      type: "warning",
      effect: "dark",
      "show-icon": ""
    }
  }) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Xả mấy tấm"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.form.realQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "realQuantity", $$v);
      },
      expression: "form.realQuantity"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "KT nhỏ thực tế"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: "",
      size: "mini"
    },
    model: {
      value: _vm.form.realKhoTamNho,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "realKhoTamNho", $$v);
      },
      expression: "form.realKhoTamNho"
    }
  })], 1), _vm.form.realKhoTamNho < 25 ? _c("el-alert", {
    attrs: {
      title: "Khổ tấm nhỏ thực tế tối thiểu là 25cm",
      type: "warning",
      effect: "dark",
      "show-icon": ""
    }
  }) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "DT nhỏ thực tế"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: "",
      size: "mini"
    },
    model: {
      value: _vm.form.realDaiTamNho,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "realDaiTamNho", $$v);
      },
      expression: "form.realDaiTamNho"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số m2 thực tế"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: "",
      size: "mini"
    },
    model: {
      value: _vm.form.realAcr,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "realAcr", $$v);
      },
      expression: "form.realAcr"
    }
  })], 1)], 1)], 1), _vm.visibleWithBox ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ (1 thùng thực tế)"
    }
  }, [_vm.form.mold && _vm.form.khoTamThucTe == 0 ? _c("el-input", {
    attrs: {
      size: "mini",
      value: "~",
      disabled: true
    }
  }) : _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      disabled: "",
      size: "mini"
    },
    model: {
      value: _vm.form.khoTamThucTe,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "khoTamThucTe", $$v);
      },
      expression: "form.khoTamThucTe"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài (1 thùng thực tế)"
    }
  }, [_vm.form.mold && _vm.form.daiTamThucTe == 0 ? _c("el-input", {
    attrs: {
      size: "mini",
      value: "~",
      disabled: true
    }
  }) : _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
    }],
    attrs: {
      disabled: "",
      size: "mini"
    },
    model: {
      value: _vm.form.daiTamThucTe,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "daiTamThucTe", $$v);
      },
      expression: "form.daiTamThucTe"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Diện tích 1 thùng (thực tế)"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.form.acr,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "acr", $$v);
      },
      expression: "form.acr"
    }
  })], 1)], 1)], 1) : _vm._e()], 2) : _vm._e()] : _vm._e()], 2), _vm.visibleNormal ? _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_vm.visibleCustomerInfo ? _c("section", {
    staticClass: "box"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Thông tin khách hàng ")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên kháchh hàng"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "",
      clearable: ""
    },
    on: {
      change: function change($event) {}
    },
    model: {
      value: _vm.form.customerName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customerName", $$v);
      },
      expression: "form.customerName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SĐT"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "",
      clearable: ""
    },
    on: {
      change: function change($event) {}
    },
    model: {
      value: _vm.form.customerPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customerPhone", $$v);
      },
      expression: "form.customerPhone"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Địa chỉ"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "",
      clearable: ""
    },
    on: {
      change: function change($event) {}
    },
    model: {
      value: _vm.form.customerAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customerAddress", $$v);
      },
      expression: "form.customerAddress"
    }
  })], 1)], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px",
      "margin-right": "4px",
      transform: "translateY(7px)"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addToQuote
    }
  }, [_vm._v("Lên báo giá")]), _vm.quoteForm ? [_c("el-badge", {
    attrs: {
      value: _vm.quoteForm.products.length
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.viewQuoteForm
    }
  }, [_vm._v("Xem báo giá")])], 1), _vm.$devMode ? _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: _vm.deleteQuoteForm
    }
  }, [_vm._v("Xóa báo giá")]) : _vm._e()] : _vm._e()], 2) : _vm._e(), _c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Giá bán sản phẩm ")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_vm.isAdmin ? _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giá vốn"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      size: "mini",
      value: _vm.form.moneyFinal,
      disabled: ""
    }
  })], 1)], 1) : _vm._e(), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng PVC"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      size: "mini",
      disabled: !_vm.visibleWithBox
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("totalShipFee");
      }
    },
    model: {
      value: _vm.form.totalShipFee,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "totalShipFee", $$v);
      },
      expression: "form.totalShipFee"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giá giao"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      size: "mini",
      value: _vm.form.deliveryPrice,
      disabled: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [!_vm.visibleMisaCustomerGroup ? _c("el-form-item", {
    attrs: {
      label: "Nhóm khách"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: _vm.mode == "customer"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("cartonPricingGroupId");
      }
    },
    model: {
      value: _vm.form.cartonPricingGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonPricingGroupId", $$v);
      },
      expression: "form.cartonPricingGroupId"
    }
  }, _vm._l(_vm.cartonPricingGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, "(").concat(item.profitPercent, "%)"),
        value: item.id
      }
    });
  }), 1)], 1) : _c("el-form-item", {
    attrs: {
      label: "Nhóm khách misa"
    }
  }, [_c("el-select", {
    attrs: {
      "value-key": "id"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.misaCustomerGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaCustomerGroup", $$v);
      },
      expression: "form.misaCustomerGroup"
    }
  }, _vm._l(_vm.misaCustomerGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name),
        value: item
      }
    });
  }), 1)], 1), _vm.form.height && _vm.form.length && _vm.form.height >= 4 * _vm.form.length ? _c("el-alert", {
    attrs: {
      closable: false,
      title: "Khó dán, nên báo giá cao",
      type: "warning",
      effect: "dark"
    }
  }) : _vm._e(), (_vm.form.height <= 5 || _vm.form.length >= 5 * _vm.form.height && _vm.form.height <= 15) && _vm.form.height && _vm.form.length && _vm.visbileDeTet ? _c("el-alert", {
    attrs: {
      closable: false,
      title: "Dễ tét giấy, nên báo giá cao",
      type: "warning",
      effect: "dark"
    }
  }) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giá tại kho"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      size: "mini",
      disabled: !_vm.visibleWithBox
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("sellPrice");
      }
    },
    model: {
      value: _vm.form.sellPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sellPrice", $$v);
      },
      expression: "form.sellPrice"
    }
  })], 1)], 1), _vm.isAdmin ? _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "% LN thực"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: "",
      value: _vm.form.realProfitPercent
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v("Thông tin vận chuyển")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Diện tích giấy (m2/thùng)"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.form.acr1Thung,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "acr1Thung", $$v);
      },
      expression: "form.acr1Thung"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Trọng lượng (kg/thùng)"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.form.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "weight", $$v);
      },
      expression: "form.weight"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Thể tích (m3 / thùng)"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.form.cubicMeter,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cubicMeter", $$v);
      },
      expression: "form.cubicMeter"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng diện tích"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      value: _vm.form.totalAcr,
      disabled: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng T.Lượng"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      value: _vm.form.totalWeight,
      disabled: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng T.Tích"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      value: (_vm.form.cubicMeter * _vm.form.quantity).toFixed(1),
      disabled: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "PVC/m2"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      size: "mini",
      value: _vm.form.shipFeeCarton,
      disabled: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng PVC"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      size: "mini",
      value: _vm.form.totalShipFeeCarton,
      disabled: ""
    }
  })], 1)], 1), _vm.visibleWithBox ? _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "PVC sau cùng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      size: "mini",
      value: _vm.form.totalShipFeeCarton < this.form.minShipFee ? this.form.minShipFee : this.form.totalShipFeeCarton,
      disabled: ""
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c("section", {
    staticClass: "box"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Thông tin đơn hàng ")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khoảng cách (Km)"
    }
  }, [_c("el-select", {
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.handleChangeForm("");
      }
    },
    model: {
      value: _vm.form.distance,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distance", $$v);
      },
      expression: "form.distance"
    }
  }, _vm._l(_vm.distances, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giá trị ĐH"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      size: "mini",
      value: _vm.form.totalMoneySell
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giá trị ĐH (Giao hàng)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      value: _vm.form.totalMoneyDelivery,
      size: "mini"
    }
  })], 1)], 1), _vm.isAdmin ? _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng chi phí"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      value: _vm.form.totalCost,
      size: "mini"
    }
  })], 1)], 1) : _vm._e(), _vm.isAdmin ? _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng LN"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }"
    }],
    attrs: {
      size: "mini",
      value: _vm.form.totalProfit
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _vm.isAdmin && (_vm.visibleWithBox || _vm.form.boxType == _vm.MoldBoxType.TamLot || _vm.form.boxType == _vm.MoldBoxType.VachNgan || _vm.form.boxType == _vm.MoldBoxType.GiayTam) ? _c("section", {
    staticClass: "box"
  }, [_c("div", [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Chi tiết giá vốn ")]), _c("el-button", {
    staticStyle: {
      "margin-left": "4px"
    },
    attrs: {
      size: "mini",
      disabled: !_vm.form.costs.length,
      type: "primary"
    },
    on: {
      click: _vm.handleViewDetailCost
    }
  }, [_vm._v(" Xem chi tiết ")])], 1), _c("el-table", {
    staticClass: "carton-cost-table",
    attrs: {
      data: _vm.form.costs,
      fit: "",
      "summary-method": _vm.getSummaries,
      "show-summary": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Thành phần",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(row.name))]), row.linked ? _c("div", [_vm.shouldUseLinked(row) ? _c("el-link", {
          staticStyle: {
            color: "#1890ff",
            "font-size": "11px"
          },
          on: {
            click: function click($event) {
              return _vm.handleSelectCost(row);
            }
          }
        }, [_vm._v(' Sử dụng "' + _vm._s(row.linked.name) + '" ')]) : _vm._e()], 1) : _vm._e()];
      }
    }], null, false, 1030783567)
  }), _c("el-table-column", {
    attrs: {
      label: "Loại",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.CartonCostTypeTrans[row.type]) + " ")];
      }
    }], null, false, 2190706125)
  }), _c("el-table-column", {
    attrs: {
      label: "Phân bổ",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.CartonCostCalcValueTypeTrans[row.calcValue]) + " " + _vm._s(_vm.CartonCostCalcTypeTrans[row.calcType]) + " ")];
      }
    }], null, false, 1587408942)
  }), _c("el-table-column", {
    attrs: {
      label: "Phí khởi đầu",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.minPrice)) + " đ ")];
      }
    }], null, false, 655867508)
  }), _c("el-table-column", {
    attrs: {
      label: "Phí khởi đầu / thùng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.unitMinPrice)) + " đ ")];
      }
    }], null, false, 494864466)
  }), _c("el-table-column", {
    attrs: {
      label: "Đơn giá",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.price)) + " đ ")];
      }
    }], null, false, 971586078)
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.quantity) + " ")];
      }
    }], null, false, 4059294468)
  }), _c("el-table-column", {
    attrs: {
      label: "Bật/Tắt",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [row.id ? _c("el-switch", {
          attrs: {
            value: row.isEnable,
            "active-color": "#13ce66",
            "inactive-color": "#ff4949"
          },
          on: {
            change: function change($event) {
              return _vm.changeCostEnable(row);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 1377155317)
  }), _c("el-table-column", {
    attrs: {
      label: "Chi phí / thùng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(" " + _vm._s(row.totalPrice.toFixed(0)) + " đ ")];
      }
    }], null, false, 2811880691)
  })], 1)], 1) : _vm._e()]) : _vm._e()], 1)], 1), _c("MoldModal", {
    ref: "MoldModal",
    on: {
      submitOk: _vm.handleAddMoldOk
    }
  }), _c("ViewNamedModal", {
    ref: "ViewNamedModal"
  }), _c("ViewCartonInfoModal", {
    ref: "ViewCartonInfoModal"
  }), _c("ViewDetailCostModal", {
    ref: "ViewDetailCostModal"
  }), _c("QuoteModal", {
    ref: "QuoteModal",
    attrs: {
      version: 3
    },
    on: {
      "submit:ok": _vm.handleSubmitQuoteOk
    }
  }), _c("UpdateCartonPriceModal", {
    ref: "UpdateCartonPriceModal",
    on: {
      submitOk: _vm.handleUpdatecartonPriceVersion
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;