"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    staticClass: "delivery-trip-detail-table",
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "cell-class-name": _vm.tableCellClassName
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      align: "center",
      width: "50"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row,
            $index = _ref.$index;
        return [_c("span", {
          style: {
            fontSize: _vm.visibleNote ? "16px" : "12px"
          }
        }, [_vm._v(_vm._s($index + 1))])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: _vm.visibleNote ? "16px" : "12px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" STT ")])])], 2), _vm.visibleProductCode ? _c("el-table-column", {
    attrs: {
      label: "Mã hàng",
      prop: "code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row,
            $index = _ref2.$index;
        return [_c("span", [_vm._v(" " + _vm._s(row.code) + " ")]), [!row.misaProduct && !row.matchMisaInventory && !row.misaInventory && !row.msOrderDetail ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleLinkProduct(row, $index);
            }
          }
        }, [_vm._v(" Liên kết sản phẩm ")]) : _vm._e(), row.matchMisaInventory ? _c("div", {
          staticStyle: {
            color: "red"
          },
          on: {
            click: function click($event) {
              return _vm.handleLinkProduct(row, $index);
            }
          }
        }, [_vm._v(" Đã liên kết: " + _vm._s(row.matchMisaInventory.name) + " ")]) : _vm._e()]];
      }
    }], null, false, 1899693919)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "Tên hàng",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("span", {
          style: {
            fontSize: _vm.visibleNote ? "16px" : "12px"
          }
        }, [_vm._v(" " + _vm._s(row.name) + " ")]), _vm.visibleNote ? _c("div", {
          staticStyle: {
            color: "#f54a45",
            "font-weight": "bold",
            "font-size": "18px"
          }
        }, [_vm._v(" " + _vm._s(_vm.visibleGiaCong(row) ? "Hàng theo yêu cầu" : "") + " ")]) : _vm._e()];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: _vm.visibleNote ? "16px" : "12px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tên hàng ")])])], 2), _vm.visibleCubicMeter ? _c("el-table-column", {
    attrs: {
      label: "Thể tích",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.totalCubicMeter) + " ")];
      }
    }], null, false, 314551592)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      label: "Số lượng",
      align: "right",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [!_vm.isDisableForm ? _c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }"
          }],
          on: {
            input: function input($event) {
              return _vm.handleChangeQuantity(row);
            }
          },
          model: {
            value: row.quantity,
            callback: function callback($$v) {
              _vm.$set(row, "quantity", $$v);
            },
            expression: "row.quantity"
          }
        }) : _c("span", {
          style: {
            fontSize: _vm.visibleNote ? "16px" : "12px"
          }
        }, [_vm._v(_vm._s(_vm.$formatNumberVN(row.quantity)))])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: _vm.visibleNote ? "16px" : "12px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Số lượng ")])])], 2), _vm.visibleDelivered ? [_c("el-table-column", {
    attrs: {
      label: "Đã giao",
      align: "right",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
          }],
          attrs: {
            disabled: _vm.isDisabled
          },
          on: {
            input: function input($event) {
              return _vm.handleChangeDelivered(row);
            }
          },
          model: {
            value: row.delivered,
            callback: function callback($$v) {
              _vm.$set(row, "delivered", $$v);
            },
            expression: "row.delivered"
          }
        })];
      }
    }], null, false, 2724594079)
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: _vm.visibleNote ? "16px" : "12px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Đã giao ")]), !_vm.isDisabled ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleFill
    }
  }, [_vm._v("Fill")]) : _vm._e()], 1)], 2), _c("el-table-column", {
    attrs: {
      label: "Số m2",
      align: "right",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("span", [_vm._v(" " + _vm._s(row.totalMeterDelivered) + " m2 ")])];
      }
    }], null, false, 3492902454)
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: "16px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Số m2 ")])])], 2), _c("el-table-column", {
    attrs: {
      label: "Tiền m2",
      align: "right",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("div", [_vm._v(_vm._s(_vm.$formatNumberVN(row.amountByMeter)) + "đ")])];
      }
    }], null, false, 3892901960)
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: "16px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tiền m2 ")])])], 2), _c("el-table-column", {
    attrs: {
      label: "Số m3",
      align: "right",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("span", [_vm._v(" " + _vm._s(row.totalCubicMeterDelivered) + " m3 ")])];
      }
    }], null, false, 11749257)
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: "16px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Số m3 ")])])], 2), _c("el-table-column", {
    attrs: {
      label: "Tiền m3",
      align: "right",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("div", [_vm._v(_vm._s(_vm.$formatNumberVN(row.amountByCubicMeter)) + "đ")])];
      }
    }], null, false, 3324194070)
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: "16px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tiền m3 ")])])], 2)] : [_c("el-table-column", {
    attrs: {
      label: "Tồn thành phẩm",
      align: "right",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c("span", {
          style: {
            color: row.totalTP < row.quantity ? "#f54a45" : "#34c724",
            fontWeight: "bold",
            fontSize: _vm.visibleNote ? "16px" : "12px"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalTP)) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: _vm.visibleNote ? "16px" : "12px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tồn thành phẩm ")])])], 2), _c("el-table-column", {
    attrs: {
      label: "Tồn BTP (Chưa dán)",
      align: "right",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_c("span", {
          style: {
            fontSize: _vm.visibleNote ? "16px" : "12px"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalBTP)) + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("label", {
    style: {
      fontSize: _vm.visibleNote ? "16px" : "12px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tồn BTP (Chưa dán) ")])])], 2)], _vm.visibleTotalNVL ? _c("el-table-column", {
    attrs: {
      label: "Tồn NVL (Chưa chạp,bế)",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalNVL)) + " "), row.totalNVLToTP ? _c("span", [_vm._v(" => " + _vm._s(_vm.$formatNumberVN(row.totalNVLToTP)) + " TP ")]) : _vm._e()];
      }
    }], null, false, 2315596771)
  }) : _vm._e(), !_vm.isDisableForm ? _c("el-table-column", {
    attrs: {
      label: "Xóa",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var $index = _ref14.$index;
        return [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleRemove($index);
            }
          }
        })];
      }
    }], null, false, 1224505069)
  }) : _vm._e()], 2)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;