"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.array.concat.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    attrs: {
      id: "upload-control"
    }
  }, [_c("el-upload", {
    staticStyle: {
      margin: "0 auto"
    },
    style: {
      height: "".concat(_vm.height, "px"),
      width: "".concat(_vm.width, "px")
    },
    attrs: {
      name: "file",
      drag: "",
      "before-upload": _vm.beforeUpload,
      action: _vm.$baseUrl + _vm.uploadUrl,
      "on-success": _vm.handleSuccess,
      "show-file-list": false,
      headers: {
        token: _vm.token,
        version: _vm.version
      },
      "on-progress": _vm.onProgress,
      "on-error": _vm.onError,
      disabled: _vm.disabled
    }
  }, [_vm.isProgressing ? _c("el-progress", {
    attrs: {
      type: "circle",
      percentage: _vm.percentUpload
    }
  }) : _vm._e(), !_vm.isProgressing ? [_vm.imagePath ? _c("img", {
    staticClass: "image",
    staticStyle: {
      "object-fit": "contain"
    },
    style: "width:".concat(_vm.width, "px;height:").concat(_vm.height, "px"),
    attrs: {
      src: _vm.imageUrl,
      alt: ""
    }
  }) : [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v(" Drag or "), _c("em", [_vm._v("click here to upload")])])]] : _vm._e()], 2), _vm.visibleDelete && _vm.imagePath ? _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("el-button", {
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa ảnh")])], 1) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;