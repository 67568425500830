"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới" : "Cập nhật",
      visible: _vm.visible,
      top: "30px",
      width: "1200px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên chuyến"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-button", {
    staticClass: "font-bold font-size--small",
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v("In")]), _c("truck-tour-print", {
    ref: "TruckTourPrint",
    staticStyle: {
      height: "0"
    },
    attrs: {
      truckTour: _vm.form
    }
  }), _c("el-table", {
    attrs: {
      data: _vm.form.orders,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Mã đơn"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank",
            href: "https://nhanh.vn/order/manage/detail?storeId=84054&id=".concat(row.id)
          }
        }, [_vm._v(" " + _vm._s(row.id) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(row.customer.phone) + " ")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.customer.name))]), _c("br"), _c("span", [_vm._v(_vm._s(row.customer.address))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sản phẩm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return _vm._l(row.productToOrders, function (item, index) {
          return _c("div", {
            key: index,
            staticClass: "product-grid",
            class: {
              "not-last": index != row.productToOrders.length - 1
            }
          }, [_c("label", [_vm._v(" " + _vm._s(item.product.name) + " ")]), _c("span", [item.product.unit ? _c("span", [_vm._v("(" + _vm._s(item.product.unit) + ")")]) : _vm._e()]), _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(item.price)) + " ")]), _c("span", {
            staticStyle: {
              "text-align": "right"
            }
          }, [_vm._v(" x" + _vm._s(item.quantity) + " ")])]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị",
      width: "90",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalPrice)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Mô tả",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("p", [_vm._v(" " + _vm._s(row.description) + " ")]), row.privateDescription ? _c("p", [_vm._v(" ---- "), _c("br"), _vm._v(" " + _vm._s(row.privateDescription) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Nhân viên",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("span", {
          staticClass: "order-status-label",
          class: row.statusCode
        }, [_vm._v(" " + _vm._s(row.statusName) + " ")]), _c("br"), _c("span", [_vm._v(_vm._s(row.seller.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var $index = _ref7.$index;
        return [_vm.form.orders.length > 1 ? _c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleDeleteOrder($index);
            }
          }
        }, [_vm._v("Xóa")]) : _vm._e()];
      }
    }])
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "danger",
      loading: _vm.loadingDelete
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa")])], 1), _c("div", [_vm.status == "update" ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "success",
      loading: _vm.loadingComplete
    },
    on: {
      click: _vm.completeTruckTour
    }
  }, [_vm._v("Hoàn thành")]) : _vm._e(), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Lưu")])], 1)])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;