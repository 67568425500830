"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaPOApi = void 0;

var _request = require("@/utils/request");

var misaPOApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaPo',
      params: params
    });
  },
  checkedMiss: function checkedMiss(id, isChecked) {
    return (0, _request.request)({
      url: "/v1/admin/misaPo/".concat(id, "/checked"),
      method: 'patch',
      data: {
        isChecked: isChecked
      }
    });
  },
  findMiss: function findMiss(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaPo/miss',
      params: params,
      timeout: 60 * 1000 * 5
    });
  },
  findPending: function findPending(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaPo/pending',
      params: params
    });
  }
};
exports.misaPOApi = misaPOApi;