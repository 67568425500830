"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaCrawlLogApi = void 0;

var _request = require("@/utils/request");

var misaCrawlLogApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaCrawlLog',
      params: params
    });
  }
};
exports.misaCrawlLogApi = misaCrawlLogApi;