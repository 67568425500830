"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_vm.$devMode ? _c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm mới")])], 1)]) : _vm._e(), _c("el-table", {
    attrs: {
      data: _vm.cartons,
      border: "",
      stripe: ""
    },
    on: {
      "row-click": _vm.handleUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Loại thùng",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Giá gia công"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.price)) + " đ/thùng ")];
      }
    }])
  })], 1), _c("carton-type-modal", {
    ref: "CartonTypeModal",
    on: {
      "submit:ok": _vm.fetchCartons
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;