"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Bắt đầu sản xuất",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.form.isFlowStep,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isFlowStep", $$v);
      },
      expression: "form.isFlowStep"
    }
  }, [_vm._v("Quy trình theo tuần tự")]), _c("el-form-item", {
    attrs: {
      label: "Quy trình",
      prop: "productionProcessId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    on: {
      change: _vm.handelChangeProcess
    },
    model: {
      value: _vm.form.productionProcessId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "productionProcessId", $$v);
      },
      expression: "form.productionProcessId"
    }
  }, _vm._l(_vm.productionProcesses, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _c("el-table", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      data: _vm.form.productionWorks,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Bước"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-select", {
          attrs: {
            value: row.productionStepId,
            disabled: !row.isAllowEditStep
          },
          on: {
            change: function change(val) {
              return _vm.handleChangeStep(row, val);
            }
          }
        }, _vm._l(_vm.productionSteps, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: item.name,
              value: item.id
            }
          });
        }), 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Xóa",
      width: "80",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var $index = _ref2.$index;
        return [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDeleteStep($index);
            }
          }
        })];
      }
    }])
  })], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAddStep
    }
  }, [_vm._v("Thêm bước")])], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.createData
    }
  }, [_vm._v("Bắt đầu sản xuất")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;