"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaOrderApi = void 0;

var _request = require("@/utils/request");

var misaOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaOrder',
      params: params
    });
  },
  getTotal: function getTotal(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaOrder/total',
      params: params
    });
  },
  sync: function sync() {
    return (0, _request.request)({
      url: '/v1/admin/misaOrder/sync',
      method: 'post'
    });
  }
};
exports.misaOrderApi = misaOrderApi;