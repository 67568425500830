"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-bottom": "4px"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Tìm kiếm ")]), _c("el-input", {
    staticStyle: {
      "min-width": "200px"
    },
    attrs: {
      icon: "el-icon-search",
      type: "primary",
      placeholder: "Tìm tên SP, đơn MH",
      size: "medium"
    },
    on: {
      click: _vm.fetchData
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Ngày ")]), _c("el-date-picker", {
    attrs: {
      clearable: false,
      type: "daterange",
      format: "dd/MM/yyyy",
      "range-separator": "-",
      "start-placeholder": "",
      "end-placeholder": ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)]), _c("div", {
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: true
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.isHiddenComplete,
      callback: function callback($$v) {
        _vm.isHiddenComplete = $$v;
      },
      expression: "isHiddenComplete"
    }
  }, [_vm._v(" Ẩn hoàn thành ")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      prop: "",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-button", {
          staticClass: "font-bold",
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleCreateInventory(row);
            }
          }
        }, [_vm._v(" Tạo thẻ kho")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số LSX",
      prop: "code",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày tạo",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("label", {
          class: [_vm.dateClass(row.startAt)],
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(_vm.$formatDate(row.startAt)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Diễn giải",
      "min-width": "250",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "ĐMH",
      prop: "poCode",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tên NVL",
      "min-width": "250",
      prop: "materialName"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng NVL",
      prop: "materialQuantity",
      align: "right",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Sóng",
      prop: "materialCartonWave",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Chống thấm",
      prop: "waterProof",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Kết cấu",
      prop: "materialCartonStruct",
      "min-width": "150"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Lịch sử nhập",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.totalInventory > 0 ? _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewInventory(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.totalInventory) + " lần ")]) : _vm._e()];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("CreateWorkOrderInventoryModal", {
    ref: "CreateWorkOrderInventoryModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ViewWorkOrderInventoryModal", {
    ref: "ViewWorkOrderInventoryModal",
    attrs: {
      version: _vm.version
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;