"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Cập nhật tag",
      visible: _vm.visible,
      width: "700px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("label", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "20px"
    }
  }, [_vm._v("Cập nhật tag")]), _c("br"), _vm.misaCustomer ? _c("span", [_vm._v(" " + _vm._s(_vm.misaCustomer.name) + " - " + _vm._s(_vm.misaCustomer.tel) + " ")]) : _vm._e()]), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tags"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      clearable: "",
      filterable: ""
    },
    model: {
      value: _vm.form.misaCustomerTagIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaCustomerTagIds", $$v);
      },
      expression: "form.misaCustomerTagIds"
    }
  }, _vm._l(_vm.misaCustomerTags, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("span", {
      staticStyle: {
        display: "inline-block",
        width: "12px",
        height: "12px",
        "border-radius": "50%",
        "vertical-align": "middle"
      },
      style: {
        background: item.color
      }
    }), _c("span", [_vm._v(" " + _vm._s(item.name) + " ")])]);
  }), 1)], 1), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.createTag
    }
  }, [_vm._v("Thêm thẻ")]), _vm.misaCustomerTags.length ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.viewTag
    }
  }, [_vm._v("Quản lý thẻ")]) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Đồng ý")])], 1), _c("MisaCustomerTagModal", {
    ref: "MisaCustomerTagModal",
    on: {
      submitOk: _vm.fetchMisaCustomerTags
    }
  }), _c("MisaCustomerTagListModal", {
    ref: "MisaCustomerTagListModal",
    on: {
      submitOk: _vm.updateTagsOk,
      delete: _vm.handleDelete
    }
  })], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;