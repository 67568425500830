"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "panel-shadow"
  }, [_c("h3", {
    staticClass: "text-text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v("Thông tin thêm")]), _c("div", [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Khối lượng quy đổi: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.weightExchange / 1000, 2)) + " Kg")])]), _c("div", [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Khối lượng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.weight / 1000, 2)) + " Kg")])]), _c("div", [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Cân nặng tối đa: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.weightMax / 1000, 2)) + " Kg / thùng")])]), _c("div", [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Diện tích giấy: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.acreage, 4)) + " m2")])]), _c("div", [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí vận chuyển của loại sóng: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.shipFeeWave)) + "đ")])]), _c("div", [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Phí vận chuyển: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.shipFee)) + "đ")])]), _vm.$store.state.user.info.role.id == 1 ? [_c("div", [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá vốn: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.costPrice)) + "đ (Phí in: " + _vm._s(_vm.$formatNumberVN(_vm.printCost)) + "đ, Phí thùng: " + _vm._s(_vm.$formatNumberVN(_vm.costPrice - _vm.printCost)) + "đ)")])]), _c("div", [_c("label", {
    staticClass: "text-color--black",
    attrs: {
      for: ""
    }
  }, [_vm._v("Giá bán: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.price)) + "đ (Phí v/c: " + _vm._s(_vm.$formatNumberVN(_vm.shipFee)) + "đ, Giá sp: " + _vm._s(_vm.$formatNumberVN(_vm.price - _vm.shipFee)) + "đ)")])])] : _vm._e()], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;