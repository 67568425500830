"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Khách hàng")]), _c("el-select", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.fetchCustomers
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.customerId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "customerId", $$v);
      },
      expression: "query.customerId"
    }
  }, _vm._l(_vm.customers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " - ").concat(item.phone),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm")])], 1)]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-s-promotion"
    },
    on: {
      click: _vm.saveAll
    }
  }, [_vm._v("Lưu tất cả")]), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-bottom": "12px",
      "margin-left": "12px"
    },
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.handleCreateLink
    }
  }, [_vm._v("Tạo liên kết cho khách")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "row-key": "id"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Sản phẩm",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "right",
      label: "Giá bán lẻ"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.price)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá số lượng 10"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          attrs: {
            placeholder: ""
          },
          model: {
            value: row.price10,
            callback: function callback($$v) {
              _vm.$set(row, "price10", $$v);
            },
            expression: "row.price10"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá số lượng 50"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          attrs: {
            placeholder: ""
          },
          model: {
            value: row.price50,
            callback: function callback($$v) {
              _vm.$set(row, "price50", $$v);
            },
            expression: "row.price50"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá số lượng 100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          attrs: {
            placeholder: ""
          },
          model: {
            value: row.price100,
            callback: function callback($$v) {
              _vm.$set(row, "price100", $$v);
            },
            expression: "row.price100"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá số lượng 300"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          attrs: {
            placeholder: ""
          },
          model: {
            value: row.price300,
            callback: function callback($$v) {
              _vm.$set(row, "price300", $$v);
            },
            expression: "row.price300"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá số lượng 500"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          attrs: {
            placeholder: ""
          },
          model: {
            value: row.price500,
            callback: function callback($$v) {
              _vm.$set(row, "price500", $$v);
            },
            expression: "row.price500"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá số lượng 1000"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          attrs: {
            placeholder: ""
          },
          model: {
            value: row.price1000,
            callback: function callback($$v) {
              _vm.$set(row, "price1000", $$v);
            },
            expression: "row.price1000"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            loading: row.loading
          },
          on: {
            click: function click($event) {
              return _vm.handleSave(row);
            }
          }
        }, [_vm._v("Lưu")])];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("CartonPriceModal", {
    ref: "CartonPriceModal"
  }), _c("CreateTokenModal", {
    ref: "CreateTokenModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;