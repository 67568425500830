"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới sản phẩm" : "Cập nhật sản phẩm",
      visible: _vm.visible,
      width: "1200px",
      top: "0px",
      "append-to-body": "",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại",
      prop: "carton"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium",
      "value-key": "id"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.carton,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "carton", $$v);
      },
      expression: "form.carton"
    }
  }, _vm._l(_vm.cartons, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lớp",
      prop: "layer"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeLayer
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_c("el-option", {
    attrs: {
      label: "3 lớp",
      value: 3
    }
  }), _c("el-option", {
    attrs: {
      label: "5 lớp",
      value: 5
    }
  }), _c("el-option", {
    attrs: {
      label: "7 lớp",
      value: 7
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại sóng",
      prop: "cartonWave"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      "value-key": "id",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.cartonWave,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonWave", $$v);
      },
      expression: "form.cartonWave"
    }
  }, _vm._l(_vm.cartonWavesFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Kết cấu giấy",
      prop: "cartonStruct"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium",
      filterable: "",
      "value-key": "id"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.cartonStruct,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cartonStruct", $$v);
      },
      expression: "form.cartonStruct"
    }
  }, _vm._l(_vm.cartonStructsFilter, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1)], 1), _vm.form.layer ? _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      position: "absolute",
      top: "0",
      left: "195px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "mini"
    },
    on: {
      click: _vm.handleSearchCartonStruct
    }
  }, [_vm._v("Tra cứu")]) : _vm._e(), _vm.form.cartonStruct && _vm.form.cartonStruct.note ? _c("small", [_vm._v("Ghi chú: " + _vm._s(_vm.form.cartonStruct.note))]) : _vm._e()], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài (cm)",
      prop: "length"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    attrs: {
      size: "medium"
    },
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.length,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length", $$v);
      },
      expression: "form.length"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Rộng (cm)",
      prop: "width"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    attrs: {
      size: "medium"
    },
    on: {
      input: function input($event) {
        _vm.handleChangeForm();

        _vm.generateName();
      }
    },
    model: {
      value: _vm.form.width,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width", $$v);
      },
      expression: "form.width"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cao (cm)",
      prop: "height"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    attrs: {
      size: "medium"
    },
    on: {
      input: function input($event) {
        _vm.handleChangeForm();

        _vm.generateName();
      }
    },
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng",
      prop: "quantity"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    attrs: {
      size: "medium"
    },
    on: {
      input: function input($event) {
        _vm.handleChangeForm();

        _vm.generateName();
      }
    },
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng màu"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeNumColor
    },
    model: {
      value: _vm.form.numColor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "numColor", $$v);
      },
      expression: "form.numColor"
    }
  }, _vm._l(5, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: "".concat(item, " m\xE0u"),
        value: item
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "B\u1EA3n in(~".concat(_vm.$formatNumberVN(Math.ceil(_vm.framePrintCostSuggest)), "\u0111)")
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    attrs: {
      disabled: !_vm.form.numColor,
      size: "medium"
    },
    on: {
      change: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.framePrintCost,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "framePrintCost", $$v);
      },
      expression: "form.framePrintCost"
    }
  })], 1)], 1), _vm.isSmallSize && _vm.form.quantity < 1000 ? _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [this.form.length < 25 ? _c("span", {
    staticStyle: {
      color: "#ff1515"
    }
  }, [_vm._v("Chiều dài tối thiểu là 25cm")]) : +this.form.width + +this.form.height < 32 ? _c("span", {
    staticStyle: {
      color: "#ff1515"
    }
  }, [_vm._v("Chiều rộng + chiều cao không đủ 32cm")]) : +this.form.width < 6 ? _c("span", {
    staticStyle: {
      color: "#ff1515"
    }
  }, [_vm._v("Chiều rộng không đủ 6cm")]) : +this.form.height < 10 ? _c("span", {
    staticStyle: {
      color: "#ff1515"
    }
  }, [_vm._v("Chiều cao không đủ 10cm")]) : _c("span", {
    staticStyle: {
      color: "#ff1515"
    }
  }, [_vm._v("Số lượt ít nhất là 1.000 thùng nếu là thùng trơn. Và 3.000 thùng nếu là thùng in")])]) : !_vm.isSmallSize && _vm.form.quantity < 500 ? _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("span", {
    staticStyle: {
      color: "#ff1515"
    }
  }, [_vm._v("Số lượng ít nhất là 500 thùng nếu là thùng trơn và 1.000 thùng nếu là thùng in")])]) : _vm._e(), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mặt chống thấm",
      size: "normal"
    }
  }, [_c("el-select", {
    attrs: {
      "value-key": "",
      placeholder: "",
      clearable: "",
      filterable: ""
    },
    on: {
      change: function change($event) {
        _vm.handleChangeForm();

        _vm.generateName();
      }
    },
    model: {
      value: _vm.form.waterProofFaceType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "waterProofFaceType", $$v);
      },
      expression: "form.waterProofFaceType"
    }
  }, _vm._l(_vm.waterProofFaces, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên sản phẩm"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giá gợi ý"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    attrs: {
      value: _vm.form.priceSuggest,
      size: "medium",
      readonly: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giá bán (sale)",
      prop: "price"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Ghi chú"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1)], 1), _c("product-quote-info-panel", {
    attrs: {
      weight: _vm.totalWeight,
      weightExchange: _vm.totalWeightExchange,
      weightMax: _vm.form.cartonStruct.weight,
      acreage: _vm.acreage,
      shipFee: _vm.moneyTransfer,
      shipFeeWave: _vm.form.cartonWave ? _vm.form.cartonWave.shipFee : 0,
      costPrice: _vm.form.costPrice,
      price: _vm.form.price,
      printCost: _vm.form.printCost
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-size--medium font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Lưu")])], 1), _c("search-carton-struct-modal", {
    ref: "SearchCartonStructModal",
    on: {
      save: _vm.handleChooseCartonStruct
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;