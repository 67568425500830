"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", [_c("div", {
    staticStyle: {
      "font-size": "20px",
      "margin-bottom": "24px",
      color: "red"
    }
  }, [_vm._v(" Nhập mã LSX để nhập kho hoặc quét QRCode ")]), !_vm.workOrder ? [_c("div", {
    staticClass: "qr-container"
  }, [_c("el-row", {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18,
      xs: 24,
      offset: 0
    }
  }, [_c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhập mã LSX")]), _c("el-input", {
    attrs: {
      placeholder: "",
      size: "normal",
      clearable: ""
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchWorkOrder.apply(null, arguments);
      }
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1)]), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(17px)"
    },
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-search",
      loading: _vm.loadingSearch
    },
    on: {
      click: _vm.fetchWorkOrder
    }
  }, [_vm._v("Tìm kiếm")])], 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("QrcodeStream", {
    attrs: {
      paused: _vm.paused,
      constraints: {
        facingMode: _vm.facingMode
      },
      formats: ["qr_code"]
    },
    on: {
      decode: _vm.onDetect,
      error: _vm.onError
    }
  })], 1)], 1)] : [_c("span", {
    staticStyle: {
      "font-size": "24px",
      color: "#000",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.workOrder.code))]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.workOrder.name))]), _c("div", {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-receiving",
      size: "mini"
    },
    on: {
      click: _vm.handleImport
    }
  }, [_vm._v("Nhập kho")]), [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      icon: "el-icon-magic-stick",
      size: "mini"
    },
    on: {
      click: _vm.scanOther
    }
  }, [_vm._v("Quét mã khác")]), _vm.productionReport ? [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: function click($event) {
        return _vm.handlePrint("tp");
      }
    }
  }, [_vm._v("In phiếu TP")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: function click($event) {
        return _vm.handlePrint("btp");
      }
    }
  }, [_vm._v("In phiếu BTP, NVL")])] : _vm._e()]], 2), _vm.productionReport ? _c("div", [_vm._m(0), _c("ProductionReportTable", {
    attrs: {
      loading: _vm.loading,
      productionReports: _vm.productionReports,
      visibleInspec: false
    },
    on: {
      delete: _vm.handleDelete,
      print: _vm.handlePrint,
      update: _vm.handleUpdate
    }
  })], 1) : _vm._e()]], 2), _c("CreateReportQuantityModal", {
    ref: "CreateReportQuantityModal",
    on: {
      submitOk: _vm.handleReportOk
    }
  }), _vm.productionReport ? _c("ReportTPPrint", {
    ref: "ReportTPPrint",
    attrs: {
      productionReport: _vm.productionReport,
      workOrder: _vm.workOrder
    }
  }) : _vm._e(), _vm.productionReport ? _c("ReportBTPPrint", {
    ref: "ReportBTPPrint",
    attrs: {
      productionReport: _vm.productionReport,
      workOrder: _vm.workOrder
    }
  }) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      "margin-bottom": "8px",
      "margin-top": "24px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lịch sử nhập kho")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;