"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.concat.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhóm Sản phẩm")]), _c("el-select", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      filterable: "",
      remote: "",
      size: "medium",
      "remote-method": _vm.fetchProductGroups
    },
    on: {
      change: _vm.handleChangeProductGroup
    },
    model: {
      value: _vm.query.productGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "productGroupId", $$v);
      },
      expression: "query.productGroupId"
    }
  }, _vm._l(_vm.productGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Khách hàng ")]), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      size: "medium",
      "remote-method": _vm.fetchCustomers
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.customerId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "customerId", $$v);
      },
      expression: "query.customerId"
    }
  }, _vm._l(_vm.customers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " - ").concat(item.phone),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm cấu hình")])], 1)]), _vm.query.customerId ? _c("PromotionList", {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      customer: _vm.customer,
      promotions: _vm.customer.promotions
    }
  }) : _vm._e(), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    },
    on: {
      "row-click": _vm.handleUpdate
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Giá trị đơn hàng",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalPrice)) + "đ ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Lợi nhuận (%)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.profitPercent) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "120",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-button", {
          attrs: {
            type: "danger",
            size: "small",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleDelete(row);
            }
          }
        })];
      }
    }])
  })], 1), _c("ProfitProductGroupConfigModal", {
    ref: "ProfitProductGroupConfigModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;