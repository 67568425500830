"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Lịch sử thay đổi chuyến",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      "min-height": "200px"
    }
  }, [!_vm.deliveryTripLogs.length ? [_c("span", [_vm._v("Chưa có lịch sử nào!")])] : _c("el-timeline", _vm._l(_vm.deliveryTripLogs, function (item, index) {
    return _c("el-timeline-item", {
      key: index,
      attrs: {
        color: index == 0 ? "#0bbd87" : "",
        timestamp: _vm.$formatDate(item.dateCreated, "HH:mm, DD/MM/YYYY")
      }
    }, [_c("el-card", [_c("p", [_vm._v(_vm._s(item.desc))]), item.type == _vm.DeliveryTripLogType.Modify ? _c("el-button", {
      attrs: {
        icon: "el-icon-info",
        type: "primary",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.viewDetail(item);
        }
      }
    }, [_vm._v("Xem chi tiết")]) : _vm._e()], 1)], 1);
  }), 1)], 2), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1), _c("DeliveryLogDetailModal", {
    ref: "DeliveryLogDetailModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;