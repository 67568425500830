"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBuyTypeContent = exports.MisaBuyType = void 0;
var MisaBuyType = {
  'LATE': 'Lâu chưa mua',
  'SOON': 'Sắp mua'
};
exports.MisaBuyType = MisaBuyType;

var getBuyTypeContent = function getBuyTypeContent(misaCustomer) {
  var avgDayBetweenPurchase = misaCustomer.avgDayBetweenPurchase,
      purchaseDay = misaCustomer.purchaseDay,
      dayWithoutPurchase = misaCustomer.dayWithoutPurchase;
  var day = purchaseDay || avgDayBetweenPurchase;

  if (!day) {
    return;
  }

  var dayDiff = Math.abs(day - dayWithoutPurchase);

  if (day <= 5) {
    if (dayDiff <= 2) {
      return "SOON";
    }
  } else if (day <= 10) {
    if (dayDiff <= 3) {
      return "SOON";
    }
  } else if (day <= 20) {
    if (dayDiff <= 5) {
      return "SOON";
    }
  } else {
    if (dayDiff <= 10) {
      return "SOON";
    }
  }

  if (dayWithoutPurchase > day) {
    if (day <= 5) {
      if (dayDiff > 2) {
        return "LONG";
      }
    } else if (day <= 10) {
      if (dayDiff > 3) {
        return "LONG";
      }
    } else if (day <= 20) {
      if (dayDiff > 5) {
        return "LONG";
      }
    } else {
      if (dayDiff > day / 2) {
        return "LONG";
      }

      return "";
    }
  }
};

exports.getBuyTypeContent = getBuyTypeContent;