"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "customer-modal",
    attrs: {
      title: "#KH ".concat(_vm.customer.name),
      visible: _vm.visible,
      width: "1200px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.handleClose
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("h2", {
          staticStyle: {
            margin: "0"
          }
        }, [_c("el-link", {
          staticStyle: {
            "font-size": "30px"
          },
          attrs: {
            target: "_blank",
            href: "https://nhanh.vn/order/manage/index?storeId=84054&customerName=".concat(_vm.customer.phone),
            type: "primary"
          }
        }, [_vm._v("#KH " + _vm._s(_vm.customer.name))])], 1)];
      },
      proxy: true
    }])
  }, [_c("el-tabs", {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      name: "info",
      label: "Thông tin"
    }
  }, [_c("section", [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingData,
      expression: "loadingData"
    }],
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c("section", [_c("el-checkbox", {
    model: {
      value: _vm.customer.isAddFriendZalo,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "isAddFriendZalo", $$v);
      },
      expression: "customer.isAddFriendZalo"
    }
  }, [_vm._v(" Đã kết bạn Zalo ")]), _c("el-checkbox", {
    attrs: {
      "read-only": ""
    },
    model: {
      value: _vm.customer.isCrossSell,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "isCrossSell", $$v);
      },
      expression: "customer.isCrossSell"
    }
  }, [_vm._v(" Đã bán chéo ")]), _c("el-checkbox", {
    attrs: {
      "read-only": ""
    },
    model: {
      value: _vm.customer.isRef,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "isRef", $$v);
      },
      expression: "customer.isRef"
    }
  }, [_vm._v(" Đã giới thiệu ")])], 1)]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 10,
      offset: 0
    }
  }, [_c("section", [_c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("SĐT: ")]), _c("span", [_vm._v(_vm._s(_vm.customer.phone))])]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Địa chỉ: ")]), _c("span", [_vm._v(_vm._s(_vm.customer.address))])]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Sản phẩm đã mua: ")]), !_vm.boughtProducts.length ? _c("span", [_vm._v("Không có!")]) : _c("ul", _vm._l(_vm.boughtProducts, function (item, index) {
    return _c("li", {
      key: index
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Số đơn hàng: ")]), _c("span", [_vm._v(_vm._s(_vm.customer.totalOrder))])]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Chu kỳ: ")]), _c("span", [_vm._v(_vm._s(_vm.customer.buyCycle) + " ngày")])]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ghi chú: ")]), _c("el-input", {
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 7
      }
    },
    model: {
      value: _vm.customer.note,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "note", $$v);
      },
      expression: "customer.note"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("N.viên chăm sóc: ")]), _c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.sellerId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sellerId", $$v);
      },
      expression: "form.sellerId"
    }
  }, _vm._l(_vm.sellers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Gắn tags: ")]), _c("el-select", {
    attrs: {
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.customer.tagArr,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "tagArr", $$v);
      },
      expression: "customer.tagArr"
    }
  }, _vm._l(_vm.customerTags, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Block khách hàng:")]), _c("br"), _c("el-checkbox", {
    staticStyle: {
      "margin-top": "8px",
      display: "block"
    },
    model: {
      value: _vm.customer.isIgnoreTask,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "isIgnoreTask", $$v);
      },
      expression: "customer.isIgnoreTask"
    }
  })], 1)]), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày mua kế tiếp:")]), _c("el-date-picker", {
    staticStyle: {
      "margin-top": "8px",
      display: "block"
    },
    attrs: {
      type: "date",
      format: "dd/MM/yyyy"
    },
    model: {
      value: _vm.customer.nextBuyAt,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "nextBuyAt", $$v);
      },
      expression: "customer.nextBuyAt"
    }
  })], 1)])], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      type: "primary",
      loading: _vm.loadingUpdate,
      size: "default"
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Cập nhật thông tin")])], 1), _c("el-col", {
    attrs: {
      span: 14,
      offset: 0
    }
  }, [_c("CustomerLog", {
    attrs: {
      customerId: _vm.customer.id
    },
    on: {
      submitOk: _vm.onSubmitOk
    }
  })], 1)], 1)], 1)]), _c("el-tab-pane", {
    attrs: {
      name: "promotion",
      label: "Khuyến mãi"
    }
  }, [_c("PromotionList", {
    attrs: {
      customer: _vm.customer,
      promotions: _vm.customer.promotions
    },
    on: {
      submitOk: _vm.fetchData
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div"), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;