"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaLeadApi = void 0;

var _request = require("@/utils/request");

var misaLeadApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaLead',
      params: params
    });
  },
  countDuplicate: function countDuplicate() {
    return (0, _request.request)({
      url: '/v1/admin/misaLead/count/duplicate'
    });
  },
  summaryCategory: function summaryCategory(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaLead/summary/category',
      params: params
    });
  },
  summaryStaff: function summaryStaff(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaLead/summary/staff',
      params: params
    });
  },
  summaryStatus: function summaryStatus(params) {
    return (0, _request.request)({
      url: '/v1/admin/misaLead/summary/status',
      params: params
    });
  },
  changeOwner: function changeOwner(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/misaLead/".concat(id, "/changeOwner"),
      data: data,
      method: 'patch'
    });
  },
  updateStatus: function updateStatus(id, status) {
    return (0, _request.request)({
      url: "/v1/admin/misaLead/".concat(id, "/status"),
      data: {
        status: status
      },
      method: 'patch'
    });
  },
  findCategory: function findCategory() {
    return (0, _request.request)({
      url: '/v1/admin/misaLead/category'
    });
  },
  findSource: function findSource() {
    return (0, _request.request)({
      url: '/v1/admin/misaLead/source'
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaLead',
      data: data,
      method: 'post'
    });
  },
  importData: function importData(data) {
    return (0, _request.request)({
      url: '/v1/admin/misaLead/import',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/misaLead/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/misaLead/".concat(id),
      method: 'delete'
    });
  }
};
exports.misaLeadApi = misaLeadApi;