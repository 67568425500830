"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCartonType = exports.getBestFactorKhoTam = exports.checkOverSize = exports.calcCubicMeter = exports.calc = void 0;

require("core-js/modules/es.error.cause.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.number.to-fixed.js");

var _mold = require("@/types/mold");

var _utils = require("@/utils");

var _elementUi = require("element-ui");

var _lodash = require("lodash");

/**
 * - lấy loại thùng bế hay chạp
 * - Nếu dài >=25, Rộng + Cao >=32, Rộng>=6, Cao >=10 => Kiểu làm là chạp
 * - Còn lại là bế
 */
var getCartonType = function getCartonType(_ref) {
  var length = _ref.length,
      width = _ref.width,
      height = _ref.height;

  if (length >= 25 && width + height >= 32 && width >= 6 && height >= 10) {
    return 'CHAP';
  }

  return 'BE';
};
/**
 * - Dài tấm <= 230
 * - Khổ tấm <= 80
 * - Dài tấm > 115 thì giá +5%
 * @returns true: +%5
 */


exports.getCartonType = getCartonType;

var checkOverSize = function checkOverSize(_ref2) {
  var length = _ref2.length,
      width = _ref2.width,
      height = _ref2.height;
  var maxLength = 510;

  if (width + height >= 140) {
    throw new Error("Thùng này quá chiều khổ (Tối đa chỉ nhỏ 140cm)");
  }

  if ((length + width) * 2 >= maxLength) {
    throw new Error("Thùng này quá chiều dài tấm ngay cả khi ghép giấy (Tối đa nhỏ hơn 320cm)");
  }

  if (width + height >= 120 && width + height < 140) {
    return true;
  }

  if ((length + width) * 2 >= 260 && (length + width) * 2 < maxLength) {
    return true;
  }

  return false;
};

exports.checkOverSize = checkOverSize;

var getBestFactorKhoTam = function getBestFactorKhoTam(_ref3) {
  var currentFactor = _ref3.currentFactor,
      khoTam = _ref3.khoTam,
      khoTamCongThemNCC = _ref3.khoTamCongThemNCC,
      originKhoTamNcc = _ref3.originKhoTamNcc;
  var minCut = originKhoTamNcc - (khoTam * currentFactor + khoTamCongThemNCC);
  var newFactor = currentFactor,
      newKhoTamNcc = originKhoTamNcc;

  for (var i = currentFactor; i <= 5; i++) {
    var beforeKhoTamNCC = khoTam * i + khoTamCongThemNCC;
    var khoTamNCC = (0, _utils.ceiling)(beforeKhoTamNCC, 5);
    var newMinCut = khoTamNCC - beforeKhoTamNCC;

    if (minCut > newMinCut && khoTamNCC <= 220) {
      minCut = newMinCut;
      newFactor = i;
      newKhoTamNcc = khoTamNCC;
    }
  }

  return {
    newFactor: newFactor,
    newKhoTamNcc: newKhoTamNcc
  };
};
/**
 * Xác định khổ giấy, và tính tiền
 */


exports.getBestFactorKhoTam = getBestFactorKhoTam;

var calc = function calc(_ref4) {
  var length = _ref4.length,
      width = _ref4.width,
      height = _ref4.height,
      cartonWave = _ref4.cartonWave,
      cartonStruct = _ref4.cartonStruct,
      unitGiaCong = _ref4.unitGiaCong,
      unitPriceKeo = _ref4.unitPriceKeo,
      unitPriceDay = _ref4.unitPriceDay,
      unitPriceGhim = _ref4.unitPriceGhim,
      unitPriceIn = _ref4.unitPriceIn,
      type = _ref4.type,
      beMatrix = _ref4.beMatrix,
      isTesting = _ref4.isTesting,
      mold = _ref4.mold,
      loaiThung = _ref4.loaiThung,
      waterFace = _ref4.waterFace;
  var taiThung = 0; //tai thùng

  var daiTam = 0,
      khoTam = 0; //dài tấm, khổ tấm

  var khoTamNCC = 0,
      daiTamNCC = 0,
      minKhoTamNcc = 115,
      minDaiTamNCC = 50;
  var realKhoTam = 0,
      realDaiTam = 0; //khổ tấm, dài tấm thực tế

  var factorKhoTam = 1,
      factorDaiTam = 1; //tỉ trọng khổ, dài

  var realQuantity = 0; //sl thùng thực tế khi cắt từ giấy ncc

  var unitPriceGiay = cartonStruct.price;
  var totalPriceGiay = 0;
  var totalGiaCong = 0;
  var totalPriceKeo = 0,
      totalPriceDay = 0,
      totalPriceGhim = 0,
      totalPriceIn = 0;
  var uocTinhKhoTam = 0,
      uocTinhDaiTam = 0;
  var daiKhuonBe = 1,
      rongKhuonBe = 1;

  if (cartonWave.code == 'B' && cartonWave.layer == 3) {
    taiThung = 2.5;
  } else if (cartonWave.code == 'C' && cartonWave.layer == 3) {
    taiThung = 3;
  } else if (cartonWave.layer == 5) {
    taiThung = 3.5;
  } else if (cartonWave.layer == 7) {
    taiThung = 4;
  } //


  switch (loaiThung) {
    case _mold.MoldBoxType.A1:
      if (isTesting && !(0, _lodash.isEmpty)(mold)) {
        daiTam = mold.cartonLength;
        khoTam = mold.cartonWidth;
      } else {
        daiTam = (length + width) * 2 + taiThung;
        khoTam = width + height; //Cộng phần nắp thùng

        if (cartonWave.layer == 3) {
          khoTam += .2;
        } else if (cartonWave.layer == 5) {
          khoTam += .4;
        } else if (cartonWave.layer == 7) {
          khoTam += .6;
        }
      }

      break;
    // case MoldBoxType.

    default:
      break;
  }

  if (type == 'BE' && beMatrix) {
    rongKhuonBe = +beMatrix.split('x')[0];
    daiKhuonBe = +beMatrix.split('x')[1];
    daiTam = +(daiTam * daiKhuonBe + 2).toFixed(1);
    khoTam = +(khoTam * rongKhuonBe + 2).toFixed(1);
  }

  var khoTamCongThemNCC = 2; //Nếu khổ tấm < 115, thì ghép giấy để đủ khổ >= 115

  var isGetBestFactor = false;

  if (khoTam < minKhoTamNcc) {
    factorKhoTam = Math.ceil(minKhoTamNcc / khoTam);

    if (khoTam * factorKhoTam + khoTamCongThemNCC <= 220) {
      khoTamNCC = khoTam * factorKhoTam + khoTamCongThemNCC;
      isGetBestFactor = true;
    } else {
      factorKhoTam = 1;
      khoTamNCC = minKhoTamNcc;
    }
  } else {
    khoTamNCC = khoTam + khoTamCongThemNCC;
  }

  khoTamNCC = (0, _utils.ceiling)(khoTamNCC, 5);

  if (khoTam < minKhoTamNcc && isGetBestFactor) {
    var _getBestFactorKhoTam = getBestFactorKhoTam({
      currentFactor: factorKhoTam,
      khoTam: khoTam,
      khoTamCongThemNCC: khoTamCongThemNCC,
      originKhoTamNcc: khoTamNCC
    }),
        newFactor = _getBestFactorKhoTam.newFactor,
        newKhoTamNcc = _getBestFactorKhoTam.newKhoTamNcc;

    if (newFactor != factorKhoTam) {
      factorKhoTam = newFactor;
      khoTamNCC = newKhoTamNcc;
    }
  }

  if (khoTamNCC > 220) {
    _elementUi.Message.error("Khổ tấm NCC đã vượt quá 220cm");
  } //check ngoại lệ


  if (khoTamNCC == 195) {
    khoTamNCC = 200;
  } else if (khoTamNCC == 205) {
    khoTamNCC = 210;
  } else if (khoTamNCC == 215) {
    khoTamNCC = 220;
  } // Nếu dài tấm < 80, thì ghép giấy để đủ dài >= 80


  if (daiTam < minDaiTamNCC) {
    factorDaiTam = Math.ceil(minDaiTamNCC / daiTam);
    daiTamNCC = +(daiTam * factorDaiTam).toFixed(1);
  } else {
    daiTamNCC = daiTam;
  } //tính khổ, dài thực tế


  realKhoTam = +(khoTamNCC / factorKhoTam).toFixed(1);
  realDaiTam = +(daiTamNCC / factorDaiTam).toFixed(1); //sl thùng thực tế

  var quantityFromNCC = Math.floor(khoTamNCC * daiTamNCC / (realKhoTam * realDaiTam)); //1 tấm ncc -> ? tấm nhỏ

  realQuantity = isTesting && !(0, _lodash.isEmpty)(mold) ? mold.numOfProducts * quantityFromNCC : quantityFromNCC; //tính tổng phí gia công

  totalGiaCong = Math.ceil(realKhoTam * realDaiTam / 10000 * unitGiaCong);
  uocTinhKhoTam = +(khoTamNCC / (khoTamNCC / khoTam)).toFixed(1);
  uocTinhDaiTam = +(daiTamNCC / (daiTamNCC / daiTam)).toFixed(1); //

  totalPriceKeo = height * unitPriceKeo;
  totalPriceDay = width * unitPriceDay;
  totalPriceGhim = height * unitPriceGhim;
  totalPriceIn = realDaiTam * realKhoTam / 10000 * unitPriceIn;
  totalPriceGiay = Math.ceil(realDaiTam * realKhoTam / 10000 * unitPriceGiay);

  if (isTesting && !(0, _lodash.isEmpty)(mold)) {
    totalPriceGiay = Math.ceil(totalPriceGiay / mold.numOfProducts);
  }

  var factorMinQuantity = 300;

  if (waterFace || cartonWave.code == 'BA') {
    factorMinQuantity = 700;
  } else if (cartonWave.code == 'E') {
    factorMinQuantity = 500;
  }

  var minQuantity = Math.ceil(factorMinQuantity * 100 * realQuantity / daiTamNCC);
  var cubicMeter = calcCubicMeter({
    daiTam: realDaiTam,
    khoTam: realKhoTam,
    cartonWaveCode: cartonWave.code
  });

  if (type == 'BE') {
    cubicMeter = +(cubicMeter / realQuantity).toFixed(5);
  }

  return {
    totalGiaCong: totalGiaCong,
    taiThung: taiThung,
    daiTam: daiTam,
    khoTam: khoTam,
    realKhoTam: realKhoTam,
    realDaiTam: realDaiTam,
    realQuantity: realQuantity,
    totalPriceKeo: totalPriceKeo,
    totalPriceDay: totalPriceDay,
    totalPriceGhim: totalPriceGhim,
    totalPriceIn: totalPriceIn,
    totalPriceGiay: totalPriceGiay,
    daiTamNCC: daiTamNCC,
    khoTamNCC: khoTamNCC,
    uocTinhDaiTam: uocTinhDaiTam,
    uocTinhKhoTam: uocTinhKhoTam,
    daiKhuonBe: daiKhuonBe,
    rongKhuonBe: rongKhuonBe,
    minQuantity: minQuantity,
    cubicMeter: cubicMeter
  };
};
/**
 * - Tính thể tích: Sóng B 0.3, C 0.4,  E 0.2, BC 0.65, BCE 0.8
 */


exports.calc = calc;

var calcCubicMeter = function calcCubicMeter(_ref5) {
  var daiTam = _ref5.daiTam,
      khoTam = _ref5.khoTam,
      cartonWaveCode = _ref5.cartonWaveCode;
  var cubicMeter = 0;

  if (cartonWaveCode == 'B') {
    cubicMeter = daiTam * khoTam * 0.3;
  } else if (cartonWaveCode == 'C') {
    cubicMeter = daiTam * khoTam * 0.4;
  } else if (cartonWaveCode == 'E') {
    cubicMeter = daiTam * khoTam * 0.2;
  } else if (cartonWaveCode == 'A') {
    cubicMeter = daiTam * khoTam * 0.5;
  } else if (cartonWaveCode == 'BC') {
    cubicMeter = daiTam * khoTam * 0.65;
  } else if (cartonWaveCode == 'BCE') {
    cubicMeter = daiTam * khoTam * 0.8;
  } else if (cartonWaveCode == 'BA') {
    cubicMeter = daiTam * khoTam * 0.8;
  }

  cubicMeter = +(cubicMeter / 1000000).toFixed(5);
  return cubicMeter;
};

exports.calcCubicMeter = calcCubicMeter;