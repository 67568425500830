"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiêu đề",
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung",
      prop: "content"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Loại câu danh cho"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.ChatContentType.LongBuy
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Khách lâu chưa mua")]), _c("el-radio", {
    attrs: {
      label: _vm.ChatContentType.SoonBuy
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Khách gần mua")])], 1), _c("section", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Kết quả ví dụ: ")]), _c("span", [_vm._v(_vm._s(_vm.convertedContent))])]), _c("section", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("Các token: ")]), _c("div", {
    staticStyle: {
      "line-height": "22px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("${customerName}: ")]), _c("span", [_vm._v("Tên khách hàng")])]), _c("div", {
    staticStyle: {
      "line-height": "22px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("${me}: ")]), _c("span", [_vm._v("Danh xưng của mình(em đối với anh chị, con đối với các trường hợp còn lại)")])]), _c("div", {
    staticStyle: {
      "line-height": "22px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("${productCategoryName}: ")]), _c("span", [_vm._v("Danh danh tên danh mục các sản phẩm đã mua (Băng keo, xốp hơi, ...)")])]), _c("div", {
    staticStyle: {
      "line-height": "22px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("${firstNameCap}: ")]), _c("span", [_vm._v("Tên danh xưng đối với khách, viết hoa chữ cái đầu (Anh, Chị, Cô, Chú, ...)")])]), _c("div", {
    staticStyle: {
      "line-height": "22px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("${firstName}: ")]), _c("span", [_vm._v("Tên danh xưng đối với khách (Anh, Chị, Cô, Chú, ...)")])])])], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;