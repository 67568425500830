"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Tạo mới công việc",
      visible: _vm.visible,
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Công việc",
      prop: "content"
    }
  }, [_c("el-input", {
    attrs: {
      autosize: {
        minRows: 3,
        maxRows: 7
      },
      type: "textarea"
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Báo giá",
      prop: "quoteId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.fetchQuotes,
      clearable: ""
    },
    model: {
      value: _vm.form.quoteId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quoteId", $$v);
      },
      expression: "form.quoteId"
    }
  }, _vm._l(_vm.quotes, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.customer.name, "-").concat(item.customer.phone),
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Thời gian"
    }
  }, [_c("el-date-picker", {
    attrs: {
      disabled: "",
      format: "HH:mm dd/MM/yyyy"
    },
    model: {
      value: _vm.form.dueDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dueDate", $$v);
      },
      expression: "form.dueDate"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.createData
    }
  }, [_vm._v("Lưu")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;