"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calcDaiKhoTamThungV3 = exports.calcDaiKhoTamThung = exports.calcDaiKhoTamThucTeV3 = exports.calcDaiKhoTamThucTe = exports.calcDaiKhoTamNho = exports.calcCubicTamGiay = exports.calcCubicMeter1Thung = exports.calcCarton = void 0;

require("core-js/modules/es.error.cause.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.to-fixed.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.array.sort.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

var _objectSpread2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));

var _cartonCost = require("@/types/cartonCost");

var _mold = require("@/types/mold");

var _Product = require("@/types/Product");

var _lodash = require("lodash");

var _handler = require("../quote-carton-v2/handler");

var printPriceFactors = [1, 1.2, 1.4, 1.6, 1.8];
/**
 * Lấy % lợi nhuận theo giá trị hàng
 */

var getProfitPercentByAmount = function getProfitPercentByAmount(_ref) {
  var _ref$amount = _ref.amount,
      amount = _ref$amount === void 0 ? 0 : _ref$amount,
      cartonProfitConfigs = _ref.cartonProfitConfigs;
  var percent = null;

  var _iterator = (0, _createForOfIteratorHelper2.default)(cartonProfitConfigs),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var config = _step.value;

      if (amount >= config.fromAmount && amount <= config.toAmount) {
        percent = config.percent;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  if (percent == null) {
    throw new Error("Không tìm thấy % lợi nhuận");
  }

  return percent / 100;
};
/**
 * Lấy % lợi nhuận theo sl
 */


var getProfitPercentByQuantity = function getProfitPercentByQuantity(_ref2) {
  var quantity = _ref2.quantity,
      numColor = _ref2.numColor,
      moneyFinal = _ref2.moneyFinal,
      quantityProfitConfig = _ref2.quantityProfitConfig;
  var percent = 0;

  var _iterator2 = (0, _createForOfIteratorHelper2.default)(quantityProfitConfig),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _config = _step2.value;

      if (moneyFinal * quantity >= _config.totalPrice) {
        if (numColor) {
          percent = _config.profitPrintPercent;
          break;
        } else {
          percent = _config.profitNoPrintPercent;
          break;
        }
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  if (percent == 0) {
    var config = quantityProfitConfig[quantityProfitConfig.length - 1];
    percent = numColor ? config.profitPrintPercent : config.profitNoPrintPercent;
  }

  return percent / 100;
};
/**
 * Xác định khổ giấy, và tính tiền
 */


var calcCarton = function calcCarton(_ref3) {
  var length = _ref3.length,
      width = _ref3.width,
      height = _ref3.height,
      cartonWave = _ref3.cartonWave,
      cartonStruct = _ref3.cartonStruct,
      unitGiaCong = _ref3.unitGiaCong,
      unitPriceKeo = _ref3.unitPriceKeo,
      unitPriceDay = _ref3.unitPriceDay,
      unitPriceGhim = _ref3.unitPriceGhim,
      unitPriceIn = _ref3.unitPriceIn,
      type = _ref3.type,
      beMatrix = _ref3.beMatrix,
      boxType = _ref3.boxType,
      numColor = _ref3.numColor,
      numOfPrint = _ref3.numOfPrint,
      bangInPrice = _ref3.bangInPrice,
      quantity = _ref3.quantity,
      phuPhiBePercent = _ref3.phuPhiBePercent,
      khuonBePrice = _ref3.khuonBePrice,
      quantityProfitConfig = _ref3.quantityProfitConfig,
      distance = _ref3.distance,
      catDuoi = _ref3.catDuoi,
      napThung = _ref3.napThung,
      taiThung = _ref3.taiThung,
      teBien = _ref3.teBien,
      waterProofFaceType = _ref3.waterProofFaceType,
      mold = _ref3.mold,
      _ref3$isAutoCalcSize = _ref3.isAutoCalcSize,
      isAutoCalcSize = _ref3$isAutoCalcSize === void 0 ? true : _ref3$isAutoCalcSize,
      lastChangeInput = _ref3.lastChangeInput,
      _ref3$acr1Thung = _ref3.acr1Thung,
      acr1Thung = _ref3$acr1Thung === void 0 ? 0 : _ref3$acr1Thung,
      _ref3$daiTam1Thung = _ref3.daiTam1Thung,
      daiTam1Thung = _ref3$daiTam1Thung === void 0 ? 0 : _ref3$daiTam1Thung,
      _ref3$khoTam1Thung = _ref3.khoTam1Thung,
      khoTam1Thung = _ref3$khoTam1Thung === void 0 ? 0 : _ref3$khoTam1Thung,
      _ref3$daiTamNho = _ref3.daiTamNho,
      daiTamNho = _ref3$daiTamNho === void 0 ? 0 : _ref3$daiTamNho,
      _ref3$khoTamNho = _ref3.khoTamNho,
      khoTamNho = _ref3$khoTamNho === void 0 ? 0 : _ref3$khoTamNho,
      _ref3$slThungTrenTamN = _ref3.slThungTrenTamNho,
      slThungTrenTamNho = _ref3$slThungTrenTamN === void 0 ? 0 : _ref3$slThungTrenTamN,
      cartonCosts = _ref3.cartonCosts,
      produceBy = _ref3.produceBy,
      giayTamProfitPercent = _ref3.giayTamProfitPercent,
      thanhPhamProfitPercent = _ref3.thanhPhamProfitPercent,
      isAutoProfit = _ref3.isAutoProfit,
      profitPercent = _ref3.profitPercent,
      isEnablePEPrice = _ref3.isEnablePEPrice,
      pePrice = _ref3.pePrice,
      slTheoChieuDai = _ref3.slTheoChieuDai,
      slTheoChieuKho = _ref3.slTheoChieuKho,
      cartonPrice = _ref3.cartonPrice,
      machine = _ref3.machine,
      cartonProfitConfigs = _ref3.cartonProfitConfigs,
      misaCustomerGroup = _ref3.misaCustomerGroup,
      profitType = _ref3.profitType,
      isCotBoLotGiay = _ref3.isCotBoLotGiay;
  var version = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'v1';
  var costs = [];
  var khoTamNCC = 0,
      daiTamNCC = 0,
      minKhoTamNcc = 115,
      minDaiTamNCC = 50;
  var realKhoTamNho = 0,
      realDaiTamNho = 0; //khổ tấm, dài tấm thực tế

  var factorKhoTam = 1,
      factorDaiTam = 1; //tỉ trọng khổ, dài

  var realQuantity = 0; //sl thùng thực tế khi cắt từ giấy ncc

  var unitPriceGiay = cartonPrice;
  console.log('unitPriceGiay:', unitPriceGiay);
  var totalPriceGiay = 0;
  var totalGiaCong = 0;
  var totalPriceKeo = 0,
      totalPriceDay = 0,
      totalPriceGhim = 0,
      totalPriceIn = 0;
  var daiKhuonBe = 1,
      rongKhuonBe = 1; //

  var isThung2Manh = false;

  if (isAutoCalcSize) {
    if (version == 'v3') {
      var resultDaiTamThung = calcDaiKhoTamThungV3({
        length: length,
        width: width,
        height: height,
        taiThung: taiThung,
        napThung: napThung,
        catDuoi: catDuoi,
        slTheoChieuDai: slTheoChieuDai,
        slTheoChieuKho: slTheoChieuKho,
        khoTamNho: khoTamNho,
        daiTamNho: daiTamNho
      }, boxType, mold);
      isThung2Manh = resultDaiTamThung.isThung2Manh;
      daiTam1Thung = resultDaiTamThung.daiTam1Thung;
      khoTam1Thung = resultDaiTamThung.khoTam1Thung;
    } else {
      daiTam1Thung = 0;
      khoTam1Thung = 0;

      if (mold) {} else {
        var _resultDaiTamThung = calcDaiKhoTamThung({
          length: length,
          width: width,
          height: height,
          taiThung: taiThung,
          napThung: napThung,
          catDuoi: catDuoi,
          slTheoChieuDai: slTheoChieuDai,
          slTheoChieuKho: slTheoChieuKho,
          khoTamNho: khoTamNho,
          daiTamNho: daiTamNho
        }, boxType);

        isThung2Manh = _resultDaiTamThung.isThung2Manh;
        daiTam1Thung = _resultDaiTamThung.daiTam1Thung;
        khoTam1Thung = _resultDaiTamThung.khoTam1Thung;
      }
    }
  } // khác hộp bế


  if (![_mold.MoldBoxType.HopBe, _mold.MoldBoxType.BVN_TL].includes(boxType)) {
    if (type == 'BE') {
      if (!isAutoCalcSize && ['slThung', 'tamNho'].includes(lastChangeInput)) {//nothing
      } else {
        var daiKhoTamNhoResult = calcDaiKhoTamNho({
          beMatrix: beMatrix,
          daiTam1Thung: daiTam1Thung,
          khoTam1Thung: khoTam1Thung,
          mold: mold,
          isAuto: lastChangeInput != 'numOfBe',
          machine: machine
        });
        console.log('[calc carton] daiKhoTamNhoResult', daiKhoTamNhoResult);

        if (!daiKhoTamNhoResult) {
          throw new Error("Lỗi: quá khổ bế");
        }

        daiTamNho = daiKhoTamNhoResult.daiTamNho;
        khoTamNho = daiKhoTamNhoResult.khoTamNho;

        if (daiKhoTamNhoResult.slDai && daiKhoTamNhoResult.slKho && lastChangeInput != 'numOfBe') {
          beMatrix = "".concat(daiKhoTamNhoResult.slKho, "x").concat(daiKhoTamNhoResult.slDai);
        }
      }
    } else {
      if (!isAutoCalcSize && (lastChangeInput == 'tamNho' || lastChangeInput == 'cost')) {} else {
        daiTamNho = daiTam1Thung;
        khoTamNho = khoTam1Thung;
      }
    }
  } //* tinh dài tấm 1 thùng, khổ tấm 1 thùng


  if (mold) {
    daiTam1Thung = (daiTamNho - 2) / mold.beLength;
    khoTam1Thung = (khoTamNho - 2) / mold.beWidth;
  }

  var khoTamCongThemNCC = teBien;
  console.log('teBien:', teBien);

  var _getBestFactorKhoTamV = (0, _handler.getBestFactorKhoTamV2)({
    khoTam: khoTamNho,
    khoTamCongThemNCC: khoTamCongThemNCC
  }),
      factor = _getBestFactorKhoTamV.factor,
      minKhotam = _getBestFactorKhoTamV.minKhotam;

  factorKhoTam = factor;
  khoTamNCC = minKhotam;
  var meterNCC = [300]; //chứa số mét tới cần thiết để NCC cắt, tính sl tối thiểu
  //

  if (cartonWave.code == 'E') {
    meterNCC.push(500);
  } else if (cartonWave.code == 'BA') {
    meterNCC.push(700);
  }

  if (waterProofFaceType && waterProofFaceType != 'NULL') {
    meterNCC.push(700);
  }

  var cartonStructNameIncludes = ['230', '185'];

  if (cartonStructNameIncludes.some(function (n) {
    return cartonStruct.name.includes(n);
  })) {
    meterNCC.push(700);
  }

  var meterNCCDetermine = (0, _lodash.max)(meterNCC); // Nếu dài tấm < 80, thì ghép giấy để đủ dài >= 80

  if (daiTamNho < minDaiTamNCC) {
    factorDaiTam = Math.ceil(minDaiTamNCC / daiTamNho);
    daiTamNCC = +(daiTamNho * factorDaiTam).toFixed(1);
  } else {
    daiTamNCC = daiTamNho;
  } //tính khổ, dài thực tế


  realKhoTamNho = +(khoTamNCC / factorKhoTam).toFixed(1);
  console.log('khoTamNCC:', khoTamNCC);
  console.log('factorKhoTam:', factorKhoTam);
  console.log('realKhoTamNho:', realKhoTamNho);
  realDaiTamNho = +(daiTamNCC / factorDaiTam).toFixed(1);
  console.log('daiTamNCC:', daiTamNCC);
  console.log('factorDaiTam:', factorDaiTam);
  console.log('realDaiTamNho:', realDaiTamNho); //sl thùng thực tế (Cắt xả từ ncc)
  // realQuantity = Math.floor((khoTamNCC * daiTamNCC) / (realKhoTamNho * realDaiTamNho));

  realQuantity = Math.floor(khoTamNCC / khoTamNho); //tính tổng phí gia công

  totalGiaCong = Math.ceil(realKhoTamNho * realDaiTamNho / 10000 * unitGiaCong); //

  totalPriceKeo = height * unitPriceKeo;
  totalPriceDay = width * unitPriceDay;
  totalPriceGhim = height * unitPriceGhim;
  totalPriceIn = realDaiTamNho * realKhoTamNho / 10000 * unitPriceIn;
  var realAcr = +(realDaiTamNho * realKhoTamNho / 10000).toFixed(4);
  console.log('realAcr:', realAcr); //khác hộp bế

  if (![_mold.MoldBoxType.HopBe, _mold.MoldBoxType.BVN_TL].includes(boxType)) {
    if (isAutoCalcSize) {
      console.log('slThungTrenTamNho is using mold', !!mold); //thùng mảnh luôn luôn = 0.5

      slThungTrenTamNho = mold ? mold.numOfProducts : Math.floor(daiTamNho * khoTamNho / (daiTam1Thung * khoTam1Thung));
    }
  } else {
    slThungTrenTamNho = slTheoChieuKho * slTheoChieuDai;
  } //* tính dài, khổ tấm thực tế


  var daiTamThucTe = 0,
      khoTamThucTe = 0;

  if (version == 'v1') {
    if (mold) {
      //nothing (k tính được khi đã có khuôn bế)
      khoTamThucTe = (realKhoTamNho - 2) / mold.beWidth;
      daiTamThucTe = (realDaiTamNho - 2) / mold.beLength;
    } else {
      var result = calcDaiKhoTamThucTe({
        realDaiTamNho: realDaiTamNho,
        realKhoTamNho: realKhoTamNho,
        slThungTrenTamNho: slThungTrenTamNho
      });
      daiTamThucTe = result.daiTamThucTe;
      khoTamThucTe = result.khoTamThucTe;
    }
  } else {
    var _result = calcDaiKhoTamThucTeV3({
      realDaiTamNho: realDaiTamNho,
      realKhoTamNho: realKhoTamNho,
      slThungTrenTamNho: slThungTrenTamNho,
      mold: mold
    });

    daiTamThucTe = _result.daiTamThucTe;
    khoTamThucTe = _result.khoTamThucTe;
  } //diện tích 1 thùng thưc tế


  var acr = mold ? realDaiTamNho * realKhoTamNho / slThungTrenTamNho / 10000 : +(realAcr / slThungTrenTamNho).toFixed(4);

  if (produceBy == 'vietbox') {
    totalPriceGiay = Math.ceil(realDaiTamNho * realKhoTamNho / 10000 * unitPriceGiay / slThungTrenTamNho);
    console.log('totalPriceGiay from viet box:', totalPriceGiay);
  } else {
    var unitPrice = unitPriceGiay / (1 + giayTamProfitPercent / 100);
    var chiPhiGiaCong = 600;

    if (numColor && numOfPrint) {
      chiPhiGiaCong = 900 + (numColor - 1) * 150;
    }

    totalPriceGiay = unitPrice * acr + chiPhiGiaCong;
    costs.push({
      id: 0,
      name: 'Phí gia công',
      minPrice: 0,
      price: chiPhiGiaCong,
      isEnable: true,
      unitMinPrice: 0,
      totalPrice: chiPhiGiaCong,
      type: _cartonCost.CartonCostType.Processing
    });
  }

  console.log('unitPriceGiay:', unitPriceGiay);
  console.log('slThungTrenTamNho:', slThungTrenTamNho);
  console.log('totalPriceGiay 1:', totalPriceGiay);
  var cubicMeter = calcCubicMeter1Thung({
    daiTam: daiTam1Thung,
    khoTam: khoTam1Thung,
    cartonWaveCode: cartonWave.code,
    length: length,
    width: width,
    height: height
  }, boxType); //tính giá

  console.log('acr:', acr);
  var moneyPrint = 0;
  var minMoneyPrint = 0;

  if (produceBy == 'vietbox') {
    if (numColor) {
      var printCost = cartonCosts.find(function (e) {
        return e.name.toLowerCase() == 'phí in';
      });

      if (printCost) {
        console.log('printCost:', printCost.price);
        console.log('numOfPrint:', {
          numOfPrint: numOfPrint,
          numColor: numColor
        });
        minMoneyPrint = printCost.minPrice;
        var price = printCost.price + minMoneyPrint / quantity;
        moneyPrint = Math.round(acr * price * printPriceFactors[numColor - 1]);

        if (numOfPrint == 2) {
          moneyPrint *= 3;
        } else {
          moneyPrint *= numOfPrint;
        }

        console.log('calc moneyPrint:', {
          moneyPrint: moneyPrint,
          factor: printPriceFactors[numColor - 1]
        });
      }
    }
  } //diện tích ước tính 1 thùng


  acr1Thung = mold ? (mold.cartonLength - 2) * (mold.cartonWidth - 2) / slThungTrenTamNho / 10000 : +(daiTam1Thung * khoTam1Thung / 10000).toFixed(4);
  console.log('acr1Thung:', {
    acr1Thung: acr1Thung,
    daiTam1Thung: daiTam1Thung,
    khoTam1Thung: khoTam1Thung
  }); //diện tích tấm ncc

  var acrNCC = +(daiTamNCC * khoTamNCC / 10000).toFixed(4);
  console.log('acrNCC:', acrNCC); //diện tích tấm nhỏ

  var acrTamNho = +(daiTamNho * khoTamNho / 10000).toFixed(4);
  console.log('acrTamNho:', acrTamNho); //sl thùng ít nhất

  var newDaiTamNCC = daiTamNCC; // if (khoTamNCC >= 230) {
  //     newDaiTamNCC /= 2;
  // }

  var minQuantity = Math.ceil(realQuantity * (meterNCCDetermine / newDaiTamNCC) * slThungTrenTamNho * 100); //

  console.log('moneyPrint:', moneyPrint);
  /**
   * Giá vốn
   */

  var totalMoneyBangIn = !quantity || !bangInPrice ? 0 : Math.ceil(+bangInPrice / quantity);
  console.log('totalMoneyBangIn:', totalMoneyBangIn);
  var moneyFinal = Math.ceil(totalMoneyBangIn + moneyPrint + totalPriceGiay);
  console.log('moneyFinal 1:', {
    moneyFinal: moneyFinal,
    moneyPrint: moneyPrint,
    totalPriceGiay: totalPriceGiay,
    totalMoneyBangIn: totalMoneyBangIn
  }); //tiền mặt chống thấm

  var moneyWaterProofFace = 0;

  if (waterProofFaceType) {
    var faceType = _Product.waterProofFaces.find(function (e) {
      return e.value == waterProofFaceType;
    });

    moneyWaterProofFace = faceType.face * acr * 300;
    moneyFinal += moneyWaterProofFace;
  }

  console.log('moneyFinal 2:', moneyFinal); //nếu k đủ 300m ncc thì tăng giá

  var moneyKhuonBe = 0; //tiền khuôn bế

  var moneyPhuPhiBe = 0; //

  var moneyCartonE = 0;

  if (produceBy == 'vietbox') {
    console.log('moneyFinal 3:', moneyFinal);

    if (cartonWave.code == "E") {
      moneyCartonE = moneyFinal * 0.02;
      moneyFinal += moneyCartonE;
    }

    console.log('moneyFinal 4:', moneyFinal); //tiền phụ phí bế

    moneyPhuPhiBe = type == "BE" ? moneyFinal * (phuPhiBePercent / 100) : 0; //tiền khuôn bế

    if (quantity) {
      moneyKhuonBe = Math.ceil(+khuonBePrice / quantity);
    }

    moneyFinal += moneyKhuonBe;
    console.log('moneyFinal 5:', moneyFinal, moneyKhuonBe, khuonBePrice, quantity); //các chi phí khác

    var bigPlateToProductQuantity = realQuantity * slThungTrenTamNho; //quy đổi 1 thùng lớn ra ? thùng nhỏ

    console.log('cartonCosts:', cartonCosts);

    var _iterator3 = (0, _createForOfIteratorHelper2.default)(cartonCosts),
        _step3;

    try {
      var _loop = function _loop() {
        var cost = _step3.value;
        //* check chi phí bế
        var beTuDong = 'Phí bế tự động';
        var beTay = 'Phí bế tay';
        var isPhiBe = (cost.name == beTuDong || cost.name == beTay) && !!mold;

        if (isPhiBe) {
          if (mold.type == _mold.MoldType.Circle && cost.name == beTay || mold.type == _mold.MoldType.Flat && cost.name == beTuDong) {
            return "continue";
          }
        } //* end - check chi phí bế


        if (cost.isUseParent) {
          return "continue";
        }

        if (cost.name.toLowerCase() == 'phí in') {
          return "continue";
        }

        if (cost.name == 'Phí 2 mảnh' && !isThung2Manh) {
          return "continue";
        } //sử dụng liên kết


        if (cost.name == beTay || cost.name == beTuDong) {
          if (mold || cost.isPriority) {
            if (cost.linked) {
              var findLinked = cartonCosts.find(function (e) {
                return e.id == cost.linked.id;
              });

              if (findLinked) {
                findLinked.isUse = false;
              }
            } //nothing

          } else {
            return "continue";
          }
        } else {
          if (!cost.isPriority && cost.linked) {
            return "continue";
          }
        }

        if (type == 'BE' && cost.isNoBe) {
          return "continue";
        }

        if (cost.isPrint && (!numColor || !numOfPrint)) {
          return "continue";
        }

        if (cost.isEnableAcr) {
          var string = "".concat(acr1Thung, " ").concat(cost.acrOperator, " ").concat(cost.acrValue);

          if (cost.acrOperator2) {
            string += " && ".concat(acr1Thung, " ").concat(cost.acrOperator2, " ").concat(cost.acrValue2);
          }

          if (!eval(string)) {
            return "continue";
          }
        }

        if (cost.isEnableQuantity) {
          var _string = "".concat(quantity, " ").concat(cost.quantityOperator, " ").concat(cost.quantityValue);

          if (cost.quantityOperator2) {
            _string += " && ".concat(quantity, " ").concat(cost.quantityOperator2, " ").concat(cost.quantityValue2);
          }

          if (!eval(_string)) {
            return "continue";
          }
        }

        if (cost.isEnableKho1Thung) {
          var _string2 = "".concat(khoTam1Thung, " ").concat(cost.kho1ThungOperator, " ").concat(cost.kho1ThungValue);

          if (cost.kho1ThungOperator2) {
            _string2 += " && ".concat(khoTam1Thung, " ").concat(cost.kho1ThungOperator2, " ").concat(cost.kho1ThungValue2);
          }

          if (!eval(_string2)) {
            return "continue";
          }
        }

        if (type == 'CHAP' && cost.isBe) {
          return "continue";
        }

        if (cost.linked) {
          var _findLinked = cartonCosts.find(function (e) {
            return e.id == cost.linked.id;
          });

          if (_findLinked.isUse) {
            return "continue";
          }
        }

        var price = cost.price;
        var minPrice = cost.minPrice;

        if (cost.isPrint) {
          minPrice *= numColor;
        }

        var calcValue = cost.calcValue,
            calcType = cost.calcType;
        var totalPrice = 0,
            unitQuantity = 0;

        switch (calcValue) {
          case _cartonCost.CartonCostCalcValueType.Quantity:
            if (calcType == _cartonCost.CartonCostCalcType.Product) {
              unitQuantity = 1;
              totalPrice = price;
            } else if (calcType == _cartonCost.CartonCostCalcType.BigPlate) {
              unitQuantity = bigPlateToProductQuantity;
              totalPrice = price / unitQuantity;
            } else if (calcType == _cartonCost.CartonCostCalcType.SmallPlate) {
              unitQuantity = slThungTrenTamNho;
              totalPrice = price / slThungTrenTamNho;
            }

            break;

          case _cartonCost.CartonCostCalcValueType.Width:
            if (calcType == _cartonCost.CartonCostCalcType.Product) {
              unitQuantity = khoTam1Thung / 100;
              totalPrice = price * unitQuantity;
            } else if (calcType == _cartonCost.CartonCostCalcType.BigPlate) {
              unitQuantity = +(khoTamNCC / khoTamNho / khoTam1Thung).toFixed(4);
              totalPrice = price / unitQuantity;
            } else if (calcType == _cartonCost.CartonCostCalcType.SmallPlate) {
              unitQuantity = +(khoTamNho / khoTam1Thung).toFixed(4);
              totalPrice = price / unitQuantity;
            }

            break;

          case _cartonCost.CartonCostCalcValueType.Length:
            if (calcType == _cartonCost.CartonCostCalcType.Product) {
              unitQuantity = daiTam1Thung / 100;
              totalPrice = price * unitQuantity;
            } else if (calcType == _cartonCost.CartonCostCalcType.BigPlate) {
              unitQuantity = +(daiTamNCC / daiTamNho / daiTam1Thung).toFixed(4);
              totalPrice = price / unitQuantity;
            } else if (calcType == _cartonCost.CartonCostCalcType.SmallPlate) {
              totalPrice = price / (daiTamNho / daiTam1Thung);
              unitQuantity = daiTam1Thung;
            }

            break;

          case _cartonCost.CartonCostCalcValueType.Meter:
            if (calcType == _cartonCost.CartonCostCalcType.Product) {
              unitQuantity = acr1Thung;
              totalPrice = price * acr1Thung;
            } else if (calcType == _cartonCost.CartonCostCalcType.BigPlate) {
              totalPrice = price * acrNCC;
              unitQuantity = acrNCC;
            } else if (calcType == _cartonCost.CartonCostCalcType.SmallPlate) {
              totalPrice = price * acrTamNho;
              unitQuantity = acrTamNho;
            }

            break;

          default:
            break;
        } // console.log('calc cost', cost.name, 'unitQuantity', unitQuantity, 'totalPrice', totalPrice);


        var unitMinPrice = 0;

        if (unitQuantity && quantity) {
          unitMinPrice = minPrice / quantity;
        }

        totalPrice += +unitMinPrice.toFixed(1);
        console.log("cost name".concat(cost.name, ", cost fee ").concat(totalPrice, ", after calc:"), moneyFinal);

        if (cost.isEnable) {
          moneyFinal += totalPrice;
        }

        console.log("cost name ".concat(cost.name, ", cost fee ").concat(totalPrice, ", after calc:"), moneyFinal);
        cost.isUse = true;
        costs.push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, cost), {}, {
          totalPrice: totalPrice,
          unitMinPrice: unitMinPrice,
          price: price,
          minPrice: minPrice,
          quantity: unitQuantity
        }));
      };

      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var _ret = _loop();

        if (_ret === "continue") continue;
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }

    if (numColor && numOfPrint) {
      costs.unshift({
        // name: `Chi phí in ${numColor} màu`,
        id: 0,
        name: "Ph\xED in",
        minPrice: minMoneyPrint,
        isEnable: true,
        price: moneyPrint,
        unitMinPrice: minMoneyPrint / quantity,
        totalPrice: moneyPrint,
        type: _cartonCost.CartonCostType.Material
      });
    }

    if (totalMoneyBangIn) {
      costs.unshift({
        id: 0,
        name: "Chi ph\xED b\u1EA3n in",
        minPrice: 0,
        price: totalMoneyBangIn,
        unitMinPrice: 0,
        totalPrice: totalMoneyBangIn,
        isEnable: true,
        type: _cartonCost.CartonCostType.Material
      });
    }

    if (moneyWaterProofFace) {
      costs.unshift({
        id: 0,
        name: "Ti\u1EC1n ch\u1ED1ng th\u1EA5m",
        minPrice: 0,
        price: moneyWaterProofFace,
        unitMinPrice: 0,
        totalPrice: moneyWaterProofFace,
        isEnable: true,
        type: _cartonCost.CartonCostType.Material
      });
    }

    if (moneyCartonE) {
      costs.unshift({
        id: 0,
        name: "Ti\u1EC1n s\xF3ng E",
        minPrice: 0,
        price: moneyCartonE,
        unitMinPrice: 0,
        totalPrice: moneyCartonE,
        isEnable: true,
        type: _cartonCost.CartonCostType.Material
      });
    }

    if (moneyKhuonBe) {
      costs.unshift({
        id: 0,
        name: "Ti\u1EC1n khu\xF4n b\u1EBF",
        minPrice: 0,
        price: moneyKhuonBe,
        unitMinPrice: 0,
        totalPrice: moneyKhuonBe,
        isEnable: true,
        type: _cartonCost.CartonCostType.Material
      });
    } //tính phí hao hụt


    console.log('moneyFianl before haohut:', moneyFinal); // Nếu sl thùng <1000, là 20
    // <3000, 30
    // <5000, 50
    // <10.000, 100

    var haoHutRate = 10;
    var haoHutPhatSinh = 0;

    if (quantity > 1000) {
      haoHutRate = (quantity - 1000) / 1000 * 10;
    }

    haoHutRate += haoHutPhatSinh;
    var phiHaoHut1Tam = Math.ceil(acrTamNho * unitPriceGiay);
    console.log('phiHaoHut1Tam:', phiHaoHut1Tam);
    var phiHaoHut = Math.ceil(phiHaoHut1Tam * haoHutRate / quantity);
    console.log('phiHaoHut:', phiHaoHut);
    moneyFinal += phiHaoHut;
    console.log('moneyFinal 6:', moneyFinal);
    costs.unshift({
      id: 0,
      name: 'Hao hụt',
      minPrice: 0,
      price: phiHaoHut,
      isEnable: true,
      unitMinPrice: 0,
      totalPrice: phiHaoHut,
      type: _cartonCost.CartonCostType.Other
    }); //tính phí hao hụt (khi in)

    if (numColor && numOfPrint) {
      var phiHaoHutKhiIn = Math.ceil(phiHaoHut1Tam * ((numColor - 1) * 5 + 10) / quantity);
      moneyFinal += phiHaoHutKhiIn;
      console.log('moneyFinal 7:', moneyFinal);
      costs.unshift({
        id: 0,
        name: 'Hao hụt khi in',
        minPrice: 0,
        price: phiHaoHutKhiIn,
        isEnable: true,
        unitMinPrice: 0,
        totalPrice: phiHaoHutKhiIn,
        type: _cartonCost.CartonCostType.Other
      });
    } //


    if (isEnablePEPrice && pePrice) {
      var totalPEPice = pePrice * acr1Thung;
      costs.unshift({
        id: 0,
        name: 'Quấn PE',
        quantity: acr1Thung,
        minPrice: 0,
        price: pePrice,
        isEnable: true,
        unitMinPrice: 0,
        totalPrice: totalPEPice,
        type: _cartonCost.CartonCostType.Other
      });
      moneyFinal += totalPEPice;
    }
  } //tính cột bó lót giấy (300đ/m2)


  if (isCotBoLotGiay && typeof acr == 'number') {
    var _price = 300;
    var cotBoLotGiayPrice = acr * _price;
    moneyFinal += cotBoLotGiayPrice;
    costs.unshift({
      id: 0,
      name: 'Giấy nguyên liệu',
      quantity: +acr.toFixed(4),
      minPrice: 0,
      price: _price,
      isEnable: true,
      unitMinPrice: 0,
      totalPrice: cotBoLotGiayPrice,
      type: _cartonCost.CartonCostType.Material
    });
  } //tính giá giấy nvl


  costs.unshift({
    id: 0,
    name: 'Giấy nguyên liệu',
    quantity: +(totalPriceGiay / unitPriceGiay).toFixed(4),
    minPrice: 0,
    price: unitPriceGiay,
    isEnable: true,
    unitMinPrice: 0,
    totalPrice: totalPriceGiay,
    type: _cartonCost.CartonCostType.Material
  });
  console.log('calc cost', {
    total: costs.reduce(function (prev, cur) {
      return prev + cur.totalPrice;
    }, 0),
    costs: costs
  });
  console.log('moneyFinal before:', moneyFinal); // moneyFinal = Math.ceil(costs.reduce((prev, cur) => prev + cur.totalPrice, 0))

  moneyFinal = Math.ceil(moneyFinal);
  console.log('moneyFinal after:', moneyFinal);
  var profitRate = 0;
  console.log('isAutoProfit:', isAutoProfit);

  if (isAutoProfit) {
    if (lastChangeInput == 'profitPercent') {
      profitRate = profitPercent / 100;
    } else {
      if (profitType == 'NORMAL') {
        profitRate = getProfitPercentByQuantity({
          quantity: quantity,
          moneyFinal: moneyFinal,
          numColor: numColor,
          quantityProfitConfig: quantityProfitConfig
        });
      } else {
        profitRate = getProfitPercentByAmount({
          amount: !isNaN(moneyFinal) ? moneyFinal * quantity : 0,
          cartonProfitConfigs: cartonProfitConfigs
        }); //check tiếp nhóm khách misa

        if (!misaCustomerGroup) {
          throw new Error("Chưa chọn nhóm khách hàng");
        }

        profitRate += misaCustomerGroup.percent / 100;
      }
    }
  } else {
    if (profitType == 'CUSTOMER_GROUP') {
      profitRate = getProfitPercentByAmount({
        amount: !isNaN(moneyFinal) ? moneyFinal * quantity : 0,
        cartonProfitConfigs: cartonProfitConfigs
      }); //check tiếp nhóm khách misa

      if (!misaCustomerGroup) {
        throw new Error("Chưa chọn nhóm khách hàng");
      }

      profitRate += misaCustomerGroup.percent / 100;
    } else {
      profitRate = profitPercent / 100;
    }
  }

  console.log('profitPercent:', profitPercent);
  var isOverSize = false,
      errorMessage = '';

  if (['v1', 'v3'].includes(version)) {
    isOverSize = (0, _handler.checkOverSize)({
      length: length,
      width: width,
      height: height,
      type: type,
      daiTamNho: daiTamNho,
      khoTamNho: khoTamNho
    });
  } else {
    var resultCheckOverSize = (0, _handler.checkOverSizeV2)({
      type: type,
      daiTamNho: daiTamNho,
      khoTamNho: khoTamNho,
      isPrint: !!numColor && !!numOfPrint
    });
    isOverSize = resultCheckOverSize.isOver;
    errorMessage = resultCheckOverSize.message;
  }

  if (isOverSize && isAutoProfit) {
    profitRate += 0.02;
  }

  console.log("profitRate:", profitRate);
  moneyFinal = Math.round(moneyFinal);

  if (produceBy == 'ncc') {
    moneyFinal *= 1 + thanhPhamProfitPercent / 100;
  }

  var suggestPrice = Math.ceil((moneyFinal + moneyPhuPhiBe) / (1 - profitRate));
  var sellPrice = suggestPrice;

  if (quantity < 1000) {
    sellPrice *= 1.03;
  }

  sellPrice = Math.ceil(sellPrice); //phí ship    

  var shipFee = acr1Thung * cartonWave.shipFee;

  switch (distance) {
    case 40:
      shipFee *= 1.3;
      break;

    case 50:
      shipFee *= 1.6;
      break;

    case 60:
      shipFee *= 2;
      break;
  } // if (shipFee * quantity < minShipFee) {
  //     shipFee = minShipFee / quantity;
  // }


  shipFee = Math.ceil(shipFee); //

  var deliveryPrice = Math.ceil(sellPrice + shipFee);
  var totalMoneySell = quantity * sellPrice;
  var totalMoneyDelivery = quantity * deliveryPrice;
  var totalCost = Math.round(moneyFinal * quantity);
  var totalProfit = Math.round(totalMoneySell - totalCost);
  var totalShipFee = shipFee * quantity; //% lợi nhuận thực

  var realProfitPercent = +((1 - +(moneyFinal / sellPrice).toFixed(2)) * 100).toFixed(1); //

  var shipFeeCarton = cartonWave.shipFee;

  switch (distance) {
    case 40:
      shipFeeCarton *= 1.3;
      break;

    case 50:
      shipFeeCarton *= 1.6;
      break;

    case 60:
      shipFeeCarton *= 2;
      break;
  }

  var totalAcr = +(acr * quantity).toFixed(4);
  var totalShipFeeCarton = Math.ceil(shipFeeCarton * totalAcr);
  var weight = +(cartonStruct.weight / 1000 * acr).toFixed(3); //trọng lượng 1 thùng

  console.log('weight:', weight);
  var totalWeight = +(weight * quantity).toFixed(3);
  console.log('totalWeight:', totalWeight);

  if (lastChangeInput == 'isAutoProfit') {
    profitPercent = realProfitPercent;
  }

  return {
    weight: weight,
    totalWeight: totalWeight,
    isOverSize: isOverSize,
    acr1Thung: acr1Thung,
    daiTamThucTe: daiTamThucTe,
    khoTamThucTe: khoTamThucTe,
    totalAcr: totalAcr,
    totalShipFeeCarton: totalShipFeeCarton,
    shipFeeCarton: shipFeeCarton,
    acr: acr,
    shipFee: shipFee,
    realProfitPercent: realProfitPercent,
    profitPercent: profitPercent,
    totalShipFee: totalShipFee,
    totalProfit: totalProfit,
    totalCost: totalCost,
    totalMoneySell: totalMoneySell,
    totalMoneyDelivery: totalMoneyDelivery,
    slThungTrenTamNho: slThungTrenTamNho,
    realAcr: realAcr,
    totalGiaCong: totalGiaCong,
    taiThung: taiThung,
    daiTam1Thung: daiTam1Thung,
    khoTam1Thung: khoTam1Thung,
    daiTamNho: daiTamNho,
    khoTamNho: khoTamNho,
    realKhoTamNho: realKhoTamNho,
    realDaiTamNho: realDaiTamNho,
    realQuantity: realQuantity,
    totalPriceKeo: totalPriceKeo,
    totalPriceDay: totalPriceDay,
    totalPriceGhim: totalPriceGhim,
    totalPriceIn: totalPriceIn,
    totalPriceGiay: totalPriceGiay,
    daiTamNCC: daiTamNCC,
    khoTamNCC: khoTamNCC,
    daiKhuonBe: daiKhuonBe,
    rongKhuonBe: rongKhuonBe,
    minQuantity: minQuantity,
    cubicMeter: cubicMeter,
    deliveryPrice: deliveryPrice,
    moneyFinal: moneyFinal,
    sellPrice: sellPrice,
    costs: costs,
    moneyPhuPhiBe: moneyPhuPhiBe,
    errorMessage: errorMessage,
    beMatrix: beMatrix,
    suggestPrice: suggestPrice
  };
};
/**
 * Tính dài tấm nhỏ, khổ tấm nhỏ
 */


exports.calcCarton = calcCarton;

var calcDaiKhoTamNho = function calcDaiKhoTamNho(_ref4) {
  var khoTam1Thung = _ref4.khoTam1Thung,
      daiTam1Thung = _ref4.daiTam1Thung,
      beMatrix = _ref4.beMatrix,
      mold = _ref4.mold,
      _ref4$isAuto = _ref4.isAuto,
      isAuto = _ref4$isAuto === void 0 ? true : _ref4$isAuto,
      machine = _ref4.machine;
  var slKho = 1,
      slDai = 1; //số lượng bế theo chiều khổ và chiều dài

  var rongKhuonBe = 0,
      daiKhuonBe = 0;
  var daiTamNho = 0,
      khoTamNho = 0; //

  if (!mold) {
    //tự động tìm khuôn bế
    if (isAuto) {
      var results = [];

      for (var i = 1; i <= 5; i++) {
        var isBreak = false;

        for (var j = 1; j <= 5; j++) {
          daiTamNho = +(daiTam1Thung * j + 2).toFixed(1);
          khoTamNho = +(khoTam1Thung * i + 2).toFixed(1);

          if (machine) {//TODO tạm thời tắt đi
            // switch (machine.type) {
            //     case CartonMachineType.In:
            //         if (khoTamNho >= machine.minKhoTamIn && khoTamNho <= machine.maxKhoTamIn
            //             && daiTamNho >= machine.minDaiTamIn && daiTamNho <= machine.maxDaiTamIn) {
            //             results.push({
            //                 daiTamNho, khoTamNho, slKho: i, slDai: j
            //             })
            //         }
            //         break;
            //     case CartonMachineType.Chap:
            //         if (khoTamNho >= machine.minKhoTamChap && khoTamNho <= machine.maxKhoTamChap
            //             && daiTamNho >= machine.minDaiTamChap && daiTamNho <= machine.maxDaiTamChap) {
            //             results.push({
            //                 daiTamNho, khoTamNho, slKho: i, slDai: j
            //             })
            //         }
            //         break;
            //     case CartonMachineType.Be:
            //         if (khoTamNho >= machine.minKhoTamBe && khoTamNho <= machine.maxKhoTamBe
            //             && daiTamNho >= machine.minDaiTamBe && daiTamNho <= machine.maxDaiTamBe) {
            //             results.push({
            //                 daiTamNho, khoTamNho, slKho: i, slDai: j
            //             })
            //         }
            //         break;
            //     default:
            //         break;
            // }
          } else {
            console.log('calcDaiKhoTamNho', {
              daiTamNho: daiTamNho,
              khoTamNho: khoTamNho
            });

            if (daiTamNho >= 50 && khoTamNho >= 25 //  && ((khoTamNho <= 140 && daiTamNho <= 100)
            // || (khoTamNho <= 100 && daiTamNho <= 140))
            ) {
              results.push({
                daiTamNho: daiTamNho,
                khoTamNho: khoTamNho,
                slKho: i,
                slDai: j,
                deltaDaiTam: Math.abs(70 - daiTamNho),
                deltaKhoTam: Math.abs(60 - khoTamNho)
              }); // isBreak = true;

              break;
            }
          }
        } // if (isBreak) {
        //     break;
        // }

      }

      console.log('[calcDaiKhoTamNho] result', results);

      if (!results.length) {
        return null;
      }

      results.sort(function (a, b) {
        if (a.deltaDaiTam == b.deltaDaiTam) {
          return a.deltaKhoTam - b.deltaKhoTam;
        }

        return a.deltaDaiTam - b.deltaDaiTam;
      });
      var result = results[0];
      khoTamNho = result.khoTamNho;
      daiTamNho = result.daiTamNho;
      slKho = result.slKho;
      slDai = result.slDai;
    } //tính trên khuôn bế đã chọn 
    else {
      rongKhuonBe = beMatrix ? +beMatrix.split('x')[0] : 1;
      daiKhuonBe = beMatrix ? +beMatrix.split('x')[1] : 1;
      daiTamNho = +(daiTam1Thung * daiKhuonBe + 2).toFixed(1);
      khoTamNho = +(khoTam1Thung * rongKhuonBe + 2).toFixed(1);
    }
  } else {
    daiTamNho = mold.cartonLength;
    khoTamNho = mold.cartonWidth;
  }

  return {
    daiTamNho: daiTamNho,
    khoTamNho: khoTamNho,
    slDai: slDai,
    slKho: slKho
  };
};

exports.calcDaiKhoTamNho = calcDaiKhoTamNho;

var calcDaiKhoTamThucTe = function calcDaiKhoTamThucTe(_ref5) {
  var realDaiTamNho = _ref5.realDaiTamNho,
      realKhoTamNho = _ref5.realKhoTamNho,
      slThungTrenTamNho = _ref5.slThungTrenTamNho;
  var daiTamThucTe = +(realDaiTamNho / slThungTrenTamNho).toFixed(4);
  var khoTamThucTe = +(realKhoTamNho / slThungTrenTamNho).toFixed(4);
  return {
    daiTamThucTe: daiTamThucTe,
    khoTamThucTe: khoTamThucTe
  };
};

exports.calcDaiKhoTamThucTe = calcDaiKhoTamThucTe;

var calcDaiKhoTamThucTeV3 = function calcDaiKhoTamThucTeV3(_ref6) {
  var realDaiTamNho = _ref6.realDaiTamNho,
      realKhoTamNho = _ref6.realKhoTamNho,
      slThungTrenTamNho = _ref6.slThungTrenTamNho,
      mold = _ref6.mold;
  var daiTamThucTe = +(realDaiTamNho / (mold ? mold.beLength : slThungTrenTamNho)).toFixed(4);
  var khoTamThucTe = +(realKhoTamNho / (mold ? mold.beWidth : slThungTrenTamNho)).toFixed(4);
  return {
    daiTamThucTe: daiTamThucTe,
    khoTamThucTe: khoTamThucTe
  };
};

exports.calcDaiKhoTamThucTeV3 = calcDaiKhoTamThucTeV3;

var calcDaiKhoTamThungV3 = function calcDaiKhoTamThungV3(_ref7, boxType, mold) {
  var length = _ref7.length,
      width = _ref7.width,
      height = _ref7.height,
      _ref7$taiThung = _ref7.taiThung,
      taiThung = _ref7$taiThung === void 0 ? 0 : _ref7$taiThung,
      _ref7$catDuoi = _ref7.catDuoi,
      catDuoi = _ref7$catDuoi === void 0 ? 0 : _ref7$catDuoi,
      _ref7$napThung = _ref7.napThung,
      napThung = _ref7$napThung === void 0 ? 0 : _ref7$napThung,
      _ref7$khoTamNho = _ref7.khoTamNho,
      khoTamNho = _ref7$khoTamNho === void 0 ? 0 : _ref7$khoTamNho,
      _ref7$daiTamNho = _ref7.daiTamNho,
      daiTamNho = _ref7$daiTamNho === void 0 ? 0 : _ref7$daiTamNho,
      _ref7$slTheoChieuKho = _ref7.slTheoChieuKho,
      slTheoChieuKho = _ref7$slTheoChieuKho === void 0 ? 1 : _ref7$slTheoChieuKho,
      _ref7$slTheoChieuDai = _ref7.slTheoChieuDai,
      slTheoChieuDai = _ref7$slTheoChieuDai === void 0 ? 1 : _ref7$slTheoChieuDai;

  if (mold) {
    return {
      daiTam1Thung: mold.cartonLength,
      khoTam1Thung: mold.cartonWidth,
      isThung2Manh: false
    };
  }

  var daiTam1Thung = 0,
      khoTam1Thung = 0;
  var isThung2Manh = false;

  switch (boxType) {
    //thùng A1
    case _mold.MoldBoxType.A1:
    case _mold.MoldBoxType.NS1:
      if (length + width > 130) {
        isThung2Manh = true;
      }

      daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
      khoTam1Thung = width + height;
      break;

    case _mold.MoldBoxType.A11Nap:
      daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
      khoTam1Thung = width / 2 + height + napThung;
      break;

    case _mold.MoldBoxType.A10Nap:
      daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
      khoTam1Thung = height;
      break;

    case _mold.MoldBoxType.A1NapChom1:
      daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
      khoTam1Thung = 1.5 * width + height + napThung;
      break;

    case _mold.MoldBoxType.A1NapChom2:
      daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
      khoTam1Thung = 2 * width + height + napThung;
      break;
    //Hộp đựng giày

    case _mold.MoldBoxType.DungGiay:
      khoTam1Thung = 2 * width + 2 * height + 8.5;
      daiTam1Thung = length + 2 * height;
      break;
    //Hộp nắp gài

    case _mold.MoldBoxType.NapGai:
      khoTam1Thung = length + 4 * height + 1.2;
      daiTam1Thung = 2 * width + 3 * height + 0.25;
      break;
    //Hộp nắp gài trong

    case _mold.MoldBoxType.NapGaiTrong:
      khoTam1Thung = width + 2 * height;
      daiTam1Thung = 2 * width + 3.5 * height;
      break;
    //Thùng âm dương

    case _mold.MoldBoxType.AmDuong:
      daiTam1Thung = length + 1 + (height + 1) * 2;
      khoTam1Thung = width + 1 + (height + 1) * 2;
      break;
    //Nắp gài móc đáy

    case _mold.MoldBoxType.NapGaiMocDay:
      daiTam1Thung = (length + width) * 2 + taiThung; // 1.5*Rộng + Cao + Tai thùng + 3(đơn vị 3 này là mm nha e, 3 là thông số phần cài nắp, e comment trong code nha)

      khoTam1Thung = 1.5 * width + height + taiThung + 0.3;
      break;
    // Quai xách đục lỗ

    case _mold.MoldBoxType.QuaiXach:
      daiTam1Thung = (length + width) * 2 + taiThung;
      khoTam1Thung = length + 2 * width;
      break;
    //hộp bế: k cần tính

    case _mold.MoldBoxType.HopBe:
      khoTam1Thung = +(khoTamNho / slTheoChieuKho).toFixed(1);
      daiTam1Thung = +(daiTamNho / slTheoChieuDai).toFixed(1);
      break;

    default:
      break;
  } //nắp thùng


  if (![_mold.MoldBoxType.HopBe, _mold.MoldBoxType.BVN_TL].includes(boxType) && boxType != _mold.MoldBoxType.NS1) {
    khoTam1Thung += napThung;
  } //


  return {
    daiTam1Thung: +daiTam1Thung.toFixed(1),
    khoTam1Thung: +khoTam1Thung.toFixed(1),
    isThung2Manh: isThung2Manh
  };
};

exports.calcDaiKhoTamThungV3 = calcDaiKhoTamThungV3;

var calcDaiKhoTamThung = function calcDaiKhoTamThung(_ref8, boxType) {
  var length = _ref8.length,
      width = _ref8.width,
      height = _ref8.height,
      _ref8$taiThung = _ref8.taiThung,
      taiThung = _ref8$taiThung === void 0 ? 0 : _ref8$taiThung,
      _ref8$catDuoi = _ref8.catDuoi,
      catDuoi = _ref8$catDuoi === void 0 ? 0 : _ref8$catDuoi,
      _ref8$napThung = _ref8.napThung,
      napThung = _ref8$napThung === void 0 ? 0 : _ref8$napThung,
      _ref8$khoTamNho = _ref8.khoTamNho,
      khoTamNho = _ref8$khoTamNho === void 0 ? 0 : _ref8$khoTamNho,
      _ref8$daiTamNho = _ref8.daiTamNho,
      daiTamNho = _ref8$daiTamNho === void 0 ? 0 : _ref8$daiTamNho,
      _ref8$slTheoChieuKho = _ref8.slTheoChieuKho,
      slTheoChieuKho = _ref8$slTheoChieuKho === void 0 ? 1 : _ref8$slTheoChieuKho,
      _ref8$slTheoChieuDai = _ref8.slTheoChieuDai,
      slTheoChieuDai = _ref8$slTheoChieuDai === void 0 ? 1 : _ref8$slTheoChieuDai;
  var daiTam1Thung = 0,
      khoTam1Thung = 0;
  var isThung2Manh = false;

  switch (boxType) {
    //thùng A1
    case _mold.MoldBoxType.A1:
    case _mold.MoldBoxType.NS1:
      if (length + width > 130) {
        isThung2Manh = true;
      }

      if (boxType == _mold.MoldBoxType.A1) {
        daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
        khoTam1Thung = width + height;
      } else {
        khoTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
        daiTam1Thung = width + height;
      }

      break;

    case _mold.MoldBoxType.A1NapChom1:
      daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
      khoTam1Thung = 1.5 * width + height + napThung;

      if (length + width > 130) {
        isThung2Manh = true;
      }

      break;

    case _mold.MoldBoxType.A1NapChom2:
      daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
      khoTam1Thung = 2 * width + height + napThung;

      if (length + width > 130) {
        isThung2Manh = true;
      }

      break;

    case _mold.MoldBoxType.A11Nap:
      daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
      khoTam1Thung = width / 2 + height + napThung;

      if (length + width > 130) {
        isThung2Manh = true;
      }

      break;

    case _mold.MoldBoxType.A10Nap:
      daiTam1Thung = (length + width) * 2 + taiThung * (isThung2Manh ? 2 : 1) + catDuoi;
      khoTam1Thung = height;

      if (length + width > 130) {
        isThung2Manh = true;
      }

      break;
    //Hộp đựng giày

    case _mold.MoldBoxType.DungGiay:
      khoTam1Thung = 2 * width + 2 * height + 8.5;
      daiTam1Thung = length + 2 * height;
      break;
    //Hộp nắp gài

    case _mold.MoldBoxType.NapGai:
      khoTam1Thung = length + 4 * height + 1.2;
      daiTam1Thung = 2 * width + 3 * height + 0.25;
      break;
    //Hộp nắp gài trong

    case _mold.MoldBoxType.NapGaiTrong:
      khoTam1Thung = 2 * width + 4 * height - 0.5;
      daiTam1Thung = length + 2 * height;
      break;
    //Thùng âm dương

    case _mold.MoldBoxType.AmDuong:
      daiTam1Thung = length + 1 + (height + 1) * 2;
      khoTam1Thung = width + 1 + (height + 1) * 2;
      break;
    //Nắp gài móc đáy

    case _mold.MoldBoxType.NapGaiMocDay:
      daiTam1Thung = (length + width) * 2 + 2.5;
      khoTam1Thung = height + 1.5 * width + 0.2;
      break;
    // Quai xách đục lỗ

    case _mold.MoldBoxType.QuaiXach:
      daiTam1Thung = (length + width) * 2 + taiThung;
      khoTam1Thung = length + 2 * width;
      break;
    //hộp bế: k cần tính

    case _mold.MoldBoxType.HopBe:
      khoTam1Thung = +(khoTamNho / slTheoChieuKho).toFixed(1);
      daiTam1Thung = +(daiTamNho / slTheoChieuDai).toFixed(1);
      break;

    case _mold.MoldBoxType.BVN_TL:
      khoTam1Thung = +(khoTamNho / slTheoChieuKho).toFixed(1);
      daiTam1Thung = +(daiTamNho / slTheoChieuDai).toFixed(1);
      break;

    default:
      break;
  } //nắp thùng


  if (![_mold.MoldBoxType.HopBe, _mold.MoldBoxType.BVN_TL].includes(boxType) && boxType != _mold.MoldBoxType.NS1) {
    khoTam1Thung += napThung;
  } //


  return {
    daiTam1Thung: +daiTam1Thung.toFixed(1),
    khoTam1Thung: +khoTam1Thung.toFixed(1),
    isThung2Manh: isThung2Manh
  };
};
/**
 * Tính m3 của tấm giấy
 */


exports.calcDaiKhoTamThung = calcDaiKhoTamThung;

var calcCubicTamGiay = function calcCubicTamGiay(_ref9) {
  var daiTam = _ref9.daiTam,
      khoTam = _ref9.khoTam,
      waveCode = _ref9.waveCode;
  return daiTam * khoTam * waveCode;
};
/**
 * - Tính thể tích: Sóng B 0.3, C 0.4,  E 0.2, BC 0.65, BCE 0.8
 */


exports.calcCubicTamGiay = calcCubicTamGiay;

var calcCubicMeter1Thung = function calcCubicMeter1Thung(_ref10, loaiThung) {
  var daiTam = _ref10.daiTam,
      khoTam = _ref10.khoTam,
      cartonWaveCode = _ref10.cartonWaveCode,
      length = _ref10.length,
      width = _ref10.width,
      height = _ref10.height;
  var cubicMeter = 0;
  var factor = getCartonWaveFactor(loaiThung, cartonWaveCode);

  if (loaiThung == _mold.MoldBoxType.A1) {
    cubicMeter = (length + width) * (width + height) * factor;
  } else {
    cubicMeter = khoTam * daiTam * factor;
  }

  cubicMeter = +(cubicMeter / 1000000).toFixed(10);
  return cubicMeter;
};

exports.calcCubicMeter1Thung = calcCubicMeter1Thung;

var getCartonWaveFactor = function getCartonWaveFactor(loaiThung, cartonWaveCode) {
  var factor = 0;

  if (loaiThung == _mold.MoldBoxType.A1) {
    if (cartonWaveCode == 'A') {
      factor = 1;
    } else if (cartonWaveCode == 'B') {
      factor = 0.65;
    } else if (cartonWaveCode == 'C') {
      factor = 0.85;
    } else if (cartonWaveCode == 'E') {
      factor = 0.5;
    } else if (cartonWaveCode == 'BA') {
      factor = 1.6;
    } else if (cartonWaveCode == 'BC') {
      factor = 1.45;
    } else if (cartonWaveCode == 'BE') {
      factor = 1;
    } else if (cartonWaveCode == 'BCE') {
      factor = 2;
    }
  } // k phải A1
  else {
    if (cartonWaveCode == 'A') {
      factor = 0.5;
    } else if (cartonWaveCode == 'B') {
      factor = 0.32;
    } else if (cartonWaveCode == 'C') {
      factor = 0.42;
    } else if (cartonWaveCode == 'E') {
      factor = 0.22;
    } else if (cartonWaveCode == 'BC') {
      factor = 0.67;
    } else if (cartonWaveCode == 'BCE') {
      factor = 0.82;
    } else if (cartonWaveCode == 'BA') {
      factor = 0.82;
    } else if (cartonWaveCode == 'BE') {
      factor = 0.57;
    }
  }

  return factor;
};