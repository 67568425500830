"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeRateCustomerHistoryStatusTrans = exports.ChangeRateCustomerHistoryStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _ChangeRateCustomerHi;

var ChangeRateCustomerHistoryStatus;
exports.ChangeRateCustomerHistoryStatus = ChangeRateCustomerHistoryStatus;

(function (ChangeRateCustomerHistoryStatus) {
  ChangeRateCustomerHistoryStatus["Pending"] = "PENDING";
  ChangeRateCustomerHistoryStatus["Approve"] = "APPROVE";
  ChangeRateCustomerHistoryStatus["Reject"] = "REJECT";
})(ChangeRateCustomerHistoryStatus || (exports.ChangeRateCustomerHistoryStatus = ChangeRateCustomerHistoryStatus = {}));

var ChangeRateCustomerHistoryStatusTrans = (_ChangeRateCustomerHi = {}, (0, _defineProperty2.default)(_ChangeRateCustomerHi, ChangeRateCustomerHistoryStatus.Approve, 'Đã duyệt'), (0, _defineProperty2.default)(_ChangeRateCustomerHi, ChangeRateCustomerHistoryStatus.Reject, 'Từ chối'), (0, _defineProperty2.default)(_ChangeRateCustomerHi, ChangeRateCustomerHistoryStatus.Pending, 'Chờ duyệt'), _ChangeRateCustomerHi);
exports.ChangeRateCustomerHistoryStatusTrans = ChangeRateCustomerHistoryStatusTrans;