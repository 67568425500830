"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    staticClass: "total-sale"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalSale.BK + _vm.totalSale.MPE + _vm.totalSale.MXH + _vm.totalSale.TCT)) + "đ")])]), _c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm tên, sđt",
      size: "medium"
    },
    nativeOn: {
      keypress: function keypress($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleQuery.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), !_vm.isNoSeller ? _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhân viên")]), _c("el-select", {
    attrs: {
      value: _vm.taskQuery.staffId,
      clearable: "",
      size: "medium",
      filterable: ""
    },
    on: {
      change: _vm.onChangeStaff
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày mua hàng")]), _c("el-select", {
    attrs: {
      clearable: "",
      size: "medium"
    },
    on: {
      change: _vm.changeBuyType
    },
    model: {
      value: _vm.taskQuery.buyType,
      callback: function callback($$v) {
        _vm.$set(_vm.taskQuery, "buyType", $$v);
      },
      expression: "taskQuery.buyType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Sắp mua",
      value: "SOON_BUY"
    }
  }), _c("el-option", {
    attrs: {
      label: "Lâu chưa mua",
      value: "LONG_BUY"
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lọc tag")]), _c("el-select", {
    staticStyle: {
      width: "230px"
    },
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.handleQuery
    },
    model: {
      value: _vm.query.customerTag,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "customerTag", $$v);
      },
      expression: "query.customerTag"
    }
  }, _vm._l(_vm.customerTags, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("BLock / Unblock")]), _c("el-select", {
    attrs: {
      clearable: ""
    },
    on: {
      change: _vm.handleQuery
    },
    model: {
      value: _vm.query.isCustomerBlock,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isCustomerBlock", $$v);
      },
      expression: "query.isCustomerBlock"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Bị khóa",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "Không khóa",
      value: false
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm")])], 1)]), _c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lọc theo ngày")]), _c("el-date-picker", {
    attrs: {
      type: "daterange",
      "range-separator": "to",
      format: "dd/MM/yyyy",
      "value-format": "yyyy-MM-dd",
      "start-placeholder": "Ngày bắt đầu",
      "end-placeholder": "Ngày kết thúc",
      "picker-options": _vm.pickerOptions
    },
    on: {
      change: _vm.handleChangeDate
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)]), _vm.isAdmin ? _c("el-button", {
    staticStyle: {
      "margin-bottom": "12px",
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "small",
      disabled: !_vm.selectedRows.length
    },
    on: {
      click: _vm.handleRemove
    }
  }, [_vm._v("Xóa " + _vm._s(_vm.selectedRows.length) + " dòng đã chọn")]) : _vm._e(), _c("pagination", {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("TaskTable", {
    attrs: {
      data: _vm.data,
      loading: _vm.loading,
      onFetchData: _vm.fetchData,
      onCompleteTask: _vm.handleCompleteTask,
      onSelectRow: _vm.handleSelectRow,
      onUpdateAddFriend: _vm.handleUpdateAddFriend,
      onViewCustomer: _vm.handleViewCustomer,
      onBlockCustomer: _vm.handleBlock,
      visibleTaskRemainTime: true,
      onChangeSeller: _vm.handleChangeSeller,
      visibleChangeSeller: _vm.visibleChangeSeller,
      sellers: _vm.sellers
    }
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("CreateTaskModal", {
    ref: "CreateTaskModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("UpdateTaskModal", {
    ref: "UpdateTaskModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("CustomerModal", {
    ref: "CustomerModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("TaskReportModal", {
    ref: "TaskReportModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;