"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.misaPOStatusName = exports.MisaPOStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _misaPOStatusName;

var MisaPOStatus;
exports.MisaPOStatus = MisaPOStatus;

(function (MisaPOStatus) {
  MisaPOStatus["Pending"] = "PENDING";
  MisaPOStatus["Processing"] = "PROCESSING";
  MisaPOStatus["Complete"] = "COMPLETE"; //hoàn thành 
})(MisaPOStatus || (exports.MisaPOStatus = MisaPOStatus = {}));

var misaPOStatusName = (_misaPOStatusName = {}, (0, _defineProperty2.default)(_misaPOStatusName, MisaPOStatus.Pending, 'Chưa thực hiện'), (0, _defineProperty2.default)(_misaPOStatusName, MisaPOStatus.Processing, 'Đang thực hiện'), (0, _defineProperty2.default)(_misaPOStatusName, MisaPOStatus.Complete, 'Hoàn thành'), _misaPOStatusName);
exports.misaPOStatusName = misaPOStatusName;