"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày lập lệnh",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.startAt ? _vm.$formatDate(row.startAt) : "--") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "LSX",
      prop: "code",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.syncId ? _c("el-link", {
          staticStyle: {
            color: "#1989fa"
          },
          attrs: {
            href: "https://actapp.misa.vn/app/popup/INProductionOrderDetail/".concat(row.syncId),
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.code) + " ")]) : _c("span", [_vm._v(" " + _vm._s(row.code) + " ")]), row.misaTags.length ? [_c("div", _vm._l(row.misaTags, function (item, index) {
          return _c("MisaTagComp", {
            key: index,
            attrs: {
              misaTag: item
            }
          });
        }), 1)] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Mã KH"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.msOrder ? _c("div", [_c("span", {
          staticStyle: {
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(row.msOrder.customerCode) + " ")]), _c("br"), _c("span", [_vm._v(_vm._s(row.msOrder.customerName))])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái SX",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var _vm$WorkOrderProducti, _vm$WorkOrderProducti2;

        var row = _ref4.row;
        return [_c("el-tag", {
          staticStyle: {
            border: "unset"
          },
          attrs: {
            color: (_vm$WorkOrderProducti = _vm.WorkOrderProductionStatusTrans[row.productionStatus]) === null || _vm$WorkOrderProducti === void 0 ? void 0 : _vm$WorkOrderProducti.color,
            effect: "dark"
          }
        }, [_vm._v(" " + _vm._s((_vm$WorkOrderProducti2 = _vm.WorkOrderProductionStatusTrans[row.productionStatus]) === null || _vm$WorkOrderProducti2 === void 0 ? void 0 : _vm$WorkOrderProducti2.name) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tiến độ",
      width: "75"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.completedProcess) + " / " + _vm._s(row.totalProcess) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tên hàng hoá"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(_vm._s(row.productName))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số màu in",
      align: "right",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.printColors > 0 ? "".concat(row.printColors, " m\xE0u") : "Không") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sóng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(_vm._s(row.cartonWaveCode))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lớp"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(_vm._s(row.layer) + " lớp")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "70",
      label: "Số lượng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_vm._v(_vm._s(_vm.$formatNumberVN(row.quantity)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "70",
      label: "Loại thùng",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_vm._v(_vm._s(row.cartonTypeName))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chống thấm",
      align: "right",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_vm._v(" " + _vm._s(row.waterProof) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Định hình",
      align: "right",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_vm._v(" " + _vm._s(row.dinhHinh) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú misa"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var row = _ref14.row;
        return [row.productNote != "1" ? _c("span", [_vm.checkIsGap(row.productNote) ? _c("img", {
          attrs: {
            width: "50",
            src: _vm.warningImage
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(row.productNote) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi chú"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var row = _ref15.row;
        return [_c("el-tooltip", {
          attrs: {
            content: row.note,
            placement: "top",
            effect: "dark"
          }
        }, [_c("span", [_vm._v(_vm._s(row.note))])])];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;