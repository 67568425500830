"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.string.trim.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "login-form"
  }, [_c("div", {
    staticClass: "login-container"
  }, [_vm.formType == "login" ? [_c("span", {
    staticClass: "login-for-booker"
  }, [_vm._v("Log in for professionals")]), _c("el-form", {
    ref: "form",
    staticClass: "sign-in-form",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Username",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.username"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      label: "Password",
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      type: _vm.showPass ? "text" : "password",
      size: "medium"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c("div", {
    staticClass: "show-pwd"
  }, [!_vm.showPass ? _c("font-awesome-icon", {
    attrs: {
      icon: "eye"
    },
    on: {
      click: function click($event) {
        _vm.showPass = true;
      }
    }
  }) : _c("font-awesome-icon", {
    attrs: {
      icon: "eye-slash"
    },
    on: {
      click: function click($event) {
        _vm.showPass = false;
      }
    }
  })], 1)], 1)], 1), false ? _c("a", {
    staticClass: "forgot-label",
    on: {
      click: function click($event) {
        _vm.formType = "forgot";
      }
    }
  }, [_vm._v("Forgot password?")]) : _vm._e(), _c("el-button", {
    staticClass: "btn-login",
    attrs: {
      type: "primary",
      loading: _vm.loadingSubmit
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" Log in ")])] : _vm.formType == "reset" ? _c("re-password-form", {
    attrs: {
      email: _vm.emailReset,
      resetCode: _vm.resetCode
    },
    on: {
      back: function back($event) {
        _vm.formType = "login";
      }
    }
  }) : _vm.formType == "forgot" ? _c("forgot-form", {
    on: {
      "back-login": function backLogin($event) {
        _vm.formType = "login";
      }
    }
  }) : _vm.formType == "otp" ? _c("otp-form", {
    attrs: {
      staffId: _vm.staffId
    },
    on: {
      "back-forgot": function backForgot($event) {
        _vm.formType = "login";
      },
      otpOk: _vm.handleOtpOk
    }
  }) : _vm._e()], 2), _c("AllowGPSModal", {
    ref: "AllowGPSModal",
    on: {
      submitOk: _vm.handleAgreeGPS
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;