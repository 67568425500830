"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "800px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng công",
      prop: "quantityTask"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.quantityTask,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantityTask", $$v);
      },
      expression: "form.quantityTask"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại công việc",
      prop: "type"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.TaskTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nhân viên bán hàng",
      prop: "sellerId"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.sellerId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sellerId", $$v);
      },
      expression: "form.sellerId"
    }
  }, _vm._l(_vm.sellers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nhân viên đảm nhiệm",
      prop: "staffId"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffId", $$v);
      },
      expression: "form.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _vm.form.type == _vm.TaskType.BuyMore ? _c("section", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Lọc theo điều kiện")]), _c("el-row", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng doanh số"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.totalPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "totalPrice", $$v);
      },
      expression: "form.totalPrice"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lần mua hàng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.totalOrder,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "totalOrder", $$v);
      },
      expression: "form.totalOrder"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày mua hàng"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      format: "dd/MM/yyyy",
      type: "daterange",
      clearable: ""
    },
    model: {
      value: _vm.buyDate,
      callback: function callback($$v) {
        _vm.buyDate = $$v;
      },
      expression: "buyDate"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày chăm sóc"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      format: "dd/MM/yyyy",
      type: "daterange",
      clearable: ""
    },
    model: {
      value: _vm.taskDate,
      callback: function callback($$v) {
        _vm.taskDate = $$v;
      },
      expression: "taskDate"
    }
  })], 1)], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "small"
    },
    on: {
      click: _vm.addCategory
    }
  }, [_vm._v("Thêm danh mục")]), _vm._l(_vm.form.categories, function (item, index) {
    return _c("ProductCategoryFilterComponent", {
      key: index,
      attrs: {
        category: item,
        productCategories: _vm.productCategories
      },
      on: {
        delete: _vm.deleteCategory
      }
    });
  })], 2) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.createData
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;