"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật LSX" : "Thêm mới LSX",
      visible: _vm.visible,
      "close-on-click-modal": false,
      width: "100%",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "top"
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Thông tin",
      name: "1"
    }
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFetch,
      expression: "loadingFetch"
    }],
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mã"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày lập lệnh"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: "",
      format: "dd/MM/yyyy",
      type: "date"
    },
    model: {
      value: _vm.form.startAtDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "startAtDate", $$v);
      },
      expression: "form.startAtDate"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày kết thúc"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      format: "dd/MM/yyyy",
      type: "date",
      clearable: false
    },
    model: {
      value: _vm.form.dueAtDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dueAtDate", $$v);
      },
      expression: "form.dueAtDate"
    }
  })], 1)], 1)], 1), _c("section", [_c("label", {
    staticStyle: {
      color: "black"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Mô tả:")]), _c("div", {
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      innerHTML: _vm._s(_vm.formattedText)
    }
  })]), _c("el-form-item", {
    attrs: {
      label: "Thẻ"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "4px"
    }
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreateMisaTag
    }
  }, [_vm._v("Thẻ")]), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.handleViewMisaTag
    }
  }, [_vm._v("Quản lý thẻ")])], 1), _c("el-select", {
    attrs: {
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.form.misaTagIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "misaTagIds", $$v);
      },
      expression: "form.misaTagIds"
    }
  }, _vm._l(_vm.misaTags, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("MisaTagComp", {
      attrs: {
        misaTag: item
      }
    })], 1);
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 16,
      offset: 0
    }
  }, [_c("el-checkbox", {
    attrs: {
      disabled: _vm.form.productionStatus == _vm.WorkOrderProductionStatus.Complete
    },
    model: {
      value: _vm.form.isFlowStep,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isFlowStep", $$v);
      },
      expression: "form.isFlowStep"
    }
  }, [_vm._v("Quy trình theo tuần tự")]), _c("el-form-item", {
    attrs: {
      label: "Quy trình",
      prop: "productionProcessId"
    }
  }, [_c("el-select", {
    attrs: {
      value: _vm.form.productionProcessId,
      filterable: "",
      disabled: _vm.form.productionStatus == _vm.WorkOrderProductionStatus.Complete
    },
    on: {
      change: _vm.handelChangeProcess
    }
  }, _vm._l(_vm.productionProcesses, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _c("ElTableDraggable", [_c("el-table", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      data: _vm.form.productionWorkForm,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "40",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var $index = _ref.$index;
        return [_c("i", {
          staticClass: "el-icon-rank"
        }), _c("span", [_vm._v(" " + _vm._s($index + 1) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Bước"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-select", {
          attrs: {
            disabled: _vm.form.productionStatus == _vm.WorkOrderProductionStatus.Complete,
            value: row.productionStepId
          },
          on: {
            change: function change(val) {
              return _vm.handleChangeStep(row, val);
            }
          }
        }, _vm._l(_vm.productionSteps, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: item.name,
              value: item.id
            }
          });
        }), 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chọn máy"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-select", {
          attrs: {
            multiple: "",
            disabled: _vm.form.productionStatus == _vm.WorkOrderProductionStatus.Complete
          },
          on: {
            change: function change($event) {
              return _vm.handleChangeMachines(row);
            }
          },
          model: {
            value: row.productionMachineIds,
            callback: function callback($$v) {
              _vm.$set(row, "productionMachineIds", $$v);
            },
            expression: "row.productionMachineIds"
          }
        }, _vm._l(_vm.getMachines(row.productionStepId), function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: item.name,
              value: item.id
            }
          });
        }), 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var _vm$ProductionWorkSta, _vm$ProductionWorkSta2;

        var row = _ref4.row;
        return [row.status ? [_c("span", {
          style: {
            color: (_vm$ProductionWorkSta = _vm.ProductionWorkStatusTrans[row.status]) === null || _vm$ProductionWorkSta === void 0 ? void 0 : _vm$ProductionWorkSta.color
          }
        }, [_vm._v(" " + _vm._s((_vm$ProductionWorkSta2 = _vm.ProductionWorkStatusTrans[row.status]) === null || _vm$ProductionWorkSta2 === void 0 ? void 0 : _vm$ProductionWorkSta2.name))])] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thời gian"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.status != _vm.ProductionWorkStatus.Complete && row.duration ? [_c("span", {
          staticClass: "hour-hover",
          staticStyle: {
            color: "red",
            "font-weight": "bold"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickDuration(row);
            }
          }
        }, [_vm._v(_vm._s(_vm.renderTime(row.duration)) + " ")]), row.plannedEndAt ? _c("div", [_c("span", [_vm._v("Dự kiến kết thúc: ")]), _c("span", [_vm._v(_vm._s(_vm.$formatDate(row.plannedEndAt, "HH:mm")))])]) : _vm._e()] : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "80",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var $index = _ref6.$index;
        return [_vm.form.productionStatus != _vm.WorkOrderProductionStatus.Complete ? _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDeleteStep($index);
            }
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus",
      disabled: _vm.form.productionStatus == _vm.WorkOrderProductionStatus.Complete
    },
    on: {
      click: _vm.handleAddStep
    }
  }, [_vm._v("Thêm bước")])], 1)], 1)], 1)], 1)], 1), _vm.status == "update" && _vm.workOrder && _vm.visible ? _c("el-tab-pane", {
    attrs: {
      label: "Log",
      name: "2"
    }
  }, [_c("ProductionLogTab", {
    attrs: {
      workOrderId: _vm.workOrder.id
    }
  })], 1) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _vm.tab == "1" ? _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Đồng ý")]) : _vm._e()], 1), _c("MisaTagModal", {
    ref: "MisaTagModal",
    on: {
      submitOk: _vm.fetchMisaTags
    }
  }), _c("MisaTagManageModal", {
    ref: "MisaTagManageModal",
    on: {
      submitOk: _vm.fetchMisaTags
    }
  }), _c("SelectDeliveryTripModal", {
    ref: "SelectDeliveryTripModal",
    on: {
      submitOk: _vm.handleChoseDeliveryOk
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;