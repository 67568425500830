"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container quote"
  }, [_c("div", {
    staticClass: "filter-container"
  }), _c("el-tabs", {
    on: {
      "tab-click": _vm.handleChangeTab
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabs.filter(function (e) {
    return e.visible;
  }), function (item, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        name: item.name,
        label: item.label
      }
    }, [_c("span", {
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_vm._v(" " + _vm._s(item.label) + " "), _c("el-badge", {
      staticClass: "quote-badge-status",
      class: item.name,
      attrs: {
        value: item.total
      }
    })], 1), _c("quote-history-page", {
      attrs: {
        status: item.status,
        tab: item.name,
        staffId: _vm.staffId,
        isFocused: _vm.tab == item.name,
        staffs: _vm.staffs,
        version: 3
      },
      on: {
        fetched: _vm.handleOnFetched,
        "change-staff": _vm.handleChangeStaff
      }
    })], 1);
  }), 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;