"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCurrency = formatCurrency;
exports.formatNumber = void 0;
exports.formatNumberVN = formatNumberVN;
exports.testRoundPrice = exports.secondToMinutes = exports.roundPrice = exports.formatPhone = void 0;

require("core-js/modules/es.number.to-fixed.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

var _moment = _interopRequireDefault(require("moment"));

require("moment-duration-format");

var _libphonenumberJs = _interopRequireDefault(require("libphonenumber-js"));

function formatCurrency(n) {
  return new Intl.NumberFormat('Vi', {
    style: 'currency',
    currency: 'VND'
  }).format(n);
}

var formatPhone = function formatPhone(callingCode, phone) {
  var res = (0, _libphonenumberJs.default)("".concat(phone));
  return res ? res.formatInternational() : '';
};

exports.formatPhone = formatPhone;

function formatNumberVN(n) {
  var fixed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var replaceChar = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

  if (isNaN(n)) {
    return replaceChar;
  }

  n = (+n || 0).toFixed(n < 1 ? 2 : fixed);
  return new Intl.NumberFormat('de-DE').format(n); // return 
  //   .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, separator));
}

var formatNumber = function formatNumber(n) {
  var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ',';
  return n.toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, separator);
  });
};

exports.formatNumber = formatNumber;

var secondToMinutes = function secondToMinutes(seconds) {
  var hour = Math.floor(_moment.default.duration(seconds, 'seconds').asHours());

  var minute = _moment.default.duration(seconds, 'seconds').asMinutes();

  var second = _moment.default.duration(seconds, 'seconds').seconds();

  if (second < 10) {
    second = "0".concat(second);
  }

  if (seconds > 3600) {
    return hour + 'h ' + (minute - 60 * hour) + 'm ' + second;
  }

  return minute + 'm ' + second;
};
/**
 *
 * @param price number
 * @param fixed number
 * @returns
 */


exports.secondToMinutes = secondToMinutes;

var roundPrice = function roundPrice(price) {
  var fixed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
  var round = 0,
      suffix = 0;

  if (price > 10000) {
    round = Math.floor(price / 1000) * 1000;
    suffix = price % 1000;
  } else if (price > 1000) {
    round = Math.floor(price / 1000) * 1000; //1420 => 1000

    suffix = price % 1000; //1420 => 420
  } else {
    round = Math.floor(price / 100) * 100;
    suffix = price % 100;
  }

  while (round % fixed != 0 || round < price) {
    round += fixed;
  }

  return round;
};

exports.roundPrice = roundPrice;

var testRoundPrice = function testRoundPrice(price) {
  var fixed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;

  while (price % fixed != 0) {
    price++;
  }

  return price;
};

exports.testRoundPrice = testRoundPrice;