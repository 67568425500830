"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaskConfigType = void 0;
var TaskConfigType;
exports.TaskConfigType = TaskConfigType;

(function (TaskConfigType) {
  TaskConfigType["Sale"] = "SALE";
})(TaskConfigType || (exports.TaskConfigType = TaskConfigType = {}));