"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "upload-container"
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-upload",
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = true;
      }
    }
  }, [_vm._v("upload")]), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-upload", {
    staticClass: "editor-slide-upload",
    attrs: {
      multiple: true,
      "file-list": _vm.fileList,
      "show-file-list": true,
      "on-remove": _vm.handleRemove,
      "on-success": _vm.handleSuccess,
      "before-upload": _vm.beforeUpload,
      action: _vm.baseUrl + _vm.urlUpload,
      "list-type": "picture-card",
      name: "image",
      headers: {
        token: _vm.token
      }
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    }
  }, [_vm._v("Click upload")])], 1), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Confirm")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;