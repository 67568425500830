"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      width: "1000px",
      top: "30px",
      "close-on-click-modal": false,
      fullscreen: _vm.isFullScreen
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("label", {
    staticStyle: {
      color: "#000",
      "font-size": "20px"
    }
  }, [_vm._v("Xem lịch sử nhập kho")])]), _c("el-tabs", {
    attrs: {
      "tab-position": "top"
    },
    on: {
      "tab-click": function tabClick($event) {}
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Thông tin chung",
      name: "1"
    }
  }, [_c("div", [_vm.workOrder ? _c("WorkOrderInventoryForm", {
    ref: "WorkOrderInventoryForm",
    attrs: {
      isHiddenName: true,
      visibleUpdateInventory: _vm.isAdmin,
      workOrderId: _vm.workOrder.id
    },
    on: {
      updateInventory: _vm.handleUpdateInventory
    }
  }) : _vm._e()], 1)]), _c("el-tab-pane", {
    attrs: {
      name: "2"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" DS Pallet "), _c("el-badge", {
    attrs: {
      value: _vm.totalDetail
    }
  })], 1), _vm.workOrder ? _c("WorkOrderInventoryDetailTab", {
    attrs: {
      workOrderId: _vm.workOrder.id
    },
    on: {
      getList: _vm.onGetDetail
    }
  }) : _vm._e()], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1), _c("CreateWorkOrderInventoryModal", {
    ref: "CreateWorkOrderInventoryModal",
    on: {
      submitOk: _vm.handleUpdateInventoryOk
    }
  })], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;