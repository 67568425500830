"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationType = void 0;
var NotificationType;
exports.NotificationType = NotificationType;

(function (NotificationType) {
  NotificationType["RequestRatingCustomer"] = "REQUEST_RATING_CUSTOMER";
  NotificationType["ApproveRatingCustomer"] = "APPROVE_RATING_CUSTOMER";
  NotificationType["RejectRatingCustomer"] = "REJECT_RATING_CUSTOMER";
})(NotificationType || (exports.NotificationType = NotificationType = {}));