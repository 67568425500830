"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "po-print"
  }, [_c("div", [_c("div", {
    attrs: {
      id: "print"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhà chung cấp: ")]), _c("span", [_vm._v(_vm._s(_vm.po.supplier.name))])]), _c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ngày tạo PO: ")]), _c("span", [_vm._v(_vm._s(_vm.po.poDate))])]), _c("h3", {
    staticClass: "text-center"
  }, [_vm._v(" Danh sách ")]), _c("table", {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_vm._m(0), _c("tbody", [_vm._l(_vm.productsPo, function (item, index) {
    return _c("tr", {
      key: index
    }, [_c("td", {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c("td", [_vm._v(_vm._s(item.productName))]), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(item.available)))]), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(item.quantityPo, 0, ".")))]), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(item.actualQuantity, 0, ".")))]), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(item.quantityPo - item.actualQuantity, 0, ".")))]), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(item.quantityYet, 0, ".")))]), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(item.quantity, 0, ".")))]), _c("td", {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(item.isComplete ? "Hoàn thành" : "Chưa hoàn thành"))])]);
  }), _c("tr", {
    staticClass: "footer"
  }, [_c("td", {
    staticClass: "text-center",
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("Tổng")]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.sum.available)))]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.sum.quantityPo)))]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.sum.actualQuantity)))]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.sum.remain)))]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.sum.quantityYet)))]), _c("td", {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.sum.quantity)))]), _c("td", {
    staticClass: "text-right"
  })])], 2)])])])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("thead", [_c("tr", [_c("th", [_vm._v("STT")]), _c("th", {
    staticClass: "text-left",
    staticStyle: {
      width: "250px"
    }
  }, [_vm._v("Sản phẩm")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("Tồn kho")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("Đã lên lịch")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("Đã giao")]), _c("th", {
    staticClass: "text-right",
    staticStyle: {
      width: "100px"
    }
  }, [_vm._v("Còn lại")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("Tồn + Chưa nhập")]), _c("th", {
    staticStyle: {
      width: "80px"
    }
  }, [_vm._v("Số lượng đặt hàng")]), _c("th", {
    staticStyle: {
      width: "120px"
    }
  }, [_vm._v("Hoàn thành")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;