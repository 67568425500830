"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var customerApi = {
  findAll: findAll,
  update: update,
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/v1/admin/customer/".concat(id)
    });
  },
  findList: function findList(params) {
    return (0, _request.default)({
      url: "/v1/admin/customer/list",
      params: params
    });
  },
  findSaleMix: function findSaleMix(params) {
    return (0, _request.default)({
      url: "/v1/admin/customer/sale/mix",
      params: params
    });
  },
  checkPhone: function checkPhone(params) {
    return (0, _request.default)({
      url: "/v1/admin/customer/check/phone",
      params: params
    });
  },
  findSaleByProduct: function findSaleByProduct(params) {
    return (0, _request.default)({
      url: '/v1/admin/customer/top-sale-by-product',
      params: params
    });
  },
  findSaleByProductQuantity: function findSaleByProductQuantity(params) {
    return (0, _request.default)({
      url: '/v1/admin/customer/top-sale-by-product/quantity',
      params: params
    });
  },
  changeSeller: function changeSeller(customerId, sellerId) {
    return (0, _request.default)({
      url: "/v1/admin/customer/".concat(customerId, "/change-seller"),
      data: {
        sellerId: sellerId
      },
      method: 'put'
    });
  },
  syncTotalPriceAndOrder: function syncTotalPriceAndOrder(customerId) {
    return (0, _request.default)({
      url: "/v1/admin/customer/calc-total-price-and-order",
      params: {
        customerId: customerId
      }
    });
  },
  setPromotions: function setPromotions(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/customer/".concat(id, "/promotion"),
      data: data,
      method: 'put'
    });
  },
  findProductBought: function findProductBought(customerId) {
    return (0, _request.default)({
      url: "/v1/admin/customer/".concat(customerId, "/product-bought")
    });
  },
  findProductCategoryBought: function findProductCategoryBought(customerId) {
    return (0, _request.default)({
      url: "/v1/admin/customer/".concat(customerId, "/product-category-bought")
    });
  }
};
exports.customerApi = customerApi;

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/v1/admin/customer",
    method: "get",
    params: listQuery
  });
}

function update(customerId, data) {
  return (0, _request.default)({
    url: "/v1/admin/customer/".concat(customerId, "/update"),
    method: "put",
    data: data
  });
}