"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "notification-item"
  }, [_c("div", {
    staticClass: "notification-image"
  }, [_c("img", {
    attrs: {
      src: _vm.$baseUrlMedia + _vm.notification.staff.avatar,
      alt: ""
    }
  })]), _c("div", [_c("div", {
    staticClass: "notification-desc",
    domProps: {
      innerHTML: _vm._s(_vm.notification.desc)
    }
  }), _c("span", {
    staticClass: "notification-time"
  }, [_vm._v(" " + _vm._s(_vm.$fromNow(_vm.notification.dateCreated)) + " ")])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;