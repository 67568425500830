"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "",
      visible: _vm.visible,
      width: "1300px",
      top: "30px",
      "close-on-click-modal": false,
      "close-on-press-escape": _vm.isCloseEscape
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "600",
      "font-size": "26px"
    }
  }, [_vm._v("Danh sách báo cáo")]), _vm.workOrder ? _c("div", [_vm._v(" " + _vm._s(_vm.workOrder.productName) + " ")]) : _vm._e(), _vm.date ? _c("div", [_vm._v("Ngày " + _vm._s(_vm.dateFormatted))]) : _vm._e()]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [(_vm.productionWork.status != _vm.ProductionWorkStatus.Complete || _vm.$devMode) && _vm.visibleCreate ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Tạo báo cáo")]) : _vm._e()], 1), _c("ProductionReportTable", {
    attrs: {
      loading: _vm.loading,
      productionReports: _vm.data,
      visibleInspec: _vm.visibleInspec
    },
    on: {
      delete: _vm.handleDelete,
      print: _vm.handlePrint,
      update: _vm.handleUpdate
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("span"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1), _c("CreateReportQuantityModal", {
    ref: "CreateReportQuantityModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _vm.selected ? _c("ReportTPPrint", {
    ref: "ReportTPPrint",
    attrs: {
      productionReport: _vm.selected,
      workOrder: _vm.workOrder
    }
  }) : _vm._e(), _vm.selected ? _c("ReportBTPPrint", {
    ref: "ReportBTPPrint",
    attrs: {
      productionReport: _vm.selected,
      workOrder: _vm.workOrder
    }
  }) : _vm._e()], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;