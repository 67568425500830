"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.string.link.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "ID",
      prop: "id"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "User"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.userFullName) + " (" + _vm._s(row.username) + ") ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Action",
      prop: "note"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Link",
      prop: "note"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-link", {
          attrs: {
            type: "primary",
            href: row.link,
            target: "_blank"
          }
        }, [_vm._v("Liên kết")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Last update"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.updatedAt)) + " ")];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;