"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", [_vm._l(_vm.tabs, function (item, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        label: item.label
      }
    }, [_c("quote-product-print", {
      attrs: {
        note: _vm.note,
        numExpireDay: _vm.numExpireDay,
        onUpdateNote: _vm.handleUpdateNote,
        company: item.data,
        tabName: item.name
      }
    })], 1);
  }), _c("el-tab-pane", {
    attrs: {
      label: "Cấu hình in"
    }
  }, [_c("quote-product-config-print", {
    on: {
      "change-config": _vm.handleChangeConfig
    }
  })], 1)], 2)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;