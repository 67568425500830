"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("el-table", {
    attrs: {
      data: _vm.products,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      align: "center",
      width: "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.productCategory && row.productCategory.type == _vm.ProductCategoryType.Size ? _c("img", {
          attrs: {
            src: _vm.bigSizeImg,
            width: "50"
          }
        }) : _vm._e(), row.productCategory && row.productCategory.type == _vm.ProductCategoryType.Weight ? _c("img", {
          attrs: {
            src: _vm.bigWeightImg,
            width: "50"
          }
        }) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tên",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", [_vm._v(" " + _vm._s(row.name) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khối lượng (Kg)",
      prop: "weight",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Vận chuyển tối đa",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(Math.ceil(200 / row.weight)) + " " + _vm._s(row.unit) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("el-input", {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: {
              alias: "decimal",
              groupSeparator: ".",
              rightAlign: false,
              autoGroup: true,
              repeat: 20,
              autoUnmask: true,
              allowMinus: false
            },
            expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
          }],
          staticClass: "input-number",
          staticStyle: {
            "text-align": "center"
          },
          model: {
            value: row.quantity,
            callback: function callback($$v) {
              _vm.$set(row, "quantity", $$v);
            },
            expression: "row.quantity"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng khối lượng (Kg)",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity * row.weight)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-tooltip", {
          attrs: {
            content: "Xóa",
            placement: "top",
            effect: "dark"
          }
        }, [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.onDelete(row);
            }
          }
        })], 1)];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;