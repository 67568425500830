"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.misaQuotes,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Người tạo",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.misaEmployee ? "".concat(row.misaEmployee.name, " (").concat(row.misaEmployee.code, ")") : "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Cơ hội",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.misaOpportunity ? _c("el-link", {
          attrs: {
            type: "primary",
            href: "https://amisapp.misa.vn/crm/opportunity/view/".concat(row.misaOpportunity.misaId, "/null"),
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.misaOpportunity.name) + " ")]) : _c("span", [_vm._v(" - ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số báo giá",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-link", {
          attrs: {
            type: "primary",
            href: "https://amisapp.misa.vn/crm/quote/view/".concat(row.misaId, "/44"),
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.code) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày báo giá",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$stringToDate(row.date)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Người tạo",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.misaEmployee ? "".concat(row.misaEmployee.name, " (").concat(row.misaEmployee.code, ")") : "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khách hàng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          nativeOn: {
            click: function click($event) {
              if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return _vm.handleClickCustomer(row.misaCustomer);
            }
          }
        }, [_vm._v(" " + _vm._s(row.misaCustomer ? "".concat(row.misaCustomer.code, " - ").concat(row.misaCustomer.name) : "") + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.stageText) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng tiền"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalMoney)) + " ")];
      }
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;