"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nhanhApi = void 0;

var _request = require("@/utils/request");

var nhanhApi = {
  getLinkAuth: function getLinkAuth() {
    return (0, _request.request)({
      url: '/v1/hooks/nhanh/auth/link'
    });
  }
};
exports.nhanhApi = nhanhApi;