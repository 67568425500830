"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentMethodTrans = exports.PaymentMethodEasyInvoice = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _PaymentMethodTrans;

var PaymentMethodEasyInvoice;
exports.PaymentMethodEasyInvoice = PaymentMethodEasyInvoice;

(function (PaymentMethodEasyInvoice) {
  PaymentMethodEasyInvoice["TM"] = "T/M";
  PaymentMethodEasyInvoice["CK"] = "C/K";
  PaymentMethodEasyInvoice["TMCK"] = "TM/CK"; //CM hoac tien mat
})(PaymentMethodEasyInvoice || (exports.PaymentMethodEasyInvoice = PaymentMethodEasyInvoice = {}));

var PaymentMethodTrans = (_PaymentMethodTrans = {}, (0, _defineProperty2.default)(_PaymentMethodTrans, PaymentMethodEasyInvoice.TM, 'T/M'), (0, _defineProperty2.default)(_PaymentMethodTrans, PaymentMethodEasyInvoice.CK, 'C/K'), (0, _defineProperty2.default)(_PaymentMethodTrans, PaymentMethodEasyInvoice.TMCK, 'TM/CK'), _PaymentMethodTrans);
exports.PaymentMethodTrans = PaymentMethodTrans;