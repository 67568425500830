"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "pricing-modal",
    attrs: {
      title: "Xem gi\xE1 ".concat(_vm.product.name),
      visible: _vm.visible,
      fullscreen: "",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFetch,
      expression: "loadingFetch"
    }],
    staticClass: "pricing-table",
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "row-class-name": _vm.generateRowClassName
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      label: "Số lượng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.quantity)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "right",
      label: "Giá tiền"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", {
          staticClass: "font-bold"
        }, [_vm._v(_vm._s(_vm.$formatNumberVN(row.price)) + "đ")])];
      }
    }])
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div"), _c("el-button", {
    staticClass: "font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;