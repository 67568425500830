"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "truck-tour-print"
  }, [_c("div", {
    attrs: {
      id: "truck-tour-print"
    }
  }, [_c("table", [_vm._m(0), _c("tbody", _vm._l(_vm.dataFilter, function (item, index) {
    return _c("tr", {
      key: index
    }, [item.length ? _c("td", {
      staticClass: "text-center",
      attrs: {
        rowspan: item.length
      }
    }, [_vm._v(_vm._s(index + 1))]) : _vm._e(), item.length ? _c("td", {
      attrs: {
        rowspan: item.length
      }
    }, [_vm._v(_vm._s(item.customer.name))]) : _vm._e(), item.length ? _c("td", {
      attrs: {
        rowspan: item.length
      }
    }, [_vm._v(_vm._s(item.customer.phone))]) : _vm._e(), item.length ? _c("td", {
      attrs: {
        rowspan: item.length
      }
    }, [_vm._v(_vm._s(item.customer.address))]) : _vm._e(), _c("td", [_vm._v(_vm._s(item.product.name))]), _c("td", {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.product.quantity))]), item.length ? _c("td", {
      staticClass: "text-center",
      attrs: {
        rowspan: item.length
      }
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(item.calcTotalMoney)))]) : _vm._e(), item.length ? _c("td", {
      attrs: {
        rowspan: item.length
      }
    }, [_vm._v(_vm._s(item.seller.name))]) : _vm._e()]);
  }), 0)])])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("thead", [_c("tr", [_c("th", {
    staticClass: "text-center"
  }, [_vm._v("STT")]), _c("th", [_vm._v("Khách hàng")]), _c("th", [_vm._v("SĐT")]), _c("th", [_vm._v("Địa chỉ")]), _c("th", {
    staticStyle: {
      width: "200px"
    }
  }, [_vm._v("Sản phẩm")]), _c("th", {
    staticClass: "text-center"
  }, [_vm._v("Số lượng")]), _c("th", {
    staticClass: "text-center"
  }, [_vm._v("Thu hộ")]), _c("th", [_vm._v("Nhân viên")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;