"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      fullscreen: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("h2", {
    staticStyle: {
      margin: "0"
    }
  }, [_vm.status == "create" ? _c("span", [_vm._v(" Thêm mới ")]) : _c("div", [_c("span", [_vm._v(" Thông tin khách hàng ")]), _c("el-link", {
    staticStyle: {
      "font-size": "24px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleClickCode(_vm.misaCustomer);
      }
    }
  }, [_vm._v(" #" + _vm._s(_vm.misaCustomer ? _vm.misaCustomer.code : "") + " ")])], 1)])]), _c("el-tabs", [_c("el-tab-pane", {
    attrs: {
      label: "Thông tin"
    }
  }, [_c("MisaCustomerInfoTab", {
    attrs: {
      misaCustomer: _vm.misaCustomer
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Báo giá"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Báo giá "), _c("el-badge", {
    attrs: {
      value: _vm.totalQuote
    }
  })], 1), _vm.misaCustomer ? _c("MisaCustomerQuoteTab", {
    attrs: {
      misaCustomerId: _vm.misaCustomer.id
    }
  }) : _vm._e()], 1), _c("el-tab-pane", {
    attrs: {
      label: "Đơn hàng"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" Đơn hàng "), _c("el-badge", {
    attrs: {
      value: _vm.totalOrder
    }
  })], 1), _vm.misaCustomer ? _c("MisaCustomerOrderTab", {
    attrs: {
      misaCustomerId: _vm.misaCustomer.id
    }
  }) : _vm._e()], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;