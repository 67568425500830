"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới" : "Cập nhật",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Kết cấu giấy (Khách hàng)",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Kết cấu giấy (Kỹ thuật)",
      prop: "subName"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.subName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subName", $$v);
      },
      expression: "form.subName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Kết cấu giấy (Dễ hiểu)"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lớp",
      size: "normal"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 3
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_vm._v("3 lớp ")]), _c("el-radio", {
    attrs: {
      label: 5
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_vm._v("5 lớp ")]), _c("el-radio", {
    attrs: {
      label: 7
    },
    model: {
      value: _vm.form.layer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "layer", $$v);
      },
      expression: "form.layer"
    }
  }, [_vm._v("7 lớp ")])], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số KG tối đa"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm._v(" Kg ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.maxWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "maxWeight", $$v);
      },
      expression: "form.maxWeight"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giá tiền",
      prop: "price"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm._v(" đ/m"), _c("sup", [_vm._v("2")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Định lượng",
      prop: "weight"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm._v(" gram ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "weight", $$v);
      },
      expression: "form.weight"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số m2 tối thiểu",
      prop: "minM2"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm._v(" m2 ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.minM2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "minM2", $$v);
      },
      expression: "form.minM2"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số m2 tối đa",
      prop: "maxM2"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }"
    }],
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm._v(" m2 ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.maxM2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "maxM2", $$v);
      },
      expression: "form.maxM2"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Ghi chú",
      prop: "note"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 4,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", [_c("el-button", {
    staticClass: "font-size--medium font-bold",
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _vm.status == "update" ? _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      loading: _vm.loadingDelete,
      type: "danger"
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa")]) : _vm._e()], 1), _c("el-button", {
    staticClass: "font-size--medium font-bold",
    attrs: {
      type: "primary",
      loading: _vm.loadingSubmit
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Lưu")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;