"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "panel-shadow"
  }, [_c("h2", {
    staticClass: "text-color--black",
    staticStyle: {
      margin: "0",
      "margin-bottom": "12px"
    }
  }, [_vm._v("Thông tin khách hàng")]), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.customer,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 16,
      offset: 0
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 16,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên khách hàng",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.customer.name,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "name", $$v);
      },
      expression: "customer.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Điện thoại",
      prop: "phone"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.customer.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "phone", $$v);
      },
      expression: "customer.phone"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 16,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Email",
      prop: "email"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.customer.email,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "email", $$v);
      },
      expression: "customer.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khu vực",
      prop: "area"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.CustomerArea.Internal
    },
    on: {
      change: function change($event) {
        return _vm.$emit("changeArea");
      }
    },
    model: {
      value: _vm.customer.area,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "area", $$v);
      },
      expression: "customer.area"
    }
  }, [_vm._v(" Nội thành HCM")]), _c("el-radio", {
    attrs: {
      label: _vm.CustomerArea.External
    },
    on: {
      change: function change($event) {
        return _vm.$emit("changeArea");
      }
    },
    model: {
      value: _vm.customer.area,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "area", $$v);
      },
      expression: "customer.area"
    }
  }, [_vm._v(" Ngoại thành HCM")])], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Công ty",
      prop: ""
    }
  }, [_c("el-input", {
    model: {
      value: _vm.customer.company,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "company", $$v);
      },
      expression: "customer.company"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Địa chỉ",
      prop: "address"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.customer.address,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "address", $$v);
      },
      expression: "customer.address"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.CustomerQuoteType.Normal
    },
    model: {
      value: _vm.customer.type,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "type", $$v);
      },
      expression: "customer.type"
    }
  }, [_vm._v("Người dùng cuối")]), _c("el-radio", {
    attrs: {
      label: _vm.CustomerQuoteType.Trading
    },
    model: {
      value: _vm.customer.type,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "type", $$v);
      },
      expression: "customer.type"
    }
  }, [_vm._v("Thương mại")])], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ngày triển khai"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      format: "dd/MM/yyyy"
    },
    model: {
      value: _vm.customer.implementationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "implementationDate", $$v);
      },
      expression: "customer.implementationDate"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 200,
      imagePath: _vm.form.image,
      uploadUrl: "/v1/admin/quote/upload"
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.$set(_vm.form, "image", path);
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Danh sách ảnh"
    }
  }, [_c("quote-image-gallery", {
    attrs: {
      images: _vm.imageUrls,
      onChange: _vm.onChangeImages
    }
  })], 1)], 1)], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;