"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.workOrderInventoryApi = void 0;

var _request = require("@/utils/request");

var workOrderInventoryApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/workOrderInventory',
      params: params
    });
  },
  summaryQuantity: function summaryQuantity(params) {
    return (0, _request.request)({
      url: "/v1/admin/workOrderInventory/summary/quantity",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/workOrderInventory',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/workOrderInventory/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/workOrderInventory/".concat(id),
      method: 'delete'
    });
  }
};
exports.workOrderInventoryApi = workOrderInventoryApi;