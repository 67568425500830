"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "top-sale__panel",
    class: _vm.color
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("div", [_c("CountTo", {
    staticClass: "value",
    attrs: {
      startVal: 0,
      endVal: _vm.amount
    }
  })], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;