"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "receipt-print"
  }, [_vm.isPreviewing ? _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-printer"
    },
    on: {
      click: _vm.print
    }
  }, [_vm._v("In")]) : _vm._e(), _c("div", {
    attrs: {
      id: _vm.tabName
    }
  }, [_c("div", {
    attrs: {
      id: "receipt-print"
    }
  }, [_c("div", {
    staticClass: "receipt-print"
  }, [_c("div", {
    staticClass: "header"
  }, [_vm.company.logo ? _c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "img-box",
    staticStyle: {
      "margin-right": "10px"
    }
  }, [_c("img", {
    attrs: {
      width: "50",
      src: _vm.company.logo,
      alt: ""
    }
  })])]) : _vm._e(), _c("div", {
    staticClass: "right font-size--14"
  }, [[_c("div", {
    staticClass: "font-bold"
  }, [_vm._v(" " + _vm._s(_vm.company.name) + " ")])], _c("div", [_vm._v(" ĐC: " + _vm._s(_vm.company.address) + " ")]), _c("div", [_vm._v(" Điện thoại: "), _c("span", [_vm._v(" " + _vm._s(_vm.company.phone))])])], 2)]), _c("div", {
    staticStyle: {
      position: "relative"
    }
  }, [_c("h3", {
    staticClass: "text-center",
    staticStyle: {
      margin: "8px 0"
    }
  }, [_vm._v(" HÓA ĐƠN BÁN HÀNG ")]), _c("div", {
    staticStyle: {
      position: "absolute",
      right: "0",
      "font-size": "14px",
      top: "0px"
    }
  }, [_c("i", [_vm._v("Số: ")]), _vm.preview ? _c("span", [_vm._v(_vm._s(_vm.form.no))]) : _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.no,
      expression: "form.no"
    }],
    staticClass: "input-custom",
    staticStyle: {
      width: "115px"
    },
    domProps: {
      value: _vm.form.no
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.form, "no", $event.target.value);
      }
    }
  })])]), _c("div", {
    staticClass: "customer-info"
  }, [_c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("div", {
    staticClass: "flex-row",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tên khách hàng: ")]), _vm.preview ? _c("span", {
    staticStyle: {
      flex: "1",
      "border-bottom": "1px dashed",
      "margin-left": "4px"
    }
  }, [_vm._v(_vm._s(_vm.order.customer.name))]) : _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.customer.name,
      expression: "order.customer.name"
    }],
    staticClass: "input-custom font-size--14",
    staticStyle: {
      flex: "1"
    },
    attrs: {
      type: "text"
    },
    domProps: {
      value: _vm.order.customer.name
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.order.customer, "name", $event.target.value);
      }
    }
  })])]), _c("div", [_c("div", {
    staticClass: "flex-row",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Điện thoại: ")]), _vm.preview ? _c("span", {
    staticStyle: {
      flex: "1",
      "border-bottom": "1px dashed",
      "margin-left": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.order.customer.phone) + " ")]) : _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.customer.phone,
      expression: "order.customer.phone"
    }],
    staticClass: "input-custom font-size--14",
    attrs: {
      type: "text"
    },
    domProps: {
      value: _vm.order.customer.phone
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.order.customer, "phone", $event.target.value);
      }
    }
  })])]), _c("div", [_c("div", {
    staticClass: "flex-row"
  }, [_c("label", {
    staticStyle: {
      "font-size": "14px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Địa chỉ: ")]), _vm.preview ? _c("span", {
    staticStyle: {
      flex: "1",
      "border-bottom": "1px dashed",
      "margin-left": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.order.customer.address) + " ")]) : _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.customer.address,
      expression: "order.customer.address"
    }],
    staticClass: "input-custom font-size--14",
    attrs: {
      type: "text"
    },
    domProps: {
      value: _vm.order.customer.address
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.order.customer, "address", $event.target.value);
      }
    }
  })])])]), _c("table", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._m(0), _c("tbody", [_vm._l(_vm.products, function (item, index) {
    return _c("tr", {
      key: index
    }, [_c("td", {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c("td", [_vm.preview ? _c("span", [_vm._v(_vm._s(item.productName))]) : _c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.productName,
        expression: "item.productName"
      }],
      staticClass: "input-no-border",
      attrs: {
        type: "text"
      },
      domProps: {
        value: item.productName
      },
      on: {
        input: function input($event) {
          if ($event.target.composing) return;

          _vm.$set(item, "productName", $event.target.value);
        }
      }
    })]), _c("td", {
      staticClass: "text-center"
    }, [_vm.preview ? _c("div", {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.$formatNumberVN(item.quantity)))]) : _c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.quantity,
        expression: "item.quantity"
      }],
      staticClass: "input-no-border text-center",
      attrs: {
        type: "text"
      },
      domProps: {
        value: item.quantity
      },
      on: {
        input: function input($event) {
          if ($event.target.composing) return;

          _vm.$set(item, "quantity", $event.target.value);
        }
      }
    })]), _c("td", {
      staticClass: "text-center"
    }, [_c("div", {
      staticClass: "cell text-center"
    }, [_vm.preview ? _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(item.price)) + " "), item.price ? _c("span", [_vm._v("đ")]) : _vm._e()]) : _c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.price,
        expression: "item.price"
      }, {
        name: "mask",
        rawName: "v-mask",
        value: {
          alias: "decimal",
          groupSeparator: ".",
          rightAlign: false,
          autoGroup: true,
          repeat: 20,
          autoUnmask: true,
          allowMinus: false
        },
        expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
      }],
      staticClass: "input-no-border text-center",
      attrs: {
        type: "text"
      },
      domProps: {
        value: item.price
      },
      on: {
        input: function input($event) {
          if ($event.target.composing) return;

          _vm.$set(item, "price", $event.target.value);
        }
      }
    })])]), _c("td", {
      staticClass: "text-center"
    }, [_c("div", {
      staticClass: "cell"
    }, [_vm.preview ? _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(Math.round(item.quantity * item.price))) + " "), item.quantity && item.price ? _c("span", [_vm._v("đ")]) : _vm._e()]) : _c("input", {
      staticClass: "input-no-border text-center",
      attrs: {
        readonly: "",
        type: "text"
      },
      domProps: {
        value: _vm.$formatNumberVN(Math.round(item.quantity * item.price))
      }
    })])])]);
  }), _c("tr", [_vm._m(1), _c("td", {
    staticClass: "text-center"
  }, [_c("div", {}, [_vm._v(_vm._s(_vm.$formatNumberVN(Math.round(_vm.totalPrice))) + "đ")])])])], 2)]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thành tiền")]), _c("i", [_vm._v("(Bằng chữ)")]), _vm._v(": " + _vm._s(_vm.$DocTienBangChu(_vm.totalPrice || 0)) + " đồng ")]), _vm._m(2)])])])], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("thead", [_c("tr", [_c("th", {
    staticClass: "text-center",
    staticStyle: {
      width: "60px"
    }
  }, [_vm._v("STT")]), _c("th", {
    staticStyle: {
      width: "200px"
    }
  }, [_vm._v("Tên hàng")]), _c("th", {
    staticClass: "text-center",
    staticStyle: {
      width: "70px"
    }
  }, [_vm._v("Số lượng")]), _c("th", {
    staticClass: "text-center"
  }, [_vm._v("Đơn giá")]), _c("th", {
    staticClass: "text-center"
  }, [_vm._v("Thành tiền")])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("td", {
    staticClass: "text-center font-bold",
    attrs: {
      colspan: "4"
    }
  }, [_c("div", {}, [_vm._v(" TỔNG CỘNG ")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "sign",
    staticStyle: {
      display: "flex",
      "justify-content": "space-around",
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticClass: "left text-center"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Khách hàng")]), _c("br"), _c("span", [_vm._v("(Ký và ghi rõ họ tên)")]), _c("div", {
    staticStyle: {
      height: "50px"
    }
  })]), _c("div", {
    staticClass: "right text-center"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Người bán")]), _c("br"), _c("span", [_vm._v("(Ký và ghi rõ họ tên)")]), _c("div", {
    staticStyle: {
      height: "50px"
    }
  })])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;