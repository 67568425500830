"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeliveryTripLogType = void 0;
var DeliveryTripLogType;
exports.DeliveryTripLogType = DeliveryTripLogType;

(function (DeliveryTripLogType) {
  DeliveryTripLogType["Modify"] = "MODIFY";
  DeliveryTripLogType["UpdateStatus"] = "UPDATE_STATUS";
})(DeliveryTripLogType || (exports.DeliveryTripLogType = DeliveryTripLogType = {}));