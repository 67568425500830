"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.concat.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container product-group-pricing-config"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm tên sp"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Nhóm sản phẩm")]), _c("el-select", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      filterable: "",
      size: "medium"
    },
    on: {
      change: function change($event) {
        return _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.productGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "productGroupId", $$v);
      },
      expression: "query.productGroupId"
    }
  }, _vm._l(_vm.productGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Khách hàng")]), _c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.fetchCustomers,
      size: "medium"
    },
    on: {
      change: function change($event) {
        return _vm.fetchData();
      }
    },
    model: {
      value: _vm.query.customerId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "customerId", $$v);
      },
      expression: "query.customerId"
    }
  }, _vm._l(_vm.customers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " - ").concat(item.phone),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      transform: "translateY(10px)"
    },
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-search"
    },
    on: {
      click: function click($event) {
        return _vm.fetchData();
      }
    }
  }, [_vm._v("Tìm")])], 1)]), _vm.query.customerId ? _c("PromotionList", {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      customer: _vm.customer,
      promotions: _vm.customer.promotions
    },
    on: {
      submitOk: _vm.fetchCustomerDetail
    }
  }) : _vm._e(), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      type: "warning",
      size: "small",
      icon: "el-icon-s-promotion"
    },
    on: {
      click: _vm.saveAll
    }
  }, [_vm._v("Lưu tất cả")]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_vm.query.customerId ? _c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" Lần cập nhật cuối: " + _vm._s(_vm.$formatDateTime(_vm.lastConfig.dateUpdated)) + " ")]) : _vm._e()]), _c("ElTableDraggable", {
    on: {
      drop: _vm.handleDrop
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "config-table",
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "row-key": "id",
      "row-class-name": _vm.tableRowClassName
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "40",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var $index = _ref.$index;
        return [_c("span", [_vm._v(" " + _vm._s($index + 1) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sản phẩm",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Hiển thị ở extension"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-checkbox", {
          on: {
            change: function change($event) {
              return _vm.changeVisiblePriceInExtension(row);
            }
          },
          model: {
            value: row.visiblePriceInExtension,
            callback: function callback($$v) {
              _vm.$set(row, "visiblePriceInExtension", $$v);
            },
            expression: "row.visiblePriceInExtension"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "right",
      label: "Giá bán lẻ"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.price)) + " ")];
      }
    }])
  }), _vm._l(this.quantities, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        align: "center",
        label: "".concat(item, " ").concat(_vm.firstProduct.unit)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref4) {
          var row = _ref4.row;
          return [!_vm.isRounded ? _c("el-input", {
            directives: [{
              name: "mask",
              rawName: "v-mask",
              value: {
                alias: "decimal",
                groupSeparator: ".",
                rightAlign: false,
                autoGroup: true,
                repeat: 20,
                autoUnmask: true,
                allowMinus: false
              },
              expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
            }],
            staticClass: "el-input-center",
            attrs: {
              placeholder: ""
            },
            model: {
              value: row["price".concat(item)],
              callback: function callback($$v) {
                _vm.$set(row, "price".concat(item), $$v);
              },
              expression: "row[`price${item}`]"
            }
          }) : _c("el-input", {
            directives: [{
              name: "mask",
              rawName: "v-mask",
              value: {
                alias: "decimal",
                groupSeparator: ".",
                rightAlign: false,
                autoGroup: true,
                repeat: 20,
                autoUnmask: true,
                allowMinus: false
              },
              expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
            }],
            staticClass: "el-input-center",
            attrs: {
              placeholder: ""
            },
            model: {
              value: row["price".concat(item, "-temp")],
              callback: function callback($$v) {
                _vm.$set(row, "price".concat(item, "-temp"), $$v);
              },
              expression: "row[`price${item}-temp`]"
            }
          })];
        }
      }], null, true)
    });
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            loading: row.loading
          },
          on: {
            click: function click($event) {
              return _vm.handleSave(row);
            }
          }
        }, [_vm._v("Lưu")])];
      }
    }])
  })], 2)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;