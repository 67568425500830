"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("div", {
    staticClass: "panel-item green"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Số lần CSKH ")]), _c("section", _vm._l(_vm.report.topLog, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "detail",
      staticStyle: {
        "margin-top": "4px"
      },
      on: {
        click: function click($event) {
          return _vm.onClickTopLog(item.staffId);
        }
      }
    }, [_c("img", {
      staticStyle: {
        "object-fit": "cover",
        "vertical-align": "middle",
        "margin-right": "4px",
        "border-radius": "50%"
      },
      attrs: {
        width: "40",
        height: "40",
        src: _vm.$baseUrlMedia + item.staffAvatar,
        alt: ""
      }
    }), _c("span", [_vm._v(_vm._s(item.staffName))]), _vm._v(": "), _c("label", [_vm._v(_vm._s(item.total))])]);
  }), 0)])]), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("div", {
    staticClass: "panel-item yellow"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Số đơn hàng ")]), _c("section", _vm._l(_vm.report.topOrder, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "detail",
      staticStyle: {
        "margin-top": "4px"
      },
      on: {
        click: function click($event) {
          return _vm.onClickTopOrder(item.staffId);
        }
      }
    }, [_c("img", {
      staticStyle: {
        "object-fit": "cover",
        "vertical-align": "middle",
        "margin-right": "4px",
        "border-radius": "50%"
      },
      attrs: {
        width: "40",
        height: "40",
        src: _vm.$baseUrlMedia + item.staffAvatar,
        alt: ""
      }
    }), _c("span", [_vm._v(_vm._s(item.staffName))]), _vm._v(": "), _c("label", [_vm._v(_vm._s(item.total))])]);
  }), 0)])]), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("div", {
    staticClass: "panel-item blue"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Doanh số ")]), _c("section", _vm._l(_vm.report.topSale, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "detail",
      staticStyle: {
        "margin-top": "4px"
      },
      on: {
        click: function click($event) {
          return _vm.onClickTopTotalPrice(item.staffId);
        }
      }
    }, [_c("img", {
      staticStyle: {
        "object-fit": "cover",
        "vertical-align": "middle",
        "margin-right": "4px",
        "border-radius": "50%"
      },
      attrs: {
        width: "40",
        height: "40",
        src: _vm.$baseUrlMedia + item.staffAvatar,
        alt: ""
      }
    }), _c("span", [_vm._v(_vm._s(item.staffName))]), _vm._v(": "), _c("label", [_vm._v(_vm._s(_vm.$formatNumberVN(item.total)) + "đ")])]);
  }), 0)])]), _c("el-col", {
    attrs: {
      span: 6,
      offset: 0
    }
  }, [_c("div", {
    staticClass: "panel-item green"
  }, [_c("label", {
    staticClass: "title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Kết bạn Zalo ")]), _c("section", _vm._l(_vm.report.topZalo, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "detail",
      staticStyle: {
        "margin-top": "4px"
      },
      on: {
        click: function click($event) {
          return _vm.onClickTopAddZalo(item.staffId);
        }
      }
    }, [_c("img", {
      staticStyle: {
        "object-fit": "cover",
        "vertical-align": "middle",
        "margin-right": "4px",
        "border-radius": "50%"
      },
      attrs: {
        width: "40",
        height: "40",
        src: _vm.$baseUrlMedia + item.staffAvatar,
        alt: ""
      }
    }), _c("span", [_vm._v(_vm._s(item.staffName))]), _vm._v(": "), _c("label", [_vm._v(_vm._s(item.total))])]);
  }), 0)])])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;