"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm kiếm tên"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm kiếm")])], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: true
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isNeedImport,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isNeedImport", $$v);
      },
      expression: "query.isNeedImport"
    }
  }, [_vm._v("Cần mua hàng")]), _c("el-checkbox", {
    attrs: {
      label: true
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isAlmostOver,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isAlmostOver", $$v);
      },
      expression: "query.isAlmostOver"
    }
  }, [_vm._v("Yêu cầu về hàng")])], 1), _c("el-button", {
    staticClass: "font-bold",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      type: "primary",
      size: "medium"
    },
    on: {
      click: _vm.handleCreatePo
    }
  }, [_vm._v("Tạo PO")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      "row-key": "id",
      fit: "",
      "default-sort": {
        prop: "quantity-yet",
        order: "ascending"
      }
    },
    on: {
      "selection-change": _vm.handleChangeSelection,
      "sort-change": _vm.handleChangeSort
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      type: "selection"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Sản phẩm",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank",
            href: "https://nhanh.vn/product/item/detail?id=".concat(row.id, "&storeId=84054")
          }
        }, [_vm._v(" " + _vm._s(row.name) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn kho (Có thể bán)",
      align: "right",
      prop: "available",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.available >= row.minQuantityToSell ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumber(row.available)) + " ")]) : _c("label", {
          staticStyle: {
            color: "red",
            "font-weight": "bold"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumber(row.available)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đã lên lịch",
      align: "right",
      prop: "quantityPo",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickPending(row, {
                isHasQuantity: _vm.isHasQuantity
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantityPo)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đã giao",
      align: "right",
      prop: "actualQuantity"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.actualQuantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Còn lại",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantityPo - row.actualQuantity)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn + Chưa nhập",
      align: "right",
      prop: "quantity-yet",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("span", {
          class: row.quantityYet < row.quantityThreshold ? "text-danger font-bold" : ""
        }, [_vm._v(_vm._s(row.quantityYet))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngưỡng cần nhập (Tồn tối thiểu mua hàng)",
      align: "right",
      prop: "quantityThreshold"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantityThreshold)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tồn tối thiểu để bán hàng",
      align: "right",
      prop: "quantityThreshold"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.minQuantityToSell)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "SL chờ nhập kho",
      align: "right",
      prop: "pending",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleClickPending(row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.pendingQuantity)) + " ")])];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("HandlePendingQuantityModal", {
    ref: "HandlePendingQuantityModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("POModal", {
    ref: "POModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;