"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "form"
  }, [_vm.title ? _c("h2", {
    staticStyle: {
      "margin-top": "0",
      color: "#163237",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.description ? _c("span", [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _c("el-form", {
    ref: "form",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài (cm)",
      prop: "length"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.length,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length", $$v);
      },
      expression: "form.length"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Rộng (cm)",
      prop: "width"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.width,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "width", $$v);
      },
      expression: "form.width"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cao (cm)",
      prop: "height"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Khổ tấm (m)"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: "",
      value: _vm.khoTam
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dài tấm (m)"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.daiTam,
      disabled: ""
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Diện tích (m2)"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: "",
      value: _vm.$formatNumberVN(_vm.acreage, 2)
    }
  })], 1)], 1)], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;