"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      width: "148mm"
    },
    style: {
      display: _vm.$devMode ? "block" : "none"
    },
    attrs: {
      id: "report-tp-print"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column"
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("qrcode-vue", {
    attrs: {
      value: _vm.workOrder.code,
      size: _vm.qrSize,
      level: "H",
      "render-as": "svg"
    }
  })], 1), _c("div", {
    staticStyle: {
      "text-align": "center",
      flex: "1"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    }
  }, [_vm._v("Mã LSX: " + _vm._s(_vm.lsxCode))])])]), _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      "font-size": "70px",
      "font-weight": "bold"
    }
  }, [_vm._v(" " + _vm._s(_vm.sizeString) + " ")]), _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.workOrder.productName) + " ")])])])])]), _c("h1", {
    staticStyle: {
      "text-align": "center",
      color: "#000",
      "font-size": "20px"
    }
  }, [_vm._v(" THẺ THÀNH PHẨM HẢI ĐĂNG ")]), _c("div", {
    staticClass: "raw-table"
  }, [_c("table", [_c("tr", [_c("td", {
    staticStyle: {
      "font-weight": "bold",
      width: "200spx"
    }
  }, [_vm._v(" " + _vm._s(_vm.productionReport.productionWork.workOrder.printColors ? "THÙNG IN" : "KHÔNG IN") + " ")]), _c("td", {
    attrs: {
      colspan: "3"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-around"
    }
  }, _vm._l(_vm.bo, function (item) {
    return _c("div", {
      key: item
    }, [_c("span", {
      staticStyle: {
        "vertical-align": "middle",
        "font-size": "12px"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]), _c("span", {
      staticStyle: {
        width: "15px",
        height: "15px",
        border: "1px solid",
        display: "inline-block",
        "vertical-align": "middle"
      }
    })]);
  }), 0)])]), _vm._m(0), _c("tr", {
    staticStyle: {
      height: "35px"
    }
  }, [_c("td", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatDate(_vm.productionReport.dateCreated)) + " ")]), _c("td", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.productionReport.quantity)) + " ")]), _c("td"), _c("td")]), _vm._l(_vm.totalRows, function (index) {
    return _c("tr", {
      key: index,
      staticStyle: {
        height: "35px"
      }
    }, [_c("td"), _c("td"), _c("td"), _c("td")]);
  })], 2)]), _vm._m(1)]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("tr", [_c("td", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Ngày")]), _c("td", {
    staticStyle: {
      "text-align": "center",
      width: "25%"
    }
  }, [_vm._v("Nhập")]), _c("td", {
    staticStyle: {
      "text-align": "center",
      width: "25%"
    }
  }, [_vm._v("Xuất")]), _c("td", {
    staticStyle: {
      "text-align": "center",
      width: "25%"
    }
  }, [_vm._v("Tồn cuối")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-around",
      "margin-top": "24px"
    }
  }, [_c("div", [_vm._v("NHÂN VIÊN NK")]), _c("div", [_vm._v("NHÂN VIÊN KHO")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;