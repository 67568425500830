"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MisaOpportunitySuccessReason = exports.MisaOpportunityStatusValue = exports.MisaOpportunityStatusColors = exports.MisaOpportunityStatus = exports.MisaOpportunityFailReason = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/my-project/vietbox-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _MisaOpportunityStatu;

var MisaOpportunityStatusColors = (_MisaOpportunityStatu = {}, (0, _defineProperty2.default)(_MisaOpportunityStatu, 0, 'rgb(51, 126, 255)'), (0, _defineProperty2.default)(_MisaOpportunityStatu, 1, 'rgb(66, 98, 240)'), (0, _defineProperty2.default)(_MisaOpportunityStatu, 7, 'rgb(49, 180, 145)'), (0, _defineProperty2.default)(_MisaOpportunityStatu, 2, 'rgb(236, 65, 65)'), (0, _defineProperty2.default)(_MisaOpportunityStatu, 4, 'rgb(240, 125, 66)'), (0, _defineProperty2.default)(_MisaOpportunityStatu, 3, 'rgb(66, 72, 87)'), (0, _defineProperty2.default)(_MisaOpportunityStatu, 10, 'rgb(100, 66, 240)'), (0, _defineProperty2.default)(_MisaOpportunityStatu, 5, 'rgb(233, 180, 73)'), (0, _defineProperty2.default)(_MisaOpportunityStatu, 6, 'rgb(51, 126, 255)'), _MisaOpportunityStatu);
exports.MisaOpportunityStatusColors = MisaOpportunityStatusColors;
var MisaOpportunityStatus;
exports.MisaOpportunityStatus = MisaOpportunityStatus;

(function (MisaOpportunityStatus) {
  MisaOpportunityStatus["New"] = "New";
  MisaOpportunityStatus["Quoted"] = "Quoted";
  MisaOpportunityStatus["SendSample"] = "SendSample";
  MisaOpportunityStatus["Order"] = "Order";
  MisaOpportunityStatus["AppCustomer"] = "AppCustomer";
  MisaOpportunityStatus["ComingBuy"] = "ComingBuy";
  MisaOpportunityStatus["Success"] = "Success";
  MisaOpportunityStatus["Fail"] = "Fail";
})(MisaOpportunityStatus || (exports.MisaOpportunityStatus = MisaOpportunityStatus = {}));

var MisaOpportunityStatusValue = [
/**
 * mới
 */
{
  value: 1,
  text: 'MỚI',
  status: MisaOpportunityStatus.New,
  probability: 10
},
/**
 * đã báo giá
 */
{
  value: 2,
  text: 'ĐÃ BÁO GIÁ',
  status: MisaOpportunityStatus.Quoted,
  probability: 30
},
/**
 * gửi mẫu
 */
{
  value: 4,
  text: 'GỬI MẪU',
  status: MisaOpportunityStatus.SendSample,
  probability: 50
},
/**
 * lên đơn
 */
{
  value: 3,
  text: 'CHỜ LÊN ĐƠN',
  status: MisaOpportunityStatus.Order,
  probability: 90
},
/**
 * hẹn khách
 */
{
  value: 10,
  text: 'Khách hẹn lại sau',
  status: MisaOpportunityStatus.AppCustomer,
  probability: 90
},
/**
 * sắp mua lại
 */
{
  value: 7,
  text: 'SẮP MUA LẠI',
  status: MisaOpportunityStatus.ComingBuy,
  probability: 50
},
/**
 * thành công
 */
{
  value: 5,
  text: 'THÀNH CÔNG',
  status: MisaOpportunityStatus.Success,
  probability: 100
}, {
  value: 0,
  text: 'THẤT BẠI',
  status: MisaOpportunityStatus.Fail,
  probability: 0
}];
exports.MisaOpportunityStatusValue = MisaOpportunityStatusValue;
var MisaOpportunitySuccessReason = [{
  "DictionaryID": 5,
  "id": 1,
  "text": "Giá cả và chính sách bán hàng tốt",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}, {
  "DictionaryID": 5,
  "id": 3,
  "text": "Dịch vụ chăm sóc khách hàng của công ty tốt",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}, {
  "DictionaryID": 5,
  "id": 5,
  "text": "Tin tưởng thương hiệu của Công ty",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}, {
  "DictionaryID": 5,
  "id": 7,
  "text": "Khả năng thuyết phục khách hàng của NVKD tốt",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}, {
  "DictionaryID": 5,
  "id": 9,
  "text": "Sản phẩm đáp ứng yêu cầu của KH",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}];
exports.MisaOpportunitySuccessReason = MisaOpportunitySuccessReason;
var MisaOpportunityFailReason = [{
  "DictionaryID": 5,
  "id": 2,
  "text": "Giá cả và chính sách bán hàng chưa tốt",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}, {
  "DictionaryID": 5,
  "id": 4,
  "text": "Dịch vụ chăm sóc khách hàng của công ty chưa tốt",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}, {
  "DictionaryID": 5,
  "id": 6,
  "text": "Thương hiệu công ty không có uy tín",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}, {
  "DictionaryID": 5,
  "id": 8,
  "text": "Khả năng thuyết phục khách hàng của NVKD chưa tốt",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}, {
  "DictionaryID": 5,
  "id": 10,
  "text": "Sản phẩm không đáp ứng yêu cầu của KH",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}, {
  "DictionaryID": 5,
  "id": 11,
  "text": "Khách hàng sử dụng gói miễn phí",
  "TypeColor": 0,
  "MISAEntityState": 2,
  "IsCallFrom": false,
  "IsCallTo": false
}];
exports.MisaOpportunityFailReason = MisaOpportunityFailReason;