"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: "Tên"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "code",
      label: "Code"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Sản phẩm",
      prop: "productIds"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      multiple: "",
      "reserve-keyword": "",
      remote: "",
      "remote-method": _vm.fetchProducts
    },
    model: {
      value: _vm.form.productIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "productIds", $$v);
      },
      expression: "form.productIds"
    }
  }, _vm._l(_vm.products, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;