"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "append-to-body": "",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v("Thẻ kho: " + _vm._s(_vm.workOrder ? _vm.workOrder.code : ""))]), _vm.workOrder ? [_c("div", [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.workOrder.materialName))])]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.workOrder.name))])])] : _vm._e()], 2), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số lượng",
      prop: "quantity"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        clearIncomplete: true
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n          clearIncomplete: true,\n        }"
    }],
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Biển số",
      prop: "licensePlate"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.licensePlate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "licensePlate", $$v);
      },
      expression: "form.licensePlate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nhân viên kho",
      prop: "staffName"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.form.staffName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffName", $$v);
      },
      expression: "form.staffName"
    }
  }, _vm._l(_vm.staffNames, function (name) {
    return _c("el-radio", {
      key: name,
      attrs: {
        label: name
      }
    }, [_vm._v(_vm._s(name))]);
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Nhà cung cấp",
      prop: "providerName"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.form.providerName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "providerName", $$v);
      },
      expression: "form.providerName"
    }
  }, _vm._l(_vm.providerNames, function (name) {
    return _c("el-radio", {
      key: name,
      attrs: {
        label: name
      }
    }, [_vm._v(_vm._s(name))]);
  }), 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;