"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "vietbox-note-item"
  }, [_vm.vietboxNote.createdStaff ? _c("div", {
    staticClass: "owner-name"
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.vietboxNote.createdStaff.name) + " ")])]) : _vm._e(), _c("div", {
    staticClass: "activity-name"
  }, [_vm._v(" " + _vm._s(_vm.vietboxNote.content) + " ")]), _c("div", {
    staticClass: "due-date",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_c("i", [_c("i", {
    staticClass: "el-icon-timer"
  }), _c("span", {}, [_vm._v(_vm._s(_vm.$formatDateTime(_vm.vietboxNote.dateCreated)) + " ")])])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;