"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    style: {
      display: _vm.$devMode ? "block" : "none"
    }
  }, [_c("div", {
    attrs: {
      id: "delivery-print"
    }
  }, [_c("div", {
    staticStyle: {
      position: "relative",
      "text-align": "center"
    }
  }, [_c("div", [_c("img", {
    attrs: {
      src: _vm.logo,
      width: 70,
      alt: ""
    }
  }), _vm._m(0)]), _c("h1", {
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_vm._v("PHIẾU YÊU CẦU GIAO HÀNG")]), _vm.deliveryTrip && _vm.type == "NORMAL" ? [_c("span", [_vm._v("Ngày: " + _vm._s(_vm.$formatDate(_vm.deliveryTrip.startAt)))]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.deliveryTrip.name))]), _vm._v(" - "), _c("span", [_vm._v(_vm._s(_vm.deliveryTrip.privateName))])])] : _vm.type == "PE" ? [_c("span", [_vm._v("Ngày: " + _vm._s(_vm.date))])] : _vm._e()], 2), _c("div", {
    staticStyle: {
      "font-size": "18px",
      "margin-bottom": "20px",
      "margin-top": "12px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng thể tích: ")]), _c("span", [_vm._v(_vm._s(_vm.totalCubicMeter.toFixed(1)) + " m3")])]), _c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng thể tích (Đã giao): ")]), _c("span", [_vm._v(_vm._s(_vm.totalCubicMeterDelivered.toFixed(1)) + " m3")])]), _vm.visibleDelivered ? [_vm.deliveryTrips.length > 1 ? _c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Ghép: ")]), _c("span", {
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.deliveryTrips.length - 1) + " điểm (" + _vm._s(_vm.$formatNumberVN(_vm.phiGhepDiem)) + " đ) ")])]) : _vm._e(), _c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền theo thể tích: ")]), _c("span", {
    staticStyle: {
      color: "red",
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByCubicMeter)) + "đ")]), _vm.phiGhepDiem ? _c("span", [_vm._v(" + " + _vm._s(_vm.$formatNumberVN(_vm.phiGhepDiem)) + "đ = "), _c("span", {
    staticStyle: {
      color: "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.totalAmountByCubicMeter + _vm.phiGhepDiem)) + " ")])]) : _vm._e()]), _c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền theo mét vuông: ")]), _c("span", {
    staticStyle: {
      color: "red",
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByMeter)) + "đ")]), _vm.phiGhepDiem ? _c("span", [_vm._v(" + " + _vm._s(_vm.$formatNumberVN(_vm.phiGhepDiem)) + "đ = "), _c("span", {
    staticStyle: {
      color: "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByMeter + _vm.phiGhepDiem)))])]) : _vm._e()]), _c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tổng tiền theo mét vuông (Mới): ")]), _c("span", {
    staticStyle: {
      color: "red",
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByMeter2)) + "đ")]), _vm.phiGhepDiem ? _c("span", [_vm._v(" + " + _vm._s(_vm.$formatNumberVN(_vm.phiGhepDiem)) + "đ = "), _c("span", {
    staticStyle: {
      color: "20px"
    }
  }, [_vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalAmountByMeter2 + _vm.phiGhepDiem)))])]) : _vm._e()])] : _vm._e(), _vm._l(_vm.deliveryTrips, function (child, i) {
    return _c("div", {
      staticStyle: {
        "margin-top": "24px"
      }
    }, [_c("label", {
      staticStyle: {
        "font-size": "20px"
      }
    }, [_vm._v("Điểm " + _vm._s(i + 1) + ": " + _vm._s(child.customerName) + " ")]), _c("el-tag", {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        type: "",
        size: "normal",
        effect: "dark"
      }
    }, [_c("label", {
      staticStyle: {
        "font-size": "18px"
      },
      attrs: {
        for: ""
      }
    }, [_vm._v(" (" + _vm._s(child.totalCubicMeter.toFixed(1)) + " m3)")])]), !_vm.visibleDelivered ? [child.createdStaff ? _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Người tạo: ")]), _c("span", [_vm._v(" " + _vm._s(child.createdStaff.name) + " ")])]) : _vm._e(), _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("ĐC: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.getAddress(child)) + " ")])]), child.isPE ? _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      staticStyle: {
        color: "red"
      },
      attrs: {
        for: ""
      }
    }, [_vm._v("Quấn PE: ")]), _c("span", [_vm._v(" " + _vm._s(child.peNote) + " ")])]) : _vm._e(), child.note ? _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Ghi chú: ")]), _c("span", [_vm._v(" " + _vm._s(child.note) + " ")])]) : _vm._e(), child.privateNote ? _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Giao hộ: ")]), _c("span", [_vm._v(" " + _vm._s(child.privateNote) + " ")])]) : _vm._e()] : _vm._e(), _vm.visibleDelivered ? [_c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" Tiền theo m2: ")]), _c("span", {
      staticStyle: {
        color: "red",
        "font-size": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(child.amountByMeter)) + "đ (" + _vm._s(child.totalMeterDelivered) + "m2) ")])]), _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" Tiền theo m2 (mới): ")]), _c("span", {
      staticStyle: {
        color: "red",
        "font-size": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(child.amountByMeter2)) + "đ (" + _vm._s(child.totalMeterDelivered) + "m2) ")])]), _c("div", {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" Tiền theo m3: ")]), _c("span", {
      staticStyle: {
        color: "red",
        "font-size": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(child.amountByCubicMeter)) + "đ (" + _vm._s(child.totalCubicMeterDelivered) + "m3) ")])])] : _vm._e(), _c("table", [_c("tr", [_c("td", {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v("STT")]), _c("td", [_vm._v("Tên hàng")]), !_vm.visibleDelivered ? [_c("td", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v("Số lượng")]), _c("td", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v("Tồn thành phẩm")]), _c("td", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v("Tồn BTP (Chưa dán)")])] : [_c("td", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v("Đã giao")]), _c("td", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v("Số m2")]), _c("td", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v("Tiền m2")]), _c("td", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v("Số m3")]), _c("td", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v("Tiền m3")])]], 2), _vm._l(_vm.getDeliveryTripDetails(child), function (row, index) {
      return _c("tr", [_c("td", {
        staticStyle: {
          "text-align": "center"
        }
      }, [_vm._v(_vm._s(index + 1))]), _c("td", [_c("span", [_vm._v(" " + _vm._s(row.name) + " ")]), _c("div", {
        staticStyle: {
          "font-weight": "bold"
        }
      }, [_vm._v(" " + _vm._s(_vm.visibleGiaCong(row) ? "Hàng theo yêu cầu" : "") + " ")])]), !_vm.visibleDelivered ? [_c("td", {
        staticStyle: {
          "text-align": "right"
        }
      }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " ")]), _c("td", {
        staticStyle: {
          "text-align": "right"
        }
      }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalTP)) + " ")]), _c("td", {
        staticStyle: {
          "text-align": "right"
        }
      }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalBTP)) + " ")])] : [_c("td", {
        staticStyle: {
          "text-align": "right"
        }
      }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.delivered)) + " ")]), _c("td", {
        staticStyle: {
          "text-align": "right"
        }
      }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalMeterDelivered)) + " ")]), _c("td", {
        staticStyle: {
          "text-align": "right"
        }
      }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amountByMeter)) + " ")]), _c("td", {
        staticStyle: {
          "text-align": "right"
        }
      }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalCubicMeterDelivered)) + " ")]), _c("td", {
        staticStyle: {
          "text-align": "right"
        }
      }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amountByCubicMeter)) + " ")])]], 2);
    })], 2)], 2);
  })], 2)]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_vm._v("CÔNG TY TNHH SẢN XUẤT BAO BÌ GIẤY HẢI ĐĂNG")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;