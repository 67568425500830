"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tổng khối lượng mẫu (Kg)",
      prop: "palletWeight"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.palletWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "palletWeight", $$v);
      },
      expression: "form.palletWeight"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Số lượng tấm mẫu đi cân (cái)",
      prop: "quantity"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
    }],
    on: {
      input: _vm.handleChangeForm
    },
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Cân nặng trên 1 sp (Kg)"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
    }],
    attrs: {
      disabled: "",
      value: _vm.form.unitWeight
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;